/**
 * Utilitaires pour les abonnements
 */

/**
 * Extrait la périodicité de l'abonnement à partir des données utilisateur
 * @param userData Données utilisateur
 * @returns Périodicité de l'abonnement ('month' ou 'year')
 */
export const extractSubscriptionInterval = (userData: any): string => {
  console.log('Extraction de la périodicité pour:', userData);
  
  // Cas 1: Vérifier si l'utilisateur a des abonnements dans userData.subscriptions
  if (userData?.subscriptions?.data && userData.subscriptions.data.length > 0) {
    const subscription = userData.subscriptions.data[0];
    
    // Vérifier si l'abonnement a un plan avec un intervalle
    if (subscription?.plan?.interval) {
      // Retourner directement l'intervalle du plan (déjà au format 'month' ou 'year')
      console.log(`Périodicité extraite de plan.interval: ${subscription.plan.interval}`);
      return subscription.plan.interval;
    }
  }
  
  // Cas 2: Vérifier si l'utilisateur a un abonnement dans userData.user.subscriptions
  if (userData?.user?.subscriptions?.data && userData.user.subscriptions.data.length > 0) {
    const subscription = userData.user.subscriptions.data[0];
    
    // Vérifier si l'abonnement a un plan avec un intervalle
    if (subscription?.plan?.interval) {
      // Retourner directement l'intervalle du plan (déjà au format 'month' ou 'year')
      console.log(`Périodicité extraite de user.subscriptions.plan.interval: ${subscription.plan.interval}`);
      return subscription.plan.interval;
    }
  }
  
  // Cas 3: Vérifier si l'utilisateur a un abonnement dans userData.subscription
  if (userData?.subscription?.plan?.interval) {
    // Retourner directement l'intervalle du plan (déjà au format 'month' ou 'year')
    console.log(`Périodicité extraite de subscription.plan.interval: ${userData.subscription.plan.interval}`);
    return userData.subscription.plan.interval;
  }
  
  // Cas 4: Vérifier si l'utilisateur a un abonnement dans userData.currentPeriod
  if (userData?.currentPeriod) {
    // Retourner directement la période (déjà au format 'month' ou 'year')
    console.log(`Périodicité extraite de currentPeriod: ${userData.currentPeriod}`);
    return userData.currentPeriod;
  }
  
  // Cas 5: Vérifier si l'utilisateur a un abonnement dans userData.selectedPeriod
  if (userData?.selectedPeriod) {
    // Retourner directement la période (déjà au format 'month' ou 'year')
    console.log(`Périodicité extraite de selectedPeriod: ${userData.selectedPeriod}`);
    return userData.selectedPeriod;
  }
  
  // Valeur par défaut si aucune information d'abonnement n'est trouvée
  console.log('Aucune périodicité trouvée, utilisation de la valeur par défaut: year');
  return 'year';
};

/**
 * Ajoute la périodicité de l'abonnement aux permissions de l'utilisateur
 * @param permissions Permissions actuelles
 * @param userData Données utilisateur
 * @returns Permissions avec la périodicité ajoutée
 */
export const addIntervalToPermissions = (permissions: any, userData: any): any => {
  // Créer une copie des permissions
  const updatedPermissions = { ...permissions };
  
  // Extraire l'intervalle au format 'month' ou 'year'
  const intervalValue = extractSubscriptionInterval(userData);
  console.log('Intervalle extrait pour les permissions:', intervalValue);
  
  // Ajouter la propriété interval si elle n'existe pas déjà
  if (!updatedPermissions.interval) {
    updatedPermissions.interval = {
      value: intervalValue,
      id: 0
    };
    console.log('Intervalle ajouté aux permissions:', updatedPermissions.interval);
  }
  
  return updatedPermissions;
};