import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getPredefinedPermissions,
  compareShallowObject
} from '../utils';
import { calculatePrice } from '../utils/priceCalculatorClient';
import { offerLabelsAndColors, Go } from '../Constants';
import { offerApi } from '../Api';
import { CustomOffer } from '../types/offer';
import { config } from '../config';

// IDs des produits Stripe pour l'offre groupe
const STRIPE_PRODUCTS = {
  fv_groupe_product_id: config.stripe.groupe_product_id || '',
  fv_usage_product_id: config.stripe.usage_product_id || ''
} as const;

export const useOffer = (
  user_permissions: any,
  isNewUser: boolean,
  currentPeriod: 'month' | 'year'
) => {
  const [currentOffer, setCurrentOfferState] = useState<CustomOffer | null>(null);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [type, setType] = useState<number>(0);
  const location = useLocation();
  
  // Utiliser une ref pour éviter les boucles infinies
  const prevOfferRef = useRef<CustomOffer | null>(null);

  const updatePrice = useCallback((offer: CustomOffer) => {
    if (!offer) {
      console.log('useOffer: No offer provided for price update');
      return;
    }

    console.log('useOffer: Updating price for offer:', offer);
    const newPrice = calculatePrice(offer, currentPeriod);

    console.log('useOffer: Calculated price details:', {
      offer,
      currentPeriod,
      newPrice
    });

    console.log('useOffer: New price calculated:', newPrice);
    setCurrentPrice(newPrice);
  }, [currentPeriod]);

  const setCurrentOffer = useCallback((offer: CustomOffer) => {
    console.log('useOffer: Setting current offer:', offer);
    setCurrentOfferState(offer);
    updatePrice(offer);
  }, [updatePrice]);

  const handleOfferUpdate = useCallback((newOfferOrFunction: CustomOffer | ((prevOffer: CustomOffer) => CustomOffer)) => {
    setCurrentOfferState((prevOffer: CustomOffer | null) => {
      if (!prevOffer) return null;

      const newOffer = typeof newOfferOrFunction === 'function' 
        ? newOfferOrFunction(prevOffer) 
        : newOfferOrFunction;

      console.log('useOffer: Handling offer update:', newOffer);

      const updatedOffer = {
        ...newOffer,
        name: newOffer.name === 'Groupe' || newOffer.isGroupeOffer ? 'Groupe' : 'Personnalisée',
        period: newOffer.period || prevOffer.period,
        basePrice: newOffer.basePrice || prevOffer.basePrice
      };

      // Mettre à jour le prix immédiatement
      setTimeout(() => updatePrice(updatedOffer), 0);

      return updatedOffer;
    });
  }, [updatePrice]);

  // Effet pour initialiser l'offre uniquement si aucune offre n'est déjà définie
  useEffect(() => {
    const initializeOffer = () => {
      if (currentOffer) {
        console.log('useOffer: Offer already set, skipping initialization');
        return;
      }

      console.log('useOffer: Initializing offer');
      const params = new URLSearchParams(location.search);
      let newOffer: CustomOffer;

      if (params.get("studio") !== null) {
        console.log('useOffer: Using studio config');
        newOffer = getPredefinedPermissions('offer_studio_label');
      } else if (params.get("pro") !== null) {
        console.log('useOffer: Using pro config');
        newOffer = getPredefinedPermissions('offer_pro_label');
      } else if (!user_permissions || Object.keys(user_permissions).length === 0 || isNewUser) {
        console.log('useOffer: Using solo config');
        newOffer = getPredefinedPermissions('offer_solo_label');
      } else {
        console.log('useOffer: Using custom config');
        newOffer = getPredefinedPermissions('offer_solo_label');
      }

      // Comparer avec l'offre précédente pour éviter les mises à jour inutiles
      if (JSON.stringify(newOffer) !== JSON.stringify(prevOfferRef.current)) {
        console.log('useOffer: Setting new offer:', newOffer);
        prevOfferRef.current = newOffer;
        setCurrentOfferState(newOffer);
        updatePrice(newOffer);
      }
    };

    initializeOffer();
  }, [location.search, currentOffer, updatePrice, user_permissions, isNewUser]);

  // Effet pour mettre à jour le type
  useEffect(() => {
    if (!currentOffer) return;

    const checkType = () => {
      const soloConfig = offerLabelsAndColors[1].config;
      const proConfig = offerLabelsAndColors[2].config;

      if (compareShallowObject(soloConfig, currentOffer)) {
        return 1;
      }
      if (compareShallowObject(proConfig, currentOffer)) {
        return 2;
      }
      return 0;
    };

    const newType = checkType();
    if (newType !== type) {
      setType(newType);
    }
  }, [currentOffer, type]);

  const handleSubmit = useCallback(async (formValues: any) => {
    if (formValues.isGroupeOffer) {
      // Création de deux abonnements séparés : groupe (selon période choisie) et usage (mensuel)
      const payload = {
        products: [
          {
            // Abonnement groupe (selon la période choisie)
            product_id: STRIPE_PRODUCTS.fv_groupe_product_id,
            interval: currentPeriod,
            mode: 'subscription',
            metadata: {
              type: 'groupe',
              isGroupeOffer: 'true',
              upload_size: String(200 * Go),
              upload_ttl: '15',
              team_size: '-1',
              transfer_notification: 'true',
              transfer_authentication: 'true',
              transfer_tracking: 'true',
              consumption_export: 'true',
              customization: 'true',
              outlook_addin: 'true',
              studio: 'true'
            }
          },
          {
            // Abonnement usage (toujours mensuel)
            product_id: STRIPE_PRODUCTS.fv_usage_product_id,
            interval: 'month',
            usage_type: 'metered',
            mode: 'subscription',
            metadata: {
              type: 'usage'
            }
          }
        ]
      };
      
      if (user_permissions && !isNewUser) {
        try {
          return await offerApi.createCheckoutSessionForUser(payload);
        } catch (e) {
          console.error('Error in handleSubmit for groupe offer:', e);
          return null;
        }
      }
      return payload;
    }
  
    const payload = {
      ...currentOffer,
      interval: currentPeriod,
      ...Object.keys(formValues).reduce(
        (acc: any, cur: string) => {
          acc[cur] = formValues[cur];
          return acc;
        },
        {}
      )
    };
  
    if (user_permissions && !isNewUser) {
      try {
        return await offerApi.createCheckoutSessionForUser({ products: payload });
      } catch (e) {
        console.error('Error in handleSubmit:', e);
        return null;
      }
    }
    
    return payload;
  }, [currentPeriod, currentOffer, user_permissions, isNewUser]);

  return {
    currentOffer,
    setCurrentOffer,
    currentPrice,
    type,
    handleSubmit,
    handleOfferUpdate
  };
};
