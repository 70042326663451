import { gradients } from "../Constants";

export function getThemeClass(id: string) {
  let themeClass: any;
  if (id === "custom-0" || id === "custom-1") return id;
  if ("0" === id[0])
    themeClass = gradients.clair.find((gradient) => gradient.id === id);
  else themeClass = gradients.sombre.find((gradient) => gradient.id === id);

  return themeClass?.className || "default";
}

export function getClassName(transferConfig: any) {
  // Si pas de configuration, retourner une classe vide
  if (!transferConfig || !transferConfig.config) {
    return "";
  }

  // Si un background custom est présent, pas de gradient
  if (transferConfig.config.background?.url) {
    return "";
  }

  // Si un thème est défini et pas de background custom, appliquer le gradient
  return transferConfig.config.theme ? getThemeClass(transferConfig.config.theme) : "";
}

export function getStyle(transferConfig: any, userConfig: any, app: any) {
  // 1. On part systématiquement du background par défaut
  let style = {
    backgroundImage: `url(${app.BACKGROUND})`,
    maxHeight: "100vh",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "fixed",
    top: 0,
  };

  // 2. Si un background est défini dans transferConfig => il l’emporte
  if (transferConfig?.config?.background?.url) {
    style.backgroundImage = `url(${transferConfig.config.background.url})`;
  } 
  // 3. Sinon, s’il y en a un dans userConfig => il l’emporte à son tour
  else if (userConfig?.background?.url) {
    style.backgroundImage = `url(${userConfig.background.url})`;
  }

  // 4. (Optionnel) Pour un gradient ou un thème custom, on peut faire :
  // if (transferConfig?.config?.theme) {
  //    style.backgroundImage = "none";
  //    style.background = getSomeGradientFromTheme(transferConfig.config.theme);
  // }

  return style;
}
