import React, { FormEvent, useState, useEffect, useRef } from "react";
import {
  InputCheckboxComponent,
  InputEmailComponent,
  InputPasswordComponent,
  InputTextComponent,
} from "./Input";
import { i18n, onClickSubmit } from "../../utils";
import { Loader } from "lucide-react";

interface InvitationFormProps {
  onSubmit: (data: any) => Promise<any>;
  first_name: any;
  last_name: any;
  email: any;
  organization: any;
  job: any;
  password: any;
}

export function InvitationFormComponent({ onSubmit, ...rest }: InvitationFormProps) {
  const { first_name, last_name, email, organization, job, password } = rest;
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const mounted = useRef(true);

  const handleNextStep = (evt: React.MouseEvent) => {
    evt.preventDefault();
    const form = document.querySelector('form');
    const inputs = Array.from(form?.querySelectorAll('input[required]') || []) as HTMLInputElement[];
    const currentStepInputs = inputs.filter(input => {
      const parent = input.closest('[data-step]');
      return parent?.getAttribute('data-step') === '1';
    });
    
    const hasErrors = currentStepInputs.some(input => !input.checkValidity());
    if (!hasErrors) {
      setCurrentStep(2);
    } else {
      form?.classList.add('was-validated');
    }
  };

  const handlePrevStep = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setCurrentStep(1);
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.classList.add("was-validated"); // Optionnel : ajustez ou supprimez selon vos besoins

    const inputs = Array.from(evt.currentTarget.querySelectorAll("input")) as HTMLInputElement[];
    const hasErrors = inputs.some(input => !input.checkValidity());

    if (!hasErrors && onSubmit) {
      setSubmitting(true);

      const formData = inputs.reduce((acc, cur) => {
        if (cur.name && cur.name !== "confirm_password") {
          if (cur.type === "checkbox") {
            acc[cur.name] = cur.checked;
          } else if (cur.value) {
            acc[cur.name] = cur.value;
          }
        }
        return acc;
      }, {} as Record<string, any>);

      const submitErrors = await onSubmit(formData);

      if (submitErrors?.field && !submitErrors.ok) {
        if (mounted.current) {
          setSubmitting(false);
        }

        switch (submitErrors.field) {
          case "email":
            if (mounted.current && email?.setCurrentError) {
              email.setCurrentError(submitErrors.reason);
            }
            break;
          default:
            break;
        }
      } else {
        // Réinitialiser l'état après une soumission réussie
        if (mounted.current) {
          setSubmitting(false);
        }
        // Vous pouvez également réinitialiser le formulaire ou rediriger l'utilisateur
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6"
      noValidate
    >
      {/* Indicateur de progression */}
      <div className="flex items-center justify-center mb-6">
        <div className="flex items-center space-x-2">
          <div className={`w-6 h-6 text-sm rounded-full flex items-center justify-center border-2 ${
            currentStep >= 1 ? 'border-emerald-500 text-emerald-500' : 'border-gray-500 text-gray-500'
          }`}>
            1
          </div>
          <div className={`w-8 h-0.5 ${
            currentStep === 2 ? 'bg-emerald-500' : 'bg-gray-500'
          }`}></div>
          <div className={`w-6 h-6 text-sm rounded-full flex items-center justify-center border-2 ${
            currentStep === 2 ? 'border-emerald-500 text-emerald-500' : 'border-gray-500 text-gray-500'
          }`}>
            2
          </div>
        </div>
      </div>
      {/* Step 1: Informations essentielles */}
      <div data-step="1" className={currentStep === 1 ? 'block' : 'hidden'}>
        <h3 className="text-base font-medium text-gray-300 mb-4 text-center">
          {i18n._("step_1_title") || "Informations essentielles"}
        </h3>
        <div className="space-y-6">
          {/* Prénoms et Noms */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <InputTextComponent inputObject={first_name} />
            </div>
            <div>
              <InputTextComponent inputObject={last_name} />
            </div>
          </div>

          {/* Email */}
          <div>
            <InputEmailComponent inputObject={email} />
          </div>

          {/* Mot de Passe */}
          <div>
            <InputPasswordComponent {...password.getRawInput()} />
          </div>

          {/* Bouton Suivant */}
          <button
            onClick={handleNextStep}
            className="w-full bg-emerald-500 text-white py-3 px-4 rounded-lg font-medium transition-all duration-300 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-800 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
          >
            {i18n._("next_step_label") || "Suivant"}
          </button>
        </div>
      </div>

      {/* Step 2: Informations complémentaires */}
      <div data-step="2" className={currentStep === 2 ? 'block' : 'hidden'}>
        <h3 className="text-base font-medium text-gray-300 mb-4 text-center">
          {i18n._("step_2_title") || "Informations complémentaires"}
        </h3>
        <div className="space-y-6">
          {/* Organisation */}
          <div>
            <InputTextComponent inputObject={organization} />
          </div>

          {/* Poste */}
          <div>
            <InputTextComponent inputObject={job} />
          </div>

          {/* Acceptation des CGU */}
          <div>
            <InputCheckboxComponent
              id="cgu_input"
              label={i18n._("accept_cgu_member_label")}
              name="cgu"
              defaultChecked={false}
              required
            />
          </div>

          {/* Boutons de navigation */}
          <div className="flex gap-4">
            <button
              onClick={handlePrevStep}
              className="w-1/3 bg-gray-500 text-white py-3 px-4 rounded-lg font-medium transition-all duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              {i18n._("previous_step_label") || "Retour"}
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="w-2/3 bg-emerald-500 text-white py-3 px-4 rounded-lg font-medium transition-all duration-300 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-800 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {!submitting ? (
                i18n._("btn_valid_label")
              ) : (
                <Loader className="w-5 h-5 animate-spin" />
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
