import React, { useEffect, useRef, useState } from 'react';
import { i18n } from "../../../../utils";

interface ChunkProgress {
  loaded: number;
  total: number;
}

interface FileUploadProgressProps {
  progress: number;
  isVisible: boolean;
  transferFinished?: boolean;
  chunks?: Record<string, ChunkProgress>;
  onProgressUpdate?: (progress: number) => void;
}

const FileUploadProgress: React.FC<FileUploadProgressProps> = ({
  progress,
  isVisible,
  transferFinished = false,
  chunks = {},
  onProgressUpdate
}) => {
  const progressRef = useRef(progress);
  const mountedRef = useRef(true);
  const [smoothProgress, setSmoothProgress] = useState(progress);
  const lastUpdateRef = useRef(Date.now());
  const animationFrameRef = useRef<number>();

  // Calculate actual progress from chunks for more accuracy
  const calculateChunkProgress = () => {
    // Si le transfert est terminé, on retourne 100 directement
    if (transferFinished) {
      return 100;
    }

    // Si pas de chunks, utiliser la progression passée en prop
    if (!Object.keys(chunks).length) return progress;

    let totalLoaded = 0;
    let totalSize = 0;

    Object.values(chunks).forEach(chunk => {
      totalLoaded += chunk.loaded;
      totalSize += chunk.total;
    });

    // Si le total size est 0, mais que le transfert est terminé, on retourne 100
    if (totalSize === 0) {
      return transferFinished ? 100 : 0;
    }

    const calculatedProgress = (totalLoaded * 100) / totalSize;

    // Si on est presque à la fin et que le transfert est terminé, force à 100
    if (calculatedProgress > 90 && transferFinished) {
      return 100;
    }

    // Utiliser la plus grande valeur entre la progression calculée et celle passée en prop
    return Math.max(Math.min(calculatedProgress, 100), progress);
  };

  // Smooth progress animation avec priorité au statut de fin
  const animateProgress = () => {
    if (!mountedRef.current) return;

    const now = Date.now();
    const timeDelta = now - lastUpdateRef.current;
    
    // Calculer la progression cible
    const targetProgress = calculateChunkProgress();
    const currentProgress = progressRef.current;

    // Calculer le pas d'animation
    const step = transferFinished
      ? Math.min(100 - currentProgress, 5) // Plus grand pas pour atteindre 100% rapidement
      : Math.min(Math.abs(targetProgress - currentProgress) * (timeDelta / 500), 2); // Animation plus rapide

    if (Math.abs(targetProgress - currentProgress) > 0.1) {
      const newProgress = currentProgress + (
        targetProgress > currentProgress ? step : -step
      );
      
      progressRef.current = newProgress;
      setSmoothProgress(Math.round(newProgress * 10) / 10);
      lastUpdateRef.current = now;
      
      animationFrameRef.current = requestAnimationFrame(animateProgress);

      onProgressUpdate?.(progressRef.current);
    } else if (transferFinished && currentProgress < 100) {
      // Force à 100% si le transfert est terminé
      progressRef.current = 100;
      setSmoothProgress(100);
      onProgressUpdate?.(100);
    }
  };

  // Effet pour forcer la progression à 100% quand transferFinished devient true
  useEffect(() => {
    if (transferFinished && smoothProgress < 100) {
      progressRef.current = 100;
      setSmoothProgress(100);
    }
  }, [transferFinished]);

  useEffect(() => {
    mountedRef.current = true;
    const targetProgress = calculateChunkProgress();
    
    // Si la progression cible est différente de la progression actuelle, démarrer l'animation
    if (Math.abs(targetProgress - progressRef.current) > 0.1) {
      progressRef.current = progress;
      setSmoothProgress(progress);
      animationFrameRef.current = requestAnimationFrame(animateProgress);
    }

    return () => {
      mountedRef.current = false;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [progress, transferFinished, chunks]);

  if (!isVisible) return null;

  return (
    <div className="flex-1">
      <div className="h-1.5 w-full rounded-full bg-base-100 overflow-hidden">
        <div 
          className={`h-full w-full origin-left transform transition-all duration-300 ease-out
            ${transferFinished ? 'bg-emerald-500' : 'bg-gradient-to-r from-[#2096ff] to-[#05ffa3]'}`}
          style={{ 
            transform: `translateX(${smoothProgress - 100}%)`,
            transition: transferFinished ? 'transform 0.5s ease-out' : undefined 
          }}
        />
      </div>
      <div className="flex justify-between mt-1">
        <span className="text-xs tabular-nums">
          {Math.round(smoothProgress)}%
        </span>
        {transferFinished && (
          <span className="text-xs text-emerald-500">{i18n._("transfer_completed")}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(FileUploadProgress);