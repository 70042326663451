import React, { useEffect, useState } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

export type ToastType = 'success' | 'error';

interface ToastProps {
  type: ToastType;
  message: string;
  isVisible: boolean;
  duration?: number; // Durée en millisecondes
  onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({ 
  type, 
  message, 
  isVisible, 
  duration = 3000, // 3 secondes par défaut
  onClose
}) => {
  const [isShowing, setIsShowing] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let showTimer: NodeJS.Timeout;
    let hideTimer: NodeJS.Timeout;
    let cleanupTimer: NodeJS.Timeout;

    if (isVisible) {
      setShouldRender(true);
      // Petit délai pour permettre l'animation d'entrée
      showTimer = setTimeout(() => {
        setIsShowing(true);
        // Programmer la fermeture
        hideTimer = setTimeout(() => {
          setIsShowing(false);
          // Attendre la fin de l'animation avant de retirer du DOM
          cleanupTimer = setTimeout(() => {
            setShouldRender(false);
            // Réinitialiser isVisible pour permettre une nouvelle animation
            if (onClose) {
              onClose();
            }
          }, 300);
        }, duration);
      }, 10);
    }

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      clearTimeout(cleanupTimer);
    };
  }, [isVisible, duration, onClose]);

  if (!shouldRender) return null;

  const styles = {
    success: {
      bg: 'bg-green-50 dark:bg-green-900/40',
      border: 'border-green-500',
      text: 'text-emerald-700 dark:text-emerald-100',
      icon: 'text-emerald-500 dark:text-emerald-400'
    },
    error: {
      bg: 'bg-red-50 dark:bg-red-900/40',
      border: 'border-red-500',
      text: 'text-red-700 dark:text-red-100',
      icon: 'text-red-500 dark:text-red-400'
    }
  };

  const currentStyle = styles[type];

  return (
    <div 
      className={`fixed bottom-20 right-4 ${currentStyle.bg} ${currentStyle.text} px-6 py-3 rounded-lg shadow-lg flex items-center space-x-2 z-50 border-l-4 ${currentStyle.border} transform transition-all duration-300 ease-in-out ${isShowing ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
    >
      {type === 'success' ? (
        <span className={currentStyle.icon}>✓</span>
      ) : (
        <AlertCircle className={currentStyle.icon} size={20} />
      )}
      <span>{message}</span>
    </div>
  );
};

export default Toast;
