import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import usageApi, { UsageStats } from '../Api/usageApi';

export const useUsageStats = () => {
  const user = useSelector((state: any) => state.user?.user);
  const [stats, setStats] = useState<UsageStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchStats = useCallback(async () => {
    try {
      if (!user?.id) {
        setError('Utilisateur non connecté');
        setLoading(false);
        return;
      }

      setLoading(true);
      const data = await usageApi.getStats();
      if (data) {
        const statsData = {
          currentPeriod: {
            usage: data.currentPeriod?.usage || 0,
            estimatedCost: data.currentPeriod?.estimatedCost || 0
          },
          previousMonth: {
            usage: data.previousMonth?.usage || 0,
            cost: data.previousMonth?.cost || 0
          },
          total: {
            usage: data.total?.usage || 0,
            cost: data.total?.cost || 0
          },
          showCosts: user.role !== 1, // Afficher les coûts seulement pour les non-membres
          admin: data.admin // Inclure les informations de l'admin
        };
        setStats(statsData);
        setError(null);
      } else {
        setStats(null);
        setError('Aucune donnée disponible');
      }
    } catch (err: any) {
      console.error('Error fetching usage stats:', err);
      console.error('Error details:', {
        status: err.response?.status,
        statusText: err.response?.statusText,
        data: err.response?.data,
        message: err.message
      });
      if (err.response?.data?.error) {
        setError(err.response.data.error.message);
      } else if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError(err.message || 'Erreur lors de la récupération des statistiques d\'utilisation');
      }
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      fetchStats();
      // Rafraîchir les données toutes les 5 minutes
      const interval = setInterval(fetchStats, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [user, fetchStats]);

  return {
    stats,
    loading,
    error,
    refresh: fetchStats
  };
};
