interface LogMeta {
  service?: string;
  method?: string;
  [key: string]: any;
}

// Utiliser un singleton pour éviter la création excessive d'instances
class Logger {
  private static instance: Logger;
  private static instances: Map<string, Logger> = new Map();
  private meta: LogMeta;
  private isDev = process.env.NODE_ENV === 'development';
  
  // Limiter le nombre de logs par seconde
  private logThrottleMap: Map<string, number> = new Map();
  private LOG_THROTTLE_LIMIT = 10; // Nombre max de logs identiques par seconde
  private LOG_THROTTLE_WINDOW = 1000; // Fenêtre de 1 seconde

  private constructor(meta: LogMeta = {}) {
    this.meta = meta;
  }

  static getInstance(meta: LogMeta = {}): Logger {
    // Créer une clé unique basée sur les métadonnées
    const key = JSON.stringify(meta);
    
    if (!this.instances.has(key)) {
      // Limiter le nombre d'instances à 20
      if (this.instances.size >= 20) {
        // Supprimer l'instance la plus ancienne
        const oldestKey = this.instances.keys().next().value;
        if (oldestKey !== undefined) {
          this.instances.delete(oldestKey);
        }
      }
      
      this.instances.set(key, new Logger(meta));
    }
    
    return this.instances.get(key)!;
  }

  private formatMessage(level: string, message: string, data?: any) {
    const timestamp = new Date().toISOString();
    
    return {
      timestamp,
      level,
      message,
      data,
      meta: this.meta
    };
  }

  private shouldThrottle(level: string, message: string): boolean {
    const key = `${level}:${message}`;
    const now = Date.now();
    const lastTime = this.logThrottleMap.get(key) || 0;
    
    if (now - lastTime < this.LOG_THROTTLE_WINDOW) {
      // Incrémenter le compteur
      const count = (this.logThrottleMap.get(`${key}:count`) || 0) + 1;
      this.logThrottleMap.set(`${key}:count`, count);
      
      // Si on dépasse la limite, throttle
      if (count > this.LOG_THROTTLE_LIMIT) {
        // Logguer une fois toutes les 100 occurrences quand même
        if (count % 100 === 0) {
          console.warn(`[THROTTLED] Message répété ${count} fois: ${message}`);
        }
        return true;
      }
    } else {
      // Réinitialiser le compteur
      this.logThrottleMap.set(key, now);
      this.logThrottleMap.set(`${key}:count`, 1);
    }
    
    return false;
  }

  info(message: string, data?: any) {
    if (this.shouldThrottle('INFO', message)) return;
    
    const log = this.formatMessage('INFO', message, data);
    console.log(`[${log.timestamp}] [${log.level}] ${message}`, data ? data : '');
  }

  warn(message: string, data?: any) {
    if (this.shouldThrottle('WARN', message)) return;
    
    const log = this.formatMessage('WARN', message, data);
    console.warn(`[${log.timestamp}] [${log.level}] ${message}`, data ? data : '');
  }

  error(message: string, data?: any) {
    // Ne pas throttler les erreurs
    const log = this.formatMessage('ERROR', message, data);
    console.error(`[${log.timestamp}] [${log.level}] ${message}`, data ? data : '');
  }

  debug(message: string, data?: any) {
    if (!this.isDev) return;
    
    if (this.shouldThrottle('DEBUG', message)) return;
    
    const log = this.formatMessage('DEBUG', message, data);
    console.debug(`[${log.timestamp}] [${log.level}] ${message}`, data ? data : '');
  }
}

// Exporter une fonction qui retourne l'instance singleton
export const logger = (meta: LogMeta = {}) => Logger.getInstance(meta);