import React, { useState, useEffect } from "react";
import { Copy, Mail, Share2, ExternalLink } from 'lucide-react';
import Toast from "../Toast/Toast";
import { i18n } from "../../utils";

export interface IActionInputComponentProps {
  value: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  navigateTo?: {
    enabled: boolean;
    cb?: () => void;
  };
  mailTo?: {
    subject?: string;
    body?: string;
    enabled: boolean;
    cb?: () => void;
  };
  onCopy?: (copied: boolean | any) => void;
  customClass?: string;
  explanation_label?: string;
  title?: string;
  expirationDate?: Date;
}

const resetStateTimeout = 1500;
const actionTimeout = 500;

export function ActionInputComponent({
  value,
  navigateTo,
  mailTo,
  onCopy,
  inputProps,
  customClass = "",
  explanation_label,
  title,
  expirationDate,
}: IActionInputComponentProps) {
  const [copied, setCopied] = useState(false);
  const [gone, setGone] = useState(false);
  const [sent, setSent] = useState(false);
  const [shared, setShared] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isShareSupported, setIsShareSupported] = useState(false);
  const inputId = `input_to_copy_${Date.now()}`;
  
  // Référence pour stocker les timeouts actifs
  const timeoutsRef = React.useRef<number[]>([]);

  // Nettoyer tous les timeouts lors du démontage
  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach(window.clearTimeout);
      timeoutsRef.current = [];
    };
  }, []);

  // Vérifier le support du partage natif
  useEffect(() => {
    setIsShareSupported(
      typeof navigator !== 'undefined' && 
      !!navigator.share &&
      !!navigator.canShare
    );
  }, []);

  const handleCopy = (evt: React.FocusEvent<HTMLInputElement>) => {
    const dataDom = evt.currentTarget;
    
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // Utiliser l'API moderne si disponible
        navigator.clipboard.writeText(value).then(() => {
          setCopied(true);
          if (onCopy) {
            onCopy(true);
          } else {
            setShowNotification(true);
          }
          const timeoutId = window.setTimeout(() => {
            setCopied(false);
            dataDom.blur();
            setShowNotification(false);
          }, resetStateTimeout);
          timeoutsRef.current.push(timeoutId);
        });
      } else {
        // Fallback pour les contextes non sécurisés
        const selection = window.getSelection();
        if (!dataDom || !selection) return;
        
        dataDom.select();
        document.execCommand("copy");
        window.getSelection()?.removeAllRanges();
        setCopied(true);
        if (onCopy) {
          onCopy(true);
        } else {
          setShowNotification(true);
        }
        const timeoutId = window.setTimeout(() => {
          dataDom.blur();
          setCopied(false);
          setShowNotification(false);
        }, resetStateTimeout);
        timeoutsRef.current.push(timeoutId);
      }
    } catch (e) {
      if (onCopy) onCopy(e);
    }
  };

  const constructMailToLink = () => {
    const defaultSubject = title ? 
      i18n._("link_copy_subject", [title, value]) :
      i18n._("share_link_title");

    const defaultBody = expirationDate ?
      i18n._("link_copy_message", [
        title || i18n._("share_link_title"),
        expirationDate.toLocaleDateString(),
        value
      ]).split("<br />").join("\n") :
      value;

    const subject = mailTo?.subject || defaultSubject;
    const body = mailTo?.body || defaultBody;
    
    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleShare = async () => {
    if (!isShareSupported) {
      handleMailTo({ preventDefault: () => {}, stopPropagation: () => {} } as any);
      return;
    }

    try {
      const shareText = mailTo?.body || i18n._("share_link_default_message");
      const shareData = {
        title: title || i18n._("share_link_title"),
        text: shareText,
        ...(shareText.includes(value) ? {} : { url: value })
      };

      if (navigator.canShare(shareData)) {
        await navigator.share(shareData);
        setShared(true);
        const timeoutId = window.setTimeout(() => setShared(false), resetStateTimeout);
        timeoutsRef.current.push(timeoutId);
      } else {
        // Fallback si les données ne peuvent pas être partagées
        handleMailTo({ preventDefault: () => {}, stopPropagation: () => {} } as any);
      }
    } catch (error) {
      if ((error as Error)?.name !== 'AbortError') {
        handleMailTo({ preventDefault: () => {}, stopPropagation: () => {} } as any);
      }
    }
  };

  const handleMailTo = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();

    const mailToLink = constructMailToLink();

    setSent(true);
    const actionTimeoutId = window.setTimeout(() => {
      window.open(mailToLink, "_blank");
      if (mailTo?.cb) mailTo.cb();
    }, actionTimeout);
    timeoutsRef.current.push(actionTimeoutId);

    const resetTimeoutId = window.setTimeout(() => {
      try {
        setSent(false);
      } catch (e) {}
    }, resetStateTimeout);
    timeoutsRef.current.push(resetTimeoutId);
  };

  const handleNavigateTo = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();

    setGone(true);
    const actionTimeoutId = window.setTimeout(() => {
      window.open(value, "_blank")?.focus();
      if (navigateTo?.cb) navigateTo.cb();
    }, actionTimeout);
    timeoutsRef.current.push(actionTimeoutId);

    const resetTimeoutId = window.setTimeout(() => {
      try {
        setGone(false);
      } catch (e) {}
    }, resetStateTimeout);
    timeoutsRef.current.push(resetTimeoutId);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      {explanation_label && (
        <span className="block mb-2 text-sm font-medium text-gray-200">
          {explanation_label}
        </span>
      )}

      <div className={`relative w-full ${customClass}`}>
      <input
  id={inputId}
  readOnly
  className={`w-full bg-gray-800/90 text-gray-50 px-4 py-3 pr-[90px] rounded-lg
              border border-gray-600 
              shadow-sm shadow-gray-900/20
              placeholder:text-gray-400
              focus:border-emerald-400 focus:bg-gray-800
              focus:outline-none focus:ring-2 focus:ring-emerald-500/40 
              hover:border-gray-500 hover:bg-gray-750
              transition-all duration-300 ease-in-out
              ${copied ? "bg-gray-700/90 border-emerald-500/50 ring-1 ring-emerald-500/20" : ""}`}
  onFocus={handleCopy}
  {...inputProps}
  value={value}
/>
        
        <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center space-x-2 bg-base-100">
          <span
            onClick={() => handleCopy({ currentTarget: document.getElementById(inputId) as HTMLInputElement } as any)}
            className="cursor-pointer transition-all duration-200 p-1.5 rounded-md hover:bg-gray-700"
            aria-label="Copy"
          >
            <Copy 
              className={`w-5 h-5 ${
                copied ? "test-emerald-500" : "text-gray-400 hover:text-gray-300"
              }`}
            />
          </span>

          {navigateTo?.enabled && (
            <span
              onClick={handleNavigateTo}
              className="cursor-pointer transition-all duration-200 p-1.5 rounded-md hover:bg-gray-700"
              aria-label="Navigate"
            >
              <ExternalLink 
                className={`w-5 h-5 ${
                  gone ? "test-emerald-500" : "text-gray-400 hover:text-gray-300"
                }`}
              />
            </span>
          )}
          
          {/* Icône adaptative selon la plateforme */}
          <span
            onClick={handleShare}
            className="cursor-pointer transition-all duration-200 p-1.5 rounded-md hover:bg-gray-700"
            aria-label="Share"
          >
            {shared || sent ? (
              <Share2 className="w-5 h-5 test-emerald-500 custom-pulse" />
            ) : (
              isShareSupported ? (
                <Share2 className="w-5 h-5 text-gray-400 hover:text-gray-300" />
              ) : (
                <Mail className="w-5 h-5 text-gray-400 hover:text-gray-300" />
              )
            )}
          </span>
        </div>
      </div>

      <Toast
        type="success"
        message={i18n._("link_copied")}
        isVisible={showNotification}
        duration={resetStateTimeout}
        onClose={closeNotification}
      />
    </>
  );
}

export interface NavigationButtonProps {
  value: string;
  customClass?: string;
  onNavigate?: () => void;
}

export function NavigationButton({
  value,
  customClass = "",
  onNavigate
}: NavigationButtonProps) {
  const [gone, setGone] = useState(false);
  const timeoutsRef = React.useRef<number[]>([]);

  // Nettoyer les timeouts lors du démontage
  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach(window.clearTimeout);
      timeoutsRef.current = [];
    };
  }, []);

  const handleNavigateTo = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    setGone(true);
    const actionTimeoutId = window.setTimeout(() => {
      window.open(value, "_blank")?.focus();
      if (onNavigate) onNavigate();
    }, actionTimeout);
    timeoutsRef.current.push(actionTimeoutId);

    const resetTimeoutId = window.setTimeout(() => setGone(false), resetStateTimeout);
    timeoutsRef.current.push(resetTimeoutId);
  };

  return (
    <button
      onClick={handleNavigateTo}
      className={`inline-flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 
                 text-gray-200 rounded-lg transition-colors duration-200 ${customClass}`}
      aria-label="Navigate to link"
    >
      <span className="text-sm">Ouvrir le lien</span>
      <ExternalLink 
        className={`w-4 h-4 ${gone ? "test-emerald-500" : "text-gray-400"}`}
      />
    </button>
  );
}

export default ActionInputComponent;
