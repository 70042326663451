import { useCallback } from 'react';
import { CustomOffer } from '../types/offer';
import { calculatePrice } from '../utils/priceCalculatorClient';

export const useUpgradePrice = () => {
  const calcPrice = useCallback((formValues: CustomOffer, period: 'month' | 'year') => {
    console.log('useUpgradePrice: Starting price calculation');
    console.log('useUpgradePrice: Input values:', { formValues, period });
    return calculatePrice(formValues, period);
  }, []);

  const calculatePriceDifference = useCallback(async (
    currentOffer: CustomOffer,
    newOffer: CustomOffer,
    period: 'month' | 'year' = 'year'
  ): Promise<{
    currentPrice: number;
    newPrice: number;
    difference: number;
  }> => {
    const currentPrice = calcPrice(currentOffer, period);
    const newPrice = calcPrice(newOffer, period);
    
    console.log('Comparaison des prix:', {
      prixActuel: currentPrice,
      nouveauPrix: newPrice,
      difference: newPrice - currentPrice,
      periode: period
    });

    return {
      currentPrice,
      newPrice,
      difference: newPrice - currentPrice
    };
  }, [calcPrice]);

  return {
    calcPrice,
    calculatePriceDifference
  };
};
