import React, { useState, useEffect } from 'react';
import { X, Rocket, Send, BookOpen } from 'lucide-react';
import { images } from '../assets';
import { isMobile, i18n } from '../utils';
import '../Components/HeaderV2/Brand/Brand.css';
import FileVertGuideTooltip from './Overlays/FileVertGuideTooltip';

// Style pour l'animation de la bannière
const style = document.createElement('style');
style.innerHTML = `
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}`;
document.head.appendChild(style);

interface PricingBannerProps {
  onChoice: (choice: 'free' | 'pro' | 'close') => void;
  cookiesAccepted?: boolean;
  isDownloadPage?: boolean;
  isUserConnected?: boolean;
}

const PricingBanner: React.FC<PricingBannerProps> = ({ onChoice, cookiesAccepted = false, isDownloadPage = false, isUserConnected = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    // Si l'utilisateur est connecté, fermer la bannière immédiatement
    if (isUserConnected) {
      handleClose();
      return;
    }
    
    // Sinon, déclencher l'animation après le montage du composant
    setTimeout(() => setIsVisible(true), 100);
  }, [isUserConnected]);

  const handleClose = () => {
    setIsClosing(true);
    // Attendre la fin de l'animation avant de déclencher onChoice
    setTimeout(() => onChoice('close'), 500);
  };

  const handleFreeClick = () => {
    setIsClosing(true);
    // Attendre la fin de l'animation avant de déclencher onChoice
    setTimeout(() => onChoice('free'), 500);
  };

  const handleProClick = () => {
    setIsClosing(true);
    // Attendre la fin de l'animation avant de déclencher onChoice et la redirection
    setTimeout(() => {
      onChoice('pro');
      window.location.href = '/offers';
    }, 500);
  };

  return (
    <>
      {/* Backdrop sombre */}
      <div 
        className="fixed inset-0 bg-black/50 z-[60]"
        onClick={handleClose}
      />
      <div 
        className="fixed bottom-0 left-0 right-0 bg-gray-900/95 backdrop-blur-sm border-t border-gray-800 z-[61]"
        style={{
          animation: isClosing 
            ? 'slideUp 0.5s ease-out reverse forwards'
            : isVisible 
              ? 'slideUp 0.5s ease-out forwards' 
              : 'none',
          transform: 'translateY(100%)',
          opacity: 0
        }}
      >
        <div className={`max-w-7xl mx-auto ${isMobile ? 'p-4' : 'p-6'}`}>
          <button
            onClick={handleClose}
            className={`absolute ${isMobile ? 'top-2 right-2' : 'top-4 right-4'} text-gray-400 hover:text-white`}
          >
            <X className={`${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
          </button>

          <div className={`${isMobile ? 'flex flex-col gap-6' : 'flex items-center gap-12'}`}>
            {/* Left side with logo and slogan */}
            <div className={`${isMobile ? 'w-full' : 'w-1/3'}`}>
              <img
                className={`brand logo ${isMobile ? 'mobile' : 'enlarged'} mb-4`}
                src={images.Logo}
                alt={i18n._('pricingBanner.logoAlt')}
              />
              <div className="space-y-3">
                <p className={`${isMobile ? 'text-base' : 'text-lg'} font-medium text-emerald-400`}>
                  {i18n._('pricingBanner.slogan')}
                </p>
                <p className={`text-gray-300 ${isMobile ? 'text-base' : 'text-lg'}`}>
                  {isDownloadPage
                    ? i18n._('pricingBanner.downloadDescription')
                    : i18n._('pricingBanner.defaultDescription')
                  }
                </p>
              </div>
            </div>

            {/* Right side with cards */}
            <div className={`${isMobile ? 'w-full flex flex-col gap-6' : 'w-2/3 grid grid-cols-2 gap-6'}`}>
              {/* Pro Plan - Affiché en premier sur mobile */}
              <div className={`${isMobile ? 'order-first' : ''} bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl ${isMobile ? 'p-4' : 'p-6'} transform hover:scale-105 transition-transform duration-300 border border-emerald-500`}>
                <div className="flex justify-between items-center mb-4">
                  <h3 className={`${isMobile ? 'text-lg' : 'text-xl'} font-bold text-white`}>
                    {isDownloadPage
                      ? i18n._('pricingBanner.proPlan.title.downloadPage')
                      : i18n._('pricingBanner.proPlan.title.default')
                    }
                  </h3>
                  <Rocket className="text-emerald-400 w-6 h-6" />
                </div>
                
                <div className="space-y-3 mb-6">
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.proPlan.feature1.downloadPage')
                        : i18n._('pricingBanner.proPlan.feature1.default')
                      }
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.proPlan.feature2.downloadPage')
                        : i18n._('pricingBanner.proPlan.feature2.default')
                      }
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.proPlan.feature3.downloadPage')
                        : i18n._('pricingBanner.proPlan.feature3.default')
                      }
                    </p>
                  </div>
                </div>

                <div className="flex items-baseline gap-1 mb-6">
                  <p className={`${isMobile ? 'text-base' : 'text-lg'} font-bold text-white`}>
                    {isDownloadPage
                      ? i18n._('pricingBanner.proPlan.price.downloadPage')
                      : i18n._('pricingBanner.proPlan.price.default')
                    }
                  </p>
                  <span className="text-xs text-gray-400">
                    {isDownloadPage ? "" : i18n._('pricingBanner.proPlan.perMonth')}
                  </span>
                </div>

                <button
                  onClick={handleProClick}
                  className="w-full py-2 px-4 rounded-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-colors"
                >
                  {isDownloadPage
                    ? i18n._('pricingBanner.proPlan.button.downloadPage')
                    : i18n._('pricingBanner.proPlan.button.default')
                  }
                </button>
              </div>

              {/* Free Plan - Affiché en second sur mobile */}
              <div className={`${isMobile ? 'order-last' : ''} bg-gray-800 rounded-xl ${isMobile ? 'p-4' : 'p-6'} transform hover:scale-105 transition-transform duration-300 border border-gray-700`}>
                <div className="flex justify-between items-center mb-4">
                  <h3 className={`${isMobile ? 'text-lg' : 'text-xl'} font-bold text-white`}>{i18n._('pricingBanner.freePlan.title')}</h3>
                  <Send className="text-emerald-400 w-6 h-6" />
                </div>
                
                <div className="space-y-3 mb-6">
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.freePlan.feature1.downloadPage')
                        : i18n._('pricingBanner.freePlan.feature1.default')
                      }
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.freePlan.feature2.downloadPage')
                        : i18n._('pricingBanner.freePlan.feature2.default')
                      }
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full mr-2"></div>
                    <p className="text-sm text-gray-300">
                      {isDownloadPage
                        ? i18n._('pricingBanner.freePlan.feature3.downloadPage')
                        : i18n._('pricingBanner.freePlan.feature3.default')
                      }
                    </p>
                  </div>
                </div>

                <div className="space-y-3">
                  <button
                    onClick={handleFreeClick}
                    className="w-full py-2 px-4 rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                  >
                    {isDownloadPage
                      ? i18n._('pricingBanner.freePlan.button.downloadPage')
                      : i18n._('pricingBanner.freePlan.button.default')
                    }
                  </button>
                  <button
                    onClick={() => {
                      if (cookiesAccepted) {
                        setShowGuide(true);
                      } else {
                        handleFreeClick();
                      }
                    }}
                    className="w-full py-2 px-4 rounded-lg border border-emerald-500/30 text-emerald-500 hover:bg-emerald-500/10 transition-colors flex items-center justify-center gap-2"
                  >
                    <BookOpen className="w-4 h-4" />
                    {i18n._('pricingBanner.freePlan.guideButton')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showGuide && (
        <FileVertGuideTooltip 
          onClose={() => setShowGuide(false)}
          onComplete={() => setShowGuide(false)}
        />
      )}
    </>
  );
};

export default PricingBanner;
