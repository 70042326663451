// src/Components/Loader/Loader.tsx
import React from "react";
import { isMobile } from "../../utils";

interface Props {
  type?: "Dot" | "Spinner" | "network_error";
  percent?: number;
  size?: "small" | "big";
  text?: string;
  color?: "dark" | "light";
  height?: string | number;
  className?: string; // Ajoutez className ici
}

// Détermination dynamique de la taille basée sur la hauteur de la fenêtre
const determineSize = () => {
  const height = window.innerHeight >= 812 ? 170 : 130;
  const width = window.innerHeight >= 812 ? 170 : 130;
  return { height, width };
};

// Composant Spinner
const Spinner: React.FC<{ percent: number; className?: string }> = ({ percent, className }) => {
  const { height, width } = determineSize();
  const radius = height / 2 - 5;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <div className={`mx-auto ${className}`} style={{ width, height }}>
      <div className="relative flex justify-center items-center">
        {/* Pourcentage */}
        <span
          className={`absolute text-base-content ${
            isMobile ? "text-sm" : "text-lg"
          } font-medium`}
        >
          {Math.round(percent)}%
        </span>
        {/* SVG Spinner */}
        <svg
          height={height}
          width={width}
          shapeRendering="geometricPrecision"
          viewBox={`0 0 ${height} ${width}`}
        >
          <defs>
            <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2096ff" />
              <stop offset="100%" stopColor="#05ffa3" />
            </linearGradient>
          </defs>
          {/* Cercle de fond */}
          <circle
            className="stroke-base-300"
            r={radius}
            cx={height / 2}
            cy={width / 2}
            fill="transparent"
            strokeWidth="5"
          />
          {/* Cercle de progression */}
          <circle
            className="transition-all duration-500 ease-in-out"
            stroke="url(#progressGradient)"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            r={radius}
            cx={height / 2}
            cy={width / 2}
            fill="transparent"
            strokeWidth="5"
            transform={`rotate(-90 ${height / 2} ${width / 2})`}
          />
        </svg>
      </div>
    </div>
  );
};

// Composant LoaderBig
const LoaderBig: React.FC<{ text?: string; color?: "dark" | "light"; className?: string }> = ({
  text,
  color,
  className,
}) => {
  const textColor = color === "light" ? "text-white" : "text-gray-800";
  const spanColor = color === "light" ? "bg-white" : "bg-gray-800";

  return (
    <div className={`fixed inset-0 flex flex-col items-center justify-center text-center ${className}`}>
      <div className="flex space-x-2">
        <span className={`block w-4 h-4 rounded-full ${spanColor} custom-pulse`} />
        <span className={`block w-4 h-4 rounded-full ${spanColor} custom-pulse delay-200`} />
        <span className={`block w-4 h-4 rounded-full ${spanColor} custom-pulse delay-400`} />
      </div>
      {text && <p className={`${textColor} mt-4`}>{text}</p>}
    </div>
  );
};

// Composant LoaderNetwork
const LoaderNetwork: React.FC<{ text?: string; className?: string }> = ({ text, className }) => {
  return (
    <div className={`fixed inset-0 flex flex-col items-center justify-center text-center ${className}`}>
      <div className="flex space-x-2">
        <span className="block w-4 h-4 rounded-full bg-white custom-pulse" />
        <span className="block w-4 h-4 rounded-full bg-white custom-pulse delay-200" />
        <span className="block w-4 h-4 rounded-full bg-white custom-pulse delay-400" />
      </div>
      {text && <p className="text-white mt-4">{text}</p>}
    </div>
  );
};

// Composant LoaderSmall
const LoaderSmall: React.FC<{
  text?: string;
  color?: "dark" | "light";
  height?: string | number;
  className?: string;
}> = ({ text, color, height, className }) => {
  const textColor = color === "light" ? "text-white" : "text-gray-800";
  const spanColor = color === "light" ? "bg-white" : "bg-gray-800";

  return (
    <div className={`flex flex-col items-center justify-center ${className}`} style={{ height }}>
      <div className="flex space-x-2">
        <span className={`block w-3 h-3 rounded-full ${spanColor} custom-pulse`} />
        <span className={`block w-3 h-3 rounded-full ${spanColor} custom-pulse delay-200`} />
        <span className={`block w-3 h-3 rounded-full ${spanColor} custom-pulse delay-400`} />
      </div>
      {text && <p className={`${textColor} mt-2`}>{text}</p>}
    </div>
  );
};

// Composant principal LoaderComponent
const LoaderComponent: React.FC<Props> = ({
  type = "Spinner",
  percent = 0,
  size = "big",
  text = "",
  color = "dark",
  height,
  className, // Ajoutez className ici
}) => {
  switch (type) {
    case "Spinner":
      if (percent === undefined) {
        console.warn("Spinner type requires percent prop.");
        return null;
      }
      return <Spinner percent={percent} className={className} />;
    case "network_error":
      return <LoaderNetwork text={text} className={className} />;
    case "Dot":
      if (size === "small") {
        return <LoaderSmall height={height} color={color} text={text} className={className} />;
      }
      return <LoaderBig color={color} text={text} className={className} />;
    default:
      return <LoaderBig color={color} text={text} className={className} />;
  }
};
export { Spinner };
export default LoaderComponent;
