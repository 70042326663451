import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { History, Info, Copy, ExternalLink, Download, Clock, Upload, Check } from 'lucide-react';
import { Transfer } from '../../Constants/defaultTransfer';
import { archiveTransfer, setMinimized, purgeInfos } from '../../Store/Actions/transferAction';
import reduxStore from '../../Store';
import transferApi from '../../Api/transferApi';
import { i18n, convertSize } from '../../utils';
import Toast from '../Toast/Toast';
import MinimizedTransfer from './MinimizedTransfer';

const BASE_URL = process.env.REACT_APP_URL;

interface ExtendedTransfer extends Omit<Transfer, 'progress'> {
  transfer_name?: string;
  downloaded?: number;
  transfer_status?: number;
  created_at?: number;
  archived?: boolean;
  lastSaved?: number;
  backgroundActive?: boolean;
  lastProgressUpdate?: number;
  progress: {
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed: number;
      avgSpeed: number;
      chunkSize: number;
      chunksUploaded: number;
      totalChunks: number;
    };
    last: {
      timestamp: number;
      loaded: number;
    };
  };
}

interface StorageTransfer {
  id: string;
  transfer_name: string;
  link: string;
  expiration_date?: string;
  created_at: number;
}

interface TransferState {
  transfers: { [key: string]: ExtendedTransfer };
  activeTransferId: string | null;
}

interface ReduxState {
  transfer: TransferState;
  user?: {
    user?: {
      id: number;
    };
  };
  _persist?: {
    rehydrated: boolean;
  };
}

type AppDispatch = (action: AnyAction) => void;
interface ButtonUploadHistoryProps {
  user_id?: number;
  transfer: TransferState;
  dispatch: AppDispatch;
}

const LOCAL_STORAGE_KEY = 'filevert_transfer_history';
const TRANSFERS_TO_SHOW = {
  connected: 5,
  nonConnected: 3
};
const TWO_DAYS_MS = 2 * 24 * 60 * 60 * 1000;

const decodeFileName = (name: string): string => {
  if (!name) return '';
  try {
    return name
      .replace(/_5B_/g, '[')
      .replace(/_5D_/g, ']')
      .replace(/\+/g, ' ');
  } catch (e) {
    return name;
  }
};

const truncateText = (text: string, maxLength: number): string => {
  const decodedText = decodeFileName(text);
  if (decodedText.length > maxLength) {
    return decodedText.substring(0, maxLength) + '...';
  }
  return decodedText;
};

const cleanExpiredTransfers = (transfers: ExtendedTransfer[]): ExtendedTransfer[] => {
  const now = Date.now();
  return transfers.filter(transfer => transfer.created_at && now - transfer.created_at < TWO_DAYS_MS);
};

const ButtonUploadHistory: React.FC<ButtonUploadHistoryProps> = ({ user_id, transfer, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [transfers, setTransfers] = useState<ExtendedTransfer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompletionToast, setShowCompletionToast] = useState(false);
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [isStoreReady, setIsStoreReady] = useState(false);
  const isMountedRef = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const updateState = {
    setTransfers: (value: ExtendedTransfer[]): void => {
      if (isMountedRef.current) {
        setTransfers(value);
      }
    },
    setTransfersWithCallback: (callback: (prev: ExtendedTransfer[]) => ExtendedTransfer[]): void => {
      if (isMountedRef.current) {
        setTransfers(prev => callback(prev));
      }
    },
    setBoolean: (setter: (value: boolean) => void, value: boolean): void => {
      if (isMountedRef.current) {
        setter(value);
      }
    }
  };

// Cette fonction vérifie strictement si un transfert est réellement actif
const isTransferReallyActive = (t: ExtendedTransfer): boolean => {
  // Un transfert est actif s'il a eu une mise à jour récente et n'est pas terminé
  const lastUpdate = t.lastProgressUpdate || 0;
  const isRecent = Date.now() - lastUpdate < 30000;
  // Et soit il est verrouillé, soit il est en mode background
  return isRecent && !t.isUploadFinished && !t.purging && (!!t.isLocked || !!t.backgroundActive);
};

  // Initialisation de Redux
  useEffect(() => {
    let mounted = true;
    
    const unsubscribe = reduxStore.store.subscribe(() => {
      const state = reduxStore.store.getState() as ReduxState;
      if (state._persist?.rehydrated && mounted) {
        updateState.setBoolean(setIsStoreReady, true);
        unsubscribe();
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  // Charger les transferts quand le menu est ouvert
  useEffect(() => {
    if (!isOpen) return;

    if (!user_id) {
      try {
        const history = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]') as StorageTransfer[];
        
        // Limiter l'affichage aux 3 premiers transferts
        const limitedHistory = history.slice(0, TRANSFERS_TO_SHOW.nonConnected);
        
        // Convertir StorageTransfer en ExtendedTransfer comme dans getFromLocalStorage
        const convertedTransfers = limitedHistory.map((t: StorageTransfer) => ({
          ...t,
          isLocked: false,
          isUploadFinished: true,
          isMinimized: false,
          loaded: 0,
          totalSize: 0,
          currentFileIndex: 0,
          filesProgress: {},
          startTime: t.created_at,
          totalTime: 0,
          consumption: {
            size: 0,
            gCO2: 0,
            kWh: 0,
            kms: 0
          },
          progress: {
            current: {
              timestamp: Date.now(),
              loaded: 0,
              instantSpeed: 0,
              avgSpeed: 0,
              chunkSize: 0,
              chunksUploaded: 0,
              totalChunks: 0
            },
            last: {
              timestamp: Date.now(),
              loaded: 0
            }
          }
        } as ExtendedTransfer));
        
        updateState.setTransfers(convertedTransfers);
      } catch (error) {
        console.error('Erreur lors de la lecture de l\'historique:', error);
        updateState.setTransfers([]);
      }
      return;
    }

    const completedTransfers = Object.entries(transfer.transfers || {})
      .filter(([_, t]) => t.isUploadFinished)
      .map(([id, t]) => ({
        id,
        transfer_name: t.name || i18n._('unnamed_transfer'),
        link: t.link || `${BASE_URL}/d/${id}`,
        downloaded: 0,
        transfer_status: 1,
        created_at: t.startTime || Date.now(),
        expiration_date: t.expiration_date,
        isMinimized: false,
        isLocked: false,
        isUploadFinished: true,
        loaded: t.loaded || 0,
        totalSize: t.totalSize || 0,
        currentFileIndex: t.currentFileIndex || 0,
        filesProgress: t.filesProgress || {},
        startTime: t.startTime || Date.now(),
        totalTime: t.totalTime || 0,
        consumption: t.consumption || {
          size: 0,
          gCO2: 0,
          kWh: 0,
          kms: 0
        },
        progress: t.progress || {
          current: {
            timestamp: Date.now(),
            loaded: 0,
            instantSpeed: 0,
            avgSpeed: 0,
            chunkSize: 0,
            chunksUploaded: 0,
            totalChunks: 0
          },
          last: {
            timestamp: Date.now(),
            loaded: 0
          }
        }
      } as ExtendedTransfer))
      .sort((a, b) => (b.created_at || 0) - (a.created_at || 0))
      .slice(0, TRANSFERS_TO_SHOW.connected);

    updateState.setTransfers(completedTransfers);
  }, [transfer.transfers, user_id, isOpen]);

  // Détecter la fin d'un transfert et l'archiver après un délai
  useEffect(() => {
    const currentTransferId = transfer.activeTransferId;
    if (!currentTransferId || !isStoreReady) return;

    const currentTransfer = transfer.transfers[currentTransferId];
    if (!currentTransfer) return;

    // Si le transfert vient de se terminer (et n'est pas archivé)
    if (currentTransfer.isUploadFinished && !currentTransfer.archived && !currentTransfer.purging) {
      console.log('[ButtonUploadHistory] Transfer finished:', {
        id: currentTransferId,
        progress: 100
      });
      
      // Archiver le transfert seulement après un délai
      const archiveTimeout = setTimeout(() => {
        if (isMountedRef.current) {
          dispatch(archiveTransfer(currentTransferId, currentTransfer));
        }
      }, 5000);
      
      return () => clearTimeout(archiveTimeout);
    }
  }, [transfer.transfers, transfer.activeTransferId, dispatch, isStoreReady]);

  // Ajouter un transfert terminé à l'historique
  useEffect(() => {
    const currentTransferId = transfer.activeTransferId;
    if (!currentTransferId || !isStoreReady || !isOpen) return;

    const currentTransfer = transfer.transfers[currentTransferId];
    if (!currentTransfer?.isUploadFinished) return;

    updateState.setTransfersWithCallback((prevTransfers) => {
      const transferExists = prevTransfers.some(t => t.id === currentTransferId);
      if (transferExists) return prevTransfers;

      const newTransfer: ExtendedTransfer = {
        id: currentTransferId,
        transfer_name: currentTransfer.name || i18n._('unnamed_transfer'),
        link: currentTransfer.link || `${BASE_URL}/d/${currentTransferId}`,
        downloaded: 0,
        transfer_status: 1,
        created_at: currentTransfer.startTime || Date.now(),
        expiration_date: currentTransfer.expiration_date,
        isMinimized: false,
        isLocked: false,
        isUploadFinished: true,
        loaded: currentTransfer.loaded,
        totalSize: currentTransfer.totalSize,
        currentFileIndex: currentTransfer.currentFileIndex,
        filesProgress: {...currentTransfer.filesProgress},
        startTime: currentTransfer.startTime,
        totalTime: currentTransfer.totalTime,
        consumption: {...currentTransfer.consumption},
        progress: {
          current: {
            ...currentTransfer.progress.current,
            chunkSize: 0,
            chunksUploaded: 0,
            totalChunks: 0
          },
          last: {...currentTransfer.progress.last}
        }
      };

      return [newTransfer, ...prevTransfers].slice(0, TRANSFERS_TO_SHOW.connected);
    });
  }, [transfer.transfers, transfer.activeTransferId, isOpen, isStoreReady, updateState]);

  const minimizedTransfers = Object.entries(transfer.transfers)
  .filter(([_, t]: [string, ExtendedTransfer]) => t.isMinimized && !t.isUploadFinished)
  .map(([id, t]: [string, ExtendedTransfer]) => ({ ...t, id }));

  const handleRestoreTransfer = (transferId: string): void => {
    dispatch(setMinimized(transferId, false));
  };

  const handleCancelTransfer = (transferId: string): void => {
    dispatch(purgeInfos(transferId));
  };

  const getFromLocalStorage = (): ExtendedTransfer[] => {
    try {
      const localTransfers = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]') as StorageTransfer[];
      
      // Convertir en ExtendedTransfer
      const convertedTransfers = localTransfers.map((t: StorageTransfer) => ({
        ...t,
        isLocked: false,
        isUploadFinished: true,
        isMinimized: false,
        loaded: 0,
        totalSize: 0,
        currentFileIndex: 0,
        filesProgress: {},
        startTime: t.created_at,
        totalTime: 0,
        consumption: {
          size: 0,
          gCO2: 0,
          kWh: 0,
          kms: 0
        },
        progress: {
          current: {
            timestamp: Date.now(),
            loaded: 0,
            instantSpeed: 0,
            avgSpeed: 0,
            chunkSize: 0,
            chunksUploaded: 0,
            totalChunks: 0
          },
          last: {
            timestamp: Date.now(),
            loaded: 0
          }
        }
      } as ExtendedTransfer));
      
      // Nettoyer les transferts expirés
      const cleanedTransfers = cleanExpiredTransfers(convertedTransfers);
      
      // Éliminer les doublons en utilisant un Map
      const uniqueTransfersMap = new Map<string, ExtendedTransfer>();
      cleanedTransfers.forEach(transfer => {
        if (!transfer.id) return; // Ignorer les transferts sans ID
        
        // Si l'ID n'existe pas encore dans la map ou si ce transfert est plus récent, l'ajouter
        if (!uniqueTransfersMap.has(transfer.id) ||
            (transfer.created_at || 0) > (uniqueTransfersMap.get(transfer.id)?.created_at || 0)) {
          uniqueTransfersMap.set(transfer.id, transfer);
        }
      });
      
      // Convertir la Map en tableau
      const uniqueTransfers = Array.from(uniqueTransfersMap.values());
      
      // Filtrer, trier et limiter
      const filteredTransfers = uniqueTransfers
        .filter((t: ExtendedTransfer) => t.isUploadFinished);
      
      const sortedTransfers = filteredTransfers
        .sort((a: ExtendedTransfer, b: ExtendedTransfer) => (b.created_at || 0) - (a.created_at || 0));
      
      const limitedTransfers = sortedTransfers
        .slice(0, TRANSFERS_TO_SHOW.nonConnected);
      
      
      // Sauvegarder dans localStorage
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(limitedTransfers));
      console.log('[DEBUG] getFromLocalStorage - Sauvegarde dans localStorage effectuée');
      
      return limitedTransfers;
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique:', error);
      return [];
    }
  };

  const fetchTransferHistory = async (): Promise<void> => {
    updateState.setBoolean(setIsLoading, true);
    try {
      if (user_id) {
        const allTransfers = await transferApi.getAllUserTransfer(user_id);
        if (Array.isArray(allTransfers)) {
          interface ApiTransfer {
            transfer_id: string;
            transfer_name: string;
            transfer_type: number;
            transfer_status: number;
            downloaded: number;
            created_at: string;
          }

          const uniqueTransfers = allTransfers.reduce((acc: ApiTransfer[], transfer: ApiTransfer) => {
            if (transfer &&
                typeof transfer === 'object' &&
                transfer.transfer_id &&
                transfer.transfer_type === 0 &&
                (transfer.transfer_status === 0 || transfer.transfer_status === 1) &&
                !acc.some(t => t.transfer_id === transfer.transfer_id)) {
              acc.push(transfer);
            }
            return acc;
          }, []);

          const recentUploads = uniqueTransfers
            .sort((a: ApiTransfer, b: ApiTransfer) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            )
            .slice(0, TRANSFERS_TO_SHOW.connected)
            .map((transfer: ApiTransfer): ExtendedTransfer => ({
              id: transfer.transfer_id,
              transfer_name: transfer.transfer_name || i18n._('unnamed_transfer'),
              link: `${BASE_URL}/d/${transfer.transfer_id}`,
              downloaded: transfer.downloaded || 0,
              transfer_status: transfer.transfer_status,
              created_at: new Date(transfer.created_at).getTime(),
              isMinimized: false,
              isLocked: false,
              isUploadFinished: true,
              loaded: 0,
              totalSize: 0,
              currentFileIndex: 0,
              filesProgress: {},
              startTime: Date.now(),
              totalTime: 0,
              consumption: {
                size: 0,
                gCO2: 0,
                kWh: 0,
                kms: 0
              },
              progress: {
                current: {
                  timestamp: Date.now(),
                  loaded: 0,
                  instantSpeed: 0,
                  avgSpeed: 0,
                  chunkSize: 0,
                  chunksUploaded: 0,
                  totalChunks: 0
                },
                last: {
                  timestamp: Date.now(),
                  loaded: 0
                }
              }
            }));
          updateState.setTransfers(recentUploads);
        }
      } else {
        // Récupérer les transferts depuis localStorage
        const localTransfers = getFromLocalStorage();
        
        // Éliminer les doublons en utilisant un Map pour conserver uniquement un transfert par ID
        const uniqueTransfersMap = new Map<string, ExtendedTransfer>();
        localTransfers.forEach(transfer => {
          // Ignorer les transferts sans ID
          if (!transfer.id) return;
          
          // Si l'ID n'existe pas encore dans la map ou si ce transfert est plus récent, l'ajouter
          if (!uniqueTransfersMap.has(transfer.id) ||
              (transfer.created_at || 0) > (uniqueTransfersMap.get(transfer.id)?.created_at || 0)) {
            uniqueTransfersMap.set(transfer.id, transfer);
          }
        });
        
        // Convertir la Map en tableau, trier par date et limiter
        const uniqueTransfers = Array.from(uniqueTransfersMap.values())
          .sort((a, b) => (b.created_at || 0) - (a.created_at || 0))
          .slice(0, TRANSFERS_TO_SHOW.nonConnected);
        
        // Mettre à jour le localStorage avec les transferts dédupliqués
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(uniqueTransfers));
        
        updateState.setTransfers(uniqueTransfers);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique des transferts:', error);
      updateState.setTransfers([]);
    } finally {
      updateState.setBoolean(setIsLoading, false);
    }
  };

  const handleClick = (): void => {
    if (!isOpen) {
      fetchTransferHistory();
    }
    updateState.setBoolean(setIsOpen, !isOpen);
  };

  const copyToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      updateState.setBoolean(setShowCompletionToast, true);
      setTimeout(() => updateState.setBoolean(setShowCompletionToast, false), 3000);
    } catch (error) {
      console.error('Erreur lors de la copie:', error);
    }
  };

// Fonction complètement repensée pour la détection des transferts
const { hasActiveTransfer, currentProgress, currentName } = React.useMemo(() => {
  if (!isStoreReady || !transfer?.transfers) {
    return { hasActiveTransfer: false, currentProgress: 0, currentName: '' };
  }

  // Chercher uniquement les transferts RÉELLEMENT actifs
  const activeTransfers = Object.values(transfer.transfers)
    .filter(isTransferReallyActive);

  // S'il y a au moins un transfert actif
  if (activeTransfers.length > 0) {
    // Prendre le plus récent ou celui avec le plus d'activité
    const mostActiveTransfer = activeTransfers.sort((a, b) =>
      (b.lastProgressUpdate || 0) - (a.lastProgressUpdate || 0)
    )[0];
    
    const progress = Math.min(100, Math.round(
      (mostActiveTransfer.loaded * 100) / Math.max(1, mostActiveTransfer.totalSize)
    ));
    
    return {
      hasActiveTransfer: true,
      currentProgress: progress,
      currentName: mostActiveTransfer.name || i18n._('unnamed_transfer')
    };
  }
  
  // Vérifier si nous venons de terminer un transfert il y a moins de 5 secondes
  // Cette partie est uniquement pour l'affichage du nom, pas pour l'animation
  const recentlyFinishedTransfer = Object.values(transfer.transfers)
    .filter(t => t.isUploadFinished && !t.archived && !t.purging &&
      t.lastProgressUpdate && (Date.now() - t.lastProgressUpdate < 5000))
    .sort((a, b) => (b.lastProgressUpdate || 0) - (a.lastProgressUpdate || 0))[0];
  
  if (recentlyFinishedTransfer) {
    return {
      // Important: pas d'animation pour un transfert terminé
      hasActiveTransfer: false,
      currentProgress: 100,  // Mais afficher 100%
      currentName: recentlyFinishedTransfer.name || i18n._('transfer_finished')
    };
  }
  
  // Aucun transfert actif ou récemment terminé
  return {
    hasActiveTransfer: false,
    currentProgress: 0,
    currentName: ''
  };
}, [transfer.transfers, transfer.activeTransferId, isStoreReady]);

  // Gérer l'affichage de l'icône de validation
  useEffect(() => {
    // Vérifier si le transfert actif vient de se terminer
    if (transfer.activeTransferId &&
        transfer.transfers[transfer.activeTransferId]?.isUploadFinished &&
        !transfer.transfers[transfer.activeTransferId]?.archived &&
        !transfer.transfers[transfer.activeTransferId]?.purging &&
        !showCheckIcon) {  // Éviter de réactiver le check si déjà actif
      console.log('[ButtonUploadHistory] Showing check icon');
      setShowCheckIcon(true);

      // Timer strict de 5 secondes pour l'icône de validation
      const timeoutId = setTimeout(() => {
        if (isMountedRef.current) {
          console.log('[ButtonUploadHistory] Hiding check icon');
          setShowCheckIcon(false);
        }
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [transfer.transfers, transfer.activeTransferId, showCheckIcon]);

  // Logger les mises à jour du transfert actif
  useEffect(() => {
    if (hasActiveTransfer) {
      const timeoutId = setTimeout(() => {
        console.log('[ButtonHistory] Active transfer update:', {
          progress: currentProgress,
          name: currentName,
          location: window.location.pathname,
          timestamp: new Date().toISOString()
        });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [hasActiveTransfer, currentProgress, currentName]);

  return (
    <div className="relative">
      <div className="relative group">
        {hasActiveTransfer && currentProgress < 100 && !showCheckIcon && (
          <div className="absolute -inset-4 pointer-events-none flex items-center justify-center">
            <div className="w-full h-full animate-spin rounded-full border-[4px] border-emerald-500 border-t-transparent" role="status" aria-label="loading"></div>
          </div>
        )}
        {minimizedTransfers.length > 0 && (
          <div className="absolute -top-2 -right-2 bg-emerald-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {minimizedTransfers.length}
          </div>
        )}
        <button
          onClick={handleClick}
          className="p-2 rounded-full bg-white text-black hover:bg-emerald-500 dark:hover:bg-emerald-500 transition-colors relative group"
          aria-label={i18n._('upload_history')}
        >
          {hasActiveTransfer && (
           <div className="absolute -bottom-14 left-1/2 transform -translate-x-1/2 bg-black/80 text-white px-3 py-1.5 rounded text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
             <div className="flex flex-col items-center gap-0.5">
               {window.location.pathname === '/' ? (
                 <>
                   <span>{currentName}</span>
                   {currentProgress > 0 && currentProgress < 100 && (
                     <>
                       <span className="text-xs text-emerald-400">{currentProgress}%</span>
                       {(() => {
                         const activeTransfer = transfer.activeTransferId ? transfer.transfers[transfer.activeTransferId] : null;
                         if (activeTransfer?.progress?.current) {
                           return (
                             <span className="text-xs text-gray-400">
                               {Math.round(activeTransfer.progress.current.instantSpeed / 1024)} KB/s
                               {" "}
                               (moy: {Math.round(activeTransfer.progress.current.avgSpeed / 1024)} KB/s)
                             </span>
                           );
                         }
                         return null;
                       })()}
                     </>
                   )}
                 </>
               ) : (
                 <span>{showCheckIcon ? i18n._('transfer_finished') : i18n._('transfer_in_progress')}</span>
               )}
             </div>
           </div>
         )}
          
          {showCheckIcon ? (
            <Check className="w-5 h-5 text-emerald-500 group-hover:text-white" />
          ) : hasActiveTransfer ? (
            <Upload className="w-5 h-5 text-emerald-500 group-hover:text-white" />
          ) : (
            <History className="w-5 h-5 group-hover:text-white" />
          )}
        </button>
      </div>

      <div className="fixed bottom-4 right-4 space-y-2 z-50">
        {minimizedTransfers.map((transfer) => (
          <MinimizedTransfer
            key={transfer.id}
            transfer={transfer}
            onRestore={() => handleRestoreTransfer(transfer.id)}
          />
        ))}
      </div>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white dark:bg-base-300 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700 z-[60]">
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2 dark:text-white flex items-center">
              <Clock className="w-5 h-5 mr-2 text-emerald-500" />
              {i18n._('recent_transfers')}
            </h3>
            {isLoading ? (
              <div className="flex justify-center items-center h-20">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900 dark:border-white" />
              </div>
            ) : transfers.length > 0 ? (
              <ul className="space-y-2">
                {transfers.map((transfer, index) => (
                   <li key={`${transfer.id}_${index}`} className="flex flex-col p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <ExternalLink className="w-4 h-4 text-gray-400" />
                        <a
                          href={transfer.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-emerald-500 dark:text-emerald-500 hover:underline truncate max-w-[140px]"
                        >
                          {truncateText(transfer.transfer_name || '', 20)}
                        </a>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => copyToClipboard(transfer.link || '')}
                          className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
                          aria-label={i18n._('copy_link')}
                        >
                          <Copy className="w-4 h-4" />
                        </button>
                        <button
                          className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
                          aria-label={i18n._('information')}
                          title={transfer.transfer_name}
                        >
                          <Info className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-1 text-xs text-gray-500 dark:text-gray-400">
                      {user_id && (
                        <div className="flex items-center">
                          <Download className="w-3 h-3 mr-1" />
                          {transfer.downloaded || 0} {(transfer.downloaded || 0) > 1 ? i18n._('downloads') : i18n._('download.button.download')}
                        </div>
                      )}
                      {transfer.expiration_date && (
                        <div className="flex items-center">
                          <Clock className="w-3 h-3 mr-1" />
                          {new Date(transfer.expiration_date).toLocaleString([], {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-4">
                {i18n._('history_component_empty')}
              </p>
            )}
          </div>
        </div>
      )}

      <Toast
        type="success"
        message={i18n._('link_copied')}
        isVisible={showCompletionToast}
        onClose={() => setShowCompletionToast(false)}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const typedState = state as {
    transfer: TransferState;
    user?: { user?: { id: number } };
  };
  return {
    transfer: typedState.transfer,
    user_id: typedState.user?.user?.id,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonUploadHistory);