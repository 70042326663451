import React from "react";
import { connect } from "react-redux";
import { FolderUp, FileUp } from "lucide-react";
import { Loader } from "lucide-react";
import { convertSize, i18n } from "../../../../utils";
import "./UploaderInput.css";
import { transferApi } from "../../../../Api";
import { setConnectionQuality } from "../../../../Store/Actions/connectionActions";
import ConnectionQualityBars from "../../../ConnectionQualityBars/ConnectionQualityBars";

interface Props {
  dispatch: Function;
  user: any;
  transfer: any;
  file: any[];
  isFilesLoading: boolean;
  [key: string]: any;
  maximum_size?: number;
  layoutContext?: 'deposit' | 'form';
  standalone?: boolean;
  isMainUploader?: boolean;
  getFilesFromEvent: (event: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => Promise<File[]>;
  onFiles: (files: File[]) => void;
  connectionQuality?: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';
  setConnectionQuality: (quality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown') => void;
}

interface State {
  isSubmited: boolean;
  isFilesLoading: boolean;
  isDragActive: boolean;
  dragCounter: number;
  mountTime: number;
  isMobile: boolean;
}

class UploaderInputComponent extends React.Component<Props, State> {
  private pingInterval: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmited: false,
      isFilesLoading: false,
      isDragActive: false,
      dragCounter: 0,
      mountTime: Date.now(),
      isMobile: window.innerWidth < 640
    };
  }

  componentDidMount() {
    // Ping initial
    this.checkConnectionQuality();
    
    // Ajouter un écouteur pour les changements de taille de fenêtre
    window.addEventListener('resize', this.handleResize);
  }
  
  // Gérer les changements de taille de fenêtre
  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 768 // Utiliser une largeur plus grande pour détecter les vues compactes
    });
  };

  componentDidUpdate(prevProps: Props) {
    const wasInConfigPhase = !prevProps.transfer?.isLocked && prevProps.file?.length > 0;
    const isInConfigPhase = !this.props.transfer?.isLocked && this.props.file?.length > 0;

    // Démarrer le ping régulier quand on entre en phase de configuration
    if (!wasInConfigPhase && isInConfigPhase) {
      this.startRegularPing();
    }
    // Arrêter le ping quand on quitte la phase de configuration
    else if (wasInConfigPhase && !isInConfigPhase) {
      this.stopRegularPing();
    }
  }

  componentWillUnmount() {
    this.stopRegularPing();
    // Supprimer l'écouteur d'événement pour éviter les fuites de mémoire
    window.removeEventListener('resize', this.handleResize);
  }

  private startRegularPing = () => {
    if (!this.pingInterval) {
      // Augmenter l'intervalle à 10 secondes au lieu de 2 secondes
      this.pingInterval = setInterval(this.checkConnectionQuality, 10000);
      console.log("[UploaderInputComponent] Started regular ping with 10s interval");
    }
  }

  private stopRegularPing = () => {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  // Cache pour la qualité de connexion
  private connectionQualityCache = {
    quality: 'unknown' as 'excellent' | 'good' | 'medium' | 'poor' | 'unknown',
    timestamp: 0,
    ttl: 30000 // 30 secondes de TTL
  };

  // Fonction pour obtenir la qualité de connexion avec cache
  private getConnectionQualityWithCache = async (): Promise<'excellent' | 'good' | 'medium' | 'poor' | 'unknown'> => {
    const now = Date.now();
    // Si le cache est valide, utiliser la valeur en cache
    if (now - this.connectionQualityCache.timestamp < this.connectionQualityCache.ttl) {
      return this.connectionQualityCache.quality;
    }
    
    try {
      const result = await transferApi.getConnectionDetails();
      // Mettre à jour le cache
      this.connectionQualityCache = {
        quality: result.quality || 'unknown',
        timestamp: now,
        ttl: 30000
      };
      return result.quality;
    } catch (error) {
      console.error("Erreur lors de la récupération de la qualité de connexion:", error);
      return 'unknown';
    }
  };

  // Stocker la dernière qualité détectée pour éviter les mises à jour inutiles
  private lastDetectedQuality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown' = 'unknown';

  checkConnectionQuality = async () => {
    try {
      // Obtenir la qualité depuis l'API backend avec cache
      const apiQuality = await this.getConnectionQualityWithCache();
      
      // Obtenir la qualité depuis l'API Network Information du navigateur
      const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
      let browserQuality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown' = 'unknown';
      
      if (connection) {
        const { effectiveType, downlink, rtt } = connection;
        
        if (effectiveType === '4g' && downlink >= 10 && (!rtt || rtt < 50)) {
          browserQuality = 'excellent';
        } else if ((effectiveType === '4g' && downlink >= 5) || (effectiveType === '3g' && downlink >= 5 && (!rtt || rtt < 100))) {
          browserQuality = 'good';
        } else if ((effectiveType === '3g' && downlink >= 2) || (effectiveType === '2g' && downlink >= 2 && (!rtt || rtt < 300))) {
          browserQuality = 'medium';
        } else if (effectiveType === '2g' || effectiveType === 'slow-2g' || downlink < 2 || (rtt && rtt >= 300)) {
          browserQuality = 'poor';
        }
      }

      // Déterminer la qualité finale
      let finalQuality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';

      // Si l'API renvoie une qualité, l'utiliser directement
      if (apiQuality) {
        finalQuality = apiQuality;
      } else {
        // Sinon, utiliser la qualité du navigateur comme fallback
        finalQuality = browserQuality;
      }

      // Ne mettre à jour que si la qualité a changé
      if (finalQuality !== this.lastDetectedQuality) {
        console.log(`Connection quality changed: ${this.lastDetectedQuality} -> ${finalQuality}`);
        this.lastDetectedQuality = finalQuality;
        this.props.setConnectionQuality(finalQuality);
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de la qualité de connexion:', error);
      // En cas d'erreur, utiliser uniquement la qualité du navigateur
      const connection = (navigator as any).connection;
      let fallbackQuality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown' = 'unknown';
      
      if (connection) {
        const { effectiveType } = connection;
        fallbackQuality =
          effectiveType === '4g' ? 'good' :
          effectiveType === '3g' ? 'medium' :
          effectiveType === '2g' ? 'poor' : 'unknown';
      }
      
      // Ne mettre à jour que si la qualité a changé
      if (fallbackQuality !== this.lastDetectedQuality) {
        console.log(`Connection quality changed (fallback): ${this.lastDetectedQuality} -> ${fallbackQuality}`);
        this.lastDetectedQuality = fallbackQuality;
        this.props.setConnectionQuality(fallbackQuality);
      }
    }
  }

  handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { getFilesFromEvent, onFiles } = this.props;
    const chosenFiles = await getFilesFromEvent(e);
    onFiles(chosenFiles);
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      dragCounter: prevState.dragCounter + 1,
      isDragActive: true
    }));
  };

  handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      dragCounter: prevState.dragCounter - 1,
      isDragActive: prevState.dragCounter === 1 ? false : prevState.isDragActive
    }));
  };

  handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragActive: false, dragCounter: 0 });

    const { getFilesFromEvent, onFiles } = this.props;
    const chosenFiles = await getFilesFromEvent(e);
    onFiles(chosenFiles);
  };

  renderInputButtons = () => {
    const { file, accept, getFilesFromEvent, onFiles } = this.props;
    const fileInputId = `input-file-${this.props.layoutContext || 'deposit'}`;
    const directoryInputId = `input-directory-${this.props.layoutContext || 'deposit'}`;
    const isForm = this.props.layoutContext === 'form';
    const isCompactView = this.state.isMobile || window.innerWidth < 768;

    // Récupérer la phase actuelle depuis le store Redux
    const currentPhase = this.props.transfer?.currentPhase || 'INITIAL';
    const isConfigurationPhase = currentPhase === 'CONFIGURATION';

    // Déterminer la classe de disposition en fonction de la phase
    // Afficher horizontalement seulement dans la phase de configuration
    const layoutClass = isConfigurationPhase ? 'flex-row' : 'flex-col';

    return (
      <div className="relative w-full h-full flex items-top justify-center z-10">
        <div className={`up-deposit relative shadow-lg bg-base-300/95 border border-white/10 rounded-lg p-3 ${layoutClass} flex gap-3 input-upload-buttons`}>
          {/* Upload Files Button */}
          <div className="group flex uploader-button">
            <label
              htmlFor={fileInputId}
              className="relative flex items-center justify-center btn btn-ghost hover:bg-white h-12 w-12 p-0 transition-all duration-300 ease-out hover:scale-105"
            >
              <FileUp size={24} strokeWidth={1.5} className="transition-transform duration-300 hover:text-black" />
            </label>
            <div className="absolute left-[calc(100%+4px)] overflow-hidden">
              <div className="bg-base-100/80 backdrop-blur-sm shadow-md rounded-lg h-12 flex items-center whitespace-nowrap transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out">
                <span className="px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                  {i18n._("add_files_label")}
               
                </span>
              </div>
            </div>
          </div>

          {/* Upload Directory Button */}
          <div className="group flex uploader-button">
            <label
              htmlFor={directoryInputId}
              className="relative flex items-center justify-center btn btn-ghost hover:bg-white h-12 w-12 p-0 transition-all duration-300 ease-out hover:scale-105"
            >
              <FolderUp size={24} strokeWidth={1.5} className="transition-transform duration-300 hover:text-black" />
            </label>
            <div className="absolute left-[calc(100%+4px)] overflow-hidden">
              <div className="bg-base-100/80 backdrop-blur-sm shadow-md rounded-lg h-12 flex items-center whitespace-nowrap transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out">
                <span className="px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                  {i18n._("add_directory_label")}
                </span>
              </div>
            </div>
          </div>

          {/* Connection Quality - Visible only in configuration phase */}
          {this.props.transfer?.isLocked === false && this.props.file?.length > 0 && (
            <div className="group flex uploader-button">
              <div className="relative flex items-center justify-center h-12 w-12">
                <ConnectionQualityBars quality={this.props.connectionQuality || 'unknown'} />
              </div>
            </div>
          )}
        </div>

        <input
          id={fileInputId}
          type="file"
          className="hidden"
          accept={accept}
          multiple
          onChange={this.handleFileChange}
        />

        <input
          id={directoryInputId}
          type="file"
          className="hidden"
          accept={accept}
          multiple
          onChange={this.handleFileChange}
          {...{
            webkitdirectory: "true",
            mozdirectory: "true",
            directory: "true"
          }}
        />
      </div>
    );
  };

  renderLoadingState = () => {
    if (!this.props.isFilesLoading) return null;

    return (
      <div className="inset-0 flex items-center justify-center">
        <div className="text-center">
          <Loader className="animate-spin" size={24} />
        </div>
      </div>
    );
  };

  render = () => {
    const { layoutContext, isMainUploader } = this.props;
    const { isDragActive } = this.state;

    // Ne pas rendre dans certains contextes
    if (layoutContext === 'form' && !isMainUploader) return null;
    if (layoutContext === 'deposit' && isMainUploader) return null;

    // Classes de conteneur adaptatives selon le contexte
    const containerClass = layoutContext === 'form'
      ? 'h-full bg-base-200/50 rounded-lg'
      : 'relative w-full h-full';

    const dropZoneClass = `h-full ${
      isDragActive ? 'border-2 border-dashed border-primary bg-primary/10 rounded-lg w-full' : ''
    } transition-colors duration-300 ease-in-out`;

    // Récupérer la phase actuelle depuis le store Redux
    const currentPhase = this.props.transfer?.currentPhase || 'INITIAL';
    const isConfigurationPhase = currentPhase === 'CONFIGURATION';

    return (
      <div
        className={`${containerClass} ${dropZoneClass}`}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        aria-label={i18n._("drag_and_drop_area")}
        role="region"
        tabIndex={0}
        data-uploadertype={layoutContext}
        data-phase={currentPhase}
      >
        {this.renderLoadingState()}
        {this.renderInputButtons()}
        
        

        {isDragActive && (
          <div className="absolute rounded-lg inset-0 flex items-center justify-center bg-primary/20 backdrop-blur-sm z-0">
            <p className="text-sm font-semibold text-primary">
              {i18n._("drop_files_here")}
            </p>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : null;
  return {
    user: state.user?.user,
    transfer: activeTransfer,
    file: state.file,
    connectionQuality: state.connection?.quality || 'unknown',
  };
};

const mapDispatchToProps = {
  setConnectionQuality,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderInputComponent);
