import { ReactPlayerProps } from "react-player";
import ReactPlayer from "react-player/lazy";
import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "./Components/Timeline";
import { LoaderComponent } from "../index";
import AudioVisualizer from "./AudioVisualizer";
import { Music } from "lucide-react";
import AnimatedBackground from "./AnimatedBackground";

function openFullscreen(elem: any) {
  elem.setAttribute("controlsList", "nodownload");

  if (elem?.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem?.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem?.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

function closeFullscreen() {
  // try {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //     //@ts-ignore
  //   } else if (document?.webkitExitFullscreen) { /* Safari */
  //     //@ts-ignore
  //     document.webkitExitFullscreen();
  //     //@ts-ignore
  //   } else if (document?.msExitFullscreen) { /* IE11 */
  //     //@ts-ignore
  //     document.msExitFullscreen();
  //   }
  // } catch(e) {}
}

export function FVVideoPlayer({
  setZoomedComment,
  zoomedComment,
  children,
  comments: initialComments,
  onTimeChange,
  onReadyCB,
  type,
  ratio,
  hidden,
  isPlaying,
  setIsPlaying,
  ...props
}: ReactPlayerProps) {
  console.log('FVVideoPlayer: Received props:', { type, isPlaying, hidden });

  const [comments, setComments] = useState<any[]>(initialComments || []);
  const [ready, setReady] = useState<boolean>(false);
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false);
  const [child, setChild] = useState(children);
  const playerRef = useRef<ReactPlayer>(null);
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [playing, setPlaying] = useState<boolean>(isPlaying);
  const [refreshed, setRefreshed] = useState<any>(Date.now());
  const [volume, setVolume] = useState<number>(1);
  const [hoveredMarker, setHoveredMarker] = useState<string | null>(null);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [newCommentPosition, setNewCommentPosition] = useState<{ x: number; y: number } | null>(null);
  const [commentContent, setCommentContent] = useState("");
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  // Ajouter un reset timer pour loadingBuffer
  useEffect(() => {
    // Si loadingBuffer reste bloqué à true, le réinitialiser après un délai
    if (loadingBuffer) {
      const timer = setTimeout(() => {
        console.log("Forcing loadingBuffer reset");
        setLoadingBuffer(false);
      }, 8000); // 8 secondes
      
      return () => clearTimeout(timer);
    }
  }, [loadingBuffer]);

  useEffect(() => {
    setComments(initialComments || []);
  }, [initialComments]);

  useEffect(() => {
    console.log('FVVideoPlayer: zoomedComment changed:', zoomedComment);
    if (zoomedComment && playerRef.current) {
      const comment = comments.find(c => c.id === zoomedComment);
      if (comment && typeof comment.time === 'number') {
        playerRef.current.seekTo(comment.time);
        setCurrentTime(comment.time);
        
        // Ajout d'un délai pour s'assurer que le lecteur a eu le temps de se mettre à jour
        setTimeout(() => {
          const commentElement = document.getElementById(`fv-tl-comment-${zoomedComment}`);
          if (commentElement) {
            commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 100);
      }
    }
  }, [zoomedComment, comments]);

  // Ajout d'un effet pour gérer le défilement même si le commentaire zoomé est le même
  useEffect(() => {
    if (zoomedComment) {
      const commentElement = document.getElementById(`fv-tl-comment-${zoomedComment}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentTime]);

  const handleMarkerClick = (time: number) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time);
      setCurrentTime(time);
    }
  };

  const handleVideoClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!playerRef.current) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;
    
    // Calculate time based on horizontal position
    const time = (clickX / rect.width) * duration;
    
    // Calculate marker position percentages
    const x = Math.min(100, Math.max(0, (clickX / rect.width) * 100));
    const y = Math.min(100, Math.max(0, (clickY / rect.height) * 100));

    // Seek to the clicked time
    playerRef.current.seekTo(time);
    setCurrentTime(time);

    // Set new comment position
    setNewCommentPosition({ x, y });
    setShowCommentForm(true);
    setPlaying(false);
  };

  const handleAddComment = (content: string, e?: React.FormEvent) => {
    e?.preventDefault(); // Empêche le rechargement de la page
    
    if (!newCommentPosition || !currentTime) return;

    const newComment = {
      id: Date.now().toString(),
      content,
      time: currentTime,
      pos: newCommentPosition,
    };

    setComments([...comments, newComment]);
    setShowCommentForm(false);
    setNewCommentPosition(null);
    setCommentContent("");
  };

  const handleDeleteComment = (id: string) => {
    setComments(prev => prev.filter(comment => comment.id !== id));
  };

  const renderVideoMarkers = () => {
    return null; // Les marqueurs sont maintenant gérés dans Timeline.tsx
  };

  useEffect(() => {
    let isMounted = true;
    
    if (isMounted) {
      setMarkers(comments);
    }
    
    return () => {
      isMounted = false;
    };
  }, [comments, zoomedComment]);

  useEffect(() => {
    // Utiliser un flag pour éviter les mises à jour répétées 
    if (isPlaying !== undefined && isPlaying !== playing) {
      setPlaying(isPlaying);
    }
  }, [isPlaying]);

  useEffect(() => {
    let isMounted = true;
    
    if (isMounted) {
      setChild(children);
    }
    
    return () => {
      isMounted = false;
    };
  }, [children]);

  useEffect(() => {
    let isMounted = true;
    let timeout: NodeJS.Timeout;
    
    if (isMounted) {
      setChild(children);
      setRefreshed(null);
      timeout = setTimeout(() => {
        if (isMounted) {
          setRefreshed(() => Date.now());
        }
      }, 400);
    }
    
    return () => {
      isMounted = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [props?.width, children]);

  useEffect(() => {
    let isMounted = true;
    
    if (isMounted) {
      setMarkers(getMarkers(comments));
    }
    
    return () => {
      isMounted = false;
    };
  }, [comments]);

  // const marginLeft = `${(100 - (100 / ratio)) / 2}%`, width = `${100 / ratio}%`;

  const handleFullScreen = (cb?: any) => {
    let elem: any = null;
    
    if (type === "image") {
      elem = document.querySelector('#fv-video-player img');
    } else {
      elem = playerRef.current?.getInternalPlayer();
    }

    if (!elem) {
      console.error('Fullscreen element not found');
      return;
    }

    if (!fullScreen) {
      openFullscreen(elem);
      elem.onfullscreenchange = () => {
        setFullScreen(false);
        elem.onfullscreenchange = null;
        setRefreshed(() => Date.now());
      };
    }
    setFullScreen(() => !fullScreen);
  };

  useEffect(() => {
    let isMounted = true;
    let timeout: NodeJS.Timeout;

    if (isMounted && !fullScreen) {
      setReady(false);
      timeout = setTimeout(() => {
        if (isMounted) {
          setReady(true);
        }
      }, 250);
    }
    if (isMounted) {
      setRefreshed(Date.now());
    }

    return () => {
      isMounted = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [fullScreen]);

  useEffect(() => {
    if (type === "audio" && playerRef.current) {
      setAudioElement(playerRef.current.getInternalPlayer() as HTMLAudioElement);
    }
  }, [type]);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 0,
        display: hidden ? "none" : undefined,
      }}
    >
      {ready && refreshed && child}

      {loadingBuffer && (
        <div
          style={{
            zIndex: 1000,
            position: "absolute",
            left: "50%",
            bottom: "80px", // Au-dessus des contrôles
            transform: "translateX(-50%)",
            background: "rgba(0,0,0, .7)",
            padding: "8px 16px",
            borderRadius: "4px",
            color: "white",
          }}
        >
          Chargement...
        </div>
      )}

      {/* {!ready && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            console.log("Be patient please ...");
            return false;
          }}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            background: "rgba(0,0,0, .6)",
            width: "100%",
            height: "100%",
            display: "flex",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              margin: "auto",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <LoaderComponent size={"small"} />
          </div>
        </div>
      )} */}

      {(type === "video" || type === "audio") && (
       <>
         <div className="relative w-full h-[calc(100vh-90px)]">
           {type === "audio" && <AnimatedBackground isPlaying={playing} />}
           <ReactPlayer
             {...props}
             onProgress={({ playedSeconds }: any) => {
               if (onTimeChange) onTimeChange(playedSeconds);
               setCurrentTime(playedSeconds);
             }}
             onReady={() => {
               console.log("Player is ready");
               if (onReadyCB) onReadyCB();
               setReady(true);
               setLoadingBuffer(false); // Réinitialiser loadingBuffer
             }}
             onBuffer={() => {
               console.log("Buffering started");
               setLoadingBuffer(true);
               
               // Ajouter un timeout de sécurité pour réinitialiser loadingBuffer
               // au cas où onBufferEnd ne serait pas appelé
               setTimeout(() => {
                 setLoadingBuffer(false);
               }, 5000); // 5 secondes maximum de buffering
             }}
             onBufferEnd={() => {
               console.log("Buffering ended");
               setLoadingBuffer(false);
             }}
             // Simplifier les événements de recherche
             onSeek={(seconds) => {
               console.log("Seek to", seconds);
             }}
             onSeeked={() => {
               console.log("Seek completed");
               setLoadingBuffer(false); // S'assurer que le buffer est réinitialisé
             }}
             onDuration={setDuration}
             // MODIFICATION CRITIQUE: Supprimer la condition sur loadingBuffer
             playing={playing} 
             ref={playerRef}
             volume={volume}
             controls={fullScreen}
             controlsList={"nodownload"}
             onPause={() => {
               console.log("Pause event");
               setPlaying(false);
               if (setIsPlaying) setIsPlaying(false);
             }}
             onPlay={() => {
               console.log("Play event");
               setPlaying(true);
               if (setIsPlaying) setIsPlaying(true);
             }}
             onError={(error) => {
               console.error("Player error:", error);
               setReady(true);
               setLoadingBuffer(false); // Réinitialiser en cas d'erreur
               setTimeout(() => {
                 setRefreshed(Date.now())
               }, 250);
             }}
             width="100%"
             height="100%"
           />
           {renderVideoMarkers()}
           {type === "audio" && (
             <>
               {audioElement && <AudioVisualizer audioElement={audioElement} />}
               <div className="absolute inset-0 flex items-center justify-center z-10">
                 <Music size={120} className="text-white opacity-50" />
               </div>
             </>
           )}
         </div>
         <div className="absolute bottom-0 w-full z-50">
           <Timeline
             {...{
               setVolume,
               volume,
               setZoomedComment,
               zoomedComment,
               loadedBuffer: playerRef?.current?.getSecondsLoaded(),
               refreshed,
               playing,
               setPlaying,
               duration,
               currentTime,
               setCurrentTime,
               type,
               fullScreen,
               setFullScreen: handleFullScreen,
             }}
             markers={markers}
             playerRef={playerRef}
           />
         </div>
       </>
     )}

      {type === "image" && (
        <div
          id="fv-video-player"
          className={`d-flex justify-content-center ${props?.className}`}
          data-name={props?.name}
          style={{
            textAlign: "center",
            width: props?.width,
            height: props?.height,
          }}
        >
          <img
            style={{
              display: "flex",
              margin: "auto",
              maxWidth: props?.width,
              maxHeight: props?.height,
            }}
            onLoad={() => {
              setReady(true);
              if (onReadyCB) onReadyCB();
            }}
            src={props?.url as any}
          />
        </div>
      )}
    </div>
  );
}

function getMarkers(comments: any) {
  return comments
    ?.filter(
      ({ status, time }: any) => status !== "close" && typeof time === "number"
    )
    ?.sort((c1: any, c2: any) =>
      typeof c1.time === "number" &&
      typeof c2.time === "number" &&
      c1.time < c2.time
        ? -1
        : 1
    )
    ?.map(({ id, content, username, time }: any) => ({
      id,
      content,
      username,
      time,
    }));
}