// src/Components/CustomModal/CustomModal.tsx
import React from "react";
import { X } from "lucide-react";
import i18n from '../../utils/i18n';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string; // Ajout de la propriété title
  children: React.ReactNode;
  className?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className = "",
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-base-100 rounded-lg shadow-xl w-11/12 max-w-lg ${className}`}
      >
        {title && (
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-lg font-medium">{title}</h3>
            <button
              onClick={onClose}
              className="rounded-md p-2 hover:bg-gray-100"
            >
              <span className="sr-only">{i18n._("close_label")}</span>
              <X
                size={24}
                className="text-gray-600 hover:text-gray-800 transition-transform transform custom-pulse"
              />
            </button>
          </div>
        )}
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
