// src/Store/Actions/uiAction.ts
export const SET_PANEL_STATE = 'SET_PANEL_STATE';

export function setPanelState(value: boolean) {
  return {
    type: SET_PANEL_STATE,
    value
  };
}

// Grouper les actions pour l'export par défaut
const uiAction = {
  setPanelState
};

// Exporter le groupe d'actions
export default uiAction;