import React, { useState } from "react";
import { DepositFormComponent } from "../../../Form";
import { getDepositFormConfig } from "../../../../Services";
import { i18n, isMobile } from "../../../../utils";

export function DepositBoxComponent({
  onDepositFormSubmit,
  user_email,
  isPro,
}: any) {
  const [uploaderEmail, setUploaderEmail] = useState(user_email);
  const [accepted, setAccepted] = useState(false);

  if (accepted) console.log("CGU has been accepted");

  return (
    <>
      <div className="fixed inset-0 bg-black/30 z-[99999]"></div>

      <div
        className={`
          fixed z-[99999] min-h-[400px] h-[400px]
          ${isMobile ? 'w-[90%] left-[5%] top-[10%]' : 'w-[400px] left-1/2 -translate-x-1/2 top-[calc(50%-200px)]'}
          card text-center fv-card-form-deposit
        `}
      >
        <div
          className="modal-header card-header text-centere"
        >
          <h2>{i18n._("fv_deposit_cgu_card_header")}</h2>
        </div>

        <div className="card-body">
          <div>
            <DepositFormComponent
              isPro={isPro}
              onSubmit={async (values: any) => {
                setAccepted(values?.cgu);
                setUploaderEmail(values?.email);
                if (values?.cgu && values?.email) {
                  return new Promise((resolve) => {
                    setTimeout(
                      () => {
                        const result = onDepositFormSubmit({
                          uploader_email: values?.email,
                          accepted: values?.cgu,
                        });
                        resolve(result);
                      },
                      250
                    );
                  });
                }
                return Promise.resolve();
              }}
              {...getDepositFormConfig(uploaderEmail || "")}
            />
          </div>
        </div>

        <div className="card-footer text-center fv-card-form-deposit">
          <span>{i18n._("fv_deposit_cgu_card_footer")}</span>
        </div>
      </div>
    </>
  );
}