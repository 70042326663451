import { CustomOffer } from '../types/offer';

import { Go } from '../Constants/sizeInO';

const roundPrice = (p: number) => Math.round(p * 100) / 100;

const basePrices = {
  upload_ttl: (value: number) => Number(value) + (Number(value) === 7 ? 2 : 5),
  upload_size: (value: number) => {
    const sizeInGo = Number(value) / Go;
    const prices: { [key: number]: number } = {
      2: 0.2,
      5: 0.25,
      10: 0.3,
      20: 0.4,
      50: 0.6,
      100: 0.9,
      200: 1.2
    };
    return prices[Math.floor(sizeInGo)] || prices[200];
  },
  transfer_notification: 0.5,
  transfer_authentication: 0.65,
  transfer_tracking: 1,
  consumption_export: 0.5,
  customization: 0.65,
  outlook_addin: 0.95,
  studio: 1.50
};

const teamSizeRatios = {
  0: 1,
  5: 0.152,
  10: 0.149,
  20: 0.135,
  30: 0.128,
  50: 0.104,
  100: 0.08
};

export const calculatePrice = (
  offer: CustomOffer,
  currentPeriod: 'year' | 'month'
): number => {
  console.log('Calcul du prix avec:', {
    offer,
    currentPeriod
  });

  // Si c'est une offre groupe, retourner le prix fixe
  if (offer.name === 'Groupe' || offer.isGroupeOffer) {
    const price = currentPeriod === 'year' ? 490 : 58.80;
    console.log('Prix offre groupe:', price);
    return price;
  }

  // Calculer le ratio utilisateur
  const teamSize = offer.team_size?.value;
  const ratioPerUser = typeof teamSize === 'number' ? (teamSizeRatios[teamSize as keyof typeof teamSizeRatios] || 1) : 1;
  const qty = typeof teamSize === 'number' ? teamSize : 0;

  // Calculer le prix total en une seule passe
  let uploadTtlPrice = 0;
  let uploadSizePrice = 0;

  const total = Object.entries(offer).reduce((acc, [key, value]: [string, any]) => {
    if (key === 'name' || key === 'period' || key === 'basePrice' || key === 'isGroupeOffer') {
      return acc;
    }

    const val = value?.value;
    if (val && key in basePrices) {
      const basePrice = typeof basePrices[key as keyof typeof basePrices] === 'function'
        ? (basePrices[key as keyof typeof basePrices] as (value: number) => number)(val)
        : basePrices[key as keyof typeof basePrices] as number;

      console.log(`Feature ${key}:`, {
        valeur: val,
        prixBase: basePrice
      });

      if (key === 'upload_ttl') {
        uploadTtlPrice = basePrice;
        return acc;
      }
      
      if (key === 'upload_size') {
        uploadSizePrice = basePrice;
        return acc + basePrice;
      }

      return acc + basePrice;
    }
    return acc;
  }, 0);

  // Ajuster le prix de upload_ttl en fonction de upload_size
  const adjustedUploadTtlPrice = roundPrice(uploadTtlPrice * uploadSizePrice - uploadSizePrice);
  const finalTotal = total + adjustedUploadTtlPrice;

  // Calculer le prix de base avec le multiplicateur d'équipe
  const basePrice = finalTotal * (1 + qty * ratioPerUser);
  
  // Pour un abonnement mensuel, ajouter 20% au prix de base
  const finalPrice = currentPeriod === 'year' ? basePrice : basePrice * 1.2;

  console.log('Calcul final:', {
    prixParFeature: basePrices,
    prixBaseParUser: finalTotal,
    multiplicateurEquipe: 1 + qty * ratioPerUser,
    nombreUsers: qty,
    prixFinal: roundPrice(finalPrice),
    prixMensuel: currentPeriod === 'year' ? basePrice / 12 : finalPrice,
    prixAnnuel: currentPeriod === 'year' ? basePrice : finalPrice * 12,
    periode: currentPeriod,
    détails: {
      prixBase: finalTotal,
      multiplicateurEquipe: 1 + qty * ratioPerUser,
      nombreUtilisateurs: qty
    }
  });

  return roundPrice(finalPrice);
};
