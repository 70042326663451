import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import i18n from '../../utils/i18n';
import { X, ChevronRight } from 'lucide-react';
import { images } from '../../assets';

interface FileVertGuideTooltipProps {
  onClose: () => void;
  onComplete?: () => void;
}

const FileVertGuideTooltip: React.FC<FileVertGuideTooltipProps> = ({ onClose, onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  // Déclaration des traductions en constantes
  const translations = {
    step1: {
      title: i18n._("guide.steps1.title"),
      description: i18n._("guide.steps1.description"),
      imageAlt: i18n._("guide.steps1.image_alt"),
      how: {
        title: i18n._("guide.steps1.how_title"),
        line1: i18n._("guide.steps1.how_line1"),
        line2: i18n._("guide.steps1.how_line2")
      },
      why: {
        title: i18n._("guide.steps1.why_title"),
        line1: i18n._("guide.steps1.why_line1"),
        line2: i18n._("guide.steps1.why_line2")
      }
    },
    step2: {
      title: i18n._("guide.steps2.title"),
      description: i18n._("guide.steps2.description"),
      imageAlt: i18n._("guide.steps2.image_alt"),
      configuration: {
        title: i18n._("guide.steps2.config_title"),
        line1: i18n._("guide.steps2.config_line1"),
        line2: i18n._("guide.steps2.config_line2")
      },
      security: {
        title: i18n._("guide.steps2.security_title"),
        line1: i18n._("guide.steps2.security_line1"),
        line2: i18n._("guide.steps2.security_line2")
      }
    },
    step3: {
      title: i18n._("guide.steps3.title"),
      description: i18n._("guide.steps3.description"),
      imageAlt: i18n._("guide.steps3.image_alt"),
      comparison: {
        title: i18n._("guide.steps3.comparison_title"),
        text: i18n._("guide.steps3.comparison_text"),
        line1: i18n._("guide.steps3.comparison_line1"),
        line2: i18n._("guide.steps3.comparison_line2")
      }
    },
    step4: {
      title: i18n._("guide.steps4.title"),
      description: i18n._("guide.steps4.description"),
      imageAlt: i18n._("guide.steps4.image_alt"),
      line1: i18n._("guide.steps4.line1"),
      line2: i18n._("guide.steps4.line2"),
      line3: i18n._("guide.steps4.line3")
    },
    step5: {
      title: i18n._("guide.steps5.title"),
      description: i18n._("guide.steps5.description"),
      imageAlt: i18n._("guide.steps5.image_alt"),
      line1: i18n._("guide.steps5.line1"),
      line2: i18n._("guide.steps5.line2"),
      line3: i18n._("guide.steps5.line3"),
      mailSubject: i18n._("guide.steps5.mail_subject"),
      mailBody: i18n._("guide.steps5.mail_body"),
      mailButton: i18n._("guide.steps5.mail_button")
    },
    nav: {
      skip: i18n._("guide.nav.skip"),
      back: i18n._("guide.nav.back"),
      continue: i18n._("guide.nav.continue"),
      finish: i18n._("guide.nav.finish")
    }
  };

  const steps = [
    {
      title: translations.step1.title,
      description: translations.step1.description,
      content: (
        <div className="rounded-xl p-6">
          <div className="aspect-video relative mb-6 overflow-hidden rounded-lg">
            <img 
              src={images.TooltipHelpers1} 
              alt={translations.step1.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0"></div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="grid grid-cols-2 gap-4">
              {/* Section "Comment ?" */}
              <div className="p-3 bg-gray-900/30 rounded-lg">
                <div className="text-sm text-emerald-500 mb-1">
                  {translations.step1.how.title}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                    <span className="text-sm text-gray-300">
                      {translations.step1.how.line1}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                    <span className="text-sm text-gray-300">
                      {translations.step1.how.line2}
                    </span>
                  </div>
                </div>
              </div>
              {/* Section "Pourquoi ?" */}
              <div className="p-3 bg-gray-900/30 rounded-lg">
                <div className="text-sm text-emerald-500 mb-1">
                  {translations.step1.why.title}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                    <span className="text-sm text-gray-300">
                      {translations.step1.why.line1}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                    <span className="text-sm text-gray-300">
                      {translations.step1.why.line2}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      title: translations.step2.title,
      description: translations.step2.description,
      content: (
        <div className="bg-gray-800/40 backdrop-blur rounded-xl p-6 border border-gray-700/50">
          <div className="aspect-video relative mb-6 overflow-hidden rounded-lg">
            <img 
              src={images.TooltipHelpers2} 
              alt={translations.step2.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0"></div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {/* Section "Configuration" */}
            <div className="p-3 bg-gray-900/30 rounded-lg">
              <div className="text-sm text-emerald-500 mb-1">
                {translations.step2.configuration.title}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                  <span className="text-sm text-gray-300">
                    {translations.step2.configuration.line1}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                  <span className="text-sm text-gray-300">
                    {translations.step2.configuration.line2}
                  </span>
                </div>
              </div>
            </div>
            {/* Section "Sécurité" */}
            <div className="p-3 bg-gray-900/30 rounded-lg">
              <div className="text-sm text-emerald-500 mb-1">
                {translations.step2.security.title}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                  <span className="text-sm text-gray-300">
                    {translations.step2.security.line1}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                  <span className="text-sm text-gray-300">
                    {translations.step2.security.line2}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      title: translations.step3.title,
      description: translations.step3.description,
      content: (
        <div className="bg-gray-800/40 backdrop-blur rounded-xl p-6 border border-gray-700/50">
          <div className="aspect-video relative mb-6 overflow-hidden rounded-lg">
            <img 
              src={images.TooltipHelpers3} 
              alt={translations.step3.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0"></div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-lg text-white font-semibold">
              {translations.step3.comparison.title}
            </div>
            <div className="text-sm text-gray-400">
              {translations.step3.comparison.text}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                <span className="text-sm text-gray-300">
                  {translations.step3.comparison.line1}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
                <span className="text-sm text-gray-300">
                  {translations.step3.comparison.line2}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      title: translations.step4.title,
      description: translations.step4.description,
      content: (
        <div className="bg-gray-800/40 backdrop-blur rounded-xl p-6 border border-gray-700/50">
          <div className="aspect-video relative mb-6 overflow-hidden rounded-lg">
            <img 
              src={images.TooltipHelpers4} 
              alt={translations.step4.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0"></div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step4.line1}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step4.line2}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step4.line3}
              </span>
            </div>
          </div>
        </div>
      )
    },
    {
      title: translations.step5.title,
      description: translations.step5.description,
      content: (
        <div className="bg-gray-800/40 backdrop-blur rounded-xl p-6 border border-gray-700/50">
          <div className="aspect-video relative mb-6 overflow-hidden rounded-lg">
            <img 
              src={images.TooltipHelpers5} 
              alt={translations.step5.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0"></div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step5.line1}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step5.line2}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 rounded-full bg-emerald-500"></div>
              <span className="text-sm text-gray-300">
                {translations.step5.line3}
              </span>
            </div>
            <a 
              href={`mailto:?subject=${encodeURIComponent(translations.step5.mailSubject)}&body=${encodeURIComponent(translations.step5.mailBody)}`}
              className="mt-4 px-6 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-2"
            >
              {translations.step5.mailButton}
            </a>
          </div>
        </div>
      )
    }
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete();
    }
    handleClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-gradient-to-b from-gray-900/90 to-gray-800/90 flex items-center justify-center p-4 z-[9999]">
      <div className="w-full max-w-2xl mx-auto px-4">
        <div className="bg-gray-800/50 backdrop-blur-lg rounded-2xl shadow-2xl overflow-hidden border border-gray-700">
          {/* Header */}
          <div className="p-6 border-b border-gray-700">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-white">
                {steps[currentStep].title}
              </h2>
              <button
                onClick={handleClose}
                className="p-2 text-gray-400 hover:text-white rounded-full hover:bg-gray-700/50 transition-colors"
              >
                <X size={20} />
              </button>
            </div>
            <p className="text-gray-400 text-sm mt-2">
              {steps[currentStep].description}
            </p>
          </div>

          {/* Contenu principal */}
          <div className="p-0">
            {steps[currentStep].content}
          </div>

          {/* Footer : pagination + navigation */}
          <div className="p-6 bg-gray-900/50 border-t border-gray-700 flex items-center justify-between">
            <div className="flex items-center gap-2">
              {steps.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full transition-colors ${
                    currentStep === index ? 'bg-emerald-500' : 'bg-gray-600'
                  }`}
                />
              ))}
            </div>
            
            <div className="flex gap-3">
              {currentStep === 0 ? (
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-gray-400 hover:text-white text-sm transition-colors"
                >
                  {translations.nav.skip}
                </button>
              ) : (
                <button
                  onClick={handleBack}
                  className="px-4 py-2 text-gray-400 hover:text-white text-sm transition-colors"
                >
                  {translations.nav.back}
                </button>
              )}
              
              <button
                onClick={currentStep === steps.length - 1 ? handleComplete : handleNext}
                className="px-6 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg text-sm font-medium transition-colors flex items-center gap-2"
              >
                {currentStep === steps.length - 1
                  ? translations.nav.finish
                  : translations.nav.continue}
                <ChevronRight size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FileVertGuideTooltip;
