import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { BurgerMenuButton, LangComponent } from "..";
import "./BurgerMenuV2.css";
import {
  Home,
  Send,
  Activity,
  Users,
  UserCircle,
  Settings,
  FileSignature,
  LogOut,
  HelpCircle,
  Mail,
  Play
} from "lucide-react";
import { i18n } from "../../utils";
import { userAction } from "../../Store";

// Constants from Tab.tsx
const ACTIVITY = "activity";
const SEND = "send";
const RECEIVE = "receive";
const COLLAB = "collab";
const SUBSCRIPTION = "subscription";
const ACCOUNT = "account";
const PERSONALIZATION = "personalization";

interface UserPermissions {
  customization?: {
    value: boolean;
  };
  studio?: {
    value: boolean;
  };
}

interface Subscription {
  id: string;
  current_period_end: number;
}

interface User {
  role?: number;
  permissions?: UserPermissions;
  subscriptions?: Subscription[];
}

interface BurgerMenuV2StateProps {
  user: User | null;
}

interface BurgerMenuV2DispatchProps {
  dispatch: any;
}

interface BurgerMenuV2OwnProps {
  light?: boolean;
  onTrigger?: (opened: boolean) => void;
  backdrop?: boolean;
  className?: string;
}

type BurgerMenuV2Props = BurgerMenuV2StateProps & 
  BurgerMenuV2DispatchProps & 
  BurgerMenuV2OwnProps & 
  RouteComponentProps;

const BurgerMenuV2Component: React.FC<BurgerMenuV2Props> = ({
  light,
  onTrigger,
  backdrop,
  user,
  className,
  location,
  dispatch
}) => {
  const [opened, setOpened] = useState(false);
  const [availableTabs, setAvailableTabs] = useState<string[]>([]);

  useEffect(() => {
    if (location.pathname === "/contact" || location.hash === "#") {
      return;
    }

    setOpened(false);
    if (onTrigger) onTrigger(false);
  }, [location, onTrigger]);

  useEffect(() => {
    if (onTrigger) onTrigger(opened);
  }, [opened, onTrigger]);

  useEffect(() => {
    let tabs: string[] = [];
    if (!user?.role || user.role < 2) {
      tabs = [ACTIVITY, SEND, SUBSCRIPTION, ACCOUNT];
    } else {
      tabs = [ACTIVITY, SEND, RECEIVE, SUBSCRIPTION, ACCOUNT];
    }

    if (user?.permissions?.customization?.value) {
      tabs.push(PERSONALIZATION);
    }

    if (user?.permissions?.studio?.value) {
      const sendIndex = tabs.indexOf(SEND);
      if (sendIndex !== -1) {
        tabs.splice(sendIndex + 1, 0, COLLAB);
      }
    }

    setAvailableTabs(tabs);
  }, [user]);

  const getIcon = (tab: string) => {
    const iconProps = {
      size: 20,
      className: light ? "text-gray-600" : "text-gray-300"
    };

    switch (tab) {
      case ACTIVITY:
        return <Home {...iconProps} />;
      case SEND:
        return <Send {...iconProps} />;
      case RECEIVE:
        return <Activity {...iconProps} />;
      case COLLAB:
        return <Users {...iconProps} />;
      case SUBSCRIPTION:
        return <FileSignature {...iconProps} />;
      case ACCOUNT:
        return <UserCircle {...iconProps} />;
      case PERSONALIZATION:
        return <Settings {...iconProps} />;
      default:
        return null;
    }
  };

  const getTabUrl = (tab: string): string => {
    const baseUrl = window.location.origin;
    if (tab === COLLAB) {
      return `${baseUrl}/collab`;
    }
    return `${baseUrl}/profil?tab=${tab}`;
  };

  const menuItemClass = `flex items-center text-left w-full gap-3 py-2.5 rounded-lg transition-colors ${
    light ? "text-gray-700 hover:bg-gray-100" : "text-white hover:bg-gray-800"
  }`;

  return (
    <div className={className}>
      {backdrop && (
        <div
          className={`fv-burger-menu-backdrop fv_burger_menu_backdrop ${
            opened ? "open" : "close"
          }`}
          onClick={() => setOpened(false)}
        />
      )}

      <BurgerMenuButton
        opened={opened}
        setOpened={setOpened}
        light={light}
        onClick={setOpened}
        backdrop={true}
      />

      <div
        className={`fv-burger-menu-wrapper z-40 ${light ? "light" : ""} ${
          opened && "opened"
        } fv_burger_menu_wrapper overflow-y-auto`}
      >
        <div className="flex flex-col h-full">
          {/* Language Selector */}
          <div className="absolute left-4 top-4">
            <LangComponent
              textClass={`hover:opacity-80 transition-opacity`}
              dispatch={dispatch}
            />
          </div>
          {/* Navigation Links */}
          <div className="flex-1 p-2 flex flex-col gap-1">
        

            {/* Tab links */}
            {availableTabs.map((tab) => (
              <a
                key={tab}
                href={getTabUrl(tab)}
                className={menuItemClass}
                onClick={() => setOpened(false)}
              >
                {getIcon(tab)}
                <span className="text-sm font-medium">
                  {i18n._(`profil_${tab}_title`)}
                </span>
              </a>
            ))}
          </div>

          {/* Support and Contact Links */}
          {/* Start Collab Button */}
          {user?.permissions?.studio?.value && (
            <div className="p-2 border-t border-gray-200 dark:border-gray-700">
              <a
                href={`${window.location.origin}/collab`}
                className={menuItemClass}
                onClick={() => setOpened(false)}
              >
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gradient-to-r from-purple-500 to-purple-600">
                  <Play size={18} className="text-white ml-0.5" />
                </div>
                <span className="text-sm font-medium">Démarrer Collab</span>
              </a>
            </div>
          )}

          {/* Support and Contact Section */}
          <div className="p-2 border-t border-gray-200 dark:border-gray-700 flex flex-col gap-1">
            <a
              href={`${window.location.origin}/support`}
              className={menuItemClass}
              onClick={() => setOpened(false)}
            >
              <HelpCircle size={20} className={light ? "text-gray-600" : "text-gray-300"} />
              <span className="text-sm font-medium">Support</span>
            </a>
            
            <a
              href={`${window.location.origin}/contact`}
              className={menuItemClass}
              onClick={() => setOpened(false)}
            >
              <Mail size={20} className={light ? "text-gray-600" : "text-gray-300"} />
              <span className="text-sm font-medium">Contact</span>
            </a>
          </div>

          {/* Bottom Action Button */}
          <div className="p-2 border-t border-gray-200 dark:border-gray-700">
            <button
              onClick={() => {
                dispatch(userAction.logoutUser({}));
                setOpened(false);
              }}
              className={menuItemClass}
            >
              <LogOut size={20} className={light ? "text-gray-600" : "text-gray-300"} />
              <span className="text-sm font-medium">
                {i18n._("logout_label")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any): BurgerMenuV2StateProps => ({
  user: state.user?.user || null
});

export const BurgerMenuV2 = connect<
  BurgerMenuV2StateProps,
  BurgerMenuV2DispatchProps,
  BurgerMenuV2OwnProps
>(mapStateToProps)(withRouter(BurgerMenuV2Component));