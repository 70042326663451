import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop, { Crop, PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const MIN_WIDTH = 1920;
const MAX_WIDTH = 3840; // Largeur maximale 4K
const MAX_ADJUSTMENT = 0.7; // Valeur maximale d'ajustement (assombrissement ou éclaircissement)

interface Ratio {
  aspect: number;
  label: string;
}

interface AppProps {
  input?: React.InputHTMLAttributes<HTMLInputElement>;
  onLoadCb?: () => void;
  ratios?: Ratio[];
  originImg?: string;
  canvasId?: string;
  automatic?: boolean;
  onSave?: (croppedImage: string) => void;
}

export default function App({
  input,
  onLoadCb,
  ratios = [{ aspect: 16 / 9, label: "16/9" }],
  originImg,
  canvasId,
  automatic,
  onSave,
}: AppProps) {
  const defaultCrop: Crop = {
    unit: "%",
    width: 100,
    height: 30,
    x: 0,
    y: 0,
  };

  const [upImg, setUpImg] = useState<string | undefined>(originImg);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [crop, setCrop] = useState<Crop>(defaultCrop);
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [croppedDimensions, setCroppedDimensions] = useState<{ width: number; height: number } | null>(null);
  const [adjustment, setAdjustment] = useState<number>(0); // 0 est neutre, négatif pour éclaircir, positif pour assombrir

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const maxSize = 3.5 * 1024 * 1024; // 3.5 MB en octets

      if (file.size > maxSize) {
        console.error(`La taille du fichier (${(file.size / 1024 / 1024).toFixed(2)} MB) dépasse la limite de 3.5 MB`);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setUpImg(reader.result as string);
        // Réinitialiser l'ajustement
        setAdjustment(0);
      });
      reader.readAsDataURL(file);
    }
  };

  const onLoad = useCallback(
    (img: HTMLImageElement) => {
      imgRef.current = img;

      if (onLoadCb) onLoadCb();

      const aspect = ratios[0].aspect;
      const width = 100;
      const height = Math.min((100 / aspect) * (img.height / img.width), 100);

      setCrop({
        unit: "%",
        width,
        height,
        x: 0,
        y: 0,
      });
    },
    [onLoadCb, ratios]
  );

  const getAutoCrop = useCallback((): Crop => {
    if (!imgRef.current) return defaultCrop;

    const img = imgRef.current;
    const aspect = ratios[0].aspect;
    const width = Math.min(img.width, MAX_WIDTH);
    const height = Math.round(width / aspect);

    return {
      unit: "px",
      width,
      height,
      x: 0,
      y: Math.max(0, (img.height - height) / 2), // Centre verticalement
    };
  }, [ratios, defaultCrop]);

  const cropImage = useCallback(() => {
    if (!previewCanvasRef.current || !imgRef.current) {
      console.log("Canvas or image reference not available");
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.log("Canvas context not available");
      return;
    }

    const cropToUse = completedCrop || getAutoCrop();
    console.log("Crop being used:", cropToUse);

    console.log(`Image naturelle : ${image.naturalWidth}x${image.naturalHeight}`);
    console.log(`Image affichée : ${image.width}x${image.height}`);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropX = (cropToUse.x || 0) * scaleX;
    const cropY = (cropToUse.y || 0) * scaleY;
    const cropWidth = (cropToUse.width || image.width) * scaleX;
    const cropHeight = (cropToUse.height || image.height) * scaleY;

    console.log(`Crop réel : ${cropWidth}x${cropHeight}`);

    // Utiliser la largeur du crop, avec un minimum de 1920 et un maximum de 3840 (4K)
    const finalWidth = Math.min(Math.max(cropWidth, MIN_WIDTH), MAX_WIDTH);
    const aspectRatio = cropWidth / cropHeight;
    const finalHeight = Math.round(finalWidth / aspectRatio);

    console.log(`Dimensions finales : ${finalWidth}x${finalHeight}`);

    canvas.width = finalWidth;
    canvas.height = finalHeight;

    ctx.imageSmoothingQuality = "high";

    try {
      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        finalWidth,
        finalHeight
      );
      console.log(`Image croppée dessinée sur le canvas : ${finalWidth}x${finalHeight}`);
    } catch (error) {
      console.error("Erreur lors du dessin de l'image sur le canvas:", error);
    }

    // Vérification des pixels pour détecter si l'image est entièrement bleue
    const imageData = ctx.getImageData(0, 0, finalWidth, finalHeight);
    const data = imageData.data;
    let isAllBlue = true;
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] !== 0 || data[i + 1] !== 0 || data[i + 2] !== 255) {
        isAllBlue = false;
        break;
      }
    }
    console.log(`L'image est-elle entièrement bleue ? ${isAllBlue}`);

    // Appliquer l'ajustement (assombrissement ou éclaircissement)
    if (adjustment !== 0) {
      ctx.fillStyle = adjustment > 0
        ? `rgba(0, 0, 0, ${Math.abs(adjustment) * MAX_ADJUSTMENT})`
        : `rgba(255, 255, 255, ${Math.abs(adjustment) * MAX_ADJUSTMENT})`;
      ctx.globalCompositeOperation = adjustment > 0 ? 'darken' : 'lighten';
      ctx.fillRect(0, 0, finalWidth, finalHeight);
    }

    console.log(`Image croppée aux dimensions : ${finalWidth}x${finalHeight}`);
    setCroppedDimensions({ width: finalWidth, height: finalHeight });

    return canvas.toDataURL('image/jpeg');
  }, [completedCrop, adjustment, getAutoCrop]);

  const [shouldCrop, setShouldCrop] = useState(false);

  useEffect(() => {
    if (imgRef.current && completedCrop) {
      setShouldCrop(true);
    }
  }, [completedCrop]);

  useEffect(() => {
    if (shouldCrop) {
      cropImage();
      setShouldCrop(false);
    }
  }, [shouldCrop, cropImage]);

  const handleSave = useCallback(() => {
    console.log("Save button clicked");
    const croppedImageUrl = cropImage();
    if (onSave && croppedImageUrl) {
      console.log("Saving cropped image");
      onSave(croppedImageUrl);
    } else {
      console.log("Unable to save cropped image");
    }
  }, [cropImage, onSave]);

  return (
    <div className="App text-center">
      <div className="my-4">
        {input ? (
          <input {...input} onChange={onSelectFile} />
        ) : (
          <input
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            className="border border-gray-300 rounded px-4 py-2"
          />
        )}
      </div>

      <div style={{ position: 'relative' }}>
        <ReactCrop
          src={upImg || ""}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c: Crop) => setCrop(c)}
          onComplete={(c: Crop) => setCompletedCrop(c)}
          className="mx-auto"
          style={{ maxHeight: "70vh" }}
          imageStyle={{ maxHeight: "70vh" }}
        />
        {upImg && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: adjustment > 0
                ? `rgba(0, 0, 0, ${Math.abs(adjustment) * MAX_ADJUSTMENT})`
                : `rgba(255, 255, 255, ${Math.abs(adjustment) * MAX_ADJUSTMENT})`,
              pointerEvents: 'none',
              mixBlendMode: adjustment > 0 ? 'multiply' : 'screen',
            }}
          />
        )}
      </div>

      <div className="flex justify-center items-center space-x-2 my-4">
        <span>Éclaircir</span>
        <input
          type="range"
          min={-100}
          max={100}
          value={adjustment * 100}
          onChange={(e) => {
            const newAdjustment = Number(e.target.value) / 100;
            setAdjustment(newAdjustment);
            console.log(`Ajustement: ${newAdjustment}`);
          }}
          className="range range-xs"
        />
        <span>Assombrir</span>
      </div>

      <div className="flex justify-center space-x-2 my-4">
        {ratios.length > 1 &&
          ratios.map((ratio: Ratio) => (
            <button
              key={`btn_logo_${ratio.aspect}`}
              onClick={() => {
                if (crop.aspect === ratio.aspect) return;
                const mult = ratio.aspect === 1 ? crop.aspect || 1 : ratio.aspect;
                const newHeight =
                  (crop.height || 0) / mult;
                const newCrop: Crop = {
                  ...crop,
                  aspect: ratio.aspect,
                  height: newHeight,
                };
                setCrop(newCrop);
                setCompletedCrop(newCrop);
              }}
              className={`px-4 py-2 rounded ${
                crop.aspect === ratio.aspect
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              {ratio.label}
            </button>
          ))}
      </div>

      {onSave && (
        <div className="my-4">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Enregistrer
          </button>
        </div>
      )}

      <div className="hidden">
        <canvas
          id={canvasId}
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            borderColor: "black",
            borderWidth: 2,
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
    </div>
  );
}
