import React, { useEffect, useState } from "react";
import { i18n } from "../../utils";

export function LandingCard() {
  const [cards, setCards] = useState<any[]>([]);

  useEffect(() => {
    if (i18n?.isReady) {
      setCards(i18n._("collab_landing.cards"));
    }
  }, [i18n?.isReady]);

  return (
    <div className="bg-gradient-to-b from-base-300 to-base-200 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {cards?.map(({ title, content }: any, index: number) => (
            <div
              key={`LandingCard_${index}`}
              className="group animate-fade-in"
              style={{
                animationDelay: `${index * 150}ms`
              }}
            >
              <div className="h-full bg-base-200 rounded-xl p-8 border border-purple-500 border-opacity-10 shadow-lg transition-all duration-300 hover:shadow-primary/5 hover:border-opacity-20 hover:bg-base-300">
                <div className="flex items-start gap-4 mb-6">
                  <div className="flex-shrink-0">
                    <div className="w-12 h-12 rounded-lg bg-purple-500 bg-opacity-10 flex items-center justify-center text-purple-500 font-semibold text-lg group-hover:scale-110 transition-transform duration-300">
                      {index + 1}
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-base-content tracking-tight">
                    {title}
                  </h3>
                </div>
                
                <div
                  className="text-base-content text-lg opacity-75 leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LandingCard;