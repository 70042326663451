import React from 'react';

interface AnimatedBackgroundProps {
  isPlaying: boolean;
}

const AnimatedBackground: React.FC<AnimatedBackgroundProps> = ({ isPlaying }) => {
  return (
    <div className={`absolute inset-0 z-0 ${isPlaying ? 'custom-pulse' : ''}`}>
      <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-500 opacity-30"></div>
      <div className="absolute inset-0 bg-gradient-radial from-transparent to-black opacity-50"></div>
    </div>
  );
};

export default AnimatedBackground;