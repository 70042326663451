import React, { FormEvent, FocusEvent, useState, useEffect } from "react";
import {
  InputCheckboxComponent,
  InputEmailComponent,
  InputPasswordComponent,
  InputTextComponent,
} from "./Input";
import { i18n, onClickSubmit } from "../../utils";
import { Loader } from "lucide-react";

interface CustomOfferFormProps {
  onSubmit: (data: any) => Promise<any>;
  first_name: any;
  last_name: any;
  email: any;
  organization: any;
  job: any;
  vat_number: any;
  password: any;
  allowInvoice?: boolean; // Nouvelle prop pour indiquer si l'utilisateur a la métadonnée allow_invoice dans Stripe
}

export function CustomOfferFormComponent({ onSubmit, allowInvoice: propAllowInvoice, ...rest }: CustomOfferFormProps) {
  const [ctaDisabled, setCtaDisabled] = useState(true);
  const [pwdValid, setPwdValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isInvoicePayment, setIsInvoicePayment] = useState(false);
  
  // Vérifier si le paiement par facture est autorisé
  useEffect(() => {
    // Vérifier à la fois la prop et le localStorage
    // La prop a priorité car elle vient directement des métadonnées Stripe
    const localStorageAllowInvoice = localStorage.getItem('allow_invoice') === 'true';
    const finalAllowInvoice = propAllowInvoice !== undefined ? propAllowInvoice : localStorageAllowInvoice;
    
    setIsInvoicePayment(finalAllowInvoice);
    console.log('Paiement par facture autorisé:', finalAllowInvoice, 'prop:', propAllowInvoice, 'localStorage:', localStorageAllowInvoice);
  }, [propAllowInvoice]);
  const {
    first_name,
    last_name,
    email,
    organization,
    job,
    vat_number,
    password,
  } = rest;

  const updateSubmitBtnState = (pwd?: string, confirmPwd?: string) => {
    if (pwd && confirmPwd) {
      setPwdValid(pwd.length > 0 && pwd === confirmPwd);
    }
    if (!pwdValid) {
      if (!ctaDisabled) setCtaDisabled(true);
      return;
    }
    // Correction: Vérifier les erreurs correctement
    if (
      first_name?.currentError ||
      last_name?.currentError ||
      email?.currentError ||
      organization?.currentError ||
      job?.currentError ||
      vat_number?.currentError ||
      password?.currentError
    ) {
      setCtaDisabled(true);
      return;
    }

    setCtaDisabled(false);
  };

  const handleFormBlur = (event: FocusEvent<HTMLFormElement>) => {
    updateSubmitBtnState();
  };

  return (
    <>
      <div className="p-6">
        <form
          onBlur={handleFormBlur}
          onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            evt.currentTarget.classList.add("was-validated");

            const inputs = Array.from(
              evt.currentTarget.querySelectorAll("input")
            ) as HTMLInputElement[];
            const hasErrors = inputs.some(input => !input.checkValidity());

            if (!hasErrors && onSubmit) {
              setSubmitting(true);
              // S'assurer que l'email est toujours inclus dans formData
              // Récupérer l'email depuis l'objet email passé en props
              const emailValue = email?.value;
              console.log('Email from props:', emailValue);

              const formData = inputs.reduce((acc, cur) => {
                // Exclure les champs spéciaux
                if (cur.name && cur.name !== "confirm_password" && cur.name !== "cgu") {
                  // Pour les champs non-email, vérifier la valeur
                  if (cur.name !== "email" && !cur.value) {
                    return acc;
                  }
                  acc[cur.name] = cur.type === "checkbox" ? cur.checked : cur.value;
                }
                return acc;
              }, {
                // Toujours inclure l'email depuis les props
                email: emailValue
              } as Record<string, any>);

              console.log('Final form data:', formData);

              console.log('Form data being submitted:', formData);

              const submitErrors = await onSubmit(formData);

              if (submitErrors?.field) {
                setSubmitting(false);

                switch (submitErrors.field) {
                  case "email":
                    email.setCurrentError(submitErrors.reason);
                    break;
                  case "vat_number":
                    vat_number.setCurrentError(submitErrors.reason);
                    break;
                  default:
                    break;
                }
              } else {
                // Réinitialiser l'état après une soumission réussie
                setSubmitting(false);
              }
            }
          }}
          id="custom_offer_form"
          className="space-y-4"
          noValidate
        >
          <div>
            <InputEmailComponent inputObject={email} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            
            <div>
              <InputTextComponent inputObject={first_name} />
            </div>
            <div>
              <InputTextComponent inputObject={last_name} />
            </div>
          </div>

          <div>
            <InputPasswordComponent
              onChangeCb={updateSubmitBtnState}
              {...password.getRawInput()}
            />
          </div>

          <div>
            <InputTextComponent inputObject={organization} />
          </div>

          <div>
            <InputTextComponent inputObject={job} />
          </div>

          <div>
            <InputTextComponent inputObject={vat_number} />
          </div>

          

          <div>
            <InputCheckboxComponent
              id="cgu_input"
              label={i18n._("accept_cgu_label")}
              name="cgu"
              defaultChecked={false}
              required
            />
          </div>

          {/* Champ de soumission caché */}
          <input type="submit" hidden />

        </form>
      </div>

      <div className="flex justify-center p-4 border-t">
        <button
          disabled={ctaDisabled || submitting}
          form="custom_offer_form"
          type="submit"
          onClick={onClickSubmit}
          className={`w-full bg-primary text-primary-content py-3 rounded-md mb-3 transition-all duration-300 hover:bg-primary-focus flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed`}
        >
          {!submitting ? (
            isInvoicePayment ? i18n._("btn_pay_by_invoice") : i18n._("btn_valid_label")
          ) : (
            <Loader className="w-5 h-5 animate-spin" />
          )}
        </button>
      </div>
    </>
  );
}
