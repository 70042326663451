import { UploadPhase } from '../../Constants/UploadPhases';

// Action Types

export const SET_INFOS = "SET_INFOS";
export const SET_PREVIEWS = "SET_PREVIEWS";
export const LOCK_TRANSFER = "LOCK_TRANSFER";
export const PURGE = "PURGE";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const SET_MINIMIZED = "SET_MINIMIZED";
export const RESTORE_TRANSFER_STATE = "RESTORE_TRANSFER_STATE";
export const RESTORE_TRANSFER = "RESTORE_TRANSFER";
export const MIGRATE_TRANSFERS = "MIGRATE_TRANSFERS";
export const ARCHIVE_TRANSFER = "ARCHIVE_TRANSFER";
export const UPDATE_HISTORY = "UPDATE_HISTORY";
export const RESET_TRANSFER_PROGRESS = "RESET_TRANSFER_PROGRESS";
export const RESET_TRANSFER_STATE = "RESET_TRANSFER_STATE";
export const SET_PHASE = "SET_PHASE"; // Nouvelle action pour gérer les phases

export interface TransferProgress {
  current: { 
    timestamp: number; 
    loaded: number; 
    instantSpeed: number;
    avgSpeed?: number;
  };
  last: { timestamp: number; loaded: number };
}

export interface TransferInfo {
  isLocked?: boolean;
  isUploadFinished?: boolean;
  isMinimized?: boolean;
  loaded?: number;
  totalSize?: number;
  currentFileIndex?: number;
  filesProgress?: { [fileId: string]: any };
  progress?: {
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed?: number;
      avgSpeed?: number;
      chunkSize?: number;
      chunksUploaded?: number;
      totalChunks?: number;
    };
    last: {
      timestamp: number;
      loaded: number;
    };
  };
  startTime?: number;
  totalTime?: number;
  consumption?: {
    size: number;
    gCO2: number;
    kWh: number;
    kms: number;
  };
  name?: string;
  link?: string;
  expiration_date?: string;
  purging?: boolean;
  transfer_id?: string;
  [key: string]: any;
}

export interface ChunkInfo {
  fileId: string;
  chunkIndex: string;
  chunkLoaded: number;
  chunkTotal: number;
  chunkSize: number;
  chunksUploaded: number;
  totalChunks: number;
}

// Action Creators
export function setInfos(value: TransferInfo & { transfer_id?: string }) {
  return { type: SET_INFOS, value };
}

export function lockTransfer(transferId: string, value: boolean) {
  return { type: LOCK_TRANSFER, transferId, value };
}

export function updateProgress(
  transferId: string,
  timestamp: number, 
  loaded: number, 
  instantSpeed: number,
  chunkInfo?: ChunkInfo,
  totalLoaded?: number
) {
  return {
    type: UPDATE_PROGRESS,
    transferId,
    payload: {
      timestamp,
      loaded,
      instantSpeed,
      totalLoaded,
      ...(chunkInfo ? {
        chunkInfo: {
          fileId: chunkInfo.fileId,
          chunkIndex: chunkInfo.chunkIndex,
          loaded: chunkInfo.chunkLoaded,
          total: chunkInfo.chunkTotal,
          chunkSize: chunkInfo.chunkSize,
          chunksUploaded: chunkInfo.chunksUploaded,
          totalChunks: chunkInfo.totalChunks
        }
      } : {})
    }
  };
}

export function purgeInfos(transferId: string) {
  return { type: PURGE, transferId };
}

export function setMinimized(transferId: string, value: boolean) {
  return { type: SET_MINIMIZED, transferId, value };
}

export function restoreTransferState(state: TransferInfo) {
  return { type: RESTORE_TRANSFER_STATE, value: state };
}

export function restoreTransfer(transferId: string, transferState: TransferInfo) {
  return { type: RESTORE_TRANSFER, transferId, transferState };
}

export function migrateTransfers(transfers: { [key: string]: TransferInfo }) {
  return { type: MIGRATE_TRANSFERS, transfers };
}

export function archiveTransfer(transferId: string, transferInfo: TransferInfo) {
  const transferData = {
    id: transferId,
    transfer_name: transferInfo.transfer_name || transferInfo.name || 'Sans nom',
    link: transferInfo.link || `${process.env.REACT_APP_URL}/d/${transferId}`,
    created_at: transferInfo.startTime || Date.now(),
    expiration_date: transferInfo.expiration_date,
    isUploadFinished: true
  };

  // Mettre à jour le localStorage pour les utilisateurs non connectés
  if (!transferInfo.user_id) {
    const LOCAL_STORAGE_KEY = 'filevert_transfer_history';
    const existingTransfers = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]');
    const newTransfers = [transferData, ...existingTransfers].slice(0, 3); // Garder les 3 derniers
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newTransfers));
  }

  return { 
    type: ARCHIVE_TRANSFER, 
    transferId,
    transferData
  };
}

export function updateHistory(transfers: any[]) {
  return {
    type: UPDATE_HISTORY,
    transfers
  };
}

export function resetTransferProgress(transferId: string) {
  return {
    type: RESET_TRANSFER_PROGRESS,
    transferId
  };
}

export function resetTransferState(transferId: string) {
  return {
    type: RESET_TRANSFER_STATE,
    transferId
  };
}

// Nouvelle action pour définir la phase
export function setPhase(phase: UploadPhase) {
  return {
    type: SET_PHASE,
    payload: phase
  };
}

// Export action types and action creators
export const transferAction = {
  SET_INFOS,
  SET_PREVIEWS,
  LOCK_TRANSFER,
  PURGE,
  UPDATE_PROGRESS,
  SET_MINIMIZED,
  RESTORE_TRANSFER_STATE,
  RESTORE_TRANSFER,
  MIGRATE_TRANSFERS,
  ARCHIVE_TRANSFER,
  UPDATE_HISTORY,
  RESET_TRANSFER_PROGRESS,
  RESET_TRANSFER_STATE,
  SET_PHASE,

  setInfos,
  lockTransfer,
  updateProgress,
  purgeInfos,
  setMinimized,
  restoreTransferState,
  restoreTransfer,
  migrateTransfers,
  archiveTransfer,
  updateHistory,
  resetTransferProgress,
  resetTransferState,
  setPhase
};