import React, { ReactElement, useState } from "react";
import { appAction, userAction } from "../../Store/Actions";
import { i18n } from "../../utils";
import { userApi } from "../../Api";
import { X, Key, Lock, Mail } from "lucide-react";

interface State {
  isSubmitted: boolean;
  resetError: null | string;
  email: string;
  error: null | string;
  password: string;
  confirm_password: string;
}

interface Props {
  app: any;
  userFromParent?: any;
  dispatch: Function;
}

const PasswordModalsComponent = ({ app, dispatch, userFromParent }: Props): ReactElement | null => {
  const [state, setState] = useState<State>({
    isSubmitted: false,
    resetError: null,
    email: "",
    error: null,
    password: "",
    confirm_password: "",
  });

  const closeResetModal = () => dispatch(appAction.resetPassword(false));
  const handleChange = (evt: any) =>
    setState({ ...state, [evt.target.name]: evt.target.value });

  const handleNewPassword = async () => {
    const { password, confirm_password } = state;

    if (confirm_password !== password)
      return setState({ ...state, error: i18n._("password_missmatch_label") });

    const user = await userApi.update(
      { password, token: "", status: 1 },
      userFromParent?.id
    );

    if (!user || user.error) return;

    dispatch(appAction.savePassword(false));
    dispatch(userAction.loginUser(user));
  };

  const handleResetLink = async () => {
    const { email } = state;
    const user = await userApi.sendResetLink({ email });

    if (user.error)
      return setState({
        ...state,
        isSubmitted: true,
        resetError: user.error.message,
      });

    return setState({ ...state, isSubmitted: true, resetError: null });
  };

  const inputClasses = "w-full px-4 py-2 border rounded-lg outline-none transition-all duration-200 bg-white dark:bg-neutral-800 border-gray-300 dark:border-neutral-600 focus:ring-2 focus:ring-primary dark:focus:ring-primary/70";
  const buttonClasses = "px-6 py-2 bg-primary hover:bg-primary/90 text-white rounded-lg transition-colors shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed";

  if (app.RESET_PASSWORD) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white dark:bg-neutral-800 p-6 rounded-xl w-full max-w-md transform transition-all duration-300 ease-out scale-95 animate-modal shadow-xl">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-neutral-100 flex items-center">
              <Key className="w-5 h-5 mr-2 text-primary" />
              {i18n._("forgotten_password_label")}
            </h3>
            <button
              onClick={closeResetModal}
              className="text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-4">
            {state.isSubmitted && (
              <div className={`p-4 rounded-lg ${state.resetError ? 'bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400' : 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400'}`}>
                {state.resetError || i18n._("reset_link_sent_label")}
              </div>
            )}

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1">
                {i18n._("form_login_email_label")}
              </label>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  className={inputClasses}
                  placeholder={i18n._("form_login_email_label")}
                />
                <Mail className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                onClick={handleResetLink}
                className={buttonClasses}
              >
                {i18n._("reset_password_label")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (app.SAVE_PASSWORD) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white dark:bg-neutral-800 p-6 rounded-xl w-full max-w-md transform transition-all duration-300 ease-out scale-95 animate-modal shadow-xl">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-neutral-100 flex items-center">
              <Key className="w-5 h-5 mr-2 text-primary" />
              {i18n._("new_password_label")}
            </h3>
            <button
              onClick={() => dispatch(appAction.savePassword(false))}
              className="text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-4">
            {state.isSubmitted && (
              <div className={`p-4 rounded-lg ${state.error ? 'bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400' : 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400'}`}>
                {state.error || i18n._("password_success_saved")}
              </div>
            )}

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1">
                {i18n._("form_login_password_label")}
              </label>
              <div className="relative">
                <input
                  type="password"
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  className={`${inputClasses} ${state.error ? 'border-red-500' : ''}`}
                  placeholder={i18n._("form_login_password_label")}
                />
                <Lock className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1">
                {i18n._("form_profil_password_confirmation_label")}
              </label>
              <div className="relative">
                <input
                  type="password"
                  name="confirm_password"
                  value={state.confirm_password}
                  onChange={handleChange}
                  className={`${inputClasses} ${state.error ? 'border-red-500' : ''}`}
                  placeholder={i18n._("form_profil_password_confirmation_label")}
                />
                <Lock className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                onClick={handleNewPassword}
                className={buttonClasses}
              >
                {i18n._("password_update_label")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PasswordModalsComponent;
