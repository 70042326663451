import React from "react";

const ChromeExtension = React.lazy(() => import("./ChromeExtension/ChromeExtension"));
const Home = React.lazy(() => import("./Home/Home"));
const Receipt = React.lazy(() => import("./Receipt/Receipt"));
const Deposit = React.lazy(() => import("./Deposit/Deposit"));
const Download = React.lazy(() => import("./Download/Download"));
const About = React.lazy(() => import("./About/About"));
const Profil = React.lazy(() => import("./Profil/Profil"));
const ResetPassword = React.lazy(() => import("./ResetPassword/ResetPassword"));
const ConfirmMember = React.lazy(() => import("./Profil/Profil"));
const NotFound = React.lazy(() => import("./NotFound/NotFound"));
const Legale = React.lazy(() => import("./Legale/Legale"));
const FVStatistics = React.lazy(() => import("./FVStatistics/FVStatistics"));
const OrderScreen = React.lazy(() => import("./Order/OrderScreen"));
const InviteScreen = React.lazy(() => import("./Invite/Invite"));
const StudioScreen = React.lazy(() => import("./Studio/Studio"));
const LoginScreen = React.lazy(() => import("./Login/LoginScreen"));
const OfferSelectionScreen = React.lazy(() => import("./OfferSelection/OfferSelectionScreen"));
const RegisterScreen = React.lazy(() => import("./Register/RegisterScreen").then(module => ({ default: module.default })));
const Support = React.lazy(() => import("./Support/Support"));
const InviteConfirmationScreen = React.lazy(() => import("./InviteConfirmation/InviteConfirmation"));
const CreateDepositScreen = React.lazy(() => import("./CreateDeposit/CreateDeposit"));

export {
  ChromeExtension,
  Home,
  Receipt,
  Deposit,
  Download,
  About,
  Profil,
  ResetPassword,
  ConfirmMember,
  NotFound,
  Legale,
  FVStatistics,
  OrderScreen,
  InviteScreen,
  InviteConfirmationScreen,
  CreateDepositScreen,
  StudioScreen,
  LoginScreen,
  OfferSelectionScreen,
  RegisterScreen,
  Support
};
