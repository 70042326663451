import streamSaver from 'streamsaver';
import { config } from '../config';

// Configuration pour le téléchargement direct
const BUFFER_SIZE = 8 * 1024 * 1024; // 8 Mo de buffer pour le streaming
const KEEPALIVE_INTERVAL = 30000; // 30 secondes pour le keepalive
const PROGRESS_UPDATE_INTERVAL = 100; // 100ms pour les mises à jour de progression
const DOWNLOAD_STATE_KEY = 'filevert_download_state'; // Clé pour le stockage local

interface ProgressInfo {
  loaded: number;
  total: number;
  speed?: number;
  activeChunks?: number;
}

interface DownloadState {
  transferId: string;
  fileId: string;
  fileName: string;
  timestamp: number;
  isZip: boolean;
}

export class StreamDownloadService {
  private abortController: AbortController | null = null;
  private reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  private writer: WritableStreamDefaultWriter<Uint8Array> | null = null;
  private activeDownload: DownloadState | null = null;

  constructor() {
    // Configurer le MITM avec une URL absolue pour éviter les problèmes de scope
    const absoluteUrl = new URL('/streamsaver-mitm.html', window.location.origin).href;
    streamSaver.mitm = absoluteUrl;
    
    // Patch pour éviter les erreurs de construction d'URL dans streamsaver-mitm.html
    const originalCreateWriteStream = streamSaver.createWriteStream;
    streamSaver.createWriteStream = function(filename, options) {
      // Intercepter la création du stream pour éviter les erreurs d'URL
      try {
        return originalCreateWriteStream.call(this, filename, options);
      } catch (error) {
        // Tentative de récupération avec un nom de fichier simplifié
        const safeFilename = typeof filename === 'string' ?
          filename.replace(/[^\w\-\.]/g, '_') :
          'download.bin';
        
        return originalCreateWriteStream.call(this, safeFilename, options);
      }
    };
    
    // Ajouter un écouteur d'événement global pour le rechargement de page
    window.addEventListener('beforeunload', (event) => {
      // Si un téléchargement est en cours, l'annuler
      if (this.activeDownload) {
        this.abort();
        
        // Demander confirmation à l'utilisateur
        event.preventDefault();
        event.returnValue = '';
      }
    });
    
    // Vérifier s'il y avait un téléchargement en cours lors du dernier chargement de la page
    this.checkForInterruptedDownload();
    
    // Vérifier si nous devons forcer un rechargement pour activer le service worker
    StreamDownloadService.forceReloadIfNeeded();
    
    // S'assurer que le service worker est prêt dès le départ
    this.initializeServiceWorker().catch(error => {});
  }

  private checkForInterruptedDownload(): void {
    try {
      const savedState = localStorage.getItem(DOWNLOAD_STATE_KEY);
      if (savedState) {
        const downloadState: DownloadState = JSON.parse(savedState);
        
        // Vérifier si l'état sauvegardé est récent (moins de 5 minutes)
        const now = Date.now();
        const isRecent = (now - downloadState.timestamp) < 5 * 60 * 1000;
        
        if (isRecent) {
          // Nettoyer l'état sauvegardé immédiatement pour éviter des problèmes en cas de rechargement
          localStorage.removeItem(DOWNLOAD_STATE_KEY);
          
          // Message différent selon le type de téléchargement (fichier ou ZIP)
          const message = downloadState.isZip
            ? `Le téléchargement de l'archive "${downloadState.fileName}" a été interrompu. Voulez-vous le reprendre?`
            : `Le téléchargement du fichier "${downloadState.fileName}" a été interrompu. Voulez-vous le télécharger à nouveau?`;
          
          // Informer l'utilisateur que le téléchargement a été interrompu
          if (window.confirm(message)) {
            // Pour les fichiers individuels, déclencher un nouveau téléchargement
            if (!downloadState.isZip) {
              setTimeout(() => {
                try {
                  // Créer un lien de téléchargement direct pour le fichier
                  const url = new URL(`${config.apiUrl}/v2/transfer/${downloadState.transferId}/file/${downloadState.fileId}`);
                  const link = document.createElement('a');
                  link.href = url.toString();
                  link.setAttribute('download', downloadState.fileName);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } catch (e) {}
              }, 500); // Petit délai pour laisser l'interface se charger
            }
            // Pour les ZIP, l'utilisateur devra cliquer à nouveau sur le bouton de téléchargement
          }
        } else {
          // L'état est trop ancien, on le supprime simplement
          localStorage.removeItem(DOWNLOAD_STATE_KEY);
        }
      }
    } catch (error) {
      // En cas d'erreur, on nettoie l'état pour éviter des problèmes futurs
      localStorage.removeItem(DOWNLOAD_STATE_KEY);
    }
  }

  private saveDownloadState(state: DownloadState): void {
    try {
      localStorage.setItem(DOWNLOAD_STATE_KEY, JSON.stringify(state));
      this.activeDownload = state;
    } catch (error) {}
  }

  private clearDownloadState(): void {
    localStorage.removeItem(DOWNLOAD_STATE_KEY);
    this.activeDownload = null;
  }

  // Fonction pour forcer le rechargement de la page si nécessaire pour activer le service worker
  private static forceReloadIfNeeded(): void {
    // Vérifier si nous sommes sur la page de téléchargement
    const isDownloadPage = window.location.pathname.startsWith('/d/');
    
    // Stocker l'information que nous avons déjà essayé de recharger
    const hasAttemptedReload = sessionStorage.getItem('sw_reload_attempted');
    
    // Si nous sommes sur la page de téléchargement, que le service worker est actif mais pas contrôleur,
    // et que nous n'avons pas déjà essayé de recharger
    if (isDownloadPage && !navigator.serviceWorker.controller && !hasAttemptedReload) {
      // Marquer que nous avons essayé de recharger
      sessionStorage.setItem('sw_reload_attempted', 'true');
      
      // Recharger la page après un court délai
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  private async initializeServiceWorker(): Promise<void> {
    try {
      // Nettoyer les service workers existants
      const registrations = await navigator.serviceWorker.getRegistrations();
      const swToUnregister = registrations.filter(reg =>
        reg.scope.includes('streamsaver') || reg.scope === `${window.location.origin}/`
      );
      
      if (swToUnregister.length > 0) {
        for (const reg of swToUnregister) {
          await reg.unregister();
        }
      }

      // Attente minimale après nettoyage
      await new Promise(resolve => setTimeout(resolve, 100));

      // Enregistrer le nouveau service worker
      let registration: ServiceWorkerRegistration;
      try {
        registration = await navigator.serviceWorker.register('/streamsaver-sw.js', {
          scope: '/',
          updateViaCache: 'none'
        });
      } catch (regError) {
        // Tentative avec un scope différent
        registration = await navigator.serviceWorker.register('/streamsaver-sw.js', {
          scope: '/streamsaver/',
          updateViaCache: 'none'
        });
      }

      // Activer immédiatement si nécessaire
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }

      // Attendre l'activation avec un timeout réduit
      await Promise.race([
        new Promise<void>((resolve) => {
          if (registration.active && navigator.serviceWorker.controller) {
            resolve();
            return;
          }

          // Simplifier en écoutant uniquement le controllerchange
          const controllerChangeListener = () => {
            navigator.serviceWorker.removeEventListener('controllerchange', controllerChangeListener);
            resolve();
          };

          navigator.serviceWorker.addEventListener('controllerchange', controllerChangeListener);
        }),
        new Promise<void>((resolve, reject) =>
          setTimeout(() => {
            if (!navigator.serviceWorker.controller) {
              // Vérifier si le service worker est actif même sans controller
              if (registration.active) {
                // Forcer un rechargement du controller
                navigator.serviceWorker.register('/streamsaver-sw.js', {
                  scope: '/',
                  updateViaCache: 'none'
                }).then(() => resolve()).catch(reject);
              } else {
                reject(new Error('Timeout lors de l\'activation du service worker'));
              }
            } else {
              resolve();
            }
          }, 2000)
        )
      ]);
    } catch (error) {
      // Tentative de récupération en dernier recours
      try {
        const reg = await navigator.serviceWorker.register('/streamsaver-sw.js', {
          scope: '/',
          updateViaCache: 'none'
        });
        
        // Attendre un peu pour voir si le controller apparaît
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (fallbackError) {}
      
      throw error;
    }
  }

  // Méthode pour le téléchargement direct
  public async downloadFile(
    transferId: string,
    fileId: string,
    fileName: string,
    password?: string,
    onProgress?: (progress: ProgressInfo) => void,
    userEmail?: string
  ): Promise<void> {
    // Annuler tout téléchargement en cours avant d'en démarrer un nouveau
    if (this.activeDownload) {
      this.abort();
    }
    
    // Vérifier si le service worker a échoué précédemment
    const swFailedStatus = sessionStorage.getItem('sw_failed') === 'true';
    
    // S'assurer que le service worker est initialisé avant de commencer le téléchargement
    // sauf si nous savons déjà qu'il a échoué
    if (!navigator.serviceWorker.controller && !swFailedStatus) {
      try {
        await this.initializeServiceWorker();
      } catch (error) {
        // Marquer que le service worker a échoué pour éviter de réessayer
        sessionStorage.setItem('sw_failed', 'true');
      }
    }
    
    let keepaliveTimer: NodeJS.Timeout | null = null;
    
    try {
      // Sauvegarder l'état du téléchargement pour pouvoir le détecter en cas de rechargement
      this.saveDownloadState({
        transferId,
        fileId,
        fileName,
        timestamp: Date.now(),
        isZip: false
      });
      
      this.abortController = new AbortController();
      
      let url = `${config.apiUrl}/v2/transfer/${transferId}/file/${fileId}`;
      const params = new URLSearchParams();
      
      if (password) {
        params.append('password', password);
      }
      
      if (userEmail) {
        params.append('user_email', userEmail);
      }
      
      const queryString = params.toString();
      if (queryString) {
        url += `?${queryString}`;
      }

      // Démarrer un keepalive pour maintenir la connexion active
      keepaliveTimer = setInterval(() => {
        if (this.abortController) {
          fetch(url, {
            method: 'HEAD',
            signal: this.abortController.signal,
            headers: { 'Cache-Control': 'no-cache' }
          }).catch(() => {});
        }
      }, KEEPALIVE_INTERVAL);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/octet-stream',
          'Cache-Control': 'no-cache',
          'Connection': 'keep-alive',
          'Transfer-Encoding': 'chunked'
        },
        signal: this.abortController!.signal,
        credentials: 'same-origin',
        mode: 'cors'
      });

      if (!response.ok) {
        clearInterval(keepaliveTimer);
        this.clearDownloadState();
        throw new Error(`Erreur HTTP: ${response.status} ${response.statusText}`);
      }

      const readableStream = response.body;
      if (!readableStream) {
        clearInterval(keepaliveTimer);
        this.clearDownloadState();
        throw new Error('Le corps de la réponse est vide');
      }

      let fileStream: WritableStream<Uint8Array>;
      try {
        fileStream = streamSaver.createWriteStream(fileName);
      } catch (streamError: any) {
        clearInterval(keepaliveTimer);
        this.clearDownloadState();
        throw new Error(`Impossible de créer le stream de sortie: ${streamError.message || 'Erreur inconnue'}`);
      }

      let loaded = 0;
      let lastLoaded = 0;
      let lastTime = Date.now();
      let currentSpeed = 0;
      let buffer = new Uint8Array(BUFFER_SIZE);
      let bufferOffset = 0;
      
      const progressStream = new TransformStream({
        transform(chunk, controller) {
          // Ajouter au buffer
          if (bufferOffset + chunk.length > BUFFER_SIZE) {
            // Buffer plein, vider d'abord
            controller.enqueue(buffer.slice(0, bufferOffset));
            buffer = new Uint8Array(BUFFER_SIZE);
            bufferOffset = 0;
          }
          
          buffer.set(chunk, bufferOffset);
          bufferOffset += chunk.length;
          
          loaded += chunk.length;
          const now = Date.now();
          const elapsed = now - lastTime;
          
          if (elapsed >= PROGRESS_UPDATE_INTERVAL) {
            const bytesPerMs = (loaded - lastLoaded) / elapsed;
            currentSpeed = bytesPerMs * 1000 / (1024 * 1024);
            lastLoaded = loaded;
            lastTime = now;
          }
          
          if (onProgress) {
            onProgress({
              loaded,
              total: loaded,
              speed: currentSpeed,
              activeChunks: 1
            });
          }
        },
        flush(controller) {
          // Vider le reste du buffer
          if (bufferOffset > 0) {
            controller.enqueue(buffer.slice(0, bufferOffset));
          }
        }
      });

      const writer = fileStream.getWriter();
      this.writer = writer; // Stocker le writer pour pouvoir l'annuler proprement
      this.reader = readableStream
        .pipeThrough(progressStream)
        .getReader();

      try {
        while (true) {
          const {done, value} = await this.reader.read();
          if (done) {
            await writer.close();
            this.clearDownloadState(); // Nettoyer l'état car le téléchargement est terminé
            break;
          }
          await writer.write(value);
        }
      } finally {
        clearInterval(keepaliveTimer);
        this.reader = null;
        this.writer = null;
        this.abortController = null;
        this.clearDownloadState(); // S'assurer que l'état est nettoyé même en cas d'erreur
      }

    } catch (error) {
      this.clearDownloadState(); // Nettoyer l'état en cas d'erreur
      if (this.reader) {
        this.reader.cancel();
        this.reader = null;
      }
      if (this.writer) {
        try {
          // Essayer d'annuler proprement le writer
          this.writer.abort();
        } catch (e) {}
        this.writer = null;
      }
      this.abort();
      throw error;
    }
  }

  // Gardé pour la compatibilité avec le code existant
  public async downloadFileMultipart(
    transferId: string,
    fileId: string,
    fileName: string,
    fileSize: number,
    password?: string,
    onProgress?: (progress: ProgressInfo) => void,
    chunkSize?: number,
    maxParallel?: number,
    userEmail?: string
  ): Promise<void> {
    // Rediriger vers le téléchargement direct en ignorant chunkSize et maxParallel
    // car nous utilisons maintenant le téléchargement direct optimisé
    return this.downloadFile(transferId, fileId, fileName, password, onProgress, userEmail);
  }

  public async downloadZip(
    transferId: string,
    archiveName: string,
    password?: string,
    onProgress?: (progress: ProgressInfo) => void,
    userEmail?: string
  ): Promise<void> {
    let keepaliveTimer: NodeJS.Timeout | null = null;
    let retryCount = 0;
    const MAX_RETRIES = 2;
    
    try {
      // Sauvegarder l'état du téléchargement pour pouvoir le détecter en cas de rechargement
      this.saveDownloadState({
        transferId,
        fileId: 'zip',
        fileName: archiveName,
        timestamp: Date.now(),
        isZip: true
      });
      
      // Récupérer l'état du service worker
      const swFailedStatus = sessionStorage.getItem('sw_failed') === 'true';
      
      // Vérifier si le service worker est déjà actif
      const isServiceWorkerActive = navigator.serviceWorker.controller !== null;
      
      // S'assurer que le service worker est initialisé avant de commencer
      // sauf si nous savons déjà qu'il a échoué
      if (!isServiceWorkerActive && !swFailedStatus) {
        try {
          await this.initializeServiceWorker();
        } catch (error) {
          // Tentative de réinitialisation complète
          try {
            if ('serviceWorker' in navigator) {
              const registrations = await navigator.serviceWorker.getRegistrations();
              for (const registration of registrations) {
                await registration.unregister();
              }
              // Attendre un peu pour s'assurer que le nettoyage est effectif
              await new Promise(resolve => setTimeout(resolve, 500));
              await this.initializeServiceWorker();
            }
          } catch (reinitError) {
            // Marquer que le service worker a échoué pour éviter de réessayer
            sessionStorage.setItem('sw_failed', 'true');
          }
        }
      } else if (isServiceWorkerActive) {
        // Vérifier que le service worker est réellement fonctionnel en envoyant un ping
        try {
          const pingTimeout = setTimeout(() => {}, 1000);
          
          // Créer un canal de communication pour le ping
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = (event) => {
            clearTimeout(pingTimeout);
          };
          
          // Envoyer le ping
          if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              type: 'PING',
              timestamp: Date.now()
            }, [messageChannel.port2]);
          } else {
            clearTimeout(pingTimeout);
          }
        } catch (pingError) {
          // Continuer malgré l'erreur
        }
      }

      this.abortController = new AbortController();
      
      // Construction de l'URL avec vérification et logs
      const baseUrl = config.apiUrl.replace(/\/+$/, ''); // Supprimer les slashes finaux

      // Vérifier si l'ID contient des caractères spéciaux et les encoder
      // Élargir la regex pour capturer plus de caractères spéciaux
      const specialCharsRegex = /[\[\]\(\)\{\}\+\-\*\?\.\^\$\|\\\/]/g;
      const hasSpecialChars = specialCharsRegex.test(transferId);
      const sanitizedTransferId = hasSpecialChars ?
        transferId.replace(specialCharsRegex, char => encodeURIComponent(char)) :
        transferId;

      let url = `${baseUrl}/v2/transfer/${sanitizedTransferId}/zip`;

      // Utiliser URLSearchParams pour construire les paramètres de requête
      const params = new URLSearchParams();
      
      if (password) {
        params.append('password', password);
      }
      
      if (userEmail) {
        params.append('user_email', userEmail);
      }
      
      const queryString = params.toString();
      if (queryString) {
        url += `?${queryString}`;
      }

      // Vérifier que streamSaver est correctement configuré
      if (!StreamDownloadService.isSupported()) {
        throw new Error('Votre navigateur ne supporte pas le téléchargement de fichiers volumineux. Veuillez utiliser un navigateur moderne comme Chrome, Firefox ou Edge.');
      }
      
      // Vérifier que le MITM est correctement configuré
      if (!streamSaver.mitm || !streamSaver.mitm.includes(window.location.origin)) {
        streamSaver.mitm = `${window.location.origin}/streamsaver-mitm.html`;
      }

      // Démarrer un keepalive plus fréquent pour maintenir la connexion active
      const ENHANCED_KEEPALIVE_INTERVAL = 15000; // 15 secondes au lieu de 30
      keepaliveTimer = setInterval(() => {
        if (this.abortController) {
          fetch(url, {
            method: 'HEAD',
            signal: this.abortController.signal,
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            }
          }).catch((error) => {});
        }
      }, ENHANCED_KEEPALIVE_INTERVAL);
      
      // Fonction pour effectuer la requête avec retry
      const fetchWithRetry = async (): Promise<Response> => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Accept': 'application/octet-stream',
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Connection': 'keep-alive',
              'Transfer-Encoding': 'chunked'
            },
            signal: this.abortController!.signal,
            credentials: 'same-origin',
            mode: 'cors'
          });
          
          if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status} ${response.statusText}`);
          }
          
          return response;
        } catch (error) {
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, 1000));
            return fetchWithRetry();
          }
          throw error;
        }
      };
      
      const response = await fetchWithRetry();

      const readableStream = response.body;
      if (!readableStream) {
        if (keepaliveTimer) clearInterval(keepaliveTimer);
        throw new Error('Le corps de la réponse est vide');
      }

      // S'assurer que le service worker est toujours actif, mais éviter les réinitialisations inutiles
      if (!navigator.serviceWorker.controller) {
        // Vérifier si un service worker est en cours d'installation ou d'attente
        const registrations = await navigator.serviceWorker.getRegistrations();
        const relevantReg = registrations.find(reg =>
          reg.scope.includes(window.location.origin) ||
          reg.scope.includes('streamsaver')
        );
        
        if (relevantReg && (relevantReg.installing || relevantReg.waiting)) {
          // Forcer l'activation si en attente
          if (relevantReg.waiting) {
            relevantReg.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
          
          // Attendre l'activation avec timeout
          await Promise.race([
            new Promise<void>(resolve => {
              const activateListener = () => {
                navigator.serviceWorker.removeEventListener('controllerchange', activateListener);
                resolve();
              };
              navigator.serviceWorker.addEventListener('controllerchange', activateListener);
            }),
            new Promise<void>((_, reject) =>
              setTimeout(() => {
                if (!navigator.serviceWorker.controller) {
                  reject(new Error('Timeout lors de l\'attente de l\'activation du service worker'));
                }
              }, 3000)
            )
          ]).catch(error => {
            // Continuer même en cas d'erreur, nous allons vérifier à nouveau plus tard
          });
        } else {
          // Aucun service worker en cours, réinitialiser
          await this.initializeServiceWorker();
        }
      }

      // Vérifier à nouveau après tentative de récupération
      if (!navigator.serviceWorker.controller && !swFailedStatus) {
        // Marquer que le service worker a échoué pour éviter de réessayer
        sessionStorage.setItem('sw_failed', 'true');
        
        // Utiliser le mode fallback au lieu de lancer une erreur
        this.fallbackZipDownload(archiveName);
        return; // Sortir de la méthode pour éviter d'exécuter le reste du code
      }

      // Vérifier à nouveau que le service worker est bien actif avant de créer le stream
      // sauf si nous savons déjà qu'il a échoué
      if (!navigator.serviceWorker.controller && !swFailedStatus) {
        // Marquer que le service worker a échoué pour éviter de réessayer
        sessionStorage.setItem('sw_failed', 'true');
        
        // Utiliser le mode fallback au lieu de lancer une erreur
        this.fallbackZipDownload(archiveName);
        return; // Sortir de la méthode pour éviter d'exécuter le reste du code
      }
      
      // Si le service worker a échoué, utiliser le mode fallback
      if (swFailedStatus) {
        this.fallbackZipDownload(archiveName);
        return; // Sortir de la méthode pour éviter d'exécuter le reste du code
      }

      let fileStream: WritableStream<Uint8Array>;
      try {
        // Augmenter la taille du buffer pour les gros fichiers
        const ENHANCED_BUFFER_SIZE = 16 * 1024 * 1024; // 16 Mo au lieu de 8 Mo
        
        // Récupérer la taille du contenu
        const contentLength = parseInt(response.headers.get('content-length') || '0');
        
        // Créer un nom d'archive unique pour éviter les collisions
        const uniqueArchiveName = archiveName.includes('.zip') ?
          archiveName :
          `${archiveName}-${Date.now().toString(36)}.zip`;
        
        fileStream = streamSaver.createWriteStream(uniqueArchiveName, {
          size: contentLength,
          writableStrategy: { highWaterMark: ENHANCED_BUFFER_SIZE }
        });
        
        // Vérifier que le stream a été créé correctement
        if (!fileStream || !fileStream.getWriter) {
          throw new Error('Stream de sortie invalide');
        }
      } catch (streamError: any) {
        if (keepaliveTimer) clearInterval(keepaliveTimer);
        
        // Tentative de récupération avec des options simplifiées
        try {
          fileStream = streamSaver.createWriteStream(archiveName);
        } catch (fallbackError: any) {
          throw new Error(`Impossible de créer le stream de sortie: ${streamError.message || 'Erreur inconnue'}`);
        }
      }

      let loaded = 0;
      let lastLoaded = 0;
      let lastTime = Date.now();
      let currentSpeed = 0;
      let buffer = new Uint8Array(BUFFER_SIZE);
      let bufferOffset = 0;
      
      const progressStream = new TransformStream({
        transform(chunk, controller) {
          // Ajouter au buffer
          if (bufferOffset + chunk.length > BUFFER_SIZE) {
            // Buffer plein, vider d'abord
            controller.enqueue(buffer.slice(0, bufferOffset));
            buffer = new Uint8Array(BUFFER_SIZE);
            bufferOffset = 0;
          }
          
          buffer.set(chunk, bufferOffset);
          bufferOffset += chunk.length;
          
          loaded += chunk.length;
          const now = Date.now();
          const elapsed = now - lastTime;
          
          if (elapsed >= PROGRESS_UPDATE_INTERVAL) {
            const bytesPerMs = (loaded - lastLoaded) / elapsed;
            currentSpeed = bytesPerMs * 1000 / (1024 * 1024);
            lastLoaded = loaded;
            lastTime = now;
          }
          
          if (onProgress) {
            onProgress({
              loaded,
              total: loaded,
              speed: currentSpeed,
              activeChunks: 1
            });
          }
        },
        flush(controller) {
          // Vider le reste du buffer
          if (bufferOffset > 0) {
            controller.enqueue(buffer.slice(0, bufferOffset));
          }
        }
      });

      const writer = fileStream.getWriter();
      this.writer = writer; // Stocker le writer pour pouvoir l'annuler proprement
      let streamStarted = false;
      let chunkCount = 0;
      let totalBytesReceived = 0;
      let lastProgressUpdate = Date.now();
      let consecutiveEmptyChunks = 0;

      // Définir un timeout global pour le téléchargement
      const downloadTimeout = setTimeout(() => {
        this.clearDownloadState(); // Nettoyer l'état en cas de timeout
        if (this.reader) {
          this.reader.cancel('Timeout global du téléchargement');
        }
        if (this.writer) {
          this.writer.abort('Timeout global du téléchargement');
        }
      }, 5 * 60 * 1000); // 5 minutes
      
      try {
        this.reader = readableStream
          .pipeThrough(progressStream)
          .getReader();
        
        // Définir un timeout pour la réception du premier chunk
        const firstChunkTimeout = setTimeout(() => {
          if (!streamStarted) {
            this.clearDownloadState(); // Nettoyer l'état en cas de timeout
            throw new Error('Aucun chunk reçu après 30 secondes');
          }
        }, 30000);
      
        while (true) {
          try {
            const { done, value } = await this.reader.read();
      
            if (done) {
              await writer.close();
              this.clearDownloadState(); // Nettoyer l'état car le téléchargement est terminé
              clearTimeout(firstChunkTimeout);
              break;
            }
      
            if (value && value.length > 0) {
              if (!streamStarted) {
                streamStarted = true;
              }
              
              chunkCount++;
              totalBytesReceived += value.length;
              consecutiveEmptyChunks = 0;
              
              const now = Date.now();
              if (now - lastProgressUpdate > 2000) {
                lastProgressUpdate = now;
              }
              
              try {
                const writeStartTime = Date.now();
                await writer.write(value);
                const writeTime = Date.now() - writeStartTime;
                
                if (writeTime > 5000) {
                  // Opération d'écriture lente
                }
              } catch (writeError) {
                // Tentative de récupération pour certaines erreurs d'écriture
                if (String(writeError).includes('network') || String(writeError).includes('connection')) {
                  await new Promise(resolve => setTimeout(resolve, 1000));
                  
                  // Réessayer une fois
                  try {
                    await writer.write(value);
                  } catch (retryError) {
                    throw retryError;
                  }
                } else {
                  throw writeError;
                }
              }
            } else {
              consecutiveEmptyChunks++;
              
              // Si on reçoit trop de chunks vides consécutifs, c'est peut-être un problème
              if (consecutiveEmptyChunks > 5) {
                // Attendre un peu avant de continuer
                await new Promise(resolve => setTimeout(resolve, 2000));
                
                // Si on a déjà reçu des données, on continue quand même
                if (streamStarted && totalBytesReceived > 0) {
                  consecutiveEmptyChunks = 0; // Réinitialiser le compteur
                } else if (consecutiveEmptyChunks > 10) {
                  throw new Error('Trop de chunks vides consécutifs');
                }
              }
            }
          } catch (error) {
            // Vérifier si c'est une erreur de timeout ou d'annulation
            const errorStr = String(error);
            if ((error instanceof Error && error.name === 'AbortError') ||
                errorStr.includes('aborted') ||
                errorStr.includes('cancel')) {
              throw error;
            }
            
            // Tenter de récupérer pour les autres types d'erreurs
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Si nous avons déjà reçu des données, essayer de continuer
            if (streamStarted && chunkCount > 0 && totalBytesReceived > 0) {
              continue;
            }
            
            throw error;
          }
        }
        
        clearTimeout(downloadTimeout);
      } finally {
        if (keepaliveTimer) clearInterval(keepaliveTimer);
        if (downloadTimeout) clearTimeout(downloadTimeout);
        
        // Fermer proprement le reader
        if (this.reader) {
          try {
            this.reader.releaseLock();
          } catch (e) {
            // Erreur de libération du verrou
          }
          this.reader = null;
        }
        
        // Fermer proprement le writer
        if (this.writer) {
          try {
            // Ne pas appeler close() ici car cela pourrait bloquer si le stream est déjà fermé
            this.writer = null;
          } catch (e) {
            // Erreur de réinitialisation du writer
          }
        }
        
        // Annuler proprement l'abortController
        if (this.abortController) {
          try {
            // Ne pas appeler abort() ici car cela pourrait interrompre un téléchargement réussi
            this.abortController = null;
          } catch (e) {
            // Erreur de réinitialisation de l'abortController
          }
        }
        
        // S'assurer que l'état est nettoyé
        this.clearDownloadState();
      
        if (streamStarted && totalBytesReceived > 0) {
          // Stream terminé avec succès
        } else {
          throw new Error('Aucune donnée reçue pendant le téléchargement');
        }
      }

    } catch (error) {
      // Nettoyer l'état du téléchargement
      this.clearDownloadState();
      
      // Annuler le reader
      if (this.reader) {
        this.reader.cancel();
        this.reader = null;
      }
      
      // Annuler le writer
      if (this.writer) {
        try {
          this.writer.abort();
        } catch (e) {
          // Erreur d'annulation du writer
        }
        this.writer = null;
      }
      
      if (keepaliveTimer) clearInterval(keepaliveTimer);
      this.abort();

      if (error instanceof Error) {
        throw new Error(`Erreur lors du téléchargement ZIP: ${error.message}`);
      } else {
        throw new Error('Erreur inconnue lors du téléchargement ZIP');
      }
    }
  }

  public abort(): void {
    // Nettoyer l'état du téléchargement
    this.clearDownloadState();
    
    // Annuler la requête HTTP
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
    
    // Annuler le reader
    if (this.reader) {
      this.reader.cancel();
      this.reader = null;
    }
    
    // Annuler le writer
    if (this.writer) {
      try {
        this.writer.abort();
      } catch (e) {
        // Erreur lors de l'annulation
      }
      this.writer = null;
    }
  }

  // Méthode de secours pour télécharger un ZIP quand le service worker n'est pas disponible
  public fallbackZipDownload(archiveName: string): void {
    try {
      // Créer un lien de téléchargement direct pour le ZIP
      const transferId = this.activeDownload?.transferId;
      if (!transferId) {
        alert('Impossible de télécharger l\'archive. Veuillez réessayer.');
        return;
      }
      
      // Construction de l'URL
      const baseUrl = config.apiUrl.replace(/\/+$/, '');
      const specialCharsRegex = /[\[\]\(\)\{\}\+\-\*\?\.\^\$\|\\\/]/g;
      const sanitizedTransferId = specialCharsRegex.test(transferId) ?
        transferId.replace(specialCharsRegex, char => encodeURIComponent(char)) :
        transferId;
      
      let url = `${baseUrl}/v2/transfer/${sanitizedTransferId}/zip`;
      
      // Ajouter le mot de passe si nécessaire
      const urlParams = new URLSearchParams(window.location.search);
      const password = urlParams.get('password');
      if (password) {
        url += `?password=${encodeURIComponent(password)}`;
      }
      
      // Créer et cliquer sur un lien pour déclencher le téléchargement
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', archiveName.includes('.zip') ? archiveName : `${archiveName}.zip`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Nettoyer l'état du téléchargement
      this.clearDownloadState();
      
      // Informer l'utilisateur
      setTimeout(() => {
        alert('Le téléchargement a été lancé en mode direct. Si le téléchargement ne démarre pas automatiquement, vérifiez les paramètres de votre navigateur concernant les téléchargements automatiques.');
      }, 1000);
      
    } catch (error) {
      alert('Une erreur est survenue lors du téléchargement. Veuillez réessayer.');
      this.clearDownloadState();
    }
  }

  public static isSupported(): boolean {
    return (
      !!window.fetch &&
      !!window.ReadableStream &&
      !!window.WritableStream
    );
  }

  private static _instance: StreamDownloadService | null = null;

  private static get instance(): StreamDownloadService {
    if (!this._instance) {
      this._instance = new StreamDownloadService();
    }
    return this._instance;
  }

  public static setupStreamSaver(): Promise<boolean> {
    // Toujours nettoyer les service workers existants pour une initialisation plus rapide
    // (similaire au comportement en navigation privée)
    return navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        // Nettoyer tous les service workers existants
        const cleanupPromises = registrations
          .filter(reg => reg.scope.includes('streamsaver') || reg.scope === `${window.location.origin}/`)
          .map(reg => reg.unregister());
        
        return Promise.all(cleanupPromises);
      })
      .then(() => {
        // Attente minimale après nettoyage
        return new Promise(resolve => setTimeout(resolve, 100));
      })
      .then(() => {
        // Enregistrer un nouveau service worker
        return navigator.serviceWorker.register('/streamsaver-sw.js', {
          scope: '/',
          updateViaCache: 'none'
        });
      })
      .then((registration: ServiceWorkerRegistration) => {
        // Activer immédiatement si nécessaire
        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        
        // Attendre un court délai pour l'activation
        return new Promise<ServiceWorkerRegistration>(resolve => setTimeout(() => resolve(registration), 100));
      })
      .then((registration: ServiceWorkerRegistration) => {
        // Vérifier si le service worker est actif
        if (navigator.serviceWorker.controller) {
          return true;
        } else if (registration.active) {
          return true; // Considérer comme un succès quand même
        } else {
          return true; // Considérer comme un succès quand même
        }
      })
      .catch(error => {
        return false;
      });
  }
}

const streamDownloadService = new StreamDownloadService();
export default streamDownloadService;