import axios from 'axios';
import { config } from '../config';

export interface ApiError {
  code: string;
  message: string;
}

export interface UserUsageStats {
  id: number;
  name: string;
  email: string;
  isTeamLeader: boolean;
  currentPeriod: {
    usage: number;
    estimatedCost: number;
  };
  previousMonth: {
    usage: number;
    cost: number;
  };
  total: {
    usage: number;
    cost: number;
  };
}

export interface GroupUsageStats {
  total: {
    usage: number;
    cost: number;
  };
  users: UserUsageStats[];
}

export interface Admin {
  name: string;
  email: string;
  organization: string;
}

export interface UsageStats {
  currentPeriod: {
    usage: number;
    estimatedCost: number;
  };
  previousMonth: {
    usage: number;
    cost: number;
  };
  total: {
    usage: number;
    cost: number;
  };
  showCosts: boolean;
  admin?: Admin;
}

const usageApi = {
  subscribeToUsage: async (): Promise<any> => {
    try {
      console.log('Subscribing to usage...');
      const response = await axios.post('/stripe/usage/subscribe', {});
      console.log('Usage subscription response:', response.data);
      return response.data;
    } catch (error) {
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as any).response?.data as ApiError;
        throw new Error(apiError?.message || 'Erreur lors de la souscription à l\'usage');
      }
      throw error;
    }
  },

  getStats: async (): Promise<UsageStats> => {
    try {
      const response = await axios.get('/stripe/usage/individual/stats');
      return response.data;
    } catch (error) {
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as any).response?.data as ApiError;
        throw new Error(apiError?.message || 'Erreur lors de la récupération des statistiques');
      }
      throw error;
    }
  },

  reportUsage: async (quantity: number): Promise<void> => {
    try {
      await axios.post('/stripe/usage/report', { quantity });
    } catch (error) {
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as any).response?.data as ApiError;
        throw new Error(apiError?.message || 'Erreur lors de l\'enregistrement de l\'utilisation');
      }
      throw error;
    }
  },

  getGroupStats: async (): Promise<GroupUsageStats> => {
    try {
      const response = await axios.get('/stripe/usage/group-stats');
      return response.data;
    } catch (error) {
      if (error && typeof error === 'object' && 'response' in error) {
        const apiError = (error as any).response?.data as ApiError;
        throw new Error(apiError?.message || 'Erreur lors de la récupération des statistiques du groupe');
      }
      throw error;
    }
  }
};

export default usageApi;
