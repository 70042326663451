export interface FileProgress {
  chunks: { [chunkIndex: string]: { loaded: number; total: number } };
  totalLoaded: number;
  fileSize: number;
}

export interface TransferProgress {
  current: {
    timestamp: number;
    loaded: number;
    instantSpeed: number;
    avgSpeed: number;
    chunkSize: number;
    chunksUploaded: number;
    totalChunks: number;
  };
  last: {
    timestamp: number;
    loaded: number;
  };
}

export interface Transfer {
  id?: string;
  name?: string;
  expiration_date?: string;
  link?: string;
  purging?: boolean;
  isDeposit?: boolean;
  isLocked: boolean;
  isUploadFinished: boolean;
  isMinimized: boolean;
  loaded: number;
  totalSize: number;
  currentFileIndex: number;
  filesProgress: { [fileId: string]: FileProgress };
  progress: {
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed: number;
      avgSpeed: number;
      chunkSize: number;
      chunksUploaded: number;
      totalChunks: number;
    };
    last: {
      timestamp: number;
      loaded: number;
    };
  };
  startTime: number;
  totalTime: number;
  consumption: {
    size: number;
    gCO2: number;
    kWh: number;
    kms: number;
  };
}

export const defaultTransfer: Transfer = {
  expiration_date: '',
  link: '',
  purging: false,
  isLocked: false,
  isUploadFinished: false,
  isMinimized: false,
  loaded: 0,
  totalSize: 0,
  currentFileIndex: 0,
  filesProgress: {} as { [fileId: string]: FileProgress },
  progress: {
    current: {
      timestamp: 0,
      loaded: 0,
      instantSpeed: 0,
      avgSpeed: 0,
      chunkSize: 0,
      chunksUploaded: 0,
      totalChunks: 0
    },
    last: {
      timestamp: 0,
      loaded: 0
    }
  },
  startTime: 0,
  totalTime: 0,
  consumption: {
    size: 0,
    gCO2: 0,
    kWh: 0,
    kms: 0
  }
};
