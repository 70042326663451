import React, { useEffect, useState } from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { ChevronDown, LogOut } from "lucide-react";
import { i18n, isMobile } from "../../utils";
import Brand from "./Brand/Brand";
import { BurgerMenu, FooterComponent, LangComponent, ButtonUploadHistory } from "..";
import { LoginButtonV2 } from "../Header/Components";
import { connect } from "react-redux";
import { gradients } from "../../Constants";
import { appAction, userAction } from "../../Store";

function getThemeClass(id: string) {
  if (id === "custom-0" || id === "custom-1") return id;

  const themeClass: any =
    "0" === id[0]
      ? gradients.clair.find((gradient) => gradient.id === id)
      : gradients.sombre.find((gradient) => gradient.id === id);

  return themeClass?.className || "";
}

function getClassName(app: any) {
  const className = !app[appAction.SHOW_CUSTOM_HEADER]
    ? "normal"
    : getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

  return className;
}

function getClassId(app: any) {
  const classId = !app[appAction.SHOW_CUSTOM_HEADER]
    ? ""
    : app[appAction.SHOW_CUSTOM_HEADER];

  return classId;
}

interface HeaderV2StateProps {
  app: any;
  user: any;
  transfer: {
    transfers: {
      [key: string]: {
        isLocked: boolean;
        isUploadFinished: boolean;
        purging: boolean;
        lastProgressUpdate?: number;
      };
    };
  };
}

interface HeaderV2DispatchProps {
  dispatch: any;
}

interface HeaderV2OwnProps {
  isLogged?: boolean;
}

type HeaderV2Props = HeaderV2StateProps & HeaderV2DispatchProps & HeaderV2OwnProps & RouteComponentProps;

const HeaderV2Component: React.FC<HeaderV2Props> = (props) => {
  const {
    isLogged,
    dispatch,
    app,
    location,
    user
  } = props;

  const isCustomOfferPage = location.pathname.includes("/offers") || location.pathname.includes("/login") || location.pathname.includes("/contact");
  const themeClass = getClassName(app);
  const themeId = getClassId(app);

  const [headerOpened, setHeaderOpened] = useState<boolean>(false);
  const [bgMenuOpen, setBgMenuOpen] = useState<boolean>(false);
  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    if (isCustomOfferPage) {
      setHeaderOpened(false);
    } else {
      setHeaderOpened("normal" === themeClass);
    }
  }, [app, location, isCustomOfferPage, themeClass]);

  if (app["HIDE_HEADER"]) return null;

  // Theme-related logic
  // Forcer le mode sombre tout en préservant les paramètres personnalisés du client
  const isThemeLight = false;

  return (
    <>
      <header
        className={`
          fixed top-0 left-0 right-0 z-50
          px-4 lg:px-8
          h-20
          bg-transparent
          transition-all duration-300 ease-in-out
          ${isThemeLight ? 'bg-white bg-opacity-90' : 'bg-gray-900 bg-opacity-80'}
        `}
      >
        {/* Logo and Slogan Section */}
        <div className="h-full container mx-auto">
          <div className="flex items-center justify-between h-full">
            {/* Left Section: Back Button on Offers Page */}
            {isCustomOfferPage ? (
              <NavLink to="/" className="flex items-center gap-2 text-white hover:text-emerald-500 transition-colors">
                <ChevronDown className="w-6 h-6 rotate-90" />
                <span className="text-sm font-medium">{i18n._("back")}</span>
              </NavLink>
            ) : (
              <div className="flex items-center gap-6">
                <Brand mode={isThemeLight ? "light" : undefined} />
                {!isMobile && (
                  <div className={`
                    hidden lg:block
                    text-md font-semibold tracking-wide
                    ${isThemeLight ? 'text-gray-900' : 'text-white'}
                  `}>
                    {i18n._("header_slogan_start")}
                    <span className="inline-flex mx-2 h-5 items-center">
                      <div className="w-0.5 h-3 bg-blue-700"></div>
                      <div className="w-0.5 h-3 bg-white mx-0.5"></div>
                      <div className="w-0.5 h-3 bg-red-700"></div>
                    </span>
                    {i18n._("header_slogan_end")}
                  </div>
                )}
              </div>
            )}

            {/* Right Section: Navigation + Actions */}
            <div className="flex items-center gap-8">
              {/* Show ButtonUploadHistory on homepage by default, and on custom pages only when transfer is active */}
              {(() => {
                const hasActiveTransfer = Object.values(props.transfer?.transfers || {})
                  .some(t => t.isLocked && !t.isUploadFinished && !t.purging && Date.now() - (t.lastProgressUpdate || 0) < 30000);
                return !isCustomOfferPage || hasActiveTransfer ? <ButtonUploadHistory /> : null;
              })()}

              {/* Hide other elements on custom pages */}
              {!isCustomOfferPage && (
                <>
                  {/* CTA Button */}
                  <a href={`${window.location.origin}/offers`} className="hidden md:flex items-center px-6 py-1.5 bg-white hover:bg-emerald-500 text-black text-sm rounded-full transition-colors">
                    {i18n._("link_subscription_label")}
                  </a>

                  {/* Actions Section */}
                  <div className="flex items-center gap-4">
                    {/* Login/Menu Button */}
                    <LoginButtonV2
                      onModalChangeCB={setLoginOpen}
                      mod={isThemeLight ? "light" : "normal"}
                      iconOnly
                      defaultOpen={loginOpen}
                    />

                    {/* Mobile Menu Button */}
                    <BurgerMenu
                      onTrigger={setBgMenuOpen}
                      light={isThemeLight}
                      backdrop
                      className="lg:hidden"
                    >
                      <div className={`flex flex-col h-full ${isThemeLight ? 'bg-white text-gray-900' : 'bg-gray-900 text-white'}`}>
                        {/* Language Selector */}
                        <div className="absolute left-4 top-4">
                          <LangComponent
                            textClass={`hover:opacity-80 transition-opacity`}
                            dispatch={dispatch}
                          />
                        </div>

                        {/* Login Section */}
                        <div className="w-full flex flex-col items-center gap-4 mb-6 mt-8">
                          <span
                            className={`px-4 py-2 rounded-lg ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors cursor-pointer`}
                            onClick={() => {
                              if (!isLogged) {
                                setLoginOpen(true);
                                setBgMenuOpen(false);
                              }
                            }}
                          >
                            <LoginButtonV2 labelOnly />
                          </span>
                        </div>

                        <div className={`flex flex-col divide-y ${isThemeLight ? 'divide-gray-200' : 'divide-gray-700'} w-full`}>
                          {/* Primary Navigation */}
                          <div className="py-4 space-y-3">
                          <a
                              href={`${window.location.origin}/about`}
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("impact_link_label")}
                            </a>
                            <a
                              href={`${window.location.origin}/offers`}
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("link_subscribe_price_label")}
                            </a>
                            
                            <a
                              href={`/solutions/${i18n.langCode}`}
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("solution_link_label")}
                            </a>
                          </div>

                          {/* Secondary Navigation */}
                          <div className="py-4 space-y-3">
                            <a
                              href={`${window.location.origin}/collab`}
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("collab_link_label")}
                            </a>
                          </div>

                          {/* Tertiary Navigation */}
                          <div className="py-4 space-y-3">
                           

                            <a
                              hrefLang={i18n.langCode}
                              href={`/solutions/${i18n.langCode}/rse`}
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("engagement_link_label")}
                            </a>

                            <a
                              rel="noreferrer"
                              href={i18n._("footer_guide_link_url")}
                              target="_blank"
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("footer_guide_link_label")}
                            </a>

                            <a
                              href="https://blog.filevert.fr"
                              className={`block px-4 py-2 text-sm font-medium ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                            >
                              {i18n._("blog_link_label")}
                            </a>
                          </div>

                          {/* Contact Section */}
                          <div className="py-4 flex justify-center">
                            <FooterComponent
                              hideIcon
                              contactOnly
                              legales
                              contactClassName={`px-4 py-2 text-sm font-medium hover:opacity-80 transition-opacity`}
                            >
                              {i18n._("modal_contact_title")}
                            </FooterComponent>
                          </div>

                          {/* Logout Section */}
                          {isLogged && (
                            <div className="py-4 px-4">
                              <button
                                onClick={() => dispatch(userAction.logoutUser({}))}
                                className={`w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg
                                  ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                              >
                                <span className="text-sm font-medium">
                                  {i18n._("logout_label")}
                                </span>
                                <LogOut size={20} />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </BurgerMenu>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Header Opener */}
      {themeClass !== "normal" && !isCustomOfferPage && (
        <div
          onMouseEnter={() => !bgMenuOpen && setHeaderOpened(true)}
          className={`
            fixed top-0 left-0 right-0
            flex justify-center items-center
            h-16 bg-transparent z-40
            transition-all duration-300
            ${headerOpened ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0'}
          `}
        >
          <ChevronDown
            size={32}
            className={`
              hover:cursor-pointer transform hover:scale-110 transition-transform
              ${isThemeLight ? 'text-gray-900' : 'text-white'}
            `}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any): HeaderV2StateProps => ({
  app: state.app,
  user: state.user.user,
  transfer: state.transfer
});

const ConnectedHeaderV2 = connect<HeaderV2StateProps, HeaderV2DispatchProps, HeaderV2OwnProps>(
  mapStateToProps
)(withRouter(HeaderV2Component));

export const HeaderV2: React.FC<HeaderV2OwnProps> = (props) => <ConnectedHeaderV2 {...props} />;