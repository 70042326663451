import React, { useState } from "react";

export interface ISwitchButtonComponentProps {
  defaultChecked?: boolean;
  checked?: boolean;
  onSwitch?: (checked: boolean) => void;
  id: string;
  label: string;
  infos?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function SwitchButtonComponent({
  children,
  defaultChecked = false,
  checked,
  onSwitch,
  label,
  id,
  infos,
  disabled = false
}: ISwitchButtonComponentProps) {
  const [localChecked, setLocalChecked] = useState<boolean>(defaultChecked);
  const isChecked = checked !== undefined ? checked : localChecked;

  const handleChange = () => {
    if (!disabled) {
      const newChecked = !isChecked;
      if (checked === undefined) {
        setLocalChecked(newChecked);
      }
      onSwitch && onSwitch(newChecked);
    }
  };

  return (
    <>
      <div className="relative flex items-center group py-2">
        <input
          type="checkbox"
          className="sr-only peer"
          id={id}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          aria-label={label}
        />
        <label
          htmlFor={id}
          className="relative flex items-center cursor-pointer text-sm font-medium text-white/80 hover:text-white transition-colors"
        >
          <div className="group relative w-11 h-6">
            <div className={`absolute inset-0 rounded-full transition-colors duration-300 ${isChecked ? 'bg-emerald-500' : 'bg-white/10'}`} />
            <div 
              className={`
                absolute 
                top-[2px] 
                left-[2px] 
                bg-white/90
                border 
                border-white/20
                rounded-full 
                h-5 
                w-5 
                shadow-sm
                transition-all 
                duration-300 
                ease-in-out 
                transform
                ${isChecked ? 'translate-x-[20px]' : 'translate-x-0'}
              `}
            />
          </div>
          <span className="ml-3">{label}</span>
          {infos && (
            <div className="relative group">
              <div className="ml-2 w-4 h-4 rounded-full bg-white/10 flex items-center justify-center text-xs font-bold text-white/80 cursor-help">
                ?
              </div>
              <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block">
                <div className="bg-black/80 backdrop-blur-sm text-white text-sm rounded-lg py-2 px-3 shadow-lg max-w-xs border border-white/10">
                  {infos}
                  <div className="absolute left-0 top-full w-3 h-3 -mt-1.5 ml-3">
                    <div className="bg-black/80 w-3 h-3 transform rotate-45 border-r border-b border-white/10"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </label>
      </div>
      {children}
    </>
  );
}
