import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SignalHigh } from 'lucide-react';
import i18n from '../../../../utils/i18n';
import { Mo, Ko } from '../../../../Constants/sizeInO';

interface RootState {
  transfer: {
    activeTransferId: string | null;
    transfers: {
      [key: string]: {
        progress: {
          current: {
            timestamp: number;
            loaded: number;
            instantSpeed: number;
            avgSpeed: number;
          };
        };
      };
    };
  };
}

const selectSpeedStats = (state: RootState) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : null;

  return {
    progress: activeTransfer?.progress || {
      current: { timestamp: Date.now(), loaded: 0, instantSpeed: 0, avgSpeed: 0 }
    }
  };
};

export const SpeedDisplay: React.FC = () => {
  const { progress } = useSelector(selectSpeedStats);
  const speedHistoryRef = useRef<number[]>([]);
  const SPEED_HISTORY_SIZE = 60;

  const speed = useMemo(() => {
    // Récupérer les vitesses instantanée et moyenne
    const instantSpeed = progress.current.instantSpeed || 0;
    const avgSpeed = progress.current.avgSpeed || 0;
    
    // Utiliser une moyenne pondérée au lieu du maximum
    const effectiveSpeed = (instantSpeed * 0.3 + avgSpeed * 0.7);

    // Convertir la vitesse en Mo/s avec 2 décimales pour plus de précision
    const speedMB = effectiveSpeed / Mo;

    // Formater la vitesse avec l'unité appropriée
    if (speedMB >= 1) {
      return `${speedMB.toFixed(2)} Mo/s`;
    } else {
      const speedKB = effectiveSpeed / Ko;
      return `${speedKB.toFixed(0)} Ko/s`;
    }
  }, [progress]);

  return (
    <div className="flex flex-col items-center space-y-2">
      <SignalHigh className="w-4 h-4 text-primary" />
      <div className="text-center">
        <span className="font-medium">{speed}</span>
        <span className="block text-xs text-gray-500">{i18n._("upload_progress_speed")}</span>
      </div>
    </div>
  );
};

SpeedDisplay.displayName = 'SpeedDisplay';
