import { assetsApi } from "../Api";
import { i18n } from "./index";

const defaultLang = "fr_FR";

class I18n {
  strings: any;
  lang: string;
  langChoices: string[];
  isReady: boolean = false;

  get langCode() {
    return this.lang.substring(0, 2);
  }

  constructor() {
    this.lang = defaultLang;
    this.langChoices = [];
    this.init().then(() => (this.isReady = true));
  }

  // _ = (string: string): string => this.strings[string];

  _(key: string, args?: string | string[] | any): string | any {
    if (!key) return "";

    if (!this.strings || !this.lang || !this.strings[this.lang]) {
      return "";
    }

    if (typeof args === "string" || typeof args === "number") args = [args];

    let result: string | any = "";

    if (!key.includes(".")) {
      result = this.strings[this.lang][key];
    } else {
      key.split(".").forEach((kPark: string) => {
        try {
          if (kPark?.includes("[")) {
            const kPartIndex = parseInt(kPark.split("[")[1]);
            kPark = kPark.split("[")[0];
            result =
              result[kPark][kPartIndex] ||
              this.strings[this.lang][kPark][kPartIndex];
            return;
          }
          result = result[kPark] || this.strings[this.lang][kPark];
        } catch (e) {}
      });
    }

    if (result) {
      if (args) {
        result = result.replace(/{(\d+)}/g, function (match: any, number: any) {
          return typeof args[number] !== "undefined" ? args[number] : "";
        });
      }
      return result;
    } else {
      console.warn("Missing key in translation file : ", key);
      return "";
    }
  }

  init = async () => {
    //TODO: Mettre en cache les strings
    let forceLang: boolean = false;
    try {
      const now = new Date();
      const ttl = 1000 * 3600 * 24;

      let apiInfos: any = localStorage.getItem("apiInfos");

      if (apiInfos) apiInfos = JSON.parse(apiInfos);
      //TODO: Remove hack for cache in the futur
      else apiInfos = { hasToUpdate: true };

      let stringsFromLocalStorage = localStorage.getItem("strings");
      let strings;

      if (stringsFromLocalStorage) {
        stringsFromLocalStorage = JSON.parse(stringsFromLocalStorage);
        // prettier-ignore
        //@ts-ignore
        if (stringsFromLocalStorage && stringsFromLocalStorage.value && stringsFromLocalStorage.ttl > now) {
          // prettier-ignore
  //@ts-ignore
          strings = stringsFromLocalStorage.value;
        }
      }

      strings =
        strings && (!apiInfos || !apiInfos?.hasToUpdate)
          ? strings
          : await assetsApi.getStrings();
      this.strings = strings;
      
      // Vérifier si nous sommes sur la page de téléchargement directement
      const isDownloadPage = window.location.pathname.startsWith('/d/');

      this.langChoices = Object.keys(this.strings).sort((l1, l2) =>
        this.strings[l1].order < this.strings[l2].order ? -1 : 1
      );

      // Vérifier d'abord s'il y a une langue stockée dans localStorage (changement manuel)
      const storedLang = localStorage.getItem("lang");
      
      // Obtenir la langue du navigateur comme fallback
      let userLang = navigator.language;
      userLang = userLang
        ? `${userLang.split("-")[0]}_${userLang.split("-")[0].toUpperCase()}`
        : defaultLang;

      const urlSearchParams = new URLSearchParams(document.location.search);
      const urlLang = urlSearchParams.get("hl");
      
      // Priorité 1: Paramètre hl dans l'URL
      if (urlLang) {
        if (urlLang !== 'fr') {
          // Pour les langues autres que le français, utiliser le paramètre hl
          userLang = `${urlLang.toLowerCase()}_${urlLang.toUpperCase()}`;
          forceLang = Object.keys(this.strings).includes(userLang);
          
          // Si nous sommes sur la page de téléchargement, stocker la langue sans rediriger
          if (isDownloadPage) {
            localStorage.setItem("lang", userLang);
          }
        } else {
          // Si l'URL contient hl=fr, rediriger vers la même URL sans ce paramètre
          // Mais seulement si nous ne sommes pas sur la page de téléchargement
          if (!isDownloadPage) {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete('hl');
            
            // Si aucun paramètre restant, supprimer complètement le ? de l'URL
            if (currentUrl.search === '?') {
              window.history.replaceState({}, document.title, currentUrl.href.replace('?', ''));
            } else {
              window.history.replaceState({}, document.title, currentUrl.toString());
            }
          }
          
          // Forcer la langue française
          userLang = defaultLang;
          forceLang = true;
        }
      }
      // Si nous sommes sur la page de téléchargement sans paramètre hl
      else if (isDownloadPage && !urlLang) {
        // Vérifier s'il y a une langue stockée dans localStorage
        const storedLang = localStorage.getItem("lang");
        
        if (storedLang && Object.keys(this.strings).includes(storedLang)) {
          // Utiliser la langue stockée
          userLang = storedLang;
          
          // Si la langue n'est pas le français, ajouter le paramètre hl à l'URL
          if (storedLang.split("_")[0] !== 'fr') {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('hl', storedLang.split("_")[0]);
            
            // Mettre à jour l'URL sans redirection
            window.history.replaceState({}, document.title, currentUrl.toString());
            console.log('[i18n] Ajout du paramètre hl à l\'URL de téléchargement:', storedLang.split("_")[0]);
          }
        }
        // Sinon, utiliser la langue du navigateur
        else if (userLang.split("_")[0] !== 'fr' && Object.keys(this.strings).includes(userLang)) {
          // Ajouter le paramètre hl à l'URL
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set('hl', userLang.split("_")[0]);
          
          // Mettre à jour l'URL sans redirection
          window.history.replaceState({}, document.title, currentUrl.toString());
          console.log('[i18n] Ajout du paramètre hl à l\'URL de téléchargement (langue navigateur):', userLang.split("_")[0]);
        }
      }
      // Priorité 2: Langue stockée dans localStorage (changement manuel)
      else if (storedLang && Object.keys(this.strings).includes(storedLang)) {
        userLang = storedLang;
        
        // Si la langue stockée n'est pas le français et qu'il n'y a pas de paramètre hl,
        // rediriger vers la même URL avec le paramètre hl approprié
        // Mais seulement si nous ne sommes pas sur la page de téléchargement
        if (storedLang.split("_")[0] !== 'fr' && !isDownloadPage) {
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set('hl', storedLang.split("_")[0]);
          
          // Redirection avec window.history pour éviter le rechargement complet de la page
          window.history.replaceState({}, document.title, currentUrl.toString());
        }
      }
      // Priorité 3: Langue du navigateur
      else if (userLang.split("_")[0] !== 'fr' && Object.keys(this.strings).includes(userLang)) {
        // Rediriger vers la même URL avec le paramètre hl approprié
        // Mais seulement si nous ne sommes pas sur la page de téléchargement
        if (!isDownloadPage) {
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set('hl', userLang.split("_")[0]);
          
          // Redirection avec window.history pour éviter le rechargement complet de la page
          window.history.replaceState({}, document.title, currentUrl.toString());
        }
      }

      const rootStorage = localStorage.getItem("strings");
      if (rootStorage) {
        const appStorage = JSON.parse(rootStorage).app;

        if (appStorage && !forceLang) {
          userLang = JSON.parse(appStorage).LANG || userLang;
        }
      }

      // Si une redirection est en cours, ne pas continuer l'initialisation
      if (forceLang) {
        this.lang = userLang;
      } else {
        // Priorité claire pour la sélection de langue
        if (storedLang && Object.keys(this.strings).includes(storedLang)) {
          // 1. Langue stockée dans localStorage (changement manuel)
          this.lang = storedLang;
        } else if (Object.keys(this.strings).includes(userLang)) {
          // 2. Langue du navigateur si elle est supportée
          this.lang = userLang;
        } else {
          // 3. Langue par défaut (français)
          this.lang = defaultLang;
        }
      }

      // Préserver les données existantes du localStorage lors de la mise à jour des strings
      const existingData = localStorage.getItem("strings");
      let dataToStore = { ttl: now.getTime() + ttl, value: strings };
      
      if (existingData) {
        try {
          const parsedData = JSON.parse(existingData);
          // Conserver les autres propriétés qui pourraient exister
          if (parsedData && typeof parsedData === 'object') {
            dataToStore = { ...parsedData, ttl: now.getTime() + ttl, value: strings };
          }
        } catch (e) {
          console.warn("Erreur lors de l'analyse des données existantes du localStorage:", e);
        }
      }
      
      localStorage.setItem("strings", JSON.stringify(dataToStore));
      this.initHTMLSEOValues();

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  initRSS = async (forceReload = false) => {
    if (i18n.isReady) {
      // Suppression du log d'initialisation RSS
      const now = new Date();
      const ttl = 1000 * 3600 * 24;
      
      try {
        const langToUse = this.lang;
        // Suppression du log de langue utilisée
        
        // Vérifier si on doit forcer le rechargement ou si le cache est valide
        let rss;
        const storedRSS = JSON.parse(localStorage.getItem("rss") || "{}");
        const isCacheValid = storedRSS.ttl > now.getTime() && storedRSS.lang === langToUse && !forceReload;
        
        if (isCacheValid) {
          rss = storedRSS.value;
        } else {
          rss = await assetsApi.getRSS(langToUse);
          
          if (!rss?.annonces?.length) {
            console.warn('[i18n] Aucune annonce trouvée pour la langue:', langToUse);
          }
          
          // Stockage dans localStorage
          const storageData = {
            ttl: now.getTime() + ttl,
            value: rss,
            lang: langToUse
          };
          
          // Suppression du log de stockage
          
          localStorage.setItem("rss", JSON.stringify(storageData));
          
          // Vérification du stockage
          const storedData = JSON.parse(localStorage.getItem("rss") || "{}");
          // Suppression du log de vérification de stockage
        }
        
        return rss;
      } catch (error) {
        console.error('[i18n] Erreur lors du chargement des annonces:', error);
        return { annonces: [] };
      }
    } else {
      setTimeout(this.initRSS, 500);
    }
  };

  getLang = () => this.lang;
  getLangChoices = () => this.langChoices;

  changeLang = async (lang: string) => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    this.lang = lang;
    
    // Extraire le code de langue (2 premiers caractères)
    const langCode = lang.substr(0, 2);
    
    // Vérifier si nous sommes sur la page de téléchargement
    const isDownloadPage = window.location.pathname.startsWith('/d/');
    
    // Mettre à jour le localStorage AVANT toute redirection
    // Cela garantit que la préférence de langue est sauvegardée même si la page est rechargée
    localStorage.setItem("lang", this.lang);
    console.log('[i18n] Langue sauvegardée dans localStorage:', this.lang);
    
    // Forcer la mise à jour des traductions dans le DOM
    this.initHTMLSEOValues();
    
    // Réinitialiser les annonces pour la nouvelle langue avec rechargement forcé
    await this.initRSS(true);
    
    // Sur la page de téléchargement, mettre à jour l'URL sans redirection complète
    if (isDownloadPage) {
      console.log('[i18n] Page de téléchargement détectée, mise à jour de l\'URL sans redirection');
      
      // Mettre à jour l'URL avec le paramètre hl approprié, mais sans recharger la page
      if (langCode === 'fr') {
        // Pour le français: supprimer le paramètre hl s'il existe
        if (urlSearchParams.has("hl")) {
          urlSearchParams.delete("hl");
          const newUrl = window.location.pathname + (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '');
          window.history.replaceState({}, document.title, newUrl);
        }
      } else {
        // Pour les autres langues: ajouter ou mettre à jour le paramètre hl
        urlSearchParams.set("hl", langCode);
        const newUrl = window.location.pathname + `?${urlSearchParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
      }
      
      // Forcer une mise à jour des composants qui utilisent i18n
      // en émettant un événement personnalisé
      try {
        const event = new CustomEvent('i18n-language-changed', { detail: { lang: this.lang } });
        window.dispatchEvent(event);
        console.log('[i18n] Événement de changement de langue émis:', this.lang);
      } catch (e) {
        console.warn('[i18n] Erreur lors de l\'émission de l\'événement de changement de langue:', e);
      }
      
      return;
    }
    
    // Gérer l'URL en fonction de la langue
    if (langCode === 'fr') {
      // Pour le français: supprimer le paramètre hl s'il existe
      if (urlSearchParams.has("hl")) {
        urlSearchParams.delete("hl");
        
        // Mettre à jour l'URL
        const newSearch = urlSearchParams.toString();
        
        if (newSearch === '') {
          // Si aucun paramètre, supprimer complètement le ? de l'URL
          const newUrl = window.location.href.split('?')[0];
          window.location.href = newUrl;
        } else {
          document.location.search = newSearch;
        }
      } else if (document.location.search.includes('hl=')) {
        // Si on passe du français avec un paramètre hl quelconque, recharger la page sans ce paramètre
        window.location.href = window.location.href.replace(/(\?|&)hl=[^&]*(&|$)/, '$1').replace(/\?$/, '');
      }
    } else {
      // Pour les autres langues: ajouter ou mettre à jour le paramètre hl
      urlSearchParams.set("hl", langCode);
      
      // Mettre à jour l'URL
      const newSearch = urlSearchParams.toString();
      if (newSearch !== document.location.search.substring(1)) {
        document.location.search = newSearch;
      }
    }
  };

  initHTMLSEOValues = () => {
    const htmlLang = this.lang.split("_")[0];
    const htmlDOM = document.getElementsByTagName("html")[0];
    const titleDOM: any = document.querySelector("title");
    const descriptionDOM: any = document.querySelector(
      'meta[name="description"]'
    );

    htmlDOM.setAttribute("xml:lang", htmlLang);
    htmlDOM.setAttribute("lang", htmlLang);

    titleDOM.setAttribute("xml:lang", htmlLang);
    titleDOM.setAttribute("lang", htmlLang);
    titleDOM.setAttribute("hreflang", htmlLang);

    descriptionDOM.setAttribute("xml:lang", htmlLang);
    descriptionDOM.setAttribute("lang", htmlLang);
    descriptionDOM.setAttribute("hreflang", htmlLang);
    descriptionDOM.setAttribute(
      "content",
      i18n._("fv_website_meta_description")
    );

    titleDOM.innerText = i18n._("fv_website_meta_title");
  };
}

const _t = new I18n();

export default _t;
