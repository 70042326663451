import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AsyncHelmet from './AsyncHelmet';

interface AsyncSEORouteProps extends RouteProps {
  title?: string;
  description?: string;
  path: string;
  keywords?: string;
  lang?: string;
}

/**
 * Composant de route avec pré-rendu pour améliorer l'indexation par les moteurs de recherche
 * Ce composant permet de pré-rendre le contenu de la page pour les robots d'indexation
 */
const AsyncRouteWithSEO: React.FC<AsyncSEORouteProps> = ({
  title,
  description,
  component: Component,
  path,
  keywords,
  lang = "fr",
  ...rest
}) => {
  const [content, setContent] = useState<React.ReactNode>(null);
  const [isPrerendering, setIsPrerendering] = useState(false);
  
  // Détecter si nous sommes dans un contexte de pré-rendu (robot d'indexation)
  useEffect(() => {
    // Détection des robots d'indexation
    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
    setIsPrerendering(isBot);
    
    // Créer une instance du composant pour le pré-rendu
    if (Component) {
      try {
        // Créer une instance du composant avec des props minimales
        const componentInstance = React.createElement(Component as any, {
          location: { pathname: path },
          match: { params: {}, path, url: path }
        });
        setContent(componentInstance);
      } catch (error) {
        console.error('Error creating component instance:', error);
      }
    }
  }, [Component, path]);
  
  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={props => {
        // Détection de la langue à partir des paramètres d'URL
        const urlParams = new URLSearchParams(props.location.search);
        const detectedLang = urlParams.get('hl') || lang;
        
        return (
          <>
            <AsyncHelmet
              title={title}
              description={description}
              path={path}
              lang={detectedLang}
              keywords={keywords}
              content={content}
              isPrerender={isPrerendering}
            />
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export default AsyncRouteWithSEO;