import React, { useMemo } from 'react';
import './ConnectionQualityBars.css';
import TooltipComponent from '../Tooltip/Tooltip';
import { i18n } from '../../utils';

interface Props {
  quality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';
}

const ConnectionQualityBars: React.FC<Props> = ({ quality }) => {
  // Memoize the tooltip content to avoid unnecessary re-renders
  const tooltipContent = useMemo(() => {
    switch (quality) {
      case 'excellent':
        return i18n._('connection_quality_excellent');
      case 'good':
        return i18n._('connection_quality_good');
      case 'medium':
        return i18n._('connection_quality_medium');
      case 'poor':
        return i18n._('connection_quality_poor');
      default:
        return i18n._('connection_quality_unknown');
    }
  }, [quality]);

  // Determine the number of active bars based on connection quality
  const activeBarCount = useMemo(() => {
    switch (quality) {
      case 'excellent':
        return 4;
      case 'good':
        return 3;
      case 'medium':
        return 2;
      case 'poor':
        return 1;
      default:
        return 0;
    }
  }, [quality]);

  // Determine the color class based on connection quality
  const qualityColorClass = useMemo(() => {
    switch (quality) {
      case 'excellent':
        return 'quality-excellent';
      case 'good':
        return 'quality-good';
      case 'medium':
        return 'quality-medium';
      case 'poor':
        return 'quality-poor';
      default:
        return 'quality-unknown';
    }
  }, [quality]);

  return (
    <TooltipComponent content={tooltipContent} position="bottom">
      <div 
        className={`connection-quality-bars ${qualityColorClass} configuration-phase`} 
        role="status" 
        aria-label={tooltipContent}
      >
        {[1, 2, 3, 4].map((bar) => (
          <div
            key={bar}
            className={`bar ${bar <= activeBarCount ? 'active' : ''}`}
            aria-hidden="true"
          />
        ))}
      </div>
    </TooltipComponent>
  );
};

export default ConnectionQualityBars;
