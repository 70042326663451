import axios, { AxiosError, AxiosResponse } from "axios";
import { logger } from "../utils/logger";

// Logger centralisé pour userApi
const log = logger({ service: 'userApi' });
const isDev = process.env.NODE_ENV !== 'production';

interface Credentials {
  email: string;
  password: string;
}

interface User {
  id: string;
  user_id?: string;
  referer_id?: string;
  subscriptionStatus?: string;
  subscriptions?: {
    data: Array<{
      status: string;
    }>;
  };
}

interface AuthResponse {
  user?: User;
  token?: string;
  error?: {
    code: string;
    message: string;
  };
}

interface CustomResponse {
  data: {
    user?: User;
    token?: string;
    error?: {
      code: string;
      message: string;
    };
  };
  status: number;
}

function handleError(e: AxiosError | any) {
  if (e?.response?.data) {
    // Si l'erreur contient un code, on le conserve dans la structure
    if (e.response.data.code) {
      return {
        error: {
          code: e.response.data.code,
          message: e.response.data.message || 'Une erreur est survenue'
        }
      };
    }
    return { error: e.response.data };
  }
  return Promise.reject(e);
}

const userApi = {
  async updateUserApiKey(
    { id }: { id: number },
    action: "create" | "regenerate" | "revoke"
  ) {
    try {
      const result = await axios.get(`/user/${id}/api_key`, {
        params: {
          action,
        },
      });
      return result.data;
    } catch (error: any) {
      return error?.response?.data || error;
    }
  },

  async confirmUserFromInvitationToken(token: string) {
    try {
      const result = await axios.get(`/user/invitation_confirmation/${token}`);
      return result.data;
    } catch (error: any) {
      return error?.response?.data || error;
    }
  },

  async createUserFromInvitation(id: number, payload: any) {
    try {
      const response = await axios.post(`/user/${id}/member`, payload, {
        headers: { "x-invitation-link": document.location.href },
      });
      return response.data;
    } catch (error: any) {
      if (!error?.response?.data) return error;

      switch (error.response.data.code) {
        case "UNAVAILABLE_EMAIL":
          return {
            error: { field: "email", reason: "unavailable" },
          };
        default:
          return {
            error: { field: "unknown", reason: "unknown" },
          };
      }
    }
  },

  async getInvitationByLink() {
    try {
      const response = await axios.get(`/user/invitation_request`, {
        headers: { "x-invitation-link": document.location.href },
      });
      return response.data;
    } catch (e: any) {
      const error = e?.response?.data || e;
      return Promise.reject(error);
    }
  },

  async resetInviteInviteLink({ id, invitation_link }: any) {
    try {
      const response = await axios.patch(`/user/${id}/invitation_link`, {
        invitation_link,
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  },

  async resumeIncompletePayment(userId: number, paymentIntentId: string, token: string): Promise<string> {
    try {
      if (isDev) log.debug(`Resuming incomplete payment for user: ${userId}`);
      const response = await axios.post(
        `/stripe/resume-payment`,
        { userId, paymentIntentId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (isDev) log.debug('Resume payment response received');
      if (response.data && response.data.url) {
        return response.data.url;
      } else {
        throw new Error('No payment URL returned from server');
      }
    } catch (error) {
      log.error('Error in resumeIncompletePayment');
      throw error;
    }
  },

  async getStripePaymentUrl(userId: number, customerId: string, token: string): Promise<string> {
    try {
      if (isDev) log.debug(`Requesting Stripe payment URL for user: ${userId}`);
      const response = await axios.post(
        `/stripe/reactivate-subscription`,
        { userId, customerId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (isDev) log.debug('Stripe payment URL response received');
      if (response.data && response.data.url) {
        return response.data.url;
      } else {
        throw new Error('No payment URL returned from server');
      }
    } catch (error) {
      log.error('Error in getStripePaymentUrl');
      throw error;
    }
  },

  async reactivateSubscription(
    sId: string,
    user_id: string,
    token: string
  ): Promise<AxiosResponse & any> {
    try {
      if (isDev) log.debug(`reactivateSubscription: Starting with sId: ${sId}, user_id: ${user_id}`);
      const result = await axios.patch(`/user/${user_id}/subscription/${sId}`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Client-Version': process.env.REACT_APP_VERSION || '1.0.0'
        }
      });
      if (isDev) log.debug('reactivateSubscription: Success response received');
      return result.data;
    } catch (e) {
      log.error('reactivateSubscription: Error occurred');
      const error = e as AxiosError;
      if (error.response?.data?.error) {
        return error.response.data;
      }
      return {
        error: {
          code: "FATAL_ERROR",
          message: "Une erreur est survenue lors de la réactivation"
        },
      };
    }
  },



  async manageBilling(user: any): Promise<any> {
    try {
      if (isDev) log.debug('manageBilling called');

      if (!user?.id) {
        throw new Error('ID utilisateur manquant');
      }

      if (!user?.email) {
        throw new Error('Email utilisateur manquant');
      }

      if (isDev) log.debug(`Creating billing session for user: ${user.id}`);
      
      const requestData = {
        return_url: window.location.href,
        email: user.email,
        customer_id: user.customer_id,
        subscriptionStatus: user.subscriptionStatus
      };
      if (isDev) log.debug('Request data prepared');

      const response = await axios.put(`/user/${user.id}/manage-billing`, requestData);
      if (isDev) log.debug('Response received from server');

      if (!response?.data?.url) {
        throw new Error('Réponse inattendue du serveur');
      }

      return response.data;
    } catch (error) {
      log.error('Error in manageBilling');
      
      let errorMessage = 'Erreur lors de la création de la session de facturation';
      
      if (typeof error === 'object' && error !== null) {
        const axiosError = error as {
          response?: {
            status?: number,
            data?: any
          }
        };
        
        if (axiosError.response?.status === 500) {
          errorMessage = 'Le service de facturation est temporairement indisponible. Veuillez réessayer plus tard.';
        } else if (axiosError.response?.data) {
          errorMessage = JSON.stringify(axiosError.response.data);
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }
      }
      
      log.error(`Final error message: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  },

  async checkEmail(email: string): Promise<any> {
    try {
      let result: AxiosResponse & any = await axios.get(`/user/check/${email}`);

      if (result.error) return result;

      return result && result.data ? result.data : null;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getConnexionInfos(): Promise<any> {
    const connexionInfos = await axios.get("/ip");

    return connexionInfos?.data;
  },

  async getReferer(user_id: number): Promise<any> {
    try {
      if (isDev) log.debug(`Getting referer for user: ${user_id}`);
      const response = await axios.get(`/user/${user_id}/referer`);
      if (isDev) log.debug('Referer response received');
      return response.data;
    } catch (error) {
      log.error('Error getting referer');
      return null;
    }
  },

  async getUserActivity(user_id: number, dateRange?: string): Promise<any> {
    try {
      // Si dateRange est 'all', ajouter un paramètre pour récupérer tous les transferts
      const params = dateRange === 'all' ? { all_history: true } : {};
      const { data } = await axios.get(`/user/${user_id}/activity`, { params });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async auth(credentials: Credentials, confirm?: boolean): Promise<any> {
    try {
      if (isDev) log.debug('Tentative d\'authentification');
      const response = await axios.post(
        `/user/auth`,
        credentials,
        {
          params: {
            confirm,
          },
        }
      );

      const data = response.data;
      if (isDev) log.debug('Réponse du serveur reçue');

      // Si nous avons une erreur dans la réponse
      if (data?.error) {
        return {
          error: {
            code: data.error.code,
            message: data.error.code === 'NOT_FOUND' ? 'Identifiants incorrects' : data.error.message
          }
        };
      }

      // Si nous avons un utilisateur dans la réponse
      if (data?.user) {
        data.user.user_id = data.user.id;
        if (isDev) log.debug('Utilisateur authentifié');
        
        // Gérer le statut d'abonnement
        if (data.user.referer_id) {
          // Si c'est un membre, utiliser le statut fourni par le serveur
          if (isDev) log.debug(`Membre détecté, utilisation du statut fourni: ${data.user.subscriptionStatus}`);
        } else if (data.user.subscriptions?.data?.[0]) {
          // Si c'est un propriétaire, utiliser ses propres données d'abonnement
          data.user.subscriptionStatus = data.user.subscriptions.data[0].status;
          if (isDev) log.debug(`Propriétaire détecté, statut d'abonnement: ${data.user.subscriptionStatus}`);
        }
        
        // Inclure le token dans la réponse
        if (data.token) {
          if (isDev) log.debug('Token reçu');
          return { user: data.user, token: data.token, error: null };
        } else {
          log.warn('Token non trouvé dans la réponse');
          return { user: data.user, error: null };
        }
      }
      
      log.warn('Réponse inattendue du serveur');
      return { 
        error: {
          code: 'INVALID_RESPONSE',
          message: 'Une erreur est survenue lors de la connexion'
        }
      };
    } catch (e: any) {
      log.error('Erreur lors de l\'authentification');
      // Si l'erreur vient directement du backend
      if (e.response?.status === 404) {
        return {
          error: {
            code: 'NOT_FOUND',
            message: 'Identifiants incorrects'
          }
        };
      }
      
      // Si l'erreur a un code spécifique
      if (e.response?.data?.code) {
        return {
          error: {
            code: e.response.data.code,
            message: e.response.data.message
          }
        };
      }

      // Si c'est une erreur réseau ou autre
      return {
        error: {
          code: 'ERROR',
          message: 'Une erreur est survenue lors de la connexion'
        }
      };
    }
  },

  async simpleRegister(userInfos: any): Promise<any> {
    try {
      const response: AxiosResponse & any = await axios.post(
        `/user/register`,
        userInfos
      );

      if (response.error) {
        return { error: response.error };
      }

      if (response.data) {
        return response.data;
      }
    } catch (e) {
      return handleError(e);
    }
  },

  async sendResetLink(email: any): Promise<AxiosResponse & any> {
    try {
      const user = await axios.post(`/user/reset-password`, email);
      return user.data;
    } catch (e) {
      log.error('Erreur lors de l\'envoi du lien de réinitialisation');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async checkResetToken(token: any, email?: string): Promise<AxiosResponse & any> {
    try {
      const user = await axios.get(`/user/confirm/${token}?email=${email}`);
      return user.data;
    } catch (e: any) {
      return (
        e?.response?.data || {
          error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
        }
      );
    }
  },

  async update(
    user?: any,
    user_id?: number,
    config?: any
  ): Promise<AxiosResponse & any> {
    try {
      let params: any = {};
      if (user?.tva_number) {
        // const updatedTva = await axios.put(`/user/${user_id}/tva`, {
        //   tva_number: user?.tva_number,
        // });
        delete user.tva_number;
      }
      const updatedUser = await axios.put(
        `/user/${user_id}`,
        { user },
        config
      );
  
      return updatedUser && updatedUser.data ? updatedUser.data : null;
    } catch (e: any) {
      return Promise.reject(e?.response?.data || e);
    }
  },

  async getTeamMembers(user_id: string): Promise<AxiosResponse & any> {
    if (!user_id) return false;
    try {
      const members = await axios.get(`/user/${user_id}/member`);
      return members.data;
    } catch (e) {
      log.error('Erreur lors de la récupération des membres de l\'équipe');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async inviteMember(
    user_id: number,
    member: string
  ): Promise<AxiosResponse & any> {
    if (!member || !member.length) return null;

    try {
      const result = await axios.post(`/user/${user_id}/member`, { member });
      return result.data;
    } catch (e) {
      log.error('Erreur lors de l\'invitation d\'un membre');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async deleteMembers(
    user_id: number,
    members: any[],
    force?: boolean
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.put(`/user/${user_id}/member/delete`, {
        members,
        force,
      });
      return result.data?.user?.members || null;
    } catch (e) {
      log.error('Erreur lors de la suppression des membres');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async getCSVConsumptionReport(
    format: string = "xlsx",
    user_id: number
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios({
        method: "GET",
        url: `/user/${user_id}/consumption/export?format=${format}`,
        responseType: "arraybuffer",
      });
      return result.data || null;
    } catch (e) {
      log.error('Erreur lors de la récupération du rapport de consommation');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async cancelSubscription(
    sId: string,
    user_id?: any
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.delete(`/user/${user_id}/subscription/${sId}`);
      return result.data || null;
    } catch (e) {
      log.error('Erreur lors de l\'annulation de l\'abonnement');
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async reactivateExistingSubscription(
    sId: string,
    user_id?: any
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.patch(`/user/${user_id}/subscription/${sId}`);
      return result.data || null;
    } catch (e) {
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async userVerification() {
    try {
      // Récupérer le token depuis le localStorage
      const token = localStorage.getItem('fv-auth-token');
      if (!token) {
        return {
          error: { code: "AUTH_ERROR", message: "Token manquant" }
        };
      }

      // Configurer axios avec le token
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      const result = await axios.get(`/user/relog`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (result.data?.error) {
        return result.data;
      }

      return result.data;
    } catch (e) {
      return {
        error: {
          code: "FATAL_ERROR",
          message: "Une erreur est survenue lors de la vérification"
        }
      };
    }
  },

  async deleteFile({ bucket, key }: { bucket: string; key: string }) {
    try {
      const result = await axios.delete(`/s3/delete`, {
        data: { bucket, key }
      });
      return result.data || null;
    } catch (e) {
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async checkStripeCustomer(email: string): Promise<any> {
    try {
      const response = await axios.get(`/stripe/check-customer/${email}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getUser(user_id: string): Promise<any> {
    try {
      const response = await axios.get(`/user/${user_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async createUpgradeSession({
    user_id,
    newOffer,
    token,
    currentPermissions
  }: {
    user_id: string;
    newOffer: any;
    token: string;
    currentPermissions?: {
      team_size?: { value: number; id: number; };
      upload_size?: { value: number; id: number; };
      upload_ttl?: { value: number; id: number; };
      customization?: { value: boolean; id: number; };
      transfer_notification?: { value: boolean; id: number; };
      transfer_authentication?: { value: boolean; id: number; };
      consumption_export?: { value: boolean; id: number; };
      outlook_addin?: { value: boolean; id: number; };
      studio?: { value: boolean; id: number; };
      transfer_tracking?: { value: boolean; id: number; };
    };
  }): Promise<any> {
    try {
      // Récupérer le token depuis le localStorage si non fourni ou vide
      if (!token) {
        const localToken = localStorage.getItem('fv-auth-token');
        
        if (!localToken) {
          throw new Error('Token d\'authentification manquant');
        }
        
        token = localToken;
      }
      
      // Vérifier que le token est valide (format basique)
      if (!token.includes('.') || token.split('.').length !== 3) {
        throw new Error('Format de token invalide');
      }
      
      // Normaliser l'intervalle pour Stripe
      const normalizeInterval = (period: string | undefined, interval: string | undefined): 'month' | 'year' => {
        // Priorité à l'intervalle s'il est défini correctement
        if (interval === 'month' || interval === 'year') {
          return interval;
        }
        // Sinon, déduire de la période
        if (period === 'monthly' || period === 'month') {
          return 'month';
        }
        if (period === 'yearly' || period === 'year') {
          return 'year';
        }
        // Valeur par défaut
        return 'month';
      };

      const normalizedInterval = normalizeInterval(newOffer.period, newOffer.interval);
      
      const requestBody = {
        products: {
          name: 'Custom',
          team_size: { value: newOffer.team_size.value },
          upload_size: { value: newOffer.upload_size.value },
          upload_ttl: { value: newOffer.upload_ttl.value },
          transfer_notification: { value: newOffer.transfer_notification.value },
          transfer_authentication: { value: newOffer.transfer_authentication.value },
          transfer_tracking: { value: newOffer.transfer_tracking.value },
          consumption_export: { value: newOffer.consumption_export.value },
          customization: { value: newOffer.customization.value },
          outlook_addin: { value: newOffer.outlook_addin.value },
          studio: { value: newOffer.studio.value },
          interval: normalizedInterval
        },
        proration_behavior: newOffer.proration_behavior || 'always_invoice'
      };

      // Mettre à jour le corps de la requête avec les métadonnées au niveau racine
      const updatedRequestBody = {
        ...requestBody,
        metadata: {
          isStandardOffer: 'true',
          user_id: String(user_id),
          interval: normalizedInterval,
          original_period: newOffer.period || '',
          team_size: String(newOffer.team_size.value),
          upload_size: String(newOffer.upload_size.value),
          upload_ttl: String(newOffer.upload_ttl.value),
          transfer_notification: String(newOffer.transfer_notification.value),
          transfer_authentication: String(newOffer.transfer_authentication.value),
          transfer_tracking: String(newOffer.transfer_tracking.value),
          consumption_export: String(newOffer.consumption_export.value),
          customization: String(newOffer.customization.value),
          outlook_addin: String(newOffer.outlook_addin.value),
          studio: String(newOffer.studio.value)
        }
      };

      // Configurer les headers avec le token
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Client-Version': process.env.REACT_APP_VERSION || '1.0.0'
      };
      
      // Définir explicitement les defaults headers pour cette requête
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Utiliser l'URL complète pour éviter les problèmes de base URL
      const response = await axios.put(
        '/stripe/session',
        updatedRequestBody,
        { headers }
      );

      return response.data;
    } catch (error: any) {
      const axiosError = error as AxiosError;
      
      // Si c'est une erreur 401, essayer de récupérer plus d'informations
      if (axiosError.response?.status === 401) {
        // Vérifier si le token est toujours valide en faisant une requête simple
        try {
          await axios.get('/user/relog', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
        } catch (testError) {
          // Token invalide, ne rien faire de spécial
        }
      }
      
      throw axiosError;
    }
  },
};

export default userApi;
