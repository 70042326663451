import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { ConsumptionItemComponent } from "./Components";
import { Link } from "react-router-dom";
import { convertSize, i18n } from "../../utils";
import { appAction } from "../../Store/Actions";
import { isAndroid, isMobile, isTablet } from "react-device-detect";
import { contactApi } from "../../Api";
import { gradients } from "../../Constants";
import FileVertGuideTooltip from "../../Components/Overlays/FileVertGuideTooltip";
import { 
  Zap,
  Mail,
  X,
  UploadCloudIcon,
  Droplet,
  Leaf,
  Car,
  HelpCircle,
  ExternalLink,
} from 'lucide-react';
const { version } = require("../../../package.json");

const iconStyle = {
  fontSize: '1.5rem',
  color: 'white',
  filter: 'drop-shadow(0 0 2px rgba(255, 255, 255, 0.7))',
} as const;

const mobileIconStyle = {
  fontSize: '1.2rem',
  color: 'white',
  filter: 'drop-shadow(0 0 2px rgba(255, 255, 255, 0.7))',
} as const;

// Types
interface RootState {
  user?: {
    user?: {
      role?: number;
      permissions?: {
        upload_size?: {
          value: number;
        };
      };
      limit_total?: number;
      current_uploaded_size?: number;
    };
  };
  app: Record<string, any>;
}

// Composant FloatingInfoButton
const FloatingInfoButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(() => {
    return localStorage.getItem('infoButtonClicked') === 'true';
  });

  const handleClick = () => {
    onClick();
    if (!hasBeenClicked) {
      setHasBeenClicked(true);
      localStorage.setItem('infoButtonClicked', 'true');
    }
  };

  return (
    <button
      onClick={handleClick}
      className="absolute right-4 bottom-24 z-50 p-3 bg-white text-black rounded-full shadow-lg hover:bg-gray-100 transition-all duration-300 border border-gray-200 group"
      aria-label="Informations"
    >
      <HelpCircle className="w-6 h-6" />
      <span className="absolute right-full mr-2 top-1/2 -translate-y-1/2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
        Guide de démarrage
      </span>
    </button>
  );
};


interface Item {
  icon: React.ReactNode;
  label: string;
  getUnit: (val: number) => string;
}

interface CounterProps {
  dispatch: Function;
  transfer: {
    loaded: number;
    consumption?: {
      kWh: number;
      kms: number;
      gCO2: number;
    };
    purging?: boolean;
    className?: string;
  };
  type?: "upload" | "download" | undefined;
  app: Record<string, any>;
  isPreview?: boolean;
  forceIOS?: boolean;
  isInputActive?: boolean;
  phase?: string;
  showScrollDown?: boolean;
  className?: string;
  showMobileAnnonce?: boolean; // Nouvel état pour savoir si l'annonce est visible en version mobile
}

interface State {
  icons: Item[];
  showPreview: boolean;
}

interface ContactRequest {
  email: string;
  subject: string;
  message: string;
}

interface FooterComponentProps {
  contactOnly?: boolean;
  legales?: boolean;
  hideIcon?: boolean;
  children?: React.ReactNode;
  contactClassName?: string;
}

const fixeVal = (v: number = 0, l: number = 2, force: boolean = false) => {
  let result = "";
  v = v ? v : 0;
  if (v?.toFixed) {
    result = v.toFixed(l);
  } else {
    console.log("Error, v is not a number: ", v);
  }

  if ("0.00" === result && !force) result = "< 0.01";

  return result;
};

const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
}> = ({ isOpen, onClose, title, children, className = "" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/50" onClick={onClose}></div>
      <div className={`bg-white rounded-lg shadow-xl relative ${className}`}>
        {title && (
          <div className="flex items-center justify-between p-4 border-b">
            <h3 className="text-lg font-medium">{title}</h3>
            <button 
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-500 p-1 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Close"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        )}
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

const Input: React.FC<{
  type: string;
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: string;
  success?: string;
  rows?: number;
}> = ({ type, label, name, value, onChange, error, success, rows }) => {
  const Component = type === "textarea" ? "textarea" : "input";
  
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>
      <Component
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          error ? 'border-red-500' : success ? 'border-green-500' : ''
        }`}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        rows={rows}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
      {success && <p className="test-emerald-500 text-xs italic">{success}</p>}
    </div>
  );
};

const Button: React.FC<{
  onClick?: () => void;
  type?: "button" | "submit";
  className?: string;
  children: React.ReactNode;
}> = ({ onClick, type = "button", className = "", children }) => (
  <button
    type={type}
    onClick={onClick}
    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors ${className}`}
  >
    {children}
  </button>
);

const Alert: React.FC<{
  color: "success" | "danger";
  children: React.ReactNode;
}> = ({ color, children }) => (
  <div
    className={`${
      color === "success" ? "bg-green-100 test-emerald-500-700" : "bg-red-100 text-red-700"
    } px-4 py-3 rounded relative mb-4`}
    role="alert"
  >
    {children}
  </div>
);

const FooterCounterComponent: React.FC<CounterProps> = ({ className, ...props }) => {
  const isPreview = props.isPreview ||
    (!isMobile &&
      (!props.transfer.consumption || Object.values(props.transfer.consumption).every(val => val === 0)) &&
      props.type?.toLowerCase() !== "download");

  const shouldShowFooter = props.transfer.loaded > 0 || props.type === "download" || props.isPreview;

  const icons: Item[] = [
    {
      icon: <UploadCloudIcon style={iconStyle} />,
      label: convertSize(props.transfer.loaded).split(" ")[1],
      getUnit: (val: number): string => convertSize(val || 0).split(" ")[0],
    },
    {
      icon: <Zap style={iconStyle} />,
      label: "kWh",
      getUnit: (val: number): string => fixeVal(val, 2, isForce0()),
    },
    {
      icon: <Leaf style={iconStyle} />,
      label: "gCO2",
      getUnit: (val: number): string => fixeVal(val, 2, isForce0()),
    },
    {
      icon: <Droplet style={iconStyle} />,
      label: "L",
      getUnit: (val: number): string => fixeVal(val, 2, isForce0()),
    },
    {
      icon: <Car style={iconStyle} />,
      label: "Km",
      getUnit: (val: number): string => fixeVal(val, 2, isForce0()),
    },
  ];

  const getThemeClass = (id: string) => {
    if (id === "custom-0" || id === "custom-1") return id;

    const themeClass =
      "0" === id[0]
        ? gradients.clair.find((gradient) => gradient.id === id)
        : gradients.sombre.find((gradient) => gradient.id === id);

    return themeClass?.className || "";
  };

  const isForce2 = (): boolean =>
    "download" === props?.type && !props.transfer?.loaded;
    
  const isForce0 = (): boolean =>
    ((isMobile && !props.transfer?.loaded) ||
    ("download" === props?.type && !props.transfer?.loaded)) &&
    !props.isPreview;

  const getClassName = () => {
    const { app } = props;
    return !app[appAction.SHOW_CUSTOM_HEADER]
      ? "normal"
      : getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);
  };

  const getClassId = () => {
    const { app } = props;
    return !app[appAction.SHOW_CUSTOM_HEADER]
      ? ""
      : app[appAction.SHOW_CUSTOM_HEADER];
  };

  const renderItem = (item: Item, k: number) => {
    // Remplacer l'icône par la version mobile si on est sur mobile
    const mobileAdjustedIcon = isMobile && !isTablet
      ? React.cloneElement(item.icon as React.ReactElement, { style: mobileIconStyle })
      : item.icon;
    
    return (
      <ConsumptionItemComponent
        forceIOS={props?.forceIOS}
        type={props.type}
        key={k}
        icon={mobileAdjustedIcon}
        value={props.transfer.loaded}
        consumption={props.transfer.consumption || { kWh: 0, kms: 0, gCO2: 0 }}
        label={k === 0 ? convertSize(props.transfer.loaded).split(" ")[1] : item.label}
        getUnit={item.getUnit}
        isPreview={isPreview}
        isOpen={false}
      />
    );
  };

  const themeClass = getClassName();
  const mobileClass = !isMobile || isTablet ? "" : "mobile";

  const [showHelp, setShowHelp] = useState(false);
  const user = useSelector<RootState, any>(state => state.user?.user);
  const isUserLoggedIn = !!user;

  const handleHelpClick = () => {
    if (!showHelp) {
      setShowHelp(true);
    }
  };

  const handleHelpClose = () => {
    setShowHelp(false);
  };

  if (props.transfer.purging) return null;

  return (
    <footer className={`fixed bottom-0 w-full ${mobileClass} ${themeClass} ${isForce0() ? "opacity-0" : "opacity-100"} transition-all z-40 duration-300 ease-in-out ${isMobile && isAndroid && !props?.forceIOS ? "android" : ""}`}>
      <div className="mx-auto px-4">
        
        {!isUserLoggedIn && (
          // En version mobile, n'afficher le bouton que lorsque l'annonce est visible
          // Sur desktop, l'afficher normalement
          ((!isMobile || (isMobile && props.showMobileAnnonce)) && (
            <FloatingInfoButton onClick={handleHelpClick} />
          ))
        )}
        {showHelp && <FileVertGuideTooltip onClose={handleHelpClose} />}

        <div className={`grid grid-cols-5 ${isMobile && !isTablet ? "gap-1 px-1 py-2" : "gap-4"}`}>
          {icons.map((el, i) => (
            <div key={i} className="text-center">
              {renderItem(el, i)}
            </div>
          ))}
        </div>

        <span
          className={`text-white text-sm absolute ${isMobile && !isTablet ? "bottom-10 right-1" : "bottom-2 right-2"}`}
          style={isMobile && !isTablet ? { writingMode: 'vertical-rl', textOrientation: 'mixed' } : undefined}
        >v{version}</span>
      </div>
    </footer>
  );
};

interface FooterState {
  isOpen: boolean;
  contact_email: string;
  contact_subject: string;
  contact_message: string;
  success: boolean;
  error: string[];
  isSubmitted: boolean;
}

export class FooterComponent extends React.Component<FooterComponentProps, FooterState> {
  defaultNavbarStyle: string = "";

  constructor(props: FooterComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
      contact_email: "",
      contact_subject: "",
      contact_message: "",
      success: false,
      error: [],
      isSubmitted: false,
    };
  }

  componentDidMount() {
    if (document.location.hash === "#contact") {
      this.openModal();
    }
  }

  handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    let error = [...this.state.error];
    
    if (!value) {
      if (!error.includes(name)) {
        error.push(name);
      }
    } else {
      error = error.filter(e => e !== name);
    }

    this.setState({ ...this.state, [name]: value, error });
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openModalFromHeader = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    document.querySelector("#fv-header")?.classList.add("no-hover");
    this.forceNavbar(true);
    this.setState({ isOpen: true });
  };

  closeModalFromHeader = () => {
    document.querySelector("#fv-header")?.classList.remove("no-hover");
    this.forceNavbar(false);
    this.setState({ isOpen: false });
  };

  forceNavbar = (open: boolean) => {
    const navbar = document.querySelector("#navbar-normal") as HTMLElement;
    if (!navbar) return;

    if (open) {
      this.defaultNavbarStyle = navbar.style.cssText;
      navbar.style.display = "block";
      navbar.style.height = "100%";
      navbar.style.width = "100%";
      navbar.style.zIndex = "100000";
    } else {
      navbar.style.cssText = this.defaultNavbarStyle || "";
    }
  };

  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    const { contact_email, contact_subject, contact_message } = this.state;
    let error: string[] = [];

    if (!contact_email) error.push("contact_email");
    if (!contact_subject) error.push("contact_subject");
    if (!contact_message) error.push("contact_message");

    if (error.length) {
      this.setState({ error });
      return;
    }

    try {
      const contactRequest: ContactRequest = {
        email: contact_email,
        subject: contact_subject,
        message: contact_message,
      };

      const result = await contactApi.sendContactRequest(contactRequest);

      if (result?.success) {
        this.setState({
          success: true,
          contact_email: "",
          contact_subject: "",
          contact_message: "",
          error: [],
          isSubmitted: true,
        }, () => {
          setTimeout(() => {
            this.setState({ success: false, isSubmitted: false });
          }, 2000);
        });
      }
    } catch (err) {
      this.setState({ success: false, isSubmitted: true });
    }
  };

  render() {
    const {
      isOpen,
      success,
      isSubmitted,
      contact_email,
      contact_subject,
      contact_message,
    } = this.state;

    if (this.props.contactOnly) {
      return (
        <div>
          <Modal
            isOpen={isOpen}
            onClose={this.closeModalFromHeader}
            title={i18n._("modal_contact_title")}
            className="w-full max-w-lg"
          >
            {isSubmitted && (
              <Alert color={success ? "success" : "danger"}>
                {i18n._(success ? "form_contact_success" : "form_contact_error")}
              </Alert>
            )}

            <form onSubmit={this.handleSubmit} className="space-y-4">
              <Input
                type="email"
                label={i18n._("form_contact_email_label")}
                name="contact_email"
                value={contact_email}
                onChange={this.handleChange}
                error={this.state.error.includes("contact_email") ? i18n._("form_contact_email_invalid") : undefined}
                success={contact_email && !this.state.error.includes("contact_email") ? i18n._("form_contact_email_valid") : undefined}
              />

              <Input
                type="text"
                label={i18n._("form_contact_subject_label")}
                name="contact_subject"
                value={contact_subject}
                onChange={this.handleChange}
                error={this.state.error.includes("contact_subject") ? i18n._("form_contact_subject_invalid") : undefined}
                success={contact_subject && !this.state.error.includes("contact_subject") ? i18n._("form_contact_subject_valid") : undefined}
              />

              <Input
                type="textarea"
                label={i18n._("form_contact_message_label")}
                name="contact_message"
                value={contact_message}
                onChange={this.handleChange}
                rows={10}
                error={this.state.error.includes("contact_message") ? i18n._("form_contact_message_invalid") : undefined}
                success={contact_message && !this.state.error.includes("contact_message") ? i18n._("form_contact_message_valid") : undefined}
              />

              <div className="text-center">
                <Button type="submit" className="w-full">
                  {i18n._("btn_valid_label")}
                </Button>
              </div>
            </form>
          </Modal>

          {this.props?.legales && (
            <ul className="list-none space-y-2 gap-2">
              <li>
                <Link to="/legal-terms" className="text-sm text-gray-500 hover:text-emerald-500">
                  {i18n._("footer_basics_legales_link_label")}
                </Link>
              </li>
              <li>
                <Link to="/cgu" className="text-sm text-gray-500 hover:text-emerald-500">
                  {i18n._("footer_basics_cgu_link_label")}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-sm text-gray-500 hover:text-emerald-500">
                  {i18n._("footer_basics_privacy_link_label")}
                </Link>
              </li>
            </ul>
          )}
<div className="py-4 space-y-3">
          <Link
            to="/contact"
            className={`block px-4 py-2 text-sm font-medium hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-800 transition-colors
                  text-white ${this.props.contactClassName}`}
          >
            {!this.props?.hideIcon && (
              <Mail className="w-5 h-5" />
            )}
            {this.props?.children}
          </Link>
          </div>
        </div>
      );
    }

    return (
      <footer className="bg-gray-800 text-white pt-6 pb-4">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <h5 className="text-lg font-bold uppercase mb-4">
                {i18n._("footer_services_title")}
              </h5>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_services_transfer_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to="/offers" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_services_offers_link_label")}
                  </Link>
                </li>
                <li>
                  <a
                    href={i18n._("footer_services_blog_link_url")}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-gray-300 transition-colors inline-flex items-center"
                  >
                    {i18n._("footer_services_blog_link_label")}
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </a>
                </li>
                <li>
                  <a
                    href={i18n._("footer_guide_link_url")}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-gray-300 transition-colors inline-flex items-center"
                  >
                    {i18n._("footer_guide_link_label")}
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-center">
              <h5 className="text-lg font-bold uppercase mb-4">
                {i18n._("footer_discover_title")}
              </h5>
              <ul className="space-y-2">
                <li>
                  <Link to="/impact" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_discover_about_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to="/impact#project_2" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_discover_engagements_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to="/impact/pro" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_discover_pro_link_label")}
                  </Link>
                </li>
                <li>
                  <a
                    href={i18n._("footer_partners_link_url")}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-gray-300 transition-colors inline-flex items-center"
                  >
                    {i18n._("footer_partners_link_label")}
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-center">
              <h5 className="text-lg font-bold uppercase mb-4">
                {i18n._("footer_basics_title")}
              </h5>
              <ul className="space-y-2">
                <li>
                  <Link to="/legal-terms" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_basics_legales_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to="/cgu" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_basics_cgu_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="hover:text-gray-300 transition-colors">
                    {i18n._("footer_basics_privacy_link_label")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="hover:text-gray-300 transition-colors inline-flex items-center"
                  >
                    {i18n._("footer_basics_contact_link_label")}
                    <Mail className="w-4 h-4 ml-1" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center mt-8 text-sm border-t border-gray-700 pt-4">
          {i18n._("copyright_date")}
          <Link to="#" className="ml-1 hover:text-gray-300 transition-colors">
            {i18n._("copyright_label")}
          </Link>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(FooterCounterComponent);
