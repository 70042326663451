import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import transferApi from '../../Api/transferApi';
import { i18n } from '../../utils';
import TooltipComponent from '../Tooltip/Tooltip';
import { Search, X } from 'lucide-react';

// Variable globale pour persister l'état entre les rendus
const globalState = {
  hasLoaded: false,
  emails: [] as string[],
  isModalOpen: false,
  modalContainerId: 'email-search-modal-container'
};

// S'assurer que le conteneur de modal existe dans le DOM
function ensureModalContainer() {
  if (!document.getElementById(globalState.modalContainerId)) {
    const modalContainer = document.createElement('div');
    modalContainer.id = globalState.modalContainerId;
    document.body.appendChild(modalContainer);
  }
  return document.getElementById(globalState.modalContainerId);
}

interface ButtonEmailListProps {
  onEmailSelect: (email: string) => void;
  onChange: (emails: string[]) => void;
  user_id: number;
  user_email: string;
  addedEmails?: string[];
}

interface Transfer {
  recipients?: string | string[];
  transfer_type: number;
}

const ButtonEmailList: React.FC<ButtonEmailListProps> = ({ onEmailSelect, onChange, user_id, user_email, addedEmails = [] }) => {
  const [recentEmails, setRecentEmails] = useState<string[]>([]);
  const [allEmails, setAllEmails] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<Set<string>>(new Set());
  
  // Cet état local représente une copie de travail des emails sélectionnés
  // qui ne sera appliquée au parent que lorsque la modal sera fermée
  const [workingAddedEmails, setWorkingAddedEmails] = useState<string[]>(addedEmails);
  
  // Référence à la modal
  const modalRef = useRef<HTMLDivElement>(null);

  // Synchroniser workingAddedEmails quand addedEmails change
  useEffect(() => {
    setWorkingAddedEmails(addedEmails);
  }, [addedEmails]);

  // Pour gérer les clics dans la liste des boutons en cercle
  const handleEmailClick = useCallback((email: string) => {
    console.log("[DEBUG] ButtonEmailList handleEmailClick - email:", email, "addedEmails:", addedEmails);
    
    if (addedEmails.includes(email)) {
      // Si l'email est déjà dans la liste, on le retire
      const newEmails = addedEmails.filter(e => e !== email);
      console.log("[DEBUG] ButtonEmailList handleEmailClick - Removing email, newEmails:", newEmails);
      onChange(newEmails);
    } else {
      // Si l'email n'est pas dans la liste, on l'ajoute
      console.log("[DEBUG] ButtonEmailList handleEmailClick - Adding email");
      onEmailSelect(email);
    }
  }, [onEmailSelect, onChange, addedEmails]);

  // Pour gérer les clics dans la modal
  const handleModalEmailClick = useCallback((email: string) => {
    console.log("[DEBUG] ButtonEmailList handleModalEmailClick - email:", email);
    
    setWorkingAddedEmails(prev => {
      console.log("[DEBUG] ButtonEmailList handleModalEmailClick - prev:", prev);
      
      if (prev.includes(email)) {
        // Si l'email est déjà dans la liste, on le retire
        const newEmails = prev.filter(e => e !== email);
        console.log("[DEBUG] ButtonEmailList handleModalEmailClick - Removing email, newEmails:", newEmails);
        return newEmails;
      } else {
        // Si l'email n'est pas dans la liste, on l'ajoute
        const newEmails = [...prev, email];
        console.log("[DEBUG] ButtonEmailList handleModalEmailClick - Adding email, newEmails:", newEmails);
        return newEmails;
      }
    });
  }, []);

  const openModal = useCallback(() => {
    // Réinitialiser workingAddedEmails avec l'état actuel de addedEmails
    setWorkingAddedEmails(addedEmails);
    globalState.isModalOpen = true;
    ensureModalContainer();
    document.body.style.overflow = 'hidden'; // Empêcher le défilement pendant que la modal est ouverte
    // Force update du composant
    setSelectedEmails(new Set(selectedEmails));
  }, [addedEmails, selectedEmails]);

  const closeModal = useCallback(() => {
    console.log("[DEBUG] ButtonEmailList closeModal - workingAddedEmails:", workingAddedEmails);
    
    // Appliquer les modifications directement en envoyant la liste complète des emails
    // plutôt que d'ajouter/supprimer un par un
    onChange(workingAddedEmails);
    
    console.log("[DEBUG] ButtonEmailList closeModal - après onChange");
    
    globalState.isModalOpen = false;
    document.body.style.overflow = ''; // Restaurer le défilement
    // Force update du composant
    setSelectedEmails(new Set(selectedEmails));
  }, [workingAddedEmails, onChange, selectedEmails]);

  useEffect(() => {
    let isMounted = true;
    let timeoutId: NodeJS.Timeout;

    const fetchRecentEmails = async () => {
      if (globalState.hasLoaded) {
        console.log('[ButtonEmailList] Using cached emails:', globalState.emails.length);
        setRecentEmails(globalState.emails);
        setAllEmails(globalState.emails);
        return;
      }
      try {
        // Attendre un peu avant de charger les emails récents
        timeoutId = setTimeout(async () => {
          if (!isMounted) return;

          console.log('[ButtonEmailList] Fetching recent emails...');
          const transfers: Transfer[] = await transferApi.getAllUserTransfer(user_id);
          
          const emails = transfers
            .filter((transfer: Transfer) => transfer.recipients && transfer.recipients.length > 0)
            .flatMap((transfer: Transfer) => {
              if (typeof transfer.recipients === 'string') {
                return JSON.parse(transfer.recipients);
              }
              return transfer.recipients as string[];
            })
            .filter((email: string) => email !== user_email)
            .filter((email: string, index: number, self: string[]) => self.indexOf(email) === index)
            .slice(0, 100); // Récupérer plus d'emails pour la recherche

          // Mettre à jour le cache et marquer comme chargé seulement après avoir obtenu les emails
          console.log('[ButtonEmailList] Recent emails loaded:', emails.length);
          globalState.emails = emails;
          globalState.hasLoaded = true;
          console.log('[ButtonEmailList] Setting hasLoaded to true after successful load');

          if (!isMounted) return;
          setRecentEmails(emails.slice(0, 10)); // Afficher seulement les 10 premiers
          setAllEmails(emails);
        }, 1000); // Délai d'1 seconde
      } catch (error) {
        if (isMounted) {
          console.error('[ButtonEmailList] Error fetching recent emails:', error);
        }
      }
    };

    if (user_id && user_email) {
      fetchRecentEmails();
    }

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [user_id, user_email]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const filteredEmails = useMemo(() => {
    if (!searchQuery) return allEmails;
    return allEmails.filter(email =>
      email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [allEmails, searchQuery]);

  return (
    <div className="mt-4">
      <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        {i18n._('recent_recipients')}
      </h3>
      
      <div className="flex -space-x-3">
        {recentEmails.slice(0, 10).map((email: string, index: number) => (
          <TooltipComponent key={index} content={email}>
            <button
              onClick={() => handleEmailClick(email)}
              className={`w-10 h-10 rounded-full flex items-center justify-center border-2 border-white dark:border-neutral-900 relative transition-colors
                ${addedEmails.includes(email)
                  ? 'bg-blue-50 text-blue-400 dark:bg-blue-900/50 dark:text-blue-300 opacity-75'
                  : selectedEmails.has(email)
                    ? 'bg-blue-100/80 text-blue-500 dark:bg-blue-900/70 dark:text-blue-300/80'
                    : 'bg-blue-100 text-blue-600 hover:bg-blue-200 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800'
                }`}
              style={{ zIndex: 10 - (index % 10) }}
            >
              <span className="text-base font-medium">
                {email.split('@')[0]?.split(/[._-]/)?.map(part => part[0]?.toUpperCase()).slice(0, 2).join('')}
              </span>
            </button>
          </TooltipComponent>
        ))}
        
        {allEmails.length > 10 && (
          <TooltipComponent content={i18n._('search_recipients')}>
            <button
              onClick={openModal}
              className="w-10 h-10 rounded-full flex items-center justify-center border-2 border-white dark:border-neutral-900 relative bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700"
              style={{ zIndex: 0 }}
            >
              <Search className="w-5 h-5" />
            </button>
          </TooltipComponent>
        )}
      </div>

      {/* Modal directement intégrée dans le DOM plutôt que dans le flux React */}
      {globalState.isModalOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => {
            // Fermer la modal seulement si on clique sur l'arrière-plan
            if (e.target === e.currentTarget) {
              closeModal();
            }
          }}
        >
          <div 
            className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6 w-full max-w-md"
            ref={modalRef}
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {i18n._('search_recipients')}
              </h3>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal();
                }}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <div className="mb-4">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={i18n._('search_email_placeholder')}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            
            <div className="max-h-60 overflow-y-auto mb-4">
              {filteredEmails.length > 0 ? (
                <div className="space-y-2">
                  {filteredEmails.map((email: string, index: number) => (
                    <button
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleModalEmailClick(email);
                      }}
                      className={`w-full text-left px-3 py-2 rounded-md transition-colors
                        ${workingAddedEmails.includes(email)
                          ? 'bg-blue-100/80 text-blue-500 dark:bg-blue-900/70 dark:text-blue-300/80'
                          : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200'
                        }`}
                    >
                      {email}
                    </button>
                  ))}
                </div>
              ) : (
                <p className="text-center text-gray-500 dark:text-gray-400 py-4">
                  {i18n._('no_results_found')}
                </p>
              )}
            </div>
            
            <div className="flex justify-end space-x-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal();
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-700 dark:hover:bg-blue-800"
              >
                {i18n._('close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonEmailList;