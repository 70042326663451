import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { HeaderV2, HeaderV3, FolderLoader } from "./Components";
import ButtonUploadHistory from "./Components/Shared/ButtonUploadHistory";
import SEOHelmet from "./Components/SEO/SEOHelmet";
import RouteWithSEO from "./Components/SEO/RouteWithSEO";
import AsyncRouteWithSEO from "./Components/SEO/AsyncRouteWithSEO";
// import SEODebugger from "./Components/SEO/SEODebugger";
import { PersistGate } from "redux-persist/integration/react";
import reduxStore from "./Store";
import "./index.css";
import { Route } from "react-router";
import { isMobile } from "./utils";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { userApi, assetsApi } from "./Api";
import { connect } from "react-redux";
import { appAction, userAction } from "./Store";
import { hashLinkScroll, i18n } from "./utils";
import { StreamDownloadService } from "./Services/StreamDownloadService";
import { logger } from "./utils/logger";
import {
  Home,
  Download,
  About,
  Profil,
  ChromeExtension,
  ResetPassword,
  ConfirmMember,
  NotFound,
  Legale,
  FVStatistics,
  OrderScreen,
  InviteScreen,
  InviteConfirmationScreen,
  Deposit,
  Receipt,
  CreateDepositScreen,
  StudioScreen,
  LoginScreen,
  OfferSelectionScreen,
  RegisterScreen,
  Support,
} from "./Screens";
import ResetPasswordScreen from "./Screens/ResetPassword/ResetPasswordScreen";
import { ContactScreen } from "./Screens/Contact/ContactScreen";


// Logger centralisé pour Main
const log = logger({ service: 'Main' });
const isDev = process.env.NODE_ENV !== 'production';

interface MainProps {
  app: {
    BACKGROUND: string;
    [appAction.SHOW_LIGHT_HEADER]: boolean;
    [appAction.HIDE_HEADER]: boolean;
  };
  user: {
    id?: string;
    status?: string;
    token?: string;
    config?: {
      background?: {
        url?: string;
        file?: string;
      };
    };
  };
  dispatch: (action: any) => void;
}

interface MainState {
  isLoading: boolean;
  isLoggedOut: boolean;
}

// Composants modaux
const Modal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  children: ReactNode;
  className?: string;
}> = ({ isOpen, toggle, children, className = "" }) => {
  if (!isOpen) return null;

  return (
    <div
      role="dialog"
      aria-modal="true"
      className={`fixed inset-0 z-50 flex items-center justify-center ${className}`}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={toggle}
        role="presentation"
      ></div>
      <div
        className="bg-white rounded-lg p-6 relative z-10 max-w-lg w-full mx-4"
        role="document"
      >
        {children}
      </div>
    </div>
  );
};

const ModalHeader: React.FC<{
  children: ReactNode;
  toggle: () => void;
  className?: string;
}> = ({ children, toggle, className = "" }) => (
  <header className={`flex justify-between items-center mb-4 ${className}`} role="banner">
    <div>{children}</div>
    <button
      onClick={toggle}
      className="text-gray-500 hover:text-gray-700"
      aria-label="Fermer la fenêtre"
    >
      ×
    </button>
  </header>
);

const ModalBody: React.FC<{ children: ReactNode; className?: string }> = ({ children, className = "" }) => (
  <div role="main" className={className}>
    {children}
  </div>
);

const ModalFooter: React.FC<{ children: ReactNode; className?: string }> = ({ children, className = "" }) => (
  <footer role="contentinfo" className={`mt-4 ${className}`}>
    {children}
  </footer>
);

// Composant ScrollToTop
const ScrollToTop = withRouter(({ location }: any) => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      setTimeout(hashLinkScroll, 250);
      return;
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
});

class Main extends React.Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);

    const isForceLogout = document.location.href.includes("?force_logout=1");
    if (isForceLogout) {
      const newUrl = window.location.href.replace("?force_logout=1", "");
      window.history.replaceState({}, document.title, newUrl);
    }

    this.state = {
      isLoading: false,
      isLoggedOut: isForceLogout,
    };
  }

  componentDidMount() {
    this.initializeApp();
  }

  private async initializeApp() {
    try {
      await this.initializeI18n();
      await this.handleUserAuthentication();
      await this.initializeBackground();
      await this.initializeServiceWorker();
    } catch (error) {
      log.error("Error during app initialization");
    }
  }

  private async initializeServiceWorker() {
    try {
      // Vérifier si nous sommes sur une page de téléchargement
      const isDownloadPage = window.location.pathname.startsWith('/d/');
      
      // Initialiser le service worker en arrière-plan
      if (isDev) log.debug('Initialisation préventive du service worker StreamSaver...');
      
      // Si nous sommes sur une page de téléchargement, initialiser immédiatement
      if (isDownloadPage) {
        if (isDev) log.debug('Page de téléchargement détectée, initialisation prioritaire');
        await StreamDownloadService.setupStreamSaver();
      } else {
        // Sinon, initialiser en arrière-plan avec un délai pour ne pas bloquer le chargement de l'application
        setTimeout(() => {
          StreamDownloadService.setupStreamSaver()
            .then(success => {
              if (success) {
                if (isDev) log.debug('Service worker StreamSaver initialisé avec succès en arrière-plan');
              } else {
                log.warn('Initialisation du service worker StreamSaver incomplète');
              }
            })
            .catch(error => {
              log.error('Erreur lors de l\'initialisation du service worker StreamSaver');
            });
        }, 2000); // Délai de 2 secondes pour ne pas bloquer le chargement initial
      }
    } catch (error) {
      log.error('Erreur lors de l\'initialisation du service worker');
    }
  }

  private async initializeI18n() {
    if (!i18n.isReady) {
      await new Promise<void>((resolve) => {
        const checkI18n = setInterval(() => {
          if (i18n.isReady) {
            clearInterval(checkI18n);
            this.props.dispatch(appAction.setI18nStatus(true));
            resolve();
          }
        }, 250);
      });
    } else {
      this.props.dispatch(appAction.setI18nStatus(true));
    }
  }

  private async handleUserAuthentication() {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get("token");

    if (tokenFromUrl) {
      localStorage.setItem("fv-auth-token", tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const token = tokenFromUrl || localStorage.getItem("fv-auth-token");
    if (token && !this.props.user) {
      try {
        const result = await userApi.userVerification();
        if (result && !result.error) {
          this.props.dispatch(
            userAction.loginUser({
              user: result.user,
              token: result.token,
              expiresIn: result.user.expires_in || 3600,
            })
          );
        } else {
          localStorage.removeItem("fv-auth-token");
        }
      } catch (error) {
        log.error("Error during user verification");
        localStorage.removeItem("fv-auth-token");
      }
    }
  }

  private async initializeBackground() {
    if (this.props.user?.config?.background?.url) {
      this.props.dispatch(appAction.setBackground(this.props.user.config.background.url));
    } else {
      try {
        const defaultBackground = await assetsApi.getBackground();
        this.props.dispatch(appAction.setBackground(defaultBackground));
      } catch (error) {
        log.error("Error loading background");
      }
    }
  }

  render() {
    if (isMobile) {
      document.getElementById("root")?.classList.add("mobile");
    }

    if (!this.props?.app?.BACKGROUND) {
      return <MainLoaderComponent />;
    }

    const isLogged = !!this.props.user?.id;

    return (
      <BrowserRouter>
        <PersistGate loading={<MainLoaderComponent />} persistor={reduxStore.persistor}>
          <Suspense fallback={<MainLoaderComponent />}>
            {isLogged ? (
              <HeaderV3 isLogged={isLogged} />
            ) : (
              <HeaderV2 isLogged={isLogged} />
            )}
            <Modal
              className="fv-expireduser-modal"
              isOpen={this.state.isLoggedOut}
              toggle={() => this.setState({ isLoggedOut: false })}
            >
              <ModalHeader className="fv-modal-expired-session-header" toggle={() => this.setState({ isLoggedOut: false })}>
                <div dangerouslySetInnerHTML={{ __html: i18n._("fv_modal_header_user_expired") }} />
              </ModalHeader>

              <ModalBody className="fv-modal-expired-session-body">
                <div dangerouslySetInnerHTML={{ __html: i18n._("fv_modal_body_user_expired") }} />
              </ModalBody>

              <ModalFooter className="fv-modal-expired-session-footer">
                <div className="w-full text-center">{i18n._("fv_modal_footer_user_expired")}</div>
              </ModalFooter>
            </Modal>

            <div className="page-wrapper">
              <main
                role="main"
                aria-label="Contenu principal"
                className={`min-h-screen ${
                  this.props.app[appAction.SHOW_LIGHT_HEADER] ? "bg-white" : "bg-base-300"
                }`}
              >
                <ScrollToTop />
                {/* <SEODebugger enabled={true} /> */}
                <div className="page-container">
                  <Switch>
                    <RouteWithSEO
                      path="/u/:id"
                      component={Receipt}
                      title="Réception de fichiers - FileVert"
                      description="Accédez à vos fichiers transférés via FileVert. Téléchargement sécurisé et éco-responsable."
                    />
                    <RouteWithSEO
                      path="/deposit/:id"
                      component={Deposit}
                      title="Dépôt de fichiers - FileVert"
                      description="Espace de dépôt sécurisé FileVert. Transférez vos fichiers de manière éco-responsable."
                    />
                    <RouteWithSEO
                      path="/pro/:id"
                      component={CreateDepositScreen}
                      title="Espace Pro - FileVert"
                      description="Espace professionnel FileVert pour le transfert de fichiers volumineux. Solution sécurisée et éco-responsable."
                    />
                    <RouteWithSEO
                      path="/d/:id"
                      component={Download}
                      title="Téléchargement - FileVert"
                      description="Téléchargez vos fichiers en toute sécurité via FileVert. Service de transfert éco-responsable."
                    />
                    <RouteWithSEO
                      path="/collab"
                      component={StudioScreen}
                      exact
                      title="Espace Collaboratif - FileVert"
                      description="Collaborez et partagez vos fichiers en équipe avec FileVert. Solution professionnelle et éco-responsable."
                    />
                    <RouteWithSEO
                      path="/login"
                      component={LoginScreen}
                      exact
                      title="Connexion - FileVert"
                      description="Connectez-vous à votre compte FileVert pour gérer vos transferts de fichiers éco-responsables."
                    />
                    <RouteWithSEO
                      path="/register"
                      component={RegisterScreen}
                      exact
                      title="Inscription - FileVert"
                      description="Créez votre compte FileVert et commencez à transférer vos fichiers de manière éco-responsable."
                    />
                    <AsyncRouteWithSEO
                      path="/"
                      component={Home}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <AsyncRouteWithSEO
                      path="/about"
                      component={About}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <Route path="/impact" render={() => <Redirect to="/about" />} exact />
                    <AsyncRouteWithSEO
                      path="/offers"
                      component={OfferSelectionScreen}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <Route path="/reset-password" component={ResetPasswordScreen} exact />
                    <Route path="/reset-password/:token" component={ResetPassword} exact />
                    <Route path="/member/:member_token" component={ConfirmMember} exact />
                    <Route path="/invite/confirmation/:token" component={InviteConfirmationScreen} exact />
                    <Route path="/invite/:invitation_link" component={InviteScreen} exact />
                    <RouteWithSEO
                      path="/profil"
                      component={Profil}
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <RouteWithSEO
                      path="/legal-terms"
                      component={Legale}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <RouteWithSEO
                      path="/cgu"
                      component={Legale}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <RouteWithSEO
                      path="/privacy"
                      component={Legale}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <RouteWithSEO
                      path="/privacy-chrome-extension"
                      component={Legale}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <AsyncRouteWithSEO
                      path="/contact"
                      component={ContactScreen}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                      keywords="contact FileVert, support transfert fichiers, assistance technique, service client, demande information, contact professionnel"
                    />
                    <Route path="/support" component={Support} exact />
                    <AsyncRouteWithSEO
                      path="/chrome-extension"
                      component={ChromeExtension}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                    <Route path="/order/:status/:order_id" component={OrderScreen} />
                    <Route path="/fv-statistics" component={FVStatistics} />
                    <RouteWithSEO
                      path="*"
                      component={NotFound}
                      exact
                      // Les titres, descriptions et mots-clés seront gérés par SEOHelmet en fonction de la langue
                    />
                  </Switch>
                </div>
              </main>
              {/* <footer className="bg-base-200 py-8" role="contentinfo">
                <div className="container mx-auto px-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                      <h2 className="text-lg font-semibold mb-4">FileVert</h2>
                      <p className="text-sm">Solution éco-responsable de transfert de fichiers. 100% français, sécurisé et respectueux de l'environnement.</p>
                    </div>
                    <div>
                      <h2 className="text-lg font-semibold mb-4">Liens importants</h2>
                      <nav aria-label="Liens de pied de page">
                        <ul className="space-y-2 text-sm">
                          <li><a href="/about" className="hover:text-primary">À propos</a></li>
                          <li><a href="/contact" className="hover:text-primary">Contact</a></li>
                          <li><a href="/legal-terms" className="hover:text-primary">Mentions légales</a></li>
                        </ul>
                      </nav>
                    </div>
                    <div>
                      <h2 className="text-lg font-semibold mb-4">Certifications</h2>
                      <div className="flex space-x-4 text-sm">
                        <span className="px-2 py-1 bg-base-300 rounded">HDS</span>
                      </div>
                      <p className="mt-4 text-sm">Hébergement 100% énergies renouvelables</p>
                    </div>
                  </div>
                </div>
              </footer> */}
            </div>
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    );
  }
}

const MainLoaderComponent = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
    <FolderLoader isLoading={true} />
  </div>
);

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user?.user
    ? {
        ...state.user.user,
        token: state.user.token,
      }
    : null,
});

export default connect(mapStateToProps)(Main);