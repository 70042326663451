import React, { useState, useRef, useEffect } from "react";
import { AlertCircle } from "lucide-react";
import classNames from "classnames"; // Optionnel : pour gérer les classes conditionnelles
import { i18n } from "../../../utils";

export interface IInputPasswordComponentProps {
  name: string;
  error?: string;
  label?: string;
  defaultValue?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  onChangeCb?: (password: string, confirmPassword: string) => void;
}

export function InputPasswordComponent({
  label,
  error,
  name,
  defaultValue,
  maxLength,
  minLength,
  required,
  onChangeCb,
}: IInputPasswordComponentProps) {
  const [localError, setLocalError] = useState<string | null>(error || null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Mettre à jour l'erreur locale si la prop error change
    setLocalError(error || null);
  }, [error]);

  const handleBlur = () => {
    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";

    // Validation de la correspondance des mots de passe
    if (password !== confirmPassword) {
      setLocalError(i18n._("password_missmatch_label"));
    } else {
      setLocalError(null);
    }

    // Appeler le callback si fourni
    if (onChangeCb) {
      onChangeCb(password, confirmPassword);
    }
  };

  const handleChange = () => {
    // Réinitialiser l'erreur locale lors du changement
    setLocalError(null);

    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";

    // Appeler le callback si fourni
    if (onChangeCb) {
      onChangeCb(password, confirmPassword);
    }
  };

  return (
    <div className={`form-control ${localError ? "mb-4" : "mb-2"}`}>
      <label htmlFor={name} className="label">
        <span className="label-text">
          {label} {required && <span className="text-error">*</span>}
        </span>
      </label>
      <input
        ref={passwordRef}
        id={name}
        name={name}
        type="password"
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(
          "input input-bordered",
          {
            "input-error": localError,
            "input-success": !localError && passwordRef.current?.value,
          }
        )}
      />
      {localError && (
        <span className="mt-1 flex items-center text-error text-sm" role="alert">
          <AlertCircle className="mr-1 w-4 h-4" />
          {localError}
        </span>
      )}

      <label htmlFor={`confirm_${name}`} className="label mt-4">
        <span className="label-text">
          {i18n._("INPUTS.CONFIRM_PASSWORD.LABEL")}{" "}
          {required && <span className="text-error">*</span>}
        </span>
      </label>
      <input
        ref={confirmPasswordRef}
        id={`confirm_${name}`}
        name={`confirm_${name}`}
        type="password"
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(
          "input input-bordered",
          {
            "input-error": localError,
            "input-success": !localError && confirmPasswordRef.current?.value,
          }
        )}
      />
      {localError && (
        <span className="mt-1 flex items-center text-error text-sm" role="alert">
          <AlertCircle className="mr-1 w-4 h-4" />
          {i18n._("password_missmatch_label")}
        </span>
      )}
    </div>
  );
}
