import React, { useState } from "react";
import { File, Mail, Download, Leaf, Folder, Eye, EyeOff, Copy } from "lucide-react";
import { i18n } from "../../utils";
import { convertSize } from "../../utils";
import FolderLoader from "../FolderLoader/FolderLoader";
import FVModal from "./FVModal";
import Toast from "../Toast/Toast";

interface BaseNode {
  is_mine?: boolean;
  is_new?: boolean;
}

interface FileNode extends BaseNode {
  file_name: string;
  file_size: number;
  downloaded: number;
  is_dir?: false;
}

interface DirNode extends BaseNode {
  is_dir: true;
  dir_name: string;
  file_name: string;
  file_size: number;
  downloaded: number;
  children: Record<string, TreeNode>;
}

type TreeNode = FileNode | DirNode;

export interface IFileLightDetail {
  file_name: string;
  file_size: number;
  downloaded: number;
  is_mine?: boolean;
  is_new?: boolean;
}

interface ConsumptionData {
  size: number;
  gCO2: number;
  kWh: number;
  kms: number;
}

const PasswordField: React.FC<{ password: string }> = ({ password }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setToastVisible(true);
  };

  const handleToastClose = () => {
    setToastVisible(false);
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            readOnly
            className="text-sm bg-gray-100 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 rounded px-2 py-1 pr-8 w-32"
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
          >
            {showPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
          </button>
        </div>
        <button
          onClick={handleCopy}
          className="p-1 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded text-gray-500 hover:text-gray-700"
          title={i18n._("copy_password")}
        >
          <Copy className="w-4 h-4" />
        </button>
      </div>
      <Toast
        type="success"
        message={i18n._("password_copied")}
        isVisible={toastVisible}
        duration={2000}
        onClose={handleToastClose}
      />
    </>
  );
};

interface ProtectionDetails {
  transfer_password?: string;
  has_password?: boolean;
  password?: string;
}

interface Props {
  onClose: () => void;
  isOpen: boolean;
  transfer: {
    transfer_id?: string;
    transfer_name?: string;
    downloaded?: number;
    files?: IFileLightDetail[];
    transfer_provider?: string;
    is_protected?: boolean;
    transfer_password?: string;
    protectionDetails?: ProtectionDetails;
    consumption?: ConsumptionData | {
      kwh: string;
      gco2: string;
      kms: string;
      size: string;
    };
  };
  isLoading?: boolean;
}

const buildFileTree = (files: IFileLightDetail[]): Record<string, TreeNode> => {
  // Séparer les fichiers par utilisateur (is_mine)
  const myFiles = files.filter(f => f.is_mine);
  const otherFiles = files.filter(f => !f.is_mine);
  
  // Construire deux arbres séparés
  const buildTree = (files: IFileLightDetail[]): Record<string, TreeNode> => {
    const root: Record<string, TreeNode> = {};
    
    files.forEach((file) => {
      const pathParts = file.file_name.split('/');
      let current = root as Record<string, TreeNode>;

      pathParts.forEach((part, index) => {
        if (index === pathParts.length - 1) {
          // C'est un fichier
          current[part] = {
            ...file,
            file_name: part,
            is_dir: false
          } as FileNode;
        } else {
          // C'est un dossier
          if (!current[part]) {
            current[part] = {
              is_dir: true,
              dir_name: part,
              is_mine: file.is_mine,
              children: {},
              file_name: part,
              file_size: 0,
              downloaded: 0
            } as DirNode;
          }
          current = (current[part] as DirNode).children;
        }
      });
    });

    return root;
  };

  // Combiner les arbres avec les fichiers de l'utilisateur en premier
  return {
    ...(myFiles.length > 0 ? { [i18n._("hub_deposit")]: {
      is_dir: true,
      dir_name: i18n._("hub_deposit"),
      is_mine: true,
      children: buildTree(myFiles),
      file_name: i18n._("hub_deposit"),
      file_size: myFiles.reduce((acc, f) => {
        // Log pour débogage
        console.log("[buildFileTree] Ajout de la taille du fichier:", f.file_name, f.file_size, convertSize(f.file_size));
        // Convertir explicitement la taille du fichier en nombre
        return acc + (Number(f.file_size) || 0);
      }, 0),
      downloaded: myFiles.reduce((acc, f) => acc + f.downloaded, 0)
    } as DirNode} : {}),
    ...(otherFiles.length > 0 ? { [i18n._("other_files")]: {
      is_dir: true,
      dir_name: i18n._("other_files"),
      is_mine: false,
      children: buildTree(otherFiles),
      file_name: i18n._("other_files"),
      file_size: otherFiles.reduce((acc, f) => {
        // Log pour débogage
        console.log("[buildFileTree] Ajout de la taille du fichier (autres):", f.file_name, f.file_size, convertSize(f.file_size));
        // Convertir explicitement la taille du fichier en nombre
        return acc + (Number(f.file_size) || 0);
      }, 0),
      downloaded: otherFiles.reduce((acc, f) => acc + f.downloaded, 0)
    } as DirNode} : {})
  };
};

const getConsumptionValues = (consumption: ConsumptionData | { kwh: string; gco2: string; kms: string; size: string; } | undefined) => {
  if (!consumption) return { gco2: 0, kwh: 0 };
  
  if ('gCO2' in consumption) {
    return {
      gco2: consumption.gCO2,
      kwh: consumption.kWh
    };
  }
  
  return {
    gco2: Number(consumption.gco2),
    kwh: Number(consumption.kwh)
  };
};

export const FVTransferDetailsModal: React.FC<Props> = ({
  onClose,
  isOpen,
  transfer,
  isLoading
}) => {
  if (!isOpen) return null;

  // Débogage des informations de protection
  console.log("Protection info:", {
    is_protected: transfer?.is_protected,
    transfer_password: transfer?.transfer_password,
    protectionDetails: transfer?.protectionDetails
  });

  // Calculer la taille totale en octets en additionnant les tailles des fichiers
  const totalSize = transfer?.files?.reduce((acc, file) => {
    // Convertir explicitement la taille du fichier en nombre
    // car les tailles peuvent être stockées comme des chaînes de caractères
    const fileSize = Number(file.file_size) || 0;
    
    // Si la taille est très grande (> 1 Go) mais qu'il y a peu de fichiers, c'est probablement déjà en Go
    if (fileSize > 1_000_000_000 && transfer?.files && transfer.files.length < 10) {
      console.warn("[FVTransferDetailsModal] Taille de fichier suspecte (très grande):", fileSize);
    }
    
    return acc + fileSize;
  }, 0) || 0;

  // Log pour débogage
  console.log("[FVTransferDetailsModal] Tailles des fichiers:", transfer?.files?.map(f => ({
    name: f.file_name,
    size: f.file_size,
    sizeFormatted: convertSize(f.file_size)
  })));
  console.log("[FVTransferDetailsModal] Taille totale calculée:", totalSize, convertSize(totalSize));

  const FileTreeNode: React.FC<{
    node: TreeNode;
    path: string;
    level: number;
  }> = ({ node, path, level }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    
    if (node.is_dir) {
      return (
        <div className="hs-accordion active" role="treeitem" aria-expanded={isExpanded}>
          <div className="hs-accordion-heading py-0.5 flex items-center gap-x-0.5 w-full">
            <button 
              className="hs-accordion-toggle size-6 flex justify-center items-center hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-md focus:outline-none"
              onClick={() => setIsExpanded(!isExpanded)}
              aria-expanded={isExpanded}
            >
              <svg className="size-4 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                <path d="M5 12h14" />
                {!isExpanded && <path d="M12 5v14" />}
              </svg>
            </button>

            <div className={`grow hs-accordion-selectable hs-accordion-selected:bg-gray-100 dark:hs-accordion-selected:bg-neutral-700 px-1.5 rounded-md cursor-pointer ${node.is_mine ? "text-primary" : ""}`}>
              <div className="flex items-center gap-x-3">
                <Folder className="shrink-0 size-4 text-gray-500 dark:text-neutral-500" />
                <div className="grow">
                  <span className="text-sm text-gray-800 dark:text-neutral-200 truncate">
                    {node.dir_name}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {isExpanded && node.children && (
            <div className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300">
              <div className="ps-7 relative before:absolute before:top-0 before:start-3 before:w-0.5 before:-ms-px before:h-full before:bg-gray-100 dark:before:bg-neutral-700">
                {Object.entries(node.children)
                  .sort(([, a], [, b]) => {
                    if (a.is_dir === b.is_dir) return 0;
                    return a.is_dir ? -1 : 1;
                  })
                  .map(([name, child]) => (
                    <FileTreeNode
                      key={`${path}/${name}`}
                      node={child}
                      path={`${path}/${name}`}
                      level={level + 1}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="hs-accordion-selectable hs-accordion-selected:bg-gray-100 dark:hs-accordion-selected:bg-neutral-700 px-2 rounded-md cursor-pointer" role="treeitem">
        <div className="flex items-center gap-x-3">
          <File className="shrink-0 size-4 text-gray-500 dark:text-neutral-500" />
          <div className="grow">
            <span className="text-sm text-gray-800 dark:text-neutral-200 truncate">
              {node.file_name}
            </span>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <span>{convertSize(node.file_size)}</span>
            <span>•</span>
            <span>{node.downloaded} {i18n._("downloads")}</span>
            {/* Log pour débogage - sera visible dans la console */}
            {console.log("[FileTreeNode] Fichier:", node.file_name, "Taille:", node.file_size, "Taille formatée:", convertSize(node.file_size))}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="w-48 h-48 mx-auto">
          <FolderLoader isLoading={true} />
        </div>
      );
    }

    if (!transfer?.files || transfer.files.length === 0) {
      return (
        <div className="text-center text-sm text-gray-400 py-4">
          {i18n._("no_files_available")}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <File className="w-4 h-4 text-blue-400" />
              <span className="text-sm text-gray-400">{transfer.files.length} {i18n._("files")}</span>
            </div>
            <span className="text-sm text-gray-400">
              {(() => {
                // Calculer la somme des tailles des fichiers individuels pour vérification
                const sumOfIndividualSizes = transfer?.files?.reduce((acc, file) => acc + (file.file_size || 0), 0) || 0;
                
                // Calculer la somme des tailles des fichiers individuels
                const sumOfFileSizes = transfer?.files?.reduce((acc, file) => acc + (file.file_size || 0), 0) || 0;
                
                // Vérifier si la taille totale est en Go alors que les fichiers sont en Ko/Mo
                const filesAreMostlySmall = transfer?.files &&
                  transfer.files.length > 0 &&
                  transfer.files.filter(f => f.file_size < 1_000_000).length / transfer.files.length > 0.5;
                
                // Vérifier si la taille totale divisée par 10^9 est proche de la somme des tailles des fichiers
                const correctedSizeIsCloserToSum =
                  Math.abs((totalSize / 1_000_000_000) - sumOfFileSizes) <
                  Math.abs(totalSize - sumOfFileSizes);
                
                if (totalSize > 1_000_000_000 &&
                    (filesAreMostlySmall || correctedSizeIsCloserToSum)) {
                  
                  // Log détaillé pour le débogage
                  console.warn("[FVTransferDetailsModal] Problème d'unité détecté:", {
                    totalSize,
                    totalSizeFormatted: convertSize(totalSize),
                    sumOfFileSizes,
                    sumOfFileSizesFormatted: convertSize(sumOfFileSizes),
                    correctedSize: totalSize / 1_000_000_000,
                    correctedSizeFormatted: convertSize(totalSize / 1_000_000_000),
                    filesAreMostlySmall,
                    correctedSizeIsCloserToSum
                  });
                  
                  // Corriger la taille en divisant par 10^9 (Go)
                  return convertSize(totalSize / 1_000_000_000);
                }
                
                return convertSize(totalSize);
              })()}
            </span>
          </div>

          {/* Bloc de protection - affiché si is_protected est vrai OU si un mot de passe est disponible */}
          {(() => {
            // Vérifier toutes les sources possibles de mot de passe
            const password = transfer.protectionDetails?.transfer_password ||
                            transfer.transfer_password ||
                            (transfer.protectionDetails?.password) ||
                            (transfer.protectionDetails?.has_password ? "********" : null);
            
            console.log("Protection check:", {
              is_protected: transfer?.is_protected,
              has_password: !!password,
              password_source: transfer.protectionDetails?.transfer_password ? "protectionDetails.transfer_password" :
                              transfer.transfer_password ? "transfer_password" :
                              transfer.protectionDetails?.password ? "protectionDetails.password" :
                              transfer.protectionDetails?.has_password ? "protectionDetails.has_password" : "none"
            });
            
            // Afficher le bloc si le transfert est protégé OU si un mot de passe est disponible
            if (transfer.is_protected || password) {
              return (
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <svg className="w-4 h-4 text-yellow-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
                    </svg>
                    <span className="text-sm text-gray-400">{i18n._("protection")}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-yellow-500">{i18n._("password_protected")}</span>
                    {password ? (
                      <PasswordField password={password} />
                    ) : (
                      <span className="text-sm italic text-gray-400">{i18n._("password_not_available")}</span>
                    )}
                  </div>
                </div>
              );
            }
            
            return null;
          })()}

          {transfer.downloaded !== undefined && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Download className="w-4 h-4 text-purple-400" />
                <span className="text-sm text-gray-400">{i18n._("downloads_label")}</span>
              </div>
              <span className="text-sm text-gray-400">{transfer.downloaded}</span>
            </div>
          )}

          {(transfer.transfer_provider === "FV_OUTLOOK" || transfer.transfer_provider === "FV_STUDIO") && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Mail className="w-4 h-4 text-blue-500" />
                <span className="text-sm text-gray-400">{i18n._("type")}</span>
              </div>
              <span className={`text-sm ${transfer.transfer_provider === "FV_OUTLOOK" ? "text-blue-500" : "text-purple-500"}`}>
                {transfer.transfer_provider === "FV_OUTLOOK" ? i18n._("filevert_outlook") : i18n._("filevert_collab")}
              </span>
            </div>
          )}
        </div>

        <div className="max-h-[60vh] overflow-y-auto pr-2">
          {transfer.files && transfer.files.length > 0 && (
            <div className="hs-accordion-treeview-root" role="tree" aria-orientation="vertical">
              <div className="hs-accordion-group" role="group" data-hs-accordion-always-open="">
                {Object.entries(buildFileTree(transfer.files))
                  .sort(([, a], [, b]) => {
                    if (a.is_dir === b.is_dir) return 0;
                    return a.is_dir ? -1 : 1;
                  })
                  .map(([name, node]) => (
                    <FileTreeNode
                      key={name}
                      node={node}
                      path={name}
                      level={0}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFooter = () => (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Leaf className="w-4 h-4 text-green-400" />
        <span className="text-sm text-gray-400">{i18n._("carbon_footprint")}</span>
      </div>
      <div className="text-right space-y-1">
        <div className="text-sm text-gray-300">
          {getConsumptionValues(transfer?.consumption).gco2.toFixed(3)} g CO2
        </div>
        <div className="text-xs text-gray-500">
          {getConsumptionValues(transfer?.consumption).kwh.toFixed(3)} kWh
        </div>
      </div>
    </div>
  );

  return (
    <FVModal
      isOpen={isOpen}
      onClose={onClose}
      title={transfer?.transfer_name}
      footer={renderFooter()}
    >
      {renderContent()}
    </FVModal>
  );
};

export default FVTransferDetailsModal;