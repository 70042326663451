import React from "react";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { i18n } from "../../utils";

interface Props {
  cb: Function;
  user?: any;
}

interface State {
  form: {
    last_name: string;
    first_name: string;
    job: string;
    email: string;
    country: string;
    city: string;
    street: string;
    zip_code: string;
    password: string;
    confirmPassword?: string;
    cgu: boolean;
  };
  isValid: boolean;
}

const Input = ({ label, error, success, ...props }: any) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <input
      {...props}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    {error && (
      <div className="mt-1 text-red-600 text-sm flex items-center">
        <AlertCircle className="w-4 h-4 mr-1" />
        {error}
      </div>
    )}
    {success && (
      <div className="mt-1 test-emerald-500 text-sm flex items-center">
        <CheckCircle2 className="w-4 h-4 mr-1" />
        {success}
      </div>
    )}
  </div>
);

class MemberRegisterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        last_name: "",
        first_name: "",
        job: "",
        email: "",
        country: "",
        city: "",
        street: "",
        zip_code: "",
        password: "",
        confirmPassword: "",
        cgu: false,
      },
      isValid: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user?.email) {
      this.setState(prev => ({
        form: { ...prev.form, email: user.email }
      }));
    }
  }

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = evt.target;
    const actualValue = type === "checkbox" ? checked : value;

    this.setState(prev => ({
      form: { ...prev.form, [name]: actualValue }
    }), this.validateForm);
  };

  validateForm = () => {
    const { form } = this.state;
    let isValid = true;

    // Add your validation logic here
    // This is a simplified version of your original validation

    this.setState({ isValid });
  };

  handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    
    if (!this.state.isValid) return;

    const formData = { ...this.state.form };
    delete formData.confirmPassword;
    this.props.cb(formData);
  };

  render() {
    const { user } = this.props;
    const { form } = this.state;

    return (
      <div className="max-w-4xl mx-auto p-6">
        <form onSubmit={this.handleSubmit} className="space-y-6" noValidate>
          {(!user?.status) && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label={i18n._("form_profil_first_name_label")}
                  type="text"
                  name="first_name"
                  value={form.first_name}
                  onChange={this.handleChange}
                  required
                  autoComplete="given-name"
                />
                
                <Input
                  label={i18n._("form_profil_last_name_label")}
                  type="text"
                  name="last_name"
                  value={form.last_name}
                  onChange={this.handleChange}
                  required
                  autoComplete="family-name"
                />
              </div>

              <Input
                label={i18n._("form_profil_email_label")}
                type="email"
                name="email"
                value={form.email}
                onChange={this.handleChange}
                required
                disabled
                autoComplete="email"
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label={i18n._("form_profil_password_label")}
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={this.handleChange}
                  required
                />

                <Input
                  label={i18n._("form_profil_password_confirmation_label")}
                  type="password"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <Input
                label={i18n._("form_profil_job_label")}
                type="text"
                name="job"
                value={form.job}
                onChange={this.handleChange}
                autoComplete="organization-title"
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label={i18n._("form_profil_address_street_label")}
                  type="text"
                  name="street"
                  value={form.street}
                  onChange={this.handleChange}
                  required
                  autoComplete="street-address"
                />

                <Input
                  label={i18n._("form_profil_address_zipcode_label")}
                  type="text"
                  name="zip_code"
                  value={form.zip_code}
                  onChange={this.handleChange}
                  required
                  autoComplete="postal-code"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label={i18n._("form_profil_address_city_label")}
                  type="text"
                  name="city"
                  value={form.city}
                  onChange={this.handleChange}
                  required
                  autoComplete="address-level2"
                />

                <Input
                  label={i18n._("form_profil_address_country_label")}
                  type="text"
                  name="country"
                  value={form.country}
                  onChange={this.handleChange}
                  required
                  autoComplete="country-name"
                />
              </div>
            </>
          )}

          <div className="flex items-center">
            <input
              type="checkbox"
              name="cgu"
              checked={form.cgu}
              onChange={this.handleChange}
              className="h-4 w-4 text-blue-600 rounded border-gray-300"
              required
            />
            <label className="ml-2 text-sm text-gray-700">
              {i18n._("accept_cgu_label")}
            </label>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {i18n._("btn_valid_label")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default MemberRegisterForm;
