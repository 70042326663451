import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { config } from "../config";

import transferApi from "./transferApi";
import offerApi from "./offerApi";
import userApi from "./userApi";
import invoiceApi from "./invoiceApi";
import assetsApi from "./assetsApi";
import contactApi from "./contactApi";
import { statisticsApi } from "./statisticsApi";
import { i18n } from "../utils";

// type Window =  Window & typeof globalThis
// @ts-ignore
const appVersion = require("../../package.json")?.version;
// import { version as appVersion } from "../../package.json";

const baseUrl = `${config.apiUrl}/v2/`;
const defaultResolution = 1366 * 768;
const resolution = window.screen
  ? window.screen.height * window.screen.width
  : defaultResolution;

axios.defaults.baseURL = baseUrl;
axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

function requestInterceptor(config: AxiosRequestConfig) {
  const lang: string = i18n.getLang(),
    langChoices: string[] = i18n.getLangChoices();

  config.headers["X-Screen-Resolution"] = resolution;
  // prettier-ignore
  //@ts-ignore
  config.headers['X-Connexion-Type'] = window?.connexionInfos?.connexion || 'WIFI';
  config.headers["X-Lang"] = lang;
  config.headers["X-IHM-Version"] = appVersion;
  config.headers["Accept-Language"] = langChoices.join(", ");
  //@ts-ignore
  if (window.connexionInfos?.ip)
    //@ts-ignore
    config.headers["x-client-ip"] = window.connexionInfos?.ip;
  // config.headers['Content-Encoding'] = 'gzip';
  const { v4: uuidv4 } = require('uuid');
  const deviceId = uuidv4();
  config.headers["X-Device-Id"] = deviceId
    .split("99db")
    .join("22db"); // Will overwrite other connexions

  // Utilisation des informations du navigateur pour X-Device-Infos
  const deviceInfo = {
    os: window.navigator.platform,
    platform: window.navigator.platform,
    browser: window.navigator.userAgent
  };
  
  config.headers["X-Device-Infos"] = `${deviceInfo.os};${deviceInfo.platform};${deviceInfo.browser}`;

  // prettier-ignore
  //@ts-ignore
  config.headers["X-Country"] = window?.connexionInfos?.country;
  // prettier-ignore
  //@ts-ignore
  config.headers["X-Country-Code"] = window?.connexionInfos?.countryCode;

  // Récupérer le token directement depuis le localStorage
  const token = localStorage.getItem('fv-auth-token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

function responseSuccessInterceptor(response: AxiosResponse<any>) {
  try {
    // Utiliser l'opérateur ?? pour avoir une valeur par défaut si l'en-tête n'existe pas
    const hasToUpdate = response.headers?.["x-has-to-update"] ?? false;
    const apiVersion = response.headers?.["x-api-version"] ?? "";
    
    // Vérifier si les informations API sont différentes avant de les mettre à jour
    const currentApiInfos = localStorage.getItem("apiInfos");
    const apiInfos = JSON.stringify({
      hasToUpdate,
      apiVersion
    });

    // Gérer la longueur du contenu s'il existe
    if (response.headers?.["x-content-length"]) {
      response.headers["content-length"] = response.headers["x-content-length"];
    }

    // Ne mettre à jour le localStorage que si nécessaire
    if (currentApiInfos && currentApiInfos !== apiInfos) {
      localStorage.setItem("apiInfos", apiInfos);
    }

    return response;
  } catch (error) {
    console.warn("[API] Error in responseSuccessInterceptor:", error);
    // En cas d'erreur, retourner quand même la réponse pour ne pas bloquer le flux
    return response;
  }
}

function responseErrorInterceptor(error: AxiosError) {
  // Si l'erreur a une réponse du serveur
  if (error?.response) {
    // Si c'est une erreur 404
    if (error.response.status === 404) {
      return Promise.reject(error);
    }

    // Si l'erreur contient déjà un code, on la renvoie telle quelle
    if (error.response.data?.code) {
      return Promise.resolve({ 
        data: error.response.data,
        error: error.response.data 
      });
    }

    // Pour les erreurs HTTP, retourner l'erreur du serveur si elle existe
    if (error.response?.data) {
      return Promise.resolve({
        data: null,
        error: {
          code: error.response.data.code || 'ERROR',
          message: error.response.data.message || 'Une erreur est survenue'
        }
      });
    }

    // Pour les autres erreurs
    return Promise.resolve({
      data: null,
      error: {
        code: 'ERROR',
        message: error.message || 'Une erreur est survenue'
      }
    });
  }

  // Pour les erreurs réseau ou autres
  return Promise.reject(error);
}

const getStripe = async (id: string, customerInfo: any) => {
  const sId = await axios.post(
    `order/${id}/${customerInfo.nb_users}`,
    customerInfo
  );

  return sId;
};

export {
  offerApi,
  transferApi,
  getStripe,
  invoiceApi,
  assetsApi,
  contactApi,
  statisticsApi,
};

export { default as userApi } from './userApi';
export { default as usageApi } from './usageApi';
