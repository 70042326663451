import React, { ReactElement, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

export interface ITableHeader {
  key: string;
  label: string;
}

export const Switch = ({ checked, onChange, disabled }: any) => (
  <div
    className={`relative inline-flex items-center ${
      !disabled ? "cursor-pointer" : "cursor-not-allowed opacity-50"
    }`}
    onClick={!disabled ? onChange : undefined}
  >
    <input
      type="checkbox"
      className="sr-only"
      disabled={disabled}
      checked={checked}
      onChange={() => null}
    />
    <div
      className={`w-12 h-6 bg-gray-200 rounded-full peer transition-all duration-300 ease-in-out ${
        checked ? "bg-blue-500 shadow-inner" : ""
      }`}
    >
      <div
        className={`absolute w-5 h-5 bg-white rounded-full shadow-lg transform transition-all duration-300 ease-in-out ${
          checked ? "right-1 translate-x-0" : "left-1 -translate-x-0"
        } top-0.5`}
      />
    </div>
  </div>
);

export function TableListComponent({
  headers,
  elements,
}: {
  headers: ITableHeader[];
  elements?: any[];
}) {
  const [state, setState] = useState({ page: 0, rowsPerPage: 5 });
  const count = elements?.length || 0;

  return (
    <div className="w-full space-y-4">
      <div className="overflow-hidden bg-gray-900 rounded-xl shadow-xl border border-gray-800">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-800">
            <thead>
              <tr>
                {headers.map(({ label, key }, n: number) => (
                  <th
                    key={`t_header_cell_${key}_${n}`}
                    className="px-6 py-4 text-xs font-medium tracking-wider text-gray-400 uppercase text-left bg-gray-800/50 border-b border-gray-800 first:rounded-tl-lg last:rounded-tr-lg"
                  >
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800 bg-gray-900">
              {elements
                ?.slice(
                  state.page * state.rowsPerPage,
                  (state.page + 1) * state.rowsPerPage
                )
                .map((item: any, n: number) => (
                  <tr
                    key={`t_body_row_${n}`}
                    className="transition-all duration-200 hover:bg-gray-800/50 group"
                  >
                    {headers.map(({ key }) => (
                      <td
                        key={`t_body_cell_${key}_${n}`}
                        className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap group-hover:text-gray-100"
                      >
                        {item[key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <TableCustomPagination
          count={count}
          setParentState={setState}
          state={state}
        />
      </div>
    </div>
  );
}

export const TableCustomPagination = ({
  count,
  setParentState,
  state,
}: {
  count: number;
  setParentState: any;
  state: any;
}): ReactElement => {
  const handleChangePage = (newPage: number): void => {
    setParentState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    try {
      setParentState({
        ...state,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100, 250];
  const { page, rowsPerPage } = state;

  return (
    <div className="flex items-center justify-between px-6 py-4 border-t border-gray-800 bg-gray-900">
      <div className="flex items-center space-x-3">
        <span className="text-sm text-gray-400">Lignes par page</span>
        <select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          className="block w-20 rounded-md border border-gray-700 text-sm text-gray-300 bg-gray-800 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none"
        >
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option} className="bg-gray-800">
              {option}
            </option>
          ))}
        </select>
      </div>
      
      <div className="flex items-center space-x-6">
        <span className="text-sm font-medium text-gray-300">
          {page * rowsPerPage + 1}-
          {Math.min((page + 1) * rowsPerPage, count)} sur {count}
        </span>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0}
            className={`p-2 rounded-lg transition-all duration-200 ${
              page === 0
                ? "text-gray-600 cursor-not-allowed"
                : "text-gray-400 hover:bg-gray-800 hover:text-blue-400 active:bg-gray-700"
            }`}
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={() => handleChangePage(page + 1)}
            disabled={(page + 1) * rowsPerPage >= count}
            className={`p-2 rounded-lg transition-all duration-200 ${
              (page + 1) * rowsPerPage >= count
                ? "text-gray-600 cursor-not-allowed"
                : "text-gray-400 hover:bg-gray-800 hover:text-blue-400 active:bg-gray-700"
            }`}
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableListComponent;
