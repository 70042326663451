import React, { useState, useEffect } from "react";
import { X, Menu, User, UserPlus, Lock, Mail, UserCog } from "lucide-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { appAction, userAction } from "../../../../Store";
import { i18n } from "../../../../utils";
import { isMobile } from "../../../../utils";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { InputCheckboxComponent } from "../../../Form/Input";
import { userApi } from "../../../../Api";

interface Props {
  dispatch: Function;
  user: any;
  mod?: "light" | "normal";
  app: any;
  btnClassName?: string;
  textColorName?: string;
  labelOnly?: boolean;
  iconOnly?: boolean;
  logoutOnly?: boolean;
  defaultOpen?: boolean;
  skip?: boolean;
  onModalChangeCB?: (isOpen: boolean) => void;
}

const LoginButtonV2 = ({ dispatch, user, app, skip, onModalChangeCB, mod, iconOnly, labelOnly, defaultOpen }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isWaitingConfirmation, setIsWaitingConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    cgu: false
  });
  const [error, setError] = useState<any>(null);

  // Détecter le fragment #register dans l'URL et ouvrir la modal automatiquement
  useEffect(() => {
    const hasRegisterFragment = window.location.hash === '#register';
    if (hasRegisterFragment) {
      setIsModalOpen(true);
      setIsRegistering(true);
    } else if (defaultOpen && (!user || !user?.id)) {
      setIsModalOpen(true);
    }
  }, [defaultOpen, user]);

  // Notifier le parent quand la modal change d'état
  useEffect(() => {
    if (onModalChangeCB) {
      onModalChangeCB(isModalOpen);
    }
  }, [isModalOpen, onModalChangeCB]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (force?: boolean) => {
    try {
      const { email, password } = formData;
      const { user: authUser, error } = await userApi.auth({ email, password }, force);

      if (authUser && !error) {
        dispatch(userAction.loginUser(authUser));
        if (authUser.status) {
          closeModal();
          document.location.reload();
          return;
        }
      }

      setError(error);
    } catch (e) {
      setError(e);
    }
  };

  const handleRegister = async () => {
    const { email, password, passwordConfirmation, firstName, lastName, cgu } = formData;
    let invalid: string[] = [];

    if (!email) invalid.push("email");
    if (!password) invalid.push("password");
    if (!passwordConfirmation || passwordConfirmation !== password) invalid.push("password_confirmation");
    if (!firstName) invalid.push("first_name");
    if (!lastName) invalid.push("last_name");
    if (!cgu) invalid.push("footer_basics_cgu_link_label");

    if (invalid.length) {
      setError(invalid.map(k => i18n._(`form_profil_${k}_label`) || i18n._(`${k}`)));
      return;
    }

    const { success, waitingConfirmation, error } = await userApi.simpleRegister({
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      cgu,
    });

    if (success && !error) {
      setIsWaitingConfirmation(true);
      return;
    }

    setError(error);
  };

  const handleReset = () => {
    setIsModalOpen(false);
    dispatch(appAction.resetPassword(true));
  };

  const closeModal = () => {
    if (onModalChangeCB) onModalChangeCB(false);
    setIsModalOpen(false);
    setError(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
    if (onModalChangeCB) onModalChangeCB(true);
  };

  return (
    <div>
      {!user?.status ? (
        <>
          {skip ? (
            // Dans le contexte de la page de collaboration, ouvrir la modal
            <button
              onClick={openModal}
              className={`
                flex items-center gap-2 ${!iconOnly ? 'px-4' : 'px-2'} py-2 rounded-full bg-base-200 hover:bg-emerald-500 transition-colors
                ${mod === 'light' ?
                  'text-white rounded-full bg-base-200 hover:bg-white' :
                  'text-white hover:bg-emerald-500 hover:text-black'
                }
              `}
            >
              <User size={18} />
              {(!iconOnly || labelOnly) && <span>{i18n._("login_label")}</span>}
            </button>
          ) : (
            // Dans les autres contextes, rediriger vers la page de connexion
            <Link
              to="/login"
              className={`
                flex items-center gap-2 ${!iconOnly ? 'px-4' : 'px-2'} py-2 rounded-full bg-base-200 hover:bg-emerald-500 transition-colors
                ${mod === 'light' ?
                  'text-white rounded-full bg-base-200 hover:bg-white' :
                  'text-white hover:bg-emerald-500 hover:text-black'
                }
              `}
            >
              <User size={18} />
              {(!iconOnly || labelOnly) && <span>{i18n._("login_label")}</span>}
            </Link>
          )}

          {/* Modal d'inscription/connexion */}
          {isModalOpen && (
            <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-gray-900">
              <div className="bg-gray-800 text-white rounded-lg shadow-lg w-full max-w-md overflow-hidden">
                <div className="flex justify-between items-center p-4 border-b border-gray-700">
                  <h2 className="text-xl font-bold">
                    {isWaitingConfirmation
                      ? i18n._("register_confirmation_title") || "Confirmation Sent"
                      : isRegistering
                        ? i18n._("register_title") || "Create an Account"
                        : i18n._("login_title") || "Sign In"}
                  </h2>
                  <button onClick={closeModal} className="text-gray-400 hover:text-white">
                    <X size={20} />
                  </button>
                </div>

                {isWaitingConfirmation ? (
                  <div className="p-6 text-center">
                    <p className="mb-4">{i18n._("register_confirmation_message") || "Please check your email to confirm your account."}</p>
                    <button
                      onClick={closeModal}
                      className="w-full bg-purple-600 text-white py-3 rounded-md transition-all duration-300 hover:bg-blue-500"
                    >
                      {i18n._("close") || "Close"}
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="p-6">
                      {/* Message d'avertissement pour les collaborateurs */}
                      {skip && window.location.pathname.includes('/collab/') && (
                        <div className="mb-4 p-3 bg-yellow-900 text-yellow-100 rounded">
                          {i18n._("collab_form.login.warning") ||
                            "You need an account to collaborate on this file. Create an account or sign in to continue."}
                        </div>
                      )}

                      {error && (
                        <div className="mb-4 p-3 bg-red-900 text-red-100 rounded">
                          {Array.isArray(error) ? error.join(', ') : error.message || error}
                        </div>
                      )}

                      <form
                        id="login_form"
                        className="space-y-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          isRegistering ? handleRegister() : handleSubmit();
                        }}
                      >
                        {isRegistering && (
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <label className="block mb-1">{i18n._("form_profil_first_name_label") || "First Name"}</label>
                              <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary"
                              />
                            </div>
                            <div>
                              <label className="block mb-1">{i18n._("form_profil_last_name_label") || "Last Name"}</label>
                              <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary"
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <label className="block mb-1">{i18n._("form_profil_email_label") || "Email"}</label>
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary"
                          />
                        </div>
                        <div>
                          <label className="block mb-1">{i18n._("form_profil_password_label") || "Password"}</label>
                          <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary"
                          />
                        </div>
                        {isRegistering && (
                          <>
                            <div>
                              <label className="block mb-1">{i18n._("form_profil_password_confirmation_label") || "Confirm Password"}</label>
                              <input
                                type="password"
                                name="passwordConfirmation"
                                value={formData.passwordConfirmation}
                                onChange={handleChange}
                                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary"
                              />
                            </div>
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                id="cgu"
                                name="cgu"
                                checked={formData.cgu}
                                onChange={handleChange}
                                className="mr-2"
                              />
                              <label htmlFor="cgu">
                                {i18n._("form_profil_cgu_label") || "I accept the "}
                                <a href="/cgu" target="_blank" className="text-purple-400 hover:underline">
                                  {i18n._("footer_basics_cgu_link_label") || "Terms of Service"}
                                </a>
                              </label>
                            </div>
                          </>
                        )}
                        
                        {/* Champ de soumission caché */}
                        <input type="submit" hidden />
                      </form>
                      
                      {!isRegistering && (
                        <div className="mt-4 text-right">
                          <button
                            type="button"
                            onClick={handleReset}
                            className="text-purple-400 hover:underline"
                          >
                            {i18n._("forgot_password") || "Forgot Password?"}
                          </button>
                        </div>
                      )}
                    </div>
                    
                    <div className="flex flex-col p-4 border-t border-gray-700">
                      <button
                        form="login_form"
                        type="submit"
                        className="w-full bg-purple-600 text-white py-3 rounded-md mb-3 transition-all duration-300 hover:bg-blue-500"
                      >
                        {isRegistering
                          ? i18n._("register_button") || "Create Account"
                          : i18n._("login_button") || "Sign In"}
                      </button>
                      
                      <button
                        onClick={() => {
                          setIsRegistering(!isRegistering);
                          setError(null);
                        }}
                        className="text-purple-400 hover:underline text-center"
                      >
                        {isRegistering
                          ? i18n._("already_have_account") || "Already have an account? Sign in"
                          : i18n._("dont_have_account") || "Don't have an account? Create one"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Link
          to="/profil"
          className={`
            flex items-center gap-2 ${!iconOnly ? 'px-4' : 'px-2'} py-2 rounded-full bg-base-200 hover:bg-emerald-500 transition-colors relative
            ${mod === 'light' ?
              'text-white rounded-full bg-base-200 hover:bg-white' :
              'text-white hover:bg-emerald-500 hover:text-black'
            }
          `}
        >
          <User size={18} />
          {(!iconOnly || labelOnly) && <span>{i18n._("account_label")}</span>}
          <div className="absolute -top-1 -right-1 w-3 h-3 bg-emerald-500 rounded-full border-2 border-white"></div>
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  app: state.app,
});

export default connect(mapStateToProps)(LoginButtonV2);
