import axios, { AxiosResponse } from "axios";
import { config } from "../config";

interface CheckEmailResponse {
  exists: boolean;
  stripeStatus?: string;
  hasPaymentMethod?: boolean;
  customerId?: string;
  userStatus?: number;
}

const offerApi = {
  async getOffers(): Promise<AxiosResponse<any>> {
    const offers = await axios.get(`/stripe/offer`);
    return offers.data;
  },

  async getTotalConsumption(): Promise<AxiosResponse<any>> {
    const { data } = await axios.get(`/transfer/consumption`);
    return data || 0;
  },

  async createCheckoutSession(payload: any, referer_id?: string | null) {
    try {
      console.log('createCheckoutSession payload:', JSON.stringify(payload, null, 2));
      console.log('createCheckoutSession referer_id:', referer_id);
      
      const { data } = await axios.post("/stripe/session", payload, {
        params: { referer_id },
      });
      
      console.log('createCheckoutSession response:', JSON.stringify(data, null, 2));
      
      if (!data) {
        console.error('No data returned from /stripe/session');
        throw new Error('No session data returned from server');
      }
      return data;
    } catch (e) {
      console.error('createCheckoutSession error:', e);
      return e;
    }
  },

  async confirmCheckoutSession(status: string, session_id: string) {
    try {
      const { data } = await axios.get(`/stripe/order/${status}/${session_id}`);
      return data;
    } catch (e) {
      return e;
    }
  },

  async confirmCheckoutSessionForOldUser(status: string, session_id: string) {
    try {
      const { data } = await axios.get(
        `/stripe/order/old/${status}/${session_id}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },

  async createCheckoutSessionForUser(payload: any) {
    try {
      const { data } = await axios.put("/stripe/session", payload);
      return data || payload;
    } catch (e) {
      return e;
    }
  },

  async createGroupeOfferPayload(period: 'month' | 'year'): Promise<any> {
    try {
      const { data } = await axios.post('/stripe/groupe-offer-payload', { period });
      return data;
    } catch (e) {
      console.error('Error creating groupe offer payload:', e);
      return { error: e };
    }
  },

  async createSubscriptionWithExistingPaymentMethod(payload: any): Promise<any> {
    try {
      const { token, ...rest } = payload;
      const { data } = await axios.post('/stripe/subscription/existing-payment-method', rest, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return data;
    } catch (e) {
      console.error('Error creating subscription with existing payment method:', e);
      return { error: e };
    }
  },

  async checkEmailExists(email: string): Promise<CheckEmailResponse> {
    try {
      console.log('Vérification de l\'email:', email);
      const { data } = await axios.get(`/user/check/${encodeURIComponent(email)}`);
      console.log('Réponse de l\'API:', data);
      
      // Si l'utilisateur existe avec un moyen de paiement valide
      if (data?.hasPaymentMethod && data?.customerId) {
        return {
          exists: false, // On permet l'inscription
          hasPaymentMethod: true,
          customerId: data.customerId,
          stripeStatus: data.stripeStatus,
          userStatus: data.userStatus
        };
      }

      // Si la requête réussit, l'email n'existe pas
      console.log('Email disponible');
      return { 
        exists: false,
        hasPaymentMethod: false,
        userStatus: 0,
        stripeStatus: 'none'
      };
      
    } catch (error: any) {
      const errorData = error?.response?.data;
      console.log('Erreur API:', errorData);
      
      if (errorData?.code === 'UNAVAILABLE_EMAIL') {
        // L'email existe, on vérifie le status
        const userStatus = errorData.userStatus || 1;
        const stripeStatus = errorData.stripeStatus;
        console.log('Status utilisateur:', userStatus);
        console.log('Status Stripe:', stripeStatus);
        
        // On retourne exists: true si l'utilisateur est actif
        return { 
          exists: userStatus === 1,
          stripeStatus,
          hasPaymentMethod: errorData.hasPaymentMethod,
          customerId: errorData.customerId,
          userStatus
        };
      }
      
      console.error('Erreur lors de la vérification de l\'email:', error);
      throw error;
    }
  },

  async createCustomer(formValues: any): Promise<AxiosResponse<any> | any> {
    try {
      const { data, error }: any = await axios.post(
        `/stripe/customer`,
        formValues
      );
      return data || error;
    } catch (error: any) {
      if (!error?.response?.data) return error;

      switch (error.response.data.code) {
        case "UNAVAILABLE_EMAIL":
          return {
            error: { field: "email", reason: "unavailable" },
          };
        case "INVALID_TVA_NUMBER":
          return {
            error: { field: "vat_number", reason: "invalid" },
          };
        default:
          return {
            error: { field: "unknown", reason: "unknown" },
          };
      }
    }
  },

  /**
   * Crée une facture différée pour un client avec allowInvoice=true
   * @param payload Les données pour la création de la facture
   * @returns Les données de la facture créée ou une erreur
   */
  async createDeferredInvoice(payload: {
    customer_id: string;
    user_id: string;
    metadata: any;
    amount: number;
    currency: string;
    period: string;
  }): Promise<any> {
    try {
      console.log('Création d\'une facture différée:', payload);
      
      // Utiliser la session de paiement standard mais avec un mode spécial
      const sessionPayload = {
        customer_id: payload.customer_id,
        mode: 'payment',
        payment_method_types: ['invoice'],
        success_url: window.location.origin + '/order/success/invoice_{CHECKOUT_SESSION_ID}',
        cancel_url: window.location.origin + '/order/failed/{CHECKOUT_SESSION_ID}',
        metadata: {
          ...payload.metadata,
          payment_type: 'deferred_invoice',
          user_id: payload.user_id,
          period: payload.period
        },
        line_items: [{
          quantity: 1,
          ...(config.stripe.tax_rate ? { tax_rates: [config.stripe.tax_rate] } : {}),
          price_data: {
            currency: payload.currency,
            product: config.stripe.main_product_id || '',
            unit_amount: payload.amount
          }
        }]
      };
      
      const { data } = await axios.post('/stripe/deferred-invoice', sessionPayload);
      
      console.log('Réponse de création de facture différée:', data);
      
      if (!data) {
        console.error('Aucune donnée retournée par /stripe/deferred-invoice');
        throw new Error('Aucune donnée de facture retournée par le serveur');
      }
      
      return data;
    } catch (e: any) {
      console.error('Erreur lors de la création de la facture différée:', e);
      return { error: e.message || 'Erreur lors de la création de la facture différée' };
    }
  },
};

export default offerApi;
