import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import './index.css';
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import stores from "./Store";
import { RootState } from "./Store";
import PricingBanner from "./Components/PricingBanner";

import Main from "./Main";
import * as serviceWorker from "./serviceWorker";
import { i18n } from "./utils";
import { images } from "./assets";

const AppContent = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookiesPermissions = localStorage.getItem("cookies-permission");
  const pricingChoice = localStorage.getItem("pricing-choice");
  const [showCookies, setShowCookies] = useState(!cookiesPermissions || cookiesPermissions === 'denied');
  const [showPricingBanner, setShowPricingBanner] = useState(false);
  const [isOnDownloadPage, setIsOnDownloadPage] = useState(false);

  useEffect(() => {
    // Définir le thème DaisyUI et ignorer les préférences du navigateur
    document.documentElement.setAttribute("data-theme", "FV");
    
    // Observer les changements d'attribut data-theme pour s'assurer qu'il reste à "FV"
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-theme' &&
            document.documentElement.getAttribute('data-theme') !== 'FV') {
          document.documentElement.setAttribute('data-theme', 'FV');
        }
      });
    });
    
    // Configurer l'observateur pour surveiller les changements d'attributs sur l'élément HTML
    observer.observe(document.documentElement, { attributes: true });

    // Vérifier si nous sommes sur une page de téléchargement
    const isDownloadPath = window.location.pathname.startsWith('/d/');
    setIsOnDownloadPage(isDownloadPath);

    const shouldShowBanner = () => {
      // Si pas de cookies acceptés, ne pas montrer
      if (cookiesPermissions !== 'granted') return false;

      // Sur la page d'accueil, montrer si pas de choix fait
      if (!isDownloadPath) {
        return !user && !pricingChoice;
      }

      // Sur la page de téléchargement, vérifier si l'utilisateur n'est pas connecté et la dernière date d'affichage
      if (user) return false;
      
      const lastShown = localStorage.getItem("pricing-banner-last-shown");
      if (!lastShown) return true;

      const lastDate = new Date(lastShown);
      const now = new Date();
      return lastDate.getDate() !== now.getDate() || lastDate.getMonth() !== now.getMonth() || lastDate.getFullYear() !== now.getFullYear();
    };

    if (shouldShowBanner()) {
      setShowPricingBanner(true);
    }
  }, [cookiesPermissions, user, pricingChoice]);

  const handleCookiesAccepted = () => {
    setShowCookies(false);
    // Afficher la bannière après l'acceptation des cookies
    if (!user && !pricingChoice) {
      setTimeout(() => {
        setShowPricingBanner(true);
      }, 500);
    }
  };

  return (
    <>
      <Main />
      {showCookies && (
        <CookiesComponent 
          onAccept={handleCookiesAccepted}
        />
      )}
      {showPricingBanner && (
       <PricingBanner
         onChoice={(choice) => {
           if (choice !== 'close') {
             localStorage.setItem("pricing-choice", choice);
           }
           if (isOnDownloadPage) {
             localStorage.setItem("pricing-banner-last-shown", new Date().toISOString());
           }
           setShowPricingBanner(false);
         }}
         cookiesAccepted={true}
         isDownloadPage={isOnDownloadPage}
         isUserConnected={!!user}
       />
      )}
    </>
  );
};

const AppRooting = () => {
  return (
    <Provider store={stores.store}>
      <PersistGate loading={null} persistor={stores.persistor}>
        <AppContent />
      </PersistGate>
    </Provider>
  );
};

interface ModalProps {
  centered?: boolean;
  isOpen: boolean;
  className?: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ centered, isOpen, className = "", children }) => {
  if (!isOpen) return null;
  
  return (
    <div className={`fixed inset-0 z-50 flex ${centered ? 'items-center' : ''} justify-center backdrop-blur-sm`}>
      <div className="fixed inset-0 bg-black/70"></div>
      <div className={`bg-gray-900 border border-gray-800 rounded-xl p-8 relative z-10 max-w-lg w-full mx-4 shadow-2xl ${className}`}>
        {children}
      </div>
    </div>
  );
};

const Button: React.FC<{
  className?: string;
  color?: string;
  onClick: () => void;
  children: React.ReactNode;
}> = ({ className = "", color = "default", onClick, children }) => (
  <button
    className={`px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors duration-200 ease-in-out ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const Card: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => (
  <div className={`text-gray-100 ${className}`}>{children}</div>
);

const CardFooter: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => (
  <div className={`mt-6 pt-6 border-t border-gray-800 ${className}`}>{children}</div>
);

interface CookiesComponentProps {
  isOpen?: boolean;
  onAccept?: () => void;
}

const CookiesComponent: React.FC<CookiesComponentProps> = ({ isOpen: externalIsOpen, onAccept }) => {
  const legalsPages = ["/legal-terms", "/privacy", "/cgu"];
  const cookiesPermissionsFromLocalStorage =
    localStorage.getItem("cookies-permission");
  const [state, setState] = useState({
    cookiesPermissions: cookiesPermissionsFromLocalStorage,
    isReady: false,
    isOpen: typeof externalIsOpen === 'boolean' ? externalIsOpen : (
      !legalsPages.includes(document.location.pathname) &&
      (!cookiesPermissionsFromLocalStorage ||
        "denied" === cookiesPermissionsFromLocalStorage)
    ),
  });

  const updateState = () => {
    setTimeout(() => {
      if (!i18n.isReady) return updateState();
      return setState({ ...state, isReady: true });
    }, 250);
  };

  const handleCookiesAcceptation = () => {
    localStorage.setItem("cookies-permission", "granted");
    setState({ ...state, cookiesPermissions: "granted", isOpen: false });
    if (onAccept) {
      onAccept();
    }
  };

  let { isReady, isOpen } = state;

  if (!isReady) {
    updateState();
    return null;
  }

  let reasons: string[] | any = i18n._("modal_cookies_reasons");
  if ("modal_cookies_reasons" === reasons) reasons = undefined;

  return (
    <Modal centered isOpen={isOpen} className="max-w-md">
      <Card className="relative">
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <div className="bg-gray-800/50 p-4 rounded-full">
              <img src={images.LogoBahaus} width={60} alt="Logo Bahaus" className="opacity-90" />
            </div>
          </div>

          <h4 className="mb-4 text-xl font-bold text-white">
            {i18n._("modal_cookies_title")}
          </h4>

          <h5 className="mb-6 text-base text-gray-300">
            {i18n._("modal_cookies_intro")}
          </h5>
        </div>

        <div className="mb-6 text-gray-300">
          <ul className="space-y-2">
            {reasons &&
              reasons.map((str: string, i: number) => (
                <li key={`reasons_${i}`} className="flex items-start text-sm">
                  <span className="mr-2 text-emerald-400">•</span>
                  {str}
                </li>
              ))}
          </ul>
        </div>

        <div>
          <p
            className="text-sm text-gray-300 [&_a]:text-emerald-400 [&_a]:underline [&_a]:hover:text-emerald-300"
            dangerouslySetInnerHTML={{
              __html: i18n._("modal_cookies_outro"),
            }}
          />
        </div>

        <CardFooter className="text-center">
          <Button
            className="mx-auto bg-emerald-600 hover:bg-emerald-700"
            onClick={handleCookiesAcceptation}
          >
            {i18n._("btn_accept_label")}
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

ReactDOM.render(<AppRooting />, document.getElementById("root"));

// Désactivation du service worker pour permettre les mises à jour immédiates de l'application
// Le service worker empêchait les mises à jour de la version car il mettait en cache les ressources
serviceWorker.unregister();
