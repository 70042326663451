import React, { useEffect, useRef, useState } from "react";
import {
  Play,
  Pause,
  SkipBack,
  SkipForward,
  Volume2,
  VolumeX,
  Maximize,
} from "lucide-react";
import "./Timeline.css";

export type ITimeline = {
  markers: Array<{
    time: number;
    id: string;
    content: string;
    status: 'open' | 'close';
  }>;
  playerRef?: any;
  playing?: boolean;
  setPlaying?: any;
  duration: number;
  currentTime: number;
  fullScreen?: boolean;
  setFullScreen?: any;
  refreshed: any;
  loadedBuffer?: number;
  zoomedComment?: string;
  setZoomedComment?: (id: string | null) => void;
  type?: "audio" | "video" | "image";
  volume?: any;
  setVolume?: any;
  setCurrentTime?: any;
  isReplacing?: boolean;
  setIsReplacing?: any;
};

export function Timeline({
  type,
  setZoomedComment,
  zoomedComment,
  fullScreen,
  setFullScreen,
  volume,
  setVolume,
  markers = [],
  playerRef,
  setPlaying,
  playing,
  duration = 0,
  setCurrentTime,
  currentTime = 0,
  refreshed,
  loadedBuffer,
  setIsReplacing,
  isReplacing,
}: ITimeline) {
  const [isMuted, setIsMuted] = useState<boolean>(false);

  // Deux refs séparées
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [{ last, first }, setFirstOrLast] = useState({
    first: true,
    last: false,
    skip: true,
  });

  const [timeInfo, setTimeInfo] = useState<{
    show?: boolean;
    time?: number | null;
    left?: number | null;
    last?: any;
  }>({
    show: false,
    time: null,
    left: null,
    last: null,
  });

  const [isResizing, setIsResizing] = useState<boolean>(false);

  // Filtrer les marqueurs ouverts
  const openMarkers = markers.filter((marker: ITimeline['markers'][0]) => marker.status === 'open');

  // -- Mises à jour first/last en fonction des marqueurs
  useEffect(() => {
    const nextMarkers = markers.find(({ time }) => time > currentTime);
    const prevMarkers = markers.find(({ time }) => time < currentTime);

    setFirstOrLast(({ first, last, skip }) => ({
      first: skip ? first : !prevMarkers || !currentTime,
      last: skip ? last : !nextMarkers,
      skip: false,
    }));
  }, [currentTime, markers]);

  // -- Zoom sur un marqueur
  useEffect(() => {
    console.log('Timeline: useEffect for zooming triggered. zoomedComment:', zoomedComment);
    const found = markers.find(({ id }) => zoomedComment === id);
    console.log('Timeline: Found marker:', found);
    if (found && typeof found.time === "number" && playerRef.current) {
      console.log('Timeline: Seeking to time:', found.time);
      playerRef.current.seekTo(found.time, "seconds");
      setPlaying(false);
    }
  }, [zoomedComment, markers, playerRef, setPlaying]);

  // -- Quand on déplace le range
  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (playerRef.current) {
        console.log("Timeline: handleSeek called, current value:", e.target.value);
        
        // Appliquer directement la recherche sans changer l'état de lecture
        playerRef.current.seekTo(+e.target.value / 1000, "seconds");
        
        // Optionnel: mettre à jour le temps actuel
        if (setCurrentTime) {
          setCurrentTime(+e.target.value / 1000);
        }
      }
    } catch (err) {
      console.error("Unable to seek", err);
    }
  };
  
  // Simplifier les raccourcis clavier
  useEffect(() => {
    const onkeydown = (evt: KeyboardEvent) => {
      try {
        const tagName = (evt.target as HTMLElement)?.tagName;
        if (["INPUT", "TEXTAREA"].includes(tagName)) {
          return;
        }
        const { key, code, altKey } = evt;
  
        // Raccourci pour bascule play/pause
        if (key === " " || code === "Space") {
          console.log("Timeline: Space key pressed, toggling playback");
          if (setPlaying) {
            setPlaying(!playing);
          }
        }
        
        // Autres raccourcis...
      } catch (e) {
        console.error("Error in keyboard handler:", e);
      }
    };
  
    window.addEventListener("keydown", onkeydown);
    return () => {
      window.removeEventListener("keydown", onkeydown);
    };
  }, [playing, setPlaying]);

  // -- Seek depuis le clavier
  const handleSeekFromKeyboard = (value: number) => {
    try {
      if (playerRef.current) {
        playerRef.current.seekTo(value, "seconds");
      }
    } catch (err) {
      console.error("Unable to seek", err);
    }
  };

  // -- Gère le mute
  const handleVolume = () => {
    setIsMuted(!volume);
  };
  useEffect(handleVolume, [volume]);

  // -- Raccourcis clavier
  useEffect(() => {
    const onkeydown = (evt: KeyboardEvent) => {
      try {
        const tagName = (evt.target as HTMLElement)?.tagName;
        if (["INPUT", "TEXTAREA"].includes(tagName)) {
          return;
        }
        const { key, code, altKey } = evt;

        if (key === "Enter" && altKey) {
          setFullScreen?.((prev: boolean) => !prev);
        }

        if (key === "r" || key === "R") {
          setIsReplacing?.((prev: boolean) => !prev);
        }

        // Espace
        if (key === " " || code === "Space") {
          setPlaying?.((prev: boolean) => !prev);
        }

        // Flèches gauche/droite => -1s/+1s
        if (["ArrowLeft"].includes(key) || ["ArrowLeft"].includes(code)) {
          setCurrentTime?.((cT: number) => {
            handleSeekFromKeyboard(cT - 1);
            return cT - 1;
          });
        }
        if (["ArrowRight"].includes(key) || ["ArrowRight"].includes(code)) {
          setCurrentTime?.((cT: number) => {
            handleSeekFromKeyboard(cT + 1);
            return cT + 1;
          });
        }
      } catch (e) {
        // no-op
      }
    };

    window.addEventListener("keydown", onkeydown);
    return () => {
      window.removeEventListener("keydown", onkeydown);
    };
  }, [playing, setPlaying, setFullScreen, setIsReplacing, setCurrentTime, handleSeekFromKeyboard]);

  // -- Gère la navigation de marqueur en marqueur (préc/next)
  const seekOnComment = (dir: "next" | "prev") => {
    console.log('Timeline: seekOnComment called with direction:', dir);
    if (!markers.length) return;
    
    const sortedMarkers = [...markers].sort((a, b) => a.time - b.time);
    let targetMarker;
    const wasPlaying = playing;
  
    if (dir === "next") {
      targetMarker = sortedMarkers.find((m) => m.time > currentTime);
    } else {
      // prev
      sortedMarkers.reverse();
      targetMarker = sortedMarkers.find((m) => m.time < currentTime);
    }
  
    console.log('Timeline: Target marker:', targetMarker);
  
    if (targetMarker) {
      // Mettre en pause pendant la recherche
      setPlaying?.(false);
      
      // Effectuer la recherche
      playerRef.current.seekTo(targetMarker.time, "seconds");
      setCurrentTime?.(targetMarker.time);
      console.log('Timeline: Setting zoomedComment to:', targetMarker.id);
      setZoomedComment?.(targetMarker.id);
      
      // Si la vidéo était en cours de lecture, reprendre après un court délai
      if (wasPlaying) {
        setTimeout(() => {
          setPlaying?.(true);
        }, 300); // Délai plus long pour les marqueurs
      }
    } else if (dir === "prev" && currentTime > 0) {
      // Retour au début
      setPlaying?.(false);
      playerRef.current.seekTo(0, "seconds");
      setCurrentTime?.(0);
      
      // Si la vidéo était en cours de lecture, reprendre après un court délai
      if (wasPlaying) {
        setTimeout(() => {
          setPlaying?.(true);
        }, 300);
      }
    }
  
    const isFirst =
      dir === "prev" &&
      (!targetMarker || targetMarker.time === sortedMarkers[0].time);
    const isLast =
      dir === "next" &&
      (!targetMarker ||
        targetMarker.time === sortedMarkers[sortedMarkers.length - 1].time);
  
    setFirstOrLast({ first: isFirst, last: isLast, skip: true });
  };

  const handleMarkerClick = (time: number, id: string) => {
    console.log('Timeline: handleMarkerClick called with time:', time, 'and id:', id);
    if (playerRef.current) {
      const wasPlaying = playing;
      
      // Mettre en pause pendant la recherche
      setPlaying?.(false);
      
      // Effectuer la recherche
      playerRef.current.seekTo(time, "seconds");
      setCurrentTime?.(time);
      console.log('Timeline: Setting zoomedComment to:', id);
      setZoomedComment?.(id);
      
      // Si la vidéo était en cours de lecture, reprendre après un court délai
      if (wasPlaying) {
        setTimeout(() => {
          setPlaying?.(true);
        }, 300);
      }
    }
  };

  useEffect(() => {
    if (zoomedComment) {
      const marker = markers.find(m => m.id === zoomedComment);
      if (marker && inputRef.current) {
        const markerPosition = (marker.time / duration) * inputRef.current.offsetWidth;
        inputRef.current.scrollLeft = markerPosition - inputRef.current.offsetWidth / 2;
      }
    }
  }, [zoomedComment, markers, duration]);

  // -- Recalcule first/last si currentTime change
  useEffect(() => {
    const nextMarkers = markers.find(({ time }) => time > currentTime);
    const prevMarkers = markers.find(({ time }) => time < currentTime);

    setFirstOrLast(({ first, last, skip }) => ({
      first: skip ? first : !prevMarkers || !currentTime,
      last: skip ? last : !nextMarkers,
      skip: false,
    }));
  }, [currentTime, markers]);

  // -- Survole (hover) : calcule la position du temps par rapport à la taille
  const handleHover = (e: React.MouseEvent<HTMLInputElement>) => {
    try {
      if (!wrapperRef.current) return;

      const rect = wrapperRef.current.getBoundingClientRect();
      const mousePosition = e.clientX - rect.left;
      const left = mousePosition + 70;
      const targetTimeInPercent = mousePosition / rect.width;

      setTimeInfo({
        show: true,
        left,
        time: duration * targetTimeInPercent,
      });
    } catch (err) {
      console.error("Unable to seek on hover", err);
    }
  };

  const handleHoverEnd = () => {
    setTimeInfo((prev) => ({ ...prev, show: false }));
  };

  // -- Calcule la position en pourcentage
  const getLeft = (time: number) => {
    return duration ? (time * 100) / duration : 0;
  };

  if (isResizing) return null;

  return (
    <>
      <div className="border-t-10 border-black relative z-20" />
      {/* Container parent en position relative (4px de haut) */}
      <div className="relative w-full h-4 z-50">
        {/* Div absolute pour les marqueurs */}
        <div
          ref={wrapperRef}
          className="absolute inset-0 z-50 fv-media-timeline-wrapper"
          onClick={(e) => {
            const rect = wrapperRef.current?.getBoundingClientRect();
            if (!rect || !playerRef.current) return;
            const clickPosition = e.clientX - rect.left;
            const timelineWidth = rect.width;
            setPlaying?.(false);
            playerRef.current.seekTo(
              (clickPosition / timelineWidth) * duration,
              "seconds"
            );
          }}
        >
          {markers?.map(({ time, id, content }: any) => (
            <div
              key={`marker--${id}`}
              id={`marker--${id}`}
              className={`fv-media-timeline-marker absolute w-3 h-3 border-2
                         border-white rounded-full transform -translate-x-1/2 -translate-y-1/2
                         transition-all duration-200 ease-in-out cursor-pointer
                         hover:scale-110 hover:shadow-lg z-60
                         ${zoomedComment === id ? 'bg-yellow-500 scale-125' : 'bg-green-500'}`}
              style={{
                left: `${getLeft(time)}%`,
                top: "50%",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleMarkerClick(time, id);
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverEnd}
            >
              <div
                className="fv-media-timeline-marker-content absolute bottom-full left-1/2
                               transform -translate-x-1/2 bg-gray-800 text-gray-200 text-xs
                               py-1 px-2 rounded shadow-md whitespace-nowrap z-70 opacity-0 transition-opacity duration-200"
                style={{
                  opacity: timeInfo.show ? 1 : 0,
                  fontSize: '0.7rem',
                  lineHeight: '1rem',
                }}
              >
                <div className="font-semibold">{printTime(time)}</div>
                <div className="text-teal-300">{content}</div>
                <div
                  className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-full"
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    borderTop: '6px solid #1f2937', // Matches bg-gray-800
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Range en absolute, occupant la même zone */}
        <input
          ref={inputRef}
          type="range"
          className="timeline absolute inset-0 w-full h-4 cursor-pointer"
          max={duration * 1000}
          value={currentTime * 1000}
          onChange={handleSeek}
          onMouseMove={handleHover}
          onMouseLeave={handleHoverEnd}
          style={{
            background: `linear-gradient(
               to right,
               #14b8a6 ${(currentTime * 100) / duration}%,
               #0d9488 ${(currentTime * 100) / duration}%,
               rgba(13, 148, 136, 0.3) ${(currentTime * 100) / duration}% ${
               ((loadedBuffer || 0) * 100) / duration
             }%,
               transparent ${
                 ((loadedBuffer || currentTime) * 100) / duration
               }% 100%
             )`,
          }}
        />
      </div>

      {/* Contrôles de lecture en-dessous */}
      <div
        onMouseLeave={() => {
          setTimeInfo({ last: timeInfo });
        }}
        className="w-auto z-30 bg-gray-900 gap-2 p-4 py-4 pb-4 flex items-center justify-center"
      >
        {playing ? (
  <Pause
    className="cursor-pointer mr-2 text-white"
    size={16}
    onClick={() => {
      console.log("Timeline: Pause button clicked");
      // Simplifier l'appel pour éviter de passer par un state setter (qui peut être asynchrone)
      if (setPlaying) {
        setPlaying(false);
      }
    }}
  />
) : (
  <Play
    className="cursor-pointer mr-2 text-white"
    size={16}
    onClick={() => {
      console.log("Timeline: Play button clicked");
      // Simplifier l'appel pour éviter de passer par un state setter (qui peut être asynchrone)
      if (setPlaying) {
        setPlaying(true);
      }
    }}
  />
)}


        <SkipBack
          className={`cursor-pointer mr-2 text-white ${first ? "opacity-50" : ""}`}
          size={16}
          onClick={!first ? () => seekOnComment("prev") : undefined}
        />
        <SkipForward
          className={`cursor-pointer mr-2 text-white ${last ? "opacity-50" : ""}`}
          size={16}
          onClick={!last ? () => seekOnComment("next") : undefined}
        />

        {/* Volume */}
        {isMuted ? (
          <VolumeX
            className="cursor-pointer mr-2 text-white"
            size={16}
            onClick={() => setVolume(1)}
          />
        ) : (
          <Volume2
            className="cursor-pointer mr-2 text-white"
            size={16}
            onClick={() => setVolume(0)}
          />
        )}

        {/* Plein écran (vidéo seulement) */}
        {type !== "audio" && (
          <Maximize
            className="cursor-pointer mr-2 text-white"
            size={16}
            onClick={() => {
              setFullScreen?.((prev: boolean) => !prev);
            }}
          />
        )}
      </div>

      <div className="px-4 text-right z-40 bg-base-fv">
        <small className="text-white">
          {printTime(currentTime)} / {printTime(duration)}
        </small>
      </div>
    </>
  );
}

function printTime(time: number) {
  let hour = 0;
  let min = Math.floor(time / 60);
  let sec = Math.floor(time % 60);
  let centisec = Math.floor((time % 1) * 100);

  if (min >= 60) {
    hour = Math.floor(min / 60);
    min = min % 60;
  }

  return `${hour ? hour.toString().padStart(2, "0") + ":" : ""}${min
    .toString()
    .padStart(2, "0")}:${sec.toString().padStart(2, "0")}.${centisec
    .toString()
    .padStart(2, "0")}`;
}
