import { createStore, combineReducers, applyMiddleware, Middleware, Action, AnyAction } from "redux";
import { 
  userReducer, userAction, appReducer, appAction,
  fileReducer, fileAction, transferReducer, transferAction,
  offerReducer, offerAction, depositReducer, depositAction,
  uiReducer 
} from "./Reducers";
import connectionReducer from "./Reducers/connectionReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import transferPersistMiddleware from "./middleware/transferPersistMiddleware";

// Configuration de redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app', 'ui', 'user'], // Ne plus blacklister 'transfer'
  debug: process.env.NODE_ENV === 'development'
};

// Root reducer avec types -
const rootReducer = combineReducers({
  offer: offerReducer,
  user: userReducer,
  app: appReducer,
  file: fileReducer,
  transfer: transferReducer,
  deposit: depositReducer,
  ui: uiReducer,
  connection: connectionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// Types pour les thunks
interface ThunkDispatch<S, E, A extends Action> {
  <T extends A>(action: T): T;
  <R>(asyncAction: ThunkAction<R, S, E, A>): R;
}

type ThunkAction<R, S, E, A extends Action> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;

// Middleware
const asyncActionsMiddleware: Middleware = store => next => action => {
  if (typeof action === 'function') {
    return (action as ThunkAction<any, typeof store.getState, unknown, AnyAction>)(
      store.dispatch,
      store.getState,
      undefined
    );
  }
  return next(action);
};

// Création du store
const middlewares = [asyncActionsMiddleware, transferPersistMiddleware];

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(...middlewares)
);

// Création du persistor
const persistor = persistStore(store, null, () => {
  // Suppression du log d'initialisation
});

// Nettoyage périodique des transferts
let lastCleanupTime = 0;
const CLEANUP_INTERVAL = 60000; // 1 minute

// Debug listener (development only)
if (process.env.NODE_ENV === 'development') {
  store.subscribe(() => {
    const state = store.getState();
    const currentTime = Date.now();

    // Nettoyage périodique
    if (currentTime - lastCleanupTime > CLEANUP_INTERVAL) {
      lastCleanupTime = currentTime;
      const oldTransfers = Object.entries(state.transfer.transfers)
        .filter(([_, transfer]) => {
          const age = currentTime - (transfer.startTime || 0);
          return (
            !transfer.isLocked &&
            !transfer.isUploadFinished &&
            transfer.loaded === 0 &&
            transfer.totalSize === 0 &&
            transfer.progress.current.instantSpeed === 0 &&
            age > 300000 // Plus de 5 minutes
          );
        })
        .map(([id]) => id);

      if (oldTransfers.length > 0) {
        // Suppression du log de nettoyage
        oldTransfers.forEach(id => {
          store.dispatch(transferAction.purgeInfos(id));
        });
      }
    }

    const activeTransfer = state.transfer.activeTransferId 
      ? state.transfer.transfers[state.transfer.activeTransferId]
      : null;

    // Suppression du log détaillé de l'état
  });
}

// Types exports
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

// Action creators exports
export {
  userAction,
  appAction,
  fileAction,
  transferAction,
  offerAction,
  depositAction,
  uiReducer,
};

export * from "./Reducers/connectionReducer";

// Store export
const reduxStore = {
  store,
  persistor,
  dispatch: store.dispatch,
  getState: store.getState,
};

export default reduxStore;
