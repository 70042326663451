import { AnyAction } from 'redux';

// Action Types
export const SET_CONNECTION_QUALITY = 'SET_CONNECTION_QUALITY';

// Types
export type ConnectionQualityType = 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';

export interface ConnectionState {
  quality: ConnectionQualityType;
  lastUpdate: string | null;
  history: Array<{
    quality: ConnectionQualityType;
    timestamp: string;
  }>;
}

// Initial State
const initialState: ConnectionState = {
  quality: 'unknown',
  lastUpdate: null,
  history: [] // Initialiser comme un tableau vide
};

// Maximum history entries to keep
const MAX_HISTORY_LENGTH = 10;

const connectionReducer = (state = initialState, action: AnyAction): ConnectionState => {
  switch (action.type) {
    case SET_CONNECTION_QUALITY: {
      const newQuality = action.payload as ConnectionQualityType;
      const timestamp = new Date().toISOString();

      // Ne mettre à jour que si la qualité est différente
      if (state.quality !== newQuality) {
        console.log('[ConnectionReducer] Quality changed:', {
          from: state.quality,
          to: newQuality,
          timestamp
        });

        // S'assurer que history est un tableau avant de l'utiliser
        const currentHistory = Array.isArray(state.history) ? state.history : [];

        // Créer une nouvelle entrée d'historique
        const newHistory = [
          { quality: newQuality, timestamp },
          ...currentHistory
        ].slice(0, MAX_HISTORY_LENGTH);

        return {
          ...state,
          quality: newQuality,
          lastUpdate: timestamp,
          history: newHistory
        };
      }

      // Si la qualité n'a pas changé, mettre à jour uniquement le timestamp
      return {
        ...state,
        lastUpdate: timestamp
      };
    }

    default:
      return state;
  }
};

// Selectors
export const selectConnectionQuality = (state: { connection: ConnectionState }): ConnectionQualityType =>
  state.connection.quality;

export const selectConnectionHistory = (state: { connection: ConnectionState }) =>
  state.connection.history;

export const selectLastUpdate = (state: { connection: ConnectionState }): string | null =>
  state.connection.lastUpdate;

export default connectionReducer;