import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fileAction, transferAction } from "../../../../Store";
import { i18n } from "../../../../utils";
import { images } from "../../../../assets";

const Brand = ({ mode, resetTransfer, file, forcePath, activeTransferId }: any) => {
  const Logo = mode === "light" ? images.LogoLight : images.Logo;
  
  const handleClick = () => resetTransfer(file.length, activeTransferId);

  const logoElement = (
    <img
      className="w-[180px] h-[87px]"
      src={Logo}
      alt={i18n._("fv_logo_alt")}
    />
  );

  if (forcePath) {
    return (
      <a
        href="/"
        onClick={handleClick}
        title={i18n._("fv_slogan")}
        className="block"
      >
        {logoElement}
      </a>
    );
  }

  return (
    <Link
      to="/"
      onClick={handleClick}
      title={i18n._("fv_slogan")}
      className="block"
    >
      {logoElement}
    </Link>
  );
};

const mapStateToProps = (state: any) => ({ 
  file: state.file,
  activeTransferId: state.transfer.activeTransferId
});

const mapDispatchToProps = (dispatch: any) => ({
  resetTransfer: (fLen: any, transferId: string) => {
    dispatch(transferAction.purgeInfos(transferId));
    dispatch(fileAction.purgeFile());
    if (document.location.pathname === "/") document.location.reload();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Brand);
