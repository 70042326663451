// prettier-ignore
//@ts-ignore
const _paq = window._paq || [];

export default {
  _paq,
  trackAnnonce: (domAnnonceId: string) => {
    _paq.push(["trackAllContentImpressions"]);
    _paq.push(["trackVisibleContentImpressions"]);
    _paq.push([
      "trackContentImpressionsWithinNode",
      document.getElementById(domAnnonceId),
    ]);

    return (domLinkId: string) => {
      //@ts-ignore
      _paq.push(["enableLinkTracking"]);
      _paq.push([
        "trackContentInteractionNode",
        document.getElementById(domLinkId),
        "Clicked",
      ]);
    };
  },
};
