import React, { useRef, useEffect, useState } from "react";
import { AlertCircle } from "lucide-react";
import classNames from "classnames";
import { IInputProps, InputObject } from "../../../types/input";

export function InputTextComponent({ inputObject }: { inputObject: InputObject }) {
  const {
    label,
    name,
    defaultValue,
    maxLength,
    minLength,
    required,
    disabled,
  }: IInputProps = inputObject.getRawInput();

  const [error, setError] = useState<string | null>(null);
  const [localValue, setLocalValue] = useState(inputObject.value || defaultValue || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputObject.setRef) {
      inputObject.setRef(inputRef);
    }
  }, [inputObject]);

  useEffect(() => {
    setLocalValue(inputObject.value || defaultValue || '');
  }, [inputObject.value, defaultValue]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parentElement = event.currentTarget.parentElement;

    if (parentElement) {
      parentElement.classList.add("was-validated");
    }

    if (required && !value.trim()) {
      setError("missing");
      if (inputObject.setCurrentError) {
        inputObject.setCurrentError("missing");
      }
      return;
    }

    if (
      (minLength && value.length < minLength) ||
      (maxLength && value.length > maxLength)
    ) {
      setError("invalid");
      if (inputObject.setCurrentError) {
        inputObject.setCurrentError("invalid");
      }
      return;
    }

    setError(null);
    if (inputObject.setCurrentError) {
      inputObject.setCurrentError(null);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLocalValue(value);
    if (inputObject.setValue) {
      inputObject.setValue(value);
    }
    setError(null);
    if (inputObject.setCurrentError) {
      inputObject.setCurrentError(null);
    }

    const parentElement = event.target.parentElement;
    if (parentElement) {
      parentElement.classList.remove("was-validated");
    }
  };

  return (
    <div className={`form-control ${error ? "mb-4" : "mb-2"}`}>
      <label htmlFor={name} className="label">
        <span className="label-text">
          {label} {required && <span className="text-error">*</span>}
        </span>
      </label>
      <input
        ref={inputRef}
        id={name}
        name={name}
        type="text"
        minLength={minLength}
        maxLength={maxLength}
        value={localValue}
        required={required}
        disabled={disabled}
        className={classNames(
          "input input-bordered",
          {
            "input-error": error,
            "input-success": !error && localValue,
          }
        )}
        onBlur={handleBlur}
        onChange={handleChange}
        {...inputObject.rest}
      />
      {error && (
        <span className="mt-1 flex items-center text-error text-sm" role="alert">
          <AlertCircle className="mr-1 w-4 h-4" />
          {error === "missing" && "Ce champ est requis."}
          {error === "invalid" && "Veuillez respecter les contraintes de ce champ."}
        </span>
      )}
    </div>
  );
}
