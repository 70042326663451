import React, {
  ReactNode,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef
} from 'react';
import SchemaActions from '../../../SEO/SchemaActions';
import { FileProgress, Transfer, defaultTransfer } from '../../../../Constants/defaultTransfer';
import { useDispatch, useSelector } from "react-redux";
import { 
  CheckCircle, 
  XCircle, 
  ChevronRight, 
  MapPin, 
  Clock, 
  X,
  FileText,
  Trash2,
  ImageIcon,
  FileArchive,
  Film,
  Music,
  Code,
  Leaf,
  HelpCircle,
  Minimize2
} from "lucide-react";
import Footer from "../../../Footer/Footer";
import Toast from "../../../Toast/Toast";
import MinimizedTransfer from "../../../Shared/MinimizedTransfer";
import FileUploadProgress from "../Progress/FileUploadProgress";
import { UploadPhase, determinePhase } from "../../../../Constants/UploadPhases";

// Store Actions
import {
  transferAction,
  uiAction,
  fileAction
} from "../../../../Store/Actions";

// Components
import UploaderForm from "../UploaderForm/UploaderForm";
import { ActionInputComponent } from "../../../Button";
import LoaderComponent from "../../../Loader/Loader";
import { Progress } from "../Progress/Progress";

// Utils & Config
import { convertDate, convertSize, fixeVal, convertTime } from "../../../../utils";
import i18n from "../../../../utils/i18n";
import { config } from "../../../../config";

// Styles
import "./Layout.css";

// Utility function for i18n
const translate = (key: string, params?: any[]): string => {
  if (typeof i18n._ !== 'function') return '';
  return params ? i18n._(key, params) : i18n._(key) || '';
};

// Action destructuring
const { setInfos, setPhase } = transferAction;
const { setPanelState } = uiAction;

// Types
interface TransferInfosModel {
  id?: string;
  transfer_type?: number;
  transfer_name: string;
  transfer_message: string;
  transfer_password: string;
  transfer_confirm_password: string;
  recipients: string[];
  for_team: boolean;
  auto_remove: boolean;
  auto_send: boolean;
}

interface ProgressPanelProps {
  onMinimize?: () => void;
}

interface FileMeta {
  id: string;
  name: string;
  size: number;
  type: string;
  lastModifiedDate: string;
  uploadedDate: string;
}

interface ChunkProgress {
  loaded: number;
  total: number;
}

interface FileObject {
  cancel: () => void;
  file: File;
  meta: FileMeta;
  remove: () => void;
  restart: () => void;
}

interface Props {
  role: number;
  resetCb?: () => void;
  submitCb: (data: any) => void;
  maximum_size?: number;
  input: ReactNode;
  previews: ReactNode;
  submitButton: ReactNode;
  dropzoneProps: Record<string, any>;
  isModalOpen?: boolean;
}

interface RootState {
  transfer: {
    transfers: {
      [transferId: string]: Transfer;
    };
    activeTransferId: string | null;
    currentPhase?: UploadPhase;
  };
  file: FileObject[];
  app: {
    APP_ERROR: any;
  };
  ui: {
    isPanelOpen: boolean;
  };
  user?: {
    user?: {
      role: number;
      permissions?: {
        upload_size?: {
          value: number;
        };
      };
      limit_total?: number;
      current_uploaded_size?: number;
    };
  };
}

// Utility functions
const getFileIcon = (fileName?: string) => {
  if (!fileName) return <FileText className="w-5 h-5" />;
  
  const ext = fileName.split('.').pop()?.toLowerCase();
  
  switch(ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'webp':
      return <ImageIcon className="w-5 h-5" />;
    case 'zip':
    case 'rar':
    case '7z':
      return <FileArchive className="w-5 h-5" />;
    case 'mp4':
    case 'avi':
    case 'mov':
      return <Film className="w-5 h-5" />;
    case 'mp3':
    case 'wav':
    case 'ogg':
      return <Music className="w-5 h-5" />;
    case 'js':
    case 'ts':
    case 'jsx':
    case 'tsx':
      return <Code className="w-5 h-5" />;
    default:
      return <FileText className="w-5 h-5" />;
  }
};

const parseCurly = (str: string | undefined): string => {
  if (!str) return '';
  return str.split("[").join("_5B_").split("]").join("_5D_");
};

const decodeFileName = (fileName: string): string => {
  try {
    const decodedName = decodeURIComponent(fileName);
    return decodedName.replace(/\+/g, ' ');
  } catch {
    return fileName;
  }
};

const ttlTransfer = [2, 7, 15];

// Composant séparé pour le bouton d'annulation
const CancelButton = React.memo(({ onCancel }: { onCancel: () => void }) => {
  return (
    <button
      onClick={onCancel}
      className="btn btn-ghost btn-sm flex items-center"
      aria-label="Cancel upload"
    >
      <X className="w-4 h-4 mr-2" />
      {i18n._("cancel")}
    </button>
  );
});
CancelButton.displayName = 'CancelButton';


const LayoutComponent: React.FC<Props> = ({
  role,
  submitCb,
  resetCb,
  input,
  previews,
  submitButton,
  dropzoneProps,
  isModalOpen = false
}) => {
  const dispatch = useDispatch();
  const transfer = useSelector<RootState, Transfer>(state => {
    const activeTransferId = state.transfer.activeTransferId;
    return activeTransferId ? state.transfer.transfers[activeTransferId] : defaultTransfer;
  });
  const activeTransferId = useSelector<RootState, string | null>(state => state.transfer.activeTransferId);
  const files = useSelector<RootState, FileObject[]>(state => state.file);
  const app = useSelector<RootState, { APP_ERROR: any }>(state => state.app);
  const user = useSelector<RootState, any>(state => state.user?.user);
  const isPanelOpen = useSelector<RootState, boolean>(state => state.ui?.isPanelOpen ?? false);

  const [currentPhase, setCurrentPhase] = useState<UploadPhase>(UploadPhase.INITIAL);
  const [showFinishedCard, setShowFinishedCard] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [toastState, setToastState] = useState({
    isVisible: false,
    message: "",
    type: "success" as const
  });
  const transferInfosRef = useRef<TransferInfosModel>({
    transfer_name: "",
    transfer_message: "",
    transfer_password: "",
    transfer_confirm_password: "",
    recipients: [],
    auto_remove: false,
    auto_send: false,
    for_team: false,
  });
  const [transferInfos, setTransferInfos] = useState<TransferInfosModel>(transferInfosRef.current);

  // Key Fix: Create isMounted ref to track component mount state
  const isMounted = useRef(true);
  
  // References for stability
  const transferUpdateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  
  // Create a ref for files to avoid direct dependency
  const filesRef = useRef<FileObject[]>(files);
  useEffect(() => {
    filesRef.current = files;
  }, [files]);
  
  const speedHistoryRef = useRef<number[]>([]);
  const lastStatsRef = useRef<{
    percent: number;
    instantSpeed: number;
    averageSpeed: number;
    lastUpdate: number;
  }>({
    percent: 0,
    instantSpeed: 0,
    averageSpeed: 0,
    lastUpdate: Date.now()
  });

  // Key Fix: Enhanced cleanup effect
  useEffect(() => {
    return () => {
      isMounted.current = false;
      
      // Clear all timeouts
      if (transferUpdateTimeoutRef.current) {
        clearTimeout(transferUpdateTimeoutRef.current);
        transferUpdateTimeoutRef.current = null;
      }
      
      // Cancel all animation frames
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      
      // Cancel all active file uploads
      filesRef.current.forEach(file => {
        if (file.cancel) {
          file.cancel();
        }
      });
    };
  }, []);

  // Utilisation de useCallback pour updateTransferInfos
  const updateTransferInfos = useCallback((updater: (prev: TransferInfosModel) => TransferInfosModel) => {
    if (!isMounted.current) return;
    
    const newValue = updater(transferInfosRef.current);
    transferInfosRef.current = newValue;
    setTransferInfos(newValue);
  }, []);

  const wrappedSubmitCb = useCallback(async (data: any) => {
    if (!isMounted.current) return;
    
    const currentTransferInfos = transferInfosRef.current;
    return submitCb({
      ...data,
      transfer_name: currentTransferInfos.transfer_name || data.transfer_name
    });
  }, [submitCb]);

  // Key Fix: Check isMounted before updating state in updateTransferSize
  useEffect(() => {
    if (!isMounted.current) return;

    const updateTransferSize = () => {
      if (!isMounted.current) return;

      if (files.length > 0) {
        const newTotalSize = files.reduce((acc, file) => acc + file.meta.size, 0);
        if (newTotalSize !== transfer.totalSize && !transfer.isLocked) {
          const sizeInGB = newTotalSize / 1073741824;
          const kWh = sizeInGB * 0.175;
          const gCO2 = kWh * 100;
          const kms = gCO2 / 120;
          const roundedKms = Number(kms.toFixed(2));
          
          if (isMounted.current) {
            dispatch(transferAction.setInfos({ 
              ...transfer,
              totalSize: newTotalSize,
              consumption: {
                size: newTotalSize,
                kWh,
                gCO2,
                kms: roundedKms
              }
            }));
          }
        }
      } else if (transfer.totalSize > 0 && !transfer.isLocked && isMounted.current) {
        dispatch(transferAction.setInfos({
          ...transfer,
          totalSize: 0,
          consumption: {
            size: 0,
            kWh: 0,
            gCO2: 0,
            kms: 0
          }
        }));
      }
    };

    transferUpdateTimeoutRef.current = setTimeout(updateTransferSize, 100);

    return () => {
      if (transferUpdateTimeoutRef.current) {
        clearTimeout(transferUpdateTimeoutRef.current);
        transferUpdateTimeoutRef.current = null;
      }
    };
  }, [files, transfer.isLocked, transfer.totalSize, dispatch, transfer]);

  // Key Fix: Check isMounted before updating phase
  const [hasBeenFinished, setHasBeenFinished] = useState(false);
  const [isCompactView, setIsCompactView] = useState(window.innerWidth < 768);

  // Ajouter un écouteur pour les changements de taille de fenêtre
  useEffect(() => {
    if (!isMounted.current) return;
    
    const handleResize = () => {
      if (isMounted.current) {
        setIsCompactView(window.innerWidth < 768);
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;

    const handlePhaseChange = () => {
      if (!isMounted.current) return;
      
      let newPhase;

      // Logique pour déterminer la phase actuelle
      if (app.APP_ERROR) {
        newPhase = UploadPhase.ERROR;
      } else if (transfer.isUploadFinished) {
        newPhase = UploadPhase.FINISHED;
      } else if (transfer.isLocked && transfer.totalSize > 0) {
        newPhase = UploadPhase.PROGRESS;
      } else if (files.length > 0 && !transfer.isLocked) {
        newPhase = UploadPhase.CONFIGURATION;
      } else {
        newPhase = UploadPhase.INITIAL;
      }

      // Gestion de la carte de fin
      if (newPhase === UploadPhase.FINISHED && !hasBeenFinished && isMounted.current) {
        setHasBeenFinished(true);
        setShowFinishedCard(true);
      } else if (newPhase === UploadPhase.CONFIGURATION && hasBeenFinished) {
        // Réinitialisation uniquement lors d'un nouveau transfert
        setHasBeenFinished(false);
        setShowFinishedCard(false);
      }

      // Mise à jour de la phase si elle a changé
      if (newPhase !== currentPhase && isMounted.current) {

        setCurrentPhase(newPhase);
        dispatch(transferAction.setPhase(newPhase));
      }
    };

    // Store animation frame ID in ref for cleanup
    animationFrameRef.current = requestAnimationFrame(handlePhaseChange);
    
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
    };
  }, [
    app.APP_ERROR,
    transfer.isUploadFinished,
    transfer.isLocked,
    transfer.totalSize,
    files.length,
    currentPhase,
    hasBeenFinished,
    dispatch
  ]);

  const getFilePercent = useCallback((fileId: string): number => {
    if (!isMounted.current) return 0;
    
    const fileProgress = transfer.filesProgress[fileId];
    if (!fileProgress) return 0;
    
    // Calculer la progression totale basée sur les chunks
    let totalLoaded = 0;
    let totalSize = 0;
    
    Object.values(fileProgress.chunks).forEach(chunk => {
      totalLoaded += chunk.loaded;
      totalSize += chunk.total;
    });
    
    if (totalSize === 0) return 0;
    const percent = (totalLoaded * 100) / totalSize;
    return Math.min(Math.floor(percent), 100);
  }, [transfer.filesProgress, transfer]);

  const getChunkPercent = useCallback((fileId: string, chunkIndex: string): number => {
    if (!isMounted.current) return 0;
    
    const chunk = transfer.filesProgress[fileId]?.chunks[chunkIndex];
    if (!chunk) return 0;
    return Math.min(Math.round((chunk.loaded * 100) / chunk.total), 100);
  }, [transfer.filesProgress]);

  // Key Fix: Check isMounted before updating state in panel toggle
  const handlePanelToggle = useCallback(() => {
    if (isMounted.current) {
      dispatch(uiAction.setPanelState(!isPanelOpen));
    }
  }, [isPanelOpen, dispatch]);

  // Key Fix: Check isMounted before updating state in file delete
  const handleFileDelete = useCallback((index: number) => {
    if (!transfer.isLocked && files[index] && isMounted.current) {
      const file = files[index];
      const fileSize = file.meta.size;
      file.remove();
      if (transfer.totalSize > 0) {
        dispatch(transferAction.setInfos({
          ...transfer,
          totalSize: Math.max(0, transfer.totalSize - fileSize),
          consumption: {
            ...transfer.consumption,
            size: Math.max(0, transfer.totalSize - fileSize)
          }
        }));
      }
    }
  }, [transfer.isLocked, transfer.totalSize, files, dispatch]);

  // Key Fix: Reset state with isMounted check
  const resetState = useCallback(() => {
    if (!isMounted.current) return;
    
    speedHistoryRef.current = [];
    lastStatsRef.current = {
      percent: 0,
      instantSpeed: 0,
      averageSpeed: 0,
      lastUpdate: Date.now()
    };
    
    // Use the ref to access files
    filesRef.current.forEach(file => {
      if (file.cancel) {
        file.cancel();
      }
    });
    
    setTimeout(() => {
      if (isMounted.current) {

      }
    }, 0);
  }, []);

  // Key Fix: Check isMounted in handleCancelTransfer
  const handleCancelTransfer = useCallback(async () => {
    if (!isMounted.current) return;
    
    const confirmation = window.confirm(i18n._("confirm_upload_cancelation_label"));
    if (!confirmation) return;
    
    // Cancel each file via the ref
    filesRef.current.forEach(file => {
      if (file.cancel) {
        file.cancel();
      }
    });
    
    resetState();
    
    const initialState = {
      loaded: 0,
      isLocked: false,
      isUploadFinished: false,
      progress: {
        current: {
          timestamp: Date.now(),
          loaded: 0,
          instantSpeed: 0
        },
        last: {
          timestamp: Date.now(),
          loaded: 0
        }
      }
    };
    
    if (isMounted.current) {
      dispatch(transferAction.setInfos(initialState));
      dispatch(transferAction.setPhase(UploadPhase.INITIAL));
      await resetCb?.();
      window.location.reload();
    }
  }, [resetCb, resetState, dispatch]);

  // Key Fix: Check isMounted before updating state in link copy
  const handleLinkCopy = useCallback(() => {
    if (!isMounted.current) return;
    
    setToastState({
      isVisible: true,
      message: i18n._("upload_finished_copied_label"),
      type: "success"
    });
  }, []);

  const getTtlDate = useCallback((): string => {
    if (!isMounted.current) return '';
    
    const ttl = ttlTransfer[role] ?? ttlTransfer[0];
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + ttl);
    return convertDate(expirationDate, i18n.getLang());
  }, [role]);

  const getRemainingTime = useCallback((): string => {
    if (!transfer.loaded || !transfer.totalSize || !isMounted.current) return '';
    
    const speed = transfer.progress.current.avgSpeed || transfer.progress.current.instantSpeed || 0;
    const remainingSize = Math.max(0, transfer.totalSize - transfer.loaded);
    if (speed <= 0) return i18n._("calculating_remaining_time") || '';
    const remainingTimeInSeconds = Math.round(remainingSize / speed);
    const smoothedTime = Math.max(remainingTimeInSeconds, 1);
    return convertTime(smoothedTime, i18n._("remaining_label"));
  }, [transfer.progress, transfer.loaded, transfer.totalSize]);

  const getUploadSpeed = useCallback((): string => {
    if (!isMounted.current) return '0 B/s';
    
    const speed = transfer.progress.current.avgSpeed || transfer.progress.current.instantSpeed || 0;
    return `${convertSize(speed)}/s`;
  }, [transfer.progress]);

  // Key Fix: Check isMounted in handleResend
  const handleResend = useCallback(() => {
    if (!isMounted.current) return;
    
    // Réinitialiser l'état local une seule fois
    setShowFinishedCard(false);
    setHasBeenFinished(false);
    
    // Réinitialiser les références et statistiques
    resetState();
    
    // Annuler tous les téléchargements en cours
    filesRef.current.forEach(file => {
      if (file.cancel) {
        file.cancel();
      }
    });
    
    // Purger complètement les informations de transfert et les fichiers
    dispatch(fileAction.purgeFile());
    
    // Réinitialiser l'état du transfert actif avec les valeurs par défaut
    const initialTransferState = {
      ...defaultTransfer,
      isLocked: false,
      isUploadFinished: false,
      loaded: 0,
      totalSize: 0,
      filesProgress: {},
      progress: {
        current: {
          timestamp: Date.now(),
          loaded: 0,
          instantSpeed: 0,
          avgSpeed: 0
        },
        last: {
          timestamp: Date.now(),
          loaded: 0
        }
      }
    };
    
    // Réinitialiser les informations de transfert
    if (activeTransferId) {
      dispatch(transferAction.purgeInfos(activeTransferId));
      dispatch(transferAction.setInfos(initialTransferState));
    }
    
    // Réinitialiser explicitement la phase
    dispatch(transferAction.setPhase(UploadPhase.INITIAL));
    setCurrentPhase(UploadPhase.INITIAL);
    
    // Réinitialiser les informations de transfert
    setTransferInfos({
      transfer_name: "",
      transfer_message: "",
      transfer_password: "",
      transfer_confirm_password: "",
      recipients: [],
      auto_remove: false,
      auto_send: false,
      for_team: false,
    });
    
    // Appeler le callback de réinitialisation si disponible
    if (resetCb) {
      resetCb();
    }
    
    // Forcer une mise à jour de l'interface sans recharger la page
    setTimeout(() => {
      if (isMounted.current) {
        // Forcer la mise à jour de la phase une dernière fois
        setCurrentPhase(UploadPhase.INITIAL);
        dispatch(transferAction.setPhase(UploadPhase.INITIAL));
      }
    }, 200);
  }, [resetState, dispatch, activeTransferId, resetCb, currentPhase]);

  // Ajouter un effet pour logger les changements de showFinishedCard
  useEffect(() => {
    if (!isMounted.current) return;
    

  }, [showFinishedCard, currentPhase, transfer.isUploadFinished]);

  // HeaderProgress Component
  const HeaderProgress: React.FC = React.memo(() => (
    <div className="header-transfer w-full fv_layout_current_header justify-end flex items-center">
      <div className="w-full flex items-center justify-between">
        {files.length > 0 && !transfer.isUploadFinished && (
          <div className="flex items-center gap-2 fixed md:relative md:gap-4 top-[140px] right-4 md:top-auto md:right-auto z-30">
            <div
              onClick={handlePanelToggle}
              className="hover:cursor-pointer fv_layout_current_header_size_wrapper flex items-center"
            >
              <span className="leading-none inline-flex items-center gap-2 text-white text-xs md:text-md backdrop-blur-lg rounded-full px-2 py-1 md:px-3 md:py-2 border border-white/20 bg-gray-800/70 shadow-lg">
                {transfer.totalSize ? convertSize(transfer.totalSize) : '0 B'}
              </span>
            </div>
            <div className="fv_layout_current_header_arrow_wrapper">
              <ChevronRight size={16}
                onClick={handlePanelToggle}
                className={`icon-chevron-right bg-base-200/95 rounded-full border-0 shadow-lg hover:bg-base-300 pointer-events-auto hover:cursor-pointer transition-transform duration-300 ${isPanelOpen ? "rotate-90" : ""}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ));

  // Key Fix: Use stable setTransferInfos that respects isMounted
  const handleSetTransferInfos = useCallback<React.Dispatch<React.SetStateAction<TransferInfosModel>>>((value) => {
    if (!isMounted.current) return;
    
    const newState = value instanceof Function ? value(transferInfosRef.current) : value;
    transferInfosRef.current = newState;
    setTransferInfos(newState);
  }, []);

  const ConfigurationPhase: React.FC = React.memo(() => {
    return (
      <div className="space-y-6">
        <HeaderProgress />
        {isCompactView ? (
          // Version compacte avec UploaderInput au-dessus du formulaire
          <div className="flex flex-col w-full">
            <div className="w-full min-w-[360px] mb-4 flex justify-center configuration-phase">
              {input}
            </div>
            <div className="w-full max-w-[360px] mx-auto">
              <UploaderForm
                transfer={transfer}
                user={user}
                file={files}
                submitCb={submitCb}
                app={app}
                user_upload_size={{
                  per_transfer_limit: user?.permissions?.upload_size?.value || 2 * 1024 * 1024 * 1024,
                  total_transfer_limit: user?.limit_total || 2 * 1024 * 1024 * 1024,
                }}
                transferInfos={transferInfos}
                setTransferInfos={handleSetTransferInfos}
              />
            </div>
          </div>
        ) : (
          // Version standard avec UploaderInput à côté du formulaire
          <div className="flex flex-row w-full">
            <div className="w-1/5">
              {input}
            </div>
            <div className="w-4/5 ml-3 max-w-[360px]">
              <UploaderForm
                transfer={transfer}
                user={user}
                file={files}
                submitCb={submitCb}
                app={app}
                user_upload_size={{
                  per_transfer_limit: user?.permissions?.upload_size?.value || 2 * 1024 * 1024 * 1024,
                  total_transfer_limit: user?.limit_total || 2 * 1024 * 1024 * 1024,
                }}
                transferInfos={transferInfos}
                setTransferInfos={handleSetTransferInfos}
              />
            </div>
          </div>
        )}
      </div>
    );
  });

  const FinishedPhase: React.FC = React.memo(() => {
    // La consommation de base depuis le transfer (représente la consommation standard immédiate)
    const consumption = useMemo(() => ({
      gCO2: fixeVal(transfer.consumption.gCO2),
      kms: transfer.consumption.kms.toFixed(2)
    }), [transfer.consumption]);
    
    // La consommation standard est celle directement affichée dans l'interface
    const standardConsumption = useMemo(() => ({
      gCO2: consumption.gCO2,
      kms: consumption.kms
    }), [consumption]);
    
    // Constantes alignées avec le bilan carbone
    const PUE_STANDARD = 1.57;
    const PUE_FILEVERT = 1.2;
    const FRANCE_VS_EUROPE_CARBON = 0.057 / 0.4; // Ratio du mix électrique français vs européen
    const EMAIL_REPLACE_FACTOR = 1; // Un transfert remplace au moins un email avec pièce jointe
    
    // Constantes pour le calcul des économies (issues du CarbonEconomyCalculator)
    const CO2_SAVED_PER_GO_TRANSFERRED = 0.01405; // kg CO₂ / Go
    const CO2_SAVED_PER_GO_STORED = 0.08394; // kg CO₂ / Go
    const CO2_SAVED_PER_EMAIL = 0.0182; // kg CO₂ / email
    
    // Calcul des économies en CO2 en tenant compte de tous les facteurs
    const savings = useMemo(() => {
      // Taille du fichier en Go (minimum 0.01 Go pour éviter les valeurs trop petites)
      const fileSize = Math.max(0.01, transfer.totalSize / (1024 * 1024 * 1024));
      
      // La valeur standard calculée par la plateforme
      const standardValueG = parseFloat(standardConsumption.gCO2);
      
      // Calcul du facteur de stockage en fonction de la durée réelle
      const storageDurationDays = transfer.expiration_date 
        ? Math.min(15, Math.max(2, (new Date(transfer.expiration_date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)))
        : 2;
      
      // Facteur de stockage (différence entre stockage temporaire et stockage long terme)
      const storageTimeFactor = 1 - (storageDurationDays / 365);
      
      // Facteurs d'économie pour chaque composante
      const pueEfficiencyFactor = 1 - (PUE_FILEVERT / PUE_STANDARD);
      const carbonMixFactor = 1 - FRANCE_VS_EUROPE_CARBON;
      
      // Calcul des économies par type, en grammes de CO₂
      const transferSavingsG = fileSize * CO2_SAVED_PER_GO_TRANSFERRED * 1000;
      const storageSavingsG = fileSize * CO2_SAVED_PER_GO_STORED * storageTimeFactor * 1000;
      const emailSavingsG = EMAIL_REPLACE_FACTOR * CO2_SAVED_PER_EMAIL * 1000;
      
      // Économie liée à l'optimisation du PUE et du mix électrique
      const infrastructureSavingsG = standardValueG * pueEfficiencyFactor * carbonMixFactor;
      
      // Somme des économies, avec un cap pour éviter de dépasser la valeur standard
      const totalSavingsG = Math.min(
        standardValueG * 0.95, // Maximum 95% d'économie pour éviter des incohérences
        transferSavingsG + storageSavingsG + emailSavingsG + infrastructureSavingsG
      );
      
      // Arrondissement pour l'affichage
      const roundedSavingsG = Math.round(totalSavingsG);
      
      // Si la valeur est trop petite, renvoyer une chaîne spéciale
      if (roundedSavingsG < 0.1) {
        return {
          kgCO2: "< 0.1",
          gCO2: "< 0,1",
          details: {
            transfers: "< 0,1",
            storage: "< 0,1",
            emails: "< 0,1",
            infrastructure: "< 0,1"
          }
        };
      }
      
      return {
        kgCO2: (roundedSavingsG / 1000).toFixed(2),
        gCO2: roundedSavingsG.toString(),
        details: {
          transfers: transferSavingsG.toFixed(1),
          storage: storageSavingsG.toFixed(1),
          emails: emailSavingsG.toFixed(1),
          infrastructure: infrastructureSavingsG.toFixed(1)
        }
      };
    }, [standardConsumption.gCO2, transfer.totalSize, transfer.expiration_date]);
    
    // La consommation FileVert est calculée comme la différence entre standard et économies
    const fileVertConsumption = useMemo(() => {
      const standardValue = parseFloat(standardConsumption.gCO2);
      
      // Vérifier si savings.gCO2 est une chaîne spéciale (< 0,1)
      if (savings.gCO2.startsWith("<")) {
        // Si l'économie est inférieure à 0,1g, la consommation FileVert est presque identique à la standard
        return { gCO2: Math.round(standardValue).toString() };
      }
      
      const savedValue = parseFloat(savings.gCO2);
      const fileVertValue = Math.max(0, standardValue - savedValue);
      
      // Si la valeur est très petite, on affiche "< 0,1"
      if (fileVertValue < 0.1) {
        return { gCO2: "< 0,1" };
      }
      
      return { gCO2: Math.round(fileVertValue).toString() };
    }, [standardConsumption.gCO2, savings.gCO2]);
  
    // Format expiration date nicely
    const formatExpirationDate = useCallback(() => {
      if (!transfer.expiration_date) {
        return getTtlDate();
      }
  
      const date = new Date(transfer.expiration_date);
      const isLangFr = i18n.getLang() === 'fr' || i18n.getLang() === 'fr_FR';
  
      if (isLangFr) {
        // Format pour le français avec le nom du mois
        const frOptions: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        };
  
        let formattedDate = new Intl.DateTimeFormat('fr-FR', frOptions)
          .format(date)
          .replace(',', ' à')
          .replace(/:/g, 'h');
  
        // Enlever le zéro devant l'heure si présent
        formattedDate = formattedDate.replace(/0(\d)h/, '$1h');
        
        return formattedDate;
      }
  
      // Format anglais
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      };
  
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }, [transfer.expiration_date]);
  
    // Vérifier que nous avons un transfert valide à afficher
    if (!transfer.isUploadFinished || !transfer.link) {
      return null;
    }
  
    return (
      <div className="w-[350px] py-4 px-2 bg-base-200 rounded-xl overflow-hidden text-center shadow-lg">
        <div className="p-3">
          <div className="flex justify-center mb-2">
            <CheckCircle className="w-10 h-10 text-emerald-500" />
          </div>
          <h3 className="text-xl font-bold">
            {i18n._("upload_finished_title")}
          </h3>
        </div>
        
        {/* Expiration date */}
        <div className="mb-3 flex justify-center">
          <div className="bg-base-100/50 rounded-lg py-1 px-3 inline-flex items-center gap-1">
            <Clock className="w-3.5 h-3.5 text-primary" />
            <span className="text-xs font-medium">
              {formatExpirationDate()}
            </span>
          </div>
        </div>
        
        <div className="px-3 py-2">
          <div className="text-sm mb-2">
            {i18n._("transfer_sharing_indication")}
          </div>
          <ActionInputComponent
            customClass="w-full"
            mailTo={{
              subject: i18n._("link_copy_subject", [i18n._("fv_title"), config.ihmUrl]),
              body: i18n._("link_copy_message", [
                i18n._("fv_title"),
                convertDate(new Date(transfer.expiration_date || ''), i18n.getLang(), true),
                parseCurly(transfer.link),
              ]).split("<br />").join("\n"),
              enabled: true,
            }}
            value={parseCurly(transfer.link)}
            onCopy={handleLinkCopy}
          />
        </div>
  
        {/* Grid for expiration and consumption */}
        <div className="px-3 py-2">
          <div className="bg-base-100 rounded-lg p-3">
            <div className="flex items-center justify-center gap-1.5 mb-2">
              <Leaf className="w-4 h-4 text-emerald-500" />
              <span className="text-sm font-medium">
                {i18n._("ecological_impact")}
              </span>
            </div>
            
            {/* CO2 savings display */}
            <div className="mb-2 text-center">
              <div className="font-bold">
                <span className="text-2xl text-emerald-500">{savings.gCO2}</span>
                <span className="text-sm text-emerald-500/70"> gCO₂</span>
              </div>
              <div className="mt-2 text-sm text-base-content/70 flex flex-col items-center">
  <span>
  {i18n._("standards")} <strong>{standardConsumption.gCO2}</strong> {i18n._("co2_label")}
  </span>
  <span className="text-xs opacity-75">
  {i18n._("standards_base")}
  </span>
</div>
            </div>
            
          </div>
        </div>
  
        <div className="p-3 mt-1 border-t border-base-300/30">
          <button 
            onClick={handleResend}
            className="btn btn-primary btn-sm w-full"
          >
            {i18n._("upload_resend_label")}
          </button>
        </div>
      </div>
    );
  });

  const ErrorComponent: React.FC = React.memo(() => (
    <div className="card bg-base-200 shadow-xl max-w-xl mx-auto mt-8">
      <div className="card-header bg-error/10 p-4 text-center">
        <div className="flex justify-center items-center gap-2">
          <XCircle size={32} className="text-error" />
          <h2 className="card-title text-error">{i18n._("upload_error_title") || ""}</h2>
        </div>
      </div>
      <div className="card-body text-center py-8">
        <p
          className="text-base-content"
          dangerouslySetInnerHTML={{ __html: String(i18n._("upload_error_text")) }}
        />
      </div>
      <div className="card-actions justify-center p-4 border-t border-base-300">
        <button onClick={handleResend} className="btn btn-ghost">
          {i18n._("upload_resend_label")}
        </button>
      </div>
    </div>
  ));

  const FileDetailsPanel: React.FC = React.memo(() => (
    <div 
      className={`fixed right-0 top-0 rounded-box bottom-0 mt-20 mb-20 w-96 bg-base-300 transform 
      transition-all duration-300 ease-out shadow-xl z-40
      ${isPanelOpen ? 'translate-x-0' : 'translate-x-full'}`}
    >
      <div className="flex flex-col h-full border-l border-gray-700">
        <div className="p-4 border-b border-base-300/20">
          <div className="flex justify-between items-center">
            <h3 className="font-medium">{files.length} fichier{files.length > 1 ? 's' : ''}</h3>
            <button 
              onClick={handlePanelToggle}
              className="btn btn-ghost btn-sm btn-circle"
              aria-label="Close file details panel"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          {files.length === 0 ? (
            <div className="flex justify-center items-center h-full p-4">
              <p>{i18n._("no_files_uploaded_label") || ""}</p>
            </div>
          ) : (
            files.map((fileObj, index) => (
              <div
                key={fileObj.meta.id}
                className="group relative border-b border-base-300/20 hover:bg-base-300/20 transition-all duration-200"
              >
                <div className="relative p-4">
                  <div className="flex items-center gap-3">
                    {getFileIcon(fileObj.meta.name)}
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-2">
                        <h4 className="font-medium truncate">
                          {decodeFileName(fileObj.meta.name)}
                        </h4>
                      </div>
                      <div className="flex items-center gap-3 text-sm text-base-content/70">
                        <span>{convertSize(fileObj.meta.size)}</span>
                        <FileUploadProgress 
                          progress={getFilePercent(fileObj.meta.id)}
                          isVisible={true}
                          transferFinished={transfer.isUploadFinished}
                          chunks={transfer.filesProgress[fileObj.meta.id]?.chunks}
                          onProgressUpdate={(progress) => {
                            // Only update if component is still mounted
                            if (isMounted.current) {

                            }
                          }}
                        />
                      </div>
                    </div>
                    {!transfer.isLocked && (
                      <button 
                        onClick={() => handleFileDelete(index)}
                        className="btn btn-ghost btn-sm btn-circle opacity-0 group-hover:opacity-100 transition-opacity"
                        aria-label={`Delete file ${decodeFileName(fileObj.meta.name)}`}
                      >
                        <Trash2 className="w-4 h-4 text-error" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="absolute -left-12 top-1/2 -translate-y-1/2 z-40">
        <button
          onClick={handlePanelToggle}
          className="btn btn-circle btn-sm bg-white backdrop-blur-sm custom-pulse text-black te border-0 shadow-lg hover:bg-base-300 hover:text-emerald-500 pointer-events-auto"
          aria-label="Toggle file details panel"
        >
          <ChevronRight
            size={10}
            className={`w-4 h-4 transition-transform duration-300 ${isPanelOpen ? 'rotate-180' : ''}`}
          />
        </button>
      </div>
    </div>
  ));

  const ProgressPanel: React.FC<ProgressPanelProps> = ({ onMinimize }) => (
    <div className="card bg-base-200 shadow-xl max-w-xl mx-auto rounder-lg w-[300px]">
      <div className="card-body text-center">
        <div className="w-full max-w-md mx-auto">
          <div className="flex justify-end mb-2">
            {/* Bouton de minimisation commenté
            <button
              onClick={onMinimize}
              className="btn btn-ghost btn-sm"
              title={i18n._("minimize_transfer")}
            >
              <Minimize2 className="w-4 h-4 mr-2" />
              {i18n._("minimize")}
            </button>
            */}
          </div>
          <Progress onCancel={handleCancelTransfer} />
        </div>
      </div>
    </div>
  );

  // Key Fix: Check isMounted in renderContent
  const renderContent = useCallback((): JSX.Element => {
    if (!isMounted.current) return <></>;
    
    if (currentPhase === UploadPhase.FINISHED) {
      return <FinishedPhase />;
    }
    
    switch (currentPhase) {
      case UploadPhase.ERROR:
        return <ErrorComponent />;
      case UploadPhase.INITIAL:
        return (
          <div className="w-full max-w-md mx-auto">
            {input}
          </div>
        );
      case UploadPhase.CONFIGURATION:
        return <ConfigurationPhase />;
      default:
        return <></>;
    }
  }, [currentPhase, input, ConfigurationPhase, ErrorComponent, FinishedPhase]);

  // Key Fix: Check isMounted in renderProgress
  const renderProgress = useCallback((): JSX.Element => {
    if (!isMounted.current) return <></>;
    
    if ((transfer.isLocked || transfer.purging) && !isMinimized) {
      return <ProgressPanel onMinimize={() => setIsMinimized(true)} />;
    }
    
    if (isMinimized) {
      return (
        <div className="fixed bottom-4 right-4 z-50">
          <button
            onClick={() => setIsMinimized(false)}
            className="btn btn-primary btn-sm"
          >
            {i18n._("show_progress")}
          </button>
        </div>
      );
    }
    
    return <></>;
  }, [transfer.isLocked, transfer.purging, isMinimized, setIsMinimized]);

  const [showHelp, setShowHelp] = useState(false);
  
  // Key Fix: Check isMounted in useEffect for showHelp
  useEffect(() => {
    if (!isMounted.current) return;
    
    if (currentPhase !== UploadPhase.INITIAL) {
      setShowHelp(false);
    }
  }, [currentPhase]);
return (
  <div className="container mx-auto p-4 relative min-h-screen flex flex-col items-center justify-center">
    {/* Ajouter les actions structurées pour les IA */}
    <SchemaActions baseUrl={config.ihmUrl} />
    <Toast
      type={toastState.type}
      message={toastState.message}
      isVisible={toastState.isVisible}
      onClose={() => {
        if (isMounted.current) {
          setToastState(prev => ({ ...prev, isVisible: false }));
        }
      }}
    />
      
    <div className="relative w-full z-50 flex items-center justify-center">
        {showFinishedCard ? (
          <FinishedPhase />
        ) : transfer.isLocked || transfer.purging ? (
          isMinimized ? (
            <div className="fixed bottom-4 right-4 z-50">
              <MinimizedTransfer
                transfer={{
                  id: activeTransferId || '',
                  ...transfer
                }}
                onRestore={() => {
                  if (isMounted.current) {
                    setIsMinimized(false);
                  }
                }}
              />
            </div>
          ) : (
            <ProgressPanel onMinimize={() => {
              if (isMounted.current) {
                setIsMinimized(true);
              }
            }} />
          )
        ) : (
          renderContent()
        )}
      </div>
      
      {files.length > 0 && (
        <div className="z-50">
          <FileDetailsPanel />
        </div>
      )}
    </div>
  );
};

export default LayoutComponent;