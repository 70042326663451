import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import SEOHelmet from './SEOHelmet';
import { i18n } from '../../utils';

interface SEORouteProps extends RouteProps {
  title?: string;
  description?: string;
  path: string;
}

// Composant enfant qui gère la logique de langue et le rendu du composant principal
interface RouteContentProps {
  Component: React.ComponentType<any>;
  title?: string;
  description?: string;
  path: string;
  location: any;
  match: any;
  history: any;
}

const RouteContent: React.FC<RouteContentProps> = ({
  Component,
  title,
  description,
  path,
  location,
  match,
  history
}) => {
  const [currentLang, setCurrentLang] = useState<string>('fr');
  
  // Détection de la langue à partir des paramètres d'URL
  const urlParams = new URLSearchParams(location.search);
  const detectedLang = urlParams.get('hl') || 'fr';
  
  // Convertir la langue au format attendu par i18n (fr -> fr_FR)
  const getNormalizedLang = (langCode: string) => {
    switch (langCode) {
      case 'fr': return 'fr_FR';
      case 'en': return 'en_EN';
      case 'es': return 'es_ES';
      case 'de': return 'de_DE';
      case 'nl': return 'nl_NL';
      case 'pt': return 'pt_PT';
      default: return 'fr_FR';
    }
  };
  
  // Effet pour mettre à jour la langue actuelle si elle a changé
  useEffect(() => {
    if (detectedLang !== currentLang) {
      setCurrentLang(detectedLang);
    }
  }, [detectedLang, location.search, currentLang]);
  
  // Effet pour forcer i18n à utiliser la langue détectée
  useEffect(() => {
    if (currentLang && i18n.isReady) {
      const normalizedLang = getNormalizedLang(currentLang);
      console.log(`RouteWithSEO: Changement de langue vers ${normalizedLang}`);
      
      // Vérifier si la langue est disponible dans i18n
      if (i18n.strings && Object.keys(i18n.strings).includes(normalizedLang)) {
        // Forcer i18n à utiliser la langue détectée
        if (i18n.lang !== normalizedLang) {
          i18n.changeLang(normalizedLang);
        }
      } else {
        console.warn(`RouteWithSEO: Langue ${normalizedLang} non disponible, utilisation de la langue par défaut`);
      }
    }
  }, [currentLang, i18n.isReady]);
  
  return (
    <>
      <SEOHelmet
        title={title}
        description={description}
        path={path}
        lang={detectedLang}
      />
      <Component location={location} match={match} history={history} />
    </>
  );
};

const RouteWithSEO: React.FC<SEORouteProps> = ({
  title,
  description,
  component: Component,
  path,
  ...rest
}) => {
  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={props => (
        <RouteContent
          Component={Component}
          title={title}
          description={description}
          path={path}
          {...props}
        />
      )}
    />
  );
};

export default RouteWithSEO;