// src/Store/Reducers/uiReducer.ts
export const SET_PANEL_STATE = 'SET_PANEL_STATE';

export const setPanelState = (value: boolean) => ({
  type: SET_PANEL_STATE,
  value
});

// Actions groupées
export const uiAction = {
  setPanelState
};

interface UIState {
  isPanelOpen: boolean;
}

const initialState: UIState = {
  isPanelOpen: false
};

const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PANEL_STATE:
      return {
        ...state,
        isPanelOpen: action.value
      };
    default:
      return state;
  }
};

export default uiReducer;