import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOHelmetProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  path?: string;
  lang?: string;
  keywords?: string;
}

const SEOHelmet: React.FC<SEOHelmetProps> = ({
  title = "FileVert - Le transfert de fichiers, vertueux et responsable",
  description = "Solution professionnelle de transfert de fichiers volumineux jusqu'à 200 Go. Économisez 76% de stockage et réduisez votre empreinte carbone. 100% français, sécurisé et éco-responsable.",
  canonicalUrl = "https://filevert.fr",
  path = "",
  lang = "fr"
}) => {
  // Construire l'URL complète
  const fullUrl = `${canonicalUrl}${path}`;
  
  // Construire l'URL canonique (sans paramètre hl=fr pour éviter le contenu dupliqué)
  const canonicalFullUrl = lang === 'fr' && path.includes('hl=fr')
    ? fullUrl.replace(/(\?|&)hl=fr(&|$)/, '$1').replace(/\?$/, '')
    : fullUrl;
  const languages = ["fr", "en", "es", "de", "nl", "pt"];
  
  // Traductions des titres selon la langue et la route
  const getTitleByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Titres pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "FileVert - Eco-friendly file transfer";
        case "es":
          return "FileVert - Transferencia de archivos ecológica";
        case "de":
          return "FileVert - Umweltfreundliche Dateiübertragung";
        case "nl":
          return "FileVert - Milieuvriendelijke bestandsoverdracht";
        case "pt":
          return "FileVert - Transferência de arquivos ecológica";
        default:
          return "FileVert - Le transfert de fichiers, vertueux et responsable";
      }
    }
    
    // Titres pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "About FileVert - Eco-friendly file transfer";
        case "es":
          return "Acerca de FileVert - Transferencia de archivos ecológica";
        case "de":
          return "Über FileVert - Umweltfreundliche Dateiübertragung";
        case "nl":
          return "Over FileVert - Milieuvriendelijke bestandsoverdracht";
        case "pt":
          return "Sobre FileVert - Transferência de arquivos ecológica";
        default:
          return "À propos de FileVert - Transfert de fichiers vertueux";
      }
    }
    
    // Titres pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "FileVert Offers - Professional transfer solutions";
        case "es":
          return "Ofertas FileVert - Soluciones profesionales de transferencia";
        case "de":
          return "FileVert Angebote - Professionelle Übertragungslösungen";
        case "nl":
          return "FileVert Aanbiedingen - Professionele overdrachtsoplossingen";
        case "pt":
          return "Ofertas FileVert - Soluções profissionais de transferência";
        default:
          return "Offres FileVert - Solutions professionnelles de transfert";
      }
    }
    
    // Titres pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "Contact - FileVert";
        case "es":
          return "Contacto - FileVert";
        case "de":
          return "Kontakt - FileVert";
        case "nl":
          return "Contact - FileVert";
        case "pt":
          return "Contato - FileVert";
        default:
          return "Contact - FileVert";
      }
    }
    
    // Titres pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Chrome Extension - FileVert simplified transfer";
        case "es":
          return "Extensión Chrome - Transferencia simplificada FileVert";
        case "de":
          return "Chrome-Erweiterung - Vereinfachte FileVert-Übertragung";
        case "nl":
          return "Chrome-extensie - Vereenvoudigde FileVert-overdracht";
        case "pt":
          return "Extensão Chrome - Transferência simplificada FileVert";
        default:
          return "Extension Chrome FileVert - Transfert simplifié";
      }
    }
    
    // Titres pour la page Profil
    if (currentPath === '/profil') {
      switch(currentLang) {
        case "en":
          return "My FileVert Account - Transfer Management";
        case "es":
          return "Mi Cuenta FileVert - Gestión de Transferencias";
        case "de":
          return "Mein FileVert-Konto - Übertragungsverwaltung";
        case "nl":
          return "Mijn FileVert-Account - Overdrachtsbeheer";
        case "pt":
          return "Minha Conta FileVert - Gerenciamento de Transferências";
        default:
          return "Mon Espace FileVert - Gestion des transferts";
      }
    }
    
    // Titres pour la page Mentions légales
    if (currentPath === '/legal-terms') {
      switch(currentLang) {
        case "en":
          return "Legal Notice - FileVert";
        case "es":
          return "Aviso Legal - FileVert";
        case "de":
          return "Impressum - FileVert";
        case "nl":
          return "Juridische Kennisgeving - FileVert";
        case "pt":
          return "Aviso Legal - FileVert";
        default:
          return "Mentions légales - FileVert";
      }
    }
    
    // Titres pour la page CGU
    if (currentPath === '/cgu') {
      switch(currentLang) {
        case "en":
          return "Terms of Service - FileVert";
        case "es":
          return "Términos de Servicio - FileVert";
        case "de":
          return "Nutzungsbedingungen - FileVert";
        case "nl":
          return "Servicevoorwaarden - FileVert";
        case "pt":
          return "Termos de Serviço - FileVert";
        default:
          return "Conditions Générales d'Utilisation - FileVert";
      }
    }
    
    // Titres pour la page Politique de confidentialité
    if (currentPath === '/privacy') {
      switch(currentLang) {
        case "en":
          return "Privacy Policy - FileVert";
        case "es":
          return "Política de Privacidad - FileVert";
        case "de":
          return "Datenschutzrichtlinie - FileVert";
        case "nl":
          return "Privacybeleid - FileVert";
        case "pt":
          return "Política de Privacidade - FileVert";
        default:
          return "Politique de confidentialité - FileVert";
      }
    }
    
    // Titres pour la page Politique de confidentialité de l'extension Chrome
    if (currentPath === '/privacy-chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Chrome Extension Privacy - FileVert";
        case "es":
          return "Privacidad de la Extensión Chrome - FileVert";
        case "de":
          return "Chrome-Erweiterung Datenschutz - FileVert";
        case "nl":
          return "Chrome-extensie Privacy - FileVert";
        case "pt":
          return "Privacidade da Extensão Chrome - FileVert";
        default:
          return "Confidentialité Extension Chrome - FileVert";
      }
    }
    
    // Titres pour la page 404
    if (currentPath === '*') {
      switch(currentLang) {
        case "en":
          return "404 - Page Not Found | FileVert";
        case "es":
          return "404 - Página No Encontrada | FileVert";
        case "de":
          return "404 - Seite Nicht Gefunden | FileVert";
        case "nl":
          return "404 - Pagina Niet Gevonden | FileVert";
        case "pt":
          return "404 - Página Não Encontrada | FileVert";
        default:
          return "404 - Page non trouvée | FileVert";
      }
    }
    
    // Titre par défaut
    switch(currentLang) {
      case "en":
        return "FileVert - Eco-friendly file transfer solution";
      case "es":
        return "FileVert - Transferencia de archivos ecológica";
      case "de":
        return "FileVert - Umweltfreundliche Dateiübertragung";
      case "nl":
        return "FileVert - Milieuvriendelijke bestandsoverdracht";
      case "pt":
        return "FileVert - Transferência de arquivos ecológica";
      default:
        return "FileVert - Le transfert de fichiers, vertueux et responsable";
    }
  };
  
  // Traductions des descriptions selon la langue et la route
  const getDescriptionByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Descriptions pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "Transfer your large files up to 200 GB securely and eco-responsibly. French solution hosted on 100% renewable energy servers.";
        case "es":
          return "Transfiera sus archivos grandes hasta 200 GB de forma segura y ecológica. Solución francesa alojada en servidores 100% energía renovable.";
        case "de":
          return "Übertragen Sie Ihre großen Dateien bis zu 200 GB sicher und umweltfreundlich. Französische Lösung, gehostet auf Servern mit 100% erneuerbarer Energie.";
        case "nl":
          return "Draag uw grote bestanden tot 200 GB veilig en milieuvriendelijk over. Franse oplossing gehost op servers met 100% hernieuwbare energie.";
        case "pt":
          return "Transfira seus arquivos grandes até 200 GB de forma segura e ecológica. Solução francesa hospedada em servidores 100% energia renovável.";
        default:
          return "Transférez vos fichiers volumineux jusqu'à 200 Go de manière sécurisée et éco-responsable. Solution française hébergée sur des serveurs 100% énergies renouvelables.";
      }
    }
    
    // Descriptions pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "Discover how FileVert reduces the environmental impact of file transfers. 100% renewable energy infrastructure, optimized storage and eco-responsible approach.";
        case "es":
          return "Descubra cómo FileVert reduce el impacto ambiental de la transferencia de archivos. Infraestructura 100% energía renovable, almacenamiento optimizado y enfoque eco-responsable.";
        case "de":
          return "Entdecken Sie, wie FileVert die Umweltauswirkungen von Dateiübertragungen reduziert. 100% erneuerbare Energieinfrastruktur, optimierter Speicher und umweltbewusster Ansatz.";
        case "nl":
          return "Ontdek hoe FileVert de milieu-impact van bestandsoverdrachten vermindert. 100% hernieuwbare energie-infrastructuur, geoptimaliseerde opslag en milieuvriendelijke aanpak.";
        case "pt":
          return "Descubra como o FileVert reduz o impacto ambiental da transferência de arquivos. Infraestrutura 100% energia renovável, armazenamento otimizado e abordagem eco-responsável.";
        default:
          return "Découvrez comment FileVert réduit l'impact environnemental du transfert de fichiers. Infrastructure 100% énergies renouvelables, stockage optimisé et approche éco-responsable.";
      }
    }
    
    // Descriptions pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "File transfer solutions for professionals and businesses. Maximum security, GDPR compliance and reduced environmental impact.";
        case "es":
          return "Soluciones de transferencia de archivos para profesionales y empresas. Máxima seguridad, cumplimiento del RGPD e impacto ambiental reducido.";
        case "de":
          return "Dateiübertragungslösungen für Fachleute und Unternehmen. Maximale Sicherheit, DSGVO-Konformität und reduzierte Umweltauswirkungen.";
        case "nl":
          return "Bestandsoverdrachtsoplossingen voor professionals en bedrijven. Maximale beveiliging, AVG-naleving en verminderde milieu-impact.";
        case "pt":
          return "Soluções de transferência de arquivos para profissionais e empresas. Segurança máxima, conformidade com o RGPD e impacto ambiental reduzido.";
        default:
          return "Solutions de transfert de fichiers pour professionnels et entreprises. Sécurité maximale, conformité RGPD et impact environnemental réduit.";
      }
    }
    
    // Descriptions pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "Contact the FileVert team for any questions about our eco-responsible file transfer services. Responsive and professional support.";
        case "es":
          return "Contacte al equipo de FileVert para cualquier pregunta sobre nuestros servicios de transferencia de archivos eco-responsables. Soporte receptivo y profesional.";
        case "de":
          return "Kontaktieren Sie das FileVert-Team für Fragen zu unseren umweltbewussten Dateiübertragungsdiensten. Reaktionsschneller und professioneller Support.";
        case "nl":
          return "Neem contact op met het FileVert-team voor vragen over onze milieuvriendelijke bestandsoverdrachtsservices. Responsieve en professionele ondersteuning.";
        case "pt":
          return "Entre em contato com a equipe FileVert para qualquer dúvida sobre nossos serviços de transferência de arquivos eco-responsáveis. Suporte responsivo e profissional.";
        default:
          return "Contactez l'équipe FileVert pour toute question sur nos services de transfert de fichiers éco-responsables. Support réactif et professionnel.";
      }
    }
    
    // Descriptions pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Optimize your transfers with the FileVert Chrome extension. Send your large files directly from your browser.";
        case "es":
          return "Optimice sus transferencias con la extensión Chrome de FileVert. Envíe sus archivos grandes directamente desde su navegador.";
        case "de":
          return "Optimieren Sie Ihre Übertragungen mit der FileVert Chrome-Erweiterung. Senden Sie Ihre großen Dateien direkt aus Ihrem Browser.";
        case "nl":
          return "Optimaliseer uw overdrachten met de FileVert Chrome-extensie. Verzend uw grote bestanden rechtstreeks vanuit uw browser.";
        case "pt":
          return "Otimize suas transferências com a extensão Chrome do FileVert. Envie seus arquivos grandes diretamente do seu navegador.";
        default:
          return "Optimisez vos transferts avec l'extension Chrome FileVert. Envoyez vos fichiers volumineux directement depuis votre navigateur.";
      }
    }
    
    // Descriptions pour la page Profil
    if (currentPath === '/profil') {
      switch(currentLang) {
        case "en":
          return "Manage your file transfers, track your environmental impact statistics and customize your FileVert settings.";
        case "es":
          return "Gestione sus transferencias de archivos, siga sus estadísticas de impacto ambiental y personalice su configuración de FileVert.";
        case "de":
          return "Verwalten Sie Ihre Dateiübertragungen, verfolgen Sie Ihre Umweltauswirkungsstatistiken und passen Sie Ihre FileVert-Einstellungen an.";
        case "nl":
          return "Beheer uw bestandsoverdrachten, volg uw statistieken over milieu-impact en pas uw FileVert-instellingen aan.";
        case "pt":
          return "Gerencie suas transferências de arquivos, acompanhe suas estatísticas de impacto ambiental e personalize suas configurações do FileVert.";
        default:
          return "Gérez vos transferts de fichiers, suivez vos statistiques d'impact environnemental et personnalisez vos paramètres FileVert.";
      }
    }
    
    // Descriptions pour la page Mentions légales
    if (currentPath === '/legal-terms') {
      switch(currentLang) {
        case "en":
          return "Consult our legal notice. FileVert is committed to transparency, GDPR compliance and the protection of personal data.";
        case "es":
          return "Consulte nuestro aviso legal. FileVert está comprometido con la transparencia, el cumplimiento del RGPD y la protección de datos personales.";
        case "de":
          return "Lesen Sie unser Impressum. FileVert setzt sich für Transparenz, DSGVO-Konformität und den Schutz personenbezogener Daten ein.";
        case "nl":
          return "Raadpleeg onze juridische kennisgeving. FileVert zet zich in voor transparantie, AVG-naleving en de bescherming van persoonsgegevens.";
        case "pt":
          return "Consulte nosso aviso legal. FileVert está comprometido com a transparência, conformidade com o RGPD e proteção de dados pessoais.";
        default:
          return "Consultez nos mentions légales. FileVert s'engage pour la transparence, la conformité RGPD et la protection des données personnelles.";
      }
    }
    
    // Descriptions pour la page CGU
    if (currentPath === '/cgu') {
      switch(currentLang) {
        case "en":
          return "Terms of service for the FileVert service. Eco-responsible, secure and compliant file transfer.";
        case "es":
          return "Términos de servicio para el servicio FileVert. Transferencia de archivos eco-responsable, segura y conforme.";
        case "de":
          return "Nutzungsbedingungen für den FileVert-Dienst. Umweltbewusste, sichere und konforme Dateiübertragung.";
        case "nl":
          return "Servicevoorwaarden voor de FileVert-service. Milieuvriendelijke, veilige en conforme bestandsoverdracht.";
        case "pt":
          return "Termos de serviço para o serviço FileVert. Transferência de arquivos eco-responsável, segura e conforme.";
        default:
          return "Conditions générales d'utilisation du service FileVert. Transfert de fichiers éco-responsable, sécurisé et conforme aux normes.";
      }
    }
    
    // Descriptions pour la page Politique de confidentialité
    if (currentPath === '/privacy') {
      switch(currentLang) {
        case "en":
          return "Our privacy policy details our commitment to protecting your data and respecting your privacy.";
        case "es":
          return "Nuestra política de privacidad detalla nuestro compromiso con la protección de sus datos y el respeto de su privacidad.";
        case "de":
          return "Unsere Datenschutzrichtlinie beschreibt unser Engagement für den Schutz Ihrer Daten und die Achtung Ihrer Privatsphäre.";
        case "nl":
          return "Ons privacybeleid beschrijft onze toewijding aan het beschermen van uw gegevens en het respecteren van uw privacy.";
        case "pt":
          return "Nossa política de privacidade detalha nosso compromisso com a proteção de seus dados e o respeito à sua privacidade.";
        default:
          return "Notre politique de confidentialité détaille notre engagement pour la protection de vos données et le respect de votre vie privée.";
      }
    }
    
    // Descriptions pour la page Politique de confidentialité de l'extension Chrome
    if (currentPath === '/privacy-chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Privacy policy for the FileVert Chrome extension. Data security and protection guaranteed.";
        case "es":
          return "Política de privacidad para la extensión Chrome de FileVert. Seguridad y protección de datos garantizadas.";
        case "de":
          return "Datenschutzrichtlinie für die FileVert Chrome-Erweiterung. Datensicherheit und -schutz garantiert.";
        case "nl":
          return "Privacybeleid voor de FileVert Chrome-extensie. Gegevensbeveiliging en -bescherming gegarandeerd.";
        case "pt":
          return "Política de privacidade para a extensão Chrome do FileVert. Segurança e proteção de dados garantidas.";
        default:
          return "Politique de confidentialité de l'extension Chrome FileVert. Sécurité et protection des données garanties.";
      }
    }
    
    // Descriptions pour la page 404
    if (currentPath === '*') {
      switch(currentLang) {
        case "en":
          return "The page you are looking for does not exist. Return to the home page to transfer your files in an eco-responsible way.";
        case "es":
          return "La página que busca no existe. Vuelva a la página de inicio para transferir sus archivos de manera eco-responsable.";
        case "de":
          return "Die gesuchte Seite existiert nicht. Kehren Sie zur Startseite zurück, um Ihre Dateien umweltbewusst zu übertragen.";
        case "nl":
          return "De pagina die u zoekt bestaat niet. Ga terug naar de startpagina om uw bestanden op een milieuvriendelijke manier over te dragen.";
        case "pt":
          return "A página que você está procurando não existe. Volte à página inicial para transferir seus arquivos de maneira eco-responsável.";
        default:
          return "La page que vous recherchez n'existe pas. Retournez à l'accueil pour transférer vos fichiers de manière éco-responsable.";
      }
    }
    
    // Description par défaut
    switch(currentLang) {
      case "en":
        return "Eco-friendly file transfer up to 200 GB. 100% renewable energy, French hosting, secure and GDPR compliant.";
      case "es":
        return "Transferencia de archivos ecológica hasta 200 GB. Energía 100% renovable, alojamiento francés, seguro y conforme al RGPD.";
      case "de":
        return "Umweltfreundliche Dateiübertragung bis 200 GB. 100% erneuerbare Energie, französisches Hosting, sicher und DSGVO-konform.";
      case "nl":
        return "Milieuvriendelijke bestandsoverdracht tot 200 GB. 100% hernieuwbare energie, Franse hosting, veilig en AVG-conform.";
      case "pt":
        return "Transferência de arquivos ecológica até 200 GB. Energia 100% renovável, hospedagem francesa, segura e em conformidade com o RGPD.";
      default:
        return "Transfert de fichiers éco-responsable jusqu'à 200 Go. Énergie 100% renouvelable, hébergement français, sécurisé et conforme RGPD. Solution professionnelle.";
    }
  };
  
  // Traductions des mots-clés selon la langue et la route
  const getKeywordsByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Mots-clés pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "large file transfer, wetransfer alternative, large attachments, secure file sending, temporary storage, eco-friendly, professional data transfer";
        case "es":
          return "transferencia de archivos grandes, alternativa wetransfer, adjuntos grandes, envío seguro de archivos, almacenamiento temporal, ecológico, transferencia de datos profesional";
        case "de":
          return "große Dateiübertragung, wetransfer Alternative, große Anhänge, sichere Dateiübertragung, temporärer Speicher, umweltfreundlich, professionelle Datenübertragung";
        case "nl":
          return "grote bestandsoverdracht, wetransfer alternatief, grote bijlagen, veilige bestandsverzending, tijdelijke opslag, milieuvriendelijk, professionele gegevensoverdracht";
        case "pt":
          return "transferência de arquivos grandes, alternativa wetransfer, anexos grandes, envio seguro de arquivos, armazenamento temporário, ecológico, transferência de dados profissional";
        default:
          return "transfert fichiers volumineux, wetransfer alternative, pièces jointes volumineuses, envoi fichiers sécurisé, stockage temporaire, éco-responsable, transfert de données professionnel";
      }
    }
    
    // Mots-clés pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "eco-friendly file transfer, environmental impact, renewable energy, optimized storage, carbon footprint, ecological file transfer";
        case "es":
          return "transferencia de archivos ecológica, impacto ambiental, energía renovable, almacenamiento optimizado, huella de carbono, transferencia de archivos ecológica";
        case "de":
          return "umweltfreundliche Dateiübertragung, Umweltauswirkungen, erneuerbare Energie, optimierter Speicher, CO2-Fußabdruck, ökologische Dateiübertragung";
        case "nl":
          return "milieuvriendelijke bestandsoverdracht, milieu-impact, hernieuwbare energie, geoptimaliseerde opslag, koolstofvoetafdruk, ecologische bestandsoverdracht";
        case "pt":
          return "transferência de arquivos ecológica, impacto ambiental, energia renovável, armazenamento otimizado, pegada de carbono, transferência de arquivos ecológica";
        default:
          return "transfert fichiers éco-responsable, impact environnemental, énergies renouvelables, stockage optimisé, empreinte carbone, transfert fichiers écologique";
      }
    }
    
    // Mots-clés pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "professional solutions, business file transfer, data security, GDPR compliance, reduced environmental impact, file transfer offers";
        case "es":
          return "soluciones profesionales, transferencia de archivos empresariales, seguridad de datos, cumplimiento del RGPD, impacto ambiental reducido, ofertas de transferencia de archivos";
        case "de":
          return "professionelle Lösungen, Unternehmens-Dateiübertragung, Datensicherheit, DSGVO-Konformität, reduzierte Umweltauswirkungen, Dateiübertragungsangebote";
        case "nl":
          return "professionele oplossingen, bedrijfsbestandsoverdracht, gegevensbeveiliging, AVG-naleving, verminderde milieu-impact, bestandsoverdrachtsaanbiedingen";
        case "pt":
          return "soluções profissionais, transferência de arquivos empresariais, segurança de dados, conformidade com o RGPD, impacto ambiental reduzido, ofertas de transferência de arquivos";
        default:
          return "solutions professionnelles, transfert fichiers entreprise, sécurité données, conformité RGPD, impact environnemental réduit, offres transfert fichiers";
      }
    }
    
    // Mots-clés pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "FileVert contact, file transfer support, technical assistance, customer service, information request, professional contact";
        case "es":
          return "contacto FileVert, soporte de transferencia de archivos, asistencia técnica, servicio al cliente, solicitud de información, contacto profesional";
        case "de":
          return "FileVert Kontakt, Dateiübertragungsunterstützung, technische Unterstützung, Kundendienst, Informationsanfrage, professioneller Kontakt";
        case "nl":
          return "FileVert contact, bestandsoverdrachtsondersteuning, technische assistentie, klantenservice, informatieverzoek, professioneel contact";
        case "pt":
          return "contato FileVert, suporte de transferência de arquivos, assistência técnica, atendimento ao cliente, solicitação de informações, contato profissional";
        default:
          return "contact FileVert, support transfert fichiers, assistance technique, service client, demande information, contact professionnel";
      }
    }
    
    // Mots-clés pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "chrome extension, browser file transfer, transfer extension, chrome plugin, simplified transfer, eco-friendly extension";
        case "es":
          return "extensión chrome, transferencia de archivos del navegador, extensión de transferencia, complemento chrome, transferencia simplificada, extensión ecológica";
        case "de":
          return "Chrome-Erweiterung, Browser-Dateiübertragung, Übertragungserweiterung, Chrome-Plugin, vereinfachte Übertragung, umweltfreundliche Erweiterung";
        case "nl":
          return "chrome-extensie, browserbestandsoverdracht, overdrachtsextensie, chrome-plugin, vereenvoudigde overdracht, milieuvriendelijke extensie";
        case "pt":
          return "extensão chrome, transferência de arquivos do navegador, extensão de transferência, plugin chrome, transferência simplificada, extensão ecológica";
        default:
          return "extension chrome, transfert fichiers navigateur, extension transfert, plugin chrome, transfert simplifié, extension éco-responsable";
      }
    }
    
    // Mots-clés pour la page Profil
    if (currentPath === '/profil') {
      switch(currentLang) {
        case "en":
          return "file transfer management, environmental impact statistics, user account, file history, transfer tracking, eco-friendly dashboard";
        case "es":
          return "gestión de transferencia de archivos, estadísticas de impacto ambiental, cuenta de usuario, historial de archivos, seguimiento de transferencias, panel ecológico";
        case "de":
          return "Dateiübertragungsverwaltung, Umweltauswirkungsstatistiken, Benutzerkonto, Dateiverlauf, Übertragungsverfolgung, umweltfreundliches Dashboard";
        case "nl":
          return "bestandsoverdrachtsbeheer, statistieken over milieu-impact, gebruikersaccount, bestandsgeschiedenis, overdrachtstracking, milieuvriendelijk dashboard";
        case "pt":
          return "gerenciamento de transferência de arquivos, estatísticas de impacto ambiental, conta de usuário, histórico de arquivos, rastreamento de transferências, painel ecológico";
        default:
          return "gestion transfert fichiers, statistiques impact environnemental, compte utilisateur, historique fichiers, suivi transferts, tableau de bord éco-responsable";
      }
    }
    
    // Mots-clés pour la page Mentions légales
    if (currentPath === '/legal-terms') {
      switch(currentLang) {
        case "en":
          return "legal notice, terms of use, GDPR compliance, data protection, legal information, file transfer service";
        case "es":
          return "aviso legal, términos de uso, cumplimiento del RGPD, protección de datos, información legal, servicio de transferencia de archivos";
        case "de":
          return "Impressum, Nutzungsbedingungen, DSGVO-Konformität, Datenschutz, rechtliche Informationen, Dateiübertragungsdienst";
        case "nl":
          return "juridische kennisgeving, gebruiksvoorwaarden, AVG-naleving, gegevensbescherming, juridische informatie, bestandsoverdrachtsservice";
        case "pt":
          return "aviso legal, termos de uso, conformidade com o RGPD, proteção de dados, informações legais, serviço de transferência de arquivos";
        default:
          return "mentions légales, conditions d'utilisation, conformité RGPD, protection des données, informations légales, service transfert fichiers";
      }
    }
    
    // Mots-clés pour la page CGU
    if (currentPath === '/cgu') {
      switch(currentLang) {
        case "en":
          return "terms of service, user agreement, file transfer conditions, service usage, legal terms, user rights";
        case "es":
          return "términos de servicio, acuerdo de usuario, condiciones de transferencia de archivos, uso del servicio, términos legales, derechos del usuario";
        case "de":
          return "Nutzungsbedingungen, Benutzervereinbarung, Dateiübertragungsbedingungen, Servicenutzung, rechtliche Bedingungen, Benutzerrechte";
        case "nl":
          return "servicevoorwaarden, gebruikersovereenkomst, bestandsoverdrachtsvoorwaarden, servicegebruik, juridische voorwaarden, gebruikersrechten";
        case "pt":
          return "termos de serviço, acordo de usuário, condições de transferência de arquivos, uso do serviço, termos legais, direitos do usuário";
        default:
          return "conditions générales d'utilisation, accord utilisateur, conditions transfert fichiers, utilisation service, termes légaux, droits utilisateur";
      }
    }
    
    // Mots-clés pour la page Politique de confidentialité
    if (currentPath === '/privacy') {
      switch(currentLang) {
        case "en":
          return "privacy policy, data protection, personal information, GDPR, confidentiality, data security";
        case "es":
          return "política de privacidad, protección de datos, información personal, RGPD, confidencialidad, seguridad de datos";
        case "de":
          return "Datenschutzrichtlinie, Datenschutz, persönliche Informationen, DSGVO, Vertraulichkeit, Datensicherheit";
        case "nl":
          return "privacybeleid, gegevensbescherming, persoonlijke informatie, AVG, vertrouwelijkheid, gegevensbeveiliging";
        case "pt":
          return "política de privacidade, proteção de dados, informações pessoais, RGPD, confidencialidade, segurança de dados";
        default:
          return "politique de confidentialité, protection des données, informations personnelles, RGPD, confidentialité, sécurité des données";
      }
    }
    
    // Mots-clés pour la page Politique de confidentialité de l'extension Chrome
    if (currentPath === '/privacy-chrome-extension') {
      switch(currentLang) {
        case "en":
          return "chrome extension privacy, browser plugin data, extension security, data protection, privacy terms, browser add-on";
        case "es":
          return "privacidad de extensión chrome, datos de complemento de navegador, seguridad de extensión, protección de datos, términos de privacidad, complemento de navegador";
        case "de":
          return "Chrome-Erweiterung Datenschutz, Browser-Plugin-Daten, Erweiterungssicherheit, Datenschutz, Datenschutzbedingungen, Browser-Add-on";
        case "nl":
          return "chrome-extensie privacy, browserplug-ingegevens, extensiebeveiliging, gegevensbescherming, privacyvoorwaarden, browseradd-on";
        case "pt":
          return "privacidade da extensão chrome, dados do plugin do navegador, segurança da extensão, proteção de dados, termos de privacidade, complemento do navegador";
        default:
          return "confidentialité extension chrome, données plugin navigateur, sécurité extension, protection données, conditions confidentialité, module navigateur";
      }
    }
    
    // Mots-clés pour la page 404
    if (currentPath === '*') {
      switch(currentLang) {
        case "en":
          return "page not found, 404 error, missing page, file transfer, eco-friendly solution, FileVert";
        case "es":
          return "página no encontrada, error 404, página faltante, transferencia de archivos, solución ecológica, FileVert";
        case "de":
          return "Seite nicht gefunden, 404 Fehler, fehlende Seite, Dateiübertragung, umweltfreundliche Lösung, FileVert";
        case "nl":
          return "pagina niet gevonden, 404 fout, ontbrekende pagina, bestandsoverdracht, milieuvriendelijke oplossing, FileVert";
        case "pt":
          return "página não encontrada, erro 404, página ausente, transferência de arquivos, solução ecológica, FileVert";
        default:
          return "page non trouvée, erreur 404, page manquante, transfert fichiers, solution éco-responsable, FileVert";
      }
    }
    
    // Mots-clés par défaut
    switch(currentLang) {
      case "en":
        return "large file transfer, wetransfer alternative, large attachments, secure file sending, temporary storage, eco-friendly, professional data transfer";
      case "es":
        return "transferencia de archivos grandes, alternativa wetransfer, adjuntos grandes, envío seguro de archivos, almacenamiento temporal, ecológico, transferencia de datos profesional";
      case "de":
        return "große Dateiübertragung, wetransfer Alternative, große Anhänge, sichere Dateiübertragung, temporärer Speicher, umweltfreundlich, professionelle Datenübertragung";
      case "nl":
        return "grote bestandsoverdracht, wetransfer alternatief, grote bijlagen, veilige bestandsverzending, tijdelijke opslag, milieuvriendelijk, professionele gegevensoverdracht";
      case "pt":
        return "transferência de arquivos grandes, alternativa wetransfer, anexos grandes, envio seguro de arquivos, armazenamento temporário, ecológico, transferência de dados profissional";
      default:
        return "transfert fichiers volumineux, wetransfer alternative, pièces jointes volumineuses, envoi fichiers sécurisé, stockage temporaire, éco-responsable, transfert de données professionnel";
    }
  };
  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "FileVert",
    "applicationCategory": "BusinessApplication",
    "description": description,
    "url": fullUrl,
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "EUR"
    },
    "provider": {
      "@type": "Organization",
      "name": "FileVert",
      "url": canonicalUrl
    }
  };

  // Obtenir la traduction pour l'attribut alt des images
  const getImageAltByLang = (currentLang: string): string => {
    switch(currentLang) {
      case "en":
        return "FileVert - Eco-friendly file transfer";
      case "es":
        return "FileVert - Transferencia de archivos ecológica";
      case "de":
        return "FileVert - Umweltfreundliche Dateiübertragung";
      case "nl":
        return "FileVert - Milieuvriendelijke bestandsoverdracht";
      case "pt":
        return "FileVert - Transferência de arquivos ecológica";
      default:
        return "FileVert - Transfert de fichiers éco-responsable";
    }
  };

  return (
    <Helmet
      htmlAttributes={{ lang }}
      titleAttributes={{
        lang,
        "data-hreflang": lang,
        "data-alternate": "true"
      }}
    >
      <title>{title || getTitleByLang(lang)}</title>
      <meta name="description" content={description || getDescriptionByLang(lang)} />
      <meta name="keywords" content={getKeywordsByLang(lang)} />
      <link rel="canonical" href={canonicalFullUrl} />
      
      {/* Meta tags pour Open Graph et Twitter avec traductions */}
      <meta property="og:image:alt" content={getImageAltByLang(lang)} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={getImageAltByLang(lang)} />
      
      {/* Hreflang tags for multilingual support */}
      {languages.filter(l => l !== 'fr').map(l => (
        <link
          key={l}
          rel="alternate"
          hrefLang={l}
          href={`${canonicalUrl}${path}${path.includes('?') ? '&' : '?'}hl=${l}`}
        />
      ))}
      {/* Le français est la langue par défaut, donc pas de paramètre hl=fr */}
      <link rel="alternate" hrefLang="fr" href={`${canonicalUrl}${path}`} />
      <link rel="alternate" hrefLang="x-default" href={`${canonicalUrl}${path}`} />
      
      {/* Open Graph */}
      <meta property="og:title" content={title || getTitleByLang(lang)} />
      <meta property="og:description" content={description || getDescriptionByLang(lang)} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={lang === 'en' ? 'en_US' : `${lang}_${lang.toUpperCase()}`} />
      {languages.filter(l => l !== lang).map(l => (
        <meta
          key={l}
          property="og:locale:alternate"
          content={l === 'en' ? 'en_US' : `${l}_${l.toUpperCase()}`}
        />
      ))}
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || getTitleByLang(lang)} />
      <meta name="twitter:description" content={description || getDescriptionByLang(lang)} />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          ...schemaData,
          description: description || getDescriptionByLang(lang),
          inLanguage: lang
        })}
      </script>
    </Helmet>
  );
};

export default SEOHelmet;