import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { i18n, matomo } from "../utils";
import { connect } from "react-redux";
import { UploadPhase, determinePhase } from "../Constants/UploadPhases";
import { assetsApi } from "../Api";
import { isMobile as isMobileOrTablet, isTablet } from "react-device-detect";

const { _paq } = matomo;

interface Annonce {
  id: string;
  title: string;
  body: string;
  Phrase: string;
  url: string;
  publisher: string;
}

interface AnnonceState {
  mounted: boolean;
  annonce: Annonce | null;
  rssFeed: any[];
  currentIndex: number;
  isLoading: boolean;
}

interface RotatorContent {
  text: string;
  className?: string;
}

interface SimpleTextRotatorProps {
  content: RotatorContent[];
}

const SimpleTextRotator: React.FC<SimpleTextRotatorProps> = ({ content }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isChangingText, setIsChangingText] = useState(false);

  useEffect(() => {
    if (content.length === 0) return;

    const intervalId = setInterval(() => {
      startTransition();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [content]);

  const startTransition = () => {
    setPreviousIndex(currentIndex);
    setIsTransitioning(true);
    
    // Attendre que la transition de sortie soit complète avant de changer le texte
    setTimeout(() => {
      setIsChangingText(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
      
      // Démarrer la transition d'entrée après le changement de texte
      setTimeout(() => {
        setIsTransitioning(false);
        // Réinitialiser l'état de changement de texte après la transition
        setTimeout(() => {
          setIsChangingText(false);
        }, 50);
      }, 50);
    }, 950); // Légèrement plus court que la durée de transition pour éviter un gap
  };

  if (content.length === 0) return null;

  const baseTextStyle = "text-white text-5xl font-poppins font-extrabold tracking-wide antialiased";

  return (
    <div className="relative h-24 overflow-hidden">
      {/* Current text */}
      <div
        className={`absolute w-full transform transition-all duration-1000 ease-in-out
          ${isTransitioning ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'}`}
      >
        <div className={`${baseTextStyle} ${content[currentIndex].className || ''}`}>
          {!isChangingText && content[currentIndex].text}
        </div>
      </div>

      {/* Previous text */}
      <div
        className={`absolute w-full transform transition-all duration-1000 ease-in-out
          ${isTransitioning ? 'translate-y-0 opacity-0' : '-translate-y-full opacity-0'}`}
      >
        <div className={`${baseTextStyle} ${content[previousIndex].className || ''}`}>
          {content[previousIndex].text}
        </div>
      </div>
    </div>
  );
};


interface TransferState {
  isConfigurationPhase?: boolean;
  isUploadFinished?: boolean;
  isLocked?: boolean;
  totalSize?: number;
  purging?: boolean;
}

interface AnnonceComponentProps {
  isDownloadPage?: boolean;
  isUploading?: boolean;
  /** @deprecated Used in Home.tsx but not in this component */
  btnClassName?: string;
  typeAnnonce?: string;
  transfer?: TransferState;
  layoutPhase?: UploadPhase; // Phase venant directement du composant Layout
  customTitle?: string;
  customMessage?: string;
  customUrl?: string;
  organization?: string;
  user?: any; // Utilisateur connecté
}

const AnnonceComponent: React.FC<AnnonceComponentProps> = (props) => {
  const [state, setState] = useState<AnnonceState>({
    mounted: false,
    annonce: null,
    rssFeed: [],
    currentIndex: 0,
    isLoading: false
  });

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      const storedRSS = JSON.parse(localStorage.getItem("rss") || "{}");
      const currentLang = i18n.getLang();
      
      
      // Vérifier si nous sommes sur la page de téléchargement et s'il y a un paramètre de langue dans l'URL
      if (props.isDownloadPage) {
        
        // Récupérer la langue de l'URL
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlLang = urlSearchParams.get("hl");
        
        // Ne changer la langue que lors du chargement initial (si mounted est false)
        if (urlLang && !mounted) {
          const formattedLang = `${urlLang.toLowerCase()}_${urlLang.toUpperCase()}`;
          
          // Si la langue de l'URL est différente de la langue actuelle, changer la langue
          if (formattedLang !== currentLang) {
            await i18n.changeLang(formattedLang);
            return; // La langue a changé, le composant va se recharger
          }
        }
      }
      
      // Forcer le rechargement des annonces
      
      const rssData = await i18n.initRSS(true); // Passer true pour forcer le rechargement
      
      // Récupérer les données du localStorage après le rechargement
      const rssFeed = JSON.parse(localStorage.getItem("rss") || "{}");
      
      if (rssFeed.value?.annonces) {
      }

      if (mounted && rssFeed.value?.annonces?.length > 0) {
        const randomIndex = Math.floor(Math.random() * rssFeed.value.annonces.length);
        
        setState((prevState) => ({
          ...prevState,
          mounted: true,
          annonce: rssFeed.value.annonces[randomIndex]?.node || null,
          rssFeed: rssFeed.value,
          currentIndex: Math.floor(Math.random() * rssFeed.value.annonces.length),
          isLoading: false,
        }));
      } else {
        
        // Essayer avec la langue française comme fallback
        if (currentLang !== 'fr_FR') {
          const frRssData = await assetsApi.getRSS('fr_FR');
          
          if (frRssData?.annonces?.length > 0) {
            const randomIndex = Math.floor(Math.random() * frRssData.annonces.length);
            setState((prevState) => ({
              ...prevState,
              mounted: true,
              annonce: frRssData.annonces[randomIndex]?.node || null,
              rssFeed: frRssData,
              currentIndex: randomIndex,
              isLoading: false,
            }));
          }
        }
      }
    };

    init();
    return () => { mounted = false; };
  }, [i18n.getLang()]); // Re-exécuter quand la langue change

  // Déterminer la phase actuelle du transfert
  const getCurrentPhase = (): UploadPhase => {
    const { transfer, layoutPhase } = props;
    
    // Si la phase est fournie par le Layout, utilisez-la
    if (layoutPhase !== undefined) {
      return layoutPhase;
    }
    
    // Fallback sur la détermination basée sur le transfert
    return determinePhase(transfer || {});
  };

  // Fonction pour obtenir le contenu complet de la phase (titre, description, cta)
  const getPhaseContent = (): { message: string | null; description: string | null; cta: string | null } => {
    const phase = getCurrentPhase();
    const isLoggedIn = !!props.user;
    
    switch (phase) {
      case UploadPhase.FINISHED:
        return {
          message: i18n._("filevert_finished_title"),
          description: isLoggedIn
            ? i18n._("phase_finished_description_logged_in")
            : i18n._("phase_finished_description"),
          cta: isLoggedIn ? null : i18n._("phase_finished_cta")
        };
      case UploadPhase.PROGRESS:
        return {
          message: i18n._("upload_in_progress_title"),
          description: isLoggedIn
            ? i18n._("phase_progress_description_logged_in")
            : i18n._("phase_progress_description"),
          cta: isLoggedIn ? null : i18n._("phase_progress_cta")
        };
      case UploadPhase.CONFIGURATION:
        return {
          message: i18n._("add_more_files_prompt"),
          description: i18n._("phase_configuration_description"),
          cta: isLoggedIn ? null : i18n._("phase_configuration_cta")
        };
      case UploadPhase.ERROR:
        return {
          message: i18n._("upload_error_title"),
          description: i18n._("phase_error_description"),
          cta: isLoggedIn ? null : i18n._("phase_error_cta")
        };
      case UploadPhase.INITIAL:
      default:
        return {
          message: null,
          description: null,
          cta: null
        };
    }
  };

  // Obtenir le message approprié pour la phase actuelle (méthode maintenue pour compatibilité)
  const getPhaseMessage = (): string | null => {
    const phase = getCurrentPhase();
    
    switch (phase) {
      case UploadPhase.FINISHED:
        return i18n._("filevert_finished_title");
      case UploadPhase.PROGRESS:
        return i18n._("upload_in_progress_title");
      case UploadPhase.CONFIGURATION:
        return i18n._("add_more_files_prompt");
      case UploadPhase.ERROR:
        return i18n._("upload_error_title");
      case UploadPhase.INITIAL:
      default:
        return null;
    }
  };

  const handleClick = (url: string) => {
    _paq.push(["trackLink", url, "link"]);
    // Ouvrir dans un nouvel onglet avec l'attribut noopener pour éviter les interférences
    const newWindow = window.open(url || "/offers", "_blank");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const { isDownloadPage, typeAnnonce, customTitle, customMessage, customUrl, organization, user } = props;
  const { annonce, isLoading } = state;
  const isLoggedIn = !!user;

  if (isLoading) return null;

  // Si ce n'est pas la page de téléchargement, afficher le message de phase
  if (!isDownloadPage) {
    const phaseContent = getPhaseContent();
    
    if (phaseContent.message) {
      return (
        <div className="relative">
          <div className="phases flex flex-col space-y-4 max-w-2xl">
            <h2 className="pl-8 font-bold text-white/90 text-4xl tracking-tight drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)]">
              {phaseContent.message}
            </h2>
            
            {/* Description élégante */}
            {phaseContent.description && (
              <p className="pl-8 text-white/80 text-xl font-light leading-relaxed tracking-wide">
                {phaseContent.description}
              </p>
            )}
            
            {/* Call to action harmonieux - masqué si l'utilisateur est connecté */}
            {phaseContent.cta && !isLoggedIn && (
  <div className="mt-2 pl-8">
    {(getCurrentPhase() === UploadPhase.PROGRESS || getCurrentPhase() === UploadPhase.FINISHED) ? (
      <NavLink
        to="/offers"
        className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-lg text-white/90 font-medium text-sm tracking-wide border border-white/20 shadow-lg hover:bg-white/20 transition-all duration-300 hover:scale-105 relative z-[1000] pointer-events-auto"
      >
        {phaseContent.cta}
      </NavLink>
    ) : getCurrentPhase() === UploadPhase.ERROR ? (
      <NavLink
        to="/support"
        className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-lg text-white/90 font-medium text-sm tracking-wide border border-white/20 shadow-lg hover:bg-white/20 transition-all duration-300 hover:scale-105 relative z-[1000] pointer-events-auto"
      >
        {phaseContent.cta}
      </NavLink>
    ) : (
      <span className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-lg text-white/90 font-medium text-sm tracking-wide border border-white/20 shadow-lg">
        {phaseContent.cta}
      </span>
    )}
  </div>
)}
          </div>
        </div>
      );
    }

    // Si pas de message de phase, afficher le rotateur de texte
    const messages = i18n._("messages") || [];
    return messages && messages.map ? (
      <div className="relative z-40">
        <SimpleTextRotator content={messages} />
      </div>
    ) : null;
  }

  // Logique pour la page de téléchargement
  if (!annonce && !customTitle && !customMessage) return null;

  return (
    <div className={`relative w-full flex items-center justify-center ${
      isMobileOrTablet ? 'p-4' : 'p-8'
    }`} style={{ height: 'auto' }}>
      <div className={`flex w-full mx-auto ${
        isMobileOrTablet ? 'gap-4 max-w-xl' : 'gap-12 max-w-4xl'
      }`}>
        <div className="flex-1 flex flex-col justify-center min-h-0">
          {/* Utiliser le titre personnalisé s'il existe, sinon utiliser le titre de l'annonce */}
          <h2 className={`text-white/90 font-medium tracking-tight drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] mb-6 ${
            isMobileOrTablet ? 'text-3xl' : 'text-5xl'
          }`}>
            {customTitle || annonce?.title}
          </h2>
          
          <div className={`rounded-3xl backdrop-blur-lg bg-white/5 ${isMobileOrTablet ? 'p-4' : 'p-8'} max-w-2xl`}>
            {/* Utiliser le message personnalisé s'il existe, sinon utiliser le corps de l'annonce */}
            <div
              className={`text-white/70 leading-relaxed mb-6 ${
                isMobileOrTablet ? 'text-base' : 'text-lg'
              }`}
              dangerouslySetInnerHTML={{ __html: customMessage || annonce?.body || '' }}
            />
            
            {/* Afficher la phrase de l'annonce uniquement s'il n'y a pas de contenu personnalisé */}
            {!customMessage && annonce?.Phrase && (
              <p className={`text-white/60 italic mb-8 ${
                isMobileOrTablet ? 'text-sm' : 'text-base'
              }`}>
                {annonce.Phrase}
              </p>
            )}

            <div className="relative z-40">
              {/* N'afficher le bouton avec lien que si la langue est le français */}
              {i18n.getLang().includes('fr') || customUrl ? (
                <button
                  onClick={() => handleClick(customUrl || annonce?.url || '/offers')}
                  className={`
                    inline-flex items-center justify-center
                    transition-all duration-300
                    rounded-full
                    bg-white text-gray-900 hover:bg-gray-100
                    group
                    ${isMobileOrTablet
                      ? 'px-3 py-1.5 text-xs font-medium'
                      : 'px-5 py-2.5 text-sm font-medium'
                    }
                  `}
                >
                  {i18n._(
                    (!customUrl && annonce?.publisher !== "FileVert" && annonce?.url)
                      ? "know_more_label"
                      : "know_more_v2_label"
                  )}
                  <svg
                    className={`transition-transform duration-300 ease-out group-hover:translate-x-1 ${
                      isMobileOrTablet ? 'ml-1 w-3 h-3' : 'ml-2 w-4 h-4'
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              ) : (
                <div className="text-white/50 text-sm italic">
                  {i18n._("links_disabled_non_french")}
                </div>
              )}
              {/* N'afficher l'organisation que pour les annonces personnalisées */}
              {(customTitle || customMessage) && organization && (
                <div className="absolute right-0 mt-2 text-sm text-white/60 italic">
                  {organization}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Éléments décoratifs avec obliques et contraste */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {/* Bandes obliques contrastées */}
        <div className="absolute inset-0">
          {[...Array(6)].map((_, i) => (
            <div
              key={i}
              className="absolute w-[200%] h-32 -rotate-[30deg] origin-left animate-oblique"
              style={{
                top: `${i * 25}%`,
                left: '-50%',
                background: `linear-gradient(90deg, 
                  ${i % 2 === 0 
                    ? 'rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.05) 50%, rgba(255,255,255,0.02) 100%'
                    : 'rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,0.03) 100%'
                  })`,
                transform: `rotate(-30deg) translateY(${i * 20}px)`,
                animationDelay: `${i * 0.8}s`,
                animationDuration: '12s'
              }}
            />
          ))}
        </div>

        {/* Lignes obliques dynamiques */}
        <div className="absolute inset-0">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="absolute w-0.5 h-[200%] -rotate-[30deg] origin-top-left animate-oblique"
              style={{
                left: `${30 + i * 35}%`,
                background: `linear-gradient(180deg, 
                  transparent 0%,
                  rgba(255,255,255,${0.1 - i * 0.02}) 30%,
                  rgba(255,255,255,${0.1 - i * 0.02}) 70%,
                  transparent 100%
                )`,
                animationDelay: `${i * 1.2 + 0.5}s`,
                animationDuration: '12s'
              }}
            />
          ))}
        </div>

        {/* Overlay dégradé pour le contraste */}
        <div 
          className="absolute inset-0"
          style={{
            background: `
              linear-gradient(120deg, 
                rgba(0,0,0,0.1) 0%,
                transparent 50%,
                rgba(255,255,255,0.05) 100%
              )
            `
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  currentPhase: state.transfer?.currentPhase,
  transfer: state.transfer?.activeTransferId
    ? state.transfer.transfers[state.transfer.activeTransferId]
    : undefined,
  user: state.user?.user // Ajout de l'utilisateur connecté
});

export default connect(mapStateToProps)(AnnonceComponent);