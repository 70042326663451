import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { contactApi } from "../../Api";
import { i18n } from "../../utils";
import { appAction } from "../../Store";
import { Mail, MessageSquare, Tag, Phone, HelpCircle } from "lucide-react";

interface IFormData {
  contact_email: string;
  contact_subject: string;
  contact_message: string;
  contact_phone: string;
}

interface IError {
  message: string;
}

interface IProps extends RouteComponentProps {
  app: {
    BACKGROUND: string;
  };
}

interface IState {
  user: {
    user: any;
  };
  app: {
    BACKGROUND: string;
  };
}

interface ContactRequest {
  email: string;
  subject: string;
  message: string;
}

const Alert: React.FC<{
  color: "success" | "danger";
  children: React.ReactNode;
}> = ({ color, children }) => (
  <div
    className={`${
      color === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
    } px-4 py-3 rounded relative mb-4`}
    role="alert"
  >
    {children}
  </div>
);

const Input: React.FC<{
  type: string;
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rows?: number;
}> = ({ type, label, name, value, onChange, rows }) => {
  const Component = type === "textarea" ? "textarea" : "input";
  
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-300">
        {label}
      </label>
      <div className="relative mt-1">
        {name === 'contact_email' && <Mail className="absolute left-3 top-2.5 text-gray-400" size={18} />}
        {name === 'contact_subject' && <Tag className="absolute left-3 top-2.5 text-gray-400" size={18} />}
        {name === 'contact_message' && <MessageSquare className="absolute left-3 top-2.5 text-gray-400" size={18} />}
        {name === 'contact_phone' && <Phone className="absolute left-3 top-2.5 text-gray-400" size={18} />}
        <Component
          className="block w-full pl-10 pr-3 py-2 bg-gray-700/50 border border-gray-600 rounded-md focus:ring-emerald-500 focus:border-emerald-500 text-gray-100 placeholder-gray-400"
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          rows={rows}
        />
      </div>
    </div>
  );
};

const ContactScreen: React.FC<IProps> = ({ app, history }) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    history.goBack();
  };
  
  const [formData, setFormData] = useState<IFormData>({
    contact_email: "",
    contact_subject: "",
    contact_message: "",
    contact_phone: "",
  });
  const [error, setError] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const mountedRef = useRef(true);
  const appState = useSelector((state: any) => state.app);

  useEffect(() => {
    mountedRef.current = true;
    dispatch({ type: appAction.SHOW_CUSTOM_HEADER, value: 'custom-0' });
    return () => {
      mountedRef.current = false;
      dispatch({ type: appAction.SHOW_CUSTOM_HEADER, value: null });
    };
  }, [dispatch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!mountedRef.current) return;
    
    const { name, value } = e.target;
    let newError = [...error];
    
    if (!value) {
      if (!newError.includes(name)) {
        newError.push(name);
      }
    } else {
      newError = newError.filter(e => e !== name);
    }

    setError(newError);
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const email = formData.contact_email;
    const subject = formData.contact_subject;
    const message = formData.contact_message;
    let newError: string[] = [];

    if (!email) newError.push("contact_email");
    if (!subject) newError.push("contact_subject");
    if (!message) newError.push("contact_message");

    if (newError.length) {
      setError(newError);
      return;
    }

    try {
      // Importer SystemInfo uniquement au moment de l'envoi
      const { systemInfo } = await import('../../utils/SystemInfo');
      const sysInfo = systemInfo.getFormattedInfo();

      const contactRequest: ContactRequest = {
        email,
        subject,
        message: `${message}\n\n${sysInfo}\n\nTéléphone:\n${formData.contact_phone}`,
      };

      const result = await contactApi.sendContactRequest(contactRequest);

      if (result?.success && mountedRef.current) {
        setSuccess(true);
        setFormData({
          contact_email: "",
          contact_subject: "",
          contact_message: "",
          contact_phone: "",
        });
        setError([]);
        setIsSubmitted(true);
        
        setTimeout(() => {
          if (mountedRef.current) {
            setSuccess(false);
            setIsSubmitted(false);
          }
        }, 2000);
      }
    } catch (err) {
      if (mountedRef.current) {
        setSuccess(false);
        setIsSubmitted(true);
      }
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center px-4 py-12 bg-gray-900"
      style={{
        background: appState?.BACKGROUND ? `linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95)), url(${appState.BACKGROUND}) no-repeat center center/cover fixed` : undefined
      }}
    >
      <div className="relative max-w-xl mx-auto w-full">
        {/* Demo Block - Position absolue sur grand écran, empilé sur mobile */}
        <div className="lg:absolute lg:-left-64 lg:top-1/2 lg:transform lg:-translate-y-1/2 bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-2xl border border-gray-700 mb-6 lg:mb-0 lg:w-56 w-full lg:transition-all lg:duration-300 lg:hover:-translate-x-2">
          <div>
            <h2 className="text-xl font-bold text-emerald-400 mb-3">
              {i18n._("demo.title")}
            </h2>
            <h3 className="text-sm text-gray-300 mb-4">
              {i18n._("demo.subtitle")}
            </h3>
            <div className="space-y-2 mb-6">
              {(i18n._("demo.features") as string[]).map((feature, index) => (
                <div key={index} className="flex items-center space-x-2 text-sm">
                  <div className="flex-shrink-0 h-4 w-4 text-emerald-400">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-gray-300">{feature}</span>
                </div>
              ))}
            </div>
          </div>
          <a
            href="https://meet.brevo.com/hugo-filevert/onboarding-filevert"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 transition duration-150 ease-in-out w-full shadow-lg hover:shadow-xl"
          >
            {i18n._("demo.button")}
          </a>
          
          {/* Nouveau bloc de support rapide */}
          <div className="mt-4 p-4 bg-gray-700/50 rounded-lg border border-gray-600">
            <div className="flex items-center space-x-2 mb-2">
              <HelpCircle className="w-5 h-5 text-emerald-400" />
              <h3 className="text-sm font-semibold text-gray-200">
                {i18n._("support.title")}
              </h3>
            </div>
            <p className="text-xs text-gray-300 mb-3">
              {i18n._("support.description")}
            </p>
            <a
              href="/support"
              className="inline-flex items-center justify-center px-3 py-1.5 border border-gray-500 text-xs font-medium rounded-md text-gray-200 bg-gray-600 hover:bg-gray-500 transition duration-150 ease-in-out w-full"
            >
              {i18n._("support.button")}
            </a>
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-gray-800/90 backdrop-blur-sm p-8 rounded-lg shadow-2xl border border-gray-700">
          <div className="flex items-center justify-center space-x-4 mb-8">
            <Mail className="w-8 h-8 text-emerald-500" />
            <h1 className="text-2xl font-bold text-center text-gray-100">
              {i18n._("modal_contact_title")}
            </h1>
          </div>

          {isSubmitted && (
            <Alert color={success ? "success" : "danger"}>
              {i18n._(success ? "form_contact_success" : "form_contact_error")}
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <Input
              type="email"
              label={i18n._("form_contact_email_label")}
              name="contact_email"
              value={formData.contact_email}
              onChange={handleChange}
            />

            <Input
              type="tel"
              label="Numéro de téléphone"
              name="contact_phone"
              value={formData.contact_phone}
              onChange={handleChange}
            />

            <Input
              type="text"
              label={i18n._("form_contact_subject_label")}
              name="contact_subject"
              value={formData.contact_subject}
              onChange={handleChange}
            />

            <Input
              type="textarea"
              label={i18n._("form_contact_message_label")}
              name="contact_message"
              value={formData.contact_message}
              onChange={handleChange}
              rows={10}
            />

            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50"
            >
              {i18n._("btn_valid_label")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user?.user,
  app: state.app,
});

const ContactScreenWithRouter = withRouter(ContactScreen);

export { ContactScreenWithRouter as ContactScreen };
export default connect(mapStateToProps)(ContactScreenWithRouter);