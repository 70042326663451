import React, { useState, useRef, useEffect } from "react";
import { AlertCircle } from "lucide-react";
import classNames from "classnames";
import { i18n } from "../../../utils";

export interface IInputEmailComponentProps {
  name: string;
  error?: string;
  label?: string;
  defaultValue?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  [rest: string]: any;
}

interface InputObject {
  getRawInput: () => IInputEmailComponentProps;
  setError: (error: string | null) => void;
  setRef: (ref: React.RefObject<HTMLInputElement>) => void;
  setCurrentError: (error: string | null) => void;
  setValue: (value: string) => void;
  value: string;
  disabled?: boolean;
  rest?: any;
}

export function InputEmailComponent({ inputObject }: { inputObject: InputObject }) {
  const {
    label,
    name,
    defaultValue,
    maxLength,
    minLength,
    required,
    disabled,
  }: IInputEmailComponentProps = inputObject.getRawInput();

  const [error, setError] = useState<string | null>(null);
  const [localValue, setLocalValue] = useState(inputObject.value || defaultValue || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputObject.setRef) {
      inputObject.setRef(inputRef);
    }
  }, [inputObject]);

  useEffect(() => {
    setLocalValue(inputObject.value || '');
  }, [inputObject.value]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (required && !value.trim()) {
      setError("missing");
      if (inputObject.setCurrentError) {
        inputObject.setCurrentError("missing");
      }
      return;
    }

    if (
      (minLength && value.length < minLength) ||
      (maxLength && value.length > maxLength) ||
      !isValidEmail(value)
    ) {
      setError("invalid");
      if (inputObject.setCurrentError) {
        inputObject.setCurrentError("invalid");
      }
      return;
    }

    setError(null);
    if (inputObject.setCurrentError) {
      inputObject.setCurrentError(null);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLocalValue(value);
    if (inputObject.setValue) {
      inputObject.setValue(value);
    }
    setError(null);
    if (inputObject.setCurrentError) {
      inputObject.setCurrentError(null);
    }
  };

  return (
    <div className={`form-control ${error ? "mb-4" : "mb-2"}`}>
      <label htmlFor={name} className="label">
        <span className="label-text">
          {label} {required && <span className="text-error">*</span>}
        </span>
      </label>
      <input
        ref={inputRef}
        id={name}
        name={name}
        type="email"
        minLength={minLength}
        maxLength={maxLength}
        value={localValue}
        required={required}
        autoComplete="email"
        className={classNames(
          "w-full px-3 py-2 bg-gray-800 text-gray-200 border border-gray-600 rounded-md focus:ring-2 focus:ring-emerald-500",
          {
            "input-error": error,
            "input-success": !error && localValue,
          }
        )}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        {...inputObject.rest}
      />
      {error && (
        <span className="mt-1 flex items-center text-error text-sm" role="alert">
          <AlertCircle className="mr-1 w-4 h-4" />
          {error === "missing" && "Ce champ est requis."}
          {error === "invalid" && "Veuillez entrer une adresse email valide."}
        </span>
      )}
    </div>
  );
}

function isValidEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}
