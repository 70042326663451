export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const START_TOKEN_REFRESH = "START_TOKEN_REFRESH";
export const CHECK_TOKEN_EXPIRATION = "CHECK_TOKEN_EXPIRATION";
export const UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS";
export const SET_PRORATION_PREVIEW = "SET_PRORATION_PREVIEW";

export function loginUser(value: { user: any; token: string; expiresIn: number }) {
  return { type: LOGIN_USER, value };
}

export function logoutUser(value: any) {
  setTimeout(() => (document.location.href = "/"), 500);
  return { type: LOGOUT_USER, value };
}

export function refreshToken(value: { token: string; expiresIn: number }) {
  return { type: REFRESH_TOKEN, value };
}

export function startTokenRefresh() {
  return { type: START_TOKEN_REFRESH };
}

export function checkTokenExpiration() {
  return { type: CHECK_TOKEN_EXPIRATION };
}

export function updateUserPermissions(permissions: any) {
  return { type: UPDATE_USER_PERMISSIONS, permissions };
}

export function setProrationPreview(preview: any) {
  return { type: SET_PRORATION_PREVIEW, preview };
}
