// src/Components/Header.tsx
import React from "react";
import "./Header.css";

import { X, Menu } from "lucide-react";

import { BrandComponent, LoginButtonComponent } from "./Components";

import { connect } from "react-redux";

import { appAction } from "../../Store";
import { i18n } from "../../utils";
import { isMobile } from "../../utils";
import LangComponent from "../LangComponent";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FooterComponent } from "..";
import { hashLinkScrollProps } from "../../utils/hashLinkScroll";
import { gradients } from "../../Constants";

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user.user,
  transfer: state.transfer.transfers,
  file: state.file,
});

interface Props {
  dispatch: Function;
  app: any;
  user: any;
  file: IFileWithMeta[];
}

function getSerializedSlogan(str: string): string {
  return str.replace(/{{(\w+)}}/g, () => '<img alt="Drapeau français" src="/flag.png" />');
}

const HeaderComponent = connect(mapStateToProps)(
  class HeaderComponentNormal extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);
      this.state = { isMenuOpen: false };
    }

    componentDidMount() {
      if (this.props.app.SAVE_PASSWORD || this.props.app.RESET_PASSWORD)
        setTimeout(() => {
          this.setState({ isMenuOpen: false });
        }, 250);
    }

    getThemeClass(id: string) {
      if (id === "custom-0" || id === "custom-1") return id;

      const themeClass: any =
        id.startsWith("0")
          ? gradients.clair.find((gradient) => gradient.id === id)
          : gradients.sombre.find((gradient) => gradient.id === id);

      return themeClass?.className || "";
    }

    getClassName = () => {
      const { app } = this.props;

      const className = !app[appAction.SHOW_CUSTOM_HEADER]
        ? "normal"
        : this.getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

      return className;
    };

    getClassId = () => {
      const { app } = this.props;

      const classId = !app[appAction.SHOW_CUSTOM_HEADER]
        ? ""
        : app[appAction.SHOW_CUSTOM_HEADER];

      return classId;
    };

    toggleMenu = () => {
      this.setState((prevState: any) => ({
        isMenuOpen: !prevState.isMenuOpen,
      }));
    };

    handleCloseMenu = () => {
      this.setState({ isMenuOpen: false });
    };

    onModalChangeCB = (isOpen: boolean) => {
      this.setState({ ...this.state, isModalOpen: isOpen });
    };

    render = () => {
      if (this.props.app[appAction.SHOW_LIGHT_HEADER]) return null;

      const themeClass = this.getClassName();
      const themeId = this.getClassId();

      let btnClassName = "border border-gray-800 text-gray-800";
      let textColorName = "text-gray-800";
      let isLight = isMobile;
      let isWhite = isMobile;

      if (themeId && themeId.length) {
        isLight = themeId.startsWith("0") || themeId === "normal";
        isWhite =
          gradients.clair.some(({ id }) => themeId === id) || themeId === "normal";
        btnClassName = isWhite
          ? "border border-white text-white"
          : "border border-black text-black";
        textColorName = isWhite ? "text-white" : "text-black";
      }

      return (
        <header
          id="fv-header"
          className={`w-full fixed top-0 left-0 z-50 bg-transparent transition-all ${
            themeClass ? `bg-${themeClass}` : ""
          } py-4`}
        >
          <div className="container mx-auto flex justify-between items-center px-4">
            <div className="flex items-center">
              <BrandComponent
                mode={!isLight || !isWhite ? "light" : "normal"}
                dispatch={this.props.dispatch}
                file={this.props.file}
              />
            </div>

            <div className="hidden md:flex items-center space-x-4">
              <a
                href="/impact"
                className={`uppercase font-bold ${textColorName}`}
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_more_label")}
              </a>
              <a
                href="/offers"
                className={`uppercase font-bold ${textColorName}`}
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_subscribe_price_label")}
              </a>
              <FooterComponent
                contactOnly={true}
                contactClassName={`uppercase font-bold ${textColorName}`}
              />
              <LoginButtonComponent
                onModalChangeCB={this.onModalChangeCB}
                textColorName={textColorName}
                btnClassName={btnClassName}
              />
            </div>

            <div className="md:hidden">
              <button onClick={this.toggleMenu} className="text-gray-800">
                {this.state.isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {this.state.isMenuOpen && (
            <div className="md:hidden bg-white shadow-lg p-4 space-y-4">
              <a
                href="/impact"
                className={`block uppercase font-bold text-black`}
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_more_label")}
              </a>
              <a
                href="/offers"
                className={`block uppercase font-bold text-black`}
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_subscribe_price_label")}
              </a>
              <FooterComponent
                contactOnly={true}
                contactClassName={`block uppercase font-bold text-black`}
              />
              <LoginButtonComponent
                onModalChangeCB={this.onModalChangeCB}
                textColorName="text-black"
                btnClassName="border border-black text-black"
              />
            </div>
          )}
        </header>
      );
    };
  }
);

const HeaderLightComponent = connect(mapStateToProps)(
  class HeaderComponentLight extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);

      this.state = {
        isMenuOpen: false,
        isModalOpen: false,
      };
    }

    componentDidMount() {
      if (this.props.app.SAVE_PASSWORD || this.props.app.RESET_PASSWORD)
        setTimeout(() => {
          this.setState({ isMenuOpen: false });
        }, 250);
    }

    toggleMenu = () => {
      this.setState((prevState: any) => ({
        isMenuOpen: !prevState.isMenuOpen,
      }));
    };

    handleCloseMenu = () => {
      this.setState({ isMenuOpen: false });
    };

    onModalChangeCB = (isOpen: boolean) => {
      this.setState({ ...this.state, isModalOpen: isOpen });
    };

    render = () => {
      if (!this.props.app[appAction.SHOW_LIGHT_HEADER]) return null;

      const offerLinkLabel = document.location.pathname.includes("/profil")
        ? i18n._("offer_cta_upgrade_label")
        : i18n._("link_subscription_label");

      return (
        <header
          className={`w-full fixed top-0 left-0 z-50 bg-white py-4`}
        >
          <div className="container mx-auto flex justify-between items-center px-4">
            <div className="flex items-center">
              <BrandComponent
                file={this.props.file}
                dispatch={this.props.dispatch}
                mode="light"
              />
            </div>

            <div className="hidden md:flex items-center space-x-4">
              <a
                href="/impact#annonce"
                className="uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_filevertV2_label")}
              </a>
              <a
                href="/impact#metrics"
                className="uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_metrics_label")}
              </a>
              <a
                href="/impact#scope"
                className="uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_scope_label")}
              </a>
              <a
                href="/impact/pro"
                className="uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_business_label")}
              </a>
              <a
                href="/offers"
                className="uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {offerLinkLabel}
              </a>
              <LoginButtonComponent
                onModalChangeCB={this.onModalChangeCB}
                mod="light"
              />
            </div>

            <div className="md:hidden">
              <button onClick={this.toggleMenu} className="text-black">
                {this.state.isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {this.state.isMenuOpen && (
            <div className="md:hidden bg-white shadow-lg p-4 space-y-4">
              <a
                href="/impact#annonce"
                className="block uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_filevertV2_label")}
              </a>
              <a
                href="/impact#metrics"
                className="block uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_metrics_label")}
              </a>
              <a
                href="/impact#scope"
                className="block uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_scope_label")}
              </a>
              <a
                href="/impact/pro"
                className="block uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {i18n._("link_business_label")}
              </a>
              <a
                href="/offers"
                className="block uppercase font-bold text-black"
                onClick={this.handleCloseMenu}
              >
                {offerLinkLabel}
              </a>
              <LoginButtonComponent
                onModalChangeCB={this.onModalChangeCB}
                textColorName="text-black"
                btnClassName="border border-black text-black"
              />
            </div>
          )}
        </header>
      );
    };
  }
);

const FVHeader = () => {
  if (isMobile)
    // prettier-ignore
    //@ts-ignore
    document.getElementById('root').classList.add('mobile');

  return (
    <div
      style={{ height: !isMobile ? 88 : 50 }}
      className={!isMobile ? "" : "h-12 bg-gray-100"}
    >
      <HeaderComponent />
      <HeaderLightComponent />
    </div>
  );
};

export default FVHeader;
