import React, { useState, useEffect, KeyboardEvent, ClipboardEvent } from 'react';
import { Plus } from 'lucide-react';

interface MultiEmailProps {
  emails: string[];
  onChange: (emails: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
  maxEmails?: number;
}

export const isValidEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const MultiEmailInput: React.FC<MultiEmailProps> = ({ 
  emails, 
  onChange, 
  disabled = false,
  placeholder = "Entrez les adresses email...",
  maxEmails
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [localEmails, setLocalEmails] = useState<string[]>(emails);
  const [isCurrentEmailValid, setIsCurrentEmailValid] = useState(false);
  const [showLimitError, setShowLimitError] = useState(false);

  useEffect(() => {
    setLocalEmails(emails);
  }, [emails]);
  
  const isLimitReached = maxEmails ? localEmails.length >= maxEmails : false;
  
  const addEmails = (emailsToAdd: string[]) => {
    if (maxEmails && isLimitReached) {
      setShowLimitError(true);
      setTimeout(() => setShowLimitError(false), 3000);
      return;
    }

    const validEmails = emailsToAdd
      .map(email => email.trim())
      .filter(email => email && isValidEmail(email))
      .filter(email => !localEmails.includes(email))
      // Ne limite le nombre d'emails que si maxEmails est défini
      .slice(0, maxEmails ? maxEmails - localEmails.length : undefined);
  
    const hasInvalidEmails = emailsToAdd.some(email => email.trim() && !isValidEmail(email));
    setIsInvalid(hasInvalidEmails);
  
    if (validEmails.length) {
      const newEmails = [...localEmails, ...validEmails];
      setLocalEmails(newEmails);
      onChange(newEmails);
      setIsInvalid(false);
      setIsCurrentEmailValid(false);
    }
  };
  
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;
  
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue) {
        if (maxEmails && isLimitReached) {
          setShowLimitError(true);
          setTimeout(() => setShowLimitError(false), 3000);
          return;
        }
        addEmails([inputValue]);
        setInputValue('');
        setIsCurrentEmailValid(false);
      }
    } else if (e.key === 'Backspace' && !inputValue && localEmails.length > 0) {
      const newEmails = localEmails.slice(0, -1);
      setLocalEmails(newEmails);
      onChange(newEmails);
      setIsInvalid(false);
    }
  };
  
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    if (disabled) return;
  
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const pastedEmails = pastedText.split(/[,\s]+/);
    addEmails(pastedEmails);
    setIsCurrentEmailValid(false);
  };
  
  const handleRemoveEmail = (indexToRemove: number) => {
    if (disabled) return;
    const newEmails = localEmails.filter((_, index) => index !== indexToRemove);
    setLocalEmails(newEmails);
    onChange(newEmails);
    setIsInvalid(false);
  };
  
  const handleAddEmail = () => {
    if (inputValue) {
      if (maxEmails && isLimitReached) {
        setShowLimitError(true);
        setTimeout(() => setShowLimitError(false), 3000);
        return;
      }
      addEmails([inputValue]);
      setInputValue('');
      setIsCurrentEmailValid(false);
    }
  };
  
  const handleBlur = () => {
    if (inputValue.trim()) {
      if (maxEmails && isLimitReached) {
        setShowLimitError(true);
        setTimeout(() => setShowLimitError(false), 3000);
        return;
      }
      addEmails([inputValue]);
      setInputValue('');
      setIsCurrentEmailValid(false);
    }
  };

  return (
    <div className="w-full">
      <div 
        className={`flex flex-wrap gap-2 p-2 border rounded-md bg-gray-800 min-h-[42px] max-h-[120px] max-w-[315px] overflow-scroll transition-colors focus:ring-2 focus:ring-emerald-500
          ${isInvalid ? 'border-red-500' : 'border-gray-600'}
          ${disabled ? 'bg-gray-700' : 'bg-gray-800'}
        `}
        role="group"
        aria-labelledby="email-input-label"
      >
        {localEmails.map((email, index) => (
          <div
            key={index}
            className={`flex items-center gap-1 px-2 py-1 rounded-md text-sm
              ${disabled ? 'bg-gray-700 text-gray-300' : 'bg-gray-700 text-gray-200'}
            `}
          >
            <span>{email}</span>
            {!disabled && (
              <button
                type="button"
                onClick={() => handleRemoveEmail(index)}
                className="text-gray-400 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 rounded-full"
                aria-label={`Remove ${email}`}
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
        ))}
        {!disabled && (
          <div className="flex flex-1 items-center">
            <input
              type="email"
              value={inputValue}
              onChange={e => {
                const value = e.target.value;
                setInputValue(value);
                setIsInvalid(false);
                setIsCurrentEmailValid(value.trim() !== '' && isValidEmail(value.trim()));
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              onBlur={handleBlur}
              placeholder={maxEmails && isLimitReached ? `Limite de ${maxEmails} emails atteinte` : emails.length === 0 ? placeholder : ''}
              className="flex-1 min-w-[200px] text-gray-200 outline-none bg-transparent focus:ring-0 text-gray-200 placeholder-gray-400"
              aria-invalid={isInvalid}
              aria-describedby="email-input-help email-input-error"
              disabled={Boolean(maxEmails && isLimitReached)}
            />
            {isCurrentEmailValid && (!maxEmails || !isLimitReached) && (
              <button
                type="button"
                onClick={handleAddEmail}
                className="ml-2 p-2 text-emerald-500 hover:text-emerald-400 focus:outline-none focus:ring-2 focus:ring-emerald-500 rounded-full transition-colors duration-200"
                aria-label="Ajouter un email"
              >
                <Plus size={20} />
              </button>
            )}
          </div>
        )}
      </div>
      <div className="mt-1 text-sm">
        {isInvalid && (
          <p id="email-input-error" className="text-red-500 mt-1">
            Veuillez entrer une adresse email valide
          </p>
        )}
        {showLimitError && maxEmails && (
          <p className="text-red-500 mt-1">
            Limite de {maxEmails} emails atteinte
          </p>
        )}
        {maxEmails && (
          <p className="text-gray-400 text-xs mt-1">
            {localEmails.length}/{maxEmails} emails
          </p>
        )}
      </div>
    </div>
  );
};
