import React, { useContext, useState } from "react";
import { 
  Upload, 
  Loader, 
  File, 
  FilePlus, 
  X, 
  CheckCircle, 
  AlertCircle,
  UploadCloudIcon
} from "lucide-react";
import { TransferContext } from "../../Contexts";
import { i18n } from "../../utils";

// Types definition
interface FileItemProps {
  file: File;
  index: number;
}

// File Item Component
const FileItem: React.FC<FileItemProps> = ({ file, index }) => {
  // Convert bytes to readable format
  const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  // Extract file extension
  const getFileExtension = (filename: string): string => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  };
  
  // Determine file type icon
  const getFileIcon = (file: File): React.ReactNode => {
    const extension = getFileExtension(file.name).toLowerCase();
    
    // Video files
    if (file.type.startsWith('video/') || ['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return <File className="w-5 h-5" />;
    }
    
    // Image files
    if (file.type.startsWith('image/') || ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(extension)) {
      return <File className="w-5 h-5" />;
    }
    
    // Audio files
    if (file.type.startsWith('audio/') || ['mp3', 'wav', 'ogg', 'flac'].includes(extension)) {
      return <File className="w-5 h-5" />;
    }
    
    // Default icon
    return <File className="w-5 h-5" />;
  };

  return (
    <div className="bg-base-300 rounded-lg p-4 flex items-center">
      <div className="p-2 bg-purple-500/10 rounded-lg mr-4">
        {getFileIcon(file)}
      </div>
      <div className="flex-1 truncate">
        <p className="font-medium text-base-content truncate" title={file.name}>
          {file.name}
        </p>
        <p className="text-sm text-base-content/70">
          {formatBytes(file.size)} • {file.type.split('/')[0]}
        </p>
      </div>
    </div>
  );
};

interface UploaderStandaloneFullLayoutProps {
  files: File[];
}

// Layout for when files are selected
const UploaderStandaloneFullLayout: React.FC<UploaderStandaloneFullLayoutProps> = ({ files }) => {
  if (!files || files.length === 0) return null;
  
  return (
    <div className="mt-6 space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-purple-500 flex items-center">
          <FilePlus className="w-5 h-5 mr-2" />
          {i18n._("selected_files") || "Fichiers sélectionnés"}
        </h3>
        <span className="text-sm text-base-content/70">
          {files.length} {files.length > 1 ? 'fichiers' : 'fichier'}
        </span>
      </div>
      
      <div className="space-y-3">
        {files.map((file: File, index: number) => (
          <FileItem key={`${file.name}-${index}`} file={file} index={index} />
        ))}
      </div>
    </div>
  );
};

interface UploaderStandaloneProps {
  children?: React.ReactNode;
  multiple?: boolean;
  onFiles?: (files: File[]) => void;
  onSubmit?: (data: { transfer_name: string }) => void;
  accept?: string;
  transfer_name?: string;
  isLocked?: boolean;
  isUploadFinished?: boolean;
  isMinimized?: boolean;
  loaded?: number;
  totalSize?: number;
  currentFileIndex?: number;
  filesProgress?: Record<string, any>;
  progress?: any;
  startTime?: number;
  totalTime?: number;
  consumption?: any;
  transfer_link?: string;
  id?: string;
}

// Main UploaderStandalone Component
const UploaderStandalone: React.FC<UploaderStandaloneProps> = ({
  children,
  multiple = false,
  onFiles,
  onSubmit,
  accept,
  transfer_name,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [transferName, setTransferName] = useState<string>(transfer_name || "");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const transfer = useContext(TransferContext);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const onFilesHandler = (files: FileList | null): void => {
    if (!files) return;
    
    setError("");
    const filesArray = Array.from(files);
    
    // Basic validation
    if (files.length > 10) {
      setError("Vous ne pouvez pas sélectionner plus de 10 fichiers à la fois.");
      return;
    }
    
    if (onFiles) onFiles(filesArray);
    setSelectedFiles(filesArray);
  };

  const handleDrag = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
      return;
    }

    onFilesHandler(e.dataTransfer.files);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    onFilesHandler(e.target.files);
  };

  const cancelUpload = (): void => {
    setSelectedFiles([]);
    setTransferName("");
    setUploading(false);
  };

  // Calculate upload progress percentage
  const progressPercentage = transfer?.loaded && selectedFiles[0]?.size 
    ? Math.round((transfer.loaded / selectedFiles[0].size) * 100) 
    : 0;

  if (selectedFiles.length) {
    return (
      <div className="w-full rounded-xl bg-base-200 p-6 ">
        {/* Transfer Name Input */}
        <div className="space-y-6 max-w-lg mx-auto bg-base-300 p-6 rounded-lg shadow-xl">
        <div className="mb-6">
          <label htmlFor="transfer_name" className="block text-sm font-medium text-base-content/80 mb-2">
            {i18n._("collab_form.transfer.label") || "Nom du transfert"}
          </label>
          <input
            id="transfer_name"
            type="text"
            placeholder={i18n._("collab_form.transfer.label") || "Nom du transfert"}
            className="input input-bordered w-full bg-base-300 border-primary/20 focus:border-primary"
            value={transferName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransferName(e.target.value)}
            readOnly={uploading}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg flex items-center">
            <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
            <span className="text-red-500">{error}</span>
          </div>
        )}

        {/* Upload Progress */}
        {uploading && (
          <div className="w-full mb-6">
            <div className="flex justify-between mb-2">
              <span className="text-sm text-base-content/70">Progression</span>
              <span className="text-sm font-medium text-primary">{progressPercentage}%</span>
            </div>
            <div className="relative h-3 w-full rounded-full overflow-hidden bg-base-300">
              <div 
                className="h-full bg-primary transition-all duration-300"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-xs text-base-content/50">
                {formatBytes(transfer?.loaded || 0)} / {formatBytes(selectedFiles[0]?.size || 0)}
              </span>
              {progressPercentage === 100 ? (
                <span className="text-xs flex items-center text-green-500">
                  <CheckCircle className="w-3 h-3 mr-1" />
                  Terminé
                </span>
              ) : (
                <span className="text-xs flex items-center text-primary">
                  <Loader className="w-3 h-3 mr-1 animate-spin" />
                  En cours...
                </span>
              )}
            </div>
          </div>
        )}

        {/* File List */}
        {!uploading && <UploaderStandaloneFullLayout files={selectedFiles} />}

        {/* Actions Buttons */}
        <div className="mt-6 flex space-x-3">
          {!uploading && !transfer.loaded && !transfer?.transfer_link && (
            <>
              <button
                className="btn btn-primary flex-1"
                onClick={() => {
                  if (!transferName) {
                    setTransferName(selectedFiles[0].name);
                  }
                  setUploading(true);
                  onSubmit?.({
                    transfer_name: transferName || selectedFiles[0].name,
                  });
                }}
                disabled={error !== ""}
              >
                <Upload className="w-4 h-4 mr-2" />
                {i18n._("collab_form.transfer.submit") || "Envoyer"}
              </button>
              <button
                className="btn btn-outline"
                onClick={cancelUpload}
              >
                <X className="w-4 h-4 mr-2" />
                {i18n._("cancel") || "Annuler"}
              </button>
            </>
          )}
          
          {uploading && !transfer?.transfer_link && (
            <button
              className="btn btn-outline btn-error flex-1"
              onClick={cancelUpload}
            >
              <X className="w-4 h-4 mr-2" />
              {i18n._("cancel_upload") || "Annuler le transfert"}
            </button>
          )}
        </div>
      </div></div>
    );
  }

  return (
    <div 
      className={`w-full rounded-lg transition-colors duration-200 ${
        dragActive ? 'border-primary bg-primary/5' : ''
      }`}
    >
      <form
        className="w-full h-full relative"
        onDragEnter={handleDrag}
        onDragOver={(e) => e.preventDefault()}
        onSubmit={(e: React.FormEvent) => e.preventDefault()}
      >
        <input
          type="file"
          accept={accept}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          id="input-file-upload"
          multiple={multiple || false}
          onChange={handleChange}
        />

        <label
          htmlFor="input-file-upload"
          className="w-full h-full flex flex-col items-center justify-center cursor-pointer"
        >
          {children || (
            <div className="text-center p-12">
              <div className="p-4 mb-4 bg-primary bg-opacity-10 rounded-full mx-auto w-fit">
                <Upload className="w-10 h-10 text-primary" />
              </div>
              <p className="text-lg font-medium mb-2">
                Glissez et déposez vos fichiers ici
              </p>
              <p className="text-base-content/70">
                ou cliquez pour sélectionner des fichiers
              </p>
            </div>
          )}
        </label>

        {dragActive && (
          <div
            className="absolute inset-0 w-full h-full"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </form>
    </div>
  );
};

// Helper function to format bytes
const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export default UploaderStandalone;