import React, { useMemo, useCallback } from 'react';
import {
  Upload,
  Clock,
  Users,
  Palette,
  Bell,
  Lock,
  BarChart,
  Mail,
  Video,
  Check,
  X,
  Calendar
} from 'lucide-react';
import { i18n } from '../../../../utils';
import PermissionItem from './PermissionItem';
import UpgradeButton from '../../../Buttons/UpgradeButton';

interface Permission<T = number | boolean> {
  value: T;
  id: number;
}

interface PermissionGroup {
  name: string;
  icon: React.ReactNode;
  format: (value: any) => React.ReactNode | string;
  label: string;
}

interface Permissions {
  upload_size?: Permission;
  upload_ttl?: Permission;
  team_size?: Permission;
  customization?: Permission;
  transfer_notification?: Permission;
  transfer_authentication?: Permission;
  consumption_export?: Permission;
  outlook_addin?: Permission;
  chrome_plugin?: Permission;
  studio?: Permission;
  transfer_tracking?: Permission;
  interval?: Permission<string>;
}

interface PermissionsCardProps {
  permissions: Permissions;
  readOnly?: boolean;
  user_id: string; // Rendre user_id obligatoire
  actionButton?: React.ReactNode;
}

const ensureUserId = (userId: string | undefined, readOnly: boolean): void => {
  if (!readOnly && !userId) {
    console.error('PermissionsCard: user_id is required for non-readonly mode');
    throw new Error('user_id is required for non-readonly mode');
  }
};

const PermissionsCard: React.FC<PermissionsCardProps> = ({ permissions, readOnly = false, user_id }) => {
  // Vérifier que l'ID utilisateur est présent
  ensureUserId(user_id, readOnly);

  // Log pour déboguer
  console.log('PermissionsCard - permissions reçues:', permissions);
  console.log('PermissionsCard - user_id:', user_id);
  // Groupes de permissions avec leurs icônes et formatage
  type PermissionGroupConfig = {
    title: string;
    permissions: PermissionGroup[];
  };

  const formatValue = useCallback((value: any, formatter: (value: any) => React.ReactNode | string) => {
    return formatter(value);
  }, []);

  const permissionGroups = useMemo<PermissionGroupConfig[]>(() => [
    {
      title: i18n._('transfers_group_title'),
      permissions: [
        {
          name: 'upload_size',
          icon: <Upload size={20} />,
          format: (value: number) => `${value / 1_000_000_000} ${i18n._('gb')}`,
          label: i18n._('upload_size_label')
        },
        {
          name: 'upload_ttl',
          icon: <Clock size={20} />,
          format: (value: number) => i18n._('days_count', [value]),
          label: i18n._('upload_ttl_label')
        },
        {
          name: 'interval',
          icon: <Calendar size={20} />,
          format: (value: string) => {
            if (value === 'monthly' || value === 'month') return i18n._('monthly_subscription');
            if (value === 'yearly' || value === 'year') return i18n._('yearly_subscription');
            return value;
          },
          label: i18n._('subscription_interval')
        },
        {
          name: 'transfer_tracking',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('transfer_tracking_label')
        }
      ]
    },
    {
      title: i18n._('team_customization_group_title'),
      permissions: [
        {
          name: 'team_size',
          icon: <Users size={20} />,
          format: (value: number) => value === -1 ? i18n._('unlimited_members') : i18n._('team_members_count', [value]),
          label: i18n._('team_size_label')
        },
        {
          name: 'customization',
          icon: <Palette size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('customization_label')
        }
      ]
    },
    {
      title: i18n._('security_notifications_group_title'),
      permissions: [
        {
          name: 'transfer_authentication',
          icon: <Lock size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('transfer_authentication_label')
        },
        {
          name: 'transfer_notification',
          icon: <Bell size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('transfer_notification_label')
        }
      ]
    },
    {
      title: i18n._('integrations_tools_group_title'),
      permissions: [
        {
          name: 'outlook_addin',
          icon: <Mail size={20} />,
          format: (value: boolean) => value ? (
            <div className="flex items-center space-x-2">
              <div className="flex space-x-2">
                <a href="https://filevert.fr/fv-outlook/manifest.xml" download="manifest.xml" className="text-primary hover:underline">{i18n._('download_label')}</a>
                <span>|</span>
                <a href="https://filevert.fr/solutions/fr/guide/#plugin" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">{i18n._('guide_label')}</a>
              </div>
              <Check className="text-green-500" />
            </div>
          ) : <X className="text-red-500" />,
          label: i18n._('outlook_addin_label')
        },
        {
          name: 'studio',
          icon: <Video size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('studio_label')
        },
        {
          name: 'chrome_plugin',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('chrome_plugin_label')
        },
        {
          name: 'consumption_export',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <X className="text-red-500" />,
          label: i18n._('consumption_export_label')
        }
      ]
    }
  ], []);

  // Mémoiser le rendu des groupes de permissions
  const renderPermissionGroups = useMemo(() =>
    permissionGroups.map((group, groupIndex) => {
      // Vérifier si au moins une permission du groupe existe dans l'objet permissions
      const hasAnyPermission = group.permissions.some(perm =>
        permissions[perm.name as keyof Permissions]
      );
      
      // Ne pas afficher le groupe s'il n'a aucune permission
      if (!hasAnyPermission) return null;
      
      return (
        <div key={groupIndex} className="space-y-4">
          <h3 className="text-lg font-medium text-gray-700 dark:text-neutral-300">
            {group.title}
          </h3>
          <div className="bg-gray-50 dark:bg-neutral-700/50 rounded-lg p-4 space-y-4">
            {group.permissions.map((perm, permIndex) => {
              const permission = permissions[perm.name as keyof Permissions];
              if (!permission) return null;

              return (
                <PermissionItem
                  key={permIndex}
                  icon={perm.icon}
                  label={perm.label}
                  formattedValue={formatValue(permission.value, perm.format)}
                />
              );
            })}
          </div>
        </div>
      );
    }),
    [permissionGroups, permissions, formatValue]
  );

  return (
    <div className="bg-neutral-800 border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
          <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
          {i18n._('permissions_title')}
        </h2>
        {!readOnly && (
          <UpgradeButton
            permissions={permissions}
            user_id={user_id}
            buttonText={i18n._('upgrade_button_label')}
          />
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {renderPermissionGroups}
      </div>
    </div>
  );
};

const arePermissionsEqual = (prevProps: PermissionsCardProps, nextProps: PermissionsCardProps) => {
  const prevKeys = Object.keys(prevProps.permissions);
  const nextKeys = Object.keys(nextProps.permissions);

  if (prevKeys.length !== nextKeys.length) return false;

  return prevKeys.every(key => {
    const prevValue = prevProps.permissions[key as keyof Permissions]?.value;
    const nextValue = nextProps.permissions[key as keyof Permissions]?.value;
    return prevValue === nextValue;
  });
};

// Utiliser React.memo avec une fonction de comparaison personnalisée
export default React.memo(PermissionsCard, arePermissionsEqual);