import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import usageApi, { GroupUsageStats } from '../Api/usageApi';

export const useGroupUsageStats = () => {
  const user = useSelector((state: any) => state.user?.user);
  const [stats, setStats] = useState<GroupUsageStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchStats = useCallback(async () => {
    try {
      if (!user?.permissions?.team_size?.value || user.permissions.team_size.value !== -1) {
        setError('Cette fonctionnalité est réservée aux offres groupe');
        setLoading(false);
        return;
      }

      setLoading(true);
      console.log('useGroupUsageStats - Fetching stats...');
      
      const data = await usageApi.getGroupStats();
      console.log('useGroupUsageStats - Response:', {
        hasData: !!data,
        totalUsage: data?.total?.usage,
        totalCost: data?.total?.cost,
        userCount: data?.users?.length
      });
      setStats(data);
      setError(null);
    } catch (err: any) {
      console.error('Error fetching group usage stats:', err);
      console.error('Error details:', {
        status: err.response?.status,
        statusText: err.response?.statusText,
        data: err.response?.data,
        message: err.message
      });
      if (err.response?.data?.error) {
        setError(err.response.data.error.message);
      } else if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError(err.message || 'Erreur lors de la récupération des statistiques du groupe');
      }
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      fetchStats();
      const interval = setInterval(fetchStats, 5 * 60 * 1000);

      // Écouter les événements de transfert
      const handleTransferComplete = () => {
        console.log('Transfer completed, refreshing stats...');
        fetchStats();
      };
      window.addEventListener('transfer-complete', handleTransferComplete);

      return () => {
        clearInterval(interval);
        window.removeEventListener('transfer-complete', handleTransferComplete);
      };
    }
  }, [user, fetchStats]);

  return {
    stats,
    loading,
    error,
    refresh: fetchStats
  };
};
