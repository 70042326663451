import axios, { AxiosResponse } from "axios";
import { images } from "../assets";

const prefix = "assets";
const defaultLang = "fr_FR";

const assetsApi = {
  async getBackground(): Promise<AxiosResponse & any> {
    const time = Date.now();
    try {
      const backgroudResponse = await axios.get(
        `${prefix}/background?d=${time}`,
        {
          timeout: 10000,
        }
      );
      return `${backgroudResponse.data}?d=${time}`;
    } catch (e) {
      return images.Slide2;
    }
  },

  async getRSS(lang: string = "fr_FR"): Promise<AxiosResponse & any> {
    try {
      // Vérifier le cache en premier
      const cacheKey = `rss_cache_${lang}`;
      let cachedData = null;
      
      try {
        cachedData = localStorage.getItem(cacheKey);
      } catch (storageError) {
        console.warn('[RSS] Erreur lors de l\'accès au localStorage:', storageError);
      }
      
      if (cachedData) {
        try {
          const { data, timestamp } = JSON.parse(cachedData);
          // Cache valide pendant 1 heure
          if (Date.now() - timestamp < 3600000) {
            console.log(`[RSS] Utilisation du cache pour la langue ${lang}`);
            return data;
          }
        } catch (parseError) {
          console.warn('[RSS] Erreur lors de l\'analyse des données du cache:', parseError);
        }
      }

      type LanguageConfig = {
        expectedLanguage: string;
        jsonFilename: string;
      };

      type LanguageConfigs = {
        [key: string]: LanguageConfig;
      };

      // Les langues supportées et leurs configurations
      const languageConfigs: LanguageConfigs = {
        fr: { expectedLanguage: 'Français', jsonFilename: 'fr_annonce_FR.json' },
        en: { expectedLanguage: 'English', jsonFilename: 'en_annonce_EN.json' },
        es: { expectedLanguage: 'Español', jsonFilename: 'es_annonce_ES.json' },
        de: { expectedLanguage: 'Deutsch', jsonFilename: 'de_annonce_DE.json' },
        pt: { expectedLanguage: 'Português', jsonFilename: 'pt_annonce_PT.json' },
        nl: { expectedLanguage: 'Nederlands', jsonFilename: 'nl_annonce_NL.json' }
      };

      // Déterminer la configuration de langue
      const langCode = lang.substring(0, 2).toLowerCase();
      const config = languageConfigs[langCode] || languageConfigs.fr;

      // Fonction pour sauvegarder dans le cache
      const saveToCache = (data: any) => {
        try {
          // Vérifier si localStorage est disponible
          if (typeof localStorage !== 'undefined') {
            // Sauvegarder les données dans une clé spécifique pour éviter les conflits
            localStorage.setItem(cacheKey, JSON.stringify({
              data,
              timestamp: Date.now()
            }));
            
            // Vérifier que les données ont bien été sauvegardées
            const savedData = localStorage.getItem(cacheKey);
            if (!savedData) {
              console.warn('[RSS] Les données n\'ont pas été correctement sauvegardées dans le cache');
            }
          }
        } catch (e) {
          console.warn('[RSS] Erreur lors de la sauvegarde dans le cache:', e);
        }
      };

      // Essayer de récupérer directement le fichier JSON de la langue
      try {
        const directResponse = await axios.get(
          `https://fv-assets-dev.s3.fr-par.scw.cloud/locales/annonces/${config.jsonFilename}`,
          { timeout: 10000 }
        );

        if (directResponse.data?.annonces) {
          saveToCache(directResponse.data);
          return directResponse.data;
        }
      } catch (directError) {
        console.warn(`[RSS] Erreur lors de la récupération directe pour ${lang}:`, directError);
      }

      // Si échec, essayer l'API avec la langue demandée
      try {
        const rss = await axios.get(`${prefix}/rss`, {
          timeout: 10000,
          headers: { 'x-lang': lang }
        });

        if (rss.data?.annonces) {
          saveToCache(rss.data);
          return rss.data;
        }
      } catch (apiError) {
        console.warn(`[RSS] Erreur lors de la récupération API pour ${lang}:`, apiError);
      }

      // Si tout échoue et ce n'est pas déjà le français, essayer le fallback français
      if (langCode !== 'fr') {
        try {
          const fallbackResponse = await axios.get(
            `https://fv-assets-dev.s3.fr-par.scw.cloud/locales/annonces/fr_annonce_FR.json`,
            { timeout: 10000 }
          );

          if (fallbackResponse.data?.annonces) {
            saveToCache(fallbackResponse.data);
            return fallbackResponse.data;
          }
        } catch (fallbackError) {
          console.warn('[RSS] Erreur lors de la récupération du fallback français:', fallbackError);
        }
      }

      // Si tout a échoué, retourner un tableau vide
      return { annonces: [] };
    } catch (e) {
      console.error('[RSS] Erreur générale:', e);
      return { annonces: [] };
    }
  },

  async getLegale(type: string, lang: string): Promise<AxiosResponse & any> {
    const timestamp = Date.now();
    const headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    };

    try {
      // Try requested language first
      const legaleContent = await axios.get(`legale/${type}/${lang}?t=${timestamp}`, {
        timeout: 10000,
        headers
      });
      return legaleContent.data;
    } catch (e) {
      if ((e as any)?.response?.status === 404) {
        try {
          // If requested language fails, try English
          const fallbackLang = "en_EN";
          const legaleContent = await axios.get(
            `legale/${type}/${fallbackLang}?t=${timestamp}`,
            {
              timeout: 10000,
              headers
            }
          );
          return legaleContent.data;
        } catch (e) {
          try {
            // If English fails, try default language (fr_FR)
            const legaleContent = await axios.get(
              `legale/${type}/${defaultLang}?t=${timestamp}`,
              {
                timeout: 10000,
                headers
              }
            );
            return legaleContent.data;
          } catch (e) {
            return Promise.reject(e);
          }
        }
      }
      return Promise.reject(e);
    }
  },

  async getStrings(): Promise<AxiosResponse & any> {
    try {
      // Récupérer les chaînes de traduction depuis l'API
      const strings = await axios.get(`${prefix}/locales`, { timeout: 10000 });
      
      // Vérifier si nous avons des données existantes dans le localStorage
      // pour préserver d'autres propriétés qui pourraient s'y trouver
      try {
        const existingStrings = localStorage.getItem("strings");
        if (existingStrings) {
          const parsedExisting = JSON.parse(existingStrings);
          if (parsedExisting && typeof parsedExisting === 'object' && parsedExisting.value) {
            // Préserver les propriétés existantes qui ne sont pas des traductions
            const nonStringProps = Object.keys(parsedExisting)
              .filter(key => key !== 'value' && key !== 'ttl')
              .reduce((obj, key) => {
                obj[key] = parsedExisting[key];
                return obj;
              }, {} as Record<string, any>);
              
            // Ajouter ces propriétés aux nouvelles données
            console.log('[assetsApi] Préservation des propriétés existantes du localStorage lors de la mise à jour des strings');
          }
        }
      } catch (storageError) {
        console.warn('[assetsApi] Erreur lors de l\'accès au localStorage pour les strings:', storageError);
      }
      
      return strings.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  /*async getCustomCss(): Promise<any> {
    try {
      const customCss = await axios.get(`${prefix}/custom-css`, {
        timeout: 10000,
      });
      return customCss.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },*/
};

export default assetsApi;