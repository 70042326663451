import { userAction } from "../Actions";
import { AnyAction } from "redux";

interface UserState {
  user: any | null;
  token: string | null;
  prorationPreview: any | null;
}

// Charger le token depuis le localStorage lors de l'initialisation
const storedToken = localStorage.getItem('fv-auth-token');

const initialState: UserState = {
  user: null,
  token: storedToken,
  prorationPreview: null
};

export default function userReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case userAction.LOGIN_USER:
      const { user, token } = action.value;
      console.log('User reducer - Login:', { 
        hasUser: !!user, 
        hasToken: !!token,
        userId: user?.id,
        tokenPreview: token ? `${token.substring(0, 10)}...` : null
      });

      // Stocker le token dans le localStorage pour la persistance
      if (token) {
        localStorage.setItem('fv-auth-token', token);
      }

      return {
        ...state,
        user,
        token: token || null
      };

    case userAction.LOGOUT_USER:
      console.log('User reducer - Logout, clearing state');
      localStorage.removeItem('fv-auth-token');
      return initialState;

    case userAction.UPDATE_USER_PERMISSIONS:
      console.log('User reducer - Update permissions:', action.permissions);
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.permissions
        }
      };

    case userAction.SET_PRORATION_PREVIEW:
      return {
        ...state,
        prorationPreview: action.preview
      };

    default:
      return state;
  }
}

export { userAction };
