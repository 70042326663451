export interface Config {
  env: string;
  apiUrl: string;
  ihmUrl: string;

  stripe: {
    public_key: string;
    tax_rate?: string;
    main_product_id?: string;
    groupe_product_id?: string;
    usage_product_id?: string;
    params: {
      apiVersion: "2020-08-27" | string;
    };
  };
}
declare global {
  interface Window {
    config: Config;
  }
}

const config: Config = (() => window.config)();
export { config };
