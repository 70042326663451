import { i18n, translateHeaders } from "../../utils";
import React, { useEffect, useState } from "react";
import { TableListComponent, Switch } from "./TableListComponent";
import { membersTableHeadersCells } from "../../Constants";
import { Trash2 } from "lucide-react";
import { userApi } from "../../Api";

const getMembersPl = (members: any[], user_id: string, updateCB: any) =>
  members
    .filter(({ deleted_at }) => !deleted_at)
    .map(({ id, member_name, email, status }) => ({
      members_cell_name: member_name,
      members_cell_email: email,
      members_cell_status: i18n._(
        status === 1
          ? "member_status_active"
          : status
          ? "member_status_deleted"
          : "member_status_inactive"
      ),
      members_cell_action: (
        <div className="flex justify-center">
          <Switch
            disabled={status < 1}
            checked={status === 1}
            onChange={() => userApi.deleteMembers(Number(user_id), [id])}
          />
        </div>
      ),
      members_cell_delete: (
        <div className="flex justify-center">
          <button
            className={`text-red-600 hover:text-red-800 transition-colors ${
              status > 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
            }`}
            disabled={status > 1}
            onClick={async () => {
              const updatedMembers = await userApi.deleteMembers(
                Number(user_id),
                [id],
                true
              );
              if (updateCB && updatedMembers) {
                try {
                  updateCB(updatedMembers);
                } catch (e) {}
              }
            }}
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      ),
    }));

export function TableMembersComponent({
  members,
  user_id,
}: {
  members: any;
  user_id: string;
}) {
  const [membersList, setMembersList] = useState(members);

  useEffect(() => {
    setMembersList(members);
  }, [members]);

  return (
    <TableListComponent
      headers={translateHeaders(membersTableHeadersCells)}
      elements={getMembersPl(membersList, user_id, setMembersList)}
    />
  );
}

export default TableMembersComponent;