import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Brand from "../HeaderV2/Brand/Brand";
import { ButtonUploadHistory } from "..";
import { BurgerMenuV2 } from "../BurgerMenu/BurgerMenuV2";
import { appAction } from "../../Store";
import { gradients } from "../../Constants";
import { LoginButtonV2 } from "../Header/Components";

function getThemeClass(id: string) {
  if (id === "custom-0" || id === "custom-1") return id;

  const themeClass: any =
    "0" === id[0]
      ? gradients.clair.find((gradient) => gradient.id === id)
      : gradients.sombre.find((gradient) => gradient.id === id);

  return themeClass?.className || "";
}

function getClassName(app: any) {
  const className = !app[appAction.SHOW_CUSTOM_HEADER]
    ? "normal"
    : getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

  return className;
}

function getClassId(app: any) {
  const classId = !app[appAction.SHOW_CUSTOM_HEADER]
    ? ""
    : app[appAction.SHOW_CUSTOM_HEADER];

  return classId;
}

interface HeaderV3StateProps {
  app: any;
  user: any;
  transfer: {
    transfers: {
      [key: string]: {
        isLocked: boolean;
        isUploadFinished: boolean;
        purging: boolean;
        lastProgressUpdate?: number;
      };
    };
  };
}

interface HeaderV3DispatchProps {
  dispatch: any;
}

interface HeaderV3OwnProps {
  isLogged?: boolean;
}

type HeaderV3Props = HeaderV3StateProps & HeaderV3DispatchProps & HeaderV3OwnProps & RouteComponentProps;

const HeaderV3Component: React.FC<HeaderV3Props> = (props) => {
  const {
    isLogged,
    dispatch,
    app,
    location,
    user
  } = props;

  const [bgMenuOpen, setBgMenuOpen] = useState<boolean>(false);
  const [loginOpen, setLoginOpen] = useState(false);

  if (app["HIDE_HEADER"]) return null;

  // Theme-related logic
  const isCustomOfferPage = location.pathname.includes("/offers") || location.pathname.includes("/login");
  const themeId = getClassId(app);
  
  // Forcer le mode sombre sur toutes les pages
  const isThemeLight = false;

  return (
    <header
      className={`
        fixed top-0 left-0 right-0 z-50
        px-4 lg:px-8
        h-20
        bg-transparent
        transition-all duration-300 ease-in-out
        ${isThemeLight ? 'bg-white bg-opacity-90' : 'bg-gray-900 bg-opacity-80'}
      `}
    >
      {/* Main Content */}
      <div className="h-full container mx-auto">
        <div className="flex items-center justify-between h-full">
          {/* Left Section: Brand */}
          <div className="flex items-center gap-6">
            <Brand mode={isThemeLight ? "light" : undefined} />
          </div>

          {/* Right Section: Actions */}
          <div className="flex items-center gap-8">
            {/* Show ButtonUploadHistory on homepage by default, and on custom pages only when transfer is active */}
            {(() => {
              const hasActiveTransfer = Object.values(props.transfer?.transfers || {})
                .some(t => t.isLocked && !t.isUploadFinished && !t.purging && Date.now() - (t.lastProgressUpdate || 0) < 30000);
              return !isCustomOfferPage || hasActiveTransfer ? <ButtonUploadHistory /> : null;
            })()}

            {/* Login Button */}
            <LoginButtonV2
              onModalChangeCB={setLoginOpen}
              mod={isThemeLight ? "light" : "normal"}
              iconOnly
              defaultOpen={loginOpen}
            />

            {/* Burger Menu */}
            <BurgerMenuV2
              light={isThemeLight}
              onTrigger={setBgMenuOpen}
              backdrop
            />
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: any): HeaderV3StateProps => ({
  app: state.app,
  user: state.user.user,
  transfer: state.transfer
});

const ConnectedHeaderV3 = connect<HeaderV3StateProps, HeaderV3DispatchProps, HeaderV3OwnProps>(
  mapStateToProps
)(withRouter(HeaderV3Component));

export const HeaderV3: React.FC<HeaderV3OwnProps> = (props) => <ConnectedHeaderV3 {...props} />;