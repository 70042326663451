import React, { SyntheticEvent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { X, Trash2, Image, Palette, Check } from "lucide-react";
import { gradients } from "../../../../../../Constants/gradients";
import { i18n } from "../../../../../../utils";
import { BtnComponent } from "../../../../../index";
import { userApi, assetsApi } from "../../../../../../Api";
import * as userAction from "../../../../../../Store/Actions/userAction";
import * as appAction from "../../../../../../Store/Actions/appAction";
import { images } from "../../../../../../assets";
import CropperComponent from "../../../../../Cropper";

interface SwitchProps {
  checked: boolean;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

interface Props {
  dispatch: Function;
  handleChangeSpace: Function;
  user: any;
  isDisabled: boolean;
  title?: string;
}

interface State {
  user: {
    config: {
      rss_feed: string;
      website: string;
      title: string;
      message: string;
      theme: string;
      logo?: any;
      background?: any;
    };
    logo: any;
    background: any;
  };
  isGradientModalOpen: boolean;
  hasChanged: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  isCroppieOpen: boolean;
  isCroppieLoaded: boolean;
  croppieType: string;
  logoError: string | null;
  backgroundError: string | null;
}

class PersonalizationBase extends React.Component<Props, State> {

  private readonly MAX_FILE_SIZE = 1024 * 1024 * 3.5; // 3.5MB pour rester sous la limite de 4MB
  private readonly MAX_WIDTH = 3840; // 4K width
  private readonly MAX_HEIGHT = 2160; // 4K height
  
  private processImage = async (base64: string, fileType: string): Promise<string> => {
    try {
      // Créer une image à partir du base64
      const img = new (window.Image as any)() as HTMLImageElement;
      await new Promise<void>((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = (e) => reject(e);
        img.src = base64;
      });
  
      let width = img.width;
      let height = img.height;
  
      // Redimensionner si l'image dépasse la résolution 4K
      if (width > this.MAX_WIDTH || height > this.MAX_HEIGHT) {
        const ratio = Math.min(this.MAX_WIDTH / width, this.MAX_HEIGHT / height);
        width = Math.floor(width * ratio);
        height = Math.floor(height * ratio);
      }
  
      // Créer un canvas pour le traitement
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Could not get canvas context');
      
      // Appliquer les meilleurs paramètres de qualité
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      
      // Dessiner l'image
      ctx.drawImage(img, 0, 0, width, height);
  
      // Commencer avec la qualité maximale
      let quality = 1.0;
      let output = canvas.toDataURL(fileType, quality);
      let size = Math.ceil((output.length - 22) * 3 / 4);
  
      // Réduire progressivement la qualité si la taille est trop grande
      while (size > this.MAX_FILE_SIZE && quality > 0.5) {
        quality -= 0.1;
        output = canvas.toDataURL(fileType, quality);
        size = Math.ceil((output.length - 22) * 3 / 4);
        console.debug(`[Personalization] Trying quality: ${quality.toFixed(2)}, size: ${(size / 1024 / 1024).toFixed(2)}MB`);
      }
  
      // Si toujours trop grand, essayer une compression plus agressive
      if (size > this.MAX_FILE_SIZE) {
        while (size > this.MAX_FILE_SIZE && quality > 0.1) {
          quality -= 0.05;
          output = canvas.toDataURL(fileType, quality);
          size = Math.ceil((output.length - 22) * 3 / 4);
          console.debug(`[Personalization] Emergency compression - quality: ${quality.toFixed(2)}, size: ${(size / 1024 / 1024).toFixed(2)}MB`);
        }
      }
  
      console.debug(`[Personalization] Final image - quality: ${quality.toFixed(2)}, size: ${(size / 1024 / 1024).toFixed(2)}MB, dimensions: ${width}x${height}`);
      return output;
    } catch (error) {
      console.error('[Personalization] Error processing image:', error);
      throw error;
    }
  };
  
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        config: {
          rss_feed: "",
          website: "",
          title: "",
          message: "",
          theme: "",
          logo: null,
          background: null,
        },
        logo: null,
        background: null,
      },
      isGradientModalOpen: false,
      hasChanged: false,
      isLoading: false,
      isDisabled: true,
      isSuccess: false,
      isCroppieOpen: false,
      isCroppieLoaded: false,
      croppieType: "",
      logoError: null,
      backgroundError: null
    };
  }

  async componentDidMount() {
    console.debug("[Personalization] Component mounted");
    const { user } = this.props;
    if (user) {
      const { config = {}, logo = null, background = null } = user;
      console.debug("[Personalization] Initializing state from props:", { config, logo, background });

      // Initialiser l'état avec les valeurs par défaut si nécessaire
      const initialState = {
        user: {
          config: {
            rss_feed: config.rss_feed || "",
            website: config.url || config.website || "",
            title: config.title || "",
            message: config.message || "",
            theme: config.theme || "",
            logo: logo || config.logo || null,
            background: background || config.background || null,
          },
          logo: logo || config.logo || null,
          background: background || config.background || null,
        },
        isDisabled: false,
      };

      await this.setState(initialState);

      // Appliquer le background approprié au chargement
      try {
        const userBackground = background || config.background;
        if (userBackground?.url) {
          await this.props.dispatch(appAction.setBackground(userBackground.url));
        } else {
          const defaultBackground = await assetsApi.getBackground();
          await this.props.dispatch(appAction.setBackground(defaultBackground));
        }
      } catch (error) {
        console.error("Failed to load background:", error);
        try {
          const defaultBackground = await assetsApi.getBackground();
          await this.props.dispatch(appAction.setBackground(defaultBackground));
        } catch (e) {
          console.error("Failed to load default background:", e);
        }
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.user !== this.props.user) {
      console.debug("[Personalization] User props updated:", this.props.user);
      const { config, logo, background } = this.props.user;
      
      // Vérifier si nous sommes en train de supprimer un gradient ou un background
      const isRemovingGradient = prevProps.user?.config?.theme && !config?.theme;
      const isRemovingBackground = prevProps.user?.background && !background;
      
      this.setState(prevState => {
        // Si nous sommes en train de supprimer un gradient ou un background,
        // conserver les valeurs actuelles de l'état pour ces propriétés
        return {
          user: {
            config: {
              rss_feed: config?.rss_feed || "",
              website: config?.url || config?.website || "",
              title: config?.title || "",
              message: config?.message || "",
              // Conserver une chaîne vide si nous sommes en train de supprimer un gradient
              theme: isRemovingGradient ? "" : (config?.theme || ""),
              logo: logo || config?.logo || null,
              // Conserver null si nous sommes en train de supprimer un background
              background: isRemovingBackground ? null : (background || config?.background || null),
            },
            logo: logo || config?.logo || null,
            background: isRemovingBackground ? null : (background || config?.background || null),
          },
        };
      });
    }
  }

  persistUserUpdates = async () => {
    try {
      console.debug("[Personalization] Starting text updates persistence");
      this.setState({ isLoading: true });

      const { user: stateUser } = this.state;
      const { user: reduxUser } = this.props;

      // Ne mettre à jour que les champs de texte
      const mergedUserData = {
        ...reduxUser,
        config: {
          ...reduxUser.config,
          rss_feed: stateUser.config.rss_feed,
          website: stateUser.config.website,
          title: stateUser.config.title,
          message: stateUser.config.message,
          theme: stateUser.config.theme
        }
      };

      const updatedUserFromServer = await userApi.update(mergedUserData, this.props.user.id);

      const finalUser = {
        ...updatedUserFromServer,
        config: {
          ...updatedUserFromServer.config,
          rss_feed: stateUser.config.rss_feed,
          website: stateUser.config.website,
          title: stateUser.config.title,
          message: stateUser.config.message,
          theme: stateUser.config.theme
        }
      };

      this.props.dispatch(userAction.loginUser(finalUser));

      this.setState({
        isLoading: false,
        hasChanged: false,
        isDisabled: true,
        isSuccess: true
      });

      console.debug("[Personalization] Text updates persisted successfully");
    } catch (err) {
      console.error("[Personalization] Error persisting text updates:", err);
      this.setState({
        isLoading: false,
        isDisabled: false,
        isSuccess: false
      });
    }
  };

  handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    console.debug(`[Personalization] Field changed - ${name}: ${value}`);
    this.setState((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        config: {
          ...prev.user.config,
          [name]: value,
        },
      },
      hasChanged: true,
      isDisabled: false,
      isSuccess: false,
    }));
  };

  checkFileSize = (file: File, type: string): boolean => {
    const maxSize = 3.5 * 1024 * 1024; // 3.5 MB
    if (file.size > maxSize) {
      const errorMessage = `L'image est trop volumineuse (${(file.size / 1024 / 1024).toFixed(2)} MB). La taille maximale est de 3.5 MB.`;
      this.setState({
        [`${type}Error`]: errorMessage
      } as any);
      return false;
    }
    return true;
  };

  openCroppieModal = (croppieType: string) => {
    console.debug(`[Personalization] Opening croppie modal for ${croppieType}`);
    const fileInput = document.getElementById(croppieType) as HTMLInputElement;
    
    if (fileInput?.files?.length) {
      const file = fileInput.files[0];
      if (!this.checkFileSize(file, croppieType)) {
        return;
      }
      
      // Réinitialiser le message d'erreur si la taille est correcte
      this.setState({
        [`${croppieType}Error`]: null
      } as any);
    }

    this.setState({ isCroppieOpen: true, croppieType }, () => {
      document.body.onfocus = this.listenIfCancel;
    });
  };

  closeCroppieModal = () => {
    console.debug("[Personalization] Closing croppie modal");
    this.setState((prev) => ({
      ...prev,
      isCroppieOpen: false,
      isCroppieLoaded: false,
    }));
  };

  onCroppieLoaded = () => {
    console.debug("[Personalization] Croppie loaded");
    this.setState({ isCroppieLoaded: true });
  };

  listenIfCancel = () => {
    setTimeout(() => {
      if (this.state.isCroppieLoaded) return;
      console.debug("[Personalization] Croppie load cancelled");
      this.closeCroppieModal();
    }, 500);
  };

  persistImageUpdate = async (imageType: string, imageData: any) => {
    try {
      console.debug(`[Personalization] Persisting ${imageType} update`, { imageType, imageData });
      this.setState({ isLoading: true });

      const { user: reduxUser } = this.props;
      const mergedUserData = {
        ...reduxUser,
        config: {
          ...reduxUser.config,
        }
      };

      // Si imageData est null, on supprime explicitement l'image
      if (imageData === null) {
        delete mergedUserData.config[imageType];
        delete mergedUserData[imageType];
        mergedUserData.config[imageType] = null;
        mergedUserData[imageType] = null;
      } else {
        mergedUserData.config[imageType] = imageData;
        mergedUserData[imageType] = imageData;
      }

      console.debug(`[Personalization] Merged user data:`, JSON.stringify(mergedUserData, null, 2));

      const updatedUserFromServer = await userApi.update(mergedUserData, this.props.user.id);
      console.debug(`[Personalization] Updated user from server:`, JSON.stringify(updatedUserFromServer, null, 2));
      
      const finalUser = {
        ...updatedUserFromServer,
        [imageType]: imageData,
        config: {
          ...updatedUserFromServer.config,
          [imageType]: imageData,
        },
      };

      // S'assurer que l'image est bien supprimée dans finalUser si imageData est null
      if (imageData === null) {
        delete finalUser[imageType];
        delete finalUser.config[imageType];
      }

      console.debug(`[Personalization] Final user data:`, JSON.stringify(finalUser, null, 2));

      this.props.dispatch(userAction.loginUser(finalUser));
      this.setState({ isLoading: false });

    } catch (err: unknown) {
      console.error(`[Personalization] Error persisting ${imageType} update:`, err);
      if (err instanceof Error && 'response' in err) {
        console.error(`[Personalization] Server responded with:`, JSON.stringify((err as any).response.data, null, 2));
      }
      this.setState({ isLoading: false });
      throw err; // Rethrow the error to be caught by the caller
    }
  };

  handleRemoveGradient = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    console.debug("[Personalization] Removing gradient");
    try {
      const defaultBackground = await assetsApi.getBackground();
      console.debug("[Personalization] Default background fetched:", defaultBackground);

      // Mettre à jour le background par défaut dans Redux
      this.props.dispatch(appAction.setBackground(defaultBackground));
      
      // Réinitialiser uniquement le thème dans l'état local
      this.setState(
        (prev) => ({
          user: {
            ...prev.user,
            config: {
              ...prev.user.config,
              theme: "", // Réinitialiser explicitement le thème
            },
          },
          hasChanged: true,
          isDisabled: false,
          isSuccess: false,
        }),
        async () => {
          console.debug("[Personalization] State updated, persisting changes...");
          try {
            // Sauvegarder uniquement la modification du thème
            await this.persistUserUpdates();
            console.debug("[Personalization] User updates persisted successfully");
            
            // Forcer une mise à jour de l'état pour s'assurer que l'interface est correctement mise à jour
           this.setState({
             user: {
               ...this.state.user,
               config: {
                 ...this.state.user.config,
                 theme: "" // S'assurer que le thème est bien vide
               }
             },
             hasChanged: false,
             isDisabled: true,
             isSuccess: true,
             isLoading: false
           });
           
           // Forcer un rafraîchissement du composant
           this.forceUpdate();
          } catch (error) {
            console.error("[Personalization] Error in handleRemoveGradient callback:", error);
            this.setState({
              isLoading: false,
              isDisabled: false,
              isSuccess: false
            });
          }
        }
      );
    } catch (err: unknown) {
      console.error("[Personalization] Error removing gradient:", err);
      
      // En cas d'erreur, s'assurer que l'état local est correctement mis à jour
      this.setState({
        user: {
          ...this.state.user,
          config: {
            ...this.state.user.config,
            theme: "" // Réinitialiser quand même le thème pour éviter l'affichage du gradient
          }
        },
        isLoading: false,
        hasChanged: false,
        isDisabled: true,
        isSuccess: false,
      });
      
      // Essayer de mettre à jour le background par défaut même en cas d'erreur
      try {
        const fallbackBackground = await assetsApi.getBackground();
        this.props.dispatch(appAction.setBackground(fallbackBackground));
      } catch (e) {
        console.error("[Personalization] Error getting fallback background:", e);
      }
    }
  };

  handleRemoveBg = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    console.debug("[Personalization] Removing background");
    try {
      const defaultBackground = await assetsApi.getBackground();
      console.debug("[Personalization] Default background fetched:", defaultBackground);

      // Mettre à jour le background par défaut dans Redux
      this.props.dispatch(appAction.setBackground(defaultBackground));
      
      // Réinitialiser le background et le thème dans l'état local
      this.setState(
        (prev) => ({
          user: {
            ...prev.user,
            background: null,
            config: {
              ...prev.user.config,
              background: null,
              theme: "", // Réinitialiser explicitement le thème
            },
          },
          hasChanged: true,
          isDisabled: false,
          isSuccess: false,
        }),
        async () => {
          console.debug("[Personalization] State updated, persisting changes...");
          try {
            // Mettre à jour le backend
            await this.persistImageUpdate("background", null);
            console.debug("[Personalization] Image update persisted successfully");
            
            // Sauvegarder les modifications du thème
            await this.persistUserUpdates();
            console.debug("[Personalization] User updates persisted successfully");
            
            // Notifier les autres composants
            await this.props.handleChangeSpace("UPDATE", null, "background");
            console.debug("[Personalization] handleChangeSpace called successfully");
            
            // Forcer une mise à jour de l'état pour s'assurer que l'interface est correctement mise à jour
            this.setState({
              user: {
                ...this.state.user,
                background: null,
                config: {
                  ...this.state.user.config,
                  background: null,
                  theme: "" // S'assurer que le thème est bien vide
                }
              },
              hasChanged: false,
              isDisabled: true,
              isSuccess: true,
              isLoading: false
            });
            
            // Forcer un rafraîchissement du composant
            this.forceUpdate();
          } catch (error) {
            console.error("[Personalization] Error in handleRemoveBg callback:", error);
            this.setState({
              isLoading: false,
              isDisabled: false,
              isSuccess: false
            });
          }
        }
      );
    } catch (err: unknown) {
      console.error("[Personalization] Error removing background:", err);
      
      // En cas d'erreur, s'assurer que l'état local est correctement mis à jour
      this.setState({
        user: {
          ...this.state.user,
          background: null,
          config: {
            ...this.state.user.config,
            background: null,
            theme: "" // Réinitialiser quand même le thème et le background pour éviter l'affichage
          }
        },
        isLoading: false,
        hasChanged: false,
        isDisabled: true,
        isSuccess: false,
      });
      
      // Essayer de mettre à jour le background par défaut même en cas d'erreur
      try {
        const fallbackBackground = await assetsApi.getBackground();
        this.props.dispatch(appAction.setBackground(fallbackBackground));
      } catch (e) {
        console.error("[Personalization] Error getting fallback background:", e);
      }
    }
  };

  handleCropResult = async (evt: any) => {
    try {
      console.debug("[Personalization] Handling crop result");
      const canvasId = evt.target.attributes["data-crop"].value;
      const canvasDom: HTMLCanvasElement | null = document.querySelector(`#${canvasId}`);
      const inputDom: HTMLInputElement | null = document.querySelector(`#${this.state.croppieType}`);
  
      if (!canvasDom || !inputDom?.files?.[0]) {
        throw new Error("Missing elements for image processing");
      }
  
      const fileType = inputDom.files[0].type || 'image/jpeg';
      const fileName = inputDom.files[0].name;
  
      // Obtenir l'image du canvas et la traiter
      const initialBase64 = canvasDom.toDataURL(fileType);
      const processedBase64 = await this.processImage(initialBase64, fileType);
      
      // Calculer la taille approximative
      const size = Math.ceil((processedBase64.length - 22) * 3 / 4);
      console.debug(`[Personalization] Processed image size: ${(size / 1024 / 1024).toFixed(2)}MB`);
  
      const s3Bucket = 'filevert-user-assets';
      const extension = fileType.split('/')[1] || 'png';
      const timestamp = Date.now();
      const s3Key = `${this.state.croppieType}s/${this.props.user.id}/${timestamp}.${extension}`;
  
      const newImageData = {
        file: processedBase64,
        name: fileName,
        url: processedBase64,
        type: fileType,
        size: size,
        lastModified: Date.now(),
        bucket: s3Bucket,
        key: s3Key,
        extension: extension,
        s3Params: {
          Bucket: s3Bucket,
          ACL: 'public-read',
          ContentType: fileType,
          CacheControl: 'max-age=31536000',
          Metadata: {
            'user-id': this.props.user.id.toString(),
            'file-type': this.state.croppieType,
            'file-extension': extension
          }
        }
      };
  
      // Le reste de votre code reste identique
      await this.props.handleChangeSpace("UPDATE", newImageData, this.state.croppieType);
      
      this.setState(
        (prev) => ({
          isCroppieOpen: false,
          isCroppieLoaded: false,
          user: {
            ...prev.user,
            [this.state.croppieType]: newImageData,
            config: {
              ...prev.user.config,
              [this.state.croppieType]: newImageData,
              // CHANGED: Set theme to "custom-0" (light text) by default when adding a background
              ...(this.state.croppieType === "background" && { theme: "custom-0" }),
            },
          },
        }),
        async () => {
          await this.persistImageUpdate(this.state.croppieType, newImageData);
          if (this.state.croppieType === "background") {
            this.props.dispatch(appAction.setBackground(newImageData.url));
          }
        }
      );
    } catch (error) {
      console.error("[Personalization] Error handling crop result:", error);
      this.closeCroppieModal();
    }
  };

  handleRemoveLogo = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    console.debug("[Personalization] Removing logo");
    this.setState(
      (prev) => ({
        user: {
          ...prev.user,
          logo: null,
          config: {
            ...prev.user.config,
            logo: null,
          },
        },
      }),
      () => {
        console.debug("[Personalization] Logo removed, persisting...");
        this.persistImageUpdate("logo", null);
      }
    );
  };

  resetSpace = async (evt: SyntheticEvent<HTMLButtonElement> | any) => {
    evt.preventDefault();
    evt.stopPropagation();
    console.debug("[Personalization] Resetting space to default");
    
    try {
      this.setState({ isLoading: true });

      // 1. Récupérer le background par défaut
      const defaultBackground = await assetsApi.getBackground();

      // 2. Préparer les données de réinitialisation
      const resetData = {
        config: {
          rss_feed: "",
          website: "",
          title: "",
          message: "",
          theme: "",
          logo: null,
          background: null,
        },
        logo: null,
        background: null,
      };

      // 3. Mettre à jour l'utilisateur sur le serveur
      const updatedUser = await userApi.update(resetData, this.props.user.id);

      // 4. Informer Account.tsx du reset
      await this.props.handleChangeSpace("RESET", null, "background");

      // 5. Mettre à jour Redux avec les données réinitialisées
      await this.props.dispatch(userAction.loginUser({
        ...updatedUser,
        ...resetData
      }));

      // 6. Mettre à jour l'état local
      this.setState({
        user: {
          ...resetData
        },
        isLoading: false,
        hasChanged: false,
        isDisabled: true,
        isSuccess: true,
      });

      // 7. Appliquer le background par défaut
      await this.props.dispatch(appAction.setBackground(defaultBackground));
      console.debug("[Personalization] Reset completed successfully");
    } catch (err) {
      console.error("[Personalization] Error resetting space:", err);
      // En cas d'erreur, forcer l'application du background par défaut
      try {
        const fallbackBackground = await assetsApi.getBackground();
        this.props.dispatch(appAction.setBackground(fallbackBackground));
      } catch (e) {
        console.error("[Personalization] Error getting fallback background:", e);
      }
      this.setState({ isLoading: false });
    }
  };

  openGradientModal = () => {
    console.debug("[Personalization] Opening gradient modal");
    this.setState({ isGradientModalOpen: true, isDisabled: false });
  };

  closeGradientModal = () => {
    console.debug("[Personalization] Closing gradient modal");
    this.setState({ isGradientModalOpen: false });
  };

  handleSelectGradient = async (gradientId: string) => {
    console.debug(`[Personalization] Selected gradient: ${gradientId}`);
    try {
      // Récupérer le background par défaut
      const defaultBackground = await assetsApi.getBackground();

      this.setState(
        (prev) => ({
          ...prev,
          user: {
            ...prev.user,
            background: null,
            config: {
              ...prev.user.config,
              theme: gradientId,
              background: null,
            },
          },
          hasChanged: true,
          isDisabled: false,
          isSuccess: false,
        }),
        async () => {
          console.debug("[Personalization] Gradient selected, persisting...");
          await this.persistUserUpdates();
          // Mettre à jour le background dans le store Redux
          this.props.dispatch(appAction.setBackground(defaultBackground));
          this.setState({ isGradientModalOpen: false });
        }
      );
    } catch (error) {
      console.error("[Personalization] Error selecting gradient:", error);
      this.setState({
        isLoading: false,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
      });
    }
  };

  parseImageData = (imageData: any) => {
    if (!imageData) return null;
    
    let parsedData = imageData;
    if (typeof imageData === "string") {
      try {
        parsedData = JSON.parse(imageData);
      } catch {
        parsedData = {
          file: imageData,
          url: imageData
        };
      }
    }

    const bucket = parsedData.bucket || 'filevert-user-assets';
    const key = parsedData.key || `users/${this.props.user.id}/${parsedData.type === 'logo' ? 'logos' : 'backgrounds'}/${Date.now()}.${parsedData.type?.split('/')[1] || 'png'}`;
    
    const baseUrl = `https://${bucket}.s3.amazonaws.com/${key}`;
    
    return {
      file: parsedData.file || parsedData.url,
      name: parsedData.name || "image",
      url: parsedData.url ? parsedData.url.includes('http') ? parsedData.url : baseUrl : baseUrl,
      type: parsedData.type || 'image/png',
      size: parsedData.size || 0,
      lastModified: parsedData.lastModified || Date.now(),
      bucket,
      key,
      s3Params: {
        Bucket: bucket,
        ACL: 'public-read',
        ContentType: parsedData.type || 'image/png',
        CacheControl: 'max-age=31536000'
      }
    };
  };

  wrapLogoName = (logoName: string) => (logoName.length > 33 ? `${logoName.substr(0, 30)}...` : logoName);

  render() {
    const { isDisabled } = this.props;
    const { isCroppieOpen, isCroppieLoaded, croppieType, isGradientModalOpen } = this.state;

    let { logo, background } = this.state.user;

    if (typeof logo === "string" && logo.length > 10) {
      logo = JSON.parse(logo);
    }
    if (typeof background === "string" && background.length > 10) {
      background = JSON.parse(background);
    }

    let logoSrc = logo?.file || logo?.url;
    logoSrc += !logoSrc || logoSrc.includes("base64") ? "" : `?${Date.now()}`;

    let backgroundSrc = background?.file || background?.url;
    backgroundSrc += !backgroundSrc || backgroundSrc.includes("base64") ? "" : `?${Date.now()}`;

    const hasCustomizationPermission = this.props.user?.permissions?.customization?.value;
    if (!hasCustomizationPermission) return null;

    return (
      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
        {/* Modal de recadrage du logo */}
        {isCroppieOpen && croppieType === "logo" && (
          <div className={`fixed inset-0 z-50 overflow-y-auto ${!isCroppieLoaded && "hidden"}`}>
            <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"></div>
            <div className="flex min-h-full items-center justify-center p-4">
              <div className="relative bg-white dark:bg-neutral-800 rounded-xl shadow-xl max-w-2xl w-full">
                <div className="flex justify-between items-center p-4 md:p-5 border-b dark:border-neutral-700">
                  <h3 className="text-lg font-semibold text-primary">
                    {i18n._("modal_crop_logo_title") || "Recadrage du logo"}
                  </h3>
                  <button 
                    onClick={this.closeCroppieModal} 
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <X className="h-6 w-6" size={24} color="currentColor" />
                  </button>
                </div>
                <div className="p-6">
                  <CropperComponent
                    canvasId="logo_canvas"
                    automatic
                    onLoadCb={this.onCroppieLoaded}
                    originImg={this.state.user?.logo?.file}
                    input={{
                      className: "file-input w-full hidden",
                      disabled: isDisabled,
                      accept: ".png, .jpg, .jpeg, .avif",
                      id: "logo",
                      type: "file",
                      name: "logo",
                    }}
                  />
                </div>
                <div className="flex justify-center p-4 md:p-5 border-t dark:border-neutral-700">
                  <button
                    onClick={this.handleCropResult}
                    data-crop="logo_canvas"
                    className="px-5 py-2.5 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors shadow-sm"
                  >
                    {i18n._(`btn_crop_logo_label`) || "Valider le recadrage"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal de recadrage de l'arrière-plan */}
        {isCroppieOpen && croppieType === "background" && (
          <div className={`fixed inset-0 z-50 overflow-y-auto ${!isCroppieLoaded && "hidden"}`}>
            <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"></div>
            <div className="flex min-h-full items-center justify-center p-4">
              <div className="relative bg-white dark:bg-neutral-800 rounded-xl shadow-xl max-w-2xl w-full">
                <div className="flex justify-between items-center p-4 md:p-5 border-b dark:border-neutral-700">
                  <h3 className="text-lg font-semibold text-primary">
                    {i18n._("modal_crop_background_title") || "Recadrage de l'arrière-plan"}
                  </h3>
                  <button 
                    onClick={this.closeCroppieModal} 
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
                <div className="p-6">
                  <CropperComponent
                    canvasId="background_canvas"
                    ratios={[{ aspect: 16 / 9, label: "16/9" }]}
                    onLoadCb={this.onCroppieLoaded}
                    originImg={this.state.user?.background?.file}
                    input={{
                      className: "file-input w-full hidden",
                      disabled: isDisabled,
                      accept: ".png, .jpg, .jpeg, .avif",
                      id: "background",
                      type: "file",
                      name: "background",
                    }}
                  />
                </div>
                <div className="flex justify-center p-4 md:p-5 border-t dark:border-neutral-700">
                  <button
                    onClick={this.handleCropResult}
                    data-crop="background_canvas"
                    className="px-5 py-2.5 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors shadow-sm"
                  >
                    {i18n._(`btn_crop_background_label`) || "Valider le recadrage"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mb-8">
          <h1 className="text-3xl font-bold text-primary">
            {i18n._("account_personalization_title")}
          </h1>
        </div>

        <div className="space-y-12">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
              {i18n._("account_personalization_preview")}
              <span
                className={`ml-4 ${isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:opacity-80 transition-opacity"}`}
                onClick={isDisabled ? undefined : this.resetSpace}
                title={i18n._("btn_reset_label")}
              >
                <img alt="icon-reset" className="h-6" src={images.ResetIcon} />
              </span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Section Logo */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="p-4 md:p-5">
                  <div className="flex items-center gap-x-2 mb-4">
                    <p className="text-xs uppercase tracking-wide text-gray-500">Logo</p>
                  </div>
                  <div className="flex flex-col items-center justify-center min-h-[200px] bg-gray-50 dark:bg-neutral-900 rounded-lg p-4">
                    {logo && logo.name ? (
                      <div className="relative">
                        <img 
                          alt="logo-space" 
                          className="max-h-32 object-contain" 
                          src={logoSrc} 
                        />
                        <button
                          onClick={this.handleRemoveLogo}
                          className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors shadow-md"
                        >
                          <Trash2 size={14} color="currentColor" />
                        </button>
                        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-green-500 text-white rounded-full p-1.5 shadow-md">
                          <Check size={14} />
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="logo"
                        className="flex flex-col items-center cursor-pointer p-4 hover:bg-gray-100 dark:hover:bg-neutral-700/20 transition-colors rounded-lg"
                        onClick={() => {
                          if (!isDisabled) this.openCroppieModal("logo");
                        }}
                      >
                        <Image className="h-12 w-12 text-primary mb-3" size={48} color="currentColor" />
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          {i18n._("form_profil_logo_label")}
                        </span>
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          accept=".png, .jpg, .jpeg"
                          className="hidden"
                          onChange={(e) => {
                            if (!isDisabled && e.target.files && e.target.files.length > 0) {
                              const file = e.target.files[0];
                              const maxSize = 3.5 * 1024 * 1024;
                              if (file.size > maxSize) {
                                this.setState({
                                  logoError: `L'image est trop volumineuse (${(file.size / 1024 / 1024).toFixed(2)} MB). La taille maximale est de 3.5 MB.`
                                });
                                e.target.value = '';
                                return;
                              }
                              this.setState({ logoError: null });
                              this.openCroppieModal("logo");
                            }
                          }}
                        />
                      </label>
                    )}
                  </div>
                  {this.state.logoError && (
                    <div className="mt-2 px-3 py-2 text-sm text-red-600 bg-red-50 dark:bg-red-900/10 dark:text-red-400 rounded-lg border border-red-200 dark:border-red-800/20 text-center">
                      {this.state.logoError}
                    </div>
                  )}
                  <small className="block mt-2 italic text-gray-500 text-center">
                    {i18n._("logo_size_recommendations")}
                  </small>
                </div>
              </div>

              {/* Section Fond d'écran */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="p-4 md:p-5">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">Fond d'écran</p>
                      <p className="text-sm text-gray-700 dark:text-gray-300">
                        {i18n._("form_profil_add_gradient_label")} {" "}
                        <span 
                          className={`text-primary ${isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:underline"}`}
                          onClick={isDisabled ? () => null : this.openGradientModal}
                        >
                          {i18n._("form_profil_gradient_label")}
                        </span>
                      </p>
                      <Palette 
                        onClick={isDisabled ? () => null : this.openGradientModal}
                        className={`text-primary ${isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                        size={20}
                        color="currentColor"
                      />
                      {this.state.user?.config?.theme && (
                        <span className="text-xs text-gray-500">
                          {gradients.clair.concat(gradients.sombre).find(g => g.id === this.state.user?.config?.theme)?.text || ""}
                        </span>
                      )}
                    </div>
                    {background && background?.name && (
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs text-gray-500">
                          {this.state.user?.config?.theme === "custom-1" ? "Texte sombre" : "Texte clair"}
                        </span>
                        <Switch
                          checked={
                            !this.state.user?.config?.theme ||
                            this.state.user?.config?.theme === "custom-0"
                          }
                          onChange={async () => {
                            if (!isDisabled) {
                              const newTheme =
                                this.state.user?.config?.theme === "custom-0" ? "custom-1" : "custom-0";
                              
                              // Préserver le background personnalisé
                              const currentBackground = this.state.user.background;
                              
                              this.setState(
                                (prev) => ({
                                  user: {
                                    ...prev.user,
                                    background: currentBackground,
                                    config: {
                                      ...prev.user.config,
                                      theme: newTheme,
                                      background: currentBackground,
                                    },
                                  },
                                  hasChanged: true,
                                  isDisabled: false,
                                  isSuccess: false,
                                }),
                                () => {
                                  this.persistUserUpdates();
                                }
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center min-h-[200px] bg-gray-50 dark:bg-neutral-900 rounded-lg p-4">
                    {background && background.name ? (
                      <div className="relative w-full">
                        <img 
                          alt="background-space" 
                          className="w-full h-32 object-cover rounded-lg shadow-sm border border-gray-100 dark:border-neutral-700" 
                          src={backgroundSrc} 
                        />
                        <button
                          onClick={this.handleRemoveBg}
                          className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors shadow-md"
                        >
                          <Trash2 size={14} />
                        </button>
                        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-green-500 text-white rounded-full p-1.5 shadow-md">
                          <Check size={14} color="currentColor" />
                        </div>
                      </div>
                    ) : this.state.user.config.theme && this.state.user.config.theme !== "" && this.state.user.config.theme.length > 0 && this.state.user.config.theme !== "undefined" && this.state.user.config.theme !== "null" ? (
                      <div className="relative w-full">
                        {/* Aperçu du gradient sélectionné */}
                        <div
                          className={`w-full h-32 rounded-lg shadow-sm ${
                            gradients.clair.concat(gradients.sombre).find(g => g.id === this.state.user.config.theme)?.className || ""
                          }`}
                        />
                        {/* Bouton de suppression */}
                        <button
                          onClick={this.handleRemoveGradient}
                          className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors shadow-md"
                        >
                          <Trash2 size={14} />
                        </button>
                        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-green-500 text-white rounded-full p-1.5 shadow-md">
                          <Check size={14} color="currentColor" />
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="background"
                        className="flex flex-col items-center cursor-pointer p-4 hover:bg-gray-100 dark:hover:bg-neutral-700/20 transition-colors rounded-lg"
                        onClick={() => {
                          if (!isDisabled) this.openCroppieModal("background");
                        }}
                      >
                        <Image className="h-12 w-12 text-primary mb-3" />
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          {i18n._("form_profil_background_label")}
                        </span>
                        <input
                          type="file"
                          id="background"
                          name="background"
                          accept=".png, .jpg, .jpeg"
                          className="hidden"
                          onChange={(e) => {
                            if (!isDisabled && e.target.files && e.target.files.length > 0) {
                              const file = e.target.files[0];
                              const maxSize = 3.5 * 1024 * 1024;
                              if (file.size > maxSize) {
                                this.setState({
                                  backgroundError: `L'image est trop volumineuse (${(file.size / 1024 / 1024).toFixed(2)} MB). La taille maximale est de 3.5 MB.`
                                });
                                e.target.value = '';
                                return;
                              }
                              this.setState({ backgroundError: null });
                              this.openCroppieModal("background");
                            }
                          }}
                        />
                      </label>
                    )}
                  </div>
                  <small className="block mt-2 italic text-gray-500 text-center">
                    {i18n._("background_size_recommendations")}
                  </small>
                  {this.state.backgroundError && (
                    <div className="mt-2 px-3 py-2 text-sm text-red-600 bg-red-50 dark:bg-red-900/10 dark:text-red-400 rounded-lg border border-red-200 dark:border-red-800/20 text-center">
                      {this.state.backgroundError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <div className="flex justify-center sm:justify-end mb-6">
            <BtnComponent
              outline={false}
              background="filled"
              className="uppercase shadow-sm hover:shadow-md transition-shadow w-full sm:w-auto bg-primary text-white"
              onClick={this.persistUserUpdates}
              text={i18n._("btn_save_label")}
              isLoading={this.state.isLoading}
              hasChanged={this.state.hasChanged}
              isDisabled={!this.state.hasChanged || this.state.isLoading}
              isSuccess={this.state.isSuccess}
            />
          </div>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
              {i18n._("account_personalization_options")}
            </h2>

            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                    {i18n._("form_profil_title_label")}
                  </label>
                  <input
                    disabled={isDisabled}
                    placeholder={i18n._("form_profil_title_label")}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-neutral-900 dark:border-neutral-700"
                    onChange={this.handleChange}
                    type="text"
                    name="title"
                    value={this.state.user.config.title || ""}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                    {i18n._("form_profil_message_label")}
                  </label>
                  <textarea
                    disabled={isDisabled}
                    placeholder={i18n._("form_profil_message_label")}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-neutral-900 dark:border-neutral-700"
                    rows={5}
                    onChange={this.handleChange}
                    name="message"
                    value={this.state.user.config.message || ""}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                    {i18n._("form_profil_site_label")}
                  </label>
                  <input
                    disabled={isDisabled}
                    placeholder={i18n._("form_profil_site_label")}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-neutral-900 dark:border-neutral-700"
                    onChange={this.handleChange}
                    type="url"
                    name="website"
                    value={this.state.user.config.website || ""}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                    {i18n._("form_profil_rss_feed_label")}
                  </label>
                  <input
                    disabled={isDisabled}
                    placeholder={i18n._("form_profil_rss_feed_label")}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-neutral-900 dark:border-neutral-700"
                    onChange={this.handleChange}
                    type="text"
                    name="rss_feed"
                    value={this.state.user.config.rss_feed || ""}
                  />
                  <small className="block mt-1 text-gray-500 italic">
                    {i18n._("form_profil_rss_feed_warn")}
                  </small>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Modal de sélection de gradient */}
        {isGradientModalOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" onClick={this.closeGradientModal}></div>
            <div className="flex min-h-full items-center justify-center p-4">
              <div className="relative bg-white dark:bg-neutral-800 rounded-xl shadow-xl max-w-4xl w-full">
                <div className="flex justify-between items-center p-4 md:p-5 border-b dark:border-neutral-700">
                  <h3 className="text-lg font-semibold text-primary">
                    {i18n._("modal_select_gradient_title") || "Sélectionner un gradient"}
                  </h3>
                  <button onClick={this.closeGradientModal} className="text-gray-500 hover:text-gray-700 transition-colors">
                    <X className="h-6 w-6" />
                  </button>
                </div>
                <div className="p-6">
                  <div className="mb-8">
                    <h4 className="text-lg font-medium mb-4 text-gray-800 dark:text-neutral-200">
                      {i18n._("gradients_light_title") || "Thèmes clairs"}
                    </h4>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {gradients.clair.map((gradient) => (
                        <button
                          key={gradient.id}
                          onClick={() => this.handleSelectGradient(gradient.id)}
                          className={`relative p-4 rounded-lg border-2 transition-all ${
                            this.state.user.config.theme === gradient.id
                              ? "border-primary"
                              : "border-transparent hover:border-gray-300 dark:hover:border-neutral-600"
                          }`}
                        >
                          <div className={`h-24 rounded-lg ${gradient.className} mb-2`} />
                          <span className="block text-sm font-medium">{gradient.text}</span>
                          {this.state.user.config.theme === gradient.id && (
                            <div className="absolute top-2 right-2 bg-primary rounded-full p-1">
                              <Check className="h-4 w-4 text-white" />
                            </div>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium mb-4 text-gray-800 dark:text-neutral-200">
                      {i18n._("gradients_dark_title") || "Thèmes sombres"}
                    </h4>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {gradients.sombre.map((gradient) => (
                        <button
                          key={gradient.id}
                          onClick={() => this.handleSelectGradient(gradient.id)}
                          className={`relative p-4 rounded-lg border-2 transition-all ${
                            this.state.user.config.theme === gradient.id
                              ? "border-primary"
                              : "border-transparent hover:border-gray-300 dark:hover:border-neutral-600"
                          }`}
                        >
                          <div className={`h-24 rounded-lg ${gradient.className} mb-2`} />
                          <span className="block text-sm font-medium">{gradient.text}</span>
                          {this.state.user.config.theme === gradient.id && (
                            <div className="absolute top-2 right-2 bg-primary rounded-full p-1">
                              <Check className="h-4 w-4 text-white" />
                            </div>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// Composant Switch harmonisé
const Switch = ({ checked, onChange }: SwitchProps) => (
  <label className="relative inline-block w-14 h-8 cursor-pointer">
    <input
      type="checkbox"
      className="hidden"
      checked={checked}
      onChange={onChange}
    />
    <div
      className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 
        transition-colors duration-200 rounded-full
        ${checked ? 'bg-primary' : 'bg-gray-300 dark:bg-neutral-600'}`}
    >
      <div
        className={`absolute top-1 left-1 bg-white w-6 h-6 rounded-full shadow-sm
          transition-transform duration-200 ease-in-out
          ${checked ? 'transform translate-x-6' : ''}`}
      />
    </div>
  </label>
);

const mapStateToProps = (state: any) => {
  console.debug("[Personalization] mapStateToProps state:", state);
  return {
    user: state.user.user,
  };
};

const PersonalizationComponent = connect(mapStateToProps)(PersonalizationBase);
export default PersonalizationComponent;