import React, { useState, PropsWithChildren } from 'react';

export interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  width?: string;
  showOnHover?: boolean;
  loading?: boolean;
}

const TooltipComponent: React.FC<TooltipProps> = ({ 
  content,
  children,
  className = '',
  position = 'top',
  width = 'auto',
  showOnHover = true,
  loading = false
}) => {
  const [show, setShow] = useState(false);

  const positionClasses = {
    top: 'bottom-[calc(100%+5px)] left-1/2 -translate-x-1/2',
    bottom: 'top-[calc(100%+5px)] left-1/2 -translate-x-1/2',
    left: 'right-[calc(100%+5px)] top-1/2 -translate-y-1/2',
    right: 'left-[calc(100%+5px)] top-1/2 -translate-y-1/2'
  };

  const arrowClasses = {
    top: 'bottom-[-4px] left-1/2 -translate-x-1/2',
    bottom: 'top-[-4px] left-1/2 -translate-x-1/2',
    left: 'right-[-4px] top-1/2 -translate-y-1/2',
    right: 'left-[-4px] top-1/2 -translate-y-1/2'
  };

  return (
    <div 
      className={`relative inline-block overflow-visible ${showOnHover ? 'group' : ''}`}
      onMouseEnter={() => !showOnHover && setShow(true)}
      onMouseLeave={() => !showOnHover && setShow(false)}
      onFocus={() => !showOnHover && setShow(true)}
      onBlur={() => !showOnHover && setShow(false)}
    >
      {children}
      <div 
        role="tooltip"
        className={`
          absolute z-[9999] ${positionClasses[position]}
          ${showOnHover ? 'opacity-0 invisible group-hover:opacity-100 group-hover:visible' : show ? 'opacity-100 visible' : 'opacity-0 invisible'}
          transition-all duration-200 ease-in-out
          bg-base-300 min-w-[150px] text-white
          rounded-lg shadow-lg
          backdrop-blur-sm
          border border-white/10
          ${className}
        `}
        style={{ width }}
      >
        <div className="relative">
          <div className="p-4">
            {loading ? (
              <div className="custom-pulse space-y-3">
                <div className="h-4 bg-gray-200 dark:bg-neutral-800 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 dark:bg-neutral-800 rounded w-1/2"></div>
              </div>
            ) : content}
          </div>
          <div 
            className={`
              absolute w-2 h-2 
              bg-base-300
              transform rotate-45
              ${arrowClasses[position]}
            `}
          />
        </div>
      </div>
    </div>
  );
};

export default TooltipComponent;
