import React from "react";
import { i18n } from "../utils";
import { appAction } from "../Store/Actions";
import { ChevronDown } from "lucide-react";

const LangComponent = ({
  dispatch,
  textClass = "text-white",
}: {
  dispatch: Function;
  textClass?: string;
}) => {
  const langChoices = i18n.getLangChoices();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value;
    i18n.changeLang(lang);
    dispatch(appAction.changeLang(lang));
  };

  return (
    <div className="my-auto relative">
      <select 
        className={`select select-bordered select-sm w-20 ${textClass} bg-transparent border-none focus:outline-none`}
        value={i18n.getLang()}
        onChange={handleChange}
      >
        {langChoices.map((lang: string, key: number) => (
          <option key={key} value={lang}>
            {lang.split("_")[1]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LangComponent;