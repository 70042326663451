import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { userApi } from "../../Api";
import { i18n } from "../../utils";
import { Mail } from "lucide-react";

interface State {
  isSubmitted: boolean;
  resetError: null | string;
  email: string;
  isLoading: boolean;
}

const ResetPasswordScreen: React.FC = () => {
  const history = useHistory();
  const [state, setState] = useState<State>({
    isSubmitted: false,
    resetError: null,
    email: "",
    isLoading: false
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [evt.target.name]: evt.target.value });

  const handleResetLink = async () => {
    if (!state.email) {
      return setState({
        ...state,
        resetError: "Veuillez entrer votre adresse email"
      });
    }

    try {
      setState(prev => ({ ...prev, isLoading: true }));

      const user = await userApi.sendResetLink({ email: state.email });

      setState({
        ...state,
        isSubmitted: true,
        resetError: user.error ? user.error.message : null,
        isLoading: false
      });

    } catch (error) {
      console.error('Error sending reset link:', error);
      setState({
        ...state,
        isLoading: false,
        resetError: "Une erreur est survenue lors de l'envoi du lien"
      });
    }
  };

  const handleBack = () => {
    history.push('/login');
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4 py-12 bg-gray-900">
      <div className="max-w-md w-full space-y-8 bg-gray-800/90 backdrop-blur-sm p-8 rounded-lg shadow-2xl border border-gray-700">
        <div>
          <h2 className="text-2xl font-bold text-center text-gray-100">
            {i18n._("forgotten_password_label")}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            {i18n._("reset_password_description")}
          </p>
        </div>

        {state.isSubmitted && !state.resetError && (
          <div className="mb-4 p-4 bg-green-900/50 test-emerald-500-200 rounded-md border border-green-500/50">
            {i18n._("reset_link_sent_label")}
          </div>
        )}

        {state.resetError && (
          <div className="mb-4 p-4 bg-red-900/50 text-red-200 rounded-md border border-red-500/50">
            {state.resetError}
          </div>
        )}

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              {i18n._("form_login_email_label")}
            </label>
            <div className="relative mt-1">
              <Mail className="absolute left-3 top-2.5 text-gray-400" size={18} />
              <input
                className="block w-full pl-10 pr-3 py-2 bg-gray-700/50 border border-gray-600 rounded-md focus:ring-emerald-500 focus:border-emerald-500 text-gray-100 placeholder-gray-400"
                name="email"
                type="email"
                value={state.email}
                onChange={handleChange}
                placeholder="exemple@email.com"
                disabled={state.isLoading || state.isSubmitted}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <button
              onClick={handleResetLink}
              disabled={state.isLoading || state.isSubmitted}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {state.isLoading ? (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
              ) : (
                i18n._("reset_password_label")
              )}
            </button>

            <button
              onClick={handleBack}
              disabled={state.isLoading}
              className="w-full flex justify-center py-2 px-4 border border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-300 bg-transparent hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Retour à la connexion
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;