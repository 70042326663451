import React, {
  useCallback,
  useMemo,
  useState,
  useEffect
} from "react";
import { useDispatch } from "react-redux";
import {
  Lock,
  UserCheck,
  PlusCircle,
  Send,
  Rocket,
  X,
  Eye,
  EyeOff,
  RefreshCw,
  Trash2,
  Copy,
  Check
} from "lucide-react";
import { appAction, transferAction } from "../../../../Store/Reducers";
import EmailFormComponent from "./UploaderEmailForm";
import { transferApi, userApi } from "../../../../Api";
import { convertSize, i18n } from "../../../../utils";
import { Go } from "../../../../Constants";
import Toast from "../../../Toast/Toast";

// Clé pour stocker la méthode d'envoi préférée dans localStorage
const UPLOAD_METHOD_STORAGE_KEY = 'filevert_upload_method_preference';

interface TransferInfosModel {
  id?: string;
  transfer_type?: number;
  transfer_name: string;
  transfer_message: string;
  transfer_password: string;
  transfer_confirm_password: string;
  recipients: string[];
  for_team: boolean;
  auto_remove: boolean;
  auto_send: boolean;
  transfer_state?: "pending" | "completed" | "error";
}

type TransferPayload = Omit<
  TransferInfosModel,
  "transfer_confirm_password" | "transfer_state"
> & {
  transfer_notification: boolean;
  transfer_id?: string;
  transfer_sender?: string;
};

interface UploaderFormProps {
  transfer: any;
  user: {
    role: number;
    id?: string;
    members?: any[];
    permissions?: {
      transfer_authentication?: {
        value: boolean;
      };
    };
    referer_id?: string;
    email?: string;
  };
  file: any[];
  submitCb: (transferResult: any) => void;
  app: {
    APP_ERROR: any;
    USER_UPLOAD_FAVORITE_METHOD?: number;
  };
  user_upload_size: {
    per_transfer_limit: number;
    total_transfer_limit: number;
  };
  transferInfos: TransferInfosModel;
  setTransferInfos: React.Dispatch<React.SetStateAction<TransferInfosModel>>;
}

// Hook personnalisé pour gérer le focus
const useFormFocus = () => {
  const preventFocusSteal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    e.preventDefault();
  }, []);

  const handleBlurCapture = useCallback((e: React.FocusEvent<HTMLElement>) => {
    e.target.blur();
    e.stopPropagation();
  }, []);

  return {
    preventFocusSteal,
    handleBlurCapture
  };
};

const UploaderForm: React.FC<UploaderFormProps> = ({
  transfer,
  user,
  file,
  submitCb,
  app,
  user_upload_size,
  transferInfos,
  setTransferInfos
}) => {
  const { preventFocusSteal, handleBlurCapture } = useFormFocus();
  const dispatch = useDispatch();
  
  // Récupérer la préférence de l'utilisateur depuis localStorage ou utiliser la valeur par défaut
  const [activeTab, setActiveTab] = useState<number>(() => {
    try {
      const savedMethod = localStorage.getItem(UPLOAD_METHOD_STORAGE_KEY);
      // Si une valeur est sauvegardée, l'utiliser, sinon utiliser la valeur de l'app ou 0 par défaut
      return savedMethod !== null ? parseInt(savedMethod, 10) : (app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0);
    } catch (error) {
      // En cas d'erreur (ex: localStorage non disponible), utiliser la valeur de l'app
      return app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0;
    }
  });
  
  const [transferType, setTransferType] = useState<"email" | "link">(() => {
    try {
      const savedMethod = localStorage.getItem(UPLOAD_METHOD_STORAGE_KEY);
      // Si une valeur est sauvegardée, l'utiliser, sinon utiliser la valeur de l'app
      if (savedMethod !== null) {
        return parseInt(savedMethod, 10) === 1 ? "email" : "link";
      } else {
        return app[appAction.USER_UPLOAD_FAVORITE_METHOD] === 1 ? "email" : "link";
      }
    } catch (error) {
      // En cas d'erreur, utiliser la valeur de l'app
      return app[appAction.USER_UPLOAD_FAVORITE_METHOD] === 1 ? "email" : "link";
    }
  });

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [localPassword, setLocalPassword] = useState<string>("");
  const [localConfirmPassword, setLocalConfirmPassword] = useState<string>("");
  const [localForTeam, setLocalForTeam] = useState<boolean>(transferInfos.for_team);
  const [passwordCopied, setPasswordCopied] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  // État pour gérer le mot de passe temporaire pendant l'édition
  const [tempPasswordData, setTempPasswordData] = useState({
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    setLocalForTeam(transferInfos.for_team || false);
    // Ne pas réinitialiser les mots de passe ici pour éviter les conflits avec la modal
  }, [transferInfos.for_team]);

  useEffect(() => {
    if (isPasswordModalOpen) {
      // Initialiser les données temporaires quand la modal s'ouvre
      setTempPasswordData({
        password: transferInfos.transfer_password || "",
        confirmPassword: transferInfos.transfer_confirm_password || ""
      });
      setLocalPassword(transferInfos.transfer_password || "");
      setLocalConfirmPassword(transferInfos.transfer_confirm_password || "");
      setShowPassword(false);
      setPasswordStrength(checkPasswordStrength(transferInfos.transfer_password || ""));
    }
  }, [isPasswordModalOpen, transferInfos.transfer_password, transferInfos.transfer_confirm_password]);

  const hackTransferType: number = useMemo(
    () => Number(window.location.pathname.startsWith("/u/")),
    []
  );

  const isValidLimit = useCallback((): boolean => {
    return user_upload_size.per_transfer_limit >= (transfer.totalSize || 0);
  }, [user_upload_size.per_transfer_limit, transfer.totalSize]);

  const isTeamAuthDisabled = useMemo(() => {
    return (
      (transferInfos.auto_send ||
        !user?.permissions?.transfer_authentication?.value) &&
      !user?.members?.length &&
      !user?.referer_id
    );
  }, [transferInfos.auto_send, user]);

  const parseCurly = (str: string): string => {
    if (!str) return "";
    return str.split("[").join("_5B_").split("]").join("_5D_");
  };

  const updateTransferData = useCallback((updatedData: Partial<TransferInfosModel>) => {
    setTransferInfos(prev => ({
      ...prev,
      ...updatedData
    }));
  }, [setTransferInfos]);

  const checkPasswordStrength = useCallback((password: string): number => {
    let strength = 0;
    if (password.length >= 8) strength += 25;
    if (password.match(/[a-z]+/)) strength += 25;
    if (password.match(/[A-Z]+/)) strength += 25;
    if (password.match(/[0-9]+/)) strength += 25;
    if (password.match(/[$@#&!]+/)) strength += 25;
    return Math.min(100, strength);
  }, []);

  const handlePasswordSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!localPassword || !localConfirmPassword) return;

      const isPasswordValid = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/.test(
        localPassword
      );
      const doPasswordsMatch = localPassword === localConfirmPassword;

      if (isPasswordValid && doPasswordsMatch) {
        updateTransferData({
          transfer_password: localPassword,
          transfer_confirm_password: localConfirmPassword
        });
        setIsPasswordModalOpen(false);
      } else {
        if (!isPasswordValid) {
          alert(i18n._("form_upload_password_invalid"));
        } else if (!doPasswordsMatch) {
          alert(i18n._("form_upload_confirm_password_invalid"));
        }
      }
    },
    [localPassword, localConfirmPassword, updateTransferData]
  );

  const closePasswordModal = useCallback(() => {
    // Restaurer les valeurs temporaires
    setLocalPassword(tempPasswordData.password);
    setLocalConfirmPassword(tempPasswordData.confirmPassword);
    setIsPasswordModalOpen(false);
  }, [tempPasswordData]);

  const removePassword = useCallback(() => {
    updateTransferData({
      transfer_password: "",
      transfer_confirm_password: ""
    });
    setLocalPassword("");
    setLocalConfirmPassword("");
    setTempPasswordData({ password: "", confirmPassword: "" });
    setIsPasswordModalOpen(false);
  }, [updateTransferData]);

  const generatePassword = useCallback(() => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setLocalPassword(password);
    setLocalConfirmPassword(password);
    setPasswordStrength(100);
    setShowPassword(true);
    setPasswordCopied(false); // Réinitialiser l'état de copie
  }, []);

  const copyPasswordToClipboard = useCallback(() => {
    if (localPassword) {
      navigator.clipboard.writeText(localPassword)
        .then(() => {
          // Afficher le toast au lieu de changer l'apparence du bouton
          setShowToast(true);
        })
        .catch(err => {
          console.error('Erreur lors de la copie du mot de passe:', err);
        });
    }
  }, [localPassword]);

  // Fonction pour fermer le toast
  const handleCloseToast = useCallback(() => {
    setShowToast(false);
  }, []);

  const handleCustomSubmit = useCallback(async () => {
    if (!isValidLimit() || transfer.isLocked) return;

    try {
      const currentTransferInfo = { ...transferInfos };

      if (!currentTransferInfo.transfer_name && file.length > 0) {
        currentTransferInfo.transfer_name = parseCurly(file[0].meta.name);
      }

      console.log("[DEBUG] UploaderForm avant création du payload - currentTransferInfo:", {
        transferType,
        recipients: currentTransferInfo.recipients,
        hasRecipients: !!currentTransferInfo.recipients && currentTransferInfo.recipients.length > 0,
        recipientsLength: currentTransferInfo.recipients?.length || 0,
        isEmailType: transferType === "email"
      });

      // Assurons-nous que le type de transfert est correctement défini
      if (currentTransferInfo.transfer_type === undefined) {
        // Tous les uploads sont de type 0, qu'ils soient par email ou par lien
        // La différenciation se fait par la propriété recipients
        currentTransferInfo.transfer_type = 0;
        console.log("[DEBUG] UploaderForm handleCustomSubmit - Type de transfert défini automatiquement:", {
          transferType,
          newTransferType: currentTransferInfo.transfer_type,
          isEmail: transferType === "email"
        });
      }
      
      const payload: TransferPayload = {
        id: currentTransferInfo.id,
        transfer_type: currentTransferInfo.transfer_type,
        transfer_name: currentTransferInfo.transfer_name || "",
        transfer_message: currentTransferInfo.transfer_message || "",
        transfer_password: currentTransferInfo.transfer_password || "",
        recipients:
          transferType === "email" ? currentTransferInfo.recipients || [] : [],
        auto_remove: Boolean(currentTransferInfo.auto_remove),
        auto_send: Boolean(currentTransferInfo.auto_send),
        for_team: Boolean(currentTransferInfo.for_team),
        transfer_notification: Boolean(app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0),
        transfer_sender: user?.email || ""
      };
      
      console.log("[DEBUG] UploaderForm handleCustomSubmit - Payload créé:", {
        transferType,
        payloadTransferType: payload.transfer_type,
        isEmailTransfer: transferType === "email",
        hasRecipients: payload.recipients?.length > 0,
        recipients: payload.recipients,
        sender: payload.transfer_sender,
        // Vérifier à qui les emails devraient être envoyés selon la logique du serveur
        expectedEmailRecipients: {
          uploaderConfirmation: transferType !== "email" ? payload.transfer_sender : payload.recipients?.[0],
          downloaderMail: transferType !== "email" ? payload.recipients : payload.transfer_sender
        }
      });

      console.log("[DEBUG] UploaderForm handleCustomSubmit - Payload:", {
        payload,
        hasRecipients: !!payload.recipients && payload.recipients.length > 0,
        recipientsValue: payload.recipients,
        recipientsLength: payload.recipients?.length || 0,
        hasTransferSender: !!payload.transfer_sender,
        senderValue: payload.transfer_sender,
        userEmail: user?.email
      });

      if (transfer.id) {
        payload.transfer_id = transfer.id;
      }

      const transferState = {
        ...transfer,
        isLocked: true,
        isUploadFinished: false,
        startTime: Date.now(),
        progress: {
          last: { timestamp: Date.now(), loaded: 0 },
          current: { timestamp: Date.now(), loaded: 0 }
        },
        purging: false,
        transfer_name: currentTransferInfo.transfer_name
      };

      dispatch(transferAction.setInfos(transferState));
      updateTransferData({
        ...currentTransferInfo,
        transfer_state: "pending"
      });

      const transferResult = await transferApi.createTransfer(payload);

      if (transferResult.error) {
        throw new Error(transferResult.error);
      }

      dispatch(
        transferAction.setInfos({
          ...transferState,
          id: transferResult.id,
          transfer_id: transferResult.transfer_id,
          bucket_id: transferResult.bucket_id,
          provider_id: transferResult.provider_id
        })
      );

      updateTransferData({
        id: transferResult.id,
        transfer_state: "completed"
      });

      await submitCb(transferResult);
    } catch (error) {
      console.error("[UploaderForm] Error createTransfer:", error);
      updateTransferData({
        transfer_state: "error"
      });
      dispatch(appAction.setAppError(error));
    }
  }, [
    isValidLimit,
    file,
    transferType,
    app,
    transfer,
    transferInfos,
    dispatch,
    submitCb,
    updateTransferData
  ]);

  const handleTabChange = useCallback(
    (newTab: number) => {
      const newTransferType = newTab === 0 ? "link" : "email";
      setActiveTab(newTab);
      setTransferType(newTransferType);
      
      // Sauvegarder la préférence dans le store Redux
      dispatch(appAction.setUserUploadFavoriteMethod(newTab));
      
      // Sauvegarder la préférence dans localStorage pour la persistance entre les sessions
      try {
        localStorage.setItem(UPLOAD_METHOD_STORAGE_KEY, newTab.toString());
      } catch (error) {
        console.error("Erreur lors de la sauvegarde de la préférence d'envoi:", error);
      }
      
      console.log("[DEBUG] UploaderForm handleTabChange - Changement de type de transfert:", {
        newTab,
        newTransferType,
        transferTypeValue: 0, // Tous les uploads sont de type 0
        transferMethod: newTab, // 0 = link, 1 = email (méthode d'envoi, pas le type de transfert)
        savedToLocalStorage: true
      });
      
      // Tous les uploads sont de type 0, qu'ils soient par email ou par lien
      // La différenciation se fait par la propriété recipients
      updateTransferData({ transfer_type: 0 });
    },
    [dispatch, updateTransferData]
  );

  const handleForTeamToggle = useCallback(async () => {
    const newForTeam = !localForTeam;
    setLocalForTeam(newForTeam);

    if (!newForTeam) {
      updateTransferData({
        recipients: [],
        for_team: false
      });
      return;
    }

    try {
      const userMembers = await userApi.getTeamMembers(user?.id || "");
      const recipients = userMembers.map((member: any) => member.email);
      setLocalForTeam(true);
      updateTransferData({
        recipients,
        for_team: true
      });
    } catch (error) {
      console.error("Failed to fetch team members:", error);
      setLocalForTeam(false);
    }
  }, [localForTeam, user?.id, updateTransferData]);

  const handleAutoRemoveToggle = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    updateTransferData({ auto_remove: !transferInfos.auto_remove });
  }, [transferInfos.auto_remove, updateTransferData]);

  const handleTransferInfosUpdate = useCallback((updatedInfo: Partial<TransferInfosModel>) => {
    updateTransferData(updatedInfo);
  }, [updateTransferData]);

  if (transfer.transfer_type || transfer.type || hackTransferType) {
    return (
      <div className="h-full">
        <button
          onClick={handleCustomSubmit}
          disabled={!!transfer.isLocked}
          className="btn btn-primary w-full"
        >
          {i18n._("btn_transfer_label")}
        </button>
      </div>
    );
  }

  return (
    <div className="w-[360px] mx-auto">
      <div className="container">
        <div className="col-span-10 md:col-span-8 w-full bg-base-300 p-6 rounded-lg shadow-2xl border border-gray-700 max-w-[350px] flex flex-col max-h-[80vh] overflow-y-auto overflow-x-hidden">
          {user?.role ? (
            <div className="flex flex-col space-y-4">
              <div className="flex items-center tabs tabs-boxed bg-base-200">
                <button
                  type="button"
                  className={`tab gap-2 ${activeTab === 0 ? "tab-active" : ""}`}
                  onClick={() => handleTabChange(0)}
                  disabled={transfer.isLocked}
                >
                  <PlusCircle className="w-4 h-4" />
                  {i18n._("form_upload_tab_link_label")}
                </button>
                <button
                  type="button"
                  className={`tab gap-2 ${activeTab === 1 ? "tab-active" : ""}`}
                  onClick={() => handleTabChange(1)}
                  disabled={transfer.isLocked}
                  onMouseDown={preventFocusSteal}
                >
                  <Send className="w-4 h-4" />
                  {i18n._("form_upload_tab_email_label")}
                </button>
                <div className="ml-auto flex gap-1">
                  <div className="tooltip" data-tip={i18n._("password_explain")}>
                    <button
                      type="button"
                      className={`btn btn-ghost btn-circle ${transferInfos.transfer_password ? "text-emerald-500" : ""}`}
                      onClick={() => setIsPasswordModalOpen(true)}
                      disabled={!user?.role || !user?.permissions?.transfer_authentication?.value}
                      onMouseDown={preventFocusSteal}
                    >
                      <Lock className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="tooltip" data-tip={i18n._("for_team_explain")}>
                    <button
                      type="button"
                      className={`btn btn-ghost btn-circle ${localForTeam ? "text-emerald-500" : ""}`}
                      onClick={handleForTeamToggle}
                      disabled={isTeamAuthDisabled}
                      onMouseDown={preventFocusSteal}
                    >
                      <UserCheck className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>

              {activeTab === 1 && (
                <div className="mt-4 overflow-y-auto flex-grow" style={{ maxHeight: 'calc(80vh - 200px)' }}>
                  <EmailFormComponent
                    disabled={!user?.role}
                    transferInfos={transferInfos}
                    handleTransferInfosUpdate={handleTransferInfosUpdate}
                    handleChangeEmailForm={(key, value, recipients) => {
                      if (key === "auto_send") {
                        const updates: Partial<TransferInfosModel> = { auto_send: value };
                        if (recipients !== undefined) {
                          updates.recipients = recipients;
                        }
                        updateTransferData(updates);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <a
              href={i18n._("BUTTONS.MORE_FEATURES.URL")}
              className="btn btn-outline gap-2 w-full"
              onMouseDown={preventFocusSteal}
            >
              <Rocket className="w-4 h-4" />
              {i18n._("BUTTONS.MORE_FEATURES.LABEL")}
            </a>
          )}

          <div className="mt-4 mb-6">
            <div className="flex items-start justify-between gap-4">
              <div className="flex flex-col">
                <label className="select-none text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                  {i18n._("form_upload_transfer_auto_remove_label")}
                </label>
                <p className="text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400">
                  {i18n._("form_upload_transfer_auto_remove_description")}
                </p>
              </div>
              <div className="shrink-0">
                <div className="relative">
                  <input
                    type="checkbox" 
                    className="toggle-sm toggle toggle-primary"
                    checked={transferInfos.auto_remove}
                    onChange={() => {}} // Pour éviter l'avertissement React
                    onMouseDown={handleAutoRemoveToggle}
                    onBlurCapture={handleBlurCapture}
                    disabled={transfer.isLocked}
                    title={transfer.isLocked ? i18n._("form_upload_transfer_locked_tooltip") : ""}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 sticky bottom-0 bg-base-300 pt-2 pb-1">
            <button
              type="button"
              disabled={transfer.isLocked}
              onClick={handleCustomSubmit}
              onMouseDown={preventFocusSteal}
              className="btn btn-primary w-full shadow-lg"
            >
              {i18n._("btn_transfer_label")}
            </button>
            {!isValidLimit() && (
              <div className="text-error text-center mt-2">
                {convertSize(user_upload_size.per_transfer_limit)}/{i18n._("transfer_label")} max.
              </div>
            )}
          </div>
        </div>
      </div>

      {isPasswordModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box relative">
            <button
              type="button"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={closePasswordModal}
              onMouseDown={preventFocusSteal}
            >
              <X className="w-4 h-4" />
            </button>
            <h3 className="font-bold text-lg mb-4">
              {i18n._("form_upload_password_title")}
            </h3>
            <form
              onSubmit={handlePasswordSubmit}
              className="space-y-4"
              autoComplete="off"
              data-lpignore="true"
              noValidate
              spellCheck="false"
            >
              <div style={{ display: 'none' }}>
                <input type="text" name="fakeusernameremembered" />
                <input type="password" name="fakepasswordremembered" />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    {i18n._("form_upload_password_label")}
                  </span>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder={i18n._("form_upload_password_label")}
                    className="input input-bordered flex-grow"
                    value={localPassword}
                    onChange={(e) => {
                      const newPassword = e.target.value;
                      setLocalPassword(newPassword);
                      setPasswordStrength(checkPasswordStrength(newPassword));
                    }}
                    // Suppression des gestionnaires qui empêchent la saisie
                    // onBlurCapture={handleBlurCapture}
                    // onMouseDown={preventFocusSteal}
                    required
                    autoComplete="one-time-code"
                    data-lpignore="true"
                    name={`pwd_${Date.now()}`}
                  />
                  <button
                    type="button"
                    className="btn btn-square"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={preventFocusSteal}
                  >
                    {showPassword ? (
                      <EyeOff className="w-4 h-4" />
                    ) : (
                      <Eye className="w-4 h-4" />
                    )}
                  </button>
                </div>
                <progress
                  className={`progress w-full mt-2 ${
                    passwordStrength < 50
                      ? "progress-error"
                      : passwordStrength < 80
                      ? "progress-warning"
                      : "progress-success"
                  }`}
                  value={passwordStrength}
                  max="100"
                />
                {!/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/.test(localPassword) &&
                  localPassword && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {i18n._("form_upload_password_invalid")}
                      </span>
                    </label>
                  )}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    {i18n._("form_profil_password_confirmation_label")}
                  </span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={i18n._("form_profil_password_confirmation_label")}
                  className="input input-bordered w-full"
                  value={localConfirmPassword}
                  onChange={(e) => setLocalConfirmPassword(e.target.value)}
                  // Suppression des gestionnaires qui empêchent la saisie
                  // onBlurCapture={handleBlurCapture}
                  // onMouseDown={preventFocusSteal}
                  required
                  autoComplete="one-time-code"
                  data-lpignore="true"
                  name={`confirm_pwd_${Date.now()}`}
                />
                {localPassword !== localConfirmPassword && localConfirmPassword && (
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {i18n._("form_upload_confirm_password_invalid")}
                    </span>
                  </label>
                )}
              </div>
              <div className="flex justify-between items-center gap-2 mt-4">
                <div className="flex gap-2">
                  <button
                    type="button"
                    className="btn btn-outline btn-sm"
                    onClick={generatePassword}
                    onMouseDown={preventFocusSteal}
                  >
                    <RefreshCw className="w-4 h-4 mr-2" />
                    {i18n._("generate_password")}
                  </button>
                  
                  {/* Bouton de copie du mot de passe */}
                  {localPassword && (
                    <button
                      type="button"
                      className="btn btn-outline btn-sm"
                      onClick={copyPasswordToClipboard}
                      disabled={!localPassword}
                      title={i18n._("copy_password") || "Copier le mot de passe"}
                    >
                      <Copy className="w-4 h-4 mr-2" />
                      {i18n._("copy") || "Copier"}
                    </button>
                  )}
                  
                  {transferInfos.transfer_password && (
                    <button
                      type="button"
                      className="btn btn-error btn-sm"
                      onClick={removePassword}
                      onMouseDown={preventFocusSteal}
                    >
                      <Trash2 className="w-4 h-4 mr-2" />
                      {i18n._("remove_password")}
                    </button>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!localPassword || !localConfirmPassword}
                  onMouseDown={preventFocusSteal}
                >
                  {i18n._("btn_valid_label")}
                </button>
              </div>
            </form>
          </div>
          <div
            className="modal-backdrop"
            onClick={closePasswordModal}
            onMouseDown={preventFocusSteal}
          ></div>
        </div>
      )}

      {/* Toast pour confirmer la copie du mot de passe */}
      <Toast
        type="success"
        message={i18n._("password_copied") || "Mot de passe copié !"}
        isVisible={showToast}
        onClose={handleCloseToast}
        duration={2000}
      />
    </div>
  );
};

export default React.memo(UploaderForm, (prevProps, nextProps) => {
  return (
    prevProps.transfer.isLocked === nextProps.transfer.isLocked &&
    prevProps.transfer.totalSize === nextProps.transfer.totalSize &&
    prevProps.file.length === nextProps.file.length &&
    prevProps.transferInfos === nextProps.transferInfos &&
    prevProps.app === nextProps.app &&
    prevProps.user === nextProps.user &&
    prevProps.user_upload_size === nextProps.user_upload_size
  );
});