import React from "react";
import { Dispatch } from 'redux';
import { AnyAction } from 'redux';
import "./Uploader.css";
import { defaultTransfer } from '../../Constants/defaultTransfer';
import Dropzone, { IFileWithMeta, StatusValue, ILayoutProps } from "@jeffreyca/react-dropzone-uploader";
import uuid from "uuid";
import { Info, X } from "lucide-react";
import { config } from "../../config";
import { convertSize, calcConsumption, i18n, formatFileSize } from "../../utils";
import { connect } from "react-redux";
import reduxStore, { appAction, fileAction, transferAction } from "../../Store";
import { UploaderInputComponent, LayoutComponent, DepositCreateComponent } from "./Components";
import { LoaderComponent } from "../";
import transferApi from "../../Api/transferApi";

const { getDroppedOrSelectedFiles } = require("html5-file-selector");

const UPLOAD_CONFIG = {
  MAX_CONCURRENT_UPLOADS: 100,
  getMaxConcurrentUploads: async () => {
    try {
      const { quality } = await transferApi.getConnectionDetails();
      switch (quality) {
        case "excellent": return 100;
        case "good": return 50;
        case "medium": return 30;
        case "poor": return 2;
        default: return 5;
      }
    } catch (error) {
      return 5;
    }
  },
  CHUNK_SIZE: async (size: number) => {
    try {
      const { quality } = await transferApi.getConnectionDetails();
      let min_size;
      switch (quality) {
        case "excellent": min_size = 200 * 1024 * 1024; break;
        case "good": min_size = 100 * 1024 * 1024; break;
        case "medium": min_size = 50 * 1024 * 1024; break;
        case "poor": min_size = 10 * 1024 * 1024; break;
        default: min_size = 10 * 1024 * 1024; break;
      }
      const max_parts = 100;
      const max_size = 2 * 1024 * 1024 * 1024;
      let chunkSize = min_size;
      while (size / chunkSize > max_parts && chunkSize < max_size) {
        chunkSize = Math.min(chunkSize * 2, max_size);
      }
      return chunkSize;
    } catch (error) {
      const min_size = 10 * 1024 * 1024;
      const max_size = 2 * 1024 * 1024 * 1024;
      const max_parts = 100;
      let chunkSize = min_size;
      while (size / chunkSize > max_parts && chunkSize < max_size) {
        chunkSize = Math.min(chunkSize * 2, max_size);
      }
      return chunkSize;
    }
  },
  SPEED_SAMPLING: {
    INTERVAL: 100,
    WINDOW_SIZE: 5000  // Augmenter la fenêtre à 5 secondes pour un calcul plus stable
  }
};

interface PresignedPostData {
  UploadId: string;
  url: string;
  fields: Record<string, string>;
  error?: string;
}

type FileWithPath = File & {
  path?: string;
  webkitRelativePath?: string;
  fullPath?: string;
};

interface State {
  isModalOpen: boolean;
  files: IFileWithMeta[];
  messages: string[];
  isSubmited: boolean;
  isUploadFinished: boolean;
  totalSize: number;
  pgTransferId: number;
  isFilesLoading: boolean;
  totalLoaded: number;
  currentFileLoaded: number;
  successCopyLink: boolean;
  processingFiles: number;
  loadedFiles: number;
  transferHeaders: any;
  queuedFiles: IFileWithMeta[];
  activeUploads: number;
  progress: {
    last: {
      timestamp: number;
      loaded: number;
    };
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed?: number;
      avgSpeed?: number;
    };
  };
  isFetchingProgress: boolean;
  online: boolean;
  isInitializing: boolean;
}

interface Props {
  file: IFileWithMeta[];
  transfer: {
    purging?: boolean;
    isLocked?: boolean;
    transfer_id?: string;
    startTime?: number;
    isUploadFinished?: boolean;
    dispatch: Dispatch<AnyAction>;
  };
  app: {
    APP_ERROR: any;
    SET_APP_ERROR: boolean;
  };
  user?: {
    role?: number;
  };
  dispatch: Function;
  children?: React.ReactNode;
  show_deposit?: boolean;
  transferConfig?: any;
  transfer_type?: number;
}

interface SpeedEntry {
  speed: number;
  timestamp: number;
}

interface FileProgress {
  chunks: {
    [chunkIndex: string]: {
      loaded: number;
      total: number;
    };
  };
  totalLoaded: number;
  fileSize: number;
  speedHistory?: SpeedEntry[];
}

class UploaderComponent extends React.Component<Props, State> {
  private speedHistory: { timestamp: number; loaded: number }[] = [];
  private lastEstimatedTime: number = 0;
  filesUploaded: number[] = [];
  filesNb: number = 0;
  transferId: string;
  startTime: number;
  uploadProgress: { [fileId: string]: FileProgress } = {};

  constructor(props: Props) {
    super(props);
    this.transferId = this.props.transfer.transfer_id || uuid.v4();
    this.startTime = 0;
    this.state = {
      isInitializing: true,
      isModalOpen: false,
      isFilesLoading: false,
      pgTransferId: 0,
      files: [],
      messages: [],
      isSubmited: false,
      isUploadFinished: false,
      totalSize: 0,
      totalLoaded: 0,
      currentFileLoaded: 0,
      successCopyLink: false,
      processingFiles: 0,
      loadedFiles: 0,
      transferHeaders: undefined,
      queuedFiles: [],
      activeUploads: 0,
      progress: {
        last: { timestamp: 0, loaded: 0 },
        current: { 
          timestamp: 0, 
          loaded: 0,
          instantSpeed: 0,
          avgSpeed: 0
        }
      },
      isFetchingProgress: false,
      online: navigator.onLine
    };
  }

  getUploadParams = () => ({ url: "" });

  setFilesLoading = (chosenFiles: FileWithPath[]): Promise<FileWithPath[]> =>
    new Promise((resolve) => {
      this.setState(
        {
          processingFiles: this.state.processingFiles + chosenFiles.length,
          isFilesLoading: true,
        },
        () => setTimeout(() => resolve(chosenFiles), 100)
      );
    });

  getFilesFromEvent = async (e: any): Promise<File[]> => {
    this._dragEnd();
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    this.setState({ isModalOpen: true, isFilesLoading: true });
    
    // Vérifier si le chemin est valide
    const currentPath = window.location.pathname;
    const isValidPath = currentPath === '/' || currentPath === '/offers';
    
    if (!isValidPath) {
      this.setState({ isModalOpen: false, isFilesLoading: false });
      return [];
    }
    
    // Réinitialiser l'état du transfert actif
    if (this.transferId) {
      // Réinitialiser complètement l'état
      this.props.dispatch(transferAction.resetTransferState(this.transferId));
      this.props.dispatch(transferAction.lockTransfer(this.transferId, false));
    }
    
    const loadedFiles = await this.setFilesLoading(chosenFiles);
    return loadedFiles.map((f: any) => {
      const originalFile = f.fileObject || f;
      Object.defineProperties(originalFile, {
        'name': { value: escape(originalFile.name), writable: true },
        'fullPath': { value: f.fullPath ? escape(f.fullPath) : originalFile.webkitRelativePath, writable: true }
      });
      return originalFile;
    });
  };

  onProgress = async (XHRProgress: { loaded: number; total: number }, metaId: string, strIndex: string) => {
    // Vérifier l'état et le chemin
    const currentPath = window.location.pathname;
    const isValidPath = currentPath === '/' || currentPath === '/offers';
    
    if (this.props.transfer.purging || !this.state.online) {
      return;
    }

    if (!this.uploadProgress[metaId]) {
      this.uploadProgress[metaId] = {
        chunks: {},
        totalLoaded: 0,
        fileSize: Number(XHRProgress.total) || 0,
      };
    }
    this.uploadProgress[metaId].chunks[strIndex] = {
      loaded: Math.max(0, Number(XHRProgress.loaded) || 0),
      total: Math.max(0, Number(XHRProgress.total) || 0)
    };
    let totalLoaded = 0;
    let totalSize = 0;
    Object.values(this.uploadProgress).forEach(fileProgress => {
      totalLoaded += Object.values(fileProgress.chunks).reduce((sum, chunk) =>
        sum + Math.max(0, Number(chunk.loaded) || 0), 0);
      totalSize += fileProgress.fileSize;
    });
    const now = Date.now();
    this.speedHistory.push({ timestamp: now, loaded: totalLoaded });
    while (this.speedHistory.length > 0 && now - this.speedHistory[0].timestamp > UPLOAD_CONFIG.SPEED_SAMPLING.WINDOW_SIZE) {
      this.speedHistory.shift();
    }
    // Calcul de la vitesse instantanée sur une fenêtre glissante
    let instantSpeed = 0;
    if (this.speedHistory.length >= 2) {
      const recentHistory = this.speedHistory.slice(-10); // Utiliser les 10 derniers points
      const oldestEntry = recentHistory[0];
      const windowTime = (now - oldestEntry.timestamp) / 1000;
      const loadedInWindow = totalLoaded - oldestEntry.loaded;
      
      if (windowTime > 0) {
        // Moyenne pondérée avec plus de poids pour les mesures récentes
        const speeds = recentHistory.slice(1).map((entry, index) => {
          const timeDiff = (entry.timestamp - recentHistory[index].timestamp) / 1000;
          const loadDiff = entry.loaded - recentHistory[index].loaded;
          return timeDiff > 0 ? loadDiff / timeDiff : 0;
        });
        
        const weightedSum = speeds.reduce((sum, speed, index) => 
          sum + speed * (index + 1), 0);
        const weightSum = speeds.reduce((sum, _, index) => 
          sum + (index + 1), 0);
        
        instantSpeed = weightedSum / weightSum;
      }
    }
    // Calcul de la moyenne glissante pour la vitesse
    const avgSpeed = this.state.progress.current.avgSpeed || 0;
    const alpha = 0.3; // Facteur de lissage
    const smoothedSpeed = avgSpeed * (1 - alpha) + instantSpeed * alpha;

    const newProgress = {
      last: this.state.progress.current,
      current: { 
        timestamp: now, 
        loaded: totalLoaded,
        instantSpeed,
        avgSpeed: smoothedSpeed
      }
    };
    const currentFileLoaded = Object.values(this.uploadProgress[metaId].chunks).reduce((sum, chunk) => sum + chunk.loaded, 0);
    this.setState({ progress: newProgress, totalLoaded, currentFileLoaded }, () => {
      this.props.dispatch(transferAction.updateProgress(
        this.transferId,
        now,
        totalLoaded,
        instantSpeed,
        {
          fileId: metaId,
          chunkIndex: strIndex,
          chunkLoaded: Number(XHRProgress.loaded),
          chunkTotal: Number(XHRProgress.total),
          chunkSize: Number(XHRProgress.total),
          chunksUploaded: Object.keys(this.uploadProgress[metaId].chunks).length,
          totalChunks: Math.ceil(this.uploadProgress[metaId].fileSize / (XHRProgress.total || 1))
        },
        currentFileLoaded
      ));
      const elapsedTime = (now - (this.props.transfer.startTime || now)) / 1000;
      const consumption = calcConsumption.forUpload(totalLoaded, elapsedTime);
      this.props.dispatch(transferAction.setInfos({
        filesProgress: this.uploadProgress,
        progress: newProgress,
        loaded: totalLoaded,
        speeds: { instant: instantSpeed, average: instantSpeed },
        estimatedTimeLeft: instantSpeed > 0 ? Math.ceil((totalSize - totalLoaded) / instantSpeed) : 0,
        consumption: { ...consumption, size: totalLoaded },
        path: currentPath
      }));
    });
  };

  handleSubmit = (files: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    if (!files || !files.length) {
      return;
    }
    this.setState({ files: [...files] }, () => {});
  };

  handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => {
    // Vérifier si le chemin est valide
    const currentPath = window.location.pathname;
    const isValidPath = currentPath === '/' || currentPath === '/offers';
    
    if (!isValidPath) {
      return;
    }

    if (status === "ready" || status === "removed") {
      let totalSize = 0;
      allFiles.forEach((f) => {
        if (f.meta.size && f.meta.size > 0) {
          totalSize += status !== "removed" ? f.meta.size : 0;
        }
      });

      if (totalSize > 0) {
        const now = Date.now();
        
        // Réinitialiser complètement l'état
        this.props.dispatch(transferAction.resetTransferState(this.transferId));
        this.props.dispatch(transferAction.lockTransfer(this.transferId, false));
        
        this.setState({ totalSize }, () => {
          this.props.dispatch(fileAction.setFile(allFiles));
          this.props.dispatch(transferAction.setInfos({
            transfer_id: this.transferId,
            totalSize,
            startTime: now,
            path: currentPath,
            isLocked: false,
            isUploadFinished: false,
            loaded: 0,
            progress: {
              current: {
                timestamp: now,
                loaded: 0,
                instantSpeed: 0,
                avgSpeed: 0
              },
              last: {
                timestamp: now,
                loaded: 0
              }
            }
          }));
        });

        // Réinitialiser les états locaux
        this.uploadProgress = {};
        this.speedHistory = [];
        this.setState({
          totalLoaded: 0,
          currentFileLoaded: 0,
          progress: {
            last: { timestamp: now, loaded: 0 },
            current: { 
              timestamp: now, 
              loaded: 0,
              instantSpeed: 0,
              avgSpeed: 0
            }
          }
        });
      }
    }
  };

  onSubmitCb = async (transferInfo: any) => {
    try {
      // Assurons-nous que les propriétés sont correctement transmises
      const transferData = {
        ...transferInfo,
        transfer_size: this.props.file.reduce((sum, f) => sum + f.meta.size, 0),
        files_count: this.props.file.length,
        // Utiliser explicitement les propriétés telles qu'elles apparaissent dans l'objet transferInfo
        // Prendre en compte à la fois recipients et transfer_recipients
        recipients: transferInfo.recipients || transferInfo.transfer_recipients || [],
        transfer_recipients: transferInfo.recipients || transferInfo.transfer_recipients || [],
        transfer_sender: transferInfo.transfer_sender || ""
      };
      this.setState({
        isSubmited: false,
        isUploadFinished: false,
        totalLoaded: 0,
        currentFileLoaded: 0,
        processingFiles: 0,
        loadedFiles: 0,
        transferHeaders: undefined,
        queuedFiles: [],
        activeUploads: 0,
        progress: { 
          last: { timestamp: 0, loaded: 0 }, 
          current: { 
            timestamp: 0, 
            loaded: 0,
            instantSpeed: 0,
            avgSpeed: 0
          } 
        },
        isFetchingProgress: false
      });
      this.uploadProgress = {};
      this.filesUploaded = [];
      this.filesNb = 0;
      if (!this.props.file || this.props.file.length === 0) {
        throw new Error('No files to upload');
      }
      this.startTime = Date.now();
      this.setState({
        progress: { 
          current: { 
            timestamp: this.startTime, 
            loaded: 0,
            instantSpeed: 0,
            avgSpeed: 0
          }, 
          last: { 
            timestamp: this.startTime, 
            loaded: 0 
          } 
        }
      });
      const totalSize = this.props.file.reduce((sum, f) => sum + f.meta.size, 0);
      if (totalSize === 0) {
        throw new Error('File size cannot be 0');
      }
      let transferResponse;
      if (transferInfo.transfer_id && transferInfo.bucket_id) {
        transferResponse = { transfer_id: transferInfo.transfer_id, bucket_id: transferInfo.bucket_id };
      } else {
        transferResponse = await transferApi.createTransfer(transferData);
      }
      
      if (transferResponse.error) {
        throw new Error(transferResponse.error);
      }
      this.transferId = transferResponse.transfer_id;
      const bucket_id = transferResponse.bucket_id;
      if (!this.transferId || !bucket_id) {
        throw new Error('Missing transfer or bucket ID');
      }
      
      const uploadSingleFile = async (fileWMeta: IFileWithMeta) => {
        const file = fileWMeta.file as FileWithPath;
        this.uploadProgress[fileWMeta.meta.id] = { chunks: {}, totalLoaded: 0, fileSize: file.size };
        const chunkSize = await UPLOAD_CONFIG.CHUNK_SIZE(file.size);
        const presignedPostData = await transferApi.getPresignedPostData(
          {
            size: file.size,
            fullPath: file.fullPath || fileWMeta.meta.name,
            name: fileWMeta.meta.name,
            type: fileWMeta.meta.type,
          },
          { transfer_id: this.transferId, bucket_id: bucket_id, chunkSize }
        );
        if (presignedPostData?.error) throw presignedPostData.error;
        return transferApi.uploadFileToS3(
          presignedPostData,
          file,
          (prg: any, strIndex: string) => this.onProgress(prg, fileWMeta.meta.id, strIndex),
          { transfer_id: this.transferId, bucket_id: bucket_id }
        ).then(() => {
          this.filesUploaded.push(presignedPostData.UploadId);
          return presignedPostData;
        });
      };
      const uploadQueue = [...this.props.file];
      this.setState({ queuedFiles: uploadQueue, activeUploads: 0 });
      const inProgress = new Set();
      const results = [];
      while (uploadQueue.length > 0 || inProgress.size > 0) {
        const maxConcurrent = await UPLOAD_CONFIG.getMaxConcurrentUploads();
        while (inProgress.size < maxConcurrent && uploadQueue.length > 0) {
          const fileWMeta = uploadQueue.shift()!;
          this.setState(prevState => ({
            queuedFiles: prevState.queuedFiles.filter(f => f !== fileWMeta),
            activeUploads: prevState.activeUploads + 1
          }));
          const uploadPromise = uploadSingleFile(fileWMeta)
            .then((result: PresignedPostData) => {
              inProgress.delete(uploadPromise);
              this.setState(prevState => ({ activeUploads: prevState.activeUploads - 1 }));
              results.push(result);
            })
            .catch((error: unknown) => {
              inProgress.delete(uploadPromise);
              this.setState(prevState => ({ activeUploads: prevState.activeUploads - 1 }));
              throw error;
            });
          inProgress.add(uploadPromise);
        }
        if (inProgress.size > 0) {
          await Promise.race(Array.from(inProgress));
        }
      }
      await this.finalizeTransfer({ ...transferData, transfer_id: this.transferId, bucket_id: bucket_id });
    } catch (error) {
      this.props.dispatch(appAction.setAppError(error));
    }
  };

  finalizeTransfer = async (transfer: any) => {
    try {
      // Vérifier que tous les fichiers sont bien uploadés
      if (Object.keys(this.uploadProgress).length !== this.props.file.length) {
        throw new Error("Tous les fichiers n'ont pas été complètement uploadés");
      }
      
      // Attendre un délai de sécurité
      await new Promise(resolve => setTimeout(resolve, 2000));

      const totalTime = (Date.now() - (this.props.transfer.startTime || 0)) / 1000;
      const baseConsumption = calcConsumption.forUpload(this.state.totalSize, totalTime);
      const consumption = { ...baseConsumption, size: this.state.totalSize };
      // S'assurer que les propriétés recipients et transfer_sender sont correctement transmises
      const finalizeData = {
        ...transfer,
        consumption,
        transfer_size: this.state.totalSize,
        transfer_id: this.transferId,
        // Utiliser explicitement les propriétés telles qu'elles apparaissent dans l'objet transfer
        // Prendre en compte à la fois recipients et transfer_recipients
        recipients: transfer.recipients || transfer.transfer_recipients || [],
        transfer_recipients: transfer.recipients || transfer.transfer_recipients || [],
        transfer_sender: transfer.transfer_sender || ""
      };
      
      // Assurons-nous que le type de transfert est correctement défini
      if (finalizeData.transfer_type === undefined) {
        // Si le type n'est pas défini, utilisons la présence de destinataires pour déterminer le type
        finalizeData.transfer_type = finalizeData.recipients?.length > 0 ? 1 : 0;
      }
      
      // Tentatives de finalisation avec délai progressif
      let attempts = 0;
      const maxAttempts = 3;
      let result = null;

      while (attempts < maxAttempts) {
        try {
          result = await transferApi.finalizeTransfer(
            finalizeData,
            transfer,
            (this.props.user as any)?.email,
            true // is_received: true pour indiquer que c'est un transfert reçu
          );
          
          if (result?.transfer_link) {
            break;
          }
          throw new Error('Transfer link not generated');
        } catch (error: any) {
          attempts++;
          if (error?.response?.status === 404 && attempts < maxAttempts) {
            const delay = Math.pow(2, attempts) * 1000; // Délai exponentiel: 2s, 4s, 8s
            await new Promise(resolve => setTimeout(resolve, delay));
            continue;
          }
          throw error;
        }
      }

      if (!result?.transfer_link) {
        throw new Error('Échec de la finalisation après plusieurs tentatives');
      }

      // Préparer l'état final du transfert avec toutes les propriétés nécessaires
      const finalState = {
        ...this.props.transfer,
        totalTime,
        isUploadFinished: true,
        isLocked: false,
        archived: false,
        link: `${config.ihmUrl}/d/${result.transfer_link}`,
        consumption,
        expiration_date: result.expiration_date,
        transfer_id: this.transferId,
        lastProgressUpdate: Date.now()
      };

      // Mettre à jour l'état Redux
      this.props.dispatch(transferAction.setInfos(finalState));

      // Mettre à jour l'état local
      this.setState({
        isSubmited: true,
        isUploadFinished: true,
        isFetchingProgress: false
      });
    } catch (error) {
      this.props.dispatch(appAction.setAppError(error));
    }
  };

  handleConnexionLost = () => {
    this.setState({ online: false });
  };

  handleConnexionRetreive = () => {
    setTimeout(() => this.setState({ online: true }), window.retryDelay || 0);
  };

  _dragEnter = () => {
    window.removeEventListener("dragenter", this._dragEnter);
    window.removeEventListener("dragover", this._dragEnter);
    window.addEventListener("dragend", this._dragEnd);
    window.addEventListener("drop", this._dragEnd);
    window.addEventListener("mouseup", this._dragEnd);
    const input = document.getElementById("input-file");
    const uploaderFull = document.getElementsByClassName("uploader drop full")[0] as HTMLElement;
    const uploaderEmpty = document.getElementsByClassName("uploader drop")[0];
    if (input) { input.style.position = "fixed"; }
    if (uploaderFull) { uploaderFull.style.backgroundColor = 'rgba(255, 255, 255, .75)'; }
    if (uploaderEmpty) { uploaderEmpty.setAttribute("data-status", "active"); }
  };

  _dragEnd = () => {
    window.removeEventListener("dragend", this._dragEnd);
    window.removeEventListener("drop", this._dragEnd);
    window.removeEventListener("mouseup", this._dragEnd);
    window.addEventListener("dragenter", this._dragEnter);
    window.addEventListener("dragover", this._dragEnter);
    const input = document.getElementById("input-file");
    const uploaderEmpty = document.getElementsByClassName("uploader drop");
    if (input) { input.style.position = "relative"; }
    if (uploaderEmpty[0]) { uploaderEmpty[0].removeAttribute("data-status"); }
  };

  async componentDidMount() {
    try {
      // Attendre que redux-persist soit initialisé
      await new Promise<void>((resolve) => {
        const unsubscribe = reduxStore.store.subscribe(() => {
          const state = reduxStore.store.getState();
          if (state._persist?.rehydrated) {
            unsubscribe();
            resolve();
          }
        });
      });

      // Réinitialiser l'état des fichiers
      this.props.dispatch(fileAction.purgeFile());

      // Initialiser le transfert avec lockTransfer d'abord
      this.props.dispatch(transferAction.lockTransfer(this.transferId, false));

      // Attendre le prochain tick pour s'assurer que le state est mis à jour
      await new Promise(resolve => setTimeout(resolve, 0));

      // Puis initialiser les autres informations
      this.props.dispatch(transferAction.setInfos({
        transfer_id: this.transferId,
        isLocked: false,
        isUploadFinished: false,
        loaded: 0,
        totalSize: 0
      }));

    } catch (error) {
      // Handle error
    }
    
    window.addEventListener("offline", this.handleConnexionLost);
    window.addEventListener("online", this.handleConnexionRetreive);
    window.addEventListener("dragenter", this._dragEnter);
    window.addEventListener("dragover", this._dragEnter);
    if (this.props.app.APP_ERROR) {
      this.props.dispatch(appAction.setAppError(null));
    }
    this.setState({ isInitializing: false });
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.handleConnexionLost);
    window.removeEventListener("online", this.handleConnexionRetreive);
    window.removeEventListener("dragenter", this._dragEnter);
    window.removeEventListener("dragover", this._dragEnter);
    window.removeEventListener("dragend", this._dragEnd);
    window.removeEventListener("drop", this._dragEnd);
    window.removeEventListener("mouseup", this._dragEnd);
    if (this.props.app.APP_ERROR) {
      this.props.dispatch(appAction.setAppError(null));
    }
    if (this.state.activeUploads > 0) {
      this.state.queuedFiles.forEach(file => {
        if (file.xhr) {
          file.xhr.abort();
        }
      });
    }
  }

  render() {
    const { transfer, file, app, user } = this.props;
    const { online, isModalOpen } = this.state;
    if (transfer?.purging && !transfer?.isLocked) {
      return null;
    }
    return (
      <div className="container h-full fv_uploader_container rounded-lg">
        <div className="flex">
          <div className={`uploader h-full mx-auto w-full ${ file && file.length ? "full" : "empty" }`}>
            {(!transfer?.purging || transfer?.isLocked) && (
              <Dropzone
                getUploadParams={this.getUploadParams}
                onChangeStatus={this.handleChangeStatus}
                onSubmit={this.handleSubmit}
                InputComponent={(props) => {
                  // Si des fichiers sont présents, utiliser le contexte 'form'
                  const context = file && file.length > 0 ? 'form' : 'home';
                  return (
                    <UploaderInputComponent
                      {...{
                        ...props,
                        layoutContext: context,
                        isMainUploader: true,
                        dispatch: this.props.dispatch,
                        isFilesLoading: this.state.isFilesLoading,
                        file: this.props.file,
                        transfer: this.props.transfer,
                        user: this.props.user,
                        getFilesFromEvent: (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) =>
                          this.getFilesFromEvent(e),
                        // Ajouter detectConnectionQuality
                        detectConnectionQuality: async () => {
                          try {
                            const { quality } = await transferApi.getConnectionDetails();
                            return quality;
                          } catch (error) {
                            return 'unknown';
                          }
                        }
                      }}
                    />
                  );
                }}
                LayoutComponent={(props: ILayoutProps) => (
                  <LayoutComponent {...props} role={user?.role ?? 0} submitCb={this.onSubmitCb} isModalOpen={isModalOpen} />
                )}
                getFilesFromEvent={this.getFilesFromEvent}
                accept="*"
                autoUpload={false}
                submitButtonContent={null}
              />
            )}
            {!online && !app?.SET_APP_ERROR && (
              <div className="fixed h-full w-full text-white text-center network-container">
                <LoaderComponent text={i18n._("waiting_network_connexion_label")} type="network_error" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : defaultTransfer;
  return { user: state.user?.user, file: state.file, transfer: activeTransfer, app: state.app };
};

export default connect(mapStateToProps)(UploaderComponent);