import React from "react";
import "./Brand.css";

import { images } from "../../../assets";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { i18n, isMobile } from "../../../utils";
import { connect } from "react-redux";
import { fileAction, transferAction } from "../../../Store";

interface BrandProps extends RouteComponentProps {
  mode?: string;
  file: any;
  activeTransferId: string;
  resetTransfer: (fLen: any, transferId: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  resetTransfer: (fLen: any, transferId: string) => {
    dispatch(transferAction.purgeInfos(transferId));
    dispatch(fileAction.purgeFile());
  },
});

const mapStateToProps = (state: any) => ({
  file: state.file,
  activeTransferId: state.transfer.activeTransferId
});

const Brand = ({ mode, resetTransfer, file, activeTransferId }: BrandProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    resetTransfer(file.length, activeTransferId);
    
    // Créer un nouvel objet URLSearchParams pour ne conserver que le paramètre de langue
    const newParams = new URLSearchParams();
    
    // Récupérer le paramètre de langue depuis l'URL actuelle
    const currentParams = new URLSearchParams(window.location.search);
    const urlLang = currentParams.get("hl");
    
    // Ou depuis localStorage si non présent dans l'URL
    const storedLang = localStorage.getItem("lang");
    const langCode = urlLang || (storedLang ? storedLang.substring(0, 2) : null);
    
    // Ajouter le paramètre de langue s'il existe et n'est pas le français
    if (langCode && langCode !== 'fr') {
      newParams.set("hl", langCode);
    }
    
    // Rediriger vers la page d'accueil avec uniquement le paramètre de langue (sauf pour le français)
    window.location.href = newParams.toString() ? `/?${newParams.toString()}` : "/";
  };

  return (
    <div className={"p-0 m-0"}>
      <a
        href="#"
        onClick={handleClick}
        title={i18n._("fv_slogan")}
      >
        <img
          className={`brand logo ${isMobile ? "mobile" : "enlarged"}`}
          src={mode === "light" ? images.LogoLight : images.Logo}
          alt={i18n._("fv_logo_alt")}
        />
      </a>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Brand));