import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userApi } from '../../Api';
import UpgradeModal from '../Modal/UpgradeModal';

interface UpgradeButtonProps {
  permissions: any;
  user_id: string; // Rendre user_id obligatoire
  className?: string;
  buttonText?: string;
}

/**
 * Composant bouton pour ouvrir le modal de mise à niveau
 * Peut être utilisé partout dans l'application où un bouton de mise à niveau est nécessaire
 */
const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  permissions,
  user_id,
  className = "bg-primary hover:bg-primary/80 text-white font-bold py-2 px-4 rounded transition-colors",
  buttonText = "Mettre à niveau"
}) => {
  // État pour gérer l'ouverture/fermeture du modal
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  
  // Hooks Redux
  const dispatch = useDispatch();
  const reduxToken = useSelector((state: any) => state.user?.token);

  // Fonction pour ouvrir le modal
  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  // Fonction pour fermer le modal
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  // Fonction pour gérer la mise à niveau
  const handleUpgrade = useCallback(async (newOffer: any) => {
    // Vérification explicite de l'ID utilisateur
    if (!user_id) {
      console.error('handleUpgrade: user_id is required');
      return { error: { message: 'ID utilisateur manquant' } };
    }

    try {
      console.log('handleUpgrade: starting upgrade process', {
        userId: user_id,
        offerDetails: newOffer
      });
      const token = localStorage.getItem('fv-auth-token') || reduxToken;
      
      if (!token) {
        console.error('Token d\'authentification manquant');
        return { error: { message: 'Token d\'authentification manquant' } };
      }
      
      return await userApi.createUpgradeSession({
        user_id,
        newOffer,
        token,
        currentPermissions: permissions
      });
    } catch (error) {
      console.error('Erreur lors de la mise à niveau:', error);
      return { error: { message: 'Une erreur est survenue lors de la mise à niveau' } };
    }
  }, [user_id, permissions, reduxToken]);

  // Vérifier que l'ID utilisateur est disponible
  if (!user_id) {
    console.error('UpgradeButton: Missing user_id');
    return null;
  }

  return (
    <>
      <button
        onClick={handleOpenModal}
        className={className}
      >
        {buttonText}
      </button>

      {/* Modal de mise à niveau */}
      <UpgradeModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        currentPermissions={permissions}
        onUpgrade={handleUpgrade}
        user_id={user_id}
      />
    </>
  );
};

export default UpgradeButton;