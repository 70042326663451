import React from "react";
import "./Receive.css";
import { Plus, AlertCircle, CheckCircle } from "lucide-react";
import { HistoryComponent } from "../Shared";
import { i18n } from "../../../../utils";
import { transferApi } from "../../../../Api";
import { connect } from "react-redux";
import { ActionInputComponent } from "../../../Button";
import { QRCodeComponent } from "../../../QRCodeComponent";
import Toast, { ToastType } from "../../../Toast/Toast";

interface Props {
  title: string;
  user?: any;
}

interface State {
  success?: boolean;
  error?: any;
  transfer_recipients?: string;
  transfer_name?: string;
  isLoading?: boolean;
  searchQuery: string;
  stats: {
    successfulTransfers: number;
    totalDataTransferred: string;
  };
  periodFilter: 'month' | 'year';
  toast: {
    isVisible: boolean;
    type: ToastType;
    message: string;
  };
}

class ReceiveComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transfer_recipients: "",
      transfer_name: "",
      success: false,
      error: null,
      isLoading: false,
      searchQuery: '',
      stats: {
        successfulTransfers: 0,
        totalDataTransferred: "0 Ko"
      },
      periodFilter: 'month',
      toast: {
        isVisible: false,
        type: 'success' as ToastType,
        message: ''
      }
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    return this.setState({
      ...this.state,
      [evt.target.name]: value
    });
  };

  handleNewReceive = async () => {
    this.setState({ ...this.state, isLoading: true });
    const { transfer_name, transfer_recipients } = this.state;
    const transfer = await transferApi.createNewReceive({
      transfer_name,
      transfer_recipients: [transfer_recipients],
    });

    if (transfer.error)
      this.setState({
        ...this.state,
        success: false,
        error: transfer.error,
        isLoading: false,
      });

    this.setState({
      error: null,
      success: true,
      transfer_recipients: "",
      transfer_name: "",
      isLoading: false,
    });
  };

  handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    const target = evt.target as HTMLFormElement;
    target.className += " was-validated";

    let errors = [];
    const { transfer_recipients } = this.state;

    if (!transfer_recipients || !transfer_recipients.length)
      errors.push("transfer_recipients");

    const emailField = document.querySelector('input[type="email"]');
    if (emailField instanceof HTMLInputElement) {
      if (!!errors.length) {
        emailField.setCustomValidity('invalid');
        emailField.classList.add("is-invalid");
        emailField.classList.remove("is-valid");
        return false;
      } else {
        emailField.setCustomValidity('');
        emailField.classList.add("is-valid");
        emailField.classList.remove("is-invalid");
      }
    }

    await this.handleNewReceive();
  };

  handlePeriodChange(period: 'month' | 'year'): void {
    this.setState({ periodFilter: period });
  }

  updateStats = (filteredHistory: any[]): void => {
    const successfulTransfers = filteredHistory.filter(
      transfer => transfer.transfer_status === 0 || transfer.transfer_status === 1
    ).length;

    const totalSize = filteredHistory.reduce((total, transfer) => {
      return total + (transfer.transfer_size || 0);
    }, 0);

    this.setState({
      stats: {
        successfulTransfers,
        totalDataTransferred: this.formatSize(totalSize)
      }
    });
  }

  formatSize(bytes: number): string {
    const units = ['o', 'Ko', 'Mo', 'Go', 'To'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
  }

  handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ searchQuery: e.target.value });
  }

  showToast = (type: ToastType, message: string) => {
    this.setState({
      toast: {
        isVisible: true,
        type,
        message
      }
    });
  };

  hideToast = () => {
    this.setState({
      toast: {
        ...this.state.toast,
        isVisible: false
      }
    });
  };

  render = () => {
    const { error, success, isLoading, transfer_recipients, searchQuery, stats, periodFilter, toast } = this.state;
    const { title, user } = this.props;

    const hasPermission = user?.permissions?.transfer_tracking?.value;

    if (!hasPermission) {
      return (
        <div className="mx-auto px-4 py-8">
          <div className="text-center">
            <h3 className="text-xl font-bold text-gray-700 mb-4">
              {i18n._("permission_denied")}
            </h3>
            <p className="text-gray-600">
              {i18n._("transfer_tracking_required")}
            </p>
          </div>
        </div>
      );
    }

    return (
      <>
      <div className="max-w-[100rem] px-4 sm:px-6 lg:px-8 mx-auto">
        {/* En-tête */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-primary flex items-center">
            <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
            {title}
          </h1>
        </div>

        {/* Grille principale */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Section de gauche : Stats et QR Code */}
          <div className="space-y-8">
            {/* Statistiques */}
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 mb-4 flex items-center">
                <span className="w-2 h-8 bg-accent rounded-r mr-3"></span>
                {i18n._("receive_stats_title")}
              </h2>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2 mb-2">
                  <span className="text-sm text-gray-600 dark:text-neutral-400">
                    {i18n._("period_label")} :
                  </span>
                  <select
                    value={this.state.periodFilter}
                    onChange={(e) => this.handlePeriodChange(e.target.value as 'month' | 'year')}
                    className="py-2 px-3 pe-9 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                  >
                    <option value="month">{i18n._("current_month")}</option>
                    <option value="year">{i18n._("rolling_year")}</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-primary/10 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">{i18n._("files_received")}</p>
                    <p className="text-2xl font-bold text-primary">{this.state.stats.successfulTransfers}</p>
                  </div>
                  <div className="bg-secondary/10 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">{i18n._("data_received")}</p>
                    <p className="text-2xl font-bold text-secondary">{this.state.stats.totalDataTransferred}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* QR Code et Lien */}
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 mb-4 flex items-center">
                <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                {i18n._("deposit_link_title")}
              </h2>
              
              <div className="space-y-6">
                {/* Lien de dépôt */}
                <div>
                  <ActionInputComponent
                    customClass="receive"
                    onCopy={() => {
                      this.showToast('success', i18n._("upload_finished_copied_label") || "Lien copié avec succès !");
                    }}
                    mailTo={{
                      subject: i18n._("fv_creation_deposit_card_mailto_subject"),
                      body: i18n._("fv_creation_deposit_card_mailto_body", [
                        encodeURIComponent(user?.deposit_link),
                      ]),
                      enabled: true,
                      cb: () => console.log("MailTo opened"),
                    }}
                    navigateTo={{
                      enabled: true,
                      cb: () => console.log("Link opened"),
                    }}
                    value={user?.deposit_link}
                  />
                </div>

                {/* QR Code avec fond */}
                <div className="flex justify-center">
                  <div className="bg-gray-50 dark:bg-neutral-700/50 p-6 rounded-lg">
                    <div className="flex flex-col items-center space-y-4">
                      <QRCodeComponent link={user?.deposit_link} size={150} />
                      <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                        {i18n._("scan_qr_deposit_link")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section de droite : Formulaire de réception */}
          <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
              {i18n._("new_receive_label")}
            </h2>

            <form onSubmit={this.handleSubmit} className="space-y-6">
              {(success || error) && (
                <div className={`p-4 rounded-lg ${error ? 'bg-red-100 text-red-700 dark:bg-red-900/20 dark:text-red-400' : 'bg-green-100 test-emerald-500-700 dark:bg-green-900/20 dark:test-emerald-500'}`}>
                  <div className="flex items-center">
                    {error ?
                      <AlertCircle className="h-5 w-5 mr-2" /> :
                      <CheckCircle className="h-5 w-5 mr-2" />
                    }
                    <span>{i18n._("receive_success_label")}</span>
                  </div>
                </div>
              )}

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {i18n._("form_upload_transfer_email_label")}
                  </label>
                  <input
                    disabled={isLoading}
                    type="email"
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition-all disabled:opacity-50 disabled:cursor-not-allowed dark:bg-neutral-700 dark:border-neutral-600"
                    onChange={this.handleChange}
                    name="transfer_recipients"
                    value={transfer_recipients}
                    placeholder="email@example.com"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {i18n._("form_upload_transfer_name_label")}
                  </label>
                  <input
                    disabled={isLoading}
                    type="text"
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition-all disabled:opacity-50 disabled:cursor-not-allowed dark:bg-neutral-700 dark:border-neutral-600"
                    onChange={this.handleChange}
                    name="transfer_name"
                    value={this.state.transfer_name}
                    placeholder="Nom du transfert"
                  />
                </div>
              </div>

              <div className="pt-4">
                <button
                  disabled={isLoading || !transfer_recipients?.length}
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 focus:ring-2 focus:ring-primary focus:ring-offset-2 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                  ) : (
                    <>
                      <Plus className="h-5 w-5 mr-2" />
                      <span>{i18n._("new_receive_label")}</span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>

          {/* Section Historique */}
          <div className="col-span-1 lg:col-span-2">
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <div className="flex flex-col mb-4 gap-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
                  <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                  {i18n._("receive_history_title")}
                </h2>
                
                <div className="relative w-full">
                  <input
                    type="text"
                    placeholder={i18n._("search_transfer_placeholder")}
                    className="pl-8 py-2 px-3 pe-9 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                  />
                  <svg
                    className="absolute left-2 sm:left-3 top-2 sm:top-2.5 w-4 sm:w-5 h-4 sm:h-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>

              <HistoryComponent
                key={`history-${this.state.periodFilter}`}
                type="receive"
                searchQuery={searchQuery}
                onSearch={(query: string) => this.setState({ searchQuery: query })}
                onDataFiltered={(filteredData: any[]) => this.updateStats(filteredData)}
                defaultPeriod={this.state.periodFilter}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Toast de notification */}
      <Toast
        type={toast.type}
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={this.hideToast}
      />
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(ReceiveComponent);
