import React from 'react';

interface PermissionItemProps {
  icon: React.ReactNode;
  label: string;
  formattedValue: React.ReactNode;
}

const PermissionItem: React.FC<PermissionItemProps> = React.memo(({ icon, label, formattedValue }) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center space-x-3">
      <span className="text-gray-500 dark:text-gray-400">
        {icon}
      </span>
      <span className="text-gray-700 dark:text-gray-300">
        {label}
      </span>
    </div>
    <span className="font-medium text-gray-900 dark:text-gray-100 flex items-center">
      {formattedValue}
    </span>
  </div>
), (prev, next) => 
  prev.label === next.label && 
  prev.formattedValue === next.formattedValue
);

PermissionItem.displayName = 'PermissionItem';

export default PermissionItem;
