import axios from "axios";
import { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { config } from "../config";
import { logger } from "../utils/logger";
import { StatisticsResponse } from "../types/statistics";

const tmpLogger = logger({ service: "StatisticsApi" });

tmpLogger.info("Configuration de statisticsApi:", {
  apiUrl: config.apiUrl,
  env: config.env
});

// Utiliser l'instance axios par défaut qui a déjà les intercepteurs d'authentification
// Configuration des retries avec des délais croissants
axiosRetry(axios, {
  retries: 2, // Maximum 2 tentatives
  retryDelay: (retryCount) => {
    return retryCount * 5000; // 5s puis 10s
  },
  retryCondition: (error: AxiosError) => {
    // Ne retente que pour certains types d'erreurs
    if (error.code === 'ECONNABORTED') {
      return true;
    }
    if (error.response?.status && error.response?.status >= 500) {
      return true;
    }
    return false;
  }
});


export const statisticsApi = {
  async getStatistics({ start_date, end_date }: { start_date: string; end_date: string }): Promise<StatisticsResponse> {
    try {
      tmpLogger.info("[StatisticsApi] Requête de statistiques:", {
        start_date,
        end_date
      });

      const response = await axios.get('/statistics', {
        params: {
          start_date,
          end_date,
        },
      });

      if (!response.data) {
        throw new Error("Aucune donnée reçue");
      }

      return response.data;
    } catch (error: any) {
      tmpLogger.error("[StatisticsApi] Erreur:", error);
      return {
        error: {
          code: error.code || 'ERROR',
          message: error.message || 'Une erreur est survenue lors de la récupération des statistiques'
        }
      };
    }
  }
};