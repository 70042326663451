import React from 'react';
import { useGroupUsageStats } from '../../hooks/useGroupUsageStats';
import { UserUsageStats } from '../../Api/usageApi';
import { BarChart2, TrendingDown, TrendingUp, Users } from 'lucide-react';
import { i18n } from "../../utils";

const GroupUsageStats: React.FC = () => {
  const { stats, loading, error, refresh } = useGroupUsageStats();

  if (loading) {
    return (
      <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
        <div className="custom-pulse space-y-4">
          <div className="h-8 bg-gray-200 dark:bg-neutral-700 rounded w-1/4"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="bg-gray-50 dark:bg-neutral-700 rounded-lg p-6">
                <div className="h-6 bg-gray-200 dark:bg-neutral-600 rounded w-3/4 mb-4"></div>
                <div className="h-8 bg-gray-200 dark:bg-neutral-600 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 rounded-lg p-4 flex items-center space-x-3">
        <BarChart2 className="text-red-500 dark:text-red-400" />
        <p className="text-red-700 dark:text-red-100">{error}</p>
      </div>
    );
  }

  if (!stats?.users?.length) {
    return (
      <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
          <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
          <Users className="mr-2" />
          {i18n._("groupUsage_empty_header")}
        </h2>
        <p className="text-gray-500 dark:text-gray-400">
          {i18n._("groupUsage_empty_description")}
        </p>
      </div>
    );
  }

  const formatBytes = (bytes: number): string => {
    return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} ${i18n._("usage_stats_size_unit")}`;
  };

  const formatPrice = (price: number): string => {
    return i18n._("usage_stats_price_format", [price.toFixed(2)]);
  };

  const getTrend = (current: number, previous: number) => {
    if (current === previous) return null;
    const Icon = current > previous ? TrendingUp : TrendingDown;
    const color = current > previous ? 'text-red-500' : 'text-green-500';
    return <Icon className={color} size={16} />;
  };

  return (
    <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
        <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
        <Users className="mr-2" />
        {i18n._("groupUsage_header")}
      </h2>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-200 dark:border-neutral-700">
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                {i18n._("groupUsage_table_user")}
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                {i18n._("groupUsage_table_currentPeriod")}
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                {i18n._("groupUsage_table_previousMonth")}
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                {i18n._("groupUsage_table_total")}
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                {i18n._("groupUsage_table_trend")}
              </th>
            </tr>
          </thead>
          <tbody>
            {stats.users.map((user: UserUsageStats) => (
              <tr key={user.id} className="border-b border-gray-200 dark:border-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-700/50">
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-700 dark:text-gray-300">
                      {user.name}
                      {user.isTeamLeader && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-primary text-white">
                          {i18n._("groupUsage_teamLeader")}
                        </span>
                      )}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">{user.email}</span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-700 dark:text-gray-300">
                      {formatBytes(user.currentPeriod.usage)}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {formatPrice(user.currentPeriod.estimatedCost)}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-700 dark:text-gray-300">
                      {formatBytes(user.previousMonth.usage)}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {formatPrice(user.previousMonth.cost)}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-700 dark:text-gray-300">
                      {formatBytes(user.total.usage)}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {formatPrice(user.total.cost)}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  {getTrend(user.currentPeriod.usage, user.previousMonth.usage)}
                </td>
              </tr>
            ))}
            {/* Total Row */}
            <tr className="bg-gray-50 dark:bg-neutral-700/50 font-semibold">
              <td className="px-4 py-4 text-gray-700 dark:text-gray-300">
                {i18n._("groupUsage_total")}
              </td>
              <td className="px-4 py-4">
                <div className="flex flex-col">
                  <span className="text-gray-700 dark:text-gray-300">
                    {formatBytes(stats.total.usage)}
                  </span>
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {formatPrice(stats.total.cost)}
                  </span>
                </div>
              </td>
              <td className="px-4 py-4"></td>
              <td className="px-4 py-4"></td>
              <td className="px-4 py-4"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupUsageStats;
