import React, { useState } from "react";
import { ChevronDown, X } from "lucide-react";
import { i18n } from "../../../../../utils";
import { userApi } from "../../../../../Api";
import * as userAction from "../../../../../Store/Actions/userAction";
import ActionInputComponent from "../../../../../Components/Button/ActionInputComponent";
import Toast from "../../../../../Components/Toast/Toast";
//@ts-ignore
import SyntaxHighlighter from "react-syntax-highlighter";
//@ts-ignore
import { solarizedLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { isMobile } from "../../../../../utils";

const ITransferToDisplay = `
  interface ITransfer {
    transfer_id: string;
    transfer_name: string;
    transfer_expiration: Date | null;
    bucket_id: string;
    transfer_size: number;
    transfer_status: number;
    uploader_ip: string|null;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
    transfer_type: number;
    transfer_recipients: string[];
    transfer_sender: string;
    transfer_password: string|null;
    transfer_message: string|null;
    transfer_mail_auth: boolean;
    for_team: boolean;
    auto_remove: boolean;
    transfer_provider: string;
    consumption: {
      duration: number;
      gCO2: number;
      kWh: number;
      kms: number;
      size: number;
    };
  }
`;

const availableApis = [
  {
    method: "GET",
    path: "/api/v2/user/transfers",
    url: `${document.location.origin}/api/v2/user/transfers?key=<YOUR_API_KEY>`,
    description: "api_key_gettransfers_description",
    response: ITransferToDisplay,
  },
];

export function ApiKeyComponent({ user, dispatch }: any) {
  const [apiKeyValue, setApiKeyValue] = useState(user?.api_key?.api_key || "");
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState<string | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const updateApiKey = async (action: "create" | "regenerate" | "revoke") => {
    try {
      setUpdating(true);
      const response = await userApi.updateUserApiKey(user, action);
      setTimeout(() => {
        dispatch(userAction.loginUser(response));
        const newApiKey = response?.user?.api_key?.api_key || "";
        setApiKeyValue(newApiKey);
        setUpdating(false);

        // Définir le message de toast en fonction de l'action
        switch(action) {
          case "create":
            setToastMessage(i18n._("api_key_created_success"));
            break;
          case "regenerate":
            setToastMessage(i18n._("api_key_regenerated_success"));
            break;
          case "revoke":
            setToastMessage(i18n._("api_key_revoked_success"));
            break;
        }
      }, 0);
    } catch (e) {
      setUpdating(false);
      setToastMessage(i18n._("api_key_action_error"));
    }
  };

  const handleCloseToast = () => {
    setToastMessage(null);
  };

  return (
    <>
      <div className="mt-8 w-full md:w-1/2 sm:w-full mx-auto space-y-6">
        {!showApiKey ? (
          <button
            onClick={() => setShowApiKey(true)}
            className="text-blue-600 hover:text-blue-800 transition-colors text-sm font-medium"
          >
            {i18n._("api_key_add_tracking_key")}
          </button>
        ) : (
          <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
            <div className="flex justify-between items-center mb-6">
              <h5 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
                <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                {i18n._("api_key_title")}
              </h5>
              <button
                onClick={() => setShowApiKey(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="flex flex-col gap-4">
              <div className="w-full">
                <ActionInputComponent
                  value={apiKeyValue || i18n._("api_key_placeholder")}
                  inputProps={{
                    readOnly: true,
                  }}
                  customClass="mb-2"
                  onCopy={() => {
                    if (apiKeyValue) {
                      setToastMessage(i18n._("api_key_copied_success"));
                    }
                  }}
                />

                <div className="flex flex-col gap-4 mt-4">
                  {!apiKeyValue && (
                    <button
                      disabled={updating}
                      onClick={() => updateApiKey("create")}
                      className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 text-center font-medium text-base"
                    >
                      {i18n._("api_key_generate_key")}
                    </button>
                  )}

                  <button
                    onClick={() => setOpen(true)}
                    className="w-full px-4 py-3 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200 text-center font-medium text-base flex items-center justify-center"
                  >
                    {i18n._("api_key_usage_documentation")}
                  </button>

                  {apiKeyValue && (
                    <div className="flex gap-2">
                      <button
                        disabled={updating}
                        onClick={() => updateApiKey("regenerate")}
                        className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
                      >
                        {i18n._("api_key_regenerate")}
                      </button>
                      <button
                        disabled={updating}
                        onClick={() => updateApiKey("revoke")}
                        className="flex-1 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:opacity-50"
                      >
                        {i18n._("api_key_revoke")}
                      </button>
                    </div>
                  )}

                  <span className="text-red-500 italic text-sm block">
                    {i18n._("api_key_expiration_warning")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {open && (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-neutral-900 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-auto shadow-2xl dark:border dark:border-neutral-700">
            <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-neutral-700">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-neutral-100">
                {i18n._("api_key_modal_title")}
              </h3>
              <button
                onClick={() => setOpen(false)}
                className="p-1 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-full"
              >
                <X className="h-6 w-6 text-gray-600 dark:text-neutral-400" />
              </button>
            </div>
            
            <div className="p-4">
              {availableApis.map((apiItem, k) => (
                <div key={`api_${k}`} className="border dark:border-neutral-700 rounded-lg mb-4">
                  <button
                    className="w-full px-4 py-3 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-neutral-800 transition-colors"
                    onClick={() => setExpandedPanel(expandedPanel === `api_${k}` ? null : `api_${k}`)}
                  >
                    <span className="font-mono text-sm text-gray-700 dark:text-neutral-300">
                      {`${apiItem.method} - ${apiItem.path}?key=<YOUR_API_KEY>`}
                    </span>
                    <ChevronDown
                      className={`h-5 w-5 transform transition-transform text-gray-600 dark:text-neutral-400 ${
                        expandedPanel === `api_${k}` ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  
                  {expandedPanel === `api_${k}` && (
                    <div className="p-4 border-t dark:border-neutral-700">
                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-4">
                        <div className="md:col-span-2 font-semibold text-gray-700 dark:text-neutral-300">
                          {i18n._("api_key_method_label")}
                        </div>
                        <div className="md:col-span-10 text-gray-600 dark:text-neutral-400">{apiItem.method}</div>
                      </div>
                      
                      <hr className="my-4 border-gray-200 dark:border-neutral-700" />
                      
                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-4">
                        <div className="md:col-span-2 font-semibold text-gray-700 dark:text-neutral-300">
                          {i18n._("api_key_url_label")}
                        </div>
                        <div className="md:col-span-10 break-all text-gray-600 dark:text-neutral-400">
                          {apiItem.url.replace(
                            "<YOUR_API_KEY>",
                            apiKeyValue || "<YOUR_API_KEY>"
                          )}
                        </div>
                      </div>
                      
                      <hr className="my-4 border-gray-200 dark:border-neutral-700" />
                      
                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-4">
                        <div className="md:col-span-2 font-semibold text-gray-700 dark:text-neutral-300">
                          {i18n._("api_key_description_label")}
                        </div>
                        <div className="md:col-span-10 text-gray-600 dark:text-neutral-400">
                          {i18n._(apiItem.description)}
                        </div>
                      </div>

                      <hr className="my-4 border-gray-200 dark:border-neutral-700" />

                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-4">
                        <div className="md:col-span-2 font-semibold text-gray-700 dark:text-neutral-300">
                          {i18n._("api_key_security_label")}
                        </div>
                        <div className="md:col-span-10 text-gray-600 dark:text-neutral-400">
                          <ul className="list-disc list-inside space-y-2">
                            <li>{i18n._("api_key_security_tip_1")}</li>
                            <li>{i18n._("api_key_security_tip_2")}</li>
                            <li>{i18n._("api_key_security_tip_3")}</li>
                          </ul>
                        </div>
                      </div>

                      <hr className="my-4 border-gray-200 dark:border-neutral-700" />
                      
                      <div className="w-full">
                        <div className="text-gray-700 dark:text-neutral-300 font-semibold mb-2">
                          {i18n._("api_key_response_type_label")}
                        </div>
                        <SyntaxHighlighter
                          language="typescript"
                          style={solarizedLight}
                          className="rounded-md !bg-gray-50 dark:!bg-neutral-800 dark:text-neutral-300"
                        >
                          {ITransferToDisplay}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800/50 rounded-lg">
                <h4 className="text-blue-800 dark:text-blue-300 font-semibold mb-2">
                  {i18n._("api_key_usage_warning_title")}
                </h4>
                <p className="text-blue-700 dark:text-blue-200">
                  {i18n._("api_key_usage_warning_description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Toast de confirmation */}
      {toastMessage && (
        <Toast
          type="success"
          message={toastMessage}
          isVisible={!!toastMessage}
          onClose={handleCloseToast}
        />
      )}
    </>
  );
}
