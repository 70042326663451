import React from "react";
import "./Uploader.css";
import Dropzone, { IFileWithMeta, IUploadParams } from "@jeffreyca/react-dropzone-uploader";
import uuid from "uuid";
import Toast from "../Toast/Toast";

import { config } from "../../config";
import { convertSize, calcConsumption, i18n } from "../../utils";
import { connect } from "react-redux";
import { appAction, fileAction, transferAction } from "../../Store";

import { 
  UploaderInputComponent, 
  LayoutComponent, 
  DepositBoxComponent 
} from "./Components";

import { LoaderComponent } from "../";
import transferApi from "../../Api/transferApi";
import { isMobile } from "react-device-detect";

const { getDroppedOrSelectedFiles } = require("html5-file-selector");

interface ConnectionQuality {
  quality: 'excellent' | 'good' | 'medium' | 'poor' | string;
}

interface SpeedSampling {
  INTERVAL: number;
  WINDOW_SIZE: number;
}

interface ConnectionQualityCache {
  quality: string;
  timestamp: number;
  ttl: number;
}

interface UploadConfig {
  MAX_CONCURRENT_UPLOADS: number;
  SPEED_SAMPLING: SpeedSampling;
  _connectionQualityCache: ConnectionQualityCache;
  _getConnectionQuality: () => Promise<string>;
  getMaxConcurrentUploads: () => Promise<number>;
  CHUNK_SIZE: (size: number) => Promise<number>;
}

const UPLOAD_CONFIG: UploadConfig = {
  MAX_CONCURRENT_UPLOADS: 100,
  SPEED_SAMPLING: {
    INTERVAL: 100,
    WINDOW_SIZE: 1000
  },
  // Cache pour stocker la qualité de connexion
  _connectionQualityCache: {
    quality: "unknown",
    timestamp: 0,
    ttl: 30000 // 30 secondes de TTL
  },
  // Fonction pour obtenir la qualité de connexion avec cache
  _getConnectionQuality: async (): Promise<string> => {
    const now = Date.now();
    // Si le cache est valide, utiliser la valeur en cache
    if (now - UPLOAD_CONFIG._connectionQualityCache.timestamp < UPLOAD_CONFIG._connectionQualityCache.ttl) {
      return UPLOAD_CONFIG._connectionQualityCache.quality;
    }
    
    try {
      const result = await transferApi.getConnectionDetails() as ConnectionQuality;
      // Mettre à jour le cache
      UPLOAD_CONFIG._connectionQualityCache = {
        quality: result.quality || "unknown",
        timestamp: now,
        ttl: 30000
      };
      return result.quality;
    } catch (error) {
      console.error("Erreur lors de la récupération de la qualité de connexion:", error);
      return "unknown";
    }
  },
  getMaxConcurrentUploads: async (): Promise<number> => {
    const quality = await UPLOAD_CONFIG._getConnectionQuality();
    switch (quality) {
      case "excellent": return 100;
      case "good": return 50;
      case "medium": return 30;
      case "poor": return 2;
      default: return 5;
    }
  },
  CHUNK_SIZE: async (size: number): Promise<number> => {
    const quality = await UPLOAD_CONFIG._getConnectionQuality();
    let min_size: number;
    switch (quality) {
      case "excellent": min_size = 200 * 1024 * 1024; break;
      case "good": min_size = 100 * 1024 * 1024; break;
      case "medium": min_size = 50 * 1024 * 1024; break;
      case "poor": min_size = 10 * 1024 * 1024; break;
      default: min_size = 10 * 1024 * 1024; break;
    }
    const max_parts = 100;
    const max_size = 2 * 1024 * 1024 * 1024;
    let chunkSize = min_size;
    while (size / chunkSize > max_parts && chunkSize < max_size) {
      chunkSize = Math.min(chunkSize * 2, max_size);
    }
    return chunkSize;
  }
};

interface TransferInfos {
  auto_remove: boolean;
  transfer_name?: string;
  message?: string;
  recipients?: string;
  sender?: string;
  transfer_password?: string;
  nb_files?: number;
  startTime?: number;
  filesProgress?: {
    [fileId: string]: {
      totalLoaded: number;
      fileSize: number;
    };
  };
}

interface Props {
  user: any;
  app: any;
  dispatch: Function;
  file: IFileWithMeta[];
  transfer: {
    transfers: {
      [key: string]: TransferInfos & {
        id?: string;
        purging?: boolean;
        isLocked?: boolean;
        isUploadFinished?: boolean;
        loaded?: number;
        totalSize?: number;
        progress?: {
          current: {
            timestamp: number;
            loaded: number;
            instantSpeed: number;
          };
          last: {
            timestamp: number;
            loaded: number;
          };
        };
      };
    };
    activeTransferId: string | null;
  };
  transferConfig?: any | undefined;
  children?: any | undefined;
  transfer_type?: number | undefined;
  show_cgu_warning?: boolean;
  uploader_email?: string;
  onDepositFormSubmit?: any;
  maximum_size?: number;
}

interface State {
  isModalOpen: boolean;
  files: IFileWithMeta[];
  messages: string[];
  isSubmited: boolean;
  isUploadFinished: boolean;
  totalSize: number;
  pgTransferId: number;
  isFilesLoading: boolean;
  totalLoaded: number;
  currentFileLoaded: number;
  successCopyLink: boolean;
  processingFiles: number;
  loadedFiles: number;
  transferHeaders: any;
  progress: {
    last: {
      timestamp: number;
      loaded: number;
    };
    current: {
      timestamp: number;
      loaded: number;
    };
  };
  isFetchingProgress: boolean;
  online: boolean;
  isDragActive: boolean;
  showSuccessToast: boolean;
}

const fileInterval: number = 10;

class UploaderV2Component extends React.Component<Props, State> {
  filesUploaded: number[] = [];
  filesNb: number = 0;
  counter: number = 0;
  testTime = 0;
  filesCount: number = 0;
  tmpFilesCount: number = 0;
  transferId: string;
  apiUrl: string;
  startTime: number;
  succededFiles: number;
  directories: any;
  progress: {
    last: {
      timestamp: number;
      loaded: number;
    };
    current: {
      timestamp: number;
      loaded: number;
    };
  };
  divider: number = 1;
  timer: any;
  lastRealProgress: number = 0;
  tmpProgress: number = 0;
  apiProgressInterval: any = null;
  filePercents: {[key: string]: {[key: string]: number}} = {};

  constructor(props: Props) {
    super(props);
    if (this.props.file.length) {
      this.props.dispatch(fileAction.purgeFile());
    }

    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    if (currentTransfer?.isLocked) {
      this.props.dispatch(transferAction.lockTransfer(this.props.transfer.activeTransferId || '', false));
    }

    this.apiUrl = config.apiUrl;
    this.succededFiles = 0;
    this.directories = {};
    this.transferId = this.props.transfer.activeTransferId || '';
    this.progress = {
      last: {
        timestamp: 0,
        loaded: 0,
      },
      current: {
        timestamp: 0,
        loaded: 0,
      },
    };

    this.state = {
      isModalOpen: false,
      isFilesLoading: false,
      pgTransferId: 0,
      files: [],
      messages: [],
      isSubmited: false,
      isUploadFinished: false,
      totalSize: 0,
      totalLoaded: 0,
      currentFileLoaded: 0,
      successCopyLink: false,
      processingFiles: 0,
      loadedFiles: 0,
      transferHeaders: undefined,
      progress: this.progress,
      isFetchingProgress: false,
      online: navigator.onLine,
      isDragActive: false,
      showSuccessToast: false
    };

    this.startTime = 0;

    window.addEventListener("offline", this.handleConnexionLost);
    window.addEventListener("online", this.handleConnexionRetreive);
  }

  handleConnexionLost = () => {
    console.log("[UploaderV2Component] handleConnexionLost - offline detected");
    this.setState({ ...this.state, online: false });
  };

  handleConnexionRetreive = () => {
    console.log("[UploaderV2Component] handleConnexionRetreive - online detected");
    //@ts-ignore
    setTimeout(() => this.setState({ ...this.state, online: true }), window.retryDelay);
  };

  _dragEnter = () => {
    window.removeEventListener("dragenter", this._dragEnter);
    window.removeEventListener("dragover", this._dragEnter);
    window.addEventListener("dragend", this._dragEnd);
    window.addEventListener("drop", this._dragEnd);
    window.addEventListener("mouseup", this._dragEnd);

    const input = document.getElementById("input-file");
    const uploaderFull = document.getElementsByClassName("uploader drop full")[0];
    const uploaderEmpty = document.getElementsByClassName("uploader drop")[0];

    if (input && input.style.position !== "fixed") {
      input.style.position = "fixed";
    }
    if (uploaderFull) {
      //@ts-ignore
      uploaderFull.style.backgroundColor = "rgba(255, 255, 255, .75)";
    }
    if (uploaderEmpty) {
      uploaderEmpty.setAttribute("data-status", "active");
    }
  };

  _dragEnd = () => {
    window.removeEventListener("dragend", this._dragEnd);
    window.removeEventListener("drop", this._dragEnd);
    window.removeEventListener("mouseup", this._dragEnd);
    window.addEventListener("dragenter", this._dragEnter);
    window.addEventListener("dragover", this._dragEnter);

    const input = document.getElementById("input-file");
    const uploaderEmpty = document.getElementsByClassName("uploader drop");

    if (input && input.style.position === "fixed") {
      input.style.position = "relative";
    }
    if (uploaderEmpty[0]) {
      uploaderEmpty[0].removeAttribute("data-status");
    }
  };

  setFilesLoading = (chosenFiles: any[]) =>
    new Promise((resolve) => {
      this.setState(
        {
          processingFiles: this.state.processingFiles + chosenFiles.length,
          isFilesLoading: true,
        },
        () => setTimeout(() => resolve(chosenFiles), 100)
      );
    });

  wrapName = (str: string): string => {
    return str.length > 53 ? `${str.substr(0, 50)}...` : str;
  };

  handleRemove = (file: IFileWithMeta): void => {
    if (file && file.remove) file.remove();
  };

  onProgress = async (XHRProgress: any, metaId: string, strIndex: string) => {
    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    if (currentTransfer?.purging) {
      window.stop();
      return;
    }
    if (!this.state.online) return;

    // Initialiser les structures de données pour le fichier si nécessaire
    if (!this.filePercents[metaId]) {
      this.filePercents[metaId] = {};
    }
    if (!this.filePercents[`${metaId}_loaded`]) {
      this.filePercents[`${metaId}_loaded`] = {};
    }
    if (!this.filePercents[`${metaId}_total`]) {
      this.filePercents[`${metaId}_total`] = {};
    }

    // Mettre à jour la progression du chunk
    const oneProgress = Math.max(0, Number(XHRProgress.loaded) || 0);
    const totalChunk = Math.max(0, Number(XHRProgress.total) || 0);
    this.filePercents[metaId][strIndex] = (oneProgress / totalChunk) * 100;
    this.filePercents[`${metaId}_loaded`][strIndex] = oneProgress;
    this.filePercents[`${metaId}_total`][strIndex] = totalChunk;

    // Calculer la progression totale pour ce fichier
    const fileLoaded = Object.values(this.filePercents[`${metaId}_loaded`])
      .reduce((sum: number, val: number) => sum + (Number(val) || 0), 0);

    // Calculer la progression globale de tous les fichiers
    let totalLoaded = 0;
    let totalSize = 0;
    Object.keys(this.filePercents).forEach(fileId => {
      if (!fileId.includes('_')) {
        const loadedValues = Object.values(this.filePercents[`${fileId}_loaded`]) as number[];
        const totalValues = Object.values(this.filePercents[`${fileId}_total`]) as number[];
        totalLoaded += loadedValues.reduce((sum: number, val: number) => sum + (Number(val) || 0), 0);
        totalSize += totalValues.reduce((sum: number, val: number) => sum + (Number(val) || 0), 0);
      }
    });

    const now = Date.now();

    // Calculer la vitesse instantanée sur une fenêtre glissante
    const speedWindow = UPLOAD_CONFIG.SPEED_SAMPLING.WINDOW_SIZE;
    const speedHistory = [{
      timestamp: now,
      loaded: totalLoaded
    }];

    // Garder uniquement les points dans la fenêtre de temps
    while (speedHistory.length > 0 && now - speedHistory[0].timestamp > speedWindow) {
      speedHistory.shift();
    }

    // Calculer la vitesse moyenne sur la fenêtre
    let instantSpeed = 0;
    if (speedHistory.length >= 2) {
      const oldestEntry = speedHistory[0];
      const timeWindow = (now - oldestEntry.timestamp) / 1000;
      const loadedInWindow = totalLoaded - oldestEntry.loaded;
      instantSpeed = timeWindow > 0 ? loadedInWindow / timeWindow : 0;
    }

    const progress = {
      last: this.state.progress.current,
      current: {
        loaded: totalLoaded,
        timestamp: now,
      },
    };

    // Mettre à jour filesProgress pour le fichier en cours
    const currentFile = this.props.file.find(f => f.meta.id === metaId);
    const filesProgress = {
      ...(currentTransfer?.filesProgress || {}),
      [metaId]: {
        totalLoaded: fileLoaded,
        fileSize: currentFile?.meta.size || totalChunk,
        speed: instantSpeed
      }
    };

    this.setState(
      {
        currentFileLoaded: fileLoaded,
        totalLoaded,
        progress,
      },
      () => {
        if (currentTransfer) {
          const elapsedTime = (now - (currentTransfer.startTime || now)) / 1000;
          this.props.dispatch(
            transferAction.setInfos({
              ...currentTransfer,
              loaded: totalLoaded,
              progress,
              filesProgress,
              speeds: {
                instant: instantSpeed,
                average: totalLoaded / (elapsedTime || 1)
              },
              consumption: {
                ...calcConsumption.forUpload(totalLoaded, elapsedTime),
                size: totalLoaded
              },
              estimatedTimeLeft: instantSpeed > 0 ? Math.ceil((totalSize - totalLoaded) / instantSpeed) : 0
            })
          );
        }
      }
    );
  };

  checkFile = (file: IFileWithMeta & any) => {
    const error = this.props.file.find((f: any) => f !== file)
      ? "Fichier déjà présent"
      : false;
    if (error) console.log("[UploaderV2Component] checkFile =>", error);
    return false;
  };

  getUploadParams = ({ file, meta }: IFileWithMeta & any): IUploadParams | Promise<IUploadParams> => {
    return { url: "" };
  };

  handleChangeStatus = (
    { meta, file, xhr }: any,
    status: any,
    allFiles: IFileWithMeta[]
  ) => {
    console.log("[UploaderV2Component] handleChangeStatus:", status, meta);

    if (status === "error_upload") {
      console.log("[UploaderV2Component] handleChangeStatus error_upload =>", meta, xhr);
    }

    // Réinitialiser l'état si on ajoute de nouveaux fichiers après un transfert terminé
    if (this.state.isUploadFinished) {
      this.setState({
        isUploadFinished: false,
        totalLoaded: 0,
        currentFileLoaded: 0,
        progress: {
          last: { timestamp: 0, loaded: 0 },
          current: { timestamp: 0, loaded: 0 }
        }
      });
    }

    if (("ready" === status || "removed" === status) && this.props.file) {
      this.filesCount += 1;

      if (this.filesCount - this.tmpFilesCount === fileInterval) {
        this.tmpFilesCount += fileInterval;
        this.setState({ ...this.state, loadedFiles: this.filesCount });
      }

      if (
        this.state.processingFiles === this.filesCount ||
        "removed" === status
      ) {
        let totalSize = 0;
        allFiles.forEach((f: IFileWithMeta) => {
          totalSize += "removed" !== f.meta.status ? f.meta.size : 0;
        });

        this.setState({ 
          totalSize, 
          loadedFiles: this.filesCount, 
          processingFiles: 0
        }, () => {
          this.filesCount = 0;
          console.log("[UploaderV2Component] handleChangeStatus => totalSize:", totalSize);
          this.props.dispatch(fileAction.setFile(allFiles));
          
          const activeTransferId = this.props.transfer.activeTransferId;
          if (!activeTransferId) {
            console.error("[handleChangeStatus] No active transfer ID found");
            return;
          }

          const currentTransfer = this.props.transfer.transfers[activeTransferId];
          if (currentTransfer) {
            this.transferId = activeTransferId; // Mettre à jour l'ID local
            this.props.dispatch(
              transferAction.setInfos({
                ...currentTransfer,
                transfer_id: activeTransferId, // S'assurer que l'ID est inclus
                totalSize,
                loaded: 0,
                isLocked: false,
                isUploadFinished: false,
                filesProgress: {},
                progress: {
                  current: { timestamp: Date.now(), loaded: 0 },
                  last: { timestamp: Date.now(), loaded: 0 }
                }
              })
            );
          }
        });
      }
    }

    if ("validation_error" === status) {
      alert("Fichier déjà présent");
    }
  };

  handleSubmit = async (files: any, allFiles: any) => {
    allFiles.forEach((f: any) => f.remove());
    this.setState({ isUploadFinished: false }, async () => {
      await this.resetTransfer();
    });
  };

  getFilesFromEvent = (e: any) => {
    this.testTime = Date.now();
    let allChosenFiles: any;
    return new Promise((resolve) => {
      this._dragEnd();
      
      // Réinitialiser l'état lors de l'ajout de nouveaux fichiers
      const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
      if (currentTransfer) {
        this.props.dispatch(
          transferAction.setInfos({
            ...currentTransfer,
            isLocked: false,
            isUploadFinished: false,
            loaded: 0,
            filesProgress: {},
            progress: {
              current: { timestamp: Date.now(), loaded: 0 },
              last: { timestamp: Date.now(), loaded: 0 }
            }
          })
        );
      }

      this.setState({ 
        isModalOpen: true, 
        isFilesLoading: true,
        isUploadFinished: false,
        totalLoaded: 0,
        currentFileLoaded: 0,
        progress: {
          last: { timestamp: 0, loaded: 0 },
          current: { timestamp: 0, loaded: 0 }
        }
      });

      return getDroppedOrSelectedFiles(e)
        .then((chosenFiles: any) => {
          allChosenFiles = chosenFiles;
          return this.setFilesLoading(chosenFiles).then(
            (chosenFiles) => (allChosenFiles = chosenFiles)
          );
        })
        .then((chosenFiles: any) => {
          resolve(
            allChosenFiles.map((f: any) => {
              f.name = escape(f.name);
              f.fullPath = f.fullPath ? escape(f.fullPath) : f.fullPath;

              Object.defineProperty(f.fileObject, "name", {
                writable: true,
                value: escape(f.fileObject.name),
              });

              if (f.webkitRelativePath) {
                f.fileObject.fullPath = f.webkitRelativePath;
              } else {
                f.fileObject.fullPath =
                  f.fullPath[0] !== "/" ? f.fullPath : f.fullPath.substr(1);
              }

              return f.fileObject;
            })
          );
        });
    }) as any;
  };

  renderCustomPreview = ({ meta, fileWithMeta, k }: any) => {
    const { name, status, size } = meta;
    if (!meta || this.state.isUploadFinished || status === "error_validation") {
      return null;
    }

    const arKLoaded = this.filePercents[`${meta.id}_loaded`]
      ? Object.values(this.filePercents[`${meta.id}_loaded`])
      : [0];
    const nTotal: number = meta.size;
    const nLoaded: number = arKLoaded.length
      ? (arKLoaded as number[]).reduce((n1, n2) => n1 + n2)
      : 0;
    const percent = (nLoaded * 100) / nTotal || 0;

    const dir_name: string[] = fileWithMeta.file?.fullPath?.split("/");
    dir_name?.pop();

    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];

    return (
      <div
        key={k}
        className={
          "preview w-full bg-white hover-info text-black mb-2 shadow-sm"
        }
      >
        <div className="flex flex-row w-full items-center hover-remove">
          <div className="w-[40px] flex items-center justify-center py-2">
            {!!dir_name && dir_name.length > 0 ? (
              <span className="wrapper-info relative group">
                <i className="fa fa-info text-gray-500 hover:cursor-pointer" />
                <label className="absolute hidden group-hover:block col-12 bg-white text-black p-2 z-10 border border-gray-300 text-sm w-[250px]">
                  <strong className="mr-1">{i18n._("file_path_label")}:</strong> 
                  {unescape(`/${dir_name.join("/")}/${name}`)} <br />
                  <strong className="mr-1">{i18n._("file_name_label")}:</strong> 
                  {unescape(name)} <br />
                  <strong className="mr-1">{i18n._("file_size_label")}:</strong> 
                  {convertSize(size)}
                </label>
              </span>
            ) : (
              <span className="wrapper-info relative group">
                <i className="fa fa-info text-gray-500 hover:cursor-pointer" />
                <label className="absolute hidden group-hover:block col-12 bg-white text-black p-2 z-10 border border-gray-300 text-sm w-[250px]">
                  <strong className="mr-1">{i18n._("file_name_label")}:</strong>
                  {unescape(name)} <br />
                  <strong className="mr-1">{i18n._("file_size_label")}:</strong>
                  {convertSize(size)}
                </label>
              </span>
            )}
          </div>

          <div className="flex-1 px-3">
            <div className="flex justify-between items-center mb-1">
              <span className="text-sm">{this.wrapName(unescape(name))}</span>
              {percent > 0 && (
                <span className="text-sm">{percent.toFixed(0)}%</span>
              )}
            </div>
            <progress
              className="w-full h-2 text-blue-600 bg-gray-200 rounded"
              max={100}
              value={percent}
            ></progress>
          </div>

          {!currentTransfer?.isLocked && (
            <div className="w-[40px] flex items-center justify-center">
              <i
                className="fa fa-times text-gray-600 hover:text-gray-900 cursor-pointer"
                title="Supprimer le fichier"
                onClick={() => this.handleRemove(fileWithMeta)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  onSubmitCb = async (transfer: any) => {
    console.log("[UploaderV2Component] onSubmitCb => Starting parallel upload", transfer);
    try {
      // S'assurer que nous avons un ID de transfert valide
      const activeTransferId = this.props.transfer.activeTransferId;
      if (!activeTransferId) {
        throw new Error("No active transfer ID found");
      }
      this.transferId = activeTransferId;
      
      this.setState({
        isSubmited: false,
        isUploadFinished: false,
        totalLoaded: 0,
        currentFileLoaded: 0,
        processingFiles: 0,
        loadedFiles: 0,
        transferHeaders: undefined,
        progress: {
          last: {
            timestamp: 0,
            loaded: 0,
          },
          current: {
            timestamp: 0,
            loaded: 0,
          }
        },
        isFetchingProgress: false
      });

      this.filePercents = {};
      this.filesUploaded = [];
      this.succededFiles = 0;

      // Configuration des uploads parallèles en utilisant la fonction mise en cache
      const getMaxConcurrentUploads = async () => {
        const quality = await UPLOAD_CONFIG._getConnectionQuality();
        switch (quality) {
          case "excellent": return 100;
          case "good": return 50;
          case "medium": return 30;
          case "poor": return 2;
          default: return 5;
        }
      };

      // Fonction pour uploader un seul fichier
      const uploadSingleFile = async (fileWMeta: IFileWithMeta) => {
        console.log("[UploaderV2Component] Starting upload for file:", fileWMeta.meta.name);
        
        this.filePercents[fileWMeta.meta.id] = {};
        this.filePercents[`${fileWMeta.meta.id}_loaded`] = {};
        this.filePercents[`${fileWMeta.meta.id}_total`] = {};

        const { size, fullPath, webkitRelativePath, name } = fileWMeta.file as any;

        const presignedPostData = await transferApi.getPresignedPostData(
          {
            size,
            fullPath: `${
              this.props?.uploader_email ? this.props?.uploader_email + "/" : ""
            }${fullPath || webkitRelativePath || name || fileWMeta.meta.name}`,
            name: fileWMeta.meta.name,
            type: fileWMeta.meta.type,
          },
          transfer
        );

        if (presignedPostData?.error) {
          throw presignedPostData?.error;
        }

        const file_id = presignedPostData.UploadId;
        const { file } = fileWMeta;

        await transferApi.uploadFileToS3(
          presignedPostData,
          file,
          (prg: ProgressEvent, strIndex: string) =>
            this.onProgress(prg, fileWMeta.meta.id, strIndex),
          transfer
        );

        this.setState(
          prevState => ({
            totalLoaded: prevState.totalLoaded + file.size,
            currentFileLoaded: 0,
          })
        );

        this.filesUploaded.push(file_id);
        console.log("[UploaderV2Component] File uploaded successfully:", file_id);
        return file_id;
      };

      // Gestion des uploads parallèles
      const uploadQueue = [...this.props.file];
      const inProgress = new Set();
      const results = [];

      while (uploadQueue.length > 0 || inProgress.size > 0) {
        const maxConcurrent = await getMaxConcurrentUploads();
        
        while (inProgress.size < maxConcurrent && uploadQueue.length > 0) {
          const fileWMeta = uploadQueue.shift()!;
          
          const uploadPromise = uploadSingleFile(fileWMeta)
            .then(fileId => {
              inProgress.delete(uploadPromise);
              results.push(fileId);
            })
            .catch(error => {
              inProgress.delete(uploadPromise);
              throw error;
            });

          inProgress.add(uploadPromise);
        }

        if (inProgress.size > 0) {
          await Promise.race(Array.from(inProgress));
        }
      }

      // Une fois tous les fichiers uploadés, finaliser le transfert
      return this.finalizeTransfer(transfer);
    } catch (e) {
      console.log("[UploaderV2Component] onSubmitCb => error:", e);
      this.props.dispatch(appAction.setAppError(e));
    }
  };

  resetTransfer = async () => {
    if (this.props.file && this.props.file.length) {
      await this.props.file.forEach(async (fWMita: IFileWithMeta) => {
        await fWMita.cancel();
        await fWMita.remove();
      });
    }

    this.setState({
      isSubmited: false,
      isUploadFinished: false,
      totalSize: 0,
      totalLoaded: 0,
      currentFileLoaded: 0,
      processingFiles: 0,
      loadedFiles: 0,
      transferHeaders: undefined,
      progress: {
        last: {
          timestamp: 0,
          loaded: 0,
        },
        current: {
          timestamp: 0,
          loaded: 0,
        }
      },
      isFetchingProgress: false,
      showSuccessToast: false
    });

    this.filePercents = {};
    this.filesUploaded = [];
    this.filesNb = 0;
    this.succededFiles = 0;
    
    // Garder l'ID du transfert actif
    this.transferId = this.props.transfer.activeTransferId || '';

    this.props.dispatch(fileAction.purgeFile());
    
    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    if (currentTransfer) {
      this.props.dispatch(transferAction.setInfos({
        ...currentTransfer,
        isUploadFinished: false,
        loaded: 0,
        totalSize: 0,
        consumption: {
          size: 0,
          gCO2: 0,
          kWh: 0,
          kms: 0
        }
      }));
    }
    
    return true;
  };

  render = () => {
    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    console.log("[UploaderV2Component] render() => isUploadFinished:", currentTransfer?.isUploadFinished);

    const { children } = this.props;
    const suffix = currentTransfer?.isUploadFinished
      ? "w-full no-padding"
      : "mx-auto";
    const colClass = !!this.props.file.length
      ? `w-full`
      : "w-full";
    const colClassSuffix =
      currentTransfer?.isUploadFinished || currentTransfer?.isLocked
        ? "uploading"
        : "";
    const { loadedFiles, processingFiles } = this.state;

    if (currentTransfer?.purging) {
      return null;
    }

    return (
      <div
        className={`
          uploader h-full
          fv_uploader_container
          fv_uploader_deposit
        `}
      >
        <Toast
          type="success"
          message={i18n._("upload_deposit_finished_title")}
          isVisible={this.state.showSuccessToast}
          onClose={() => {
            this.setState({ showSuccessToast: false }, () => {
              this.props.dispatch(fileAction.purgeFile());
              if (currentTransfer) {
                this.props.dispatch(transferAction.setInfos({
                  ...currentTransfer,
                  isUploadFinished: false,
                  loaded: 0,
                  totalSize: 0,
                  consumption: {
                    size: 0,
                    gCO2: 0,
                    kWh: 0,
                    kms: 0
                  }
                }));
              }
            });
          }}
        />

        <div className="flex flex-wrap">
          <div
            className={`
              uploader 
              h-full 
              ${colClass} 
              ${this.props.file.length && !(processingFiles > loadedFiles) ? "full" : "empty"} 
              ${suffix} 
              ${colClassSuffix}
              fv_uploader_deposit_${this.props.file.length ? "full" : "empty"}
            `}
          >
            {!!children &&
              children?.key !== "bottom" &&
              !(processingFiles > loadedFiles) && (
                <div className="container mx-auto my-4">
                  <div className="flex flex-wrap w-3/4 mx-auto">
                    <div className="w-full px-0">{children}</div>
                  </div>
                </div>
              )}

            {!currentTransfer?.purging && (
              <Dropzone
                classNames={{
                  dropzone: `
                    ${colClass}
                    uploader 
                    drop 
                    ${this.props.file.length && !(processingFiles > loadedFiles) ? "full" : "empty"} 
                    ${suffix} 
                    ${colClassSuffix} 
                    fv_uploader_${this.props.file.length ? "full" : "empty"} 
                    fv_uploader_deposit_${this.props.file.length ? "full" : "empty"}
                    ${isMobile ? "flex flex-col" : ""}
                  `,
                  inputLabel: "uploader-input-label",
                }}
                validate={this.checkFile}
                autoUpload={false}
                accept="*"
                getUploadParams={this.getUploadParams}
                LayoutComponent={
                  currentTransfer?.totalSize
                    ? ({ input, previews, submitButton, dropzoneProps, ...props }) => (
                        <LayoutComponent
                          input={input}
                          previews={previews}
                          submitButton={submitButton}
                          dropzoneProps={dropzoneProps}
                          maximum_size={this.props.transferConfig?.maximum_size}
                          submitCb={this.onSubmitCb}
                          resetCb={this.resetTransfer}
                          role={this.props?.user?.role}
                        />
                      )
                    : undefined
                }
                PreviewComponent={
                  currentTransfer?.totalSize
                    ? this.renderCustomPreview
                    : undefined
                }
                InputComponent={({ accept, onFiles, getFilesFromEvent }) => (
                  <>
                    <UploaderInputComponent
                      maximum_size={this.props.transferConfig?.maximum_size}
                      percent={(loadedFiles * 100) / processingFiles}
                      accept={accept}
                      onFiles={onFiles}
                      getFilesFromEvent={getFilesFromEvent}
                      isFilesLoading={processingFiles > loadedFiles}
                    >
                      {isMobile && !this.props.file.length && !this.props.show_cgu_warning && (
                          <p
                          style={{ left: 0, bottom: 0, minHeight: 20 }}
                          className="text-center text-white fv-desposit-file-nb fv_desposit_file_nb"
                        >
                          {`${currentTransfer?.nb_files || 0} ${i18n._("add_files_label")}`}
                        </p>
                      )}

                      {!!children && children?.key === "bottom" && !(processingFiles > loadedFiles) && (
                        <div className="w-full px-0 fv-deposit-uploader-child fv_deposit_uploader_child">
                          {children}
                        </div>
                      )}
                    </UploaderInputComponent>

                    {this.props.show_cgu_warning && this.props.onDepositFormSubmit && (
                      <DepositBoxComponent
                        isPro={!!this.props.transferConfig?.user_id}
                        user_email={this.props.user?.email || ""}
                        onDepositFormSubmit={this.props.onDepositFormSubmit}
                      />
                    )}
                  </>
                )}
                getFilesFromEvent={this.getFilesFromEvent}
                onChangeStatus={this.handleChangeStatus}
                canCancel={true}
                onSubmit={this.handleSubmit}
                styles={{
                  dropzoneActive: {
                    borderColor: "#fff",
                    backgroundColor: "rgba(255, 255, 255, .6)",
                  },
                  dropzoneReject: {
                    borderColor: "red",
                    backgroundColor: "rgba(255, 155, 155, .6)",
                  },
                  preview: { display: "none" },
                  previewImage: { display: "none" },
                }}
              />
            )}

            {!(this.state.online || this.props.app.SET_APP_ERROR) && (
              <div className="fixed top-0 left-0 h-full w-full text-white text-center fv_network_container bg-black/50 flex flex-col items-center justify-center">
                <LoaderComponent
                  text={i18n._("waiting_network_connexion_label")}
                  type={"network_error"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  finalizeTransfer = async (transfer: any) => {
    this.succededFiles = 0;
    const activeTransferId = this.props.transfer.activeTransferId;
    if (!activeTransferId) {
      console.error("[finalizeTransfer] No active transfer ID found");
      return await this.resetTransfer();
    }

    const currentTransfer = this.props.transfer.transfers[activeTransferId];
    if (!currentTransfer) {
      console.error("[finalizeTransfer] Transfer not found with ID:", activeTransferId);
      return await this.resetTransfer();
    }

    const totalTime = (Date.now() - (currentTransfer.startTime || Date.now())) / 1000;
    const consumption = calcConsumption.forUpload(this.state.totalSize, totalTime);

    try {
      const result = await transferApi.finalizeTransfer(
        {
          consumption: { ...consumption, size: this.state.totalSize },
          transfer_size: this.state.totalSize,
        },
        transfer,
        this.props?.uploader_email,
        true
      );
      if (result && result.transfer_link) {
        if (currentTransfer) {
          // Préparer l'état final du transfert
          const finalState = {
            ...currentTransfer,
            totalTime,
            isUploadFinished: true,
            isLocked: false,
            archived: false,
            link: `${config.ihmUrl}/d/${result.transfer_link}`,
            consumption: { ...consumption, size: this.state.totalSize },
            expiration_date: result.expiration_date,
            lastProgressUpdate: Date.now()
          };
// Log pour debug
console.log("[finalizeTransfer] Updating transfer state:", {
  id: activeTransferId,
  finalState
});

// Mettre à jour l'état du transfert
this.props.dispatch(transferAction.setInfos(finalState));

// Déterminer le type de transfert et les propriétés spécifiques
const transferType = this.props.transfer_type || 0;
const isReceive = transferType === 1;
const isDeposit = transferType === 3;

// Archiver le transfert pour qu'il apparaisse dans l'historique
this.props.dispatch(transferAction.archiveTransfer(activeTransferId, {
  ...finalState,
  transfer_name: result.transfer_name || finalState.transfer_name || (isReceive ? 'Réception de fichiers' : 'Dépôt de fichiers'),
  transfer_type: transferType, // Préserver le type de transfert original
  is_received: isReceive // Définir is_received=true uniquement pour les réceptions
}));

console.log(`[finalizeTransfer] Archiving transfer as type ${transferType} (${isReceive ? 'receive' : isDeposit ? 'deposit' : 'other'})`);

this.setState({ showSuccessToast: true });
          this.setState({ showSuccessToast: true });
        }
      } else {
        // Si le transfert a échoué, on réinitialise
        await this.resetTransfer();
      }
    } catch (error) {
      console.error("[finalizeTransfer] Error:", error);
      await this.resetTransfer();
    }
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user?.user,
  file: state.file,
  transfer: state.transfer,
  app: state.app,
});

export default connect(mapStateToProps)(UploaderV2Component);
