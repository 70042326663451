import React from "react";
import { connect } from "react-redux";
import { Rocket, ChevronRight } from "lucide-react";
import { transferApi } from "../../../../Api";
import { transferAction, uiAction } from "../../../../Store/Actions";
import { convertSize, i18n } from "../../../../utils";
import { Go } from "../../../../Constants";

interface Props {
  dispatch: Function;
  transfer: {
    transfers: {
      [key: string]: {
        id?: string;
        totalSize?: number;
        maximum_size?: number;
      };
    };
    activeTransferId: string | null;
  };
  user: any;
  file: any;
  app?: any;
  submitCb: (transferResult: any) => void;
  maximum_size?: number;
  isPanelOpen?: boolean;
}

interface State {
  isFilesLoading: boolean;
}

const defaultLimitInGo: number = Go * 2;
const hackTransferType = Number(window.location.pathname.startsWith("/u/"));

class UploaderFormComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFilesLoading: false,
    };
  }

  isValidLimit = () => {
    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    const {
      maximum_size,
      user
    } = this.props;
    const totalSize = currentTransfer?.totalSize;

    const limit = maximum_size || user?.permissions?.upload_size?.value || defaultLimitInGo;

    if (totalSize && maximum_size) {
      return totalSize <= maximum_size;
    }

    if (totalSize && (maximum_size || currentTransfer?.maximum_size) &&
        totalSize > (maximum_size || (currentTransfer?.maximum_size || 0))) {
      return false;
    }

    return limit >= (totalSize || 0);
  };

  handleCustomSubmit = async () => {
    if (!this.isValidLimit()) return;
    const transferId = this.props.transfer.activeTransferId;
    if (!transferId) return;
    this.props.dispatch(transferAction.lockTransfer(transferId, true));
    const startTime = Date.now();
    const progress = {
      last: {
        timestamp: 0,
        loaded: 0,
      },
      current: {
        timestamp: 0,
        loaded: 0,
      },
    };
    this.props.dispatch(
      transferAction.setInfos({
        startTime,
        progress,
      })
    );

    try {
      const transfer = await transferApi.createTransfer({
        transfer_id: this.props.transfer.activeTransferId || undefined,
      });
      return this.props.submitCb(transfer);
    } catch (error) {
      console.error("Transfer creation failed:", error);
    }
  };

  handlePanelToggle = () => {
    const { dispatch, isPanelOpen } = this.props;
    dispatch(uiAction.setPanelState(!isPanelOpen));
  };

  render() {
    const { isPanelOpen } = this.props;
    const currentTransfer = this.props.transfer.transfers[this.props.transfer.activeTransferId || ''];
    const isValidLimit = this.isValidLimit();
    const limit = this.props.maximum_size || this.props?.user?.permissions?.upload_size?.value || defaultLimitInGo;

    return (
      <div className="w-full max-w-4xl mx-auto rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm"></div>
          <button
            onClick={this.handlePanelToggle}
            className="btn btn-ghost btn-sm gap-2"
          >
            <span>{convertSize(currentTransfer?.totalSize || 0)}</span>
            <ChevronRight 
              className={`transform transition-transform duration-300 ${
                isPanelOpen ? 'rotate-90' : ''
              }`}
            />
          </button>
        </div>


        {!isValidLimit && (
          <div className="text-error text-center mb-4">
            {i18n._("form_upload_transfer_limit_exceeded")}
          </div>
        )}
        <div className="space-y-6">
          {!this.props.user && (
            <button className="btn btn-outline btn-block gap-2 text-white/80">
              <Rocket className="w-5 h-5" />
              S'INSCRIRE
            </button>
          )}
          
          <button
            type="button"
            className="btn btn-primary btn-block text-white font-medium"
            onClick={this.handleCustomSubmit}
            disabled={!isValidLimit}
          >
            {i18n._("btn_transfer_label")}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user?.user,
  transfer: state.transfer,
  file: state.file,
  app: state.app,
  isPanelOpen: state.ui?.isPanelOpen
});

export default connect(mapStateToProps)(UploaderFormComponent);
