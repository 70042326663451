import React from 'react';
import { X, Maximize2, Clock } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { transferAction } from '../../Store/Actions';
import { convertSize, i18n } from '../../utils';
import { TransferProgress } from '../../Store/Actions/transferAction';

interface MinimizedTransferProps {
  transfer: {
    id: string;
    isLocked: boolean;
    isUploadFinished: boolean;
    isMinimized: boolean;
    loaded: number;
    totalSize: number;
    currentFileIndex: number;
    filesProgress: { [key: string]: FileProgress };
    progress: TransferProgress;
    startTime: number;
    totalTime: number;
    consumption: {
      size: number;
      gCO2: number;
      kWh: number;
      kms: number;
    };
    name?: string;
    expiration_date?: string;
    purging?: boolean;
  };
  onRestore: () => void;
}

interface FileProgress {
  chunks: { [key: string]: ChunkProgress };
  totalLoaded: number;
  fileSize: number;
}

interface ChunkProgress {
  loaded: number;
  total: number;
}

const MinimizedTransfer: React.FC<MinimizedTransferProps> = ({
  transfer,
  onRestore
}) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (window.confirm(i18n._("confirm_upload_cancelation_label"))) {
      dispatch(transferAction.purgeInfos(transfer.id));
    }
  };

  const progress = Math.min(100, Math.round((transfer.loaded * 100) / transfer.totalSize));
  const speed = transfer.progress.current.instantSpeed || 0;

  return (
    <div className="w-64 bg-base-200 rounded-lg shadow-lg p-3 space-y-2">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium truncate">
          {transfer.name || i18n._("unnamed_transfer")}
        </span>
        <div className="flex items-center gap-1">
          <button
            onClick={onRestore}
            className="btn btn-ghost btn-xs btn-square"
            title={i18n._("restore_transfer")}
          >
            <Maximize2 className="w-4 h-4" />
          </button>
          <button
            onClick={handleCancel}
            className="btn btn-ghost btn-xs btn-square text-error"
            title={i18n._("cancel_transfer")}
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="space-y-1">
        <div className="h-1.5 w-full bg-base-300 rounded-full overflow-hidden">
          <div
            className="h-full bg-primary transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="flex justify-between text-xs text-base-content/70">
          <span>{progress}%</span>
          <span>{convertSize(speed)}/s</span>
        </div>
      </div>

      {/* Info */}
      <div className="space-y-1 text-xs text-base-content/70">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Clock className="w-3 h-3" />
            <span>
              {convertSize(transfer.loaded)} / {convertSize(transfer.totalSize)}
            </span>
          </div>
          {transfer.consumption && (
            <span title={i18n._("eco_impact")}>
              {transfer.consumption.gCO2.toFixed(2)} gCO2
            </span>
          )}
        </div>
        {transfer.filesProgress && (
          <div className="flex justify-between">
            <span>
              {i18n._("file_progress", {
                current: transfer.currentFileIndex + 1,
                total: Object.keys(transfer.filesProgress).length
              })}
            </span>
            {transfer.totalTime > 0 && (
              <span title={i18n._("elapsed_time")}>
                {Math.round(transfer.totalTime / 1000)}s
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MinimizedTransfer;
