import React, { useState } from "react";
import { X, User, UserPlus, Lock, Mail } from "lucide-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { appAction, userAction } from "../../../../Store";
import { i18n } from "../../../../utils";
import { isMobile } from "../../../../utils";
import LangComponent from "../../../LangComponent";

interface Props {
  dispatch: Function;
  user: any;
  mod?: "light" | "normal";
  app: any;
  btnClassName?: string;
  textColorName?: string;
  labelOnly?: boolean;
  iconOnly?: boolean;
  onModalChangeCB?: (isOpen: boolean) => void;
}

const LoginButton = ({ dispatch, user, mod, app, onModalChangeCB }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState<any>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (force?: boolean) => {
    try {
      const { email, password } = formData;
      // Implement your auth logic here
      setError(null);
      setIsModalOpen(false);
    } catch (e) {
      setError(e);
    }
  };

  const closeModal = () => {
    if (onModalChangeCB) onModalChangeCB(false);
    setIsModalOpen(false);
    setError(null);
  };

  const openModal = () => {
    if (onModalChangeCB) onModalChangeCB(true);
    setIsModalOpen(true);
    setError(null);
  };

  return (
    <div className="inline-flex items-center gap-4">
      {!user?.status ? (
        <button
          onClick={openModal}
          className="flex items-center gap-2 px-4 py-2 text-gray-800 border border-gray-800 rounded hover:bg-gray-100"
        >
          <User size={18} />
          <span>{i18n._("login_label")}</span>
        </button>
      ) : (
        <div className="flex items-center gap-4">
          <Link
            to="/profil"
            className="flex items-center gap-2 px-4 py-2 text-gray-800 border border-gray-800 rounded hover:bg-gray-100"
          >
            <User size={18} />
            <span>{i18n._("account_label")}</span>
          </Link>
          <button
            onClick={() => dispatch(userAction.logoutUser({}))}
            className="text-gray-800 hover:text-gray-600"
          >
            <Lock size={18} />
          </button>
        </div>
      )}

      {!isMobile && <LangComponent textClass="text-gray-800" dispatch={dispatch} />}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{i18n._("login_label")}</h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                {error.message}
              </div>
            )}

            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <div className="mb-4">
                <label className="block mb-2">{i18n._("form_login_email_label")}</label>
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border rounded"
                  />
                  <Mail className="absolute left-3 top-2.5 text-gray-400" size={18} />
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-2">{i18n._("form_login_password_label")}</label>
                <div className="relative">
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border rounded"
                  />
                  <Lock className="absolute left-3 top-2.5 text-gray-400" size={18} />
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              >
                {i18n._("login_label")}
              </button>
            </form>

            <div className="mt-4 text-center">
              <Link to="/offers" className="text-blue-500 hover:text-blue-700">
                {i18n._("register_label")}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  app: state.app,
});

export default connect(mapStateToProps)(LoginButton);
