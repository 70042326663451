import React from "react";
import "./Banner.css";

import BannerItemComponent from "./Components/BannerItem/BannerItem";

interface Item {
  faClassName: string;
  i18nKey: string;
}

interface Props {}

interface State {}

class BannerComponent extends React.Component<Props, State> {
  items: Item[];

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.items = [
      {
        faClassName: "fas fa-map-marker-alt fa-3x mb-4",
        i18nKey: "banner_item_map",
      },
      {
        faClassName: "fa fa-hdd fa-3x mb-4",
        i18nKey: "banner_item_hdd",
      },
      {
        faClassName: "fas fa-lock fa-3x mb-4",
        i18nKey: "banner_item_lock",
      },
      {
        faClassName: "fas fa-user-shield fa-3x mb-4",
        i18nKey: "banner_item_shield",
      },
      {
        faClassName: "fas fa-recycle fa-3x mb-4",
        i18nKey: "banner_item_recycle",
      },
      {
        faClassName: "fas fa-globe-africa fa-3x mb-4",
        i18nKey: "banner_item_globe",
      },
    ];
  }

  render = () => {
    return (
      <div className="text-white lg:text-left px-5 md:px-20 fv_banner_container">
        <div className="flex flex-wrap justify-center">
          {this.items.map((item: Item, index: number) => (
            <BannerItemComponent item={item} key={index} />
          ))}
        </div>
      </div>
    );
  };
}

export default BannerComponent;
