import { Go } from './sizeInO';

export interface IOfferLabelAndColor {
  label: string;
  color: string;
  bg_color: string;
  slogan: string;
  config: {
    studio: boolean;
    transfer_notification: boolean;
    transfer_authentication: boolean;
    transfer_tracking: boolean;
    consumption_export: boolean;
    team_size: number | boolean;
    customization: boolean;
    outlook_addin: boolean;
    upload_size: number;
    upload_ttl: number;
    isGroupeOffer?: boolean;  // ajout de la nouvelle propriété
    fixed_price?: boolean;
  };
}

export const offerLabelsAndColors: IOfferLabelAndColor[] = [
  {
    label: "offer_nano_label",
    bg_color: "offer_custom_bg_color",
    color: "offer_custom_color",
    slogan: "offer_custom_slogan",
    config: {
      studio: false,
      transfer_notification: false,
      transfer_authentication: false,
      transfer_tracking: true,
      consumption_export: false,
      team_size: false,
      customization: false,
      outlook_addin: false,
      upload_size: 10 * Go,
      upload_ttl: 7,
    },
  },
  {
    label: "offer_solo_label",
    bg_color: "offer_solo_bg_color",
    color: "offer_solo_color",
    slogan: "offer_solo_slogan",
    config: {
      studio: false,
      transfer_notification: true,
      transfer_authentication: true,
      transfer_tracking: true,
      consumption_export: true,
      team_size: false,
      customization: false,
      outlook_addin: false,
      upload_size: 20 * Go,
      upload_ttl: 7,
    },
  },
  {
    label: "offer_pro_label",
    bg_color: "offer_pro_bg_color",
    color: "offer_pro_color",
    slogan: "offer_pro_slogan",
    config: {
      studio: false,
      transfer_notification: true,
      transfer_authentication: true,
      transfer_tracking: true,
      consumption_export: true,
      team_size: 5,
      customization: true,
      outlook_addin: false,
      upload_size: 20 * Go,
      upload_ttl: 15,
    },
  },
  {
    label: "offer_groupe_label",
    bg_color: "offer_studio_bg_color",
    color: "offer_studio_color",
    slogan: "offer_studio_slogan",
    config: {
      studio: true, // tout au max
      transfer_notification: true,
      transfer_authentication: true,
      transfer_tracking: true,
      consumption_export: true,
      team_size: true, // illimité
      customization: true,
      outlook_addin: true,
      upload_size: 200 * Go,
      upload_ttl: 15,
      isGroupeOffer: true, // flag pour identifier l'offre
      fixed_price: true // flag pour prix fixe
    },
},
];
