import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../../config';

interface SchemaActionsProps {
  baseUrl?: string;
}

/**
 * Composant qui ajoute des actions structurées Schema.org pour les IA
 * Permet aux IA de comprendre les actions possibles sur FileVert
 */
const SchemaActions: React.FC<SchemaActionsProps> = ({ baseUrl }) => {
  const siteUrl = baseUrl || config.ihmUrl || 'https://filevert.fr';
  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "FileVert",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "EUR"
    },
    "potentialAction": [
      {
        "@type": "UploadAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": `${siteUrl}/`,
          "actionPlatform": [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform"
          ]
        },
        "object": {
          "@type": "MediaObject",
          "encodingFormat": ["application/pdf", "image/jpeg", "image/png", "application/zip"]
        },
        "result": {
          "@type": "TransferAction",
          "agent": {
            "@type": "Person"
          },
          "object": {
            "@type": "MediaObject"
          },
          "recipient": {
            "@type": "Person"
          }
        }
      },
      {
        "@type": "TransferAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": `${siteUrl}/`,
          "actionPlatform": [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform"
          ]
        },
        "object": {
          "@type": "MediaObject"
        },
        "recipient": {
          "@type": "Person"
        },
        "description": "Transférer des fichiers volumineux de manière éco-responsable"
      },
      {
        "@type": "DownloadAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": `${siteUrl}/d/{id}`,
          "actionPlatform": [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform"
          ]
        },
        "object": {
          "@type": "MediaObject"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default SchemaActions;