import React from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../../Loader/Loader";
import { transferApi } from "../../../../Api";
import { i18n } from "../../../../utils";
import { FolderUp, FileUp, Plus, X, Share2 } from "lucide-react";
import ActionInputComponent from "../../../Button/ActionInputComponent";
import "./UploaderInput.css";
import { setConnectionQuality, detectConnectionQuality } from "../../../../Store/Actions/connectionActions";


import ConnectionQualityBars from "../../../ConnectionQualityBars/ConnectionQualityBars";
interface DirectoryInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string;
  mozdirectory?: string;
  directory?: string;
}

interface Props {
  dispatch: Function;
  user: any;
  transfer: any;
  file: any[];
  isFilesLoading: boolean;
  accept?: string;
  onFiles: (files: File[]) => void;
  getFilesFromEvent: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => Promise<File[]>;
  layoutContext?: 'home' | 'form';
  standalone?: boolean;
  isMainUploader?: boolean;
  connectionQuality?: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';
  setConnectionQuality: (quality: 'excellent' | 'good' | 'medium' | 'poor' | 'unknown') => void;
  detectConnectionQuality: () => void;
  percent?: number;
}

interface State {
  isModalOpen: boolean;
  deposit: any;
  depositLoading: boolean;
  isUploading: boolean;
  uploadProgress: number;
  uploadError: string | null;
  mountTime: number;
  isDragActive: boolean;
  dragCounter: number;
}

class UploaderInputComponent extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      deposit: null,
      depositLoading: false,
      isUploading: false,
      uploadProgress: 0,
      uploadError: null,
      mountTime: Date.now(),
      isDragActive: false,
      dragCounter: 0
    };
  }

  private hasCheckedQuality = false;

  componentDidMount() {

    if (this.props.layoutContext === 'form' && !this.hasCheckedQuality) {

      this.checkQuality();
      this.hasCheckedQuality = true;
    }
  }

  componentWillUnmount() {

  }

  private checkQuality = () => {
    if (this.props.layoutContext !== 'form') return;
    this.props.dispatch(detectConnectionQuality());
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      dragCounter: prevState.dragCounter + 1,
      isDragActive: true
    }));
  };

  handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      dragCounter: prevState.dragCounter - 1,
      isDragActive: prevState.dragCounter === 1 ? false : prevState.isDragActive
    }));
  };

  handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragActive: false, dragCounter: 0 });
    const chosenFiles = await this.props.getFilesFromEvent(e);
    this.props.onFiles(chosenFiles);
  };

  createDeposit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isModalOpen: true, depositLoading: true });
    transferApi
      .createNewDeposit()
      .then((deposit) => {
        if (!this.state.isModalOpen) this.setState({ isModalOpen: true });
        this.setState({ deposit });
      })
      .catch((e) => {})
      .finally(() => {
        this.setState({ depositLoading: false });
      });
  };

  renderCreateDepositCard = () => {
    if (!this.state.isModalOpen) return null;
    return (
      <>
        <div className="fixed inset-0 bg-black/50 z-40" onClick={() => this.setState({ isModalOpen: false })} />
        <div className="fixed inset-x-0 top-1/2 -translate-y-1/2 mx-auto max-w-md w-full rounded-lg z-50">
          <div className="space-y-8 bg-base-100 p-8 rounded-lg shadow-2xl border border-gray-700">
            <h1 className="text-2xl font-bold text-center text-gray-100">
              {i18n._("fv_creation_deposit_card_header")}
            </h1>
            {this.state.depositLoading ? (
              <div className="flex flex-col items-center gap-4">
                <span className="text-gray-300">
                  {i18n._("fv_creation_deposit_card_body_loading")}
                </span>
                <LoaderComponent size="small" type="Dot" />
              </div>
            ) : (
              <div className="space-y-6">
                <div>
                  <div className="relative mt-1">
                    <div className="block w-full p-6 border border-white/10 rounded-lg">
                      <ActionInputComponent
                        value={this.state.deposit?.deposit_link || ""}
                        navigateTo={{ enabled: true, cb: () => {} }}
                        mailTo={{
                          enabled: true,
                          subject: i18n._("fv_creation_deposit_card_mail_subject"),
                          body: i18n._("fv_creation_deposit_card_mail_body", [
                            this.state.deposit?.deposit_link || ""
                          ])
                        }}
                        explanation_label={i18n._("fv_creation_deposit_card_link_label")}
                        title={i18n._("fv_creation_deposit_card_title")}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={i18n._("fv_deposit_know_link_url")}
                    className="text-sm text-emerald-500 hover:text-emerald-400"
                  >
                    {i18n._("fv_deposit_know_link_label")}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = await this.props.getFilesFromEvent(e);
    this.props.onFiles(chosenFiles);
  };

  // Dans UploaderInputComponent, méthode renderButtons
renderButtons = () => {
  const isFormContext = this.props.layoutContext === 'form';
  const containerClasses = isFormContext
    ? "relative w-full h-auto flex items-top justify-center"
    : "relative flex items-start z-50 input-container-bloc";
  const fileInputId = `input-file-${this.props.layoutContext || 'home'}`;
  const directoryInputId = `input-directory-${this.props.layoutContext || 'home'}`;

  return (
    <div className={containerClasses}>
      <div className="relative shadow-lg bg-base-300/95 border border-white/10 rounded-lg p-3 flex flex-col gap-3 input-upload-buttons">
        {/* Upload Files Button */}
        <div className="group flex">
          <label 
            htmlFor={fileInputId}
            onClick={(e) => e.stopPropagation()} // Isoler le clic sur ce label
            className="relative flex items-center justify-center btn btn-ghost hover:bg-white hover:text-black h-12 w-12 p-0 transition-all duration-300 ease-out hover:scale-105"
          >
            <FileUp size={30} strokeWidth={1.2} className="transition-transform duration-300" />
          </label>
          <div className="absolute left-[calc(100%+4px)] overflow-hidden label-input-buttons">
            <div className="bg-base-100/80 backdrop-blur-sm shadow-md rounded-lg h-12 flex items-center whitespace-nowrap transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out">
              <span className="px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                {i18n._("add_files_label_home")}
              </span>
            </div>
          </div>
        </div>

        {/* Upload Directory Button */}
        <div className="group flex">
          <label 
            htmlFor={directoryInputId}
            onClick={(e) => e.stopPropagation()} // Isoler le clic sur ce label
            className="relative flex items-center justify-center btn btn-ghost hover:bg-white h-12 w-12 p-0 hover:text-black transition-all duration-300 ease-out hover:scale-105"
          >
            <FolderUp size={30} strokeWidth={1.2} className="transition-transform duration-300" />
          </label>
          <div className="absolute left-[calc(100%+4px)] overflow-hidden label-input-buttons">
            <div className="bg-base-100/80 backdrop-blur-sm shadow-md rounded-lg h-12 flex items-center whitespace-nowrap transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out">
              <span className="px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                {i18n._("add_directory_label_2")}
              </span>
            </div>
          </div>
        </div>

        {/* Create Deposit Button */}
        <div className="group flex deposit">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Isoler le clic pour éviter la propagation vers le container uploader
              this.createDeposit(e);
            }}
            disabled={this.state.isModalOpen}
            className="relative flex items-center justify-center btn btn-ghost hover:bg-white h-12 w-12 p-0 hover:text-black transition-all duration-300 ease-out hover:scale-105"
          >
            <Share2 size={30} strokeWidth={1.2} className="transition-transform duration-300 rotate-90" />
          </button>
          <div className="absolute left-[calc(100%+4px)] overflow-hidden label-input-buttons">
            <div className="bg-base-100/80 backdrop-blur-sm shadow-md rounded-lg h-12 flex items-center whitespace-nowrap transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out">
              <span className="px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100">
                {i18n._("fv_btn_create_deposit_cta")}
              </span>
            </div>
          </div>
        </div>

        {/* Connection Quality (affiché uniquement en configuration) */}
        {this.props.transfer?.isLocked === false && this.props.file?.length > 0 && (
          <div className="group flex">
            <div className="relative flex items-center justify-center h-12 w-12">
              <ConnectionQualityBars quality={this.props.connectionQuality || 'unknown'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


  render() {
    const { accept, layoutContext } = this.props;
    const { isDragActive } = this.state;



    const containerClass = layoutContext === 'form'
      ? 'h-full'
      : 'h-full flex items-top justify-center';

    const fileInputId = `input-file-${layoutContext || 'home'}`;
    const directoryInputId = `input-directory-${layoutContext || 'home'}`;

    return (
      <div
        className={`h-full ${containerClass} ${isDragActive ? 'border-2 border-dashed border-primary bg-white/10 rounded-lg' : ''}`}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        {this.renderButtons()}
        {this.renderCreateDepositCard()}
        <input
          id={fileInputId}
          type="file"
          className="hidden"
          accept={accept}
          multiple
          onChange={(e) =>
            this.props.getFilesFromEvent(e).then(chosenFiles => this.props.onFiles(chosenFiles))
          }
        />
        <input
          id={directoryInputId}
          type="file"
          className="hidden"
          accept={accept}
          multiple
          onChange={(e) =>
            this.props.getFilesFromEvent(e).then(chosenFiles => this.props.onFiles(chosenFiles))
          }
          {...{
            webkitdirectory: "true",
            mozdirectory: "true",
            directory: "true"
          } as DirectoryInputProps}
        />
        {isDragActive && (
          <div className="absolute inset-0 flex items-center justify-center bg-primary/10 rounded-xl backdrop-blur-sm">
            <p className="text-2xl font-semibold text-primary">
              {i18n._("drop_files_here")}
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : null;
  return {
    user: state.user.user,
    transfer: activeTransfer,
    file: state.file,
    connectionQuality: state.connection?.quality || 'unknown',
  };
};

const mapDispatchToProps = {
  setConnectionQuality,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderInputComponent);
