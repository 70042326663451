import { defaultPermissions } from "../Constants";

interface Offer {
  [key: string]: {
    value?: any;
  };
}

const compareOfferValues = (offer1: Offer, offer2: Offer, permKey: string): boolean => {
  return offer1[permKey]?.value !== offer2[permKey]?.value;
};

export function isOfferDifferent(offers: Offer[]) {
  const validOffers = offers.filter(Boolean);
  if (validOffers.length < 2) return true;

  const permKeys = Object.keys(defaultPermissions);
  const baseOffer = validOffers[0];
  
  for (let i = 1; i < validOffers.length; i++) {
    const currentOffer = validOffers[i];
    for (const perm of permKeys) {
      if (compareOfferValues(baseOffer, currentOffer, perm)) {
        return true;
      }
    }
  }
  
  return false;
}

export const isDefaultOffer = (offer: Offer): boolean => {
  if (!offer) return false;
  
  const permKeys = Object.keys(defaultPermissions);
  
  for (const key of permKeys) {
    const defaultValue = defaultPermissions[key]?.value;
    const offerValue = offer[key]?.value;
    
    if (defaultValue !== offerValue) {
      return false;
    }
  }
  
  return true;
};
