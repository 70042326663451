import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FileText } from 'lucide-react';
import { i18n } from '../../../../utils/';
import convertSize, { convertGCO2 } from '../../../../utils/convertSize';
import images from '../../../../assets/images';
import FolderLoader from '../../../FolderLoader/FolderLoader';

// Déclaration des variables de couleurs
const PRIMARY_GREEN = '#10B981';
const PRIMARY_GREEN_RGB = 'rgb(7, 171, 116)';
const DARK_GREEN_1 = '#065F46';
const DARK_GREEN_2 = '#047857';
const GRAY_1 = '#4B5563';
const GRAY_2 = '#6B7280';
const GRAY_3 = '#E5E7EB';
const RED_1 = '#B91C1C';
const RED_2 = '#991B1B';
const LIGHT_GREEN_BG_1 = '#ECFDF5';
const LIGHT_GREEN_BG_2 = '#F0FDF4';
const LIGHT_GREEN_BORDER_1 = '#D1FAE5';
const LIGHT_GREEN_BORDER_2 = '#DCFCE7';
const LIGHT_GRAY_BG_1 = '#F8FAFC';
const LIGHT_GRAY_BG_2 = '#F9FAFB';
const LIGHT_GRAY_BORDER = '#E2E8F0';


// Constants for calculating savings
const CO2_SAVED_PER_GO_TRANSFERRED = 0.01405; // kg CO₂ / Go - transferts
const CO2_SAVED_PER_GO_STORED = 0.08394; // kg CO₂ / Go - stockage
const CO2_SAVED_PER_EMAIL = 0.0182; // kg CO₂ / email
const CO2_PER_TREE_PER_YEAR = 20; // kg CO₂ absorbés par arbre/an
const CO2_PER_KM_CAR = 0.222; // kg CO₂/km voiture
const PUE_STANDARD = 1.57; // Mis à jour selon le bilan (Uptime Institute 2023)
const PUE_FILEVERT = 1.2; // Valeur moyenne entre 1.1 et 1.3 (Scaleway DC3/DC5)
const WATER_RATIO = 1.8; // Water/kWh ratio (sans division par 1.5)
const KWH_PER_GO = 0.0507; // kWh/Go (nouveau facteur énergétique)

interface RSEReportProps {
  savedConsumption: {
    activity_transfered_label: number;
    activity_saved_by_pue_label: number;
    activity_saved_by_year_label: number;
    activity_saved_label: number;
    activity_compensated_label: number;
  };
  currentConsumption: {
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  currentTeamConsumption?: {  // Optionnel pour compatibilité
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  usageStats?: {  // Optionnel pour compatibilité
    uploadVolume: number;
    downloadVolume: number;
    downloadRatio: number;
    autoRemoveRatio: number;
    passwordProtectedRatio: number;
    receptionVolume: number;
    revisionCount: number;
  };
  filevertConsumption?: {  // Optionnel pour compatibilité
    co2_reduced: number;
    energy_saved: number;
    water_saved: number;
    car_equivalent: number;
    eco_compensated: number;
  };
  dateRange: 'current_year' | 'last_year' | 'current_month' | 'all';
}

// Interface for page sections
interface PageSection {
  content: string;
  isFirst: boolean;
}

const RSEReport: React.FC<RSEReportProps> = ({
  savedConsumption,
  currentConsumption,
  currentTeamConsumption = {
    sizeIn0: 0,
    kWh: 0,
    kms: 0,
    gCO2: 0
  },
  usageStats = {
    uploadVolume: 0,
    downloadVolume: 0,
    downloadRatio: -1,
    autoRemoveRatio: -1,
    passwordProtectedRatio: -1,
    receptionVolume: 0,
    revisionCount: 0
  },
  filevertConsumption = {
    co2_reduced: 0,
    energy_saved: 0,
    water_saved: 0,
    car_equivalent: 0,
    eco_compensated: 0
  },
  dateRange,
}) => {
  // Add hooks
  const [isGenerating, setIsGenerating] = useState(false);
  const user = useSelector((state: any) => state.user.user);

  const calculateSavings = () => {
    // Calcul pour l'utilisateur individuel
    const sizeInGB = currentConsumption.sizeIn0 / (1024 * 1024 * 1024);
    
    // Calcul pour l'équipe (valeurs par défaut définies dans les paramètres de la fonction)
    const teamSizeInGB = currentTeamConsumption.sizeIn0 / (1024 * 1024 * 1024);

    // S'assurer que usageStats contient des valeurs
    const stats = {
      uploadVolume: usageStats?.uploadVolume || 0,
      downloadVolume: usageStats?.downloadVolume || 0,
      downloadRatio: usageStats?.downloadRatio || -1,
      autoRemoveRatio: usageStats?.autoRemoveRatio || -1,
      passwordProtectedRatio: usageStats?.passwordProtectedRatio || -1,
      receptionVolume: usageStats?.receptionVolume || 0,
      revisionCount: usageStats?.revisionCount || 0
    };
    
    // Total (utilisateur + équipe)
    const totalSizeInGB = sizeInGB + teamSizeInGB;
    
    // CO2 savings for different sources
    const co2SavedPUE = savedConsumption.activity_saved_by_pue_label / 1000; // Calculation based on API data
    
    // Calculate storage savings directly using the constant
    const co2SavedStorage = totalSizeInGB * CO2_SAVED_PER_GO_STORED;
    
    const co2SavedTransfers = totalSizeInGB * CO2_SAVED_PER_GO_TRANSFERRED;
    const co2SavedEmails = totalSizeInGB > 0.03 ? CO2_SAVED_PER_EMAIL : 0;
    
    // Total CO2 savings
    const totalCO2Savings = Math.max(
      co2SavedTransfers + co2SavedStorage + co2SavedEmails + co2SavedPUE,
      savedConsumption.activity_saved_label / 1000
    );
  
    // Energy savings calculation (60% according to methodology)
    const energySavings = (currentConsumption.kWh + currentTeamConsumption.kWh) * 0.6;
    
    // Water savings calculation (according to methodology)
    const waterSavings = (currentConsumption.kWh + currentTeamConsumption.kWh) * WATER_RATIO * 0.6;
    
    // Equivalences for all compensated emissions (not just savings)
    // Using standard compensated data for equivalences
    const standardEmissions = ((currentConsumption.gCO2 + currentTeamConsumption.gCO2) * 1.6) / 1000; // kg CO2
    const treesEquivalent = Math.max(1, Math.round(standardEmissions / CO2_PER_TREE_PER_YEAR));
    const kmEquivalent = Math.max(1, Math.round((standardEmissions * 1000) / CO2_PER_KM_CAR));
    
    // Utiliser les données directement du composant filevertConsumption pour plus de précision
    const directCO2Savings = filevertConsumption.co2_reduced;
    const directEnergySavings = filevertConsumption.energy_saved;
    const directWaterSavings = filevertConsumption.water_saved;
    const directCarEquivalent = filevertConsumption.car_equivalent;
  
    return {
      totalCO2Savings: directCO2Savings || totalCO2Savings,
      energySavings: directEnergySavings || energySavings,
      waterSavings: directWaterSavings || waterSavings,
      standardEmissions,
      details: {
        transfers: co2SavedTransfers,
        storage: co2SavedStorage,
        emails: co2SavedEmails,
        pue: co2SavedPUE
      },
      equivalences: {
        trees: treesEquivalent,
        km: directCarEquivalent || kmEquivalent
      },
      // Ajout des données d'équipe pour la section équipe
      team: {
        sizeInGB: teamSizeInGB,
        co2: currentTeamConsumption.gCO2 / 1000,
        energy: currentTeamConsumption.kWh,
        water: currentTeamConsumption.kWh * WATER_RATIO,
        distance: currentTeamConsumption.kms
      },
      // Ajout des statistiques d'utilisation
      usageStats: stats
    };
  };

  const generatePDF = async () => {
    try {
      // Set loading state to true when generation starts
      setIsGenerating(true);
      
      const savings = calculateSavings();
      // Create PDF with compression enabled
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true // Enable compression
      });
      
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      
      // Function to create a section and get its height
      const createSection = (content: string, isFirstSection: boolean = false): HTMLDivElement => {
        const section = document.createElement('div');
        section.style.width = '210mm';
        section.style.padding = '15mm';
        section.style.background = 'white';
        section.style.fontFamily = 'poppins, system-ui, -apple-system, sans-serif';
        section.style.boxSizing = 'border-box';
        
        // Add header if it's the first section
        if (isFirstSection) {
          const header = document.createElement('div');
          header.innerHTML = `
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 25px; padding-bottom: 15px; border-bottom: 2px solid ${GRAY_3};">
              <div style="display: flex; align-items: center; gap: 20px;">
                <img src="${images.LogoLightPNG}" alt="FileVert" style="height: 50px;"/>
                <div>
                  <h1 style="color: ${PRIMARY_GREEN}; font-size: 26px; margin: 0; font-weight: 600;">${i18n._("rse_report_title")}</h1>
                  <p style="color: ${GRAY_1}; margin: 8px 0 0 0; font-size: 16px;">${
                    dateRange === 'current_year' ? i18n._("report_subtitle_current_year") :
                    dateRange === 'last_year' ? i18n._("report_subtitle_last_year") : i18n._("report_subtitle_all")
                  }</p>
                </div>
              </div>
              <div style="text-align: right; color: ${GRAY_2}; font-size: 14px;">
                <p style="margin: 0;">${i18n._("document_generated_on")} ${new Date().toLocaleDateString('fr-FR')}</p>
                <p style="margin: 0;">${i18n._("version")}</p>
              </div>
            </div>
          `;
          section.appendChild(header);
        } else {
          // Simple header for subsequent pages
          const miniHeader = document.createElement('div');
          miniHeader.innerHTML = `
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 25px; padding-bottom: 12px; border-bottom: 1px solid ${GRAY_3};">
              <div style="display: flex; align-items: center; gap: 15px;">
                <img src="${images.LogoLightPNG}" alt="FileVert" style="height: 35px;"/>
                <div>
                  <h1 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0; font-weight: 600;">${i18n._("report_continuation")}</h1>
                </div>
              </div>
            </div>
          `;
          section.appendChild(miniHeader);
        }
        
        // Add content
        const contentDiv = document.createElement('div');
        contentDiv.innerHTML = content;
        section.appendChild(contentDiv);
        
        // Add to DOM to measure height
        document.body.appendChild(section);
        
        return section;
      };

      // Function to add footer and convert section to PDF image with preserved aspect ratio
      const addSectionToPDF = async (section: HTMLDivElement, pageNumber: number, totalPages: number): Promise<void> => {
        // Add footer
        const footer = document.createElement('div');
        footer.innerHTML = `
          <div style="border-top: 1px solid ${GRAY_3}; padding-top: 12px; margin-top: 30px; font-size: 11px; color: ${GRAY_2}; display: flex; justify-content: space-between;">
            <span>${i18n._("footer_text")} ${pageNumber}/${totalPages}</span>
            <span>${i18n._("document_generated_on")} ${new Date().toLocaleDateString('fr-FR')}</span>
          </div>
        `;
        section.appendChild(footer);
        
        // Convert to image with higher scale for better quality but reduced quality for smaller file size
        const canvas = await html2canvas(section, { 
          scale: 2,
          logging: false,
          useCORS: true,
          allowTaint: true
        });
        
        // Calculate aspect ratio
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        
        // Add new page if not the first
        if (pageNumber > 1) {
          pdf.addPage();
        }
        
        // Add image to PDF with preserved aspect ratio and reduced quality (0.8)
        pdf.addImage(
          canvas.toDataURL('image/jpeg', 0.8), // Changed from PNG to JPEG with quality 0.8
          'JPEG', 
          0, 
          0, 
          imgWidth, 
          Math.min(imgHeight, pageHeight)
        );

        // If content exceeds page height, add additional pages
        if (imgHeight > pageHeight) {
          let remainingHeight = imgHeight - pageHeight;
          let position = -pageHeight;
          
          while (remainingHeight > 0) {
            pdf.addPage();
            position -= pageHeight;
            pdf.addImage(
              canvas.toDataURL('image/jpeg', 0.8), // Reduced quality here too
              'JPEG', 
              0, 
              position, 
              imgWidth, 
              imgHeight
            );
            remainingHeight -= pageHeight;
          }
        }

        // Remove section from DOM
        document.body.removeChild(section);
      };

      // Define all report sections
      const sections = [
      // Section 1: Méthodologie
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("methodology_title")}</h2>
          <div style="background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 18px; font-size: 15px;">
            <p style="color: #1F2937; line-height: 1.6; margin: 0 0 15px 0;">
              <strong>${i18n._("database")}:</strong> ${i18n._("database_value")}
            </p>
            <p style="color: #1F2937; line-height: 1.6; margin: 0 0 15px 0;">
              <strong>${i18n._("key_parameters")}:</strong> ${i18n._("key_parameters_value")}
            </p>
            <p style="color: #1F2937; line-height: 1.6; margin: 0;">
              <strong>${i18n._("filevert_datacenters")}:</strong> ${i18n._("filevert_datacenters_value").replace("{pue_filevert}", PUE_FILEVERT.toString()).replace("{pue_standard}", PUE_STANDARD.toString())}
            </p>
          </div>
        </div>
      `,
      // Section 2: Résumé des économies
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("savings_summary_title")}</h2>
          <div style="background: ${LIGHT_GREEN_BG_1}; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 20px; margin-bottom: 15px;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
              <div>
                <h3 style="color: ${DARK_GREEN_2}; font-size: 20px; margin: 0 0 10px 0;">${i18n._("co2_savings")}</h3>
                <p style="color: ${DARK_GREEN_1}; font-size: 24px; font-weight: bold; margin: 0;">
                  ${savings.totalCO2Savings.toFixed(2)} kg CO₂
                </p>
              </div>
              <div style="text-align: right; border-left: 1px solid ${LIGHT_GREEN_BORDER_1}; padding-left: 20px;">
                <h4 style="color: ${DARK_GREEN_2}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("standard_consumption")}</h4>
                <p style="color: ${DARK_GREEN_1}; margin: 0; line-height: 1.6; font-size: 16px;">
                  ${i18n._("compensated_co2")}: <strong>${(savings.standardEmissions).toFixed(2)} kg</strong>
                </p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; font-size: 16px;">
              <div>
                <h4 style="color: ${DARK_GREEN_2}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("energy_savings")}</h4>
                <p style="color: ${DARK_GREEN_1}; font-weight: bold; margin: 0;">
                  ${savings.energySavings.toFixed(2)} kWh
                </p>
              </div>
              <div style="text-align: right;">
                <h4 style="color: ${DARK_GREEN_2}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("water_savings")}</h4>
                <p style="color: ${DARK_GREEN_1}; font-weight: bold; margin: 0;">
                  ${savings.waterSavings.toFixed(2)} L
                </p>
              </div>
            </div>
          </div>
        </div>
      `,

      // Section 3: Statistiques d'utilisation (Nouvelle section)
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">Statistiques d'utilisation</h2>
          <div style="display: flex; flex-wrap: wrap; gap: 15px; margin-bottom: 15px;">
            <div style="flex: 1; min-width: 200px; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">Volumes de transfert</h3>
              <table style="width: 100%; border-collapse: collapse; font-size: 14px; color: #1F2937;">
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Volume d'upload</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.uploadVolume === 0 ? 'N/A' : convertSize(savings.usageStats.uploadVolume)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Volume de téléchargement</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.downloadVolume === 0 ? 'N/A' : convertSize(savings.usageStats.downloadVolume)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Volume de réception</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.receptionVolume === 0 ? 'N/A' : convertSize(savings.usageStats.receptionVolume)}</td>
                </tr>
              </table>
            </div>
            
            <div style="flex: 1; min-width: 200px; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">Indicateurs d'utilisation</h3>
              <table style="width: 100%; border-collapse: collapse; font-size: 14px; color: #1F2937;">
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Ratio de téléchargement</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.downloadRatio === -1 ? 'N/A' : `${savings.usageStats.downloadRatio.toFixed(1)}%`}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Auto-suppression</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.autoRemoveRatio === -1 ? 'N/A' : `${savings.usageStats.autoRemoveRatio.toFixed(1)}%`}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Protection par mot de passe</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${savings.usageStats.passwordProtectedRatio === -1 ? 'N/A' : `${savings.usageStats.passwordProtectedRatio.toFixed(1)}%`}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0;">Nombre de révisions</td>
                  <td style="padding: 8px 0; text-align: right;">${savings.usageStats.revisionCount === 0 ? 'N/A' : savings.usageStats.revisionCount}</td>
                </tr>
              </table>
            </div>
          </div>
          <div style="background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px; font-size: 14px; font-style: italic;">
            <p style="color: #1F2937; margin: 0;">
              <strong>Note :</strong> Ces statistiques reflètent votre usage de FileVert sur la période sélectionnée. Un taux élevé d'auto-suppression et des périodes de stockage courtes contribuent significativement à la réduction de l'empreinte environnementale.
            </p>
          </div>
        </div>
      `,

      // Section 4: Détail des économies
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("savings_detail_title")}</h2>
          <div style="display: flex; gap: 20px; margin-bottom: 15px;">
            <div style="flex: 1;">
              <table style="color: #1F2937; width: 100%; border-collapse: collapse; margin-bottom: 12px; font-size: 14px;">
                <tr style="background: #F3F4F6;">
                  <th style="padding: 10px; text-align: left; border: 1px solid ${GRAY_3};">${i18n._("savings_source")}</th>
                  <th style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">kg CO₂</th>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("server_autoscaling")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">${savings.details.pue.toFixed(3)}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("quick_deletion")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">${savings.details.storage.toFixed(3)}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("optimized_flows")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">${savings.details.transfers.toFixed(3)}</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("avoided_emails")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">${savings.details.emails.toFixed(3)}</td>
                </tr>
              </table>
            </div>
            <div style="flex: 1;">
              <table style="color: #1F2937; width: 100%; border-collapse: collapse; margin-bottom: 12px; font-size: 14px;">
                <tr style="background: #F3F4F6;">
                  <th style="padding: 10px; text-align: left; border: 1px solid ${GRAY_3};">${i18n._("consumption_type")}</th>
                  <th style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">${i18n._("standard_vs_filevert")}</th>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("co2_emissions")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">
                    <span style="color: ${RED_1};">${((currentConsumption.gCO2 + currentTeamConsumption.gCO2)/1000 * 1.6).toFixed(2)} kg</span> → 
                    <span style="color: ${DARK_GREEN_2};">${((currentConsumption.gCO2 + currentTeamConsumption.gCO2)/1000).toFixed(2)} kg</span>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("energy_kwh")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">
                    <span style="color: ${RED_1};">${((currentConsumption.kWh + currentTeamConsumption.kWh) * 1.6).toFixed(2)}</span> → 
                    <span style="color: ${DARK_GREEN_2};">${(currentConsumption.kWh + currentTeamConsumption.kWh).toFixed(2)}</span>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; border: 1px solid ${GRAY_3};">${i18n._("pue_efficiency")}</td>
                  <td style="padding: 10px; text-align: right; border: 1px solid ${GRAY_3};">
                    <span style="color: ${RED_1};">${PUE_STANDARD}</span> → 
                    <span style="color: ${DARK_GREEN_2};">${PUE_FILEVERT}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style="background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px; font-size: 14px; font-style: italic;">
            <p style="color: #1F2937; margin: 0;">
              <strong>${i18n._("technical_note")}:</strong> ${i18n._("technical_note_content")}
            </p>
          </div>
        </div>
      `,

      // Section 5: Données d'équipe (Nouvelle section)
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">Consommation et économies d'équipe</h2>
          <div style="display: flex; gap: 20px; margin-bottom: 15px;">
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">Consommation de l'équipe</h3>
              <table style="color: #1F2937; width: 100%; border-collapse: collapse; margin-bottom: 12px;">
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Volume total transféré</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${currentTeamConsumption.sizeIn0 === 0 ? 'N/A' : convertSize(currentTeamConsumption.sizeIn0)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Énergie consommée</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${currentTeamConsumption.kWh === 0 ? 'N/A' : `${currentTeamConsumption.kWh.toFixed(2)} kWh`}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Émissions CO₂</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${currentTeamConsumption.gCO2 === 0 ? 'N/A' : convertGCO2(currentTeamConsumption.gCO2)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">Consommation d'eau équivalente</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${currentTeamConsumption.kWh === 0 ? 'N/A' : `${(currentTeamConsumption.kWh * WATER_RATIO).toFixed(2)} L`}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0;">Équivalent distance voiture</td>
                  <td style="padding: 8px 0; text-align: right;">${currentTeamConsumption.kms === 0 ? 'N/A' : `${currentTeamConsumption.kms.toFixed(2)} km`}</td>
                </tr>
              </table>
              <p style="color: #374151; margin-top: 15px; font-style: italic; font-size: 13px;">
                Ces données représentent la consommation cumulative de tous les membres de votre équipe, hors chef d'équipe.
              </p>
            </div>
            
            <div style="flex: 1; background: ${LIGHT_GREEN_BG_1}; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: ${DARK_GREEN_2}; font-size: 16px; margin: 0 0 12px 0;">Impact collectif</h3>
              <p style="color: ${DARK_GREEN_1}; line-height: 1.6; margin: 0 0 12px 0;">
                En combinant vos efforts personnels et ceux de votre équipe, vous avez collectivement :
              </p>
              <ul style="color: ${DARK_GREEN_1}; margin: 0 0 12px 0; padding-left: 20px; line-height: 1.6;">
                <li style="margin-bottom: 8px;"><strong>Économisé ${savings.totalCO2Savings.toFixed(2)} kg de CO₂</strong> grâce à l'utilisation de FileVert</li>
                <li style="margin-bottom: 8px;"><strong>Réduit la consommation d'énergie de ${savings.energySavings.toFixed(2)} kWh</strong></li>
                <li style="margin-bottom: 8px;"><strong>Économisé ${savings.waterSavings.toFixed(2)} L d'eau</strong> dans le processus de production d'énergie</li>
                <li><strong>Équivalent à ${savings.equivalences.trees} arbres plantés</strong> par an</li>
              </ul>
              <p style="color: ${DARK_GREEN_2}; font-weight: bold; text-align: right; margin: 15px 0 0 0; font-size: 14px;">
                L'effet collectif amplifie votre impact positif !
              </p>
            </div>
          </div>
        </div>
      `,

      // Section 6: Données de Consommation et Compensation
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("consumption_data_title")}</h2>
          <div style="display: flex; gap: 20px;">
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_2}; border: 1px solid #FEE2E2; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">${i18n._("filevert_consumption")}</h3>
              <table style="color: #1F2937; width: 100%; border-collapse: collapse; margin-bottom: 12px;">
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${i18n._("data_volume")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${convertSize(currentConsumption.sizeIn0 + currentTeamConsumption.sizeIn0)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${i18n._("power_consumption")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${(currentConsumption.kWh + currentTeamConsumption.kWh).toFixed(2)} kWh</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${i18n._("co2_emissions")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid ${LIGHT_GRAY_BORDER};">${convertGCO2(currentConsumption.gCO2 + currentTeamConsumption.gCO2)}</td>
                </tr>
              </table>
            </div>
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_1}; border: 1px solid #FEE2E2; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: ${RED_1}; font-size: 16px; margin: 0 0 12px 0;">${i18n._("standard_consumption_title")}</h3>
              <table style="color: #1F2937; width: 100%; border-collapse: collapse; margin-bottom: 12px;">
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid #FEE2E2;">${i18n._("data_volume")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid #FEE2E2;">${convertSize(currentConsumption.sizeIn0 + currentTeamConsumption.sizeIn0)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid #FEE2E2;">${i18n._("power_consumption")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid #FEE2E2;">${((currentConsumption.kWh + currentTeamConsumption.kWh) * 1.6).toFixed(2)} kWh</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0; border-bottom: 1px solid #FEE2E2;">${i18n._("co2_emissions")}</td>
                  <td style="padding: 8px 0; text-align: right; border-bottom: 1px solid #FEE2E2;">${convertGCO2((currentConsumption.gCO2 + currentTeamConsumption.gCO2) * 1.6)}</td>
                </tr>
                <tr>
                  <td style="padding: 8px 0;">${i18n._("co2_compensated_by_filevert")}</td>
                  <td style="padding: 8px 0; text-align: right; color: ${DARK_GREEN_2};"><strong>${convertGCO2((currentConsumption.gCO2 + currentTeamConsumption.gCO2) * 1.6)}</strong></td>
                </tr>
              </table>
              <p style="font-style: italic; font-size: 13px; margin: 0; color: ${GRAY_2};">
                ${i18n._("compensated_note")}
              </p>
            </div>
          </div>
          <div style="background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px; margin-top: 15px; font-size: 13px; font-style: italic;">
            <p style="color: #1F2937; margin: 0;">
              <strong>${i18n._("consumption_note")}:</strong> ${i18n._("consumption_note_content")}
            </p>
          </div>
        </div>
      `,
      // Section 7: Hébergement en France
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("hosting_title")}</h2>
          <div style="background: ${LIGHT_GREEN_BG_2}; border: 1px solid ${LIGHT_GREEN_BORDER_2}; border-radius: 8px; padding: 18px; font-size: 14px;">
            <ul style="color: #1F2937; margin: 0; padding-left: 25px; line-height: 1.6;">
              <li style="margin-bottom: 12px;"><strong>${i18n._("low_carbon_storage")}:</strong> ${i18n._("low_carbon_storage_content")}</li>
              <li style="margin-bottom: 12px;"><strong>${i18n._("local_vs_filevert")}:</strong> ${i18n._("local_vs_filevert_content")}</li>
              <li><strong>${i18n._("international_transfer_impact")}:</strong> ${i18n._("international_transfer_impact_content")}</li>
            </ul>
            <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 15px 0 0 0; font-size: 14px; text-align: right;">
              ✅ ${i18n._("hosting_result")}
            </p>
          </div>
        </div>
      `,
      // Section 8: Actions pour un numérique responsable
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("responsible_digital_title")}</h2>
          <div style="background: ${LIGHT_GREEN_BG_2}; border: 1px solid ${LIGHT_GREEN_BORDER_2}; border-radius: 8px; padding: 18px; font-size: 14px;">
            <p style="color: #166534; line-height: 1.6; margin: 0 0 15px 0;">
              ${i18n._("responsible_digital_intro")}
            </p>
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 15px;">
              <div style="background: white; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 15px;">
                <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 0 0 8px 0; font-size: 14px;">✓ ${i18n._("autoscaling")}</p>
                <p style="color: ${DARK_GREEN_1}; margin: 0; font-size: 13px;">${i18n._("autoscaling_desc")}</p>
              </div>
              <div style="background: white; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 15px;">
                <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 0 0 8px 0; font-size: 14px;">✓ ${i18n._("auto_deletion")}</p>
                <p style="color: ${DARK_GREEN_1}; margin: 0; font-size: 13px;">${i18n._("auto_deletion_desc")}</p>
              </div>
              <div style="background: white; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 15px;">
                <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 0 0 8px 0; font-size: 14px;">✓ ${i18n._("green_infrastructure")}</p>
                <p style="color: ${DARK_GREEN_1}; margin: 0; font-size: 13px;">${i18n._("green_infrastructure_desc")}</p>
              </div>
              <div style="background: white; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 15px;">
                <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 0 0 8px 0; font-size: 14px;">✓ ${i18n._("flow_stabilization")}</p>
                <p style="color: ${DARK_GREEN_1}; margin: 0; font-size: 13px;">${i18n._("flow_stabilization_desc")}</p>
              </div>
              <div style="background: white; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 15px; grid-column: span 2;">
                <p style="color: ${DARK_GREEN_2}; font-weight: bold; margin: 0 0 8px 0; font-size: 14px;">✓ ${i18n._("no_ads_tracking")}</p>
                <p style="color: ${DARK_GREEN_1}; margin: 0; font-size: 13px;">${i18n._("no_ads_tracking_desc")}</p>
              </div>
            </div>
          </div>
        </div>
      `
      ];

      // Sections 9-12 will be added in the next part for clarity
      sections.push(
      // Section 9: Bilan global et impact à long terme
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("global_impact_title")}</h2>
          <div style="display: flex; gap: 20px; margin-bottom: 15px;">
            <div style="flex: 2; background: ${LIGHT_GREEN_BG_1}; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: ${DARK_GREEN_1}; font-size: 16px; margin: 0 0 12px 0;">${i18n._("annual_savings")}</h3>
              <p style="color: #1F2937; margin: 0 0 12px 0; line-height: 1.6; font-size: 14px;">
                ${i18n._("annual_savings_intro").replace("<strong>13,53 tonnes de CO₂</strong>", `<strong>${savings.totalCO2Savings.toFixed(2)} kg de CO₂</strong>`)}
              </p>
              <ul style="color: #1F2937; margin: 0; padding-left: 20px; line-height: 1.6;">
                <li style="margin-bottom: 8px;"><strong>${savings.equivalences.trees} ${i18n._("trees_planted")}</strong> ${i18n._("trees_planted_period")}</li>
                <li style="margin-bottom: 8px;"><strong>${savings.equivalences.km} ${i18n._("km_avoided")}</strong> ${i18n._("km_avoided_desc")}</li>
                <li><strong>${savings.energySavings.toFixed(2)} ${i18n._("kwh_saved")}</strong> ${i18n._("kwh_saved_desc")}</li>
              </ul>
            </div>
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">${i18n._("usage_statistics")}</h3>
              <ul style="color: #1F2937; margin: 0; padding-left: 20px; line-height: 1.6;">
                <li style="margin-bottom: 8px;">${i18n._("shorter_storage")}</li>
                <li style="margin-bottom: 8px;">${i18n._("auto_deletion_after_download")}</li>
                <li style="margin-bottom: 8px;">${i18n._("completed_uploads")}</li>
                <li>${i18n._("direct_deletion_option")}</li>
              </ul>
            </div>
          </div>
        </div>
      `,
      // Section 10: Conformité RSE et Engagements
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("csr_compliance_title")}</h2>
          <div style="display: flex; gap: 20px;">
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">${i18n._("standards_methodologies")}</h3>
              <ul style="color: #334155; margin: 0; padding-left: 20px; line-height: 1.6;">
                <li style="margin-bottom: 8px;"><strong>Base Carbone® ADEME</strong></li>
                <li style="margin-bottom: 8px;"><strong>GHG Protocol</strong> (Scope 2 & 3)</li>
                <li style="margin-bottom: 8px;"><strong>DPEF & CSRD</strong> (pour reporting ESG)</li>
                <li><strong>Climate Neutral Data Centre Pact</strong></li>
              </ul>
            </div>
            <div style="flex: 1; background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 18px; font-size: 14px;">
              <h3 style="color: #334155; font-size: 16px; margin: 0 0 12px 0;">${i18n._("filevert_commitments")}</h3>
              <ul style="color: #334155; margin: 0; padding-left: 20px; line-height: 1.6;">
                <li style="margin-bottom: 8px;"><strong>${i18n._("renewable_energy")}</strong> ${i18n._("renewable_energy_desc")}</li>
                <li style="margin-bottom: 8px;"><strong>${i18n._("carbon_offset")}</strong> ${i18n._("carbon_offset_desc")}</li>
                <li style="margin-bottom: 8px;"><strong>${i18n._("awareness_raising")}</strong> ${i18n._("awareness_raising_desc")}</li>
                <li><strong>${i18n._("weeefund_support")}</strong> ${i18n._("weeefund_support_desc")}</li>
              </ul>
            </div>
          </div>
        </div>
      `,
      // Section 11: Comparaison avec d'autres solutions
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("comparison_title")}</h2>
          <table style="color: #4B5563; width: 100%; border-collapse: collapse; font-size: 14px;">
            <tr style="background: #F3F4F6;">
              <th style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: left;">${i18n._("service")}</th>
              <th style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">${i18n._("storage_duration")}</th>
              <th style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">${i18n._("infrastructure")}</th>
              <th style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">${i18n._("compensation")}</th>
            </tr>
            <tr>
              <td style="padding: 12px; border: 1px solid ${GRAY_3};"><strong>FileVert</strong></td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">Max 15 jours<br><span style="color: ${DARK_GREEN_1}; font-style: italic;">${i18n._("direct_deletion_possible")}</span></td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">France (100% renouvelable)<br>PUE: ${PUE_FILEVERT}</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;"><span style="color: ${DARK_GREEN_1};">✓ 100%</span><br>GoodPlanet</td>
            </tr>
            <tr>
              <td style="padding: 12px; border: 1px solid ${GRAY_3};">${i18n._("classic_service_a")}</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">${i18n._("classic_service_a_storage")}</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">Global (mix variable)<br>PUE: ~1.4-1.6</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;"><span style="color: ${DARK_GREEN_1};">✓</span> Variable</td>
            </tr>
            <tr>
              <td style="padding: 12px; border: 1px solid ${GRAY_3};">${i18n._("classic_service_b")}</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">Permanent<br><span style="color: ${RED_2}; font-style: italic;">${i18n._("accumulation_over_time")}</span></td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">Global (mix optimisé)<br>PUE: ~1.1-1.2</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;"><span style="color: ${DARK_GREEN_1};">✓</span> Partielle</td>
            </tr>
            <tr>
              <td style="padding: 12px; border: 1px solid ${GRAY_3};">${i18n._("email_with_attachment")}</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">Permanent<br><span style="color: ${RED_2}; font-style: italic;">${i18n._("multiple_copies")}</span></td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;">${i18n._("variable_by_provider")}<br>PUE: ~1.4-2.0</td>
              <td style="padding: 12px; border: 1px solid ${GRAY_3}; text-align: center;"><span style="color: ${RED_2};">✗</span> ${i18n._("rarely")}</td>
            </tr>
          </table>
          <div style="background: ${LIGHT_GRAY_BG_1}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px; font-size: 14px; font-style: italic; margin-top: 15px;">
            <p style="color:#4B5563; margin: 0; line-height: 1.6;">
              <strong>${i18n._("comparative_note")}:</strong> ${i18n._("comparative_note_content")}
            </p>
          </div>
        </div>
      `,
      // Section 12: Conclusion et perspectives
      `
        <div style="margin-bottom: 30px;">
          <h2 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0 0 15px 0; font-weight: 600;">${i18n._("conclusion_title")}</h2>
          <div style="background: ${LIGHT_GREEN_BG_1}; border: 1px solid ${LIGHT_GREEN_BORDER_1}; border-radius: 8px; padding: 18px; font-size: 14px;">
            <p style="color: ${DARK_GREEN_1}; margin: 0 0 15px 0; line-height: 1.6;">
              ${i18n._("filevert_continues")}
            </p>
            <ul style="margin: 0 0 10px 0; padding-left: 20px; color: ${DARK_GREEN_1}; line-height: 1.6;">
              <li style="margin-bottom: 10px;">${i18n._("continuous_improvement")}</li>
              <li style="margin-bottom: 10px;">${i18n._("carbon_reduction_tools")}</li>
              <li>${i18n._("active_contribution")}</li>
            </ul>
            <p style="color: ${DARK_GREEN_1}; margin: 15px 0 0 0; font-style: italic; font-size: 14px;">
              ${i18n._("conclusion_note")}
            </p>
          </div>
        </div>
      `,
      // Section de remerciement
      `
        <div style="text-align: center; margin-bottom: 30px;">
          <p style="color: ${PRIMARY_GREEN}; font-size: 16px; font-weight: 600; margin-bottom: 10px;">${i18n._("thank_you")}</p>
          <p style="color: ${GRAY_1}; font-size: 14px; font-style: italic;">${i18n._("footer_legal_report")}</p>
        </div>
      `
      );

      // Détermination de la mise en page des sections sur les pages
      const MAX_HEIGHT_PER_PAGE = 220; // Hauteur maximale estimée en millimètres pour le contenu (sans header ou footer)
      const pages: PageSection[][] = [];
      let currentPage: PageSection[] = [];
      let currentHeight = 0;
      
      // Algorithme pour grouper les sections en pages
      const processSections = async (): Promise<void> => {
        try {
          // Première page avec header complet
          let firstSection = true;
          
          // Mesurer la hauteur de chaque section et les grouper en pages
          for (let i = 0; i < sections.length; i++) {
            // Créer un div temporaire pour mesurer la hauteur de la section
            const tempSection = document.createElement('div');
            tempSection.style.width = '180mm'; // 210mm - 2 * 15mm de padding
            tempSection.style.fontFamily = 'system-ui, -apple-system, sans-serif';
            tempSection.innerHTML = sections[i];
            document.body.appendChild(tempSection);
            
            const sectionHeight = tempSection.offsetHeight * 0.264583; // Conversion px en mm (1px ≈ 0.264583mm)
            document.body.removeChild(tempSection);
            
            if (currentHeight + sectionHeight > MAX_HEIGHT_PER_PAGE || currentPage.length === 0) {
              // Si c'est la première section ou si la page courante est pleine, démarrer une nouvelle page
              if (currentPage.length > 0) {
                pages.push(currentPage);
                currentPage = [];
                currentHeight = 0;
                firstSection = false;
              }
            }
            
            currentPage.push({ content: sections[i], isFirst: firstSection && currentPage.length === 0 });
            currentHeight += sectionHeight;
            firstSection = false;
          }
          
          // Ajouter la dernière page si elle contient des sections
          if (currentPage.length > 0) {
            pages.push(currentPage);
          }
          
          // Générer les pages PDF
          const totalPages = pages.length;
          for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            const pageContent = document.createElement('div');
            pageContent.style.width = '210mm';
            pageContent.style.padding = '15mm';
            pageContent.style.background = 'white';
            pageContent.style.fontFamily = 'system-ui, -apple-system, sans-serif';
            pageContent.style.boxSizing = 'border-box';
            
            // Ajout du header approprié
            if (pageIndex === 0) {
              // Header de la première page
              const header = document.createElement('div');
              header.innerHTML = `
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 25px; padding-bottom: 15px; border-bottom: 2px solid ${GRAY_3};">
                  <div style="display: flex; align-items: center; gap: 20px;">
                    <img src="${images.LogoLightPNG}" alt="FileVert" style="height: 50px;"/>
                    <div>
                      <h1 style="color: ${PRIMARY_GREEN}; font-size: 26px; margin: 0; font-weight: 600;">${i18n._("rse_report_title")}</h1>
                      <p style="color: ${GRAY_1}; margin: 8px 0 0 0; font-size: 16px;">${
                        dateRange === 'current_year' ? i18n._("report_subtitle_current_year") :
                        dateRange === 'last_year' ? i18n._("report_subtitle_last_year") : i18n._("report_subtitle_all")
                      }</p>
                    </div>
                  </div>
                  <div style="text-align: right; color: ${GRAY_2}; font-size: 14px;">
                    <p style="margin: 0;">${i18n._("document_generated_on")} ${new Date().toLocaleDateString('fr-FR')}</p>
                    <p style="margin: 0;">${i18n._("version")}</p>
                  </div>
                </div>
              `;
              pageContent.appendChild(header);
            } else {
              // Header pour les pages suivantes
              const miniHeader = document.createElement('div');
              miniHeader.innerHTML = `
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 25px; padding-bottom: 12px; border-bottom: 1px solid ${GRAY_3};">
                  <div style="display: flex; align-items: center; gap: 15px;">
                    <img src="${images.LogoLightPNG}" alt="FileVert" style="height: 35px;"/>
                    <div>
                      <h1 style="color: ${PRIMARY_GREEN}; font-size: 20px; margin: 0; font-weight: 600;">${i18n._("report_continuation")}</h1>
                    </div>
                  </div>
                </div>
              `;
              pageContent.appendChild(miniHeader);
            }
            
            // Ajout du contenu pour chaque section sur la page
            for (const section of page) {
              const contentDiv = document.createElement('div');
              contentDiv.innerHTML = section.content;
              pageContent.appendChild(contentDiv);
            }
            
            // Ajout du footer
            const footer = document.createElement('div');
            footer.innerHTML = `
              <div style="border-top: 1px solid ${GRAY_3}; padding-top: 12px; margin-top: 30px; font-size: 11px; color: ${GRAY_2}; display: flex; justify-content: space-between;">
                <span>${i18n._("footer_text")} ${pageIndex + 1}/${totalPages}</span>
                <span>${i18n._("document_generated_on")} ${new Date().toLocaleDateString('fr-FR')}</span>
              </div>
            `;
            pageContent.appendChild(footer);
            
            document.body.appendChild(pageContent);
            
            // Conversion de la page en image et ajout dans le PDF avec rapport d'aspect préservé
            const canvas = await html2canvas(pageContent, { 
              scale: 2,
              logging: false,
              useCORS: true,
              allowTaint: true
            });
            
            // Calcul du rapport d'aspect
            const imgWidth = pageWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            
            // Ajout d'une nouvelle page si ce n'est pas la première
            if (pageIndex > 0) {
              pdf.addPage();
            }
            
            // Ajout de l'image dans le PDF avec rapport d'aspect préservé et qualité réduite (0.8)
            pdf.addImage(
              canvas.toDataURL('image/jpeg', 0.8),
              'JPEG', 
              0, 
              0, 
              imgWidth, 
              Math.min(imgHeight, pageHeight)
            );
            
            // Si le contenu dépasse la hauteur de la page, ajout de pages supplémentaires
            if (imgHeight > pageHeight) {
              let remainingHeight = imgHeight - pageHeight;
              let position = -pageHeight;
              
              while (remainingHeight > 0) {
                pdf.addPage();
                position -= pageHeight;
                pdf.addImage(
                  canvas.toDataURL('image/jpeg', 0.8),
                  'JPEG', 
                  0, 
                  position, 
                  imgWidth, 
                  imgHeight
                );
                remainingHeight -= pageHeight;
              }
            }
            
            // Suppression de la page du DOM
            document.body.removeChild(pageContent);
          }
          
          // Sauvegarder le PDF (compression a déjà été configurée au moment de la création du PDF)
          const today = new Date();
          const dateString = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
          const year = today.getFullYear();
          const sanitizedOrganization = user?.organization?.includes('.') ? user.organization.replace('.', '-') : user?.organization || '';
          const fileName = `${sanitizedOrganization ? ` ${sanitizedOrganization}` : ''} - Reporting-${i18n._("rse")}-FileVert-${dateString}${user?.first_name ? `-${user.first_name}` : ''}${user?.last_name ? `_${user.last_name}` : ''}.pdf`;
          console.log('Debug - User:', user);
          pdf.save(fileName);
          
          // Set loading state back to false when done
          setIsGenerating(false);
        } catch (error) {
          console.error("Error generating PDF:", error);
          alert("An error occurred while generating the report. Please try again.");
          // Also reset loading state in case of error
          setIsGenerating(false);
        }
      };
      
      // Démarrage du traitement des sections
      await processSections();
    } catch (error) {
      console.error("Error in PDF generation:", error);
      alert("An error occurred while generating the report. Please try again.");
      // Reset loading state in case of any error
      setIsGenerating(false);
    }
  };

  const handleClick = () => {
    if (dateRange === 'all') {
      alert(i18n._("select_year_range_alert"));
      return;
    }
    generatePDF();
  };

  return (
    <button
      onClick={handleClick}
      disabled={isGenerating || dateRange === 'all'}
      className="border border-primary text-primary hover:bg-primary/10 font-medium py-1.5 sm:py-2 px-3 sm:px-4 rounded whitespace-nowrap transition-colors text-sm sm:text-base flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-400 disabled:text-gray-400"
      title={dateRange === 'all' ? i18n._("select_year_range_tooltip") : i18n._("generate_rse_report_tooltip")}
    >
      {isGenerating ? (
        <>
          <FolderLoader isLoading={true} />
          <span>{i18n._("generating_report") || "Génération en cours..."}</span>
        </>
      ) : (
        <>
          <FileText className="w-4 h-4" />
          <span>{i18n._("generate_rse_report")}</span>
        </>
      )}
    </button>
  );
};

export default RSEReport;