import React from "react";
// Importez les icônes de Lucide React si nécessaire
// import { CheckIcon } from 'lucide-react';

export interface IInputCheckboxComponentProps {
  id: string;
  name: string;
  error?: string;
  label?: string;
  defaultChecked?: boolean;
  required?: boolean;
  [key: string]: any;
}

export function InputCheckboxComponent({
  id,
  label,
  error,
  name,
  defaultChecked,
  required,
  ...rest
}: IInputCheckboxComponentProps) {
  return (
    <div className="form-control">
      <label htmlFor={id} className="cursor-pointer label">
        <span className="label-text text-sm text-left">
          {label} {required && <span className="text-error">*</span>}
        </span>
        <input
          id={id}
          name={name}
          type="checkbox"
          defaultChecked={defaultChecked}
          required={required}
          className="toggle toggle-primary"
          {...rest}
        />
      </label>
      {error && (
        <span className="mt-1 text-error text-sm" role="alert">
          {error}
        </span>
      )}
    </div>
  );
}
