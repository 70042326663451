import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../Toast/Toast";
import { 
  CheckCircle, 
  ChevronRight, 
  MapPin, 
  Clock, 
  X,
  FileText,
  Trash2,
  ImageIcon,
  FileArchive,
  Film,
  Music,
  Code,
} from "lucide-react";

import { transferAction, uiAction } from "../../../../Store/Actions";
import UploaderForm from "../UploaderForm/UploaderForm";
import { ActionInputComponent } from "../../../Button";
import LoaderComponent from "../../../Loader/Loader";

import { convertDate, convertSize, fixeVal } from "../../../../utils";
import i18n from "../../../../utils/i18n";
import { config } from "../../../../config";

import "./Layout.css";

interface Props {
  role: number;
  resetCb?: () => void;
  submitCb: (data: any) => void;
  maximum_size?: number;
  input: React.ReactNode;
  previews: React.ReactNode;
  submitButton: React.ReactNode;
  dropzoneProps: Record<string, any>;
  isModalOpen?: boolean;
}

interface RootState {
  transfer: {
    transfers: {
      [key: string]: Transfer;
    };
    activeTransferId: string | null;
  };
  file: FileObject[];
  app: {
    APP_ERROR: any;
  };
  ui: {
    isPanelOpen: boolean;
  };
  user?: {
    user?: {
      role: number;
      permissions?: {
        upload_size?: {
          value: number;
        };
      };
      limit_total?: number;
      current_uploaded_size?: number;
    };
  };
}

interface Transfer {
  loaded: number;
  totalSize: number;
  isLocked: boolean;
  isUploadFinished: boolean;
  currentFileIndex: number;
  filesProgress: { [fileId: string]: FileProgress };
  progress: {
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed?: number;
    };
    last: {
      timestamp: number;
      loaded: number;
    };
  };
  startTime: number;
  totalTime: number;
  expiration_date: string;
  link: string;
  consumption: {
    gCO2: number;
    kms: number;
    kWh: number;
  };
  purging?: boolean;
}

interface FileProgress {
  totalLoaded: number;
  fileSize: number;
}

interface FileObject {
  meta: {
    id: string;
    name: string;
    size: number;
  };
  remove: () => void;
}

const getFileIcon = (fileName: string) => {
  if (!fileName) return <FileText className="w-5 h-5" />;
  
  const ext = fileName.split('.').pop()?.toLowerCase();
  switch(ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'webp':
      return <ImageIcon className="w-5 h-5" />;
    case 'zip':
    case 'rar':
    case '7z':
      return <FileArchive className="w-5 h-5" />;
    case 'mp4':
    case 'avi':
    case 'mov':
      return <Film className="w-5 h-5" />;
    case 'mp3':
    case 'wav':
    case 'ogg':
      return <Music className="w-5 h-5" />;
    case 'js':
    case 'ts':
    case 'jsx':
    case 'tsx':
      return <Code className="w-5 h-5" />;
    default:
      return <FileText className="w-5 h-5" />;
  }
};

const decodeFileName = (fileName: string): string => {
  try {
    return decodeURIComponent(fileName).replace(/\+/g, ' ');
  } catch {
    return fileName;
  }
};

const LayoutComponent: React.FC<Props> = ({
  role,
  resetCb,
  submitCb,
  input,
  submitButton,
  dropzoneProps,
}) => {
  const dispatch = useDispatch();
  const activeTransferId = useSelector<RootState, string | null>(state => state.transfer.activeTransferId);
  const transfer = useSelector<RootState, Transfer>(state => 
    state.transfer.transfers[activeTransferId || ''] || {
      loaded: 0,
      totalSize: 0,
      isLocked: false,
      isUploadFinished: false,
      currentFileIndex: 0,
      filesProgress: {},
      progress: {
        current: { timestamp: Date.now(), loaded: 0 },
        last: { timestamp: Date.now(), loaded: 0 }
      },
      startTime: Date.now(),
      totalTime: 0,
      expiration_date: '',
      link: '',
      consumption: { gCO2: 0, kms: 0, kWh: 0 }
    }
  );
  const files = useSelector<RootState, FileObject[]>(state => state.file);
  const isPanelOpen = useSelector<RootState, boolean>(state => state.ui?.isPanelOpen ?? false);

  const [successCopyLink, setSuccessCopyLink] = useState(false);

  useEffect(() => {
    console.log("[LayoutComponent] RENDER => isLocked:", transfer.isLocked, "isUploadFinished:", transfer.isUploadFinished);
  }, [transfer]);

  const handlePanelToggle = useCallback(() => {
    dispatch(uiAction.setPanelState(!isPanelOpen));
  }, [isPanelOpen, dispatch]);

  const handleFileDelete = useCallback((index: number) => {
    if (!transfer.isLocked && files[index]) {
      files[index].remove();
    }
  }, [transfer.isLocked, files]);

  const handleCancelTransfer = useCallback(() => {
    if (window.confirm(i18n._("confirm_upload_cancelation_label"))) {
      const initialState = {
        loaded: 0,
        isLocked: false,
        isUploadFinished: false,
        progress: {
          current: { timestamp: Date.now(), loaded: 0 },
          last: { timestamp: Date.now(), loaded: 0 }
        }
      };
      if (activeTransferId) {
        dispatch(transferAction.setInfos({
          ...initialState,
          transfer_id: activeTransferId
        }));
      }
      resetCb?.();
    }
  }, [resetCb, dispatch]);

  const getUploadProgress = useCallback((fileId: string): number => {
    const progress = transfer.filesProgress?.[fileId];
    if (!progress) return 0;
    return Math.min(Math.floor((progress.totalLoaded * 100) / progress.fileSize), 99);
  }, [transfer.filesProgress]);

  // ------------------------------------------------------------------
  // Panneau latéral: Détails des fichiers
  // ------------------------------------------------------------------
  const FileItem: React.FC<{ file: FileObject; index: number }> = React.memo(({ file, index }) => {
    const progress = transfer.isLocked ? getUploadProgress(file.meta.id) : undefined;

    return (
      <div className="group relative mb-4 p-4 rounded-lg bg-base-100 hover:bg-base-200 shadow-sm">
        <div className="flex items-center gap-3">
          {getFileIcon(file.meta.name)}
          <div className="flex-1 min-w-0">
            <h4 className="font-medium truncate text-sm">
              {decodeFileName(file.meta.name)}
            </h4>
            <div className="flex items-center gap-3 mt-1">
              <span className="text-sm text-base-content/70">
                {convertSize(file.meta.size)}
              </span>
              {progress !== undefined && (
                <FileUploadProgress progress={progress} />
              )}
            </div>
          </div>
          {!transfer.isLocked && (
            <button
              onClick={() => handleFileDelete(index)}
              className="btn btn-ghost btn-sm btn-circle opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <Trash2 className="w-4 h-4 text-error" />
            </button>
          )}
        </div>
      </div>
    );
  });

  const FileUploadProgress: React.FC<{ progress: number }> = React.memo(({ progress }) => (
    <div className="flex items-center gap-2 flex-1">
      <div className="h-1.5 w-full rounded-full bg-base-300 overflow-hidden">
        <div 
          className="h-full bg-primary transition-all duration-300"
          style={{ width: `${progress}%` }}
        />
      </div>
      <span className="text-xs w-9">{progress}%</span>
    </div>
  ));

  const FileDetailsPanel: React.FC = React.memo(() => (
    <div className="h-full flex flex-col">
      <div className="p-4 border-b border-base-300 flex justify-between items-center">
        <h3 className="font-semibold text-lg">
          {i18n._("files_details_label")}
        </h3>
        <button
          onClick={handlePanelToggle}
          className="btn btn-ghost btn-circle btn-sm"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        {files.map((file, index) => (
          <FileItem key={file.meta.id} file={file} index={index} />
        ))}
      </div>
      {transfer.isLocked && (
        <div className="p-4 border-t border-base-300">
          <div className="flex justify-between items-center text-sm">
            <span>{i18n._("upload_progress_label")}</span>
            <span>{Math.round((transfer.loaded * 100) / transfer.totalSize)}%</span>
          </div>
        </div>
      )}
    </div>
  ));

  // ------------------------------------------------------------------
  // Rendu principal
  // ------------------------------------------------------------------
  return (
    <div className="container">
      <div className="container mx-auto p-4">
        <Toast
          type="success"
          message="Lien copié avec succès"
          isVisible={successCopyLink}
          onClose={() => setSuccessCopyLink(false)}
        />

        <div className="relative flex justify-center items-center">
          <div className="flex relative z-10 max-w-4xl transition-all duration-300 deposit-bloc">
            
            {/* 1) AFFICHE LES INPUTS UNIQUEMENT si pas d'upload en cours ni terminé */}
            {!transfer.isLocked && !transfer.isUploadFinished && (
              <>
                {/* S'il n'y a pas encore de fichiers, on montre la drop zone */}
                {files.length === 0 && (
                  <div className="flex items-center justify-center" {...dropzoneProps}>
                    {input}
                  </div>
                )}

                {/* S'il y a déjà des fichiers sélectionnés, on montre le bouton "Panel" et le Form */}
                {files.length > 0 && (
                  <div className="items-center justify-between mb-4">
                    <div className="flex-1">{input}</div>
                    <button
                      onClick={handlePanelToggle}
                      className="btn btn-ghost btn-sm gap-2"
                    >
                      {/* Panel toggle */}
                    </button>
                  </div>
                )}

                {files.length > 0 && (
                  <div className="space-y-6">
                    <div className="card bg-base-200 shadow-lg ml-4">
                      <div className="col-span-10 md:col-span-8 max-w-xs space-y-8 bg-base-200 p-8 rounded-box shadow-2xl border border-gray-700 max-w-[200px]">
                        {/* Le formulaire d'upload */}
                        <UploaderForm submitCb={submitCb} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* 2) AFFICHE LA PROGRESSION si upload en cours */}
            {transfer.isLocked && !transfer.isUploadFinished && (
              <div className="card bg-base-100 shadow-lg p-6">
                <div className="flex flex-col items-center gap-6">
                  <LoaderComponent 
                    type="Spinner" 
                    size="big"
                    percent={Math.round((transfer.loaded * 100) / transfer.totalSize)}
                  />
                  <div className="text-center space-y-2">
                    <div className="text-lg font-medium">
                      {Math.round((transfer.loaded * 100) / transfer.totalSize)}%
                    </div>
                    <div className="text-base-content/70">
                      {convertSize(transfer.loaded)} / {convertSize(transfer.totalSize)}
                    </div>
                  </div>
                  <button 
                    onClick={handleCancelTransfer}
                    className="btn btn-ghost btn-sm"
                  >
                    {i18n._("cancel_label")}
                  </button>
                </div>
              </div>
            )}

          </div>
        </div>

        {/* Panneau latéral (Détails fichiers) */}
        <div 
          className={`fixed right-0 top-0 bottom-0 w-96 bg-base-200/95 mt-20 mb-20 rounded-xl transform 
                     transition-all duration-300 ease-out shadow-xl z-40
                     ${isPanelOpen ? 'translate-x-0' : 'translate-x-full'}`}
        >
          <FileDetailsPanel />

          <button
            onClick={handlePanelToggle}
            className="absolute -left-12 top-1/2 -translate-y-1/2 btn btn-circle btn-sm 
                       bg-base-200/95 backdrop-blur-sm hover:bg-base-300 z-50"
          >
            <ChevronRight 
              className={`transform transition-transform duration-300 ${
                isPanelOpen ? 'rotate-180' : ''
              }`} 
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
