import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { Maximize2, Minimize2, Send } from "lucide-react";
import { connect } from "react-redux";
import { i18n } from "../../../../utils";
import { MultiEmailInput } from "./MultiEmailInput";
import ButtonEmailList from "../../../Shared/ButtonEmailList";

interface GlobalFormState {
  autoSend: boolean;
  recipients: string[];
  message: string;
  transferName: string;
}

interface TransferInfosModel {
  id?: string;
  transfer_type?: number;
  transfer_name: string;
  transfer_message: string;
  transfer_password: string;
  transfer_confirm_password: string;
  recipients: string[];
  for_team: boolean;
  auto_remove: boolean;
  auto_send: boolean;
  transfer_state?: "pending" | "completed" | "error";
}

interface EmailFormProps {
  dispatch: Function;
  user: any;
  handleChangeEmailForm: (
    key: string,
    value: any,
    recipients?: string[]
  ) => void;
  transferInfos: TransferInfosModel;
  handleTransferInfosUpdate: (updatedInfo: Partial<TransferInfosModel>) => void;
  disabled?: boolean;
}

const defaultLimitRecipients: number = 50;

const EmailForm: React.FC<EmailFormProps> = ({
  transferInfos,
  handleTransferInfosUpdate,
  disabled,
  handleChangeEmailForm,
  user
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  // État local isolé pour le formulaire
  const [formState, setFormState] = useState<GlobalFormState>({
    autoSend: transferInfos.auto_send,
    recipients: transferInfos.recipients || [],
    message: transferInfos.transfer_message || "",
    transferName: transferInfos.transfer_name || ""
  });

  // Utiliser useRef pour stocker la version précédente des props
  const prevTransferInfosRef = useRef(transferInfos);

  useEffect(() => {
    const prevTransferInfos = prevTransferInfosRef.current;
    
    // Vérifier si les props ont changé de manière significative
    if (
      prevTransferInfos.auto_send !== transferInfos.auto_send ||
      prevTransferInfos.auto_remove !== transferInfos.auto_remove ||
      prevTransferInfos.for_team !== transferInfos.for_team ||
      JSON.stringify(prevTransferInfos.recipients) !== JSON.stringify(transferInfos.recipients)
    ) {
      setFormState(prevState => ({
        ...prevState,
        autoSend: transferInfos.auto_send,
        recipients: transferInfos.recipients || [],
        message: transferInfos.transfer_message || "",
        transferName: transferInfos.transfer_name || ""
      }));
    }
    
    // Mettre à jour la référence
    prevTransferInfosRef.current = transferInfos;
  }, [transferInfos]);

  const updateFormState = useCallback(
    <K extends keyof GlobalFormState>(key: K, value: GlobalFormState[K]) => {
      setFormState(prev => ({ ...prev, [key]: value }));
    },
    []
  );

  const handleEmailChange = useCallback(
    (newEmails: string[]) => {
      console.log("[DEBUG] UploaderEmailForm handleEmailChange - newEmails:", newEmails);
      updateFormState("recipients", newEmails);
      handleTransferInfosUpdate({
        recipients: newEmails
      });
      console.log("[DEBUG] UploaderEmailForm après handleTransferInfosUpdate - formState:", {
        recipients: newEmails,
        autoSend: formState.autoSend
      });
    },
    [updateFormState, handleTransferInfosUpdate, formState.autoSend]
  );

  // Le toggle auto_send met à jour uniquement le state local
  const handleAutoSendToggle = useCallback(() => {
    if (!disabled && !transferInfos.for_team) {
      // Utiliser directement l'état des props pour le toggle
      const newAutoSend = !transferInfos.auto_send;
      const newRecipients = newAutoSend ? [user.email] : [];

      // Mettre à jour le parent d'abord
      handleTransferInfosUpdate({
        auto_send: newAutoSend,
        recipients: newRecipients
      });

      // L'état local sera mis à jour via l'effet de synchronisation
    }
  }, [
    disabled,
    transferInfos.for_team,
    transferInfos.auto_send,
    user.email,
    handleTransferInfosUpdate
  ]);

  // Gestionnaire pour les changements locaux immédiats
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      if (name === "transfer_message") {
        updateFormState("message", value);
      } else if (name === "transfer_name") {
        updateFormState("transferName", value);
      }
    },
    [updateFormState]
  );

  // Gestionnaire pour la mise à jour du parent (appelé au blur)
  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      if (name === "transfer_message") {
        handleTransferInfosUpdate({
          transfer_message: value
        });
      } else if (name === "transfer_name") {
        handleTransferInfosUpdate({
          transfer_name: value
        });
      }
    },
    [handleTransferInfosUpdate]
  );

  const handleEmailSelect = useCallback(
    (email: string) => {
      if (!formState.recipients.includes(email)) {
        const newRecipients = [...formState.recipients, email];
        
        updateFormState("recipients", newRecipients);
        
        // Mettre à jour également l'état parent
        handleTransferInfosUpdate({
          recipients: newRecipients
        });
      }
    },
    [formState.recipients, updateFormState, handleTransferInfosUpdate]
  );

  // Lors de la validation, on met à jour l'état global via handleTransferInfosUpdate
  const handleValidate = useCallback(() => {
    console.log("[DEBUG] UploaderEmailForm handleValidate - formState:", {
      recipients: formState.recipients,
      recipientsLength: formState.recipients.length,
      autoSend: formState.autoSend
    });
    
    handleTransferInfosUpdate({
      transfer_message: formState.message,
      transfer_name: formState.transferName,
      recipients: formState.recipients,
      auto_send: formState.autoSend
    });
    
    console.log("[DEBUG] UploaderEmailForm après handleTransferInfosUpdate dans handleValidate");
    
    setIsFullscreen(false);
  }, [handleTransferInfosUpdate, formState]);

  return (
    <div className="container mx-auto">
      <div className="space-y-4 h-full overflow-x-hidden">
        <div className="flex gap-2">
          <div className="flex-grow">
            <MultiEmailInput
              emails={formState.recipients}
              onChange={handleEmailChange}
              disabled={disabled || transferInfos.for_team || formState.autoSend}
              placeholder={
                formState.autoSend
                  ? i18n._("form_upload_email_label")
                  : i18n._("form_upload_recipients_label")
              }
              maxEmails={transferInfos.for_team ? undefined : defaultLimitRecipients}
            />
            {useMemo(
              () => {
                console.log("[DEBUG] UploaderEmailForm ButtonEmailList - formState.recipients:", formState.recipients);
                return (
                  <ButtonEmailList
                    onEmailSelect={(email) => {
                      console.log("[DEBUG] UploaderEmailForm onEmailSelect - email:", email);
                      handleEmailSelect(email);
                    }}
                    onChange={(emails) => {
                      console.log("[DEBUG] UploaderEmailForm onChange - emails:", emails);
                      handleEmailChange(emails);
                    }}
                    user_id={user.id}
                    user_email={user.email}
                    addedEmails={formState.recipients}
                  />
                );
              },
              [handleEmailSelect, handleEmailChange, user.id, user.email, formState.recipients]
            )}
          </div>
          <div className="relative group flex h-14">
            <button
              type="button"
              disabled={disabled || transferInfos.for_team}
              className={`p-2 rounded ${
                formState.autoSend ? "text-emerald-500" : "text-gray-400"
              } ${
                disabled || transferInfos.for_team
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:text-gray-200"
              }`}
              onClick={handleAutoSendToggle}
            >
              <Send size={18} />
            </button>
            <div className="invisible group-hover:visible absolute z-10 px-2 py-1 text-sm text-white bg-gray-700 rounded -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
              {i18n._("auto_send_explanation")}
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <input
            disabled={disabled}
            placeholder={i18n._("form_upload_transfer_name_label")}
            onChange={handleInputChange}
            type="text"
            name="transfer_name"
            value={formState.transferName}
            className="w-full px-3 py-2 bg-gray-800 text-gray-200 border border-gray-600 rounded-md focus:ring-2 focus:ring-emerald-500"
            onBlur={handleInputBlur}
          />
          <div className="relative">
            <textarea
              disabled={disabled}
              className="w-full h-[120px] px-3 py-2 bg-gray-800 text-gray-200 border border-gray-600 rounded-md focus:ring-2 focus:ring-emerald-500 overflow-y-auto overflow-x-hidden"
              placeholder={i18n._("form_upload_transfer_message_label")}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name="transfer_message"
              value={formState.message}
            />
            <button
              type="button"
              onClick={() => setIsFullscreen(true)}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 hidden md:block"
              title={i18n._("expand_message") || "Agrandir"}
            >
              <Maximize2 size={16} />
            </button>
          </div>
        </div>
        <div
          className={`fixed inset-y-0 right-0 md:w-1/4 bg-gray-900 top-1/4 max-h-[500px] shadow-lg transform transition-transform duration-300 ease-in-out z-50 hidden md:block
            ${isFullscreen ? "translate-x-0" : "translate-x-full"}`}
        >
          <div className="h-full p-4 flex flex-col relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-200">
                {i18n._("form_upload_transfer_message_title")}
              </h2>
              <button
                type="button"
                onClick={() => setIsFullscreen(false)}
                className="text-gray-400 hover:text-gray-200"
              >
                <Minimize2 size={16} />
              </button>
            </div>
            <div className="flex-grow relative">
              <textarea
                className="w-full h-full px-3 py-2 bg-gray-800 text-gray-200 border border-gray-600 rounded-md focus:ring-2 focus:ring-emerald-500 resize-none"
                placeholder={i18n._("form_upload_transfer_message_label")}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                name="transfer_message"
                value={formState.message}
              />
            </div>
            <div className="mt-4 text-right">
              <button
                type="button"
                onClick={handleValidate}
                className="px-4 py-2 bg-gray-700 text-gray-200 rounded-md hover:bg-gray-600"
              >
                {i18n._("btn_valid_label")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapEmailFormStateToProps = (state: any) => ({
  user: state.user.user
});

export default connect(mapEmailFormStateToProps)(EmailForm);
