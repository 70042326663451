import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Clock } from 'lucide-react';
import i18n from '../../../../utils/i18n';

interface RootState {
  transfer: {
    activeTransferId: string | null;
    transfers: {
      [key: string]: {
        loaded: number;
        totalSize: number;
        progress: {
          current: {
            instantSpeed: number;
            avgSpeed: number;
          };
        };
      };
    };
  };
}

const selectTimeStats = (state: RootState) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : null;

  return {
    loaded: Number(activeTransfer?.loaded) || 0,
    totalSize: Number(activeTransfer?.totalSize) || 0,
    instantSpeed: activeTransfer?.progress?.current?.instantSpeed || 0,
    avgSpeed: activeTransfer?.progress?.current?.avgSpeed || 0
  };
};

export const RemainingTime: React.FC = () => {
  const { loaded, totalSize, instantSpeed, avgSpeed } = useSelector(selectTimeStats);

  const remainingTime = useMemo(() => {
    const remainingSize = totalSize - loaded;
    
    // Utiliser uniquement la vitesse moyenne pour une estimation plus précise
    const remainingTimeInSeconds = avgSpeed > 0 
      ? Math.max(0, Math.round(remainingSize / avgSpeed))
      : 0;

    const formatRemainingTime = (seconds: number) => {
      if (seconds === 0) return "-- : -- s";
      
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      
      if (hours > 0) {
        return `${hours}h ${minutes.toString().padStart(2, '0')}m`;
      }
      return `${minutes} : ${remainingSeconds.toString().padStart(2, '0')} s`;
    };

    return formatRemainingTime(remainingTimeInSeconds);
  }, [loaded, totalSize, avgSpeed]);

  return (
    <div className="flex flex-col items-center space-y-2">
      <Clock className="w-4 h-4 text-primary" />
      <div className="text-center">
        <span className="font-medium">{remainingTime}</span>
        <span className="block text-xs">{i18n._("upload_progress_remaining")}</span>
      </div>
    </div>
  );
};

RemainingTime.displayName = 'RemainingTime';
