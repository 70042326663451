import React from "react";
import { connect } from "react-redux";
import { HistoryComponent } from "../Shared";
import { i18n } from "../../../../utils";

interface Props {
  title: string;
  user: any;
}

interface TransferStats {
  successfulTransfers: number;
  totalDataTransferred: string;
}

interface State {
  searchQuery: string;
  stats: TransferStats;
  periodFilter: 'month' | 'year';
}

class SendComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchQuery: "",
      stats: {
        successfulTransfers: 0,
        totalDataTransferred: "0 Ko"
      },
      periodFilter: 'month'
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  handlePeriodChange(period: 'month' | 'year'): void {
    this.setState({ periodFilter: period });
  }

  updateStats = (filteredHistory: any[]): void => {
    const successfulTransfers = filteredHistory.filter(
      transfer => transfer.transfer_status === 0 || transfer.transfer_status === 1
    ).length;

    const totalSize = filteredHistory.reduce((total, transfer) => {
      return total + (transfer.transfer_size || 0);
    }, 0);

    this.setState({
      stats: {
        successfulTransfers,
        totalDataTransferred: this.formatSize(totalSize)
      }
    });
  }

  formatSize(bytes: number): string {
    const units = ['o', 'Ko', 'Mo', 'Go', 'To'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
  }

  handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ searchQuery: e.target.value });
  }

  render = () => {
    const { title } = this.props;
    const hasPermission = this.props.user?.permissions?.transfer_tracking?.value;

    if (!hasPermission) {
      return (
        <div className="mx-auto px-4 py-8">
          <div className="text-center">
            <h3 className="text-xl font-bold text-gray-700 mb-4">
              {i18n._("permission_denied")}
            </h3>
            <p className="text-gray-600">
              {i18n._("transfer_tracking_required")}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="max-w-[100rem] px-4 sm:px-6 lg:px-8 mx-auto">
        {/* En-tête */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-primary flex items-center">
            <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
            {title}
          </h1>
        </div>

        {/* Grille principale */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Section Statistiques */}
          <div className="col-span-1">
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 mb-4 flex items-center">
                <span className="w-2 h-8 bg-accent rounded-r mr-3"></span>
                {i18n._("send_stats_title")}
              </h2>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2 mb-2">
                  <span className="text-sm text-gray-600 dark:text-neutral-400">
                    {i18n._("period_label")} :
                  </span>
                  <select
                    value={this.state.periodFilter}
                    onChange={(e) => this.handlePeriodChange(e.target.value as 'month' | 'year')}
                    className="py-2 px-3 pe-9 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                  >
                    <option value="month">{i18n._("current_month")}</option>
                    <option value="year">{i18n._("rolling_year")}</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-primary/10 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">{i18n._("successful_transfers")}</p>
                    <p className="text-2xl font-bold text-primary">{this.state.stats.successfulTransfers}</p>
                  </div>
                  <div className="bg-secondary/10 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">{i18n._("data_transferred")}</p>
                    <p className="text-2xl font-bold text-secondary">{this.state.stats.totalDataTransferred}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section Historique */}
          <div className="col-span-1 lg:col-span-2">
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <div className="flex flex-col mb-4 gap-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
                  <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                  {i18n._("transfer_history")}
                </h2>
                
                <div className="relative w-full">
                  <input
                    type="text"
                    placeholder={i18n._("search_transfer_placeholder")}
                    className="pl-8 py-2 px-3 pe-9 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                  />
                  <svg
                    className="absolute left-2 sm:left-3 top-2 sm:top-2.5 w-4 sm:w-5 h-4 sm:h-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>
              <HistoryComponent
                key={`history-${this.state.periodFilter}`}
                type="upload"
                searchQuery={this.state.searchQuery}
                onSearch={(query: string) => this.setState({ searchQuery: query })}
                onDataFiltered={(filteredData: any[]) => this.updateStats(filteredData)}
                defaultPeriod={this.state.periodFilter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user?.user,
});

export default connect(mapStateToProps)(SendComponent);