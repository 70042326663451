import React from "react";
import { X } from "lucide-react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  showCloseButton?: boolean;
  size?: 'sm' | 'md' | 'lg';
  footer?: React.ReactNode;
}

export const FVModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  showCloseButton = true,
  size = 'md',
  footer
}) => {
  if (!isOpen) return null;

  const sizeClasses = {
    sm: 'w-11/12 md:w-2/3 lg:w-1/3',
    md: 'w-11/12 md:w-3/4 lg:w-1/2',
    lg: 'w-11/12 md:w-4/5 lg:w-2/3'
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center" aria-modal="true" role="dialog">
      {/* Backdrop avec flou */}
      <div 
        className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm transition-opacity"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div className={`relative ${sizeClasses[size]} max-h-[90vh] mx-auto z-10`}>
        <div className="bg-gray-800 rounded-xl shadow-2xl overflow-hidden border border-gray-700">
          {/* En-tête */}
          {(title || showCloseButton) && (
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                {title && (
                  <h3 className="text-lg font-semibold text-gray-100">
                    {title}
                  </h3>
                )}
                {showCloseButton && (
                  <button 
                    onClick={onClose}
                    className="p-2 hover:bg-gray-700/50 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5 text-gray-400" />
                  </button>
                )}
              </div>
            </div>
          )}

          {/* Corps */}
          <div className="p-6">
            {children}
          </div>

          {/* Pied */}
          {footer && (
            <div className="p-6 bg-gray-900 border-t border-gray-700">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FVModal;
