import axios from "axios";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { i18n } from "../utils";

// Cache des requêtes GET pour réduire les appels au serveur
const requestCache = new Map<string, { data: any; timestamp: number }>();
const cacheTTLs = {
  default: 60000, // 1 minute par défaut
  statistics: 300000, // 5 minutes pour les statistiques
  comments: 30000, // 30 secondes pour les commentaires
  validations: 30000, // 30 secondes pour les validations
};

// Limiter le nombre de connexions simultanées
const MAX_CONCURRENT_REQUESTS = 10;
let activeRequests = 0;
const requestQueue: Array<() => void> = [];

// Définir des limites raisonnables pour les retries
axiosRetry(axios, {
  retries: 5, // Réduit de 1000 à 5
  retryCondition: (error: AxiosError) => {
    const jsonError: any = error.toJSON();
    
    // Si le serveur est déjà surchargé, attendre plus longtemps avant de réessayer
    if (jsonError?.status === 429 || jsonError?.status === 503) {
      return true;
    }
    
    // Retry si pas de réponse du serveur ou si le status est undefined
    if (!jsonError || !jsonError.status) {
      return true;
    }
    // Retry pour les erreurs 5xx (erreurs serveur)
    if (jsonError.status >= 500) {
      return true;
    }
    // Ne pas retry pour les erreurs 4xx (erreurs client)
    return false;
  },
  retryDelay: (retryCount: number) => {
    // Backoff exponentiel: 1s, 2s, 4s, 8s, 16s
    let delay = Math.pow(2, retryCount - 1) * 1000;
    
    // Ajouter un peu de "jitter" pour éviter les tempêtes de requêtes
    delay += Math.random() * 1000;
    
    // Ne jamais dépasser 20 secondes
    if (delay > 20_000) delay = 20_000;
    
    // Pour la compatibilité
    //@ts-ignore
    window.retryDelay = delay;
    
    return delay;
  },
});

// Créer un intercepteur pour gérer la file d'attente de requêtes
axios.interceptors.request.use(async (config) => {
  // Si on dépasse le nombre max de requêtes, mettre en file d'attente
  if (activeRequests >= MAX_CONCURRENT_REQUESTS) {
    await new Promise<void>(resolve => {
      requestQueue.push(resolve);
    });
  }
  
  activeRequests++;
  return config;
});

// Gérer la libération des requêtes
axios.interceptors.response.use(
  (response) => {
    activeRequests--;
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      if (nextRequest) nextRequest();
    }
    return response;
  },
  (error) => {
    activeRequests--;
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      if (nextRequest) nextRequest();
    }
    return Promise.reject(error);
  }
);

// Variable pour suivre l'état du transfert
let isTransferInProgress = false;

// Fonction utilitaire pour la mise en cache des requêtes
const cachedRequest = async (cacheKey: string, requestFn: () => Promise<any>, ttl = cacheTTLs.default) => {
  // Vérifier si la réponse est en cache et toujours valide
  const cachedItem = requestCache.get(cacheKey);
  if (cachedItem && Date.now() - cachedItem.timestamp < ttl) {
    return cachedItem.data;
  }
  
  // Faire la requête
  const response = await requestFn();
  
  // Mettre en cache
  requestCache.set(cacheKey, {
    data: response,
    timestamp: Date.now()
  });
  
  return response;
};

// Nettoyer le cache périodiquement pour éviter les fuites mémoire
setInterval(() => {
  const now = Date.now();
  for (const [key, value] of requestCache.entries()) {
    if (now - value.timestamp > cacheTTLs.default) {
      requestCache.delete(key);
    }
  }
}, 300000); // Nettoyer toutes les 5 minutes

const transferApi = {
  async getConnectionDetails(): Promise<{
    quality: "excellent" | "good" | "medium" | "poor" | "unknown";
    lastCheck: string;
    lastLatency: number;
  }> {
    // Si un transfert est en cours, retourner la dernière qualité connue
    if (isTransferInProgress) {
      return {
        quality: "unknown",
        lastCheck: new Date().toISOString(),
        lastLatency: -1
      };
    }

    // Utiliser le cache si disponible
    const cacheKey = 'connection-details';
    return cachedRequest(cacheKey, async () => {
      // Mesurer le temps de réponse avec 3 pings pour plus de précision
      const pings = [];
      for (let i = 0; i < 3; i++) {
        const start = performance.now();
        try {
          await axios.get('/transfer/connection/ping');
          const end = performance.now();
          pings.push(end - start);
        } catch (error) {
          pings.push(Infinity);
        }
      }
  
      // Calculer la latence moyenne en excluant les valeurs Infinity
      const validPings = pings.filter(p => p !== Infinity);
      const avgLatency = validPings.length > 0
        ? validPings.reduce((a, b) => a + b, 0) / validPings.length
        : Infinity;
  
      // Envoyer la latence mesurée au serveur
      try {
        const response = await axios.get('/transfer/connection/details', {
          params: { latency: avgLatency !== Infinity ? avgLatency : -1 }
        });
  
        if (!response.data?.quality || !['excellent', 'good', 'medium', 'poor', 'unknown'].includes(response.data.quality)) {
          throw new Error('Invalid connection quality value');
        }
  
        return {
          quality: response.data.quality,
          lastCheck: response.data.lastCheck || new Date().toISOString(),
          lastLatency: avgLatency === Infinity ? -1 : avgLatency
        };
      } catch (error) {
        return {
          quality: 'unknown',
          lastCheck: new Date().toISOString(),
          lastLatency: -1
        };
      }
    }, 60000); // Cache valide 1 minute
  },

  async getStatistics({ start_date, end_date }: { start_date: string, end_date: string }): Promise<any> {
    // Utiliser un cache spécifique pour les statistiques
    const cacheKey = `statistics-${start_date}-${end_date}`;
    return cachedRequest(cacheKey, async () => {
      try {
        let stats = await axios.get(`/transfer/statistics`, {
          params: {
            start_date,
            end_date,
          },
        });
  
        if (stats.data) {
          Object.keys(stats.data?.consumption).forEach((itemKey: string) => {
            stats.data.consumption[itemKey] = Number(
              stats.data.consumption[itemKey]
            );
          });
          return stats.data;
        }
        return {};
      } catch (e) {
        return null;
      }
    }, cacheTTLs.statistics);
  },

  async getTransferComments(transfer_id: string) {
    const cacheKey = `comments-${transfer_id}`;
    return cachedRequest(cacheKey, async () => {
      try {
        return await axios.get(`/transfer/${transfer_id}/comment`);
      } catch (e) {
        return { error: "Unable to get transfer comment" };
      }
    }, cacheTTLs.comments);
  },

  async getValidations(transfer_id: string, file_id: string | number) {
    const cacheKey = `validations-${transfer_id}-${file_id}`;
    return cachedRequest(cacheKey, async () => {
      try {
        return await axios.get(
          `/transfer/${transfer_id}/file/${file_id}/validations`
        );
      } catch (e) {
        return { error: "Unable to get transfer validations" };
      }
    }, cacheTTLs.validations);
  },

  async getLightTransfer(fields: string[], transfer_id: string) {
    const cacheKey = `light-transfer-${transfer_id}-${fields.join('-')}`;
    return cachedRequest(cacheKey, async () => {
      try {
        return await axios.get(`/transfer/${transfer_id}`, {
          params: {
            fields,
            collab: true,
          },
        });
      } catch (e) {
        return { error: "Unable to get transfer light" };
      }
    }, 30000); // Cache de 30 secondes
  },

  async addTransferComment(comment: any, transfer_id: string) {
    try {
      const result = await axios.post(`/transfer/${transfer_id}/comment`, comment);
      // Invalider le cache des commentaires
      requestCache.delete(`comments-${transfer_id}`);
      return result;
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async updateTransferComment(
    transfer_id: string,
    comment_id: number,
    payload: { file_id?: number; status?: number }
  ) {
    try {
      const result = await axios.put(
        `/transfer/${transfer_id}/comments/${comment_id}`,
        payload
      );
      // Invalider le cache des commentaires
      requestCache.delete(`comments-${transfer_id}`);
      return result;
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async addTransferValidation({ file_id, transfer_id }: { file_id: string | number, transfer_id: string }) {
    try {
      const result = await axios.post(
        `/transfer/${transfer_id}/file/${file_id}/validations`
      );
      // Invalider le cache des validations
      requestCache.delete(`validations-${transfer_id}-${file_id}`);
      return result;
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async updateStatus(transfer_status: any, transfer_id: string): Promise<any> {
    try {
      const transfer = await axios.put(`/transfer/${transfer_id}`, {
        data: { transfer_status },
      });
      
      // Invalider les caches associés à ce transfert
      for (const key of requestCache.keys()) {
        if (key.includes(transfer_id)) {
          requestCache.delete(key);
        }
      }
      
      return transfer.data;
    } catch (e) {
      return { error: "Unable to update transfer" };
    }
  },

  async updateExpiration(
    transfer_expiration: any,
    transfer_id: string
  ): Promise<any> {
    try {
      const transfer = await axios.put(`/transfer/${transfer_id}`, {
        data: { transfer_expiration },
      });
      
      // Invalider les caches associés à ce transfert
      for (const key of requestCache.keys()) {
        if (key.includes(transfer_id)) {
          requestCache.delete(key);
        }
      }
      
      return transfer.data;
    } catch (e) {
      return { error: "Unable to update transfer" };
    }
  },

  async patchTransfer(transfer_infos: any, transfer_id: string): Promise<any> {
    try {
      const transfer = await axios.patch(
        `/transfer/${transfer_id}/upload`,
        transfer_infos
      );
      
      // Invalider les caches associés à ce transfert
      for (const key of requestCache.keys()) {
        if (key.includes(transfer_id)) {
          requestCache.delete(key);
        }
      }
      
      return transfer.data;
    } catch (e) {
      return { error: "Unable to update transfer" };
    }
  },

  async createTransfer(transfer_infos: any): Promise<any> {
    try {
      if (transfer_infos?.transfer_name) {
        transfer_infos.transfer_name = unescape(transfer_infos.transfer_name);
      }
      const transfer = await axios.post(`/transfer/upload`, transfer_infos);
      return transfer.data;
    } catch (e) {
      return { error: "Unable to initialize transfer" };
    }
  },

  async getPresignedPostData(
    selectedFile: any,
    transfer: any,
    userType: string = "free",
    config: {
      chunkSize?: number;
      maxConcurrentUploads?: number;
      retries?: number;
      timeout?: number;
    } = {}
  ) {
    try {
      // Validation des paramètres requis
      if (!selectedFile || !transfer) {
        throw new Error("Missing required parameters");
      }

      // Création d'un nouveau transfert si nécessaire
      if (!transfer.transfer_id) {
        const newTransfer = await this.createTransfer({
          transfer_name: selectedFile.name,
          auto_remove: true
        });
        
        if (newTransfer.error) {
          throw new Error(newTransfer.error);
        }
        
        transfer.transfer_id = newTransfer.transfer_id;
        transfer.bucket_id = newTransfer.bucket_id;
      }

      // Vérification des IDs nécessaires
      if (!transfer.transfer_id || !transfer.bucket_id) {
        throw new Error("Invalid transfer or bucket ID");
      }

      // Calcul standardisé de la taille des chunks
      const chunkSize = config.chunkSize || (() => {
        const max_parts = 100;
        const min_size = Math.pow(1024, 2) * 10; // 10MB minimum
        const max_size = Math.pow(1024, 3) * 2;  // 2GB maximum
        const optimal_parts = Math.ceil(Math.sqrt(selectedFile.size / min_size));
        
        let calculatedSize = Math.max(
          min_size,
          Math.ceil(selectedFile.size / Math.min(optimal_parts, max_parts))
        );
        
        // Arrondir à un multiple de 1MB pour plus de cohérence
        calculatedSize = Math.ceil(calculatedSize / (1024 * 1024)) * (1024 * 1024);
        calculatedSize = Math.min(calculatedSize, max_size);
        
        return calculatedSize;
      })();

      // Nombre d'uploads concurrents optimal - limité à 10 maximum pour éviter la surcharge
      const maxConcurrentUploads = Math.min(
        config.maxConcurrentUploads || Math.min(
          Math.max(3, Math.ceil(selectedFile.size / (100 * 1024 * 1024))), // Min 3, +1 par 100MB
          10 // Max 10 concurrent uploads (réduit de 100)
        ), 
        10
      );

      // Utiliser l'instance axios principale qui a déjà les intercepteurs pour l'authentification
      const signedRes = await axios.post(
        `/transfer/${transfer.transfer_id}/upload/file/sign/${transfer.bucket_id}`,
        {
          ...selectedFile,
          chunkSize,
          maxConcurrentUploads
        }
      );

      if (!signedRes.data) {
        throw new Error("Invalid response from signing service");
      }

      return signedRes.data;
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || e.message || "Échec de la signature";
      return { 
        error: errorMessage,
        details: e.response?.data || e.message
      };
    }
  },

  async uploadFileToS3(
    presignedPostData: any,
    file: any,
    onProgress: any,
    { transfer_id, bucket_id }: { transfer_id: string; bucket_id?: any }, // Ajout de bucket_id comme optionnel
    config: {
      maxConcurrentUploads?: number;
      retries?: number;
      timeout?: number;
      chunkSize?: number;
      connectionQuality?: "excellent" | "good" | "medium" | "poor" | "unknown";
    } = {}
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!presignedPostData?.multiPart || !file || !transfer_id) {
          throw new Error("Paramètres invalides pour l'upload");
        }

        // Marquer le début du transfert
        isTransferInProgress = true;

        // Configuration selon la qualité de connexion
        const quality = config.connectionQuality || 'unknown';
        let concurrencyFromQuality = 10;     // Par défaut limité à 10
        let chunkSizeFromQuality = 100 * 1024 * 1024; // Excellent par défaut pour unknown
        
        switch (quality) {
          case "excellent":
            concurrencyFromQuality = 10; // Limité à 10 (réduit de 100)
            chunkSizeFromQuality = 200 * 1024 * 1024; // 200MB
            break;
          case "good":
            concurrencyFromQuality = 8; // Réduit de 80
            chunkSizeFromQuality = 100 * 1024 * 1024; // 100MB
            break;
          case "medium":
            concurrencyFromQuality = 5; // Réduit de 50
            chunkSizeFromQuality = 50 * 1024 * 1024; // 50MB
            break;
          case "poor":
            concurrencyFromQuality = 3; // Réduit de 8
            chunkSizeFromQuality = 10 * 1024 * 1024; // 10MB
            break;
        }

        // Paramètres finaux - limités à 10 maximum pour la concurrence
        const finalConcurrency = Math.min(config.maxConcurrentUploads || concurrencyFromQuality, 10);
        let finalChunkSize = config.chunkSize || chunkSizeFromQuality;

        // Ajuster la taille des chunks pour les gros fichiers
        {
          const max_parts = 100;
          const max_size = 2 * 1024 * 1024 * 1024; // 2GB
          while (file.size / finalChunkSize > max_parts && finalChunkSize < max_size) {
            finalChunkSize = Math.min(finalChunkSize * 2, max_size);
          }
        }

        // Retries et timeout selon la taille - limités à 5 retries maximum
        const finalRetries = Math.min(config.retries || Math.min(5, Math.ceil(file.size / (10 * 1024 * 1024))), 5);
        const finalTimeout = config.timeout || Math.max(30000, Math.ceil(file.size / (50 * 1024)));

        const generatedParts = presignedPostData.multiPart;
        
        // Vérification de cohérence avant upload
        // Validation des parts générées par le serveur
        const fileSize = file.size;
        
        // Utiliser le nombre de parties générées par le serveur comme référence
        const serverPartNumbers = Object.keys(generatedParts).map(Number).sort((a, b) => a - b);
        const receivedPartsCount = serverPartNumbers.length;
        
        // Calculer le nombre attendu de parties avec la taille de chunk actuelle
        const expectedPartsCount = Math.ceil(fileSize / finalChunkSize);
        
        // Toujours ajuster la taille des chunks en fonction du nombre de parties reçues
        // pour éviter complètement les parties vides
        if (receivedPartsCount > 0) {
          // Calculer une nouvelle taille de chunk basée sur le nombre de parties reçues
          // en divisant exactement la taille du fichier par le nombre de parties
          const newChunkSize = Math.ceil(fileSize / receivedPartsCount);
          
          // Limites pour maintenir les performances
          const minChunkSize = 5 * 1024 * 1024; // 5MB minimum
          const maxChunkSize = 2 * 1024 * 1024 * 1024; // 2GB maximum
          
          // Appliquer la nouvelle taille
          if (newChunkSize >= minChunkSize && newChunkSize <= maxChunkSize) {
            finalChunkSize = newChunkSize;
          }
        }
        
        const presignedParts = await uploadParts(
          file,
          finalChunkSize,
          generatedParts,
          onProgress,
          finalConcurrency,
          finalRetries
        );
  
        if (!presignedParts?.length) {
          throw new Error("Échec de l'upload des parties");
        }

        // Vérification finale des parts
        // Utiliser les numéros de parties fournis par le serveur comme référence
        const expectedPartNumbers = Object.keys(generatedParts).map(Number).sort((a, b) => a - b);
        const uploadedPartNumbers = presignedParts.map((p: { PartNumber: number }) => p.PartNumber).sort((a, b) => a - b);
        
        // Vérifier uniquement les parties qui étaient attendues par le serveur
        const missingParts = expectedPartNumbers.filter(
          partNum => !uploadedPartNumbers.includes(partNum)
        );

        if (missingParts.length > 0) {
          throw new Error(`Parts manquantes: ${missingParts.join(", ")}`);
        }
  
        const final = {
          uploadId: presignedPostData.multiPartInfos.UploadId,
          Parts: presignedParts,
          Key: presignedPostData.multiPartInfos.Key
        };

        // Finalisation de l'upload avec gestion des erreurs
        try {
          const result = await axios.put(
            `/transfer/${transfer_id}/upload/file/complete/${final.uploadId}`,
            {
              Parts: presignedParts,
              Key: final.Key,
            }
          );

          // Réinitialiser le flag une fois le transfert terminé
          isTransferInProgress = false;
          resolve(result);
        } catch (error: any) {
          // Réinitialiser le flag en cas d'erreur
          isTransferInProgress = false;
          reject(new Error(`Échec de la finalisation: ${error.message}`));
        }
      } catch (error: any) {
        // Réinitialiser le flag en cas d'erreur
        isTransferInProgress = false;
        reject(new Error(`Échec de l'upload: ${error.message}`));
      }
    });
  },

  async finalizeTransfer(
    transfer_infos: any,
    transfer: any,
    uploader_email?: string,
    is_received?: boolean
  ): Promise<any> {
    try {
      // Calcul du timeout basé sur la taille du transfert
      const transferSize = transfer_infos?.transfer_size || 0;
      const baseTimeout = 60000; // 60 secondes minimum
      const timeoutPerMB = 200; // 200ms par MB
      const calculatedTimeout = Math.min(
        baseTimeout + (transferSize / (1024 * 1024)) * timeoutPerMB,
        300000 // Maximum 5 minutes
      );

      // Utiliser la langue du client
      const userLocale = i18n?.lang || 'fr_FR';
      
      // Utiliser directement axios au lieu de créer une nouvelle instance
      // L'instance principale a déjà la configuration de retry
      const result = await axios.patch(
        `/transfer/${transfer.transfer_id}/upload/finalize/${transfer.bucket_id}`,
        transfer_infos,
        {
          params: {
            uploader_email,
            is_received,
            locale: userLocale // Transmettre la langue au serveur
          },
          timeout: calculatedTimeout // Timeout dynamique
        }
      );

      // Invalider les caches liés à ce transfert
      for (const key of requestCache.keys()) {
        if (key.includes(transfer.transfer_id)) {
          requestCache.delete(key);
        }
      }

      return result?.data || result || null;
    } catch (error: any) {
      throw error;
    }
  },

  async getApiVersion(): Promise<any> {
    const cacheKey = 'api-version';
    return cachedRequest(cacheKey, async () => {
      return axios.get(`http://localhost:3003/api/v1/version`);
    }, 3600000); // Cache pendant 1 heure
  },

  async getTransfer(
    id: string,
    transfer_password: string | undefined = undefined,
    auth?: boolean,
    is_history?: any
  ): Promise<any> {
    // Utiliser le cache uniquement pour les requêtes sans mot de passe
    const useCache = !transfer_password;
    const cacheKey = `transfer-${id}-${!!auth}-${!!is_history}`;
    
    const fetchTransfer = async () => {
      try {
        // Si c'est un accès via l'historique, on considère que l'utilisateur est authentifié
        const isAuthorized = auth === true || is_history === true;
        
        try {
          const transfer = await axios.get(`/transfer/${id}`, {
            params: {
              transfer_password,
              is_history,
            },
          });
  
          // Si on reçoit une réponse avec des données
          if (transfer.data) {
            const hasProtection = !!(transfer.data.transfer_password || transfer.data.has_password || transfer.data.password);
            const protectionDetails = {
              transfer_password: transfer.data.transfer_password,
              has_password: transfer.data.has_password,
              password: transfer.data.password
            };
            
            // Vérifier si le transfert a un mot de passe mais que l'utilisateur n'en a pas fourni
            // Ne pas bloquer l'accès si auth=true (l'utilisateur est l'émetteur)
            if (!isAuthorized && !transfer_password && (transfer.data.transfer_password || transfer.data.has_password || transfer.data.password)) {
              return {
                transferLocked: true,
                isLocked: true,
                password_protected: true,
                is_protected: true,
                transfer_password: transfer.data.transfer_password,
                files: [],
                transfer_expiration: transfer.data.transfer_expiration || '',
                has_malware_file: false,
                has_error_file: false,
                has_processing_file: false
              };
            }
  
            // Toujours définir les flags de protection si nécessaire
            if (hasProtection) {
              const response = {
                ...transfer.data,
                is_protected: true,
                password_protected: true,
                transferLocked: !isAuthorized,
                isLocked: !isAuthorized,
                // Ne garder le mot de passe que si l'utilisateur est autorisé
                transfer_password: isAuthorized ? transfer.data.transfer_password : undefined
              };
            }
  
            return transfer.data;
          }
  
          return [];
  
        } catch (axiosError: any) {
          // Si on reçoit une erreur 401, le transfert est protégé
          // Ne pas bloquer l'accès si l'utilisateur est autorisé (émetteur ou historique)
          if (!isAuthorized && (axiosError.response?.status === 401 || axiosError.response?.data?.error?.code === "UNAUTHORIZED")) {
            return {
              transferLocked: true,
              isLocked: true,
              password_protected: true,
              is_protected: true,
              transfer_password: axiosError.response?.data?.transfer_password,
              files: [],
              transfer_expiration: '',
              has_malware_file: false,
              has_error_file: false,
              has_processing_file: false
            };
          }
          
          throw axiosError;
        }
      } catch (e: any) {
        throw e;
      }
    };
    
    // Utiliser le cache si applicable
    if (useCache) {
      return cachedRequest(cacheKey, fetchTransfer, 30000); // Cache de 30 secondes
    } else {
      return fetchTransfer();
    }
  },

  async getReceive(id: string, uploader_email?: string, locale?: string): Promise<any> {
    const cacheKey = `receive-${id}-${uploader_email || ''}`;
    
    return cachedRequest(cacheKey, async () => {
      try {
        // Utiliser la langue du client si disponible, sinon utiliser fr_FR par défaut
        const userLocale = locale || (i18n?.lang || 'fr_FR');
        
        let transferConfig: AxiosResponse & any = await axios.get(
          `/transfer/${id}`,
          {
            params: {
              is_received: true,
              uploader_email,
              locale: userLocale // Transmettre la langue au serveur
            },
          }
        );
  
        if (
          transferConfig &&
          transferConfig.error &&
          transferConfig.error.code === "UNAUTHORIZED"
        )
          transferConfig = { data: { transferLocked: true } };
  
        return transferConfig && transferConfig.data ? transferConfig.data : {};
      } catch (e) {
        return Promise.reject(e);
      }
    }, 30000); // Cache de 30 secondes
  },

  async downloadFile(
    { transfer_id, file_id }: { transfer_id: string, file_id: number },
    onDownloadProgress: (
      progressEvent: any,
      file_id: number
    ) => void = () => {}
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      responseType: "blob",
      onDownloadProgress: (progressEvent) =>
        onDownloadProgress(progressEvent, file_id),
    };
    const file = await axios.get(
      `/transfer/${transfer_id}/file/${file_id}`,
      config
    );
    return file && file.data ? file.data : null;
  },

  async downlaodTransfer(
    transfer_id: string,
    onDownloadProgress: (
      progressEvent: any,
      file_id: number
    ) => void = () => {}
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      responseType: "blob",
      onDownloadProgress: (progressEvent) =>
        onDownloadProgress(progressEvent, parseInt(transfer_id, 10) || 0),
    };

    try {
      const transferArchive = await axios.get(
        `/transfer/${transfer_id}/zip`,
        config
      );

      return transferArchive.data || null;
    } catch (error: any) {
      throw error;
    }
  },

  async getAllUserTransfer(user_id: number, dateRange?: string): Promise<any[]> {
    const cacheKey = `user-transfers-${user_id}-${dateRange || 'default'}`;
    
    return cachedRequest(cacheKey, async () => {
      // Si dateRange est 'all', ajouter un paramètre pour récupérer tous les transferts
      const params = dateRange === 'all' ? { all_history: true } : {};
      const transfer = await axios.get(`/user/${user_id}/transfer`, { params });
      return transfer && transfer.data ? transfer.data : [];
    }, 30000); // Cache de 30 secondes
  },

  async setTransferConsumption(
    path: string,
    consumption: any
  ): Promise<any> {
    const result = await axios.put(path, consumption);
    
    // Invalider les caches qui pourraient être affectés par cette mise à jour
    // Extraire l'ID du transfert de l'URL 
    const transferIdMatch = path.match(/\/transfer\/([^\/]+)/);
    const transferId = transferIdMatch ? transferIdMatch[1] : null;
    
    if (transferId) {
      for (const key of requestCache.keys()) {
        if (key.includes(transferId)) {
          requestCache.delete(key);
        }
      }
    }
    
    return result && result.data ? result.data : null;
  },

  async deleteManyTransfer(transfers: string[]): Promise<any> {
    try {
      const result = await axios.delete(`/transfer`, {
        params: { transfers },
      });
      
      // Invalider les caches pour tous les transferts supprimés
      for (const transferId of transfers) {
        for (const key of requestCache.keys()) {
          if (key.includes(transferId)) {
            requestCache.delete(key);
          }
        }
      }
      
      return result.data || null;
    } catch (e) {
      return null;
    }
  },

  async createNewReceive(transfer_infos: any): Promise<any> {
    try {
      const transfer = await axios.post(`/transfer/receive`, transfer_infos);
      return transfer.data;
    } catch (e) {
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async createNewDeposit(hub_id?: any): Promise<any> {
    try {
      const transfer = await axios.post(`/transfer/deposit`, {
        hub_id,
      });
      return transfer.data;
    } catch (e) {
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },
};

/**
 * Upload des parties de fichier vers S3 avec limite de concurrence
 */
async function uploadParts(
  file: Blob | Buffer,
  partSize: number,
  urls: Record<string, string>,
  onProgress: (progressEvent: any, partIndex: string) => void,
  max: number = 10, // Réduit de 25 à 10 par défaut
  maxRetries: number = 5 // Limité à 5 retries
): Promise<Array<{ ETag: string; PartNumber: number }>> {
  // Créer une instance unique pour tous les uploads S3
  const axiosS3 = Axios.create({
    withCredentials: false,
  });
  
  // Configurer les timeouts et headers
  axiosS3.defaults.timeout = 0;
  delete axiosS3.defaults.headers.put["Content-Type"];

  // Configurer les retries avec des limites raisonnables
  axiosRetry(axiosS3, {
    retries: maxRetries, // Limité à maxRetries (5 par défaut)
    retryCondition: (error: AxiosError | any) => {
      // Ne pas réessayer sur les erreurs d'authentification (403)
      return !(error?.response?.status === 403);
    },
    retryDelay: (retryCount: number) => {
      // Backoff exponentiel avec jitter pour éviter les tempêtes de requêtes
      const baseDelay = Math.min(1000 * Math.pow(1.5, retryCount - 1), 10000);
      const jitter = Math.random() * 1000;
      return baseDelay + jitter;
    },
  });

  const keys = Object.keys(urls);
  const promises: Array<Promise<{ ETag: string; PartNumber: number }>> = [];

  // Trier les clés numériquement pour s'assurer que les parties sont traitées dans l'ordre
  const sortedKeys = keys.map(Number).sort((a, b) => a - b).map(String);
  
  // Calculer la taille totale du fichier
  const totalSize = file instanceof Blob ? file.size : file.length;
  
  // Toujours utiliser le découpage basé sur le nombre de parties du serveur
  // pour éviter complètement les parties vides
  const partCount = sortedKeys.length;
  
  // Calculer la taille exacte de chaque partie pour éviter les parties vides
  const exactPartSize = Math.ceil(totalSize / partCount);
  
  // Créer un pool de promesses pour limiter les uploads concurrents
  let activeUploads = 0;
  const uploadQueue: Array<() => Promise<any>> = [];
  
  // Compteur pour les statistiques
  let completedUploads = 0;
  let failedUploads = 0;
  
  // Fonction pour exécuter la prochaine tâche dans la file d'attente
  const processNextUpload = async (): Promise<any> => {
    if (uploadQueue.length === 0 || activeUploads >= max) return null;
    
    activeUploads++;
    const upload = uploadQueue.shift();
    if (!upload) {
      activeUploads--;
      return null;
    }
    
    try {
      const result = await upload();
      completedUploads++;
      // Retourner le résultat pour pouvoir extraire l'ETag
      return result;
    } catch (error) {
      failedUploads++;
      throw error;
    } finally {
      activeUploads--;
      processNextUpload();
    }
  };

  for (const indexStr of sortedKeys) {
    const partNumber = parseInt(indexStr);
    const index = partNumber - 1;
    
    let start: number, end: number;
    let blob: Blob | Buffer;
    
    // Utiliser toujours le découpage adaptatif basé sur le nombre exact de parties
    start = index * exactPartSize;
    end = Math.min((index + 1) * exactPartSize, totalSize);
    
    blob = file.slice(start, end) as (Blob | Buffer);
    const blobSize = blob instanceof Blob ? blob.size : (blob as Buffer).length;

    // Vérifier si la partie a une taille valide
    if (blobSize > 0) {
      // Créer une promesse pour l'upload qui capture l'ETag directement
      const uploadPromise = async () => {
        // Utiliser l'instance axiosS3 pour faire la requête PUT
        const response = await axiosS3.put(urls[indexStr], blob, {
          onUploadProgress: (temp1: any) => {
            onProgress(temp1, indexStr);
          },
        });
        
        // Retourner la réponse complète pour extraire l'ETag
        return response;
      };
      
      // Ajouter la tâche à la file d'attente
      uploadQueue.push(uploadPromise);
      
      // Créer une promesse qui attend que la tâche soit exécutée
      promises.push(new Promise<{ ETag: string; PartNumber: number }>((resolve, reject) => {
        const checkQueue = () => {
          if (activeUploads < max) {
            processNextUpload()
              .then((response) => {
                // Extraire l'ETag de la réponse PUT
                const etag = response?.headers?.etag;
                if (etag) {
                  resolve({ ETag: etag, PartNumber: partNumber });
                } else {
                  // Fallback si l'ETag n'est pas disponible
                  console.warn(`ETag non disponible pour la partie ${partNumber}, utilisation d'un ETag généré`);
                  resolve({ ETag: `"${partNumber}"`, PartNumber: partNumber });
                }
              })
              .catch(reject);
          } else {
            // Réessayer plus tard
            setTimeout(checkQueue, 100);
          }
        };
        checkQueue();
      }));
    }
  }

  // Lancer le traitement initial
  while (activeUploads < max && uploadQueue.length > 0) {
    processNextUpload();
  }

  try {
    // Attendre que toutes les promesses soient résolues
    const results = await Promise.all(promises);
    
    // Normaliser les ETags pour s'assurer qu'ils sont correctement formatés
    const normalizedResults = results.map(part => {
      // S'assurer que l'ETag est entouré de guillemets comme requis par S3
      let etag = part.ETag;
      if (etag && !etag.startsWith('"') && !etag.endsWith('"')) {
        etag = `"${etag}"`;
      } else if (etag && etag.startsWith('"') && etag.endsWith('"')) {
        // Déjà correctement formaté
      } else {
        // Fallback si l'ETag est invalide
        etag = `"${part.PartNumber}"`;
      }
      
      return {
        ...part,
        ETag: etag
      };
    });
    
    return normalizedResults.sort((a, b) => a.PartNumber - b.PartNumber);
  } catch (e) {
    console.error(`Échec des uploads: ${completedUploads} réussis, ${failedUploads} échoués`);
    throw e;
  }
}

export default transferApi;