import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import TooltipComponent from "../../../../../Components/Tooltip/Tooltip";
import { FVTransferDetailsModal } from "../../../../FVModal/FVTransferDetailsModal";
import { connect } from "react-redux";
import { config } from "../../../../../config";
import { transferApi } from "../../../../../Api";
import { i18n, wrapStr } from "../../../../../utils";
import convertSize from "../../../../../utils/convertSize";
import Toast from "../../../../Toast/Toast";
import FolderLoader from "../../../../FolderLoader/FolderLoader";
import {
  Copy,
  Lock,
  Trash2,
  File,
  FolderLock,
  Mail,
  Info,
  ChevronLeft,
  ChevronRight,
  X,
  Cloud,
  Zap,
  Leaf,
  Droplet,
  Car,
  Calendar,
  Clock,
  Filter,
  Calendar as CalendarIcon
} from "lucide-react";

// Types
interface Props {
  type: "upload" | "receive" | "collab";
  user: any;
  searchQuery: string;
  onSearch: (query: string) => void;
  onDataFiltered?: (filteredData: History[]) => void;
  defaultPeriod?: 'month' | 'year';
}

interface HistoryRef {
  setPeriodFilter: (period: 'month' | 'year' | 'all') => void;
}

interface History {
  transfer_name: string;
  transfer_id: string;
  date_transfer: string;
  expiration_transfer?: string;
  transfer_status: number;
  isSelected?: boolean;
  transferLocked?: boolean;
  recipients?: string;
  transfer_provider?: string;
  for_team?: boolean;
  downloaded?: number;
  nb_files?: number;
  transfer_type: number;
  transfer_sender?: string;
  transfer_size?: number;
}

// Status Types
type BaseStatusType = '-2' | '-1' | '0' | '1' | '2';
type CommonStatusValue = 'aborted' | 'downloaded' | 'expired';
type StandardStatusValue = 'waiting' | 'uploaded';
type CollabStatusValue = 'wip' | 'validated';

type StatusValue = CommonStatusValue | StandardStatusValue;
type CollabValue = CommonStatusValue | CollabStatusValue;

interface StatusMapType {
  [key: string]: StatusValue;
}

interface CollabStatusMapType {
  [key: string]: CollabValue;
}

// Constants
const maxLength = 30;

const historyTab = [
  "tab_history_sender_label",
  "tab_history_recipients_label",
  "tab_history_transfer_label",
  "tab_history_dates_label",
  "tab_history_status_label",
  "tab_history_copy_label",
];

const historyCollabTab = [
  "tab_history_sender_label",
  "tab_history_contributors_label",
  "tab_history_name_label",
  "tab_history_dates_label",
  "tab_history_status_label",
  "tab_history_copy_label",
];

const STATUS_MAP: StatusMapType = {
  '-2': 'aborted',
  '-1': 'waiting',
  '0': 'uploaded',
  '1': 'downloaded',
  '2': 'expired'
};

const COLLAB_STATUS_MAP: CollabStatusMapType = {
  '-2': 'aborted',
  '-1': 'wip',
  '0': 'validated',
  '1': 'downloaded',
  '2': 'expired'
};

// Components
interface StatusBadgeProps {
  status: number;
  isCollab: boolean;
  downloaded?: number;
}

const getStatusConfig = (status: number, isCollab: boolean): string => {
  const currentStatus = isCollab 
    ? (COLLAB_STATUS_MAP[status.toString()] || 'expired')
    : (STATUS_MAP[status.toString()] || 'expired');

  switch(currentStatus) {
    case 'uploaded':
    case 'validated':
      return 'bg-teal-100 text-teal-800 dark:bg-teal-500/10 dark:text-teal-500';
    case 'downloaded':
      return 'bg-blue-100 text-blue-800 dark:bg-emerald-500/10 dark:text-emerald-500';
    case 'expired':
    case 'aborted':
      return 'bg-red-100 text-red-800 dark:bg-red-500/10 dark:text-red-500';
    case 'waiting':
    case 'wip':
      return 'bg-orange-100 text-orange-800 dark:bg-orange-500/10 dark:text-orange-500';
    default:
      return 'bg-gray-100 text-gray-800 dark:bg-gray-500/10 dark:text-gray-500';
  }
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, isCollab, downloaded }) => {
  const statusMap = isCollab ? COLLAB_STATUS_MAP : STATUS_MAP;
  const statusValue = statusMap[status.toString()] || 'expired';
  
  return (
    <span className={`inline-flex items-center gap-1.5 py-1 px-3 rounded-full text-sm font-medium ${getStatusConfig(status, isCollab)}`}>
      {i18n._(`status_${statusValue}_label`)}
    </span>
  );
};


interface TableLineProps {
  item: History;
  onSelect: (isSelected: boolean, item: History) => void;
  lineNumber: number;
}

const TableLine: React.FC<TableLineProps> = ({ item, onSelect, lineNumber }) => {
  const [showToast, setShowToast] = useState(false);
  const [transferDetails, setTransferDetails] = useState<Record<string, any>>({});
  const [transferDetailsLoading, setTransferDetailsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    transfer_name: initialTransferName,
    transfer_id,
    date_transfer,
    expiration_transfer,
    transfer_status: initialTransferStatus,
    isSelected,
    transferLocked,
    recipients: initialRecipients,
    for_team,
    downloaded,
    nb_files,
    transfer_type,
    transfer_sender,
    transfer_size,
  } = item;

  const isFromOutlook = useMemo(() => item.transfer_provider === "FV_OUTLOOK", [item.transfer_provider]);
  const isCollab = useMemo(() => item.transfer_provider === "FV_STUDIO", [item.transfer_provider]);

  let transfer_name = initialTransferName;
  try {
    transfer_name = unescape(transfer_name);
  } catch (e) {}

  let recipients = initialRecipients;
  if (recipients) {
    try {
      const parsedRecipients = typeof recipients === 'string' ? JSON.parse(recipients) : recipients;
      recipients = Array.isArray(parsedRecipients) ? parsedRecipients.join(", ") : recipients;
    } catch (e) {
      console.warn('Error parsing recipients:', e);
    }
  }

  const now = Date.now();
  const dT = new Date(date_transfer);
  const eT = expiration_transfer ? new Date(expiration_transfer) : null;

  let transfer_status = initialTransferStatus;
  transfer_status = eT && eT.getTime() < now ? 2 : transfer_status;

  useEffect(() => {
    const loadTransferDetails = async () => {
      if (!transferDetails[transfer_id]) {
        try {
          setTransferDetailsLoading(true);
          const result = await transferApi.getTransfer(transfer_id, undefined, undefined, true);
          console.log('Transfer details received:', result);
          console.log('Result consumption:', result.consumption);
          setTransferDetails(prev => ({
            ...prev,
            [transfer_id]: result
          }));
        } catch (e) {
          console.error('Error fetching transfer details:', e);
        } finally {
          setTransferDetailsLoading(false);
        }
      }
    };

    loadTransferDetails();
  }, [transfer_id, transferDetails]);

  const formatDate = useCallback((date: Date) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    return `${d < 10 ? "0" + d : d}/${m + 1 < 10 ? "0" + (m + 1) : m + 1}/${y}`;
  }, []);

  const formatTime = useCallback((date: Date) => {
    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
  }, []);

  const isTransferDisabled = (transfer_status === -1 && !isCollab) || transfer_status === -2;

  const getTransferLink = useCallback(() => {
    let path = "u";
    const fNb = Number(nb_files);
    if (eT || fNb) path = "d";
    if (transfer_type === 3 && !fNb) path = "deposit";
    if (isCollab && transfer_status <= 1) path = "collab";
    return `${config.ihmUrl}/${path}/${transfer_id}`;
  }, [transfer_id, nb_files, eT, transfer_type, isCollab, transfer_status]);

  return (
    <tr className={`hover:bg-gray-50 dark:hover:bg-neutral-800 ${isTransferDisabled ? "opacity-50" : ""}`}>
      <td>
        <div className="ps-6 py-4">
          <input
            type="checkbox"
            disabled={transfer_status > 2}
            checked={isSelected && transfer_status < 3}
            onChange={() => onSelect(!isSelected, item)}
            className="shrink-0 w-5 h-5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
          />
        </div>
      </td>
      
      <td>
        <div className="px-6 py-4">
          <div className="flex items-center gap-x-2">
            {transfer_type === 3 && (
              <File className="h-5 w-5 text-gray-400 dark:text-neutral-500" strokeWidth={1.2} />
            )}
            <TooltipComponent content={transfer_sender}>
              <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-300">
                  {transfer_sender?.split('@')[0]?.split(/[._-]/)?.map(part => part[0]?.toUpperCase()).slice(0, 2).join('')}
                </span>
              </div>
            </TooltipComponent>
          </div>
        </div>
      </td>

      <td>
        <div className="px-6 py-4">
          {recipients ? (
            typeof recipients === "string" && (() => {
              const recipientsList = recipients.split(',').map(r => r.trim());
              const visibleRecipients = recipientsList.slice(0, 3); // Réduit à 3 pour économiser de l'espace
              const hiddenCount = recipientsList.length - 3;
              
              return (
                <div className="flex items-center">
                  {/* Afficher les 3 premiers destinataires avec un décalage négatif pour les superposer */}
                  <div className="flex -space-x-3">
                    {visibleRecipients.map((recipient, index) => (
                      <TooltipComponent key={index} content={recipient}>
                        <div className="w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center border-2 border-white dark:border-neutral-900 relative z-[5]" style={{ zIndex: 5 - index }}>
                          <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                            {recipient.split('@')[0]?.split(/[._-]/)?.map(part => part[0]?.toUpperCase()).slice(0, 2).join('')}
                          </span>
                        </div>
                      </TooltipComponent>
                    ))}
                  </div>
                  
                  {/* Afficher le "+XX" si nécessaire */}
                  {hiddenCount > 0 && (
                    <TooltipComponent content={
                      <div className="p-1">
                        <div className="font-medium mb-1">{i18n._("all_recipients")}:</div>
                        <ul className="list-disc pl-4 space-y-1">
                          {recipientsList.map((recipient, index) => (
                            <li key={index}>{recipient}</li>
                          ))}
                        </ul>
                      </div>
                    }>
                      <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center border-2 border-white dark:border-neutral-900 -ml-1 relative z-0">
                        <span className="text-sm font-medium text-blue-600 dark:text-blue-300">
                          +{hiddenCount}
                        </span>
                      </div>
                    </TooltipComponent>
                  )}
                </div>
              );
            })()
          ) : (
            <span className="text-sm text-gray-400 dark:text-neutral-500">-</span>
          )}
        </div>
      </td>

      <td>
        <div className="px-6 py-4">
          <div className="flex items-center justify-between gap-x-2">
            <a
              href={getTransferLink()}
              target="_blank"
              rel="noreferrer"
              className="group flex items-center gap-3 text-sm text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400"
            >
              <div className="relative group inline-flex items-center justify-center w-12 h-12 flex-shrink-0">
                {downloaded && downloaded > 0 ? (
                  <TooltipComponent content={`${i18n._("status_downloaded_label")} (${downloaded})`}>
                    <div className="relative">
                      <File className="w-12 h-12 text-blue-500 dark:text-blue-400 transition-colors group-hover:text-blue-600 dark:group-hover:text-blue-300" strokeWidth={1.2} />
                      <span className="absolute text-[14px] font-bold text-blue-600 dark:text-blue-300 top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        {downloaded}
                      </span>
                    </div>
                  </TooltipComponent>
                ) : (
                  <File className="w-12 h-12 text-blue-500 dark:text-blue-400 transition-colors group-hover:text-blue-600 dark:group-hover:text-blue-300" strokeWidth={1.2} />
                )}
              </div>
              <div className="flex flex-col min-w-0">
                <div className="flex items-center gap-2 flex-wrap">
                  <span className="text-base truncate">
                    {transfer_type === 3 ? `D - ${transfer_id}` : wrapStr(transfer_name, maxLength)}
                  </span>
                  <div className="flex items-center gap-1 flex-shrink-0">
                    {isFromOutlook && <Mail className="h-5 w-5" />}
                    {for_team && <FolderLock className="h-5 w-5" />}
                  </div>
                </div>
                {transfer_size !== undefined && (
                  <div className="flex items-center gap-2 text-md font-medium text-gray-500 dark:text-neutral-400 flex-wrap">
                    <span>{convertSize(transfer_size)}</span>
                    {nb_files && (
                      <div className="flex items-center gap-1">
                        <span>-</span>
                        <span>{nb_files} {i18n._("files_count", [nb_files])}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </a>

            {transferLocked && (
              <Lock className="h-5 w-5 text-gray-400 dark:text-neutral-500 flex-shrink-0" />
            )}
          </div>
        </div>
      </td>

      <td>
        <div className="px-6 py-4">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Calendar className="h-5 w-5 text-gray-400 dark:text-neutral-500 flex-shrink-0" />
              <span className="text-sm text-gray-600 dark:text-neutral-400">
                {formatDate(dT)} - {formatTime(dT)}
              </span>
            </div>
            {eT && (
              <div className="flex items-center gap-2">
                <Clock className="h-5 w-5 text-gray-400 dark:text-neutral-500 flex-shrink-0" />
                <span className="text-sm text-gray-600 dark:text-neutral-400">
                  {formatDate(eT)} - {formatTime(eT)}
                </span>
              </div>
            )}
          </div>
        </div>
      </td>

      <td>
        <div className="px-6 py-4">
          <StatusBadge
            status={transfer_status}
            isCollab={isCollab}
            downloaded={downloaded}
          />
        </div>
      </td>

      <td>
        <div className="px-6 py-4 flex gap-3">
          <button
            onClick={() => {
              navigator.clipboard.writeText(getTransferLink());
              setShowToast(true);
            }}
            className="p-2.5 hover:bg-gray-100 rounded-full dark:hover:bg-neutral-800"
            aria-label="Copier le lien"
          >
            <Copy className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
          </button>
          <Toast
            type="success"
            message={i18n._("link_copied")}
            isVisible={showToast}
            duration={3000}
            onClose={() => setShowToast(false)}
          />
          
          <button
            onClick={() => {
              if (!transferDetails[transfer_id]) {
                setTransferDetailsLoading(true);
              }
              setIsModalOpen(true);
            }}
            className={`p-2.5 hover:bg-gray-100 rounded-full dark:hover:bg-neutral-800 transition-all duration-200 ${
              transferDetailsLoading ? 'custom-pulse bg-gray-100 dark:bg-neutral-800' : ''
            }`}
            aria-label="Voir les détails"
          >
            {transferDetails[transfer_id]?.protectionDetails?.transfer_password ||
             transferDetails[transfer_id]?.transfer_password ? (
              <Lock className="h-5 w-5 text-yellow-500 dark:text-yellow-400" />
            ) : (
              <Info className={`h-5 w-5 ${
                transferDetailsLoading
                  ? 'text-blue-500 dark:text-blue-400'
                  : 'text-gray-400 dark:text-neutral-500'
              }`} />
            )}
          </button>

          <FVTransferDetailsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            transfer={transferDetails[transfer_id]}
            isLoading={transferDetailsLoading}
          />
        </div>
      </td>
    </tr>
  );
};

const HistoryComponent = React.forwardRef<HistoryRef, Props>((props, ref) => {
  const { type, user, searchQuery, onSearch, onDataFiltered, defaultPeriod } = props;
  const isCollab = useMemo(() => type === "collab", [type]);
  
  // États pour les données
  const [allHistory, setAllHistory] = useState<History[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numSelected, setNumSelected] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [deleteToastMessage, setDeleteToastMessage] = useState("");

  // États pour les filtres
  const [statusFilter, setStatusFilter] = useState<'active' | 'all'>('active');
  const [periodFilter, setPeriodFilter] = useState<'month' | 'year' | 'all'>(defaultPeriod || 'month');
  const [cachedData, setCachedData] = useState<{
    [key: string]: History[];
  }>({});

  // Fonction pour filtrer les données selon les critères
  const filterData = useCallback((data: History[], query: string) => {
    // Filtre de période
    let filtered = data;
    
    if (periodFilter !== 'all') {
      const now = new Date();
      const startDate = periodFilter === 'month'
        ? new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000))  // 30 jours avant aujourd'hui
        : new Date(now.setFullYear(now.getFullYear() - 1));

      filtered = data.filter(item =>
        new Date(item.date_transfer) >= startDate
      );
    }

    // Filtre de statut avec gestion spéciale pour les fichiers studio
    if (statusFilter === 'active') {
      filtered = filtered.filter(item => {
        if (item.transfer_provider === "FV_STUDIO") {
          // Pour les fichiers studio, on considère les statuts -1 (wip) et 0 (validated) comme actifs
          return item.transfer_status >= -1 && item.transfer_status <= 0;
        }
        // Pour les autres types, on garde le comportement standard
        return item.transfer_status === 0 || item.transfer_status === 1;
      });
    }

    // Filtre de type
    filtered = filtered.filter(line => {
      switch(type) {
        case 'collab':
          return line.transfer_provider === "FV_STUDIO";
        case 'upload':
          return (line.transfer_type === 0) && line.transfer_provider !== "FV_STUDIO";
        case 'receive':
          return [1, 3].includes(line.transfer_type);
        default:
          return false;
      }
    });

    // Filtre de recherche
    if (query) {
      const queryLower = query.toLowerCase();
      filtered = filtered.filter(line => {
        const transferDate = line.date_transfer ? new Date(line.date_transfer).toLocaleString() : '';
        const recipients = typeof line.recipients === 'string' ? line.recipients : JSON.stringify(line.recipients || '');
        
        return (
          (line.transfer_name?.toLowerCase().includes(queryLower)) ||
          (recipients.toLowerCase().includes(queryLower)) ||
          (line.transfer_sender?.toLowerCase().includes(queryLower)) ||
          (transferDate.toLowerCase().includes(queryLower)) ||
          (line.transfer_id?.toLowerCase().includes(queryLower))
        );
      });
    }

    return filtered;
  }, [type, statusFilter, periodFilter]);

  const fetchHistoryData = useCallback(async (searchQuery: string) => {
    if (!user?.id) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Vérifier le cache
      const cacheKey = `${type}-${user.id}`;
      let historyData: History[];

      if (cachedData[cacheKey]) {
        historyData = cachedData[cacheKey];
      } else {
        historyData = await transferApi.getAllUserTransfer(user.id);
        if (!Array.isArray(historyData)) {
          throw new Error('Invalid history data format');
        }
        
        // Mettre en cache les données brutes
        setCachedData(prev => ({
          ...prev,
          [cacheKey]: historyData
        }));
      }

      // Traiter les données
      historyData = historyData.map(line => ({
        ...line,
        isSelected: false
      }));

      setAllHistory(historyData);
      
      // Appliquer les filtres
      const filteredData = filterData(historyData, searchQuery);
      setFilteredHistory(filteredData);
      
      // Reset pagination if needed
      if (page * rowsPerPage >= filteredData.length) {
        setPage(0);
      }
    } catch (error) {
      console.error(`Error fetching ${type} history:`, error);
      setError(i18n._('error_loading_history'));
      setFilteredHistory([]);
    } finally {
      setIsLoading(false);
      setNumSelected(0);
    }
  }, [user?.id, type, page, rowsPerPage, filterData, cachedData]);

  // Effet pour charger les données initiales
  useEffect(() => {
    fetchHistoryData(searchQuery);
  }, [fetchHistoryData, searchQuery]);

  // Effet pour mettre à jour les données filtrées quand les filtres changent
  useEffect(() => {
    if (allHistory.length > 0) {
      const filteredData = filterData(allHistory, searchQuery);
      setFilteredHistory(filteredData);
      onDataFiltered?.(filteredData);
      if (page * rowsPerPage >= filteredData.length) {
        setPage(0);
      }
    }
  }, [allHistory, filterData, searchQuery, statusFilter, periodFilter, page, rowsPerPage]);

  const handleSelect = useCallback((isSelected: boolean, item: History) => {
    setFilteredHistory(prev => 
      prev.map(line => 
        line.transfer_id === item.transfer_id 
          ? { ...line, isSelected } 
          : line
      )
    );
    setNumSelected(prev => isSelected ? prev + 1 : prev - 1);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!window.confirm(i18n._('confirm_delete_transfers'))) return;

    const selectedIds = filteredHistory
      .filter(item => item.isSelected)
      .map(({ transfer_id }) => transfer_id);

    if (selectedIds.length === 0) return;

    setIsLoading(true);
    try {
      await transferApi.deleteManyTransfer(selectedIds);
      // Afficher le Toast de succès
      setDeleteToastMessage(`${selectedIds.length} transfert(s) supprimé(s) avec succès`);
      setShowDeleteToast(true);
      
      // Invalider le cache pour forcer un rechargement complet
      setCachedData({});
      
      // Rafraîchir les données
      await fetchHistoryData(searchQuery);
    } catch (error) {
      console.error('Error deleting transfers:', error);
      setError(i18n._('error_deleting_transfers'));
    } finally {
      setIsLoading(false);
    }
  }, [filteredHistory, fetchHistoryData, searchQuery]);

  // Exposer les méthodes via ref
  React.useImperativeHandle(ref, () => ({
    setPeriodFilter: (period: 'month' | 'year' | 'all') => {
      setPeriodFilter(period);
    }
  }));

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((value: number) => {
    setRowsPerPage(value);
    setPage(0);
  }, []);

  const activeHistory = useMemo(() =>
    filteredHistory.filter(el => {
      if (el.transfer_provider === "FV_STUDIO") {
        // Pour les fichiers studio, considérer actifs uniquement les statuts -1 (wip) et 0 (validated)
        return el.transfer_status >= -1 && el.transfer_status <= 0;
      }
      // Pour les autres fichiers, garder tous les statuts < 3
      return el.transfer_status < 3;
    }),
    [filteredHistory]
  );

  const handleSelectAll = useCallback(() => {
    const shouldSelect = numSelected < activeHistory.length;
    
    setFilteredHistory(prev =>
      prev.map(line => ({
        ...line,
        isSelected: shouldSelect && line.transfer_status < 3
      }))
    );
    
    setNumSelected(shouldSelect ? activeHistory.length : 0);
  }, [activeHistory.length, numSelected]);

  const rowsPerPageOptions = [10, 25, 50, 100, 250];
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <div className="w-full py-10 px-0 sm:px-4 mx-auto relative">
      <FolderLoader isLoading={isLoading} />
      <Toast
        type="success"
        message={deleteToastMessage}
        isVisible={showDeleteToast}
        duration={3000}
        onClose={() => setShowDeleteToast(false)}
      />
      <div className="flex flex-col">
        <div className="p-1.5 min-w-full inline-block align-middle overflow-visible">
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-visible dark:bg-neutral-900 dark:border-neutral-700">
            {/* Header Section avec filtres */}
            <div className="px-6 py-4 border-b border-gray-200 dark:border-neutral-700">
              <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl sm:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                    {type === "upload" 
                      ? i18n._("upload_history_title")
                      : type === "receive" 
                        ? i18n._("receive_history_title")
                        : i18n._("collab_history_title")}
                  </h2>
                  {numSelected > 0 && (
                    <button
                      onClick={handleDelete}
                      className="py-2.5 px-4 inline-flex items-center gap-x-2 text-base font-medium rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      aria-label={i18n._("delete_selected_transfers")}
                    >
                      <Trash2 className="h-5 w-5" />
                      {i18n._("transfer_deletion_label", [numSelected])}
                    </button>
                  )}
                </div>

                {error && (
                  <p className="text-sm text-red-600 dark:text-red-500">
                    {error}
                  </p>
                )}

                {/* Barre de filtres - Optimisée pour tactile */}
                <div className="flex flex-wrap gap-2 items-center">
                  <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-3 w-[48%] sm:w-auto">
                    <div className="flex items-center gap-1">
                      <Filter className="h-4 w-4 text-gray-400" />
                      <span className="hidden sm:inline text-sm text-gray-400 dark:text-neutral-400 font-medium">
                      {i18n._("status_label")}
                      </span>
                    </div>
                    <select
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value as 'active' | 'all')}
                      className="py-1.5 sm:py-2 px-2 sm:px-3 pe-6 sm:pe-8 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 w-full sm:w-auto"
                      aria-label={i18n._("status_filter_aria")}
                    >
                      <option value="active">{i18n._("active_only")}</option>
                      <option value="all">{i18n._("all_statuses")}</option>
                    </select>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-3 w-[48%] sm:w-auto">
                    <div className="flex items-center gap-1">
                      <CalendarIcon className="h-4 w-4 text-gray-400" />
                      <span className="hidden sm:inline text-sm text-gray-400 dark:text-neutral-400 font-medium">
                        {i18n._("period_label")}
                      </span>
                    </div>
                    <select
                      value={periodFilter}
                      onChange={(e) => setPeriodFilter(e.target.value as 'month' | 'year' | 'all')}
                      className="py-1.5 sm:py-2 px-2 sm:px-3 pe-6 sm:pe-8 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 w-full sm:w-auto"
                      aria-label={i18n._("period_filter_aria")}
                    >
                      <option value="month">{i18n._("current_month")}</option>
                      <option value="year">{i18n._("rolling_year")}</option>
                      <option value="all">{i18n._("all_history")}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Table Section - Responsive Design avec indication de défilement */}
            <div className="overflow-x-auto overflow-y-visible relative">
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800/50 text-white px-2 py-1 rounded-l-md text-xs font-medium hidden md:block md:opacity-50 md:hover:opacity-100 transition-opacity">
                ← →
              </div>
              {/* Vue tableau pour écrans moyens et grands */}
              <div className="hidden md:block">
                <table className="w-full divide-y divide-gray-200 dark:divide-neutral-700">
                  <thead className="bg-gray-50 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="ps-6 py-3 text-start">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            ref={checkboxRef}
                            checked={activeHistory.length > 0 && numSelected === activeHistory.length}
                            onChange={handleSelectAll}
                          />
                        </div>
                      </th>
                      {(isCollab ? historyCollabTab : historyTab).map((tab: string, k: number) => {
                        // Skip recipients column header if no items have recipients
                        if ((tab === "tab_history_recipients_label" || tab === "tab_history_contributors_label") &&
                            !filteredHistory.some(item => item.recipients)) {
                          return null;
                        }
                        return (
                          <th
                            key={k}
                            scope="col"
                            className="px-6 py-3 text-start"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-sm font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {i18n._(tab)}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {isLoading ? (
                      <tr>
                        <td colSpan={8} className="px-6 py-8 text-center">
                          <div className="flex flex-col items-center justify-center">
                            <div className="animate-spin h-8 w-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" />
                            <p className="mt-4 text-gray-600 dark:text-gray-400">{i18n._("loading_label")}</p>
                          </div>
                        </td>
                      </tr>
                    ) : !filteredHistory.length ? (
                      <tr>
                        <td colSpan={8} className="px-6 py-8 text-center">
                          <p className="text-gray-600 dark:text-gray-400">{i18n._("no_transfer_label")}</p>
                        </td>
                      </tr>
                    ) : (
                      filteredHistory
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((item: History, k: number) => (
                        <TableLine
                          key={item.transfer_id}
                          item={item}
                          lineNumber={k}
                          onSelect={handleSelect}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {/* Vue carte pour mobile */}
              <div className="md:hidden space-y-4">
                {isLoading ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="animate-spin h-8 w-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" />
                    <p className="mt-4 text-gray-600 dark:text-gray-400">{i18n._("loading_label")}</p>
                  </div>
                ) : !filteredHistory.length ? (
                  <div className="text-center py-8">
                    <p className="text-gray-600 dark:text-gray-400">{i18n._("no_transfer_label")}</p>
                  </div>
                ) : (
                  filteredHistory
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item: History) => {
                    const {
                      transfer_name: initialTransferName,
                      transfer_id,
                      date_transfer,
                      expiration_transfer,
                      transfer_status: initialTransferStatus,
                      isSelected,
                      transferLocked,
                      recipients,
                      for_team,
                      downloaded,
                      nb_files,
                      transfer_type,
                      transfer_sender,
                      transfer_size,
                      transfer_provider
                    } = item;

                    const isCollab = transfer_provider === "FV_STUDIO";
                    const isFromOutlook = transfer_provider === "FV_OUTLOOK";
                    
                    let transfer_name = initialTransferName;
                    try {
                      transfer_name = unescape(transfer_name);
                    } catch (e) {}
                    
                    const now = Date.now();
                    const dT = new Date(date_transfer);
                    const eT = expiration_transfer ? new Date(expiration_transfer) : null;
                    
                    let transfer_status = initialTransferStatus;
                    transfer_status = eT && eT.getTime() < now ? 2 : transfer_status;
                    
                    const formatDate = (date: Date) => {
                      const d = date.getDate();
                      const m = date.getMonth();
                      const y = date.getFullYear();
                      return `${d < 10 ? "0" + d : d}/${m + 1 < 10 ? "0" + (m + 1) : m + 1}/${y}`;
                    };
                    
                    const formatTime = (date: Date) => {
                      const h = date.getHours();
                      const m = date.getMinutes();
                      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
                    };
                    
                    const getTransferLink = () => {
                      let path = "u";
                      const fNb = Number(nb_files);
                      if (eT || fNb) path = "d";
                      if (transfer_type === 3 && !fNb) path = "deposit";
                      if (isCollab && transfer_status <= 1) path = "collab";
                      return `${config.ihmUrl}/${path}/${transfer_id}`;
                    };

                    return (
                      <div key={transfer_id} className="bg-white dark:bg-neutral-800 border border-gray-200 dark:border-neutral-700 rounded-xl shadow-sm overflow-hidden">
                        <div className="p-4">
                          <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-3">
                              <input
                                type="checkbox"
                                disabled={transfer_status > 2}
                                checked={isSelected && transfer_status < 3}
                                onChange={() => handleSelect(!isSelected, item)}
                                className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              />
                              <div className="flex items-center gap-2">
                                <div className="w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                                  <span className="text-sm font-medium text-blue-600 dark:text-blue-300">
                                    {transfer_sender?.split('@')[0]?.split(/[._-]/)?.map(part => part[0]?.toUpperCase()).slice(0, 2).join('')}
                                  </span>
                                </div>
                                <StatusBadge
                                  status={transfer_status}
                                  isCollab={isCollab}
                                  downloaded={downloaded}
                                />
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(getTransferLink());
                                  // Afficher un toast
                                  const toastElement = document.createElement('div');
                                  toastElement.className = 'fixed bottom-20 right-4 bg-green-50 dark:bg-green-900/40 text-emerald-700 dark:text-emerald-100 px-6 py-3 rounded-lg shadow-lg flex items-center space-x-2 z-50 border-l-4 border-green-500 transform transition-all duration-300 ease-in-out translate-x-0 opacity-100';
                                  toastElement.innerHTML = `
                                    <span class="text-emerald-500 dark:text-emerald-400">✓</span>
                                    <span>${i18n._("link_copied")}</span>
                                  `;
                                  document.body.appendChild(toastElement);
                                  
                                  // Supprimer le toast après 3 secondes
                                  setTimeout(() => {
                                    toastElement.classList.add('translate-x-full', 'opacity-0');
                                    setTimeout(() => {
                                      document.body.removeChild(toastElement);
                                    }, 300);
                                  }, 3000);
                                }}
                                className="p-2 hover:bg-gray-100 rounded-full dark:hover:bg-neutral-800"
                              >
                                <Copy className="h-5 w-5 text-gray-400 dark:text-neutral-500" />
                              </button>
                            </div>
                          </div>
                          
                          <a
                            href={getTransferLink()}
                            target="_blank"
                            rel="noreferrer"
                            className="group flex items-start gap-3 mb-3"
                          >
                            <div className="relative group inline-flex items-center justify-center w-10 h-10 flex-shrink-0">
                              {downloaded && downloaded > 0 ? (
                                <div className="relative">
                                  <File className="w-10 h-10 text-blue-500 dark:text-blue-400 transition-colors group-hover:text-blue-600 dark:group-hover:text-blue-300" strokeWidth={1.2} />
                                  <span className="absolute text-[13px] font-bold text-blue-600 dark:text-blue-300 top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    {downloaded}
                                  </span>
                                </div>
                              ) : (
                                <File className="w-10 h-10 text-blue-500 dark:text-blue-400 transition-colors group-hover:text-blue-600 dark:group-hover:text-blue-300" strokeWidth={1.2} />
                              )}
                            </div>
                            
                            <div className="flex-1 min-w-0">
                              <div className="flex items-center gap-2 mb-1">
                                <span className="text-base font-medium text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400 truncate">
                                  {transfer_type === 3 ? `D - ${transfer_id}` : wrapStr(transfer_name, maxLength)}
                                </span>
                                {isFromOutlook && <Mail className="h-4 w-4 flex-shrink-0" />}
                                {for_team && <FolderLock className="h-4 w-4 flex-shrink-0" />}
                                {transferLocked && <Lock className="h-4 w-4 text-gray-400 dark:text-neutral-500 flex-shrink-0" />}
                              </div>
                              
                              {transfer_size !== undefined && (
                                <div className="flex items-center gap-2 text-sm font-medium text-gray-500 dark:text-neutral-400">
                                  <span>{convertSize(transfer_size)}</span>
                                  {nb_files && (
                                    <div className="flex items-center gap-1">
                                      <span>-</span>
                                      <span>{nb_files} {i18n._("files_count", [nb_files])}</span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </a>
                          
                          <div className="grid grid-cols-2 gap-2 text-sm">
                            <div className="flex items-center gap-2">
                              <Calendar className="h-4 w-4 text-gray-400 dark:text-neutral-500 flex-shrink-0" />
                              <span className="text-gray-600 dark:text-neutral-400 truncate">
                                {formatDate(dT)} - {formatTime(dT)}
                              </span>
                            </div>
                            
                            {eT && (
                              <div className="flex items-center gap-2">
                                <Clock className="h-4 w-4 text-gray-400 dark:text-neutral-500 flex-shrink-0" />
                                <span className="text-gray-600 dark:text-neutral-400 truncate">
                                  {formatDate(eT)} - {formatTime(eT)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            {/* Footer Section - Optimisé pour tactile */}
          {filteredHistory.length > 0 && (
            <div className="px-6 py-5 grid gap-4 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
              <div>
                <div className="inline-flex items-center gap-x-3">
                  <span className="text-base text-gray-600 dark:text-neutral-400">
                    {i18n._("rows_per_page_label")}
                  </span>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => handleChangeRowsPerPage(parseInt(e.target.value))}
                    className="py-2.5 px-4 pe-10 block text-base border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                  >
                    {rowsPerPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                <span className="text-base sm:text-sm text-gray-400 dark:text-neutral-400 text-center sm:text-left">
                  {i18n._("pagination_info", [
                    page * rowsPerPage + 1,
                    Math.min((page + 1) * rowsPerPage, filteredHistory.length),
                    filteredHistory.length
                  ])}
                </span>
                
                {/* Boutons de pagination - visibles uniquement en mobile et s'il y a plus d'une page */}
                <div className="flex gap-x-3 justify-center sm:hidden">
                  {page > 0 && (
                    <button
                      type="button"
                      onClick={() => handleChangePage(page - 1)}
                      className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-500 shadow-sm hover:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700"
                      aria-label={i18n._("previous_page")}
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </button>
                  )}

                  {(page + 1) * rowsPerPage < filteredHistory.length && (
                    <button
                      type="button"
                      onClick={() => handleChangePage(page + 1)}
                      className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-500 shadow-sm hover:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700"
                      aria-label={i18n._("next_page")}
                    >
                      <ChevronRight className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  );
});

const mapStateToProps = (state: any) => ({
user: state.user.user,
});

export default connect(mapStateToProps)(HistoryComponent);