import {
  SET_INFOS,
  LOCK_TRANSFER,
  UPDATE_PROGRESS,
  PURGE,
  SET_MINIMIZED,
  RESTORE_TRANSFER,
  RESET_TRANSFER_PROGRESS,
  RESET_TRANSFER_STATE,
  SET_PHASE
} from "../Actions/transferAction";
import { AnyAction } from "redux";
import { UploadPhase } from "../../Constants/UploadPhases";
import { TransferState, Transfer, TransferAction, ChunkProgress, FileProgress } from '../types';

// Nous utilisons les interfaces importées au lieu de les redéclarer localement

const initialState: TransferState = {
  transfers: {},
  activeTransferId: null,
  currentPhase: UploadPhase.INITIAL // Initialisation avec la phase initiale
};

const initialTransferState: Transfer = {
  isLocked: false,
  isUploadFinished: false,
  isMinimized: false,
  loaded: 0,
  totalSize: 0,
  currentFileIndex: 0,
  filesProgress: {},
  progress: {
    current: {
      timestamp: Date.now(),
      loaded: 0,
      instantSpeed: 0,
      avgSpeed: 0,
      chunkSize: 0,
      chunksUploaded: 0,
      totalChunks: 0
    },
    last: {
      timestamp: Date.now(),
      loaded: 0
    }
  },
  startTime: 0,
  totalTime: 0,
  consumption: {
    size: 0,
    gCO2: 0,
    kWh: 0,
    kms: 0
  },
  purging: false,
  backgroundActive: false,
  lastProgressUpdate: Date.now()
};

export default function transferReducer(
  state = initialState,
  action: AnyAction
): TransferState {
  if (!state) return initialState;

  switch (action.type) {
    case SET_PHASE: {
      // Gestion de la nouvelle action SET_PHASE
      return {
        ...state,
        currentPhase: action.payload
      };
    }

    case SET_INFOS: {
      const tempId = action.value?.transfer_id || action.transferId || state.activeTransferId;
      if (!tempId) {
        return state;
      }

      // Si c'est un nouveau transfert avec seulement l'ID, on l'initialise
      if (Object.keys(action.value).length === 1 && action.value.transfer_id) {
        return {
          ...state,
          activeTransferId: tempId,
          transfers: {
            ...state.transfers,
            [tempId]: {
              ...initialTransferState,
              transfer_id: tempId,
              archived: false,
              lastProgressUpdate: Date.now()
            }
          }
        };
      }

      // Si on marque le transfert comme terminé, assurons-nous qu'il n'est pas archivé
      if (action.value.isUploadFinished && !action.value.archived) {
        action.value = {
          ...action.value,
          archived: false
        };
      }
      const existingTransfer = state.transfers[tempId];
      const isRouteChange = action.value.path !== undefined;
      const isValidPath = !isRouteChange || action.value.path === '/' || action.value.path === '/offers';
      const isActiveTransfer = existingTransfer?.isLocked && !existingTransfer?.purging;
      const isBackgroundActive = existingTransfer?.backgroundActive || action.value.backgroundActive;

      // Continuer le traitement même sur les chemins non valides si le transfert est actif en arrière-plan
      if (!isValidPath && !isActiveTransfer && !isBackgroundActive) {
        return state;
      }

      const currentTransfer = state.transfers[tempId] || { ...initialTransferState };
      const now = Date.now();
      
      const updatedTransfer = {
        ...currentTransfer,
        ...action.value,
        transfer_id: tempId,
        isLocked: action.value.isLocked !== undefined ? action.value.isLocked : currentTransfer.isLocked,
        isUploadFinished: action.value.isUploadFinished !== undefined ? action.value.isUploadFinished : currentTransfer.isUploadFinished,
        loaded: action.value.loaded !== undefined ? action.value.loaded : currentTransfer.loaded,
        totalSize: action.value.totalSize !== undefined ? action.value.totalSize : currentTransfer.totalSize,
        currentFileIndex: action.value.currentFileIndex ?? currentTransfer.currentFileIndex,
        startTime: action.value.startTime ?? currentTransfer.startTime,
        totalTime: action.value.totalTime ?? currentTransfer.totalTime,
        name: action.value.transfer_name || action.value.name || currentTransfer.name,
        backgroundActive: isBackgroundActive,
        lastProgressUpdate: now,
        filesProgress: action.value.filesProgress
          ? {
              ...currentTransfer.filesProgress,
              ...Object.entries(action.value.filesProgress).reduce(
                (acc, [fileId, progress]: [string, any]) => {
                  const existingProgress = currentTransfer.filesProgress[fileId];
                  return {
                    ...acc,
                    [fileId]: {
                      chunks: {
                        ...(existingProgress?.chunks || {}),
                        ...(progress.chunks || {})
                      },
                      totalLoaded:
                        progress.totalLoaded ||
                        existingProgress?.totalLoaded ||
                        0,
                      fileSize:
                        progress.fileSize ||
                        existingProgress?.fileSize ||
                        action.value.totalSize ||
                        currentTransfer.totalSize ||
                        0
                    }
                  };
                },
                {}
              )
            }
          : currentTransfer.filesProgress,
        progress: {
          ...currentTransfer.progress,
          ...(action.value.progress || {}),
          current: {
            ...currentTransfer.progress.current,
            ...(action.value.progress?.current || {}),
            timestamp: action.value.progress?.current?.timestamp || now,
            loaded: action.value.loaded !== undefined
              ? action.value.loaded
              : currentTransfer.progress.current.loaded,
            instantSpeed: action.value.progress?.current?.instantSpeed !== undefined
              ? action.value.progress.current.instantSpeed
              : currentTransfer.progress.current.instantSpeed,
            avgSpeed: action.value.progress?.current?.avgSpeed !== undefined
              ? action.value.progress.current.avgSpeed
              : currentTransfer.progress.current.avgSpeed
          },
          last: {
            ...currentTransfer.progress.last,
            ...(action.value.progress?.last || {}),
            timestamp: action.value.progress?.last?.timestamp ||
              currentTransfer.progress.current.timestamp,
            loaded: action.value.progress?.last?.loaded ||
              currentTransfer.progress.current.loaded
          }
        },
        consumption: {
          ...currentTransfer.consumption,
          ...(action.value.consumption
            ? {
                kWh: Math.max(
                  currentTransfer.consumption.kWh || 0,
                  action.value.consumption.kWh || 0
                ),
                kms: Math.max(
                  currentTransfer.consumption.kms || 0,
                  action.value.consumption.kms || 0
                ),
                size: Math.max(
                  currentTransfer.consumption.size || 0,
                  action.value.consumption.size || 0
                ),
                gCO2: Math.max(
                  currentTransfer.consumption.gCO2 || 0,
                  action.value.consumption.gCO2 || 0
                )
              }
            : {})
        }
      };

      // Garder l'ID existant si c'est le même transfert
      if (action.value && action.value.transfer_id && action.value.transfer_id !== tempId) {
        const newId = action.value.transfer_id;
        const existingTransferForNewId = state.transfers[newId];
        
        // Si un transfert existe déjà avec le nouvel ID, on met à jour ses propriétés
        if (existingTransferForNewId) {
          return {
            ...state,
            activeTransferId: newId,
            transfers: {
              ...state.transfers,
              [newId]: {
                ...existingTransferForNewId,
                ...updatedTransfer,
                transfer_id: newId
              }
            }
          };
        } else {
          // Sinon, on crée un nouveau transfert avec le nouvel ID
          const newTransfer = {
            ...updatedTransfer,
            transfer_id: newId
          };
          const { [tempId]: _, ...remainingTransfers } = state.transfers;
          return {
            ...state,
            activeTransferId: newId,
            transfers: {
              ...remainingTransfers,
              [newId]: newTransfer
            }
          };
        }
      } else {
        return {
          ...state,
          activeTransferId: tempId,
          transfers: {
            ...state.transfers,
            [tempId]: updatedTransfer
          }
        };
      }
    }

    case UPDATE_PROGRESS: {
      let transferId = String(action.transferId || state.activeTransferId);
      if (!state.transfers[transferId] && action.transferId) {
        const altKey = Object.keys(state.transfers).find(key => Number(key) === Number(action.transferId));
        if (altKey) {
          transferId = altKey;
        }
      }

      if (!transferId) {
        return state;
      }

      const existingTransfer = state.transfers[transferId];
      if (!existingTransfer) {
        return state;
      }

      const isActiveTransfer = existingTransfer.isLocked && !existingTransfer.purging;
      const isBackgroundActive = existingTransfer.backgroundActive;

      if (!isActiveTransfer && !isBackgroundActive) {
        return state;
      }

      const currentPath = window.location.pathname.trim();
      const isValidPath = currentPath === '/' || currentPath === '/offers';

      const { timestamp, loaded, fileId, chunkIndex, totalLoaded, chunkInfo } = action.payload;

      if (!existingTransfer.isLocked || existingTransfer.isUploadFinished) {
        return state;
      }

      if (!existingTransfer.totalSize) {
        return state;
      }

      const progressState = {
        ...existingTransfer,
        filesProgress: { ...existingTransfer.filesProgress }
      };

      // Mise à jour directe de la progression
      let currentLoaded = existingTransfer.loaded;
      if (chunkInfo?.chunkInfo) {
        const { fileId, chunkIndex, loaded, total, chunkSize } = chunkInfo.chunkInfo;

        const fileProgress = progressState.filesProgress[fileId] || {
          chunks: {},
          totalLoaded: 0,
          fileSize: total || existingTransfer.totalSize || 0
        };

        // Mettre à jour les chunks
        fileProgress.chunks[chunkIndex] = { loaded, total };
        const newTotalLoaded = Object.values(fileProgress.chunks).reduce(
          (sum, chunk: ChunkProgress) => sum + (chunk.loaded || 0),
          0
        );

        // Mettre à jour la progression totale
        fileProgress.totalLoaded = newTotalLoaded;
        progressState.filesProgress[fileId] = fileProgress;
        
        // Calculer la progression globale
        currentLoaded = Object.values(progressState.filesProgress).reduce(
          (sum, fp: FileProgress) => sum + (fp.totalLoaded || 0),
          0
        );
      }
      const safeCurrentLoaded = Math.min(currentLoaded, existingTransfer.totalSize);
      const previousCurrent = { ...existingTransfer.progress.current };
      const currentTimestamp = timestamp || Date.now();
      const timeDiffSec = Math.max(0.001, (currentTimestamp - previousCurrent.timestamp) / 1000);
      const deltaLoaded = Math.max(0, safeCurrentLoaded - previousCurrent.loaded);

      let newInstantSpeed = previousCurrent.instantSpeed;
      let newAvgSpeed = previousCurrent.avgSpeed;

      if (deltaLoaded > 0 && timeDiffSec > 0) {
        const rawSpeed = deltaLoaded / timeDiffSec;
        const alpha = 0.7;
        newInstantSpeed = alpha * rawSpeed + (1 - alpha) * previousCurrent.instantSpeed;
        const beta = 0.3;
        newAvgSpeed = beta * newInstantSpeed + (1 - beta) * previousCurrent.avgSpeed;
      } else {
        newInstantSpeed = previousCurrent.instantSpeed * 0.5;
        newAvgSpeed = previousCurrent.avgSpeed * 0.8;
      }

      const updatedTransfer = {
        ...progressState,
        loaded: safeCurrentLoaded,
        lastProgressUpdate: currentTimestamp,
        // Toujours activer le mode background lors d'une mise à jour de progression
        backgroundActive: true,
        lastSaved: currentTimestamp,
        progress: {
          last: previousCurrent,
          current: {
            timestamp: currentTimestamp,
            loaded: safeCurrentLoaded,
            instantSpeed: newInstantSpeed,
            avgSpeed: newAvgSpeed,
            chunkSize: chunkInfo?.chunkSize || previousCurrent.chunkSize,
            chunksUploaded: chunkInfo?.chunksUploaded || previousCurrent.chunksUploaded,
            totalChunks: chunkInfo?.totalChunks || previousCurrent.totalChunks
          }
        }
      };

      // Mettre à jour l'état de la phase en fonction de la progression
      let newPhase = state.currentPhase;
      if (existingTransfer.isLocked && !existingTransfer.isUploadFinished) {
        newPhase = UploadPhase.PROGRESS;
      }

      return {
        ...state,
        currentPhase: newPhase,
        transfers: {
          ...state.transfers,
          [transferId]: updatedTransfer
        }
      };
    }

    case LOCK_TRANSFER: {
      const transferId = action.transferId || state.activeTransferId;
      if (!transferId) {
        return state;
      }

      if (!action.value) {
        return {
          ...state,
          activeTransferId: transferId,
          transfers: {
            ...state.transfers,
            [transferId]: { ...initialTransferState }
          }
        };
      }

      const currentTransfer = state.transfers[transferId] || { ...initialTransferState };
      const lockTimestamp = Date.now();

      if (currentTransfer.isUploadFinished) {
        return state;
      }

      const updatedTransfer = action.value
        ? {
            ...currentTransfer,
            isLocked: true,
            backgroundActive: true,
            startTime: lockTimestamp,
            lastProgressUpdate: lockTimestamp,
            progress: {
              current: {
                timestamp: lockTimestamp,
                loaded: 0,
                instantSpeed: currentTransfer.progress.current.instantSpeed,
                avgSpeed: currentTransfer.progress.current.avgSpeed,
                chunkSize: currentTransfer.progress.current.chunkSize,
                chunksUploaded: 0,
                totalChunks: currentTransfer.progress.current.totalChunks
              },
              last: {
                timestamp: lockTimestamp,
                loaded: 0
              }
            },
            loaded: 0,
            filesProgress: {},
            currentFileIndex: 0,
            isUploadFinished: false
          }
        : {
            ...currentTransfer,
            isLocked: false,
            backgroundActive: false,
            totalTime: lockTimestamp - currentTransfer.startTime
          };

      // Mettre à jour la phase en fonction de l'état de verrouillage
      let newPhase = state.currentPhase;
      if (action.value) {
        newPhase = UploadPhase.PROGRESS;
      } else if (currentTransfer.totalSize > 0) {
        newPhase = UploadPhase.CONFIGURATION;
      } else {
        newPhase = UploadPhase.INITIAL;
      }

      return {
        ...state,
        currentPhase: newPhase,
        transfers: {
          ...state.transfers,
          [transferId]: updatedTransfer
        }
      };
    }

    case PURGE: {
      const transferId = action.transferId || state.activeTransferId;
      if (!transferId || !state.transfers[transferId]) return state;

      const currentTransfer = state.transfers[transferId];
      if (currentTransfer.isUploadFinished || !currentTransfer.isLocked) {
        const { [transferId]: _, ...remainingTransfers } = state.transfers;
        return {
          ...state,
          transfers: remainingTransfers,
          activeTransferId: Object.keys(remainingTransfers)[0] || null,
          // Réinitialiser la phase si aucun transfert actif
          currentPhase: Object.keys(remainingTransfers).length > 0 
            ? state.currentPhase
            : UploadPhase.INITIAL
        };
      }

      // Si le transfert est en cours, on le marque comme "purging"
      return {
        ...state,
        transfers: {
          ...state.transfers,
          [transferId]: { ...currentTransfer, purging: true, backgroundActive: false }
        }
      };
    }

    case SET_MINIMIZED: {
      const transferId = action.transferId || state.activeTransferId;
      if (!transferId || !state.transfers[transferId]) return state;
      return {
        ...state,
        transfers: {
          ...state.transfers,
          [transferId]: { 
            ...state.transfers[transferId], 
            isMinimized: action.value,
            backgroundActive: action.value 
          }
        }
      };
    }

    case RESTORE_TRANSFER: {
      const { transferId } = action;
      if (!transferId || state.transfers[transferId]) return state;

      const now = Date.now();
      const lastUpdate = action.transferState?.progress?.current?.timestamp || action.transferState?.lastSaved || now;
      const isInactive = now - lastUpdate > 60 * 1000;
      const hasNoProgress = !action.transferState?.progress?.current?.instantSpeed && 
                           !action.transferState?.progress?.current?.avgSpeed;
      const isComplete = action.transferState?.isUploadFinished || 
                        (action.transferState?.loaded >= action.transferState?.totalSize);

      if (isInactive || hasNoProgress || isComplete) {
        return state;
      }

      const restoredState = {
        ...initialTransferState,
        ...action.transferState,
        backgroundActive: true,
        isMinimized: false,
        lastProgressUpdate: now,
        progress: {
          ...initialTransferState.progress,
          ...action.transferState.progress,
          current: {
            ...initialTransferState.progress.current,
            ...action.transferState.progress?.current,
            timestamp: now
          }
        },
        lastSaved: now
      };

      // Déterminer la phase en fonction de l'état restauré
      let restoredPhase = state.currentPhase;
      if (restoredState.isUploadFinished) {
        restoredPhase = UploadPhase.FINISHED;
      } else if (restoredState.isLocked) {
        restoredPhase = UploadPhase.PROGRESS;
      } else if (restoredState.totalSize > 0) {
        restoredPhase = UploadPhase.CONFIGURATION;
      }

      return {
        ...state,
        currentPhase: restoredPhase,
        transfers: {
          ...state.transfers,
          [transferId]: restoredState
        },
        activeTransferId: transferId
      };
    }

    case RESET_TRANSFER_PROGRESS: {
      const transferId = action.transferId || state.activeTransferId;
      if (!transferId) return state;

      const currentTransfer = state.transfers[transferId];
      if (!currentTransfer) {
        return state;
      }

      const now = Date.now();
      return {
        ...state,
        activeTransferId: transferId,
        currentPhase: UploadPhase.CONFIGURATION, // Revenir à la phase de configuration après reset
        transfers: {
          ...state.transfers,
          [transferId]: {
            ...currentTransfer,
            loaded: 0,
            filesProgress: {},
            backgroundActive: false,
            lastProgressUpdate: now,
            progress: {
              current: {
                timestamp: now,
                loaded: 0,
                instantSpeed: 0,
                avgSpeed: 0,
                chunkSize: 0,
                chunksUploaded: 0,
                totalChunks: 0
              },
              last: {
                timestamp: now,
                loaded: 0
              }
            }
          }
        }
      };
    }

    case RESET_TRANSFER_STATE: {
      const transferId = action.transferId;
      const currentActiveId = state.activeTransferId;

      if (transferId) {
        // Si on réinitialise le transfert actif, on garde son ID
        if (transferId === currentActiveId) {
          return {
            ...state,
            transfers: {
              ...state.transfers,
              [transferId]: {
                ...initialTransferState,
                transfer_id: transferId, // Garder l'ID
                backgroundActive: false
              }
            },
            activeTransferId: transferId,
            currentPhase: UploadPhase.INITIAL
          };
        } else {
          // Si ce n'est pas le transfert actif, on peut le supprimer
          const { [transferId]: _, ...remainingTransfers } = state.transfers;
          return {
            ...state,
            transfers: remainingTransfers,
            activeTransferId: currentActiveId,
            currentPhase: state.currentPhase
          };
        }
      }

      // Réinitialisation globale
      return {
        ...initialState,
        transfers: currentActiveId ? {
          [currentActiveId]: {
            ...initialTransferState,
            transfer_id: currentActiveId, // Garder l'ID lors de la réinitialisation globale
            backgroundActive: false
          }
        } : {},
        activeTransferId: currentActiveId
      };
    }

    default:
      return state;
  }
}