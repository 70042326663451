import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "../../utils/";

interface Props {
  text: string;
  imageSrc: string;
}

class BandComponent extends React.Component<Props> {
  render = () => {
    const { text, imageSrc } = this.props;

    return (
      <div 
        className="w-full py-5 bg-gradient-to-r from-gray-100 to-gray-200"
        id="top"
        tabIndex={-1}
      >
        <div className="container mx-auto text-center lg:text-left text-gray-800">
          <div className="px-4 mx-4 md:px-6 md:mx-6 lg:px-8 lg:mx-8 flex flex-wrap items-center">
            {/* Image Column */}
            <div className="w-full sm:w-1/2 lg:w-1/4 mb-4 lg:mb-0">
              <img
                alt="pic-band"
                className="w-full h-auto object-cover"
                src={imageSrc}
              />
            </div>

            {/* Text Column */}
            <div className="w-full sm:w-1/2 lg:w-7/12 mb-2 flex items-center">
              <h3 className="text-center w-full px-0 lg:px-8 xl:px-10 text-2xl font-semibold">
                {text}
              </h3>
            </div>

            {/* Button Column */}
            <div className="w-full lg:w-1/6 flex justify-center lg:block">
              <Link
                to="/offers"
                className="inline-block px-6 py-3 bg-blue-600 text-white uppercase font-medium rounded 
                          hover:bg-blue-700 transition-colors duration-200 ease-in-out 
                          transform hover:scale-105 text-center"
              >
                {i18n._("btn_register_label")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default BandComponent;