import React, { useState, useEffect } from 'react';
import { X, Loader2, Upload, Clock, Users, Palette, Bell, Lock, BarChart, Mail, Video, Check, X as XIcon, Calendar } from 'lucide-react';
import Toast from '../Toast/Toast';
import { i18n } from '../../utils';
import { Go } from '../../Constants/sizeInO';
import { ConfigurableOfferComponent } from '../ConfigurableOfferComponent';
// Nous utilisons notre propre composant d'affichage de permissions
import { CustomOffer, OfferItem, NumberOfferItem, BooleanOfferItem, OfferPeriod } from '../../types/offer';
import { useOffer } from '../../hooks/useOffer';
import { useUpgradePrice } from '../../hooks/useUpgradePrice';
import { useDispatch } from 'react-redux';
import * as userAction from '../../Store/Actions/userAction';
import { addIntervalToPermissions } from '../../utils/subscriptionUtils';

// Composant d'affichage de permissions personnalisé
const PermissionsDisplay: React.FC<{ permissions: any }> = ({ permissions }) => {
  // Composant pour afficher un élément de permission
  const PermissionItem = ({ icon, label, formattedValue }: { icon: React.ReactNode, label: string, formattedValue: React.ReactNode | string }) => (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <div className="text-primary">{icon}</div>
        <span className="text-gray-700 dark:text-gray-300">{label}</span>
      </div>
      <div className="text-gray-600 dark:text-gray-400">{formattedValue}</div>
    </div>
  );

  // Fonction pour formater les valeurs
  const formatValue = (value: any, formatter: (value: any) => React.ReactNode | string) => {
    return formatter(value);
  };

  // Groupes de permissions avec leurs icônes et formatage
  const permissionGroups = [
    {
      title: i18n._('permission_groups.transfers'),
      permissions: [
        {
          name: 'upload_size',
          icon: <Upload size={20} />,
          format: (value: number) => `${value / 1_000_000_000} Go`,
          label: i18n._('upload_size_label')
        },
        {
          name: 'upload_ttl',
          icon: <Clock size={20} />,
          format: (value: number) => `${value} jours`,
          label: i18n._('upload_ttl_label')
        },
        {
          name: 'interval',
          icon: <Calendar size={20} />,
          format: (value: string) => {
            if (value === 'monthly' || value === 'month') return 'Mensuel';
            if (value === 'yearly' || value === 'year') return 'Annuel';
            return value;
          },
          label: i18n._('interval_label')
        },
        {
          name: 'transfer_tracking',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('transfer_tracking_label')
        }
      ]
    },
    {
      title: i18n._('permission_groups.team_and_customization'),
      permissions: [
        {
          name: 'team_size',
          icon: <Users size={20} />,
          format: (value: number) => value === -1 ? i18n._('team_size.unlimited') : i18n._('team_size.with_members', { count: value }),
          label: i18n._('team_size_label')
        },
        {
          name: 'customization',
          icon: <Palette size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('customization_label')
        }
      ]
    },
    {
      title: i18n._('permission_groups.security_and_notifications'),
      permissions: [
        {
          name: 'transfer_authentication',
          icon: <Lock size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('transfer_authentication_label')
        },
        {
          name: 'transfer_notification',
          icon: <Bell size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('transfer_notification_label')
        }
      ]
    },
    {
      title: i18n._('permission_groups.integrations_and_tools'),
      permissions: [
        {
          name: 'outlook_addin',
          icon: <Mail size={20} />,
          format: (value: boolean) => value ? (
            <div className="flex items-center space-x-2">
              <div className="flex space-x-2">
                <a href="https://filevert.fr/fv-outlook/manifest.xml" download="manifest.xml" className="text-primary hover:underline">{i18n._('outlook_addin.download')}</a>
                <span>|</span>
                <a href="https://filevert.fr/solutions/fr/guide/#plugin" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">{i18n._('outlook_addin.guide')}</a>
              </div>
              <Check className="text-green-500" />
            </div>
          ) : <XIcon className="text-red-500" />,
          label: i18n._('outlook_addin_label')
        },
        {
          name: 'studio',
          icon: <Video size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('studio_label')
        },
        {
          name: 'chrome_plugin',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('chrome_plugin_label')
        },
        {
          name: 'consumption_export',
          icon: <BarChart size={20} />,
          format: (value: boolean) => value ? <Check className="text-green-500" /> : <XIcon className="text-red-500" />,
          label: i18n._('consumption_export_label')
        }
      ]
    }
  ];

  // Rendu des groupes de permissions
  const renderPermissionGroups = permissionGroups.map((group, groupIndex) => {
    // Vérifier si au moins une permission du groupe existe dans l'objet permissions
    const hasAnyPermission = group.permissions.some(perm =>
      permissions[perm.name]
    );
    
    // Ne pas afficher le groupe s'il n'a aucune permission
    if (!hasAnyPermission) return null;
    
    return (
      <div key={groupIndex} className="space-y-4">
        <h3 className="text-lg font-medium text-gray-700 dark:text-neutral-300">
          {group.title}
        </h3>
        <div className="bg-gray-50 dark:bg-neutral-700/50 rounded-lg p-4 space-y-4">
          {group.permissions.map((perm, permIndex) => {
            const permission = permissions[perm.name];
            if (!permission) return null;

            return (
              <PermissionItem
                key={permIndex}
                icon={perm.icon}
                label={perm.label}
                formattedValue={formatValue(permission.value, perm.format)}
              />
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
          <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
          {i18n._('permissions_title')}
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {renderPermissionGroups}
      </div>
    </div>
  );
};

interface CurrentPermissions {
  [key: string]: { value: boolean | number | string; id: number } | any;
  subscriptions?: {
    data: Array<{
      status: string;
      plan?: {
        interval?: string;
        amount?: number;
      };
      metadata?: Record<string, string>;
    }>;
  };
  status?: { value: string; id: number };
  trial_end?: { value: number; id: number };
  current_period_end?: { value: number; id: number };
}

interface UpgradeResponse {
  success?: boolean;
  url?: string;
  message?: string;
  prorationPreview?: {
    total: number;
    lines: {
      description: string;
      amount: number;
      period?: { start: number; end: number };
      proration?: boolean;
    }[];
  };
}

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPermissions: CurrentPermissions;
  onUpgrade: (newOffer: CustomOffer) => Promise<UpgradeResponse>;
  user_id: string; // Rendre user_id obligatoire au lieu d'optionnel
}

export const UpgradeModal: React.FC<UpgradeModalProps> = ({
  isOpen,
  onClose,
  currentPermissions,
  onUpgrade,
  user_id
}) => {
  const dispatch = useDispatch();
  const createDefaultOffer = (): CustomOffer => ({
    name: 'Default',
    studio: { type: 'boolean', value: false, label: 'studio' },
    transfer_notification: { type: 'boolean', value: false, label: 'transfer_notification' },
    transfer_authentication: { type: 'boolean', value: false, label: 'transfer_authentication' },
    transfer_tracking: { type: 'boolean', value: false, label: 'transfer_tracking' },
    consumption_export: { type: 'boolean', value: false, label: 'consumption_export' },
    team_size: { type: 'number', value: 0, label: 'team_size' },
    customization: { type: 'boolean', value: false, label: 'customization' },
    outlook_addin: { type: 'boolean', value: false, label: 'outlook_addin' },
    upload_size: { type: 'number', value: 2 * Go, label: 'upload_size' },
    upload_ttl: { type: 'number', value: 2, label: 'upload_ttl' }
  });

  const [step, setStep] = useState<'review' | 'configure'>('review');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentOffer, setCurrentOffer] = useState<CustomOffer>(createDefaultOffer());
  const [isLoading, setIsLoading] = useState(false);
  const [upgradeError, setUpgradeError] = useState<string | null>(null);
  const [upgradeInProgress, setUpgradeInProgress] = useState(false);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);
  const [prorationPreview, setProrationPreview] = useState<{
    total: number;
    lines: {
      description: string;
      amount: number;
      period?: { start: number; end: number };
      proration?: boolean;
    }[];
  } | null>(null);

  // État pour la périodicité sélectionnée par l'utilisateur
  const [selectedPeriod, setSelectedPeriod] = useState<'month' | 'year'>('year');
  // Réinitialiser l'état et synchroniser avec les permissions quand la modale s'ouvre
  useEffect(() => {
    if (isOpen) {
      // Vérifier d'abord si l'utilisateur a des abonnements actifs
      if (currentPermissions?.subscriptions?.data && currentPermissions.subscriptions.data.length > 0) {
        // Récupérer le premier abonnement actif
        const activeSubscription = currentPermissions.subscriptions.data[0];
        
        // Vérifier si l'abonnement a un plan avec un intervalle
        if (activeSubscription?.plan?.interval) {
          const subscriptionInterval = activeSubscription.plan.interval;
          const initialPeriod = subscriptionInterval === 'month' ? 'month' : 'year';
          setSelectedPeriod(initialPeriod);
          console.log(i18n._('logs.period_from_subscription'), {
            subscriptionInterval,
            initialPeriod
          });
          return;
        }
      }
      
      // Fallback sur les permissions si les abonnements ne sont pas disponibles
      if (currentPermissions?.interval?.value) {
        // Convertir 'monthly' en 'month' et 'yearly' en 'year' si nécessaire
        const intervalValue = currentPermissions.interval.value;
        const initialPeriod = intervalValue === 'monthly' || intervalValue === 'month' ? 'month' : 'year';
        setSelectedPeriod(initialPeriod);
        console.log(i18n._('logs.period_from_permissions'), {
          intervalValue,
          initialPeriod
        });
        return;
      }
      
      // Valeur par défaut si aucune information n'est disponible
      console.log(i18n._('logs.no_period_found_using_default'));
      setSelectedPeriod('month');
    }
  }, [isOpen, currentPermissions]);

  // État pour suivre si l'utilisateur a modifié manuellement la période
  const [userChangedPeriod, setUserChangedPeriod] = useState(false);

  // Fonction pour changer la période avec marquage de modification manuelle
  const handlePeriodChange = (period: 'month' | 'year') => {
    setSelectedPeriod(period);
    setUserChangedPeriod(true);
    console.log(i18n._('logs.period_manually_changed'), period);
  };

  // Maintenir la synchronisation avec les permissions uniquement lors de l'initialisation
  useEffect(() => {
    // Cette synchronisation n'est nécessaire que si la modale est ouverte et que l'utilisateur n'a pas modifié manuellement la période
    if (!isOpen || userChangedPeriod) return;
    
    // Vérifier d'abord si l'utilisateur a des abonnements actifs
    if (currentPermissions?.subscriptions?.data && currentPermissions.subscriptions.data.length > 0) {
      // Récupérer le premier abonnement actif
      const activeSubscription = currentPermissions.subscriptions.data[0];
      
      // Vérifier si l'abonnement a un plan avec un intervalle
      if (activeSubscription?.plan?.interval) {
        const subscriptionInterval = activeSubscription.plan.interval;
        const permissionPeriod = subscriptionInterval === 'month' ? 'month' : 'year';
        if (permissionPeriod !== selectedPeriod) {
          console.log(i18n._('logs.period_update_from_subscription'), {
            previous: selectedPeriod,
            new: permissionPeriod
          });
          setSelectedPeriod(permissionPeriod);
        }
        return;
      }
    }
    
    // Fallback sur les permissions si les abonnements ne sont pas disponibles
    if (currentPermissions?.interval?.value) {
      // Convertir 'monthly' en 'month' et 'yearly' en 'year' si nécessaire
      const intervalValue = currentPermissions.interval.value;
      const permissionPeriod = (intervalValue === 'monthly' ? 'month' :
                               intervalValue === 'yearly' ? 'year' :
                               intervalValue) as 'month' | 'year';
      if (permissionPeriod !== selectedPeriod) {
        console.log(i18n._('logs.period_update_from_permissions'), {
          previous: selectedPeriod,
          new: permissionPeriod
        });
        setSelectedPeriod(permissionPeriod);
      }
    }
  }, [isOpen, currentPermissions, selectedPeriod, userChangedPeriod]);

  const convertToCustomOffer = (permissions: CurrentPermissions): CustomOffer => {
    const defaultOffer = createDefaultOffer();
    const result: CustomOffer = {
      ...defaultOffer,
      team_size: { ...defaultOffer.team_size },
      upload_size: { ...defaultOffer.upload_size },
      upload_ttl: { ...defaultOffer.upload_ttl },
      transfer_notification: { ...defaultOffer.transfer_notification },
      transfer_authentication: { ...defaultOffer.transfer_authentication },
      transfer_tracking: { ...defaultOffer.transfer_tracking },
      consumption_export: { ...defaultOffer.consumption_export },
      customization: { ...defaultOffer.customization },
      outlook_addin: { ...defaultOffer.outlook_addin },
      studio: { ...defaultOffer.studio }
    };

    Object.entries(permissions).forEach(([key, value]) => {
      if (key in defaultOffer && value && 'value' in value) {
        const offerKey = key as keyof CustomOffer;
        const defaultValue = defaultOffer[offerKey];
        if (defaultValue && typeof defaultValue === 'object' && 'type' in defaultValue) {
          if (defaultValue.type === 'boolean' && typeof value.value === 'boolean') {
            (result[offerKey] as BooleanOfferItem).value = value.value;
          } else if (defaultValue.type === 'number' && (typeof value.value === 'number' || typeof value.value === 'string')) {
            (result[offerKey] as NumberOfferItem).value = Number(value.value);
          }
        }
      }
    });
    return result;
  };

  const { currentPrice: offerPrice, handleSubmit: handleOfferSubmit } = useOffer(convertToCustomOffer(currentPermissions), false, 'year');

  useEffect(() => {
    if (isOpen) {
      console.log('UpgradeModal: Current permissions:', currentPermissions);
      setStep('review');
      setConfirmationOpen(false);
      setCurrentOffer(createDefaultOffer());
      setIsLoading(false);
      setUpgradeError(null);
      setUpgradeInProgress(false);
      setUpgradeSuccess(false);
    }
  }, [isOpen, currentPermissions]);

  const handleConfigure = () => setStep('configure');

  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [newPrice, setNewPrice] = useState<number>(0);
  const [period, setPeriod] = useState<'month' | 'year'>('year');

  const { calculatePriceDifference } = useUpgradePrice();

  useEffect(() => {
    let mounted = true;
    const updatePrices = async () => {
      try {
        if (!currentPermissions || !currentOffer) return;
        // Déterminer la période actuelle en prenant en compte les deux formats possibles
        const intervalValue = currentPermissions?.interval?.value;
        const currentPeriod = intervalValue === 'monthly' || intervalValue === 'month' ? 'month' : 'year';
        if (mounted) setPeriod(currentPeriod);
        const convertedPermissions = convertToCustomOffer(currentPermissions);
        
        // Utiliser la période sélectionnée par l'utilisateur pour calculer le nouveau prix
        const { currentPrice: current, newPrice: updated } = await calculatePriceDifference(
          convertedPermissions,
          currentOffer,
          selectedPeriod // Utiliser la période sélectionnée pour le calcul du nouveau prix
        );
        console.log(i18n._('logs.prices_calculated'), { currentPeriod, selectedPeriod, current, updated });
        if (mounted) {
          setCurrentPrice(current);
          setNewPrice(updated);
        }
      } catch (error) {
        console.error('Erreur lors du calcul des prix:', error);
      }
    };
    updatePrices();
    return () => { mounted = false; };
  }, [currentPermissions, currentOffer, calculatePriceDifference, convertToCustomOffer, selectedPeriod]);

  const handleFinishConfiguration = async () => setConfirmationOpen(true);

  useEffect(() => {
    let mounted = true;
    const performUpgrade = async () => {
      if (!upgradeInProgress) return;
      try {
        console.log(i18n._('logs.upgrade_start'), { currentOffer, currentPrice, newPrice, period, selectedPeriod });
        
        // Définir la période et l'intervalle en fonction de la sélection de l'utilisateur
        // period doit être 'monthly' ou 'yearly' pour le type OfferPeriod
        // interval doit être 'month' ou 'year' pour Stripe
        const periodValue = selectedPeriod === 'month' ? 'monthly' : 'yearly';
        const intervalValue: 'month' | 'year' = selectedPeriod === 'month' ? 'month' : 'year';
        
        // Déterminer si c'est une mise à niveau, une réduction ou un changement de période
        const isPeriodChanging = period !== selectedPeriod;
        const isUpgrade = newPrice > currentPrice;
        const isDowngrade = newPrice < currentPrice;
        
        // Déterminer le comportement de prorata en fonction du type de changement
        // Si c'est une réduction (downgrade), on applique à la date d'anniversaire
        // Si c'est une mise à niveau (upgrade) ou un changement de période, on applique immédiatement
        const prorationBehavior = isDowngrade && !isPeriodChanging ? 'none' : 'always_invoice';
        
        const upgradedOffer = {
          ...currentOffer,
          proration_behavior: prorationBehavior,
          // Ajouter la périodicité sélectionnée à l'offre mise à jour
          period: periodValue as OfferPeriod,
          interval: intervalValue
        };
        
        console.log(i18n._('logs.sending_upgrade_request'), {
          offer: upgradedOffer,
          proration: true,
          period: periodValue,
          interval: intervalValue,
          selectedPeriod
        });
        if (!user_id) {
          throw new Error(i18n._('errors.missing_user_id'));
        }

        const upgradedOfferWithUser = {
          ...upgradedOffer,
          user_id // Ajouter l'ID utilisateur à l'offre
        };

        const result = await onUpgrade(upgradedOfferWithUser);
        console.log(i18n._('logs.upgrade_response'), { result, userId: user_id });
        if (!mounted) return;
        // Dispatch de la prévisualisation du prorata si disponible
        if (result?.prorationPreview) {
          setProrationPreview(result.prorationPreview);
          dispatch(userAction.setProrationPreview(result.prorationPreview));
        }
        if (result?.url) {
          console.log(i18n._('logs.stripe_redirect'), result.url);
          window.location.href = result.url;
        } else if (result?.success) {
          console.log(i18n._('logs.upgrade_success_auto_payment'));
          setUpgradeSuccess(true);
          // Recharger la page après un court délai
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        console.error('Erreur lors de la mise à niveau:', error);
        if (mounted) setUpgradeError(i18n._('upgrade_modal.errors.upgrade_failed'));
      } finally {
        if (mounted) {
          setIsLoading(false);
          setUpgradeInProgress(false);
        }
      }
    };
    performUpgrade();
    return () => { mounted = false; };
  }, [upgradeInProgress, currentOffer, currentPrice, newPrice, onUpgrade, dispatch, period, selectedPeriod]);

  const handleConfirmUpgrade = () => {
    setIsLoading(true);
    setUpgradeError(null);
    setUpgradeSuccess(false);
    setUpgradeInProgress(true);
  };

  // Réinitialiser tous les états quand on ferme la modale
  const handleClose = () => {
    // Réinitialiser l'offre
    setCurrentOffer(convertToCustomOffer(currentPermissions));
    
    // Réinitialiser les états de l'interface
    setStep('review');
    setConfirmationOpen(false);
    setUpgradeError(null);
    setUpgradeSuccess(false);
    setUpgradeInProgress(false);
    
    // Réinitialiser la période à celle des permissions actuelles
    const intervalValue = currentPermissions?.interval?.value;
    const permissionPeriod = intervalValue === 'monthly' || intervalValue === 'month' ? 'month' : 'year';
    setSelectedPeriod(permissionPeriod);
    
    // Réinitialiser l'état de modification manuelle de la période
    setUserChangedPeriod(false);
    
    // Log de débogage
    console.log('Réinitialisation de la modale:', {
      newPeriod: permissionPeriod,
      permissions: currentPermissions,
      userChangedPeriod: false
    });
    
    // Appeler le callback de fermeture
    onClose();
  };

  const handleResetDefault = () => {
    setCurrentOffer(convertToCustomOffer(currentPermissions));
    setUpgradeSuccess(false);
  };
  
  const isPeriodChanging = period !== selectedPeriod;

  return (
    <div className={`fixed inset-0 z-[9999] overflow-y-auto ${!isOpen ? 'hidden' : ''}`}>
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75 dark:bg-gray-900"></div>
        </div>
        <div className="inline-block align-bottom bg-white dark:bg-neutral-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                {step === 'review' ? i18n._('upgrade_modal.current_permissions') : i18n._('upgrade_modal.configure_new_offer')}
              </h3>
              <button onClick={handleClose} className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
                <X className="h-6 w-6" />
              </button>
            </div>
            {step === 'review' ? (
              <>
                {/* Créer un nouvel objet permissions avec la propriété interval */}
                {(() => {
                  // Créer une copie des permissions avec la périodicité ajoutée
                  const permissionsWithInterval = {
                    ...currentPermissions,
                    interval: {
                      value: selectedPeriod,
                      id: 0
                    }
                  };
                  
                  console.log('Permissions avec interval:', permissionsWithInterval);
                  
                  return <PermissionsDisplay permissions={permissionsWithInterval} />;
                })()}
                <div className="mt-4 flex justify-end space-x-4">
                  <a
                    href="/offers"
                    className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-focus transition-colors"
                  >
                   {i18n._('upgrade_modal.offer_change')}
                  </a>
                  <button
                    onClick={handleConfigure}
                    className="border-2 border-primary text-primary px-4 py-2 rounded hover:bg-primary/10 transition-colors"
                  >
                    {i18n._('upgrade_modal.configure_button')}
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* Sélecteur de périodicité moderne */}
                <div className="mb-6 bg-base-100 p-4 rounded-lg">
                  <h4 className="text-lg font-semibold mb-3">{i18n._('upgrade_modal.period_selection.title')}</h4>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handlePeriodChange('month')}
                      className={`flex-1 py-3 px-4 rounded-lg border-2 transition-all ${
                        selectedPeriod === 'month'
                          ? 'border-primary bg-primary/10 text-primary'
                          : 'border-gray-200 dark:border-gray-700 hover:border-primary/50'
                      }`}
                    >
                      <div className="font-medium">{i18n._('month_label')}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        Facturation mensuelle
                      </div>
                    </button>
                    <button
                      onClick={() => handlePeriodChange('year')}
                      className={`flex-1 py-3 px-4 rounded-lg border-2 transition-all ${
                        selectedPeriod === 'year'
                          ? 'border-primary bg-primary/10 text-primary'
                          : 'border-gray-200 dark:border-gray-700 hover:border-primary/50'
                      }`}
                    >
                      <div className="font-medium">{i18n._('year_label')}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        Économisez 20%
                      </div>
                    </button>
                  </div>
                </div>
                
                <div className="mb-4 space-y-2">
                  <p>{i18n._('upgrade_modal.pricing.current_price')}: {(period === 'year' ? currentPrice * 12 : currentPrice).toFixed(2)}€/{period === 'year' ? i18n._('upgrade_modal.pricing.per_year') : i18n._('upgrade_modal.pricing.per_month')}</p>
                  <p>{i18n._('upgrade_modal.pricing.new_price')}: {(selectedPeriod === 'year' ? newPrice * 12 : newPrice).toFixed(2)}€/{selectedPeriod === 'year' ? i18n._('upgrade_modal.pricing.per_year') : i18n._('upgrade_modal.pricing.per_month')}</p>
                  
                  <div className="flex justify-between">
                    <div className={newPrice !== currentPrice ? "w-3/4" : "w-full"}>
                      {currentPermissions?.status?.value === 'trialing' ? (
                        <div className="mt-2 p-4 bg-green-50 dark:bg-green-900 rounded-lg">
                          <h4 className="text-lg font-semibold text-green-700 dark:text-green-300 mb-2">{i18n._('upgrade_modal.trial_notice.title')}</h4>
                          <p className="text-green-600 dark:text-green-200">
                            {i18n._('upgrade_modal.trial_notice.message')}
                          </p>
                        </div>
                      ) : isPeriodChanging ? (
                        <div className="mt-2 p-4 bg-purple-50 dark:bg-purple-900 rounded-lg">
                          <h4 className="text-lg font-semibold text-purple-700 dark:text-purple-300 mb-2">{i18n._('upgrade_modal.period_change.title')}</h4>
                          <p className="text-purple-600 dark:text-purple-200">
                            {period === 'month' && selectedPeriod === 'year'
                              ? i18n._('upgrade_modal.period_change.monthly_to_annual')
                              : i18n._('upgrade_modal.period_change.annual_to_monthly')}
                          </p>
                        </div>
                      ) : newPrice > currentPrice ? (
                        <div className="mt-2 p-4 bg-blue-50 dark:bg-blue-900 rounded-lg">
                          <h4 className="text-lg font-semibold text-blue-700 dark:text-blue-300 mb-2">{i18n._('upgrade_modal.upgrade.title')}</h4>
                          <p className="text-blue-600 dark:text-blue-200">
                            {i18n._('upgrade_modal.upgrade.message')}
                          </p>
                        </div>
                      ) : (
                        <div className="mt-2 p-4 bg-amber-50 dark:bg-amber-900 rounded-lg">
                          <h4 className="text-lg font-semibold text-amber-700 dark:text-amber-300 mb-2">{i18n._('upgrade_modal.downgrade.title')}</h4>
                          <p className="text-amber-600 dark:text-amber-200">
                            {i18n._('upgrade_modal.downgrade.message')}
                          </p>
                        </div>
                      )}
                    </div>
                    {newPrice !== currentPrice && (
                      <div className="w-1/4 pl-4 flex items-center justify-center">
                        <div className="text-center">
                          <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                            {newPrice > currentPrice ? "Mise à niveau" : "Réduction"}
                          </div>
                          <div className="text-2xl font-bold text-primary">
                            {Math.abs(newPrice - currentPrice).toFixed(2)}€
                            <span className="text-xs block">{selectedPeriod === 'month' ? '/mois' : '/an'}</span>
                          </div>
                          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                            {newPrice > currentPrice ? "en plus" : "en moins"}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <ConfigurableOfferComponent
                  currentOffer={currentOffer}
                  setCurrentOffer={setCurrentOffer}
                  currentPrice={currentPrice}
                  currentPeriod={selectedPeriod} // Utiliser directement la période sélectionnée
                  onFinishConfiguration={handleFinishConfiguration}
                  onResetDefault={handleResetDefault}
                  currentPermissions={currentPermissions}
                />
              </>
            )}
          </div>
        </div>
        {confirmationOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="fixed inset-0 bg-black opacity-50"></div>
              <div className="relative bg-white dark:bg-neutral-800 rounded-lg p-8 max-w-md w-full m-4">
                <h4 className="text-xl font-semibold mb-4">{i18n._('upgrade_modal.confirmation.title')}</h4>
                <div className="mb-4 space-y-2">
                  <>
                    <Toast
                      type="error"
                      message={upgradeError || ''}
                      isVisible={!!upgradeError}
                      duration={5000}
                    />
                    <Toast
                      type="success"
                      message={i18n._('upgrade_modal.confirmation.success')}
                      isVisible={upgradeSuccess}
                      duration={3000}
                    />
                  </>
                  <p>Prix actuel : {(period === 'year' ? currentPrice * 12 : currentPrice).toFixed(2)}€/{period === 'year' ? 'an' : 'mois'}</p>
                  <p>Nouveau prix : {(selectedPeriod === 'year' ? newPrice * 12 : newPrice).toFixed(2)}€/{selectedPeriod === 'year' ? 'an' : 'mois'}</p>
                  
                  {/* Informations sur le changement de périodicité */}
                  {isPeriodChanging && (
                    <div className="mt-2 p-4 bg-purple-50 dark:bg-purple-900 rounded-lg">
                      <h4 className="text-lg font-semibold text-purple-700 dark:text-purple-300 mb-2">
                        {i18n._('upgrade_modal.period_change.title')}
                      </h4>
                      <p className="text-purple-600 dark:text-purple-200">
                        {period === 'month' && selectedPeriod === 'year'
                          ? i18n._('upgrade_modal.period_change.monthly_to_annual')
                          : i18n._('upgrade_modal.period_change.annual_to_monthly')}
                        <br />
                        {i18n._('upgrade_modal.period_change.proration_notice')}
                      </p>
                    </div>
                  )}
                  
                  {currentPermissions?.status?.value === 'trialing' ? (
                    <div className="mt-2 p-4 bg-green-50 dark:bg-green-900 rounded-lg">
                      <h4 className="text-lg font-semibold text-green-700 dark:text-green-300 mb-2">{i18n._('upgrade_modal.trial_notice.title')}</h4>
                      <p className="text-green-600 dark:text-green-200">
                        {i18n._('upgrade_modal.trial_notice.message')}
                        {i18n._('upgrade_modal.trial_notice.first_billing', {
                          amount: (selectedPeriod === 'year' ? newPrice * 12 : newPrice).toFixed(2)
                        })}
                        le {typeof currentPermissions?.trial_end?.value === 'number' ? 
                          new Date(currentPermissions.trial_end.value * 1000).toLocaleDateString() : 
                          'à la fin de votre période d\'essai'}.
                      </p>
                    </div>
                  ) : newPrice > currentPrice || isPeriodChanging ? (
                    <div className="mt-2 p-4 bg-blue-50 dark:bg-blue-900 rounded-lg">
                      <h4 className="text-lg font-semibold text-blue-700 dark:text-blue-300 mb-2">
                        {isPeriodChanging ? i18n._('upgrade_modal.proration.title') : i18n._('upgrade_modal.proration.upgrade_title')}
                      </h4>
                      <p className="text-blue-600 dark:text-blue-200">
                        {i18n._('upgrade_modal.proration.message')}
                      </p>
                      {prorationPreview && (
                        <div className="mt-4 space-y-2">
                          <h5 className="font-medium">{i18n._('upgrade_modal.proration.details_title')}</h5>
                          {prorationPreview.lines.map((line, index) => (
                            <div key={index} className="flex justify-between text-sm">
                              <span>{line.description}</span>
                              <span>{line.amount.toFixed(2)}€</span>
                            </div>
                          ))}
                          <div className="pt-2 border-t border-blue-200 dark:border-blue-700 flex justify-between font-semibold">
                            <span>{i18n._('upgrade_modal.proration.total')}</span>
                            <span>{prorationPreview.total.toFixed(2)}€</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mt-2 p-4 bg-amber-50 dark:bg-amber-900 rounded-lg">
                      <h4 className="text-lg font-semibold text-amber-700 dark:text-amber-300 mb-2">{i18n._('upgrade_modal.proration.downgrade_title')}</h4>
                      <p className="text-amber-600 dark:text-amber-200">
                        {i18n._('upgrade_modal.proration.downgrade_message', {
                          date: typeof currentPermissions?.current_period_end?.value === 'number' ?
                            new Date(currentPermissions.current_period_end.value * 1000).toLocaleDateString() :
                            i18n._('upgrade_modal.proration.period_end')
                        })}
                      </p>
                    </div>
                  )}
                </div>
                {isLoading ? (
                  <div className="flex flex-col items-center justify-center py-4 space-y-2">
                    <Loader2 className="animate-spin h-8 w-8 text-primary" />
                    <span className="text-gray-600 dark:text-gray-300">{i18n._('upgrade_modal.confirmation.loading')}</span>
                  </div>
                ) : (
                  <div className="flex justify-end space-x-4">
                    <button onClick={handleClose} className="px-4 py-2 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100" disabled={isLoading}>
                      {i18n._('upgrade_modal.confirmation.cancel')}
                    </button>
                    <button
                      onClick={handleConfirmUpgrade}
                      className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-focus disabled:opacity-50"
                      disabled={isLoading}
                    >
                      {i18n._('upgrade_modal.confirmation.confirm')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpgradeModal;