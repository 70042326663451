import { defaultPermissions, offerLabelsAndColors } from "../Constants";
import { CustomOffer, BooleanOfferItem, NumberOfferItem } from "../types/offer";

function convertToCustomOffer(permissions: typeof defaultPermissions): CustomOffer {
  return {
    studio: {
      ...permissions.studio,
      type: 'boolean',
      value: Boolean(permissions.studio.value),
    } as BooleanOfferItem,
    transfer_notification: {
      ...permissions.transfer_notification,
      type: 'boolean',
      value: Boolean(permissions.transfer_notification.value),
    } as BooleanOfferItem,
    transfer_authentication: {
      ...permissions.transfer_authentication,
      type: 'boolean',
      value: Boolean(permissions.transfer_authentication.value),
    } as BooleanOfferItem,
    transfer_tracking: {
      ...permissions.transfer_tracking,
      type: 'boolean',
      value: Boolean(permissions.transfer_tracking.value),
    } as BooleanOfferItem,
    consumption_export: {
      ...permissions.consumption_export,
      type: 'boolean',
      value: Boolean(permissions.consumption_export.value),
    } as BooleanOfferItem,
    team_size: {
      ...permissions.team_size,
      type: 'number',
      value: Number(permissions.team_size.value) || 0,
    } as NumberOfferItem,
    customization: {
      ...permissions.customization,
      type: 'boolean',
      value: Boolean(permissions.customization.value),
    } as BooleanOfferItem,
    outlook_addin: {
      ...permissions.outlook_addin,
      type: 'boolean',
      value: Boolean(permissions.outlook_addin.value),
    } as BooleanOfferItem,
    upload_size: {
      ...permissions.upload_size,
      type: 'number',
      value: Number(permissions.upload_size.value),
    } as NumberOfferItem,
    upload_ttl: {
      ...permissions.upload_ttl,
      type: 'number',
      value: Number(permissions.upload_ttl.value),
    } as NumberOfferItem,
  };
}

export function getPredefinedPermissions(type = offerLabelsAndColors[2].label): CustomOffer {
  const selectedOffer = offerLabelsAndColors.find(offer => offer.label === type);
  
  if (!selectedOffer) {
    return convertToCustomOffer(defaultPermissions);
  }

  const { config } = selectedOffer;

  return {
    studio: {
      ...defaultPermissions.studio,
      type: 'boolean',
      value: Boolean(config.studio),
    } as BooleanOfferItem,
    transfer_notification: {
      ...defaultPermissions.transfer_notification,
      type: 'boolean',
      value: Boolean(config.transfer_notification),
    } as BooleanOfferItem,
    transfer_authentication: {
      ...defaultPermissions.transfer_authentication,
      type: 'boolean',
      value: Boolean(config.transfer_authentication),
    } as BooleanOfferItem,
    transfer_tracking: {
      ...defaultPermissions.transfer_tracking,
      type: 'boolean',
      value: Boolean(config.transfer_tracking),
    } as BooleanOfferItem,
    consumption_export: {
      ...defaultPermissions.consumption_export,
      type: 'boolean',
      value: Boolean(config.consumption_export),
    } as BooleanOfferItem,
    team_size: {
      ...defaultPermissions.team_size,
      type: 'number',
      value: Number(config.team_size) || 0,
    } as NumberOfferItem,
    customization: {
      ...defaultPermissions.customization,
      type: 'boolean',
      value: Boolean(config.customization),
    } as BooleanOfferItem,
    outlook_addin: {
      ...defaultPermissions.outlook_addin,
      type: 'boolean',
      value: Boolean(config.outlook_addin),
    } as BooleanOfferItem,
    upload_size: {
      ...defaultPermissions.upload_size,
      type: 'number',
      value: Number(config.upload_size),
    } as NumberOfferItem,
    upload_ttl: {
      ...defaultPermissions.upload_ttl,
      type: 'number',
      value: Number(config.upload_ttl),
    } as NumberOfferItem,
  };
}
