import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Cloud, Zap, Leaf, Droplet, Car, FileText, HelpCircle, Lock } from "lucide-react";
import { i18n } from "../../../../utils/";
import RSEReport from "./RSEReport";
import Tooltip from "../../../Tooltip/Tooltip";
import transferApi from "../../../../Api/transferApi";
import convertSize, { convertGCO2 } from "../../../../utils/convertSize";
import { userApi } from "../../../../Api";
import FolderLoader from "../../../FolderLoader/FolderLoader";

// Constantes pour les calculs d'économies
const CO2_PER_GO_TRANSFERRED = 0.00257;  // kg CO₂/Go
const CO2_PER_GO_STORED = 0.00345;       // kg CO₂/Go
const CO2_PER_EMAIL = 0.0197;            // kg CO₂/email
const CO2_PER_KM_CAR = 0.222;            // kg CO₂/km voiture
const KWH_PER_GO = 0.08;                 // kWh/Go
const WATER_RATIO = 1.8 / 1.5;           // Ratio eau/kWh
const PUE_STANDARD = 1.59;  // PUE standard du marché
const PUE_FILEVERT = 1.2;   // PUE moyen de FileVert
const PUE_SAVINGS_RATIO = 1 - (PUE_FILEVERT / PUE_STANDARD); // ~24.5% d'économies

// Interface pour les props de ActivityItem
interface ItemProps {
  icon: React.ReactNode;
  value: number | string;
  label: string;
  suffix?: string;
  tooltipContent?: string;
}

// Memoized ItemComponent pour les statistiques
const ActivityItem = React.memo<ItemProps>(({ icon, value, label, suffix, tooltipContent }) => (
  <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
    <div className="p-4 md:p-5">
      <div className="flex items-center gap-x-2">
        <p className="text-xs uppercase tracking-wide text-gray-500">
          {i18n._(`tab_activity_${label?.toLowerCase()}_label`)}
        </p>
        {tooltipContent && (
          <Tooltip content={tooltipContent}>
            <HelpCircle className="w-4 h-4 text-gray-500" />
          </Tooltip>
        )}
      </div>
      <div className="mt-1 flex items-center gap-x-2">
        <span className={`text-${label === "weight" ? "accent" : label === "electricity" ? "secondary" : label === "carbon" ? "primary" : label === "L" ? "accent" : "primary"}`}>
          {icon}
        </span>
        <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
          {value}
          {suffix && <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{suffix}</span>}
        </h3>
      </div>
    </div>
  </div>
));

interface Props {
  dispatch: Function;
  user: any;
  title: string;
  changeTab?: any;
}

interface State {
  currentConsumption: {
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  currentTeamConsumption: {
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  isLoading: boolean;
  fullConsumption: {
    sizeIn0: number;
    gCO2: number;
  };
  totalAttachmentsSaved: string;
  savedByYearGCO2: string;
  savedPUERatio: string;
  savedConsumption: {
    activity_transfered_label: number;
    activity_saved_by_pue_label: number;
    activity_saved_by_year_label: number;
    activity_saved_label: number;
    activity_compensated_label: number;
  };
  usageStats: {
    uploadVolume: number;
    downloadVolume: number;
    downloadRatio: number;
    autoRemoveRatio: number;
    passwordProtectedRatio: number;
    receptionVolume: number;
    revisionCount: number;
  };
  standardConsumption: {
    co2: number;
    energy: number;
    water: number;
    ecoCompensated: number;
  };
  filevertConsumption: {
    co2_reduced: number;
    energy_saved: number;
    water_saved: number;
    car_equivalent: number;
    eco_compensated: number;
  };
  serverSavings: number;
  dateRange: 'current_year' | 'last_year' | 'current_month' | 'all';
  history: any[];
  page: number;
  pageSize: number;
  hasMore: boolean;
  error: string | null;
  retryCount: number;
}

interface ItemProps {
  icon: React.ReactNode;
  value: number | string;
  label: string;
  suffix?: string;
  tooltipContent?: string;
}

// Cache pour stocker les calculs
const calculationCache = new Map();

function ActivityComponent(props: Props) {
  // Convertir la classe en composant fonctionnel avec hooks
  const [state, setState] = useState<State>({
    savedConsumption: {
      activity_transfered_label: 0,
      activity_saved_by_pue_label: 0,
      activity_saved_by_year_label: 0,
      activity_saved_label: 0,
      activity_compensated_label: 0,
    },
    fullConsumption: {
      sizeIn0: 0,
      gCO2: 0,
    },
    currentConsumption: {
      sizeIn0: 0,
      kWh: 0,
      kms: 0,
      gCO2: 0,
    },
    currentTeamConsumption: {
      sizeIn0: 0,
      kWh: 0,
      kms: 0,
      gCO2: 0,
    },
    isLoading: true,
    totalAttachmentsSaved: "",
    savedByYearGCO2: "",
    savedPUERatio: "",
    usageStats: {
      uploadVolume: 0,
      downloadVolume: 0,
      downloadRatio: 0,
      autoRemoveRatio: 0,
      passwordProtectedRatio: 0,
      receptionVolume: 0,
      revisionCount: 0,
    },
    standardConsumption: {
      co2: 0,
      energy: 0,
      water: 0,
      ecoCompensated: 0,
    },
    filevertConsumption: {
      co2_reduced: 0,
      energy_saved: 0,
      water_saved: 0,
      car_equivalent: 0,
      eco_compensated: 0,
    },
    serverSavings: 0,
    dateRange: "current_month",
    history: [],
    page: 1,
    pageSize: 10,
    hasMore: true,
    error: null,
    retryCount: 0
  });

  // Référence pour AbortController
  const abortControllerRef = React.useRef<AbortController | null>(null);

  // Fonction pour annuler des requêtes en cours
  const cancelPendingRequests = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  }, []);

  // Nettoyage lors du démontage du composant
  useEffect(() => {
    return () => {
      cancelPendingRequests();
    };
  }, [cancelPendingRequests]);

  // Gestion du changement de période
  const handleDateRangeChange = useCallback((newRange: 'current_year' | 'last_year' | 'current_month' | 'all') => {
    // Mettre à jour l'état avec la nouvelle période
    // L'effet useEffect se chargera d'invalider le cache et de recharger les données
    setState(prevState => ({ ...prevState, dateRange: newRange, isLoading: true }));
  }, []);

  // Fonction de calcul avec mise en cache
  const calculateWithCache = useCallback(<T extends unknown>(key: string, calculation: () => T): T => {
    if (calculationCache.has(key)) {
      return calculationCache.get(key);
    }
    const result = calculation();
    calculationCache.set(key, result);
    return result;
  }, []);

  // Calcul de la consommation à partir de l'historique
  const calculateConsumption = useCallback((history: any[]) => {
    // Log pour déboguer
    console.log(`Calcul de la consommation pour ${history.length} transferts`);
    
    return history.reduce((acc, line) => {
      // Récupérer la taille du transfert
      const consumptionSize = line?.consumption?.size || 0;
      const transferSize = parseInt(line?.transfer_size || '0');
      const sizeToAdd = consumptionSize || transferSize;
      
      // Prendre en compte le statut du transfert et le nombre de téléchargements
      const downloaded = typeof line.downloaded === 'number' ? line.downloaded : 0;
      let sizeToAddToConsumption = 0;
      
      // Pour les uploads, ajouter directement la taille
      if (line.transfer_type === 0) { // 0 = upload
        sizeToAddToConsumption = sizeToAdd;
        // Log pour déboguer
        console.log(`Consommation upload ${line.transfer_id}: size=${sizeToAdd}`);
      }
      // Pour les téléchargements, vérifier le statut et downloaded
      else {
        // Cas 1: Transferts complets (status = 1)
        if (line.transfer_status === 1) {
          sizeToAddToConsumption = sizeToAdd;
          // Log pour déboguer
          console.log(`Consommation transfert complet ${line.transfer_id}: size=${sizeToAdd}, status=${line.transfer_status}`);
        }
        // Cas 2: Fichiers individuels téléchargés (downloaded > 0)
        else if (downloaded > 0) {
          sizeToAddToConsumption = sizeToAdd * downloaded;
          // Log pour déboguer
          console.log(`Consommation fichier individuel ${line.transfer_id}: size=${sizeToAdd}, downloaded=${downloaded}, total=${sizeToAddToConsumption}`);
        }
      }
      
      if (line?.consumption?.kWh || line?.consumption?.gCO2 || line?.consumption?.kms || line?.consumption?.size) {
        acc.kWh += line?.consumption?.kWh || 0;
        acc.gCO2 += line?.consumption?.gCO2 || 0;
        acc.kms += line?.consumption?.kms || 0;
        acc.sizeIn0 += sizeToAddToConsumption;
        
        // Log pour déboguer les premiers transferts
        if (line.transfer_id && (line.transfer_id.startsWith('2N7') || acc.sizeIn0 > 10000000000000)) {
          console.log(`Consommation transfert ${line.transfer_id}: size=${sizeToAdd}, downloaded=${downloaded}, total=${sizeToAddToConsumption}, acc.sizeIn0=${acc.sizeIn0}`);
        }
      }
      return acc;
    }, { sizeIn0: 0, kWh: 0, kms: 0, gCO2: 0 });
  }, []);

  // Calcul des statistiques d'utilisation
  const calculateUsageStats = useCallback((userHistory: any[]) => {
    const usageStats = {
      uploadVolume: 0,
      downloadVolume: 0,
      downloadRatio: 0,
      autoRemoveRatio: 0,
      passwordProtectedRatio: 0,
      receptionVolume: 0,
      revisionCount: 0,
    };

    const hasTransfers = userHistory.length > 0;
    const totalUserTransfers = hasTransfers ? userHistory.length : 1;

    let downloadsCount = 0;
    let autoRemoveCount = 0;
    let passwordProtectedCount = 0;

    userHistory.forEach((line: any) => {
      // Volume d'upload
      const transferSize = line?.consumption?.size || parseInt(line?.transfer_size || '0') || 0;
      if (line.transfer_type === 0) { // Seulement les uploads
        usageStats.uploadVolume += transferSize;
      }
      
      // Stats de téléchargement
      // Cas 1: Téléchargements de transferts complets (status = 1)
      if (line.transfer_status === 1) {
        // Log pour déboguer
        console.log(`Ajout au volume téléchargé (transfert complet): transferSize=${transferSize}, transferId=${line.transfer_id}`);
        
        usageStats.downloadVolume += transferSize; // Ajouter la taille du transfert complet
        downloadsCount++;
      }
      // Cas 2: Téléchargements de fichiers individuels (seulement si le transfert n'a pas déjà été compté)
      else if (typeof line.downloaded === 'number' && line.downloaded > 0) {
        downloadsCount++;
        if (line.transfer_type === 1) { // Seulement les réceptions
          usageStats.receptionVolume += transferSize * line.downloaded;
        }
        
        // Log pour déboguer
        console.log(`Ajout au volume téléchargé (fichiers individuels): transferSize=${transferSize}, downloaded=${line.downloaded}, total=${transferSize * line.downloaded}, transferId=${line.transfer_id}`);
        
        usageStats.downloadVolume += transferSize * line.downloaded; // Total des volumes téléchargés (taille * nombre de téléchargements)
      }
      
      // Auto-remove - ne compter que les transferts avec auto_remove activé
      if (line.auto_remove === true) {
        autoRemoveCount++;
      }
      
      // Protection par mot de passe - vérifier uniquement transfer_password qui est le champ officiel
      if (line.transfer_password) {
        passwordProtectedCount++;
      }
      
      // Nombre de révisions (uniquement pour les fichiers FV_STUDIO)
      if (line.transfer_type === 0 && line.transfer_provider === "FV_STUDIO") {
        usageStats.revisionCount++;
      }
    });

    // Si aucun transfert, on met les ratios à -1 pour afficher N/A
    if (!hasTransfers) {
      usageStats.downloadRatio = -1;
      usageStats.autoRemoveRatio = -1;
      usageStats.passwordProtectedRatio = -1;
    } else {
      usageStats.downloadRatio = (downloadsCount / totalUserTransfers) * 100;
      usageStats.autoRemoveRatio = (autoRemoveCount / totalUserTransfers) * 100;
      usageStats.passwordProtectedRatio = (passwordProtectedCount / totalUserTransfers) * 100;
    }

    // Log pour déboguer
    console.log(`Volume téléchargé total calculé: ${usageStats.downloadVolume} octets (${convertSize(usageStats.downloadVolume)})`);
    
    return usageStats;
  }, []);

  // Calcul des économies FileVert
  const calculateFileVertSavings = useCallback((currentConsumption: State['currentConsumption']) => {
    // Calcul des économies serveurs (24% des émissions CO2 actuelles)
    const serverSavings = currentConsumption.gCO2 * PUE_SAVINGS_RATIO;
    
    // Calcul des économies pour filevertConsumption (en kg)
    const co2SavedServers = serverSavings / 1000; // en kg
    
    // 20% d'économies stockage en kg
    const co2SavedStorage = (currentConsumption.gCO2 * 0.2) / 1000;
    
    // Total des économies CO2
    const totalCO2Saved = co2SavedServers + co2SavedStorage;
    
    // Calcul des économies d'énergie et d'eau (60% d'économies)
    const totalEnergySaved = currentConsumption.kWh * 0.6;
    const totalWaterSaved = currentConsumption.kWh * WATER_RATIO * 0.6;
    
    return {
      serverSavings,
      filevertConsumption: {
        co2_reduced: totalCO2Saved,
        energy_saved: totalEnergySaved,
        water_saved: totalWaterSaved,
        car_equivalent: Math.round(totalCO2Saved * 1000 / CO2_PER_KM_CAR),
        eco_compensated: currentConsumption.gCO2 / 1000
      }
    };
  }, []);

  // Fonction pour traiter les données d'activité
  const processActivityData = useCallback((
    history: any[],
    userActivity: any,
    providedFilteredUserHistory?: any[],
    providedFilteredTeamHistory?: any[]
  ) => {
    // Utiliser la fonction memoïsée avec cache pour calculer totalAttachmentsSaved
    const totalAttachmentsSaved = calculateWithCache(
      `totalAttachmentsSaved-${state.dateRange}-${history.length}`,
      () => {
        const count = history?.filter(({ consumption, size }: { consumption?: any, size?: number }) => {
          const x = 30 * Math.pow(1024, 2);
          return Number(consumption?.size) > x || Number(size) > x;
        })?.length;
        
        return convertGCO2(count * 46);
      }
    );

    // Calculs des jours et ratio par année
    const minTime = history?.reduce(
      (min: number, { created_at }: { created_at: number }) => (!min || created_at < min ? created_at : min),
      0
    );

    const days = Math.max(
      Date.now() / 1000 / 60 / 60 / 24 - new Date(minTime).getTime() / 1000 / 60 / 60 / 24,
      1 // Éviter division par zéro
    );

    const ratioPerYear = 365 / days;

    // Calcul de la durée de consommation
    const concurrentConsumedTime = history?.length * 24 * 365;
    const fvConsumedTime = history?.reduce(
      (acc: number, cur: any) => (acc += cur?.consumption?.duration || 24 * 365),
      0
    );

    // Calcul de la consommation totale
    const fullConsumption = history.reduce(
      (acc: { sizeIn0: number, gCO2: number }, cur: any) => ({
        sizeIn0: acc.sizeIn0 + (cur?.consumption?.size || (() => {
          if (cur.size) return Number(cur.size);
          if (cur.transfer_size) return Number(cur.transfer_size);
          return 0;
        })()),
        gCO2: acc.gCO2 + (cur?.consumption?.gCO2 || 0),
      }),
      { sizeIn0: 0, gCO2: 0 }
    );

    // Calcul des kWh consommés
    const fvKWHConsumed = (fullConsumption.sizeIn0 / 1024 / 1024 / 1024 / 1024) * fvConsumedTime;
    const concurrentKWHConsumed = (fullConsumption.sizeIn0 / 1024 / 1024 / 1024 / 1024) * concurrentConsumedTime;
    
    // Récupération sécurisée du ratio CO2 avec une valeur par défaut
    const getC02Ratio = () => {
      try {
        // Accès à une propriété globale non typée
        const connexionInfos = (window as any)?.connexionInfos;
        return connexionInfos?.CO2Ratio || 0.085; // Valeur par défaut si non disponible
      } catch (error) {
        console.warn("Erreur lors de l'accès au CO2Ratio:", error);
        return 0.085; // Valeur par défaut en cas d'erreur
      }
    };
    const savedByYearGCO2 = convertGCO2(
      (concurrentKWHConsumed - fvKWHConsumed) * getC02Ratio() * ratioPerYear
    );

    // Séparation des transferts utilisateur et équipe
    let teamHistory = history.filter(
      (transfer: any) => transfer.user_id !== props.user.id
    );
    
    const userHistory = history.filter(
      (transfer: any) => transfer.user_id === props.user.id
    );
    
    // Utiliser les historiques filtrés fournis ou filtrer les historiques
    // S'assurer que filteredUserHistory et filteredTeamHistory sont toujours des tableaux
    let filteredUserHistory: any[] = providedFilteredUserHistory || [];
    let filteredTeamHistory: any[] = providedFilteredTeamHistory || [];
    
    if ((!providedFilteredUserHistory || !providedFilteredTeamHistory) && history.length > 0) {
      if (state.dateRange !== 'all') {
        let startTimestamp = 0;
        
        if (state.dateRange === 'current_month') {
          // Filtrer les transferts des 30 derniers jours
          const now = new Date();
          const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
          startTimestamp = Math.floor(thirtyDaysAgo.getTime() / 1000);
          
          console.log("Filtre 30 derniers jours:", new Date(startTimestamp * 1000).toISOString());
        } else if (state.dateRange === 'last_year') {
          // Filtrer les transferts de l'année civile précédente (du 1er janvier au 31 décembre)
          const currentYear = new Date().getFullYear();
          const lastYearStart = new Date(currentYear - 1, 0, 1); // 1er janvier de l'année précédente
          lastYearStart.setHours(0, 0, 0, 0);
          const lastYearEnd = new Date(currentYear - 1, 11, 31, 23, 59, 59, 999); // 31 décembre de l'année précédente
          
          startTimestamp = Math.floor(lastYearStart.getTime() / 1000);
          const endTimestamp = Math.floor(lastYearEnd.getTime() / 1000);
          
          console.log("Filtre année précédente - début:", new Date(startTimestamp * 1000).toISOString());
          console.log("Filtre année précédente - fin:", new Date(endTimestamp * 1000).toISOString());
        } else if (state.dateRange === 'current_year') {
          // Filtrer les transferts de l'année en cours
          const currentYear = new Date();
          currentYear.setMonth(0, 1); // 1er janvier de l'année en cours
          currentYear.setHours(0, 0, 0, 0);
          startTimestamp = Math.floor(currentYear.getTime() / 1000);
          
          console.log("Filtre année en cours:", new Date(startTimestamp * 1000).toISOString());
        }
        
        // Filtrer les historiques utilisateur et équipe
        // Variable pour stocker la date de fin pour le filtre "last_year"
        let endTimestamp = 0;
        if (state.dateRange === 'last_year') {
          const currentYear = new Date().getFullYear();
          const lastYearEnd = new Date(currentYear - 1, 11, 31, 23, 59, 59, 999);
          endTimestamp = Math.floor(lastYearEnd.getTime() / 1000);
        }

        // Ajouter un log pour déboguer
        console.log(`Filtrage des transferts pour ${state.dateRange}, startTimestamp=${startTimestamp}`);
        
        // Afficher quelques exemples de dates de transfert
        if (userHistory.length > 0) {
          const sampleTransfers = userHistory.slice(0, 3);
          sampleTransfers.forEach((transfer: any, index: number) => {
            console.log(`Exemple de transfert ${index}:`, {
              created_at: transfer.created_at,
              type: typeof transfer.created_at,
              asDate: new Date(transfer.created_at).toISOString(),
              asTimestamp: typeof transfer.created_at === 'string'
                ? Math.floor(new Date(transfer.created_at).getTime() / 1000)
                : Number(transfer.created_at)
            });
          });
        }
        
        filteredUserHistory = userHistory.filter((transfer: any) => {
          // Convertir created_at en Date puis en timestamp
          let createdAt;
          
          try {
            if (typeof transfer.created_at === 'string') {
              createdAt = Math.floor(new Date(transfer.created_at).getTime() / 1000);
            } else if (typeof transfer.created_at === 'number') {
              // Si c'est déjà un timestamp en secondes, l'utiliser directement
              createdAt = transfer.created_at;
            } else {
              // Cas par défaut
              createdAt = Math.floor(new Date(transfer.created_at).getTime() / 1000);
            }
            
            // Pour "last_year", vérifier que la date est entre le début et la fin de l'année précédente
            if (state.dateRange === 'last_year') {
              return createdAt >= startTimestamp && createdAt <= endTimestamp;
            }
            
            const result = createdAt >= startTimestamp;
            
            // Log pour déboguer les premiers transferts
            if (transfer.transfer_id && transfer.transfer_id.startsWith('2N7')) {
              console.log(`Filtrage transfert ${transfer.transfer_id}: createdAt=${createdAt}, startTimestamp=${startTimestamp}, result=${result}`);
            }
            
            return result;
          } catch (error) {
            console.error(`Erreur lors du filtrage du transfert ${transfer.transfer_id}:`, error);
            return false;
          }
        });
        
        filteredTeamHistory = teamHistory.filter((transfer: any) => {
          // Convertir created_at en nombre si c'est une chaîne
          const createdAt = typeof transfer.created_at === 'string'
            ? Math.floor(new Date(transfer.created_at).getTime() / 1000)
            : Number(transfer.created_at);
          
          // Pour "last_year", vérifier que la date est entre le début et la fin de l'année précédente
          if (state.dateRange === 'last_year') {
            return createdAt >= startTimestamp && createdAt <= endTimestamp;
          }
          
          return createdAt >= startTimestamp;
        });
      } else {
        filteredUserHistory = userHistory;
        filteredTeamHistory = teamHistory;
      }
    }
    
    // Log pour déboguer
    console.log(`Nombre de transferts filtrés: userHistory=${userHistory.length}, filteredUserHistory=${filteredUserHistory.length}`);
    
    // Calcul des consommations utilisateur et équipe
    const currentConsumption = calculateConsumption(filteredUserHistory);
    const currentTeamConsumption = calculateConsumption(filteredTeamHistory);
    
    // Log pour déboguer
    console.log(`Consommation calculée: currentConsumption.sizeIn0=${currentConsumption.sizeIn0} (${convertSize(currentConsumption.sizeIn0)})`);
    
    // Calcul du ratio filtré
    let filteredRatio = 1;
    
    if (state.dateRange !== 'all') {
      const totalSize = fullConsumption.sizeIn0 || 1;
      const filteredSize = currentConsumption.sizeIn0 + currentTeamConsumption.sizeIn0;
      filteredRatio = Math.min(1, Math.max(0, filteredSize / totalSize));
    }
    
    // Ajustement des statistiques d'activité en fonction du filtrage
    const filteredActivity = {
      activity_transfered_label: userActivity.activity_transfered_label * filteredRatio,
      activity_saved_by_pue_label: userActivity.activity_saved_by_pue_label * filteredRatio,
      activity_saved_by_year_label: userActivity.activity_saved_by_year_label * filteredRatio,
      activity_saved_label: userActivity.activity_saved_label * filteredRatio,
      activity_compensated_label: userActivity.activity_compensated_label * filteredRatio,
    };
    
    // Si aucune donnée pour la période filtrée, réinitialiser les statistiques
    if ((state.dateRange === 'last_year' || state.dateRange === 'current_month') &&
        currentConsumption.sizeIn0 === 0 && currentTeamConsumption.sizeIn0 === 0) {
      filteredActivity.activity_transfered_label = 0;
      filteredActivity.activity_saved_by_pue_label = 0;
      filteredActivity.activity_saved_by_year_label = 0;
      filteredActivity.activity_saved_label = 0;
      filteredActivity.activity_compensated_label = 0;
    }
    
    // Calcul des statistiques d'utilisation
    const usageStats = calculateUsageStats(filteredUserHistory);
    
    // Calcul de la consommation standard
    let standardConsumption = {
      co2: (userActivity.co2 || 0) * filteredRatio,
      energy: (userActivity.energy || 0) * filteredRatio,
      water: (userActivity.water || 0) * filteredRatio,
      ecoCompensated: (userActivity.eco_compensated || 0) * filteredRatio
    };
    
    // Réinitialisation si nécessaire
    if ((state.dateRange === 'last_year' || state.dateRange === 'current_month') &&
        currentConsumption.sizeIn0 === 0 && currentTeamConsumption.sizeIn0 === 0) {
      standardConsumption = {
        co2: 0,
        energy: 0,
        water: 0,
        ecoCompensated: 0
      };
    }
    
    // Calcul des économies FileVert
    const { serverSavings, filevertConsumption } = calculateFileVertSavings(currentConsumption);
    
    // Mise à jour de l'état avec les nouvelles données
    setState(prevState => ({
      ...prevState,
      fullConsumption,
      currentConsumption,
      currentTeamConsumption,
      savedConsumption: filteredActivity,
      isLoading: false,
      totalAttachmentsSaved,
      savedByYearGCO2,
      usageStats,
      standardConsumption,
      filevertConsumption,
      serverSavings,
      history: filteredUserHistory,
      error: null,
      retryCount: 0,
      hasMore: history.length >= state.pageSize // Si on a reçu moins d'éléments que la taille de la page, on a tout récupéré
    }));
  }, [
    calculateWithCache,
    calculateConsumption,
    calculateUsageStats,
    calculateFileVertSavings,
    props.user.id,
    state.dateRange,
    state.pageSize
  ]);

  // Récupération de l'historique avec pagination
  const getUploadHistory = useCallback(async (page = 1) => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    
    // Annuler les requêtes en cours
    cancelPendingRequests();
    
    // Créer un nouvel AbortController
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    
    try {
      // Construire les paramètres de pagination
      const paginationParams = {
        page,
        pageSize: state.pageSize,
        dateRange: state.dateRange
      };
      
      // Vérifier si les données sont déjà en cache pour cette période
      const cacheKey = `activity-${props.user.id}-${state.dateRange}-${page}`;
      const cachedData = sessionStorage.getItem(cacheKey);
      
      if (cachedData && !state.retryCount) {
        try {
          console.log("Utilisation des données en cache pour l'activité");
          const { history, userActivity, filteredUserHistory, filteredTeamHistory } = JSON.parse(cachedData);
          
          // Vérifier si les données en cache sont valides
          if (history && userActivity && Array.isArray(history) &&
              filteredUserHistory && filteredTeamHistory &&
              Array.isArray(filteredUserHistory) && Array.isArray(filteredTeamHistory)) {
            console.log("Données en cache valides");
            
            // Vérifier que les données filtrées ne sont pas vides (sauf pour 'all')
            if (filteredUserHistory.length > 0 || filteredTeamHistory.length > 0 || state.dateRange === 'all') {
              console.log("Données filtrées valides pour la période", state.dateRange);
              
              // Mettre à jour l'état avec les données en cache filtrées
              processActivityData(history, userActivity, filteredUserHistory, filteredTeamHistory);
              return;
            } else {
              console.log("Données filtrées vides pour la période", state.dateRange);
            }
          }
        } catch (e) {
          console.warn("Erreur lors de la lecture des données en cache:", e);
          // Supprimer les données en cache invalides
          sessionStorage.removeItem(cacheKey);
        }
      }
      
      console.log("Chargement des données d'activité depuis le serveur");
      
      // Exécuter les requêtes en parallèle pour améliorer les performances
      const [history, userActivity] = await Promise.all([
        transferApi.getAllUserTransfer(props.user.id, state.dateRange),
        userApi.getUserActivity(props.user.id, state.dateRange)
      ]);
      
      // Log pour déboguer
      console.log(`Données récupérées de l'API: history.length=${history.length}, dateRange=${state.dateRange}`);
      console.log(`userActivity:`, userActivity);
      
      // Vérifier les données de téléchargement
      const downloadedFiles = history.filter((transfer: any) =>
        typeof transfer.downloaded === 'number' && transfer.downloaded > 0
      );
      console.log(`Fichiers téléchargés: ${downloadedFiles.length}`);
      
      // Calculer le volume total téléchargé selon la requête SQL
      const totalDownloadedVolume = history.reduce((total: number, transfer: any) => {
        const fileSize = transfer?.consumption?.size || parseInt(transfer?.transfer_size || '0') || 0;
        const downloaded = typeof transfer.downloaded === 'number' ? transfer.downloaded : 0;
        return total + (fileSize * downloaded);
      }, 0);
      
      console.log(`Volume total téléchargé (calcul SQL): ${totalDownloadedVolume} octets (${convertSize(totalDownloadedVolume)})`);
      
      // Filtrer les données en fonction de la période sélectionnée
      let filteredUserHistory: any[] = [];
      let filteredTeamHistory: any[] = [];
      
      // Séparation des transferts utilisateur et équipe
      const teamHistory = history.filter(
        (transfer: any) => transfer.user_id !== props.user.id
      );
      
      const userHistory = history.filter(
        (transfer: any) => transfer.user_id === props.user.id
      );
      
      // Filtrage par période
      if (state.dateRange !== 'all') {
        let startTimestamp = 0;
        
        if (state.dateRange === 'current_month') {
          // Filtrer les transferts des 30 derniers jours
          const now = new Date();
          const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
          startTimestamp = Math.floor(thirtyDaysAgo.getTime() / 1000);
          
          console.log("Filtre 30 derniers jours (getUploadHistory):", new Date(startTimestamp * 1000).toISOString());
        } else if (state.dateRange === 'last_year') {
          // Filtrer les transferts de l'année civile précédente (du 1er janvier au 31 décembre)
          const currentYear = new Date().getFullYear();
          const lastYearStart = new Date(currentYear - 1, 0, 1); // 1er janvier de l'année précédente
          lastYearStart.setHours(0, 0, 0, 0);
          const lastYearEnd = new Date(currentYear - 1, 11, 31, 23, 59, 59, 999); // 31 décembre de l'année précédente
          
          startTimestamp = Math.floor(lastYearStart.getTime() / 1000);
          const endTimestamp = Math.floor(lastYearEnd.getTime() / 1000);
          
          console.log("Filtre année précédente (getUploadHistory) - début:", new Date(startTimestamp * 1000).toISOString());
          console.log("Filtre année précédente (getUploadHistory) - fin:", new Date(endTimestamp * 1000).toISOString());
        } else if (state.dateRange === 'current_year') {
          // Filtrer les transferts de l'année en cours
          const currentYear = new Date();
          currentYear.setMonth(0, 1); // 1er janvier de l'année en cours
          currentYear.setHours(0, 0, 0, 0);
          startTimestamp = Math.floor(currentYear.getTime() / 1000);
          
          console.log("Filtre année en cours (getUploadHistory):", new Date(startTimestamp * 1000).toISOString());
        }
        
        // Variable pour stocker la date de fin pour le filtre "last_year"
        let endTimestamp = 0;
        if (state.dateRange === 'last_year') {
          const currentYear = new Date().getFullYear();
          const lastYearEnd = new Date(currentYear - 1, 11, 31, 23, 59, 59, 999);
          endTimestamp = Math.floor(lastYearEnd.getTime() / 1000);
        }

        // Filtrer les historiques utilisateur et équipe
        filteredUserHistory = userHistory.filter((transfer: any) => {
          // Convertir created_at en nombre si c'est une chaîne
          const createdAt = typeof transfer.created_at === 'string'
            ? Math.floor(new Date(transfer.created_at).getTime() / 1000)
            : Number(transfer.created_at);
          
          // Pour "last_year", vérifier que la date est entre le début et la fin de l'année précédente
          if (state.dateRange === 'last_year') {
            return createdAt >= startTimestamp && createdAt <= endTimestamp;
          }
          
          return createdAt >= startTimestamp;
        });
        
        filteredTeamHistory = teamHistory.filter((transfer: any) => {
          // Convertir created_at en nombre si c'est une chaîne
          const createdAt = typeof transfer.created_at === 'string'
            ? Math.floor(new Date(transfer.created_at).getTime() / 1000)
            : Number(transfer.created_at);
          
          // Pour "last_year", vérifier que la date est entre le début et la fin de l'année précédente
          if (state.dateRange === 'last_year') {
            return createdAt >= startTimestamp && createdAt <= endTimestamp;
          }
          
          return createdAt >= startTimestamp;
        });
      } else {
        filteredUserHistory = userHistory;
        filteredTeamHistory = teamHistory;
      }
      
      // Mettre en cache les données pour une utilisation ultérieure
      try {
        sessionStorage.setItem(cacheKey, JSON.stringify({
          history,
          userActivity,
          filteredUserHistory,
          filteredTeamHistory
        }));
      } catch (e) {
        console.warn("Impossible de mettre en cache les données d'activité:", e);
      }
      
      // Traiter les données avec la fonction mise à jour
      processActivityData(history, userActivity, filteredUserHistory, filteredTeamHistory);
    } catch (error) {
      // Ne pas mettre à jour l'état si la requête a été annulée
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Requête annulée');
        return;
      }
      
      console.error("Erreur lors du chargement des données:", error);
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        error: "Erreur lors du chargement des données",
        retryCount: prevState.retryCount + 1
      }));
      
      // Réessayer automatiquement après un délai si moins de 3 tentatives
      if (state.retryCount < 3) {
        setTimeout(() => {
          getUploadHistory(page);
        }, 2000);
      }
    }
  }, [
    props.user.id,
    state.dateRange,
    state.pageSize,
    calculateWithCache,
    calculateConsumption,
    calculateUsageStats,
    calculateFileVertSavings,
    cancelPendingRequests
  ]);

  // Charger plus de données (pagination)
  const loadMore = useCallback(() => {
    if (state.hasMore && !state.isLoading) {
      getUploadHistory(state.page + 1);
    }
  }, [state.hasMore, state.isLoading, state.page, getUploadHistory]);

  // Effet pour charger les données au montage et lors du changement de période
  useEffect(() => {
    // Invalider le cache lors du changement de période
    calculationCache.clear();
    
    // Supprimer également les données en cache dans sessionStorage
    Object.keys(sessionStorage).forEach(key => {
      if (key.startsWith('activity-')) {
        sessionStorage.removeItem(key);
      }
    });
    
    // Forcer le rechargement des données avec la nouvelle période
    getUploadHistory(1);
  }, [state.dateRange, getUploadHistory]);

  // Export CSV
  const handleCSVExport = useCallback(async () => {
    try {
      const report = await userApi.getCSVConsumptionReport(
        "csv",
        props.user.id
      );
      handleReportDownload(report);
    } catch (error) {
      console.error("Erreur lors de l'export CSV:", error);
      setState(prevState => ({
        ...prevState,
        error: "Erreur lors de l'export du rapport"
      }));
    }
  }, [props.user.id]);

  // Téléchargement du rapport
  const handleReportDownload = useCallback((reportCSV: ArrayBuffer) => {
    const reportDate = new Date();
    const DOMid = `filevert-consommation_${reportDate.getDate()}-${
      reportDate.getMonth() + 1
    }-${reportDate.getFullYear()}`;

    let link: any = document.getElementById(DOMid);

    if (link) return link.click();

    const url = window.URL.createObjectURL(new Blob([reportCSV]));

    link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("id", DOMid);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", `${DOMid}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }, []);

  // Memoisation des propriétés d'activité pour éviter les re-renders inutiles
  const activities = useMemo(() => [
    {
      icon: <Cloud className="w-6 h-6 text-accent" />,
      value: convertSize(state.currentConsumption.sizeIn0).split(" ")[0],
      label: "weight",
      suffix: convertSize(state.currentConsumption.sizeIn0).split(" ")[1],
      tooltipContent: i18n._("tab_activity_weight_unit")
    },
    {
      icon: <Zap className="w-6 h-6 text-secondary" />,
      value: state.currentConsumption.kWh.toFixed(2),
      label: "electricity",
      suffix: "kWh",
      tooltipContent: i18n._("tab_activity_electricity_unit")
    },
    {
      icon: <Leaf className="w-6 h-6 text-primary" />,
      value: convertGCO2(state.currentConsumption.gCO2).split(" ")[0],
      label: "carbon",
      suffix: convertGCO2(state.currentConsumption.gCO2).split(" ")[1],
      tooltipContent: i18n._("tab_activity_carbon_unit")
    },
    {
      icon: <Droplet className="w-6 h-6 text-accent" />,
      value: (state.currentConsumption.kWh * WATER_RATIO).toFixed(2),
      label: "L",
      suffix: "L",
      tooltipContent: i18n._("tab_activity_L_unit")
    },
    {
      icon: <Car className="w-6 h-6 text-gray-200" />,
      value: state.currentConsumption.kms.toFixed(2),
      label: "km",
      suffix: "km",
      tooltipContent: i18n._("tab_activity_km_unit")
    },
  ], [state.currentConsumption]);

  // Memoisation des propriétés d'activité d'équipe
  const teamActivities = useMemo(() => [
    {
      icon: <Cloud className="w-6 h-6 text-accent" />,
      value: convertSize(state.currentTeamConsumption.sizeIn0).split(" ")[0],
      label: "weight",
      suffix: convertSize(state.currentTeamConsumption.sizeIn0).split(" ")[1],
      tooltipContent: i18n._("tab_activity_weight_unit")
    },
    {
      icon: <Zap className="w-6 h-6 text-secondary" />,
      value: state.currentTeamConsumption.kWh.toFixed(2),
      label: "electricity",
      suffix: "kWh",
      tooltipContent: i18n._("tab_activity_electricity_unit")
    },
    {
      icon: <Leaf className="w-6 h-6 text-primary" />,
      value: convertGCO2(state.currentTeamConsumption.gCO2).split(" ")[0],
      label: "carbon",
      suffix: convertGCO2(state.currentTeamConsumption.gCO2).split(" ")[1],
      tooltipContent: i18n._("tab_activity_carbon_unit")
    },
    {
      icon: <Droplet className="w-6 h-6 text-accent" />,
      value: (state.currentTeamConsumption.kWh * WATER_RATIO).toFixed(2),
      label: "L",
      suffix: "L",
      tooltipContent: i18n._("tab_activity_L_unit")
    },
    {
      icon: <Car className="w-6 h-6 text-gray-200" />,
      value: state.currentTeamConsumption.kms.toFixed(2),
      label: "km",
      suffix: "km",
      tooltipContent: i18n._("tab_activity_km_unit")
    },
  ], [state.currentTeamConsumption]);

  // Message d'accueil dynamique
  const greetingMessage = useMemo(() => {
    const currentHour = new Date().getHours();
    return `${i18n._(currentHour >= 18 || currentHour < 5 ? "tab_greetings_evening" : "tab_greetings_morning")}, ${props.user.first_name}`;
  }, [props.user.first_name]);

  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto relative">
      <FolderLoader isLoading={state.isLoading} />
      
      {/* Afficher un message d'erreur si nécessaire */}
      {state.error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <p>{state.error}</p>
          <button 
            onClick={() => getUploadHistory(1)}
            className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-sm"
          >
            {i18n._("retry_button")}
          </button>
        </div>
      )}
      
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-primary">{greetingMessage}</h1>
        <div className="flex flex-col items-start gap-4 w-full sm:w-auto">
          <div className="flex items-center gap-2 w-full sm:w-auto">
            <span className="hidden sm:inline text-sm text-gray-400 dark:text-neutral-400">
              {i18n._("period_label")}
            </span>
            <select
              value={state.dateRange}
              onChange={(e) => handleDateRangeChange(e.target.value as 'current_year' | 'last_year' | 'all')}
              className="py-1.5 sm:py-2 px-2 sm:px-3 pe-6 sm:pe-9 block text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 w-full sm:w-auto"
            >
              <option value="current_month">{i18n._("current_month_option")}</option>
              <option value="current_year">{i18n._("current_year_option")}</option>
              <option value="last_year">{i18n._("previous_year_option")}</option>
              <option value="all">{i18n._("all_history_option")}</option>
            </select>
          </div>
          
          <div className="flex flex-wrap gap-2 sm:gap-4 w-full sm:w-auto">
            {props.user?.permissions?.consumption_export?.value && !props.user.referer_id && (
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 w-full sm:w-auto">
                <button
                  onClick={handleCSVExport}
                  className="border border-primary text-primary hover:bg-primary/10 font-medium py-1.5 sm:py-2 px-3 sm:px-4 rounded whitespace-nowrap transition-colors text-sm sm:text-base flex-1 sm:flex-none flex items-center justify-center gap-2"
                >
                  <FileText className="w-4 h-4" />
                  <span className="font-medium">
                    {i18n._("export_report_label")}
                  </span>
                </button>

                {!process.env.REACT_APP_DISABLE_TRIAL_CHECK && props.user.subscriptions?.data?.some((subscription: { status: string }) => subscription.status === "trialing") ? (
                  <Tooltip
                    content={i18n._("trial_rse_report_tooltip")}
                    position="bottom"
                  >
                    <button
                      className="bg-gray-400 text-white font-bold py-2 px-4 rounded flex items-center gap-2 cursor-not-allowed"
                      disabled
                    >
                      <Lock className="w-4 h-4" />
                      {i18n._("generate_rse_report")}
                    </button>
                  </Tooltip>
                ) : (
                  <RSEReport
                    savedConsumption={state.savedConsumption}
                    currentConsumption={state.currentConsumption}
                    dateRange={state.dateRange}
                    usageStats={state.usageStats}
                    currentTeamConsumption={state.currentTeamConsumption}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="space-y-12">
        {/* Statistiques d'utilisation */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
            <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
            {i18n._("statistiques_utilisation_title")}
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {/* Bloc 1: Volumes envoyés/téléchargés */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5 space-y-4">
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("volume_sent")}</p>
                    <span className="text-accent"><Cloud className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const size = convertSize(state.savedConsumption.activity_transfered_label);
                      const [value, unit] = size.split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("volume_downloaded")}</p>
                    <span className="text-primary"><Cloud className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertSize(state.usageStats.downloadVolume).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Bloc 2: Volume reçu et révisions */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5 space-y-4">
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("reception_volume")}</p>
                      <Tooltip content={i18n._("volume_recu_tooltip")}>
                        <HelpCircle className="w-4 h-4 text-gray-500" />
                      </Tooltip>
                    </div>
                    <span className="text-accent"><Cloud className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertSize(state.usageStats.receptionVolume).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("revisions_count")}</p>
                      <Tooltip content={i18n._("nombre_revisions_tooltip")}>
                        <HelpCircle className="w-4 h-4 text-gray-500" />
                      </Tooltip>
                    </div>
                    <span className="text-accent"><FileText className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {state.usageStats.revisionCount}
                  </h3>
                </div>
              </div>
            </div>

            {/* Bloc 3: Ratios d'utilisation */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5 space-y-4">
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("downloads_ratio")}</p>
                      <Tooltip content={i18n._("telechargements_tooltip")}>
                        <HelpCircle className="w-4 h-4 text-gray-500" />
                      </Tooltip>
                    </div>
                    <span className="text-secondary"><FileText className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {state.usageStats.downloadRatio === -1 ? i18n._("not_available") : `${state.usageStats.downloadRatio.toFixed(1)}%`}
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("auto_deletion")}</p>
                      <Tooltip content={i18n._("auto_suppression_tooltip")}>
                        <HelpCircle className="w-4 h-4 text-gray-500" />
                      </Tooltip>
                    </div>
                    <span className="text-primary"><Leaf className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {state.usageStats.autoRemoveRatio === -1 ? i18n._("not_available") : `${state.usageStats.autoRemoveRatio.toFixed(1)}%`}
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-x-2">
                      <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("password_protection")}</p>
                      <Tooltip content={i18n._("protection_mdp_tooltip")}>
                        <HelpCircle className="w-4 h-4 text-gray-500" />
                      </Tooltip>
                    </div>
                    <span className="text-warning"><Lock className="w-6 h-6" /></span>
                  </div>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {state.usageStats.passwordProtectedRatio === -1 ? i18n._("not_available") : `${state.usageStats.passwordProtectedRatio.toFixed(1)}%`}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section Résumé */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
            <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
            {i18n._("impact_summary_title")}
          </h2>

          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
            {/* Transferts */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">
                    {i18n._("tab_activity_weight_label")}
                  </p>
                  <Tooltip content={i18n._("transfers_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-accent"><Cloud className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      // Somme du volume envoyé et téléchargé
                      const totalVolume = state.savedConsumption.activity_transfered_label + state.usageStats.downloadVolume;
                      const [value, unit] = convertSize(totalVolume).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Économies serveurs */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">
                    {i18n._("server_savings_label_year")}
                  </p>
                  <Tooltip content={i18n._("server_savings_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-primary"><Leaf className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertGCO2(state.savedConsumption.activity_saved_by_pue_label).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Stockage sauvé */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">
                    {i18n._("saved_storage_label")}
                  </p>
                  <Tooltip content={i18n._("saved_storage_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-accent"><Zap className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertGCO2(state.savedConsumption.activity_saved_by_year_label).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Éco-compensés */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">
                    {i18n._("eco_compensated_label")}
                  </p>
                  <Tooltip content={i18n._("eco_compensated_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-accent"><Droplet className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertGCO2(state.savedConsumption.activity_compensated_label).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Économies FileVert avec impact */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
            <span className="w-2 h-8 bg-accent rounded-r mr-3"></span>
            {i18n._("economie_filevert_title")}
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-6">
            {/* Économies serveurs */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">
                    {i18n._("server_savings_label")}
                  </p>
                  <Tooltip content={i18n._("server_savings_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-primary"><Leaf className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertGCO2(state.serverSavings).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Émissions CO2 économisées */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("co2_saved")}</p>
                  <Tooltip content={i18n._("co2_economise_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-primary"><Leaf className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const [value, unit] = convertGCO2(state.filevertConsumption.co2_reduced * 1000).split(' ');
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Énergie économisée */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("energy_saved")}</p>
                  <Tooltip content={i18n._("energie_economisee_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-secondary"><Zap className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const value = state.filevertConsumption.energy_saved > 1000
                        ? (state.filevertConsumption.energy_saved / 1000).toFixed(2)
                        : state.filevertConsumption.energy_saved.toFixed(2);
                      const unit = state.filevertConsumption.energy_saved > 1000 ? "MWh" : "kWh";
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Eau économisée */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("water_saved")}</p>
                  <Tooltip content={i18n._("eau_economisee_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-accent"><Droplet className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const value = state.filevertConsumption.water_saved > 1000
                        ? (state.filevertConsumption.water_saved / 1000).toFixed(2)
                        : state.filevertConsumption.water_saved.toFixed(2);
                      const unit = state.filevertConsumption.water_saved > 1000 ? i18n._("cubic_meters") : i18n._("liters");
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>

            {/* Équivalent voiture */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500">{i18n._("tab_activity_km_label")}</p>
                  <Tooltip content={i18n._("equivalent_voiture_tooltip")}>
                    <HelpCircle className="w-4 h-4 text-gray-500" />
                  </Tooltip>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <span className="text-gray-200"><Car className="w-6 h-6" /></span>
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const value = state.filevertConsumption.car_equivalent.toFixed(2);
                      const unit = "km";
                      return (
                        <>
                          {value}
                          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400 font-medium">{unit}</span>
                        </>
                      );
                    })()}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bloc: Consommation standard (anciennement Activités personnelles) */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
            <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
            {i18n._("consommation_standard_title")} 
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-6">
            {activities.map((item: ItemProps, index: number) => (
              <ActivityItem 
                key={`activity-${index}`}
                icon={item.icon}
                value={item.value}
                label={item.label}
                suffix={item.suffix}
                tooltipContent={item.tooltipContent}
              />
            ))}
          </div>
        </section>

        {/* Activités d'équipe */}
        {props.user.role > 1 && !props.user.referer_id && !!props.user?.permissions?.team_size?.value && (
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-accent rounded-r mr-3"></span>
              {i18n._("team_tracking_label")}
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-6">
              {teamActivities.map((item: ItemProps, index: number) => (
                <ActivityItem 
                  key={`team-activity-${index}`}
                  icon={item.icon}
                  value={item.value}
                  label={item.label}
                  suffix={item.suffix}
                  tooltipContent={item.tooltipContent}
                />
              ))}
            </div>
          </section>
        )}

        {/* Le bouton "Load More" a été supprimé car toutes les données sont déjà chargées en une seule fois */}
      </div>
    </div>
  );
}

// Connect le composant fonctionnel au store Redux
const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(ActivityComponent);