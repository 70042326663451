import React from "react";
import { ChevronDown } from "lucide-react";
import { i18n } from "../../utils";
import { userApi } from "../../Api";

interface Props {
  cb: Function;
  role: number;
  user?: any;
  period?: string;
  prices?: any;
}

interface TooltipProps {
  text: string;
}

interface State {
  form: {
    role: number;
    lastName: string;
    firstName: string;
    organization: string;
    job: string;
    tva_number: string;
    email: string;
    country: string;
    city: string;
    street: string;
    zipCode: string;
    password: string;
    confirmPassword: string;
    nb_users: number;
    cgu: boolean;
  };
  isValid: boolean;
}

const qtyOptions = [1, 3, 5, 10, 20, 30];

class RegisterformComponent extends React.Component<Props, State & any> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      form: {
        lastName: "",
        firstName: "",
        organization: "",
        job: "",
        tva_number: "",
        email: "",
        country: "",
        city: "",
        street: "",
        zipCode: "",
        password: "",
        confirmPassword: "",
        role: this.props.role,
        cgu: false,
        nb_users: 1,
      },
      isValid: false,
    };
  }

  async handleChange(evt: any) {
    let isValid = true;
    let { form } = this.state;
    const passwordField = document.querySelector('input[name="password"]') as HTMLInputElement;
    const confirmPassField = document.querySelector(
      'input[name="confirmPassword"]'
    ) as HTMLInputElement;
    const value =
      evt.target.name === "cgu" ? evt.target.checked : evt.target.value;

    form[evt.target.name] = value;

    if (!this.props.user || !this.props.user.status) {
      for (let k in form) {
        if (!form[k]) {
          isValid = false;
          break;
        }
      }
      if (
        form.password &&
        form.confirmPassword &&
        confirmPassField &&
        passwordField
      ) {
        if (
          !form.password.match(
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/
          )
        ) {
          passwordField.setCustomValidity('invalid');
          passwordField.classList.add("is-invalid");
          passwordField.classList.remove("is-valid");
        } else {
          passwordField.setCustomValidity('');
          passwordField.classList.remove("is-invalid");
          passwordField.classList.add("is-valid");
        }

        if (form.password !== form.confirmPassword) {
          confirmPassField.setCustomValidity('invalid');
          confirmPassField.classList.add("is-invalid");
          confirmPassField.classList.remove("is-valid");
        } else {
          confirmPassField.setCustomValidity('');
          confirmPassField.classList.remove("is-invalid");
          confirmPassField.classList.add("is-valid");
        }
      }
    }

    this.setState({ isValid, form });
  }

  handleSubmit = async (evt: Event & any) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.className += " was-validated";

    let errors = [];
    let { form, isValid } = this.state;

    const isEmailAvailable = await userApi.checkEmail(form.email);
    const emailField = document.querySelector('input[name="email"]') as HTMLInputElement;

    if ((!isEmailAvailable || isEmailAvailable.error) && emailField) {
      isValid = false;
      emailField.setCustomValidity('invalid');
      emailField.classList.add("is-invalid");
      emailField.classList.remove("is-valid");
    } else if (emailField) {
      emailField.setCustomValidity('');
      emailField.classList.remove("is-invalid");
      emailField.classList.add("is-valid");
    }

    if (!form.cgu) errors.push("cgu");

    for (let k in form)
      if (
        !form[k] &&
        (!this.props.user || !this.props.user.status || k === "cgu")
      )
        errors.push(k);

    if (!this.props.user || !this.props.user.status)
      if (form.password !== form.confirmPassword)
        errors.push("confirmPassword");

    const submitField = document.querySelector('button[type="submit"]') as HTMLButtonElement;

    if (!isValid && submitField) {
      submitField.setCustomValidity('invalid');
      submitField.classList.add("is-invalid");
      submitField.classList.remove("is-valid");
      return false;
    } else if (submitField) {
      submitField.setCustomValidity('');
      submitField.classList.add("is-valid");
      submitField.classList.remove("is-invalid");
    }

    if (isValid) this.props.cb(form);
  };

  render = () => {
    const { role, prices, user, period } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      job,
      organization,
      tva_number,
      cgu,
      city,
      country,
      street,
      zipCode,
      nb_users,
    } = this.state.form;

    return (
      <div className="container mx-auto px-4 fv_register_form_container">
        <div className="flex flex-wrap">
          <div className="w-full px-0">
            <form
              noValidate
              className="container text-left needs-validation fv_register_form_form"
              onSubmit={this.handleSubmit}
            >
              <hr className="mt-3" />
              {(!user || !user.status) && (
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input
                      required
                      className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder={i18n._("form_profil_first_name_label")}
                      onChange={this.handleChange}
                      type="text"
                      autoComplete="given-name"
                      name="firstName"
                      value={firstName}
                    />
                    <div className="invalid-feedback text-red-500 text-xs">
                      {i18n._("form_profil_first_name_invalid")}
                    </div>
                    <div className="valid-feedback test-emerald-500 text-xs">
                      {i18n._("form_profil_first_name_valid")}
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 px-3">
                    <input
                      required
                      className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder={i18n._("form_profil_last_name_label")}
                      onChange={this.handleChange}
                      type="text"
                      autoComplete="family-name"
                      name="lastName"
                      value={lastName}
                    />
                    <div className="invalid-feedback text-red-500 text-xs">
                      {i18n._("form_profil_last_name_invalid")}
                    </div>
                    <div className="valid-feedback test-emerald-500 text-xs">
                      {i18n._("form_profil_last_name_valid")}
                    </div>
                  </div>

                  <div className="w-full px-3">
                    <input
                      required
                      className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder={i18n._("form_profil_email_label")}
                      onChange={this.handleChange}
                      type="email"
                      autoComplete="email"
                      name="email"
                      value={email}
                    />
                    <div className="invalid-feedback text-red-500 text-xs">
                      {i18n._("form_profil_email_invalid")}
                    </div>
                    <div className="valid-feedback test-emerald-500 text-xs">
                      {i18n._("form_profil_email_valid")}
                    </div>
                  </div>
                </div>
              )}

              {(!user || !user.status) && (
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3 mb-6">
                    <input
                      required
                      className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder={i18n._("form_profil_password_label")}
                      onChange={this.handleChange}
                      type="password"
                      name="password"
                      value={password}
                    />
                    <div className="invalid-feedback text-red-500 text-xs">
                      {i18n._("form_profil_password_invalid")}
                    </div>
                    <div className="valid-feedback test-emerald-500 text-xs">
                      {i18n._("form_profil_password_valid")}
                    </div>
                  </div>
                  <div className="w-full px-3">
                    <input
                      required
                      className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder={i18n._("form_profil_password_confirmation_label")}
                      onChange={this.handleChange}
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                    />
                    <div className="invalid-feedback text-red-500 text-xs">
                      {i18n._("form_profil_password_confirmation_invalid")}
                    </div>
                    <div className="valid-feedback test-emerald-500 text-xs">
                      {i18n._("form_profil_password_confirmation_valid")}
                    </div>
                  </div>
                </div>
              )}

              {/* Organization and Job fields */}
{1 < role && (!user || !user.status) && (
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <input
        required
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_organization_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="organization"
        name="organization"
        value={organization}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_organization_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_organization_valid")}
      </div>
    </div>
    <div className="w-full md:w-1/2 px-3">
      <input
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_job_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="job"
        name="job"
        value={job}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_job_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_job_valid")}
      </div>
    </div>
  </div>
)}

{/* TVA and Address section */}
{(!user || !user.status) && (
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3 mb-6">
      <input
        required
        maxLength={20}
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_tva_number_label")}
        onChange={this.handleChange}
        type="text"
        name="tva_number"
        value={tva_number}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_tva_number_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_tva_number_valid")}
      </div>
    </div>
    
    <div className="w-full px-3 mb-6">
      <input
        required
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_address_street_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="address-line1"
        name="street"
        value={street}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_address_street_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_address_street_valid")}
      </div>
    </div>

    <div className="w-full md:w-5/12 px-3 mb-6">
      <input
        required
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_address_zipcode_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="postal-code"
        name="zipCode"
        value={zipCode}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_address_zipcode_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_address_zipcode_valid")}
      </div>
    </div>

    <div className="w-full md:w-7/12 px-3 mb-6">
      <input
        required
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_address_city_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="address-level2"
        name="city"
        value={city}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_address_city_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_address_city_valid")}
      </div>
    </div>

    <div className="w-full px-3">
      <input
        required
        className="appearance-none block w-full bg-gray-50 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        placeholder={i18n._("form_profil_address_country_label")}
        onChange={this.handleChange}
        type="text"
        autoComplete="country-name"
        name="country"
        value={country}
      />
      <div className="invalid-feedback text-red-500 text-xs">
        {i18n._("form_profil_address_country_invalid")}
      </div>
      <div className="valid-feedback test-emerald-500 text-xs">
        {i18n._("form_profil_address_country_valid")}
      </div>
    </div>
  </div>
)}

              {role > 1 && (
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    {prices && (
                      <SelectTiers
                        value={nb_users}
                        disabled={!user || !user.status}
                        period={period}
                        prices={prices}
                        onChange={this.handleChange}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="flex items-center mb-6">
                <input
                  required
                  id="checkbox4"
                  type="checkbox"
                  name="cgu"
                  checked={cgu}
                  onChange={this.handleChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor="checkbox4" className="ml-2 text-sm text-gray-900">
                  {i18n._("accept_cgu_label")}
                </label>
              </div>

              <div className="flex justify-center">
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {i18n._("btn_valid_label")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
}
const TooltipComponent: React.FC<TooltipProps> = ({ text }) => {
  return (
    <div className="absolute z-10 p-2 text-sm text-gray-100 bg-gray-800 rounded shadow-lg">
      {text}
    </div>
  );
};

const renderOption = (up_to: number, price: number): string =>
  up_to > 1
    ? i18n._("more_users_quantity_label", [up_to, price.toFixed(2)])
    : i18n._("unique_user_label");

    const SelectTiers = ({ onChange, period, prices, disabled, value }: any) => {
      const price = (prices.year / 12) * value;
    
      return (
        <div className="relative">
          <select
            className="block appearance-none w-full bg-gray-50 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            disabled={disabled}
            name="nb_users"
            onChange={onChange}
            value={value}
          >
            {qtyOptions.map((el: number, i: number) => (
              el ? (
                <option key={i} value={el}>
                  {renderOption(el, (prices.year / 12) * el)}
                </option>
              ) : null
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <ChevronDown className="h-4 w-4" />
          </div>
          {disabled && <TooltipComponent text={i18n._("pro_team_explaination") || ""} />}
        </div>
      );
    };

export default RegisterformComponent;
