// SubscriptionComponent.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Eye,
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  Users,
  CreditCard,
  Receipt
} from 'lucide-react';
import UsageInvoiceReport from './UsageInvoiceReport';
import ActionInputComponent from '../../../Button/ActionInputComponent';
import Toast from '../../../Toast/Toast';
import PermissionsCard from '../Permissions/PermissionsCard';
import FolderLoader from "../../../FolderLoader/FolderLoader";
import UpgradeButton from '../../../Buttons/UpgradeButton';
import UsageStats from "../../../UsageStats/UsageStats";
import GroupUsageStats from "../../../GroupUsageStats/GroupUsageStats";
import { formatDate, i18n, userCanInviteMember } from '../../../../utils/';
import { addIntervalToPermissions } from '../../../../utils/subscriptionUtils';
import { invoiceApi, userApi, usageApi } from '../../../../Api';
import * as userAction from '../../../../Store/Actions/userAction';
import {
  TableMembersComponent,
  TableSubscriptionsComponent,
} from '../../../TableList';

// Fonction pour traduire les descriptions des factures
const translateInvoiceDescription = (description: string): string => {
  if (description.includes('Période d\'essai pour FileVert - Base')) {
    return i18n._('invoice_trial_period_translated');
  } else if (description.toLowerCase().includes('subscription')) {
    return i18n._('invoice_subscription');
  } else if (description.toLowerCase().includes('prorata') || description.toLowerCase().includes('proration')) {
    return i18n._('invoice_proration');
  }
  return description;
};

// Types et interfaces
interface User {
  id: string;
  role: number;
  email?: string;
  referer_id?: string;
  members_id?: string[];
  subscriptions?: SubscriptionData[];
  invitation_link?: string;
  customer_id?: string;
  organization?: string;
  permissions?: {
    team_size?: { value: number; id: number };
    upload_size?: { value: number; id: number };
    upload_ttl?: { value: number; id: number };
    customization?: { value: boolean; id: number };
    transfer_notification?: { value: boolean; id: number };
    transfer_authentication?: { value: boolean; id: number };
    consumption_export?: { value: boolean; id: number };
    outlook_addin?: { value: boolean; id: number };
    studio?: { value: boolean; id: number };
    transfer_tracking?: { value: boolean; id: number };
    interval?: { value: string; id: number };
  };
}

interface SubscriptionData {
  id: string;
  name: string;
  qty: number;
  customer: string;
  current_period_start: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
  status: string;
  metadata?: {
    is_groupe_subscription?: string;
    is_usage_subscription?: string;
    [key: string]: string | undefined;
  };
}

interface Subscription extends SubscriptionData {
  isActive: boolean;
}

interface LineItem {
  description: string;
  amount: number;
  type?: string;
  period?: { start: number; end: number };
}

interface CreditNote {
  amount: number;
  reason: string;
  memo?: string;
}

interface Invoice {
  lines: { data: LineItem[] };
  period_start: number;
  period_end: number;
  total: number;
  subtotal: number;
  amount_due?: number;
  hosted_invoice_url: string;
  starting_balance?: number;
  ending_balance?: number;
  amount_refunded?: number;
  credit_notes?: { data: CreditNote[] };
}

interface Customer {
  subscriptions?: { data?: SubscriptionData[] };
}

interface Referer {
  email: string;
  first_name: string;
  last_name: string;
}

interface ErrorState {
  code: string;
  message: string;
}

interface Admin {
  name: string;
  email: string;
  organization: string;
}

interface State {
  invoices: Invoice[];
  customer: Customer | null;
  members: any[];
  referer: Partial<Referer>;
  error: ErrorState | null;
  rowsPerPage: number;
  page: number;
  isLoading: boolean;
  successCopyLink: boolean;
  modalOpen: boolean;
  admin?: Admin;
}

interface Props {
  dispatch: (action: any) => void;
  user: User;
  title?: string;
}

interface MainData {
  invoices: Invoice[];
  customer: Customer | null;
  members: any[];
  admin?: Admin;
}

interface RefererData {
  referer: Partial<Referer>;
  admin?: Admin;
}

interface BaseData extends MainData {
  admin?: Admin;
}

const SubscriptionComponent: React.FC<Props> = ({ dispatch, user }) => {
  const [state, setState] = useState<State>({
    invoices: [],
    customer: null,
    members: [],
    referer: {},
    error: null,
    rowsPerPage: 5,
    page: 0,
    isLoading: true,
    successCopyLink: false,
    modalOpen: false
  });

  const { invoices, customer, members, successCopyLink, page, rowsPerPage } = state;

  const isGroupSubscription = useMemo(() => {
    return user?.permissions?.team_size?.value === -1;
  }, [user?.permissions?.team_size?.value]);

  const loadMainData = React.useCallback(async (userId: string, hasMembers: boolean): Promise<MainData> => {
    console.log('Loading main data for user:', userId, 'hasMembers:', hasMembers);
    const dataPromises = [
      invoiceApi.getInvoices(userId).catch(error => {
        console.warn('Erreur lors du chargement des factures:', error);
        return [];
      }),
      invoiceApi.getCustomer(userId).catch(error => {
        console.warn('Erreur lors du chargement du client:', error);
        return null;
      })
    ];
    if (hasMembers) {
      dataPromises.push(
        userApi.getTeamMembers(userId).catch(error => {
          console.warn('Erreur lors du chargement des membres:', error);
          return [];
        })
      );
    }
    try {
      const results = await Promise.race([
        Promise.all(dataPromises),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000))
      ]) as [Invoice[], Customer, any[]];
      return {
        invoices: results[0] || [],
        customer: results[1],
        members: results[2] || [],
      };
    } catch (error) {
      console.error('Erreur lors du chargement des données:', error);
      return { invoices: [], customer: null, members: [] };
    }
  }, []);

  const loadUserData = React.useCallback(async (signal: AbortSignal): Promise<void> => {
    if (!user?.id) return;
    console.log('Loading user data for:', user);
    try {
      // Ne charger que les données principales, les infos admin viendront des stats
      const baseData = await loadMainData(user.id, Boolean(user.members_id?.length));
      if (signal.aborted) return;
      setState(prev => ({ ...prev, ...baseData, isLoading: false, error: null }));
    } catch (error: any) {
      if (!signal.aborted) {
        setState(prev => ({
          ...prev,
          error: {
            code: 'LOAD_ERROR',
            message: error.message === 'Timeout' 
              ? i18n._('loading_timeout_error')
              : i18n._('loading_data_error')
          },
          isLoading: false,
          invoices: [],
          customer: null,
          members: []
        }));
      }
    }
  }, [user?.id, user?.referer_id, user?.members_id?.length, loadMainData, user]);

  useEffect(() => {
    const abortController = new AbortController();
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    loadUserData(abortController.signal);
    return () => abortController.abort();
  }, [loadUserData]);

  if (!user || !user.id) {
    return (
      <div className="max-w-[85rem] min-h-screen px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="text-gray-500 dark:text-gray-400">
            {i18n._("loading_user_data")}
          </div>
        </div>
      </div>
    );
  }

  // Affichage spécifique pour les membres de groupe
  if (user.role === 1) {
    return (
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="space-y-8">
          {/* Header avec info admin */}
          <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
              <div>
                <h1 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
                  <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
                  {i18n._("filevert_group_member")}
                </h1>
                <div className="space-y-2">
                  {state.admin && (
                    <>
                      <p className="text-gray-500 dark:text-gray-400">
                        <strong>{i18n._("administrator_label")}:</strong> {state.admin.name}
                      </p>
                      <p className="text-gray-500 dark:text-gray-400">
                        <strong>{i18n._("email_label")}:</strong> {state.admin.email}
                      </p>
                      <p className="text-gray-500 dark:text-gray-400">
                        <strong>{i18n._("organization_label")}:</strong> {state.admin.organization}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Permissions en lecture seule */}
          {user.permissions && (
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
                <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                {i18n._("permissions_label")}
              </h2>
              <PermissionsCard
                permissions={addIntervalToPermissions(user.permissions, user)}
                readOnly={true}
                user_id={user.id}
              />
            </div>
          )}

          {/* Stats personnelles */}
          <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
              {i18n._("personal_stats")}
            </h2>
            <UsageStats onStatsLoaded={(stats: any) => {
              if (stats.admin && !state.admin) {
                setState(prev => ({
                  ...prev,
                  admin: {
                    name: stats.admin.name,
                    email: stats.admin.email,
                    organization: stats.admin.organization
                  }
                }));
              }
            }} />
          </div>
        </div>

        {/* Toasts */}
        <Toast
          type="error"
          message={state.error?.message || ''}
          isVisible={!!state.error}
          duration={5000}
        />
        <Toast
          type="success"
          message={i18n._('upload_finished_copied_label')}
          isVisible={successCopyLink}
          duration={2000}
        />
      </div>
    );
  }

  const handleManageBilling = async () => {
    try {
      if (!user?.id) throw new Error(i18n._('user_not_identified'));
      if (user.role > 2) throw new Error(i18n._('billing_permission_error'));

      // Récupérer les informations de l'abonnement actuel
      const currentSubscription = customer?.subscriptions?.data?.[0];
      if (!currentSubscription) throw new Error(i18n._('no_subscription_found'));

      // Enrichir l'objet user avec les informations nécessaires
      const enrichedUser = {
        ...user,
        customer_id: currentSubscription.customer,
        subscriptionStatus: currentSubscription.status,
        email: user.email || ''
      };

      const result = await userApi.manageBilling(enrichedUser);
      if (!result?.url) throw new Error(i18n._('billing_url_not_available'));
      
      window.location.href = result.url;
    } catch (err: any) {
      console.error('Error in handleManageBilling:', err);
      setState(prev => ({
        ...prev,
        error: { code: 'API_ERROR', message: err.message || i18n._('billing_management_error') }
      }));
    }
  };

  const team_size = user?.permissions?.team_size?.value;
  const hasPendingSubscription = customer?.subscriptions?.data?.some(
    (sub: SubscriptionData) => sub.cancel_at_period_end
  );

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="space-y-8">
          {/* Header */}
        <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <div>
              <h1 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
                <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
                {i18n._("filevert")} {user.role > 1 && !user.referer_id && team_size ? i18n._("group") : ''}
              </h1>
              {userCanInviteMember(user) && team_size ? (
                <p className="text-gray-500 dark:text-gray-400 flex items-center">
                  <Users className="mr-2" size={20} />
                  <span>
                    {team_size === -1 ? (
                      i18n._("unlimited_members_count", [members?.length || 0])
                    ) : (
                      i18n._("members_count", [members?.length || 0, team_size])
                    )}
                  </span>
                </p>
              ) : null}
            </div>
            {userCanInviteMember(user) ? (
              <div className="w-full md:w-auto">
                {state.isLoading ? (
                  <div className="w-full md:w-96 px-4 py-2 bg-gray-50 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 rounded-lg text-gray-500">
                    {i18n._("loading_invitation_link")}
                  </div>
                ) : user.invitation_link ? (
                  <div className="space-y-2">
                    <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {i18n._("invitation_link_label")}
                    </div>
                    <ActionInputComponent
                      value={user.invitation_link}
                      onCopy={(copied) => {
                        if (copied === true) {
                          setState(prev => ({ ...prev, successCopyLink: true }));
                          setTimeout(() => setState(prev => ({ ...prev, successCopyLink: false })), 1500);
                        }
                      }}
                      mailTo={{
                        enabled: true,
                        subject: i18n._('invitation_email_subject'),
                        body: i18n._('invitation_email_body', [user.invitation_link])
                      }}
                      navigateTo={{ enabled: false }}
                      customClass="md:w-96"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        {/* Permissions et Membres */}
        {!user.referer_id && user.permissions ? (
          <PermissionsCard
            permissions={addIntervalToPermissions(user.permissions, user)}
            readOnly={user.role === 1}
            user_id={user.id} // S'assurer que user.id existe
            key={user.id} // Ajouter une clé pour forcer la mise à jour
          />
        ) : null}
        {user.permissions?.team_size?.value && !user.referer_id ? (
          <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
              <Users className="mr-2" /> {i18n._("team_members")}
            </h2>
            <TableMembersComponent members={members} user_id={user.id} />
          </div>
        ) : null}

        {/* Abonnements */}
        {!!(user.role && customer && !user.referer_id) ? (
          <div className="space-y-6">
            <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
                <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                <CreditCard className="mr-2" /> {i18n._("subscriptions_label")}
              </h2>
              <TableSubscriptionsComponent
                subscriptions={customer?.subscriptions?.data ? customer.subscriptions.data.map(sub => ({
                  ...sub,
                  isActive: !sub.cancel_at_period_end && (sub.status === 'active' || sub.status === 'trialing'),
                  metadata: sub.metadata || {}
                })) : []}
                dispatch={dispatch}
                user_id={user.id}
                permissions={user.permissions}
              />
            </div>
          </div>
        ) : null}

        {/* Stats - Afficher GroupUsageStats pour les admins et UsageStats pour les membres */}
        <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
              <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
              <Users className="mr-2" />
              {isGroupSubscription && user.role !== 1 ? i18n._("team_statistics") : i18n._("personal_stats")}
            </h2>
            {isGroupSubscription && user.role !== 1 ? (
              <UsageInvoiceReport dateRange="current_month" />
            ) : null}
          </div>
          {isGroupSubscription && user.role !== 1 ? <GroupUsageStats /> : <UsageStats />}
        </div>

        {/* Tableau des Factures */}
        <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 flex items-center">
              <span className="w-2 h-8 bg-accent rounded-r mr-3"></span>
              <Receipt className="mr-2" /> {i18n._('invoices_table_title')}
            </h2>
            <button
              onClick={handleManageBilling}
              className="bg-primary hover:bg-primary/80 text-white font-bold py-2 px-4 rounded transition-colors"
            >
              {i18n._('user_manage_billing_label')}
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-200 dark:border-neutral-700">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">{i18n._("description_label")}</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">{i18n._("period_label")}</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">{i18n._("amount_label")}</th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">{i18n._("actions_label")}</th>
                </tr>
              </thead>
              <tbody>
                {invoices.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((inv, idx) => (
                  <tr key={idx} className="border-b border-gray-200 dark:border-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-700/50">
                    <td className="px-4 py-4 text-sm">
                      <div className="space-y-1">
                        {inv.lines.data.map((line, k) => {
                          const isProration = line.description.toLowerCase().includes('prorata');
                          const amountInEuros = (Math.abs(line.amount) / 100).toFixed(2);
                          const sign = line.amount < 0 ? '-' : '+';

                          return (
                            <div key={k} className={`${isProration ? 'bg-gray-50 dark:bg-neutral-700/50 p-2 rounded' : ''}`}>
                              <div className={`flex justify-between items-start ${isProration ? 'border-l-4 border-primary pl-2' : ''}`}>
                                <div className="flex-1">
                                  <div className="text-gray-700 dark:text-gray-300">
                                    {translateInvoiceDescription(line.description)}
                                    {isProration && (
                                      <span className={`ml-2 text-xs font-medium ${line.amount < 0 ? 'text-green-600 dark:text-green-400' : 'text-amber-600 dark:text-amber-400'}`}>
                                        {line.amount < 0 ? i18n._("invoice_credit") : i18n._("invoice_debit")}
                                      </span>
                                    )}
                                  </div>
                                  {line.type && (
                                    <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                      {i18n._("type_label")}: {line.type}
                                    </div>
                                  )}
                                  {isProration && line.period && (
                                    <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                      {i18n._("invoice_proration_period", [
                                        formatDate(new Date(line.period.start * 1000)),
                                        formatDate(new Date(line.period.end * 1000))
                                      ])}
                                    </div>
                                  )}
                                </div>
                                {isProration && (
                                  <div className={`text-sm font-medium ${line.amount < 0 ? 'text-green-600 dark:text-green-400' : 'text-amber-600 dark:text-amber-400'}`}>
                                    {sign}{amountInEuros} €
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300">
                      {i18n._('invoices_date_text_sub', [
                        formatDate(new Date(Number(inv.period_start) * 1000)),
                        formatDate(new Date(Number(inv.period_end) * 1000))
                      ])}
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <div className="text-gray-700 dark:text-gray-300">
                        {(inv.total / 100).toFixed(2)} €
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <a
                        href={inv.hosted_invoice_url}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center justify-center w-8 h-8 text-gray-400 hover:text-primary transition-colors"
                      >
                        <Eye size={18} />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 px-4">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">{i18n._("rows_per_page_label")}:</span>
              <select
                value={rowsPerPage}
                onChange={(e) =>
                  setState(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value), page: 0 }))
                }
                className="bg-gray-50 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 rounded px-2 py-1 text-sm text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              >
                {[5, 10, 25, 50, 100].map(value => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setState(prev => ({ ...prev, page: prev.page - 1 }))}
                disabled={page === 0}
                className="p-2 rounded-lg text-gray-400 hover:text-primary transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeft size={20} />
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {i18n._("pagination_page", [page + 1, Math.ceil(invoices.length / rowsPerPage)])}
              </span>
              <button
                onClick={() => setState(prev => ({ ...prev, page: prev.page + 1 }))}
                disabled={page >= Math.ceil(invoices.length / rowsPerPage) - 1}
                className="p-2 rounded-lg text-gray-400 hover:text-primary transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Toasts */}
      <Toast
        type="error"
        message={state.error?.message || ''}
        isVisible={!!state.error}
        duration={5000} // Les erreurs restent plus longtemps (5 secondes)
      />
      <Toast
        type="success"
        message={i18n._('upload_finished_copied_label')}
        isVisible={successCopyLink}
        duration={2000} // Les succès sont plus courts (2 secondes)
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user?.user || null
});

export default connect(mapStateToProps)(SubscriptionComponent);