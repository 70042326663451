import React, { useEffect, useCallback, useMemo } from 'react';
import { SliderNumberComponent } from './SliderNumberComponent';
import { SwitchButtonComponent } from './SwitchButtonComponent';
import { i18n, convertSize } from '../utils';
import { defaultPermissions, Go } from '../Constants';
import { CustomOffer } from '../types/offer';
import { ChevronLeft, X } from 'lucide-react';

interface ConfigurableOfferComponentProps {
  currentOffer: CustomOffer;
  setCurrentOffer: React.Dispatch<React.SetStateAction<CustomOffer>>;
  currentPrice: number;
  currentPeriod: string | undefined;
  onFinishConfiguration: () => void;
  onResetDefault: () => void;
  currentPermissions?: any;
}

const isOfferValid = (savedOffer: { offer: CustomOffer; timestamp: number; price: number }) => {
  const now = Date.now();
  const hoursSinceLastSave = (now - savedOffer.timestamp) / (1000 * 60 * 60);
  return hoursSinceLastSave <= 24;
};

export const ConfigurableOfferComponent: React.FC<ConfigurableOfferComponentProps> = ({
  currentOffer,
  setCurrentOffer,
  currentPrice,
  currentPeriod,
  onFinishConfiguration,
  onResetDefault,
  currentPermissions,
}) => {

  useEffect(() => {
    // Initialiser l'offre avec les permissions actuelles seulement dans le contexte de l'upgrade
    if (currentPermissions) {
      setCurrentOffer(prevOffer => ({
        ...prevOffer,
        ...Object.entries(currentPermissions).reduce((acc, [key, value]) => {
          if (value && typeof value === 'object' && 'value' in value && value.value !== null) {
            return {
              ...acc,
              [key]: value
            };
          }
          return acc;
        }, {})
      }));
    }
  }, [currentPermissions, setCurrentOffer]);

  const loadSavedOffer = useCallback(() => {
    if (!currentOffer?.isGroupeOffer) {
      try {
        const savedOfferString = localStorage.getItem('configuredOffer');
        if (savedOfferString) {
          const savedOffer = JSON.parse(savedOfferString);
          if (isOfferValid(savedOffer)) {
            setCurrentOffer(savedOffer.offer);
          } else {
            localStorage.removeItem('configuredOffer');
          }
        }
      } catch (error) {
        console.error('Error loading saved offer:', error);
        localStorage.removeItem('configuredOffer');
      }
    }
  }, [currentOffer?.isGroupeOffer, setCurrentOffer]);

  useEffect(() => {
    loadSavedOffer();
  }, [loadSavedOffer]);

  const excludedKeys = useMemo(() => 
    ['basePrice', 'name', 'features', 'upload_size', 'upload_ttl', 'team_size'],
    []
  );

  const allChecked = useMemo(() => {
    if (!currentOffer || currentOffer.isGroupeOffer) return false;
    
    return !Object.entries(currentOffer).some(([key, value]) => {
      if (excludedKeys.includes(key)) return false;
      return typeof value === 'object' && 
             'value' in value && 
             typeof value.value === 'boolean' && 
             !value.value;
    });
  }, [currentOffer, excludedKeys]);

  const teamSizeValue = useMemo(() => 
    typeof currentOffer?.team_size?.value === 'number' ? currentOffer.team_size.value : 0,
    [currentOffer?.team_size?.value]
  );

  const isTeamSizeDisabled = useCallback((upSize?: number, upTtl?: number): boolean => {
    if (!currentOffer) return true;
    const currentUploadSize = typeof currentOffer.upload_size.value === 'number' ? currentOffer.upload_size.value : 0;
    return (upSize ?? currentUploadSize) === 2 * Go;
  }, [currentOffer]);

  const checkAll = useCallback((checked: boolean) => {
    if (!currentOffer) return;
    
    setCurrentOffer(prevOffer => {
      const updatedOffer = Object.entries(prevOffer).reduce((acc, [k, v]) => {
        if (excludedKeys.includes(k)) {
          return { ...acc, [k]: v };
        }
        
        if (typeof v === 'object' && 'value' in v && typeof v.value === 'boolean') {
          return {
            ...acc,
            [k]: {
              ...v,
              value: checked,
            },
          };
        }
        
        return { ...acc, [k]: v };
      }, {} as CustomOffer);
      
      return updatedOffer;
    });
  }, [currentOffer, excludedKeys, setCurrentOffer]);

  const handleFinishConfiguration = useCallback(() => {
    try {
      const stateToSave = {
        offer: currentOffer,
        timestamp: Date.now(),
        price: currentPrice,
        period: currentPeriod
      };
      localStorage.setItem('configuredOffer', JSON.stringify(stateToSave));
      onFinishConfiguration();
    } catch (error) {
      console.error('Error saving configuration:', error);
    }
  }, [currentOffer, currentPrice, currentPeriod, onFinishConfiguration]);

  const handleUploadSizeChange = useCallback((value: number) => {
    setCurrentOffer((prevOffer) => ({
      ...prevOffer,
      upload_size: {
        ...prevOffer.upload_size,
        value: typeof value === 'number' ? value : 0,
      },
      team_size: {
        ...prevOffer.team_size,
        value: isTeamSizeDisabled(value, undefined) ? 0 : teamSizeValue,
      },
    }));
  }, [isTeamSizeDisabled, teamSizeValue, setCurrentOffer]);

  const handleUploadTtlChange = useCallback((value: number) => {
    setCurrentOffer(prevOffer => ({
      ...prevOffer,
      upload_ttl: {
        ...prevOffer.upload_ttl,
        value: typeof value === 'number' ? value : 0,
      },
      team_size: {
        ...prevOffer.team_size,
        value: isTeamSizeDisabled(undefined, value) ? 0 : teamSizeValue,
      },
    }));
  }, [isTeamSizeDisabled, teamSizeValue, setCurrentOffer]);

  const handleTeamSizeChange = useCallback((value: number) => {
    setCurrentOffer(prevOffer => ({
      ...prevOffer,
      team_size: {
        ...prevOffer.team_size,
        value: typeof value === 'number' ? value : 0,
      },
    }));
  }, [setCurrentOffer]);

  if (currentOffer?.isGroupeOffer) {
    return null;
  }

  return (
    <div className="bg-base-200 rounded-xl p-6 shadow-lg border border-white/10 shadow-xl">
      <h2 className="text-2xl font-semibold text-primary mb-4">{i18n._('consult_our_offers')}</h2>

      <div className="space-y-6 mb-6">
        <div>
          <h3 className="text-lg font-medium mb-2">{i18n._('upload_volume_per_transfer')}</h3>
          <SliderNumberComponent
            options={defaultPermissions.upload_size.options ?? []}
            id="upload_size"
            label={convertSize(typeof currentOffer.upload_size?.value === 'number' ? currentOffer.upload_size.value : 0, 0)}
            setValue={handleUploadSizeChange}
            value={typeof currentOffer.upload_size?.value === 'number' ? currentOffer.upload_size.value : 0}
          />
          <p className="text-sm text-base-content/70 mt-1">
            {i18n._('offer_transfer_limitation_label_1')}
          </p>
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">{i18n._('upload_ttl_label')}</h3>
          <SliderNumberComponent
            options={defaultPermissions.upload_ttl.options ?? []}
            id="upload_ttl"
            label={i18n._('qty_upload_ttl_label', [currentOffer.upload_ttl?.value ?? 0])}
            setValue={handleUploadTtlChange}
            value={typeof currentOffer.upload_ttl?.value === 'number' ? currentOffer.upload_ttl?.value : 0}
          />
          <p className="text-sm text-base-content/70 mt-1">
            {i18n._('offer_transfer_limitation_label_3')}
          </p>
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">{i18n._('team_size_label')}</h3>
          <SliderNumberComponent
            disabled={isTeamSizeDisabled()}
            options={defaultPermissions.team_size.options ?? []}
            id="team_size"
            label={
              teamSizeValue > 0
                ? i18n._(`qty_team_size_label`, [Number(teamSizeValue)])
                : `${i18n._('how_many_label')} ${i18n._('subscription_cell_users').toLowerCase()}`
            }
            setValue={handleTeamSizeChange}
            value={teamSizeValue}
          />
          <p className="text-sm text-base-content/70 mt-1">
            {i18n._('subscription_cell_users')}
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <SwitchButtonComponent
          id="all"
          label={i18n._('select_all_label')}
          onSwitch={checkAll}
          checked={allChecked}
        />
        {Object.entries(currentOffer).map(([k, v]) => {
          if (excludedKeys.includes(k) || !defaultPermissions[k]) return null;
          if (typeof v !== 'object' || !('value' in v)) return null;

          return (
            <SwitchButtonComponent
              key={`${v.label}_${k}`}
              id={k}
              label={i18n._(`select_${k}_label`)}
              onSwitch={(checked: boolean) =>
                setCurrentOffer(prev => ({
                  ...prev,
                  [k]: { ...v, value: checked },
                }))
              }
              checked={Boolean(allChecked || v.value !== false)}
              infos={i18n._(`select_${k}_tooltip_label`)}
            />
          );
        })}
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={onResetDefault}
          className="flex items-center text-base-content hover:text-base-content/70 transition-colors duration-200"
        >
          <ChevronLeft className="w-4 h-4 mr-2" />
          {i18n._('return_default')}
        </button>
        <button
          onClick={handleFinishConfiguration}
          className="flex items-center text-primary hover:text-primary-focus transition-colors duration-200"
        >
          <X className="w-4 h-4 mr-2" />
          {i18n._('finish_configuration')}
        </button>
      </div>
    </div>
  );
};
