import React from "react";
import { connect } from "react-redux";
import { Edit, User, Building2, Briefcase, Receipt, Mail, Key } from "lucide-react";
import { i18n } from "../../../../../../utils";
import { appAction } from "../../../../../../Store/Actions";
import { userApi } from "../../../../../../Api";
import PasswordModalsComponent from "../../../../../Modal/PasswordModalsComponent";
import Toast from "../../../../../Toast/Toast";

interface User {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  organization: string;
  job?: string;
  tva_number?: string;
  config: any;
}

interface Props {
  dispatch: Function;
  handleChangeInfo: Function;
  user: any;
  isDisabled: boolean;
  error?: any;
  app: any;
}

interface State {
  user: User & any;
  hasChanged: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isEmailLocked: boolean;
  isSuccess: boolean;
  isModalOpen: boolean;
  isEmailAlertOpen: boolean;
  emailError?: boolean;
  showToast: boolean;
}

class InformationComponent extends React.Component<Props, State> {
  passwordForEmail: string;
  emailInputRef: any;

  constructor(props: Props) {
    super(props);
    this.passwordForEmail = "";
    const {
      first_name,
      last_name,
      email,
      organization,
      job,
      tva_number,
      config,
    } = this.props.user;

    this.state = {
      user: {
        first_name: first_name || "",
        last_name: last_name || "",
        email: email || "",
        organization: organization || "",
        job: job || "",
        tva_number: tva_number || "",
        password: "********",
        config: config,
      },
      hasChanged: false,
      isLoading: false,
      isDisabled: props.isDisabled,
      isEmailLocked: true,
      isSuccess: false,
      isModalOpen: false,
      isEmailAlertOpen: false,
      showToast: false,
    };
  }

  handleChange = async (evt: any) => {
    // Si c'est l'email et qu'il est verrouillé ou si c'est un utilisateur Google, on ne fait rien
    if ((evt.target.name === 'email' && this.state.isEmailLocked) || this.props.user.oauth_provider) {
      return;
    }
  
    const value = evt.target.value;
  
    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user: { ...this.state.user, [evt.target.name]: value },
      },
      this.handleSubmit
    );
  };

  handleSubmit = async () => {
    const protectedKey = ["password"];
    let updatedInfos: any = {};

    Object.keys(this.state.user).forEach((key: string, i: number) => {
      if (
        ~protectedKey.indexOf(key) ||
        (key === "logo" &&
          JSON.stringify(this.state.user[key]) === this.props.user[key])
      )
        return;
      if (
        !this.props.user[key] ||
        this.props.user[key] !== this.state.user[key]
      )
        updatedInfos[key] = this.state.user[key];
    });

    if (this.state.user.email && !this.state.isEmailLocked && !this.props.user.oauth_provider) {
      const isEmailAvailable = await userApi.checkEmail(this.state.user.email);

      if (
        !isEmailAvailable ||
        isEmailAvailable !== true ||
        (isEmailAvailable?.status && isEmailAvailable?.status !== 200)
      )
        return this.setState({
          ...this.state,
          isLoading: false,
          emailError: true,
          user: { ...this.state.user, isError: true },
        });
      else
        this.setState({
          ...this.state,
          isLoading: false,
          emailError: false,
          user: { ...this.state.user, isError: false },
        });
    }
    this.props.handleChangeInfo(updatedInfos);
  };

  changePassword = () => {
    this.props.dispatch(appAction.savePassword(true));
  };

  componentDidUpdate(prevProps: Props) {
    // Si le modal de mot de passe était ouvert et vient d'être fermé, on affiche le toast
    if (prevProps.app.SAVE_PASSWORD && !this.props.app.SAVE_PASSWORD) {
      this.setState({ showToast: true });
    }
  }

  handleCloseToast = () => {
    this.setState({ showToast: false });
  };

  openModal = () => {
    if (!this.props.user.oauth_provider) {
      this.setState({ ...this.state, isModalOpen: true });
    }
  };

  closeModal = () =>
    this.setState({
      ...this.state,
      isModalOpen: false,
      isEmailAlertOpen: false,
      isEmailLocked: true,
    });

  unlockEmailField = async () => {
    const { user, error } = await userApi.auth({
      email: this.props.user.email,
      password: this.passwordForEmail,
    });

    if (!error && user && user.token) {
      this.passwordForEmail = "";
      return this.setState(
        {
          ...this.state,
          isEmailLocked: false,
          isEmailAlertOpen: false,
          isModalOpen: false,
        },
        () => {
          this.emailInputRef &&
            this.emailInputRef.setFocus &&
            this.emailInputRef.setFocus();
        }
      );
    }

    return this.setState({ ...this.state, isEmailAlertOpen: true });
  };

  render() {
    const { isDisabled, user } = this.props;
    const { isEmailLocked, isModalOpen, isEmailAlertOpen } = this.state;

    const inputClasses = `w-full px-4 py-3 border rounded-lg outline-none transition-all duration-200 
      ${isDisabled 
        ? 'bg-gray-50 dark:bg-neutral-700/50 border-gray-200 dark:border-neutral-600 cursor-not-allowed' 
        : 'bg-white dark:bg-neutral-800 border-gray-300 dark:border-neutral-600 focus:ring-2 focus:ring-primary dark:focus:ring-primary/70 hover:border-gray-400 dark:hover:border-neutral-500'
      }
      text-gray-900 dark:text-neutral-100
      placeholder-gray-500 dark:placeholder-neutral-400`;

    return (
      <div className="max-w-[85rem] mx-auto">
        {/* Section principale */}
        <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
            <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
            {i18n._("account_information_title")}
          </h2>

          <div className="space-y-6">
            {/* Nom et Prénom */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                  <User className="w-4 h-4 mr-2" />
                  {i18n._("form_profil_first_name_label")}
                </label>
                <input
                  maxLength={45}
                  disabled={isDisabled}
                  className={inputClasses}
                  onChange={this.handleChange}
                  type="text"
                  autoComplete="given-name"
                  name="first_name"
                  value={this.state.user.first_name}
                />
              </div>

              <div>
                <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                  <User className="w-4 h-4 mr-2" />
                  {i18n._("form_profil_last_name_label")}
                </label>
                <input
                  maxLength={45}
                  disabled={isDisabled}
                  className={inputClasses}
                  onChange={this.handleChange}
                  type="text"
                  autoComplete="family-name"
                  name="last_name"
                  value={this.state.user.last_name}
                />
              </div>
            </div>

            {/* Organisation */}
            <div>
              <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                <Building2 className="w-4 h-4 mr-2" />
                {i18n._("form_profil_organization_label")}
              </label>
              <input
                maxLength={45}
                disabled={isDisabled}
                className={inputClasses}
                onChange={this.handleChange}
                type="text"
                autoComplete="organization"
                name="organization"
                value={this.state.user.organization}
              />
            </div>

            {/* Poste */}
            <div>
              <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                <Briefcase className="w-4 h-4 mr-2" />
                {i18n._("form_profil_job_label")}
              </label>
              <input
                maxLength={45}
                disabled={isDisabled}
                className={inputClasses}
                onChange={this.handleChange}
                type="text"
                autoComplete="job"
                name="job"
                value={this.state.user.job}
              />
            </div>

            {/* TVA */}
            <div>
              <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                <Receipt className="w-4 h-4 mr-2" />
                {i18n._("form_profil_tva_number_label")}
              </label>
              <input
                disabled={isDisabled}
                className={`${inputClasses} ${
                  this.props?.error?.INVALID_TVA_NUMBER 
                    ? 'border-red-500 dark:border-red-500/50 focus:ring-red-500 dark:focus:ring-red-500/50' 
                    : ''
                }`}
                maxLength={20}
                onChange={this.handleChange}
                type="text"
                name="tva_number"
                value={this.state.user?.tva_number}
              />
              {this.props?.error?.INVALID_TVA_NUMBER && (
                <p className="mt-1.5 text-sm text-red-600 dark:text-red-400">
                  {i18n._("invalid_tva_number")}
                </p>
              )}
            </div>

            {/* Email */}
            <div>
              <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                <Mail className="w-4 h-4 mr-2" />
                {i18n._("form_profil_email_label")}
              </label>
              <div className="relative">
                <input
                  ref={(input) => {
                    this.emailInputRef = input;
                  }}
                  disabled={isDisabled || user.oauth_provider}
                  className={`${inputClasses} pr-10`}
                  onChange={!isEmailLocked && !user.oauth_provider ? this.handleChange : undefined}
                  onFocus={isEmailLocked && !user.oauth_provider ? this.openModal : undefined}
                  type="email"
                  autoComplete="email"
                  name="email"
                  value={this.state.user.email}
                  readOnly={isEmailLocked || user.oauth_provider}
                />
                {user.oauth_provider && (
                  <p className="mt-1.5 text-sm text-gray-500 dark:text-neutral-400">
                    {i18n._("email_managed_by_google")}
                  </p>
                )}
                {!isDisabled && !user.oauth_provider && (
                  <Edit
                    className={`absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 transition-colors
                      ${isDisabled 
                        ? 'text-gray-400 dark:text-neutral-500' 
                        : 'text-gray-600 dark:text-neutral-400 cursor-pointer hover:text-primary dark:hover:text-primary'
                      }`}
                    onClick={isDisabled ? undefined : this.openModal}
                  />
                )}
                {this.state.emailError && !isEmailLocked && (
                  <p className="mt-1.5 text-sm text-red-600 dark:text-red-400">
                    {i18n._("form_profil_email_unavailable")}
                  </p>
                )}
              </div>
            </div>

            {/* Mot de passe - Caché pour les utilisateurs Google */}
            {!user.oauth_provider && (
              <div>
                <label className="flex items-center text-sm font-medium text-gray-700 dark:text-neutral-300 mb-1.5">
                  <Key className="w-4 h-4 mr-2" />
                  {i18n._("form_profil_password_label")}
                </label>
                <button
                  className={`inline-flex items-center px-6 py-2.5 rounded-lg font-medium transition-all duration-200 ease-in-out transform
                    ${isDisabled
                      ? 'bg-gray-100 dark:bg-neutral-700/50 text-gray-500 dark:text-neutral-400 cursor-not-allowed'
                      : 'bg-primary hover:bg-primary/90 text-white hover:shadow-lg hover:-translate-y-0.5'
                    }`}
                  disabled={isDisabled}
                  onClick={this.changePassword}
                >
                  <Key className="w-4 h-4 mr-2" />
                  {i18n._("password_update_label")}
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Modal de changement d'email - Affiché seulement pour les utilisateurs non-Google */}
        {isModalOpen && !user.oauth_provider && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white dark:bg-neutral-800 p-6 rounded-xl w-full max-w-md transform transition-all duration-300 ease-out scale-95 animate-modal shadow-xl">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-neutral-100 mb-4 flex items-center">
                <Key className="w-5 h-5 mr-2 text-primary" />
                {i18n._("form_profil_password_label")}
              </h3>
              
              {isEmailAlertOpen && (
                <div className="bg-red-100 dark:bg-red-900/30 border border-red-400 dark:border-red-500/30 text-red-700 dark:text-red-400 px-4 py-3 rounded-lg mb-4 animate-shake">
                  {i18n._("invalid_credentials")}
                </div>
              )}

              <input
                type="password"
                className={`${inputClasses} mb-6`}
                placeholder={i18n._("form_profil_password_label")}
                onChange={(evt: any) => (this.passwordForEmail = evt.target.value)}
              />

              <div className="flex justify-end space-x-3">
                <button
                  onClick={this.closeModal}
                  className="px-4 py-2 text-gray-600 dark:text-neutral-400 hover:text-gray-800 dark:hover:text-neutral-200 transition-colors"
                >
                  {i18n._("btn_cancel_label")}
                </button>
                <button
                  onClick={this.unlockEmailField}
                  className="px-6 py-2 bg-primary hover:bg-primary/90 text-white rounded-lg transition-colors shadow-lg hover:shadow-xl"
                >
                  {i18n._("btn_valid_label")}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal de changement de mot de passe - Affiché seulement pour les utilisateurs non-Google */}
        {!user.oauth_provider && (
          <PasswordModalsComponent
            app={this.props.app}
            dispatch={this.props.dispatch}
            userFromParent={this.props.user}
          />
        )}

        {/* Toast de confirmation */}
        <Toast
          type="success"
          message={i18n._("password_update_success")}
          isVisible={this.state.showToast}
          onClose={this.handleCloseToast}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  app: state.app,
});

export default connect(mapStateToProps)(InformationComponent);
