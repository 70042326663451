import Logo from "./logo.svg";
import LogoLight from "./logo-light.svg";
import LogoLightPNG from "./logo-light.png";
import Slide2 from "./slide-2.jpg";
import Add from "./add.svg";
import About from "./about.svg";
import GreenMountain from "./mountain-green.svg";
import Business1 from "./business-1.jpg";
import Business2 from "./business-2.jpg";
import Business3 from "./business-3.jpg";
import Fake from "./fake.jpg";
import BandFeatures from "./business";
import MailIcon from "./mail.svg";
import FileIcon from "./icon-file.svg";
import DirectoryIcon from "./icon-directory.svg";
import LegaleShieldIcon from "./shield.svg";
import LegalePdfIcon from "./pdf.svg";
import LegaleFileIcon from "./file.svg";
import LogoBahaus from "./logo-bahaus.png";
import ResetIcon from "./reset.svg";
import OrbImage from "./orb.png";
import CerfTransition from "./fv/cerf-filevert-transition.png";
import FilevertGreen from "./fv/filevert-green.png";
import CerfBrousse from "./fv/cerf-brousse.png";
import ODD6 from "./odd/06.png";
import ODD7 from "./odd/07.png";
import ODD8 from "./odd/08.png";
import ODD9 from "./odd/09.png";
import ODD12 from "./odd/12.png";
import ODD13 from "./odd/13.png";
// import Background1 from "./background/bg-1.avif";
import audioAltImage from "./audio.png";
import TooltipHelpers1 from "./tooltipHelpers/1.png";
import TooltipHelpers2 from "./tooltipHelpers/2.png";
import TooltipHelpers3 from "./tooltipHelpers/3.png";
import TooltipHelpers4 from "./tooltipHelpers/4.png";
import TooltipHelpers5 from "./tooltipHelpers/5.png";
import TooltipHub1 from "./tooltipHelpers/hub1.png";
import TooltipHub2 from "./tooltipHelpers/hub2.png";
import TooltipHub3 from "./tooltipHelpers/hub3.png";
import ChromeHead from "./chrome-extension/chrome-head.png";
import ChromeInit from "./chrome-extension/chrome-init.png";
import ChromeProcess from "./chrome-extension/chrome-process.png";
import client1 from "./clientslogo/pinaultcollection.png";
import client2 from "./clientslogo/arte.png";
import client3 from "./clientslogo/sulpicetv.png";
import client4 from "./clientslogo/sony.png";
import client5 from "./clientslogo/loreal.png";
import client6 from "./clientslogo/fyl media.png";
import client7 from "./clientslogo/tf1.png";
import client8 from "./clientslogo/orange.png";

import FVicon from "./icon-fv.png"

const images = {
  TooltipHelpers1,
  TooltipHelpers2,
  TooltipHelpers3,
  TooltipHelpers4,
  TooltipHelpers5,
  TooltipHub1,
  TooltipHub2,
  TooltipHub3,
  audioAltImage,
  Logo,
  LogoLight,
  LogoLightPNG,
  Slide2,
  Add,
  About,
  GreenMountain,
  Business1,
  Business2,
  Business3,
  Fake,
  BandFeatures,
  MailIcon,
  FileIcon,
  DirectoryIcon,
  LegaleShieldIcon,
  LegalePdfIcon,
  LegaleFileIcon,
  LogoBahaus,
  ResetIcon,
  OrbImage,
  CerfTransition,
  FilevertGreen,
  CerfBrousse,
  ODD6,
  ODD7,
  ODD8,
  ODD9,
  ODD12,
  ODD13,
  FVicon,
  ChromeHead,
  ChromeInit,
  ChromeProcess,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  // Background: { Background1 },
};

export default images;
