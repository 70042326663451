import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../../config';
import ReactDOMServer from 'react-dom/server';
import { i18n } from '../../utils';

interface AsyncHelmetProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  path?: string;
  lang?: string;
  keywords?: string;
  content: React.ReactNode;
  isPrerender?: boolean;
}

/**
 * Composant AsyncHelmet pour améliorer l'indexation des pages par les moteurs de recherche
 * Ce composant permet de pré-rendre le contenu de la page pour les robots d'indexation
 */
const AsyncHelmet: React.FC<AsyncHelmetProps> = ({
  title,
  description,
  canonicalUrl = "https://filevert.fr",
  path = "",
  lang = "fr",
  keywords,
  content,
  isPrerender = false
}) => {
  const [isClient, setIsClient] = useState(false);
  const [isI18nReady, setIsI18nReady] = useState(false);
  // Construire l'URL complète
  const fullUrl = `${canonicalUrl}${path}`;
  
  // Construire l'URL canonique
  let canonicalFullUrl = fullUrl;
  
  // Si la langue est le français, l'URL canonique ne doit pas contenir de paramètre hl
  if (lang === 'fr') {
    // Supprimer tout paramètre hl de l'URL
    canonicalFullUrl = fullUrl.replace(/(\?|&)hl=[^&]*(&|$)/, '$1').replace(/\?$/, '');
  } else {
    // Pour les autres langues, s'assurer que l'URL canonique contient le bon paramètre hl
    const urlObj = new URL(fullUrl);
    urlObj.searchParams.set('hl', lang);
    canonicalFullUrl = urlObj.toString();
  }
  
  // Convertir la langue fournie en prop au format attendu par i18n (fr -> fr_FR)
  const normalizedLang = lang === 'fr' ? 'fr_FR' :
                         lang === 'en' ? 'en_EN' :
                         lang === 'es' ? 'es_ES' :
                         lang === 'de' ? 'de_DE' :
                         lang === 'nl' ? 'nl_NL' :
                         lang === 'pt' ? 'pt_PT' : 'fr_FR';
  
  // Traductions des titres selon la langue et la route
  const getTitleByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Titres pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "FileVert - Eco-friendly file transfer solution";
        case "es":
          return "FileVert - Transferencia de archivos ecológica";
        case "de":
          return "FileVert - Umweltfreundliche Dateiübertragung";
        case "nl":
          return "FileVert - Milieuvriendelijke bestandsoverdracht";
        case "pt":
          return "FileVert - Transferência de arquivos ecológica";
        default:
          return "FileVert - Le transfert de fichiers, vertueux et responsable";
      }
    }
    
    // Titres pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "About FileVert - Eco-friendly file transfer";
        case "es":
          return "Acerca de FileVert - Transferencia de archivos ecológica";
        case "de":
          return "Über FileVert - Umweltfreundliche Dateiübertragung";
        case "nl":
          return "Over FileVert - Milieuvriendelijke bestandsoverdracht";
        case "pt":
          return "Sobre FileVert - Transferência de arquivos ecológica";
        default:
          return "À propos de FileVert - Transfert de fichiers vertueux";
      }
    }
    
    // Titres pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "FileVert Offers - Professional transfer solutions";
        case "es":
          return "Ofertas FileVert - Soluciones profesionales de transferencia";
        case "de":
          return "FileVert Angebote - Professionelle Übertragungslösungen";
        case "nl":
          return "FileVert Aanbiedingen - Professionele overdrachtsoplossingen";
        case "pt":
          return "Ofertas FileVert - Soluções profissionais de transferência";
        default:
          return "Offres FileVert - Solutions professionnelles de transfert";
      }
    }
    
    // Titres pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "Contact - FileVert";
        case "es":
          return "Contacto - FileVert";
        case "de":
          return "Kontakt - FileVert";
        case "nl":
          return "Contact - FileVert";
        case "pt":
          return "Contato - FileVert";
        default:
          return "Contact - FileVert";
      }
    }
    
    // Titres pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Chrome Extension - FileVert simplified transfer";
        case "es":
          return "Extensión Chrome - Transferencia simplificada FileVert";
        case "de":
          return "Chrome-Erweiterung - Vereinfachte FileVert-Übertragung";
        case "nl":
          return "Chrome-extensie - Vereenvoudigde FileVert-overdracht";
        case "pt":
          return "Extensão Chrome - Transferência simplificada FileVert";
        default:
          return "Extension Chrome FileVert - Transfert simplifié";
      }
    }
    
    // Titre par défaut
    switch(currentLang) {
      case "en":
        return "FileVert - Eco-friendly file transfer solution";
      case "es":
        return "FileVert - Transferencia de archivos ecológica";
      case "de":
        return "FileVert - Umweltfreundliche Dateiübertragung";
      case "nl":
        return "FileVert - Milieuvriendelijke bestandsoverdracht";
      case "pt":
        return "FileVert - Transferência de arquivos ecológica";
      default:
        return "FileVert - Le transfert de fichiers, vertueux et responsable";
    }
  };
  
  // Traductions des descriptions selon la langue et la route
  const getDescriptionByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Descriptions pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "Transfer your large files up to 200 GB securely and eco-responsibly. French solution hosted on 100% renewable energy servers.";
        case "es":
          return "Transfiera sus archivos grandes hasta 200 GB de forma segura y ecológica. Solución francesa alojada en servidores 100% energía renovable.";
        case "de":
          return "Übertragen Sie Ihre großen Dateien bis zu 200 GB sicher und umweltfreundlich. Französische Lösung, gehostet auf Servern mit 100% erneuerbarer Energie.";
        case "nl":
          return "Draag uw grote bestanden tot 200 GB veilig en milieuvriendelijk over. Franse oplossing gehost op servers met 100% hernieuwbare energie.";
        case "pt":
          return "Transfira seus arquivos grandes até 200 GB de forma segura e ecológica. Solução francesa hospedada em servidores 100% energia renovável.";
        default:
          return "Transférez vos fichiers volumineux jusqu'à 200 Go de manière sécurisée et éco-responsable. Solution française hébergée sur des serveurs 100% énergies renouvelables.";
      }
    }
    
    // Descriptions pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "Discover how FileVert reduces the environmental impact of file transfers. 100% renewable energy infrastructure, optimized storage and eco-responsible approach.";
        case "es":
          return "Descubra cómo FileVert reduce el impacto ambiental de la transferencia de archivos. Infraestructura 100% energía renovable, almacenamiento optimizado y enfoque eco-responsable.";
        case "de":
          return "Entdecken Sie, wie FileVert die Umweltauswirkungen von Dateiübertragungen reduziert. 100% erneuerbare Energieinfrastruktur, optimierter Speicher und umweltbewusster Ansatz.";
        case "nl":
          return "Ontdek hoe FileVert de milieu-impact van bestandsoverdrachten vermindert. 100% hernieuwbare energie-infrastructuur, geoptimaliseerde opslag en milieuvriendelijke aanpak.";
        case "pt":
          return "Descubra como o FileVert reduz o impacto ambiental da transferência de arquivos. Infraestrutura 100% energia renovável, armazenamento otimizado e abordagem eco-responsável.";
        default:
          return "Découvrez comment FileVert réduit l'impact environnemental du transfert de fichiers. Infrastructure 100% énergies renouvelables, stockage optimisé et approche éco-responsable.";
      }
    }
    
    // Descriptions pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "File transfer solutions for professionals and businesses. Maximum security, GDPR compliance and reduced environmental impact.";
        case "es":
          return "Soluciones de transferencia de archivos para profesionales y empresas. Máxima seguridad, cumplimiento del RGPD e impacto ambiental reducido.";
        case "de":
          return "Dateiübertragungslösungen für Fachleute und Unternehmen. Maximale Sicherheit, DSGVO-Konformität und reduzierte Umweltauswirkungen.";
        case "nl":
          return "Bestandsoverdrachtsoplossingen voor professionals en bedrijven. Maximale beveiliging, AVG-naleving en verminderde milieu-impact.";
        case "pt":
          return "Soluções de transferência de arquivos para profissionais e empresas. Segurança máxima, conformidade com o RGPD e impacto ambiental reduzido.";
        default:
          return "Solutions de transfert de fichiers pour professionnels et entreprises. Sécurité maximale, conformité RGPD et impact environnemental réduit.";
      }
    }
    
    // Descriptions pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "Contact the FileVert team for any questions about our eco-responsible file transfer services. Responsive and professional support.";
        case "es":
          return "Contacte al equipo de FileVert para cualquier pregunta sobre nuestros servicios de transferencia de archivos eco-responsables. Soporte receptivo y profesional.";
        case "de":
          return "Kontaktieren Sie das FileVert-Team für Fragen zu unseren umweltbewussten Dateiübertragungsdiensten. Reaktionsschneller und professioneller Support.";
        case "nl":
          return "Neem contact op met het FileVert-team voor vragen over onze milieuvriendelijke bestandsoverdrachtsservices. Responsieve en professionele ondersteuning.";
        case "pt":
          return "Entre em contato com a equipe FileVert para qualquer dúvida sobre nossos serviços de transferência de arquivos eco-responsáveis. Suporte responsivo e profissional.";
        default:
          return "Contactez l'équipe FileVert pour toute question sur nos services de transfert de fichiers éco-responsables. Support réactif et professionnel.";
      }
    }
    
    // Descriptions pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "Optimize your transfers with the FileVert Chrome extension. Send your large files directly from your browser.";
        case "es":
          return "Optimice sus transferencias con la extensión Chrome de FileVert. Envíe sus archivos grandes directamente desde su navegador.";
        case "de":
          return "Optimieren Sie Ihre Übertragungen mit der FileVert Chrome-Erweiterung. Senden Sie Ihre großen Dateien direkt aus Ihrem Browser.";
        case "nl":
          return "Optimaliseer uw overdrachten met de FileVert Chrome-extensie. Verzend uw grote bestanden rechtstreeks vanuit uw browser.";
        case "pt":
          return "Otimize suas transferências com a extensão Chrome do FileVert. Envie seus arquivos grandes diretamente do seu navegador.";
        default:
          return "Optimisez vos transferts avec l'extension Chrome FileVert. Envoyez vos fichiers volumineux directement depuis votre navigateur.";
      }
    }
    
    // Description par défaut
    switch(currentLang) {
      case "en":
        return "Eco-friendly file transfer up to 200 GB. 100% renewable energy, French hosting, secure and GDPR compliant.";
      case "es":
        return "Transferencia de archivos ecológica hasta 200 GB. Energía 100% renovable, alojamiento francés, seguro y conforme al RGPD.";
      case "de":
        return "Umweltfreundliche Dateiübertragung bis 200 GB. 100% erneuerbare Energie, französisches Hosting, sicher und DSGVO-konform.";
      case "nl":
        return "Milieuvriendelijke bestandsoverdracht tot 200 GB. 100% hernieuwbare energie, Franse hosting, veilig en AVG-conform.";
      case "pt":
        return "Transferência de arquivos ecológica até 200 GB. Energia 100% renovável, hospedagem francesa, segura e em conformidade com o RGPD.";
      default:
        return "Transfert de fichiers éco-responsable jusqu'à 200 Go. Énergie 100% renouvelable, hébergement français, sécurisé et conforme RGPD. Solution professionnelle.";
    }
  };
  
  // Traductions des mots-clés selon la langue et la route
  const getKeywordsByLang = (currentLang: string) => {
    // Déterminer la route actuelle
    const currentPath = path || '';
    
    // Mots-clés pour la page d'accueil
    if (currentPath === '/') {
      switch(currentLang) {
        case "en":
          return "large file transfer, wetransfer alternative, large attachments, secure file sending, temporary storage, eco-friendly, professional data transfer";
        case "es":
          return "transferencia de archivos grandes, alternativa wetransfer, adjuntos grandes, envío seguro de archivos, almacenamiento temporal, ecológico, transferencia de datos profesional";
        case "de":
          return "große Dateiübertragung, wetransfer Alternative, große Anhänge, sichere Dateiübertragung, temporärer Speicher, umweltfreundlich, professionelle Datenübertragung";
        case "nl":
          return "grote bestandsoverdracht, wetransfer alternatief, grote bijlagen, veilige bestandsverzending, tijdelijke opslag, milieuvriendelijk, professionele gegevensoverdracht";
        case "pt":
          return "transferência de arquivos grandes, alternativa wetransfer, anexos grandes, envio seguro de arquivos, armazenamento temporário, ecológico, transferência de dados profissional";
        default:
          return "transfert fichiers volumineux, wetransfer alternative, pièces jointes volumineuses, envoi fichiers sécurisé, stockage temporaire, éco-responsable, transfert de données professionnel";
      }
    }
    
    // Mots-clés pour la page À propos
    if (currentPath === '/about') {
      switch(currentLang) {
        case "en":
          return "eco-friendly file transfer, environmental impact, renewable energy, optimized storage, carbon footprint, ecological file transfer";
        case "es":
          return "transferencia de archivos ecológica, impacto ambiental, energía renovable, almacenamiento optimizado, huella de carbono, transferencia de archivos ecológica";
        case "de":
          return "umweltfreundliche Dateiübertragung, Umweltauswirkungen, erneuerbare Energie, optimierter Speicher, CO2-Fußabdruck, ökologische Dateiübertragung";
        case "nl":
          return "milieuvriendelijke bestandsoverdracht, milieu-impact, hernieuwbare energie, geoptimaliseerde opslag, koolstofvoetafdruk, ecologische bestandsoverdracht";
        case "pt":
          return "transferência de arquivos ecológica, impacto ambiental, energia renovável, armazenamento otimizado, pegada de carbono, transferência de arquivos ecológica";
        default:
          return "transfert fichiers éco-responsable, impact environnemental, énergies renouvelables, stockage optimisé, empreinte carbone, transfert fichiers écologique";
      }
    }
    
    // Mots-clés pour la page Offres
    if (currentPath === '/offers') {
      switch(currentLang) {
        case "en":
          return "professional solutions, business file transfer, data security, GDPR compliance, reduced environmental impact, file transfer offers";
        case "es":
          return "soluciones profesionales, transferencia de archivos empresariales, seguridad de datos, cumplimiento del RGPD, impacto ambiental reducido, ofertas de transferencia de archivos";
        case "de":
          return "professionelle Lösungen, Unternehmens-Dateiübertragung, Datensicherheit, DSGVO-Konformität, reduzierte Umweltauswirkungen, Dateiübertragungsangebote";
        case "nl":
          return "professionele oplossingen, bedrijfsbestandsoverdracht, gegevensbeveiliging, AVG-naleving, verminderde milieu-impact, bestandsoverdrachtsaanbiedingen";
        case "pt":
          return "soluções profissionais, transferência de arquivos empresariais, segurança de dados, conformidade com o RGPD, impacto ambiental reduzido, ofertas de transferência de arquivos";
        default:
          return "solutions professionnelles, transfert fichiers entreprise, sécurité données, conformité RGPD, impact environnemental réduit, offres transfert fichiers";
      }
    }
    
    // Mots-clés pour la page Contact
    if (currentPath === '/contact') {
      switch(currentLang) {
        case "en":
          return "FileVert contact, file transfer support, technical assistance, customer service, information request, professional contact";
        case "es":
          return "contacto FileVert, soporte de transferencia de archivos, asistencia técnica, servicio al cliente, solicitud de información, contacto profesional";
        case "de":
          return "FileVert Kontakt, Dateiübertragungsunterstützung, technische Unterstützung, Kundendienst, Informationsanfrage, professioneller Kontakt";
        case "nl":
          return "FileVert contact, bestandsoverdrachtsondersteuning, technische assistentie, klantenservice, informatieverzoek, professioneel contact";
        case "pt":
          return "contato FileVert, suporte de transferência de arquivos, assistência técnica, atendimento ao cliente, solicitação de informações, contato profissional";
        default:
          return "contact FileVert, support transfert fichiers, assistance technique, service client, demande information, contact professionnel";
      }
    }
    
    // Mots-clés pour la page Extension Chrome
    if (currentPath === '/chrome-extension') {
      switch(currentLang) {
        case "en":
          return "chrome extension, browser file transfer, transfer extension, chrome plugin, simplified transfer, eco-friendly extension";
        case "es":
          return "extensión chrome, transferencia de archivos del navegador, extensión de transferencia, complemento chrome, transferencia simplificada, extensión ecológica";
        case "de":
          return "Chrome-Erweiterung, Browser-Dateiübertragung, Übertragungserweiterung, Chrome-Plugin, vereinfachte Übertragung, umweltfreundliche Erweiterung";
        case "nl":
          return "chrome-extensie, browserbestandsoverdracht, overdrachtsextensie, chrome-plugin, vereenvoudigde overdracht, milieuvriendelijke extensie";
        case "pt":
          return "extensão chrome, transferência de arquivos do navegador, extensão de transferência, plugin chrome, transferência simplificada, extensão ecológica";
        default:
          return "extension chrome, transfert fichiers navigateur, extension transfert, plugin chrome, transfert simplifié, extension éco-responsable";
      }
    }
    
    // Mots-clés par défaut
    switch(currentLang) {
      case "en":
        return "large file transfer, wetransfer alternative, large attachments, secure file sending, temporary storage, eco-friendly, professional data transfer";
      case "es":
        return "transferencia de archivos grandes, alternativa wetransfer, adjuntos grandes, envío seguro de archivos, almacenamiento temporal, ecológico, transferencia de datos profesional";
      case "de":
        return "große Dateiübertragung, wetransfer Alternative, große Anhänge, sichere Dateiübertragung, temporärer Speicher, umweltfreundlich, professionelle Datenübertragung";
      case "nl":
        return "grote bestandsoverdracht, wetransfer alternatief, grote bijlagen, veilige bestandsverzending, tijdelijke opslag, milieuvriendelijk, professionele gegevensoverdracht";
      case "pt":
        return "transferência de arquivos grandes, alternativa wetransfer, anexos grandes, envio seguro de arquivos, armazenamento temporário, ecológico, transferência de dados profissional";
      default:
        return "transfert fichiers volumineux, wetransfer alternative, pièces jointes volumineuses, envoi fichiers sécurisé, stockage temporaire, éco-responsable, transfert de données professionnel";
    }
  };
  
  // Gérer l'initialisation et la synchronisation de l'état
  useEffect(() => {
    setIsClient(true);
    
    // Vérifier si i18n est prêt
    const checkI18n = () => {
      if (i18n.isReady && i18n.strings) {
        setIsI18nReady(true);
        return;
      }
      
      // Si i18n n'est pas prêt, réessayer dans 100ms
      setTimeout(checkI18n, 100);
    };
    
    checkI18n();
  }, []);
  
  // Générer le HTML statique pour les robots d'indexation
  const generateStaticHTML = () => {
    // Utiliser les clés i18n pour générer le contenu en fonction de la route
    const getRouteSpecificContent = () => {
      const currentPath = path || '';
      
      // Contenu commun à toutes les pages
      const commonContent = `
        <p>${i18n._("screens.aboutScreen.annonce1.text-section1") || "FileVert est une solution de transfert de fichiers éco-responsable qui permet d'envoyer des fichiers jusqu'à 200 Go."}</p>
        <ul>
          <li>${i18n._("screens.aboutScreen.whyChoose.features.optimized.title") || "Transfert de fichiers volumineux jusqu'à 200 Go"}</li>
          <li>${i18n._("screens.aboutScreen.whyChoose.categories.security.items.cloud-french.title") || "Hébergement français sécurisé et conforme RGPD"}</li>
          <li>${i18n._("screens.aboutScreen.whyChoose.categories.eco.items.energy.title") || "Infrastructure 100% alimentée par des énergies renouvelables"}</li>
          <li>${i18n._("screens.aboutScreen.whyChoose.features.storage.title") || "Stockage temporaire optimisé pour réduire l'empreinte carbone"}</li>
        </ul>
      `;
      
      // Contenu spécifique à chaque page
      if (currentPath.includes('/about')) {
        // Récupérer les traductions avec des clés explicites pour éviter les problèmes de résolution
        const titleHead = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.annonce1?.["title-head"] || "Pour vos transferts de fichiers volumineux, pas vos données personnelles";
        const text = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.annonce1?.text || "Première solution de transfert de fichiers éco-conçue qui réduit jusqu'à 98,7% l'empreinte carbone de vos échanges numériques standards.";
        const textSection1 = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.annonce1?.["text-section1"] || "FileVert repose sur deux piliers fondamentaux : un numérique durable avec des infrastructures française optimisées réduisant l'impact environnemental, et une souveraineté totale des données garantissant confidentialité et minimisation des informations collectées.";
        
        const whyChooseTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.title || "Pourquoi choisir FileVert";
        
        const ecoTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.eco?.title || "Engagement écologique";
        const emissionsTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.eco?.items?.emissions?.title || "Économie d'émissions carbone";
        const emissionsDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.eco?.items?.emissions?.description || "Jusqu'à 98,7% de réduction par rapport à un stockage de longue durée";
        const energyTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.eco?.items?.energy?.title || "Énergie verte";
        const energyDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.eco?.items?.energy?.description || "Serveurs alimentés à 100% par des énergies renouvelables";
        
        const perfTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.performance?.title || "Performance & Rapidité";
        const speedTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.performance?.items?.speed?.title || "Transferts ultra-rapides";
        const speedDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.performance?.items?.speed?.description || "Infrastructure optimisée pour une vitesse maximale";
        const simplicityTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.performance?.items?.simplicity?.title || "Sans étapes superflues";
        const simplicityDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.performance?.items?.simplicity?.description || "Processus simplifié pour un gain de temps considérable";
        
        const securityTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.title || "Simplicité & Sécurité";
        const noRegTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.noRegistration?.title || "Utilisation sans inscription";
        const noRegDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.noRegistration?.description || "Commencez immédiatement sans création de compte";
        const cloudTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.["cloud-french"]?.title || "Cloud français";
        const cloudDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.["cloud-french"]?.description || "Infrastructure performante et sécurisée en France";
        const secTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.security?.title || "Sécurité garantie";
        const secDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.whyChoose?.categories?.security?.items?.security?.description || "Confidentialité et protection des données optimales";
        
        const impactTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.title || "Notre impact environnemental";
        const impactSubtitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.subtitle || "FileVert s'engage à réduire concrètement l'empreinte écologique du numérique en agissant sur trois leviers principaux";
        
        const storageTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.storage?.title || "Stockage optimisé";
        const storageDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.storage?.description || "4,72 tonnes de CO₂ économisées par an grâce à une durée de stockage réduite de 76% par rapport aux solutions standards";
        
        const transfersTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.transfers?.title || "Transferts efficaces";
        const transfersDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.transfers?.description || "0,83 tonne de CO₂ économisée annuellement grâce à des serveurs éco-conçus, alimentés à 100% par des énergies renouvelables";
        
        const emailsTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.emails?.title || "Emails optimisés";
        const emailsDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.metrics?.emails?.description || "7,64 tonnes de CO₂ économisées grâce au remplacement théorique des pièces jointes par des liens uniques, réduisant drastiquement les données dupliquées";
        
        const solutionsTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.title || "Nos solutions pour vos besoins";
        const solutionsSubtitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.subtitle || "Une suite complète d'outils responsables pour gérer tous vos échanges de fichiers professionnels";
        
        const transferTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.transfer?.title || "FileVert Transfert";
        const transferDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.transfer?.description || "Envoyez vos fichiers simplement et écologiquement à n'importe quel destinataire par email ou lien sécurisé.";
        
        const hubTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.hub?.title || "FileVert Hub";
        const hubDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.hub?.description || "Recevez des fichiers de vos clients ou partenaires sans limite de taille et en toute sécurité.";
        
        const collabTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.collab?.title || "FileVert Collab";
        const collabDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.solutions?.cards?.collab?.description || "Gérez efficacement les révisions et versions de vos fichiers en équipe dans un espace sécurisé.";
        
        const engagementTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.engagement?.title || "Notre engagement pour un numérique responsable";
        const engagementDesc = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.engagement?.description || "FileVert propose une vision nouvelle des outils numériques qui place l'utilisateur au centre de son projet de réduction des émissions tout en préservant sa confidentialité et les Objectifs de Développement Durable.";
        
        const totalTitle = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.total?.title || "Bilan total annuel (2025)";
        const totalValue = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.total?.value || "-13,15 tonnes CO₂e";
        const treesEquiv = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.total?.equivalents?.trees || "658 arbres plantés chaque année";
        const distanceEquiv = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.total?.equivalents?.distance || "59 234 km évités en voiture";
        const energyEquiv = i18n.strings?.[i18n.lang]?.screens?.aboutScreen?.impact?.total?.equivalents?.energy || "30 363 kWh économisés";
        
        return `
          <h2>${titleHead}</h2>
          <p>${text}</p>
          <p>${textSection1}</p>
          
          <h3>${whyChooseTitle}</h3>
          
          <div>
            <h4>${ecoTitle}</h4>
            <p>${emissionsTitle} : ${emissionsDesc}</p>
            <p>${energyTitle} : ${energyDesc}</p>
          </div>
          
          <div>
            <h4>${perfTitle}</h4>
            <p>${speedTitle} : ${speedDesc}</p>
            <p>${simplicityTitle} : ${simplicityDesc}</p>
          </div>
          
          <div>
            <h4>${securityTitle}</h4>
            <p>${noRegTitle} : ${noRegDesc}</p>
            <p>${cloudTitle} : ${cloudDesc}</p>
            <p>${secTitle} : ${secDesc}</p>
          </div>
          
          <h3>${impactTitle}</h3>
          <p>${impactSubtitle}</p>
          
          <div>
            <h4>${storageTitle}</h4>
            <p>${storageDesc}</p>
          </div>
          
          <div>
            <h4>${transfersTitle}</h4>
            <p>${transfersDesc}</p>
          </div>
          
          <div>
            <h4>${emailsTitle}</h4>
            <p>${emailsDesc}</p>
          </div>
          
          <h3>${solutionsTitle}</h3>
          <p>${solutionsSubtitle}</p>
          <ul>
            <li><strong>${transferTitle}</strong> : ${transferDesc}</li>
            <li><strong>${hubTitle}</strong> : ${hubDesc}</li>
            <li><strong>${collabTitle}</strong> : ${collabDesc}</li>
          </ul>
          
          <h3>${engagementTitle}</h3>
          <p>${engagementDesc}</p>
          
          <div>
            <h4>${totalTitle}</h4>
            <p>${totalValue}</p>
            <ul>
              <li>${treesEquiv}</li>
              <li>${distanceEquiv}</li>
              <li>${energyEquiv}</li>
            </ul>
          </div>
        `;
      } else if (currentPath.includes('/offers')) {
        return `
          <h2>${i18n._("screens.offersScreen.title") || "Nos offres"}</h2>
          <p>${i18n._("screens.offersScreen.subtitle") || "Solutions de transfert de fichiers pour professionnels et entreprises."}</p>
          ${commonContent}
        `;
      } else if (currentPath.includes('/contact')) {
        return `
          <h2>${i18n._("screens.contactScreen.title") || "Contact"}</h2>
          <p>${i18n._("screens.contactScreen.subtitle") || "Contactez l'équipe FileVert pour toute question sur nos services."}</p>
          ${commonContent}
        `;
      } else if (currentPath.includes('/chrome-extension')) {
        return `
          <h2>${i18n._("screens.chromeExtensionScreen.title") || "Extension Chrome FileVert"}</h2>
          <p>${i18n._("screens.chromeExtensionScreen.subtitle") || "Optimisez vos transferts avec l'extension Chrome FileVert."}</p>
          ${commonContent}
        `;
      } else {
        // Page d'accueil ou autre
        return `
          <h2>${i18n._("screens.homeScreen.title") || "Transfert de fichiers volumineux éco-responsable"}</h2>
          <p>${i18n._("screens.homeScreen.subtitle") || "Solution française hébergée sur des serveurs 100% énergies renouvelables."}</p>
          ${commonContent}
        `;
      }
    };
    
    // Assembler le HTML complet
    const seoHTML = `
      <div class="seo-content">
        <h1>${getTitleByLang(lang)}</h1>
        <p>${getDescriptionByLang(lang)}</p>
        <p>Mots-clés: ${getKeywordsByLang(lang)}</p>
        <div class="page-content">
          ${getRouteSpecificContent()}
          <p>URL: ${fullUrl}</p>
          <p>Langue: ${lang}</p>
        </div>
      </div>
    `;
    
    return seoHTML;
  };
  
  // Détecter si i18n est prêt
  useEffect(() => {
    if (isI18nReady) {
      // i18n est prêt, génération du contenu statique possible
    }
  }, [isI18nReady]);
  
  // Obtenir la traduction pour l'attribut alt des images
  const getImageAltByLang = (currentLang: string): string => {
    switch(currentLang) {
      case "en":
        return "FileVert - Eco-friendly file transfer";
      case "es":
        return "FileVert - Transferencia de archivos ecológica";
      case "de":
        return "FileVert - Umweltfreundliche Dateiübertragung";
      case "nl":
        return "FileVert - Milieuvriendelijke bestandsoverdracht";
      case "pt":
        return "FileVert - Transferência de arquivos ecológica";
      default:
        return "FileVert - Transfert de fichiers éco-responsable";
    }
  };

  // Vérifier si i18n est prêt pour éviter les conflits de titre
  const shouldSetTitle = !i18n.isReady || title;
  
  return (
    <Helmet
      htmlAttributes={{ lang }}
      titleAttributes={{
        lang,
        "data-hreflang": lang,
        "data-alternate": "true",
        "data-async-helmet": "true" // Marquer le titre comme défini par AsyncHelmet
      }}
    >
      {shouldSetTitle && <title>{title || getTitleByLang(lang)}</title>}
      <meta name="description" content={getDescriptionByLang(lang)} />
      <meta name="keywords" content={getKeywordsByLang(lang)} />
      <meta name="async-route" content="true" />
      <link rel="canonical" href={canonicalFullUrl} />
      
      {/* Meta tags pour Open Graph et Twitter avec traductions */}
      <meta property="og:image:alt" content={getImageAltByLang(lang)} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={getImageAltByLang(lang)} />
      
      {/* Hreflang tags for multilingual support */}
      {['en', 'es', 'de', 'nl', 'pt'].map(l => {
        // Construire l'URL avec le paramètre hl pour les langues autres que le français
        const urlObj = new URL(`${canonicalUrl}${path}`);
        
        // Nettoyer d'abord tout paramètre hl existant
        urlObj.searchParams.delete('hl');
        
        // Ajouter le paramètre hl pour les langues non françaises
        urlObj.searchParams.set('hl', l);
        
        return (
          <link
            key={l}
            rel="alternate"
            hrefLang={l}
            href={urlObj.toString()}
          />
        );
      })}
      
      {/* Le français est la langue par défaut, donc pas de paramètre hl */}
      <link
        rel="alternate"
        hrefLang="fr"
        href={`${canonicalUrl}${path.replace(/(\?|&)hl=[^&]*(&|$)/, '$1').replace(/\?$/, '')}`}
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${canonicalUrl}${path.replace(/(\?|&)hl=[^&]*(&|$)/, '$1').replace(/\?$/, '')}`}
      />
      
      {/* Pré-rendu pour les robots d'indexation */}
      <noscript>
        {`<div id="prerendered-content">${isI18nReady ? generateStaticHTML() : "Chargement du contenu..."}</div>`}
      </noscript>
      
      {/* Données structurées pour les moteurs de recherche */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": title || getTitleByLang(lang),
          "description": getDescriptionByLang(lang),
          "url": fullUrl,
          "inLanguage": lang,
          "keywords": getKeywordsByLang(lang)
        })}
      </script>
    </Helmet>
  );
};

export default AsyncHelmet;