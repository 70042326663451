import React from "react";

export interface ISliderNumberComponentProps {
  value?: number;
  label?: React.ReactNode;
  options: number[];
  setValue?: (value: number) => void;
  id: string;
  min?: number;
  max?: number;
  displayValue?: boolean;
  [rest: string]: any;
}

const valueStyle = {
  fontWeight: 600,
  fontSize: "1.25em",
  width: "auto",
  height: "40px",
  lineHeight: "40px",
  margin: "12px 0px",
  letterSpacing: "-.02em",
  marginTop: 0,
  color: "rgba(255, 255, 255, 0.9)",
};

export function SliderNumberComponent({
  displayValue,
  label,
  value,
  options,
  setValue,
  id,
  min,
  max,
  ...rest
}: ISliderNumberComponentProps) {
  const rangeValues = options.reduce(
    (acc: any, opt, i) => ({ ...acc, [opt]: i }),
    {}
  );
  const rangeMap = options.reduce(
    (acc: any, opt, i) => ({ ...acc, [i]: opt }),
    {}
  );

  return (
    <>
      <label
        aria-disabled={rest?.disabled}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }}
        className="text-white/90"
        style={valueStyle}
        htmlFor={id}
      >
        {displayValue && value}
        {label}
      </label>
      <div className="w-full">
        <input
          disabled={rest?.disabled}
          onChange={({ currentTarget: { valueAsNumber } }) =>
            setValue && setValue(rangeMap[valueAsNumber])
          }
          value={value ? rangeValues[value] || 0 : 0}
          type="range"
          className="range w-full"
          id={id}
          min={min || 0}
          max={max || options.length - 1}
          {...rest}
        />
        <div className="flex w-full justify-between px-2 text-xs">
          {options.map((_, index) => (
            <span key={index}>|</span>
          ))}
        </div>
      </div>
    </>
  );
}
