import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FileText, Users, TrendingDown, TrendingUp } from 'lucide-react';
import { i18n } from '../../../../utils/';
import convertSize from '../../../../utils/convertSize';
import images from '../../../../assets/images';
import FolderLoader from '../../../FolderLoader/FolderLoader';
import { useGroupUsageStats } from '../../../../hooks/useGroupUsageStats';
import { UserUsageStats } from '../../../../Api/usageApi';

// Déclaration des variables de couleurs
const PRIMARY_COLOR = '#10B981';
const SECONDARY_COLOR = '#047857';
const GRAY_1 = '#4B5563';
const GRAY_2 = '#6B7280';
const GRAY_3 = '#E5E7EB';
const LIGHT_GRAY_BG = '#F9FAFB';
const LIGHT_GRAY_BORDER = '#E2E8F0';
const LIGHT_GREEN_BG = '#ECFDF5';
const LIGHT_GREEN_BORDER = '#D1FAE5';

// Prix par Go
const PRICE_PER_GB = 0.01; // 0,01€ par Go

interface UsageInvoiceReportProps {
  dateRange?: 'current_month' | 'previous_month' | 'current_year' | 'all';
}

const UsageInvoiceReport: React.FC<UsageInvoiceReportProps> = ({
  dateRange = 'current_month'
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const user = useSelector((state: any) => state.user?.user);
  const { stats, loading, error } = useGroupUsageStats();

  // Fonction pour formater les dates
  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Fonction pour formater les montants en euros
  const formatAmount = (amount: number): string => {
    return `${amount.toFixed(2)} €`;
  };

  // Fonction pour formater les octets
  const formatBytes = (bytes: number): string => {
    return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} ${i18n._("usage_stats_size_unit")}`;
  };

  // Fonction pour obtenir l'icône de tendance
  const getTrendIcon = (current: number, previous: number): string => {
    if (current === previous) return '';
    const isUp = current > previous;
    const color = isUp ? '#EF4444' : '#10B981'; // Rouge pour hausse, vert pour baisse
    const icon = isUp ? '↑' : '↓';
    return `<span style="color: ${color}; font-weight: bold;">${icon}</span>`;
  };

  // Fonction pour calculer les détails de facturation
  const calculateInvoiceDetails = () => {
    if (!stats || !stats.total) {
      return {
        items: [],
        subtotal: 0,
        tax: 0,
        total: 0,
        invoiceNumber: `INV-${Date.now().toString().slice(-8)}`,
        invoiceDate: new Date(),
        dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // +30 jours par défaut
      };
    }
    
    // Créer un élément de facture pour chaque utilisateur
    const items = stats.users.map(user => {
      const usageGB = user.currentPeriod.usage / (1024 * 1024 * 1024);
      return {
        description: `${i18n._('download_volume_invoice')} - ${user.name}`,
        quantity: usageGB,
        unitPrice: PRICE_PER_GB,
        amount: usageGB * PRICE_PER_GB
      };
    });
    
    // Calculer le sous-total
    const subtotal = items.reduce((sum, item) => sum + item.amount, 0);
    
    // TVA à 20%
    const tax = subtotal * 0.2;
    
    // Total
    const total = subtotal + tax;
    
    return {
      items,
      subtotal,
      tax,
      total,
      invoiceNumber: `INV-${Date.now().toString().slice(-8)}`,
      invoiceDate: new Date(),
      dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // +30 jours par défaut
    };
  };

  // Fonction pour générer le PDF
  const generatePDF = async () => {
    if (loading || !stats) {
      alert(i18n._("loading_data_error") || "Erreur: Données non disponibles");
      return;
    }

    try {
      setIsGenerating(true);
      
      const invoiceDetails = calculateInvoiceDetails();
      
      // Créer le PDF
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });
      
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      
      // Créer le contenu HTML du PDF
      const content = document.createElement('div');
      content.style.width = '210mm';
      content.style.padding = '15mm';
      content.style.background = 'white';
      content.style.fontFamily = 'poppins, system-ui, -apple-system, sans-serif';
      content.style.boxSizing = 'border-box';
      
      // En-tête avec logo
      const header = document.createElement('div');
      header.innerHTML = `
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 25px; padding-bottom: 15px; border-bottom: 2px solid ${GRAY_3};">
          <div style="display: flex; align-items: center; gap: 20px;">
            <img src="${images.LogoLightPNG}" alt="FileVert" style="height: 50px;"/>
            <div>
              <h1 style="color: ${PRIMARY_COLOR}; font-size: 26px; margin: 0; font-weight: 600;">${i18n._("group_invoice_report_title") || "Rapport de facturation groupe"}</h1>
              <p style="color: ${GRAY_1}; margin: 8px 0 0 0; font-size: 16px;">${
                dateRange === 'current_month' ? i18n._("current_month") :
                dateRange === 'previous_month' ? i18n._("previous_month") :
                dateRange === 'current_year' ? i18n._("current_year") : i18n._("all_time")
              }</p>
            </div>
          </div>
          <div style="text-align: right; color: ${GRAY_2}; font-size: 14px;">
            <p style="margin: 0;">${i18n._("document_generated_on")} ${formatDate(new Date())}</p>
            <p style="margin: 0;">${i18n._("invoice_number")}: ${invoiceDetails.invoiceNumber}</p>
          </div>
        </div>
      `;
      content.appendChild(header);
      
      // Informations client et facture
      const clientInfo = document.createElement('div');
      clientInfo.innerHTML = `
        <div style="display: flex; justify-content: space-between; margin-bottom: 30px;">
          <div style="flex: 1;">
            <h3 style="color: ${SECONDARY_COLOR}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("client_information")}</h3>
            <div style="background: ${LIGHT_GRAY_BG}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px;">
              <p style="margin: 0 0 5px 0;"><strong>${i18n._("client_name")}:</strong> ${user?.first_name || ''} ${user?.last_name || ''}</p>
              <p style="margin: 0 0 5px 0;"><strong>${i18n._("client_email")}:</strong> ${user?.email || ''}</p>
              <p style="margin: 0;"><strong>${i18n._("client_organization")}:</strong> ${user?.organization || ''}</p>
            </div>
          </div>
          <div style="flex: 1; margin-left: 20px;">
            <h3 style="color: ${SECONDARY_COLOR}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("invoice_information")}</h3>
            <div style="background: ${LIGHT_GRAY_BG}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px;">
              <p style="margin: 0 0 5px 0;"><strong>${i18n._("invoice_date")}:</strong> ${formatDate(invoiceDetails.invoiceDate)}</p>
              <p style="margin: 0 0 5px 0;"><strong>${i18n._("due_date")}:</strong> ${formatDate(invoiceDetails.dueDate)}</p>
              <p style="margin: 0;"><strong>${i18n._("payment_method")}:</strong> ${i18n._("credit_card")}</p>
            </div>
          </div>
        </div>
      `;
      content.appendChild(clientInfo);
      
      // Résumé d'utilisation du groupe
      const groupSummary = document.createElement('div');
      groupSummary.innerHTML = `
        <div style="margin-bottom: 30px;">
          <h3 style="color: ${PRIMARY_COLOR}; font-size: 18px; margin: 0 0 15px 0;">${i18n._("group_usage_summary") || "Résumé d'utilisation du groupe"}</h3>
          <div style="background: ${LIGHT_GREEN_BG}; border: 1px solid ${LIGHT_GREEN_BORDER}; border-radius: 8px; padding: 20px; margin-bottom: 15px;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
              <div>
                <h4 style="color: ${SECONDARY_COLOR}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("total_users") || "Nombre d'utilisateurs"}</h4>
                <p style="color: ${SECONDARY_COLOR}; font-size: 20px; font-weight: bold; margin: 0;">
                  ${stats.users.length}
                </p>
              </div>
              <div>
                <h4 style="color: ${SECONDARY_COLOR}; font-size: 16px; margin: 0 0 10px 0;">${i18n._("total_usage") || "Volume total"}</h4>
                <p style="color: ${SECONDARY_COLOR}; font-size: 20px; font-weight: bold; margin: 0;">
                  ${formatBytes(stats.total.usage)}
                </p>
                <p style="color: ${GRAY_1}; margin: 5px 0 0 0; font-size: 14px;">
                  ${formatAmount(stats.total.cost)}
                </p>
              </div>
            </div>
          </div>
        </div>
      `;
      content.appendChild(groupSummary);
      
      // Tableau des utilisateurs
      const usersTable = document.createElement('div');
      usersTable.innerHTML = `
        <div style="margin-bottom: 30px;">
          <h3 style="color: ${PRIMARY_COLOR}; font-size: 18px; margin: 0 0 15px 0;">${i18n._("users_usage_details") || "Détails d'utilisation par utilisateur"}</h3>
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <thead>
              <tr style="background: ${LIGHT_GRAY_BG};">
                <th style="padding: 12px; text-align: left; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_table_user") || "Utilisateur"}</th>
                <th style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_table_currentPeriod") || "Période actuelle"}</th>
                <th style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_table_previousMonth") || "Mois précédent"}</th>
                <th style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_table_total") || "Total"}</th>
                <th style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_table_trend") || "Tendance"}</th>
              </tr>
            </thead>
            <tbody>
              ${stats.users.map((user: UserUsageStats) => `
                <tr>
                  <td style="padding: 12px; border: 1px solid ${GRAY_3};">
                    <div>
                      <div style="font-weight: bold;">${user.name} ${user.isTeamLeader ? 
                        `<span style="display: inline-block; background: ${PRIMARY_COLOR}; color: white; font-size: 10px; padding: 2px 6px; border-radius: 4px; margin-left: 5px;">${i18n._("groupUsage_teamLeader") || "Admin"}</span>` : 
                        ''}
                      </div>
                      <div style="font-size: 12px; color: ${GRAY_2};">${user.email}</div>
                    </div>
                  </td>
                  <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">
                    <div style="font-weight: bold;">${formatBytes(user.currentPeriod.usage)}</div>
                    <div style="font-size: 12px; color: ${GRAY_2};">${formatAmount(user.currentPeriod.estimatedCost)}</div>
                  </td>
                  <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">
                    <div style="font-weight: bold;">${formatBytes(user.previousMonth.usage)}</div>
                    <div style="font-size: 12px; color: ${GRAY_2};">${formatAmount(user.previousMonth.cost)}</div>
                  </td>
                  <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">
                    <div style="font-weight: bold;">${formatBytes(user.total.usage)}</div>
                    <div style="font-size: 12px; color: ${GRAY_2};">${formatAmount(user.total.cost)}</div>
                  </td>
                  <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">
                    ${getTrendIcon(user.currentPeriod.usage, user.previousMonth.usage)}
                  </td>
                </tr>
              `).join('')}
              <tr style="background: ${LIGHT_GREEN_BG}; font-weight: bold;">
                <td style="padding: 12px; border: 1px solid ${GRAY_3};">${i18n._("groupUsage_total") || "Total"}</td>
                <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};">
                  <div>${formatBytes(stats.total.usage)}</div>
                  <div style="font-size: 12px;">${formatAmount(stats.total.cost)}</div>
                </td>
                <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};"></td>
                <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};"></td>
                <td style="padding: 12px; text-align: center; border: 1px solid ${GRAY_3};"></td>
              </tr>
            </tbody>
          </table>
        </div>
      `;
      content.appendChild(usersTable);
      
      // Détails de facturation
      const invoiceDetails_section = document.createElement('div');
      invoiceDetails_section.innerHTML = `
        <div style="margin-bottom: 30px;">
          <h3 style="color: ${PRIMARY_COLOR}; font-size: 18px; margin: 0 0 15px 0;">${i18n._("invoice_details") || "Détails de facturation"}</h3>
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <thead>
              <tr style="background: ${LIGHT_GRAY_BG};">
                <th style="padding: 12px; text-align: left; border: 1px solid ${GRAY_3};">${i18n._("description") || "Description"}</th>
                <th style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${i18n._("quantity") || "Quantité"}</th>
                <th style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${i18n._("unit_price") || "Prix unitaire"}</th>
                <th style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${i18n._("amount") || "Montant"}</th>
              </tr>
            </thead>
            <tbody>
              ${invoiceDetails.items.map(item => `
                <tr>
                  <td style="padding: 12px; border: 1px solid ${GRAY_3};">${item.description}</td>
                  <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${item.quantity.toFixed(2)} Go</td>
                  <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${formatAmount(item.unitPrice)}</td>
                  <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${formatAmount(item.amount)}</td>
                </tr>
              `).join('')}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3}; font-weight: bold;">${i18n._("subtotal") || "Sous-total"}</td>
                <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${formatAmount(invoiceDetails.subtotal)}</td>
              </tr>
              <tr>
                <td colspan="3" style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3}; font-weight: bold;">${i18n._("tax") || "TVA"} (20%)</td>
                <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3};">${formatAmount(invoiceDetails.tax)}</td>
              </tr>
              <tr style="background: ${LIGHT_GREEN_BG};">
                <td colspan="3" style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3}; font-weight: bold;">${i18n._("total") || "Total"}</td>
                <td style="padding: 12px; text-align: right; border: 1px solid ${GRAY_3}; font-weight: bold;">${formatAmount(invoiceDetails.total)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      `;
      content.appendChild(invoiceDetails_section);
      
      // Détails de tarification
      const pricingDetails = document.createElement('div');
      pricingDetails.innerHTML = `
        <div style="margin-bottom: 30px;">
          <h3 style="color: ${PRIMARY_COLOR}; font-size: 18px; margin: 0 0 15px 0;">${i18n._("pricing_details") || "Détails de tarification"}</h3>
          <div style="background: ${LIGHT_GRAY_BG}; border: 1px solid ${LIGHT_GRAY_BORDER}; border-radius: 8px; padding: 15px; font-size: 14px;">
            <p style="margin: 0 0 10px 0;"><strong>${i18n._("pricing_model") || "Modèle de tarification"}:</strong> ${i18n._("pay_per_use") || "Paiement à l'usage"}</p>
            <p style="margin: 0 0 10px 0;"><strong>${i18n._("base_rate") || "Tarif de base"}:</strong> ${formatAmount(PRICE_PER_GB)} / Go</p>
            <p style="margin: 0;"><strong>${i18n._("billing_cycle") || "Cycle de facturation"}:</strong> ${i18n._("monthly") || "Mensuel"}</p>
          </div>
        </div>
      `;
      content.appendChild(pricingDetails);
      
      // Note de bas de page
      const footer = document.createElement('div');
      footer.innerHTML = `
        <div style="border-top: 1px solid ${GRAY_3}; padding-top: 15px; margin-top: 30px; font-size: 12px; color: ${GRAY_2};">
          <p style="margin: 0 0 5px 0;">${i18n._("invoice_note") || "Note: Ce document est un récapitulatif détaillé de votre consommation et ne constitue pas une facture officielle."}</p>
          <p style="margin: 0 0 5px 0;">${i18n._("payment_terms") || "Conditions de paiement: Paiement automatique par carte bancaire à la fin de chaque mois."}</p>
          <div style="display: flex; justify-content: space-between; margin-top: 15px;">
            <span>FileVert SAS - SIRET: 12345678900001</span>
            <span>${i18n._("page") || "Page"} 1/1</span>
          </div>
        </div>
      `;
      content.appendChild(footer);
      
      // Ajouter le contenu au DOM pour le convertir en image
      document.body.appendChild(content);
      
      // Convertir en image
      const canvas = await html2canvas(content, {
        scale: 2,
        logging: false,
        useCORS: true,
        allowTaint: true
      });
      
      // Calculer les dimensions
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      // Ajouter l'image au PDF
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 0.8),
        'JPEG',
        0,
        0,
        imgWidth,
        Math.min(imgHeight, pageHeight)
      );
      
      // Si le contenu dépasse la hauteur de la page, ajouter des pages supplémentaires
      if (imgHeight > pageHeight) {
        let remainingHeight = imgHeight - pageHeight;
        let position = -pageHeight;
        
        while (remainingHeight > 0) {
          pdf.addPage();
          position -= pageHeight;
          pdf.addImage(
            canvas.toDataURL('image/jpeg', 0.8),
            'JPEG',
            0,
            position,
            imgWidth,
            imgHeight
          );
          remainingHeight -= pageHeight;
        }
      }
      
      // Supprimer le contenu du DOM
      document.body.removeChild(content);
      
      // Générer le nom du fichier
      const today = new Date();
      const dateString = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
      const sanitizedOrganization = user?.organization?.includes('.') ? user.organization.replace('.', '-') : user?.organization || '';
      const fileName = `${sanitizedOrganization ? `${sanitizedOrganization} - ` : ''}Facture-Groupe-FileVert-${dateString}.pdf`;
      
      // Sauvegarder le PDF
      pdf.save(fileName);
      
      setIsGenerating(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("Une erreur est survenue lors de la génération du rapport. Veuillez réessayer.");
      setIsGenerating(false);
    }
  };
  
  if (loading) {
    return (
      <button
        disabled
        className="bg-gray-400 text-white font-bold py-2 px-4 rounded flex items-center gap-2 opacity-70"
      >
        <FolderLoader isLoading={true} />
        <span>{i18n._("loading_data") || "Chargement des données..."}</span>
      </button>
    );
  }
  
  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 rounded-lg p-4 flex items-center space-x-3">
        <FileText className="text-red-500 dark:text-red-400" />
        <p className="text-red-700 dark:text-red-100">{error}</p>
      </div>
    );
  }
  
  return (
    <button
      onClick={generatePDF}
      disabled={isGenerating || !stats}
      className={`bg-primary hover:bg-primary/80 text-white font-bold py-2 px-4 rounded flex items-center gap-2 transition-all duration-300 ${isGenerating ? 'opacity-90' : 'hover:shadow-lg'}`}
      title={i18n._("generate_group_invoice_report_tooltip") || "Générer un rapport détaillé de facturation pour le groupe"}
    >
      {isGenerating ? (
        <>
          <FolderLoader isLoading={true} />
          <span>{i18n._("generating_invoice") || "Génération en cours..."}</span>
        </>
      ) : (
        <>
          <Users className="w-4 h-4 mr-1" />
          <FileText className="w-4 h-4" />
          {i18n._("generate_group_invoice_report") || "Générer la facture détaillée du groupe"}
        </>
      )}
    </button>
  );
};

export default UsageInvoiceReport;