// Constants/UploadPhase.ts
export enum UploadPhase {
    INITIAL = 'initial',
    CONFIGURATION = 'configuration',
    PROGRESS = 'progress',
    FINISHED = 'finished',
    ERROR = 'error'
  }
  
  export interface TransferPhaseState {
    phase?: UploadPhase;
    isConfigurationPhase?: boolean;
    isUploadFinished?: boolean;
    isLocked?: boolean;
    totalSize?: number;
    purging?: boolean;
  }
  
  /**
   * Détermine la phase d'upload en fonction des propriétés du transfert
   */
  export const determinePhase = (transfer: TransferPhaseState): UploadPhase => {
    if (transfer.phase) {
      return transfer.phase;
    }
    
    if (transfer.isUploadFinished) {
      return UploadPhase.FINISHED;
    }
    
    if (transfer.isLocked || transfer.purging) {
      return UploadPhase.PROGRESS;
    }
    
    if (transfer.isConfigurationPhase || (transfer.totalSize && transfer.totalSize > 0)) {
      return UploadPhase.CONFIGURATION;
    }
    
    return UploadPhase.INITIAL;
  };