import React, { useEffect } from 'react';
import { useUsageStats } from '../../hooks/useUsageStats';
import { AlertCircle } from 'lucide-react';
import { i18n } from '../../utils';

interface Props {
  onStatsLoaded?: (stats: any) => void;
}

const UsageStats: React.FC<Props> = ({ onStatsLoaded }) => {
  const { stats, loading, error, refresh } = useUsageStats();

  useEffect(() => {
    if (stats && onStatsLoaded) {
      onStatsLoaded(stats);
    }
  }, [stats, onStatsLoaded]);

  if (loading) {
    return (
      <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
        <div className="custom-pulse space-y-4">
          <div className="h-8 bg-gray-200 dark:bg-neutral-700 rounded w-1/4"></div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="bg-gray-50 dark:bg-neutral-700 rounded-lg p-6">
                <div className="h-6 bg-gray-200 dark:bg-neutral-600 rounded w-3/4 mb-4"></div>
                <div className="h-8 bg-gray-200 dark:bg-neutral-600 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 rounded-lg p-4 flex items-center space-x-3">
        <AlertCircle className="text-red-500 dark:text-red-400" />
        <p className="text-red-700 dark:text-red-100">{error}</p>
      </div>
    );
  }

  if (!stats) {
    return null;
  }

  const formatBytes = (bytes: number): string => {
    return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} ${i18n._("usage_stats_size_unit")}`;
  };

  const formatPrice = (price: number): string => {
    return i18n._("usage_stats_price_format", [price.toFixed(2)]);
  };

  return (
    <div className="bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
        <span className="w-2 h-8 bg-primary rounded-r mr-3"></span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6 mr-2"
        >
          <path d="M12 20V10" />
          <path d="M18 20V4" />
          <path d="M6 20v-4" />
        </svg>
        {i18n._("usage_stats_title")}
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Current Period Usage */}
        <div className="bg-gray-50 dark:bg-neutral-700 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-800 dark:text-neutral-200 mb-2">
            {i18n._("usage_stats_current_period")}
          </h3>
          <p className="text-3xl font-bold text-primary">
            {formatBytes(stats.currentPeriod.usage)}
          </p>
          {stats.showCosts && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
              {i18n._("usage_stats_estimated_cost")}{formatPrice(stats.currentPeriod.estimatedCost)}
            </p>
          )}
        </div>

        {/* Previous Month Usage */}
        <div className="bg-gray-50 dark:bg-neutral-700 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-800 dark:text-neutral-200 mb-2">
            {i18n._("usage_stats_previous_month")}
          </h3>
          <p className="text-3xl font-bold text-gray-700 dark:text-gray-300">
            {formatBytes(stats.previousMonth.usage)}
          </p>
          {stats.showCosts && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
              {i18n._("usage_stats_billed_amount")}{formatPrice(stats.previousMonth.cost)}
            </p>
          )}
        </div>

        {/* Total Usage */}
        <div className="bg-gray-50 dark:bg-neutral-700 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-800 dark:text-neutral-200 mb-2">
            {i18n._("usage_stats_total")}
          </h3>
          <p className="text-3xl font-bold text-gray-700 dark:text-gray-300">
            {formatBytes(stats.total.usage)}
          </p>
          {stats.showCosts && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
              {i18n._("usage_stats_total_amount")}{formatPrice(stats.total.cost)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsageStats;
