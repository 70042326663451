import { i18n } from "../../utils";
import { IInputProps, InputObject } from "../../types/input";
import { RefObject } from "react";

export interface IInputItemProps extends IInputProps {
  type: string;
  errors: { [key: string]: string };
  name: string;

  label?: string;
  required?: boolean;

  [rest: string]: any;
}

export class InputItem implements InputObject {
  private type: string;
  public name: string;
  private required: boolean;
  public value: any;
  public defaultValue?: any;

  private _errors: { [key: string]: string } = {};
  public currentError: string | null = null;
  private _label: string = "";
  private _rest: Record<string, any> = {};
  private _ref: RefObject<HTMLInputElement> | null = null;

  public setError: (error: string | null) => void = () => {};
  public get rest(): Record<string, any> {
    return this._rest;
  }
  public get error(): string | null {
    return this.currentError ? this._errors[this.currentError] : null;
  }
  public setRef(ref: any) {
    this._ref = ref.current ? ref : { current: ref };
  }
  public get ref(): HTMLInputElement | null {
    return this._ref?.current || null;
  }

  // public onError: any;

  get label() {
    return this._label;
  }
  set label(label) {
    this._label = i18n._(label);
  }

  public setCurrentError(error: string | null) {
    this.currentError = error;
    if (this.ref) {
      if (error) {
        this.ref.setCustomValidity(this.error || "");
        this.ref.classList.remove("is-valid");
        this.ref.classList.add("is-invalid");
      } else {
        this.ref.setCustomValidity("");
        this.ref.classList.add("is-valid");
        this.ref.classList.remove("is-invalid");
      }
    }
    this.setError && this.setError(this.error);
  }

  set errors(errors: any) {
    this._errors = Object.keys(errors).reduce(
      (acc: any, cur: any) => ({ ...acc, [cur]: i18n._(errors[cur]) }),
      {}
    );
  }

  constructor({
    type,
    errors,
    name,
    label,
    required,
    value = '',
    ...rest
  }: IInputItemProps) {
    this.type = type;
    this.name = name;
    this.errors = errors;
    this.label = label ? label : `input_${name}_label`;
    this.required = required || false;
    this.value = value;
    this._rest = rest;
    return this;
  }

  public setValue(value: any) {
    this.value = value;
    if (this.ref) {
      this.ref.setCustomValidity("");
      this.ref.classList.add("is-valid");
      this.ref.classList.remove("is-invalid");
    }
    this.currentError = null;
  }

  public getRawInput(): IInputProps {
    return {
      type: this.type,
      name: this.name,
      label: this.label,
      required: this.required,
      error: this.error || undefined,
      ...this._rest,
    };
  }
}
