import React from "react";
import { connect } from "react-redux";
import { Copy, Download } from "lucide-react";
import { i18n, userCanInviteMember } from "../../../../utils/";
import { ActionInputComponent, BtnComponent } from "../../../index";
import { userApi } from "../../../../Api";
import * as userAction from "../../../../Store/Actions/userAction";
import { ApiKeyComponent } from "./Components/ApiKeyComponent";
import { InformationComponent } from "./Components";
import { PersonalizationProvider } from "../../../../Contexts/PersonalizationContext";

interface Props {
  dispatch: Function;
  user: any;
  title: string;
}

interface State {
  step: number;
  isLoading: boolean;
  hasChanged: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  user: any;
  error: any;
  successCopyLink: boolean;
}

class AccountComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      step: 2,
      isLoading: false,
      hasChanged: false,
      isDisabled: false,
      isSuccess: false,
      user: {
        config: {},
      },
      error: null,
      successCopyLink: false,
    };
  }

  componentDidMount() {
    console.debug("[Account] Component mounting with props:", this.props);
    
    if (this.props.user) {
      console.debug("[Account] Initializing state with user:", this.props.user);
      this.setState({
        user: {
          ...this.props.user,
          config: {
            ...this.props.user.config,
          },
        },
      });
    }
  }

  handleChangeInfo = (userInfo: any) => {
    console.debug("[Account] Handling info change:", userInfo);
    this.setState({
      ...this.state,
      hasChanged: true,
      user: { ...this.state.user, ...userInfo },
    });
  };

  handleLinkCopy = (evt: any) => {
    const dataDom = document.getElementById(evt.target.id);
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    if (document.execCommand("copy")) {
      this.setState({ ...this.state, successCopyLink: true }, () =>
        setTimeout(
          () => this.setState({ ...this.state, successCopyLink: false }),
          1500
        )
      );
    }
  };

  handleChangeSpace = async (
    userConfig: any,
    userLogo: any,
    key: string = "logo"
  ) => {
    console.debug("[Account] handleChangeSpace called with:", { userConfig, userLogo, key });

    // Initialiser l'utilisateur s'il n'existe pas
    if (!this.state.user) {
      this.setState({
        user: {
          config: {},
        },
      });
    }

    if (userConfig === "RESET") {
      console.debug("[Account] Resetting user space");
      const resetState = {
        hasChanged: true,
        user: {
          ...this.state.user,
          config: {
            theme: "",
            url: "",
            website: "",
            rss_feed: "",
            title: "",
            message: "",
            logo: null,
            background: null,
          },
          logo: null,
          background: null,
        },
      };
      console.debug("[Account] Reset state:", resetState);
      await this.setState(resetState);
      return;
    }

    if (userConfig === "UPDATE" && userLogo && key === "background") {
      console.debug("[Account] Updating background with:", userLogo);
      const updateState = {
        hasChanged: true,
        user: {
          ...this.state.user,
          background: userLogo,
          config: {
            ...this.state.user.config,
            background: userLogo,
            theme: "custom-0",
          },
        },
      };
      console.debug("[Account] Update state:", updateState);
      await this.setState(updateState);
      return;
    }

    if (
      key === "background" &&
      userConfig?.theme &&
      userLogo &&
      !userConfig?.theme?.includes("custom")
    ) {
      userConfig.theme = `custom-${userConfig.theme?.split("-")[1] || 0}`;
    }

    let newState;
    // Mise à jour avec config et logo/background
    if (userConfig && userLogo !== undefined) {
      console.debug("[Account] Updating with config and image:", { userConfig, userLogo, key });
      newState = {
        hasChanged: true,
        user: {
          ...this.state.user,
          config: { ...userConfig },
          [key]: userLogo,
        },
      };
    }
    // Mise à jour avec seulement logo/background
    else if (userLogo !== undefined) {
      console.debug("[Account] Updating with only image:", { userLogo, key });
      const config = this.state.user?.config || {};
      newState = {
        hasChanged: true,
        user: {
          ...this.state.user,
          config: { ...config, [key]: userLogo },
          [key]: userLogo,
        },
      };
    }
    // Mise à jour avec seulement config
    else if (userConfig) {
      console.debug("[Account] Updating with only config:", userConfig);
      newState = {
        hasChanged: true,
        user: {
          ...this.state.user,
          config: { ...userConfig },
        },
      };
    }

    if (newState) {
      console.debug("[Account] Setting new state:", newState);
      await this.setState(newState);
    }
  };

  handleSubmit = async () => {
    try {
      const { step, user } = this.state;
      const currentUserConfig = this.props.user?.config;

      if (step === 1)
        return this.setState({ ...this.state, step: 2, hasChanged: false });

      this.setState({ ...this.state, isLoading: true });

      console.debug("[Account] handleSubmit - Current state:", user);
      console.debug("[Account] handleSubmit - Current config:", currentUserConfig);

      // Préparer les données à mettre à jour
      const userUp: any = {
        config: {},
      };

      // Gérer les champs de configuration
      if (user.config) {
        Object.entries(user.config).forEach(([key, value]: [string, any]) => {
          // Convertir url en website
          const configKey = key === "url" ? "website" : key;
          
          // Ne pas inclure les champs nuls ou inchangés
          if (
            value !== null &&
            value !== currentUserConfig[configKey] &&
            !(!value && !currentUserConfig[configKey])
          ) {
            userUp.config[configKey] = value;
          }
        });
      }

      // Gérer le logo et le background
      if (user.logo !== undefined) {
        userUp.logo = user.logo;
        userUp.config.logo = user.logo;
      }
      if (user.background !== undefined) {
        userUp.background = user.background;
        userUp.config.background = user.background;
      }

      console.debug("[Account] handleSubmit - Data to update:", userUp);

      const updatedUser = await userApi.update(userUp, this.props.user.id);
      console.debug("[Account] handleSubmit - Server response:", updatedUser);

      // Mettre à jour Redux avec les données complètes
      const finalUser = {
        ...updatedUser,
        logo: userUp.logo || updatedUser.logo,
        background: userUp.background || updatedUser.background,
        config: {
          ...updatedUser.config,
          logo: userUp.logo || updatedUser.config?.logo,
          background: userUp.background || updatedUser.config?.background,
        },
      };

      this.props.dispatch(userAction.loginUser(finalUser));

      // Vérifier si nous devons réinitialiser
      const shouldReset = !finalUser.config?.theme || finalUser.config?.theme === "";
      
      if (shouldReset) {
        console.debug("[Account] Theme is empty, resetting space");
        await this.handleChangeSpace("RESET", null, "background");
      }

      this.setState({
        isLoading: false,
        isDisabled: false,
        hasChanged: false,
        step: 2,
        user: {
          ...finalUser,
          config: {
            ...finalUser.config,
            theme: shouldReset ? "" : finalUser.config?.theme,
            logo: shouldReset ? null : finalUser.config?.logo,
            background: shouldReset ? null : finalUser.config?.background,
          },
        },
        isSuccess: true
      });
    } catch (error: any) {
      if (error.code)
        this.setState({
          ...this.state,
          error: { [error.code]: error },
          isLoading: false,
        });
    }
  };

  render = () => {
    const { isLoading, hasChanged, isSuccess, step, user } = this.state;
    const { title } = this.props;

    console.debug("[Account] Checking customization permission:", {
      permissions: this.props.user?.permissions,
      customization: this.props.user?.permissions?.customization,
      value: this.props.user?.permissions?.customization?.value
    });
    
    const isCustomizable = this.props.user?.permissions?.customization?.value;
    console.debug("[Account] isCustomizable:", isCustomizable);

    return (
      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="flex flex-wrap border-b border-gray-200 dark:border-neutral-700 pb-4 mb-6">
          <div className="w-full lg:w-2/3 md:w-full my-auto">
           
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
              <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
              {title}
            </h3>
          </div>
          <div className="w-full lg:w-1/3 md:w-full my-auto">
            <BtnComponent
              outline={false}
              background="filled"
              className="float-right uppercase"
              onClick={this.handleSubmit}
              text={
                step === 1
                  ? i18n._("btn_modify_label")
                  : i18n._("btn_save_label")
              }
              isLoading={isLoading}
              hasChanged={hasChanged}
              isDisabled={
                (step !== 1 && !hasChanged) || (user && user?.isError)
              }
              isSuccess={isSuccess}
            />
          </div>
        </div>
        
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 sm:w-full mx-auto space-y-6">
            <InformationComponent
              error={this.state.error}
              isDisabled={step === 1}
              handleChangeInfo={this.handleChangeInfo}
            />

            {!!(
              false &&
              userCanInviteMember(this.props.user) &&
              !this.props.user?.referer_id
            ) && (
              <div className="mt-8">
                <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 p-6">
                  <h5 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 mb-6 flex items-center">
                    <span className="w-2 h-8 bg-secondary rounded-r mr-3"></span>
                    {i18n._("BUTTONS.AFFILIATE_LINK.LABEL")}
                  </h5>
                  <ActionInputComponent
                    value={this.props.user.affiliate_link || i18n._("no_affiliate_link_available")}
                    customClass="mt-4"
                    onCopy={() => {
                      if (this.props.user.affiliate_link) {
                        this.setState({ successCopyLink: true }, () => 
                          setTimeout(() => this.setState({ successCopyLink: false }), 1500)
                        );
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>


          {canUseApiKey(this.props.user) && (
            <div className="w-full">
              <ApiKeyComponent
                dispatch={this.props.dispatch}
                user={this.props.user}
              />
            </div>
          )}
        </div>

        {this.state.successCopyLink && (
          <div className="fixed bottom-4 left-4 z-50 bg-green-900 test-emerald-500-100 px-6 py-3 rounded-lg shadow-lg flex items-center space-x-2">
            <span className="test-emerald-500">✓</span>
            {i18n._("upload_finished_copied_label")}
          </div>
        )}
      </div>
    );
  };
}

function canUseApiKey({ permissions, role, referer_id }: any) {
  return permissions.team_size.value && role > 1 && !referer_id;
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(AccountComponent);
