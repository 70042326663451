import React, { useState } from "react";
import { connect } from "react-redux";
import images from "../../assets/images";
import {
  AccountComponent,
  ActivityComponent,
  CollabComponent,
  ReceiveComponent,
  SendComponent,
  SubscriptionComponent,
} from "./Components";
import { PersonalizationComponent } from "./Components/Account/Components";
import { i18n } from "../../utils";
import {
  Home,
  Send,
  Activity,
  Users,
  UserCircle,
  Settings,
  FileSignature,
  Menu,
  ChevronRight,
  ChevronLeft,
  X
} from "lucide-react";

// Export de ces constantes pour les réutiliser partout
export const ACTIVITY = "activity";
export const SEND = "send";
export const RECEIVE = "receive";
export const COLLAB = "collab";
export const SUBSCRIPTION = "subscription";
export const ACCOUNT = "account";
export const PERSONALIZATION = "personalization";

export type TabType =
  | typeof ACTIVITY
  | typeof SEND
  | typeof RECEIVE
  | typeof COLLAB
  | typeof SUBSCRIPTION
  | typeof ACCOUNT
  | typeof PERSONALIZATION;

interface UserPermissions {
  customization?: {
    value: boolean;
  };
  studio?: {
    value: boolean;
  };
}

interface Subscription {
  id: string;
  name: string;
  qty: number;
  current_period_start: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
  status: string;
}

interface User {
  role?: number;
  permissions?: UserPermissions;
  subscriptions?: Subscription[];
}

interface Props {
  type: TabType;
  changeTab: (tab: TabType) => void;
  user?: User;
}

interface State {
  user?: {
    user: User;
  };
}

interface ComponentConfig {
  logo?: string;
  [key: string]: any;
}

interface Components {
  [key: string]: () => JSX.Element;
}

const TabComponent = ({ type, changeTab, user }: Props): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [availableTabs, setAvailableTabs] = React.useState<TabType[]>([]);

  React.useEffect(() => {
    let tabs: TabType[] = [];
    
    // Définition des onglets de base selon le rôle
    if (!user?.role || user.role < 2) {
      tabs = [ACTIVITY, ACCOUNT, PERSONALIZATION, SEND, SUBSCRIPTION];
    } else {
      tabs = [ACTIVITY, ACCOUNT, PERSONALIZATION, SEND, RECEIVE, SUBSCRIPTION];
    }

    // Retirer l'onglet 'personalization' si l'utilisateur n'a pas la permission
    if (!user?.permissions?.customization?.value) {
      tabs = tabs.filter((tab) => tab !== PERSONALIZATION);
    }

    // Ajouter l'onglet 'collab' si l'utilisateur a accès
    if (
      user?.permissions?.studio?.value ||
      (user?.subscriptions?.length && Date.now() < new Date("2023-10-12").getTime())
    ) {
      const sendIndex = tabs.indexOf(SEND);
      if (sendIndex !== -1) {
        tabs = [
          ...tabs.slice(0, sendIndex),
          COLLAB as TabType,
          ...tabs.slice(sendIndex)
        ];
      }
    }

    setAvailableTabs(tabs);
  }, [user?.role, user?.permissions?.customization?.value, user?.permissions?.studio?.value, user?.subscriptions?.length]);

  const getComponent = React.useCallback((): JSX.Element | null => {
    if (!type) return null;

    const components: Components = {
      [ACTIVITY]: () => (
        <ActivityComponent
          changeTab={changeTab}
          title={i18n._(`profil_${ACTIVITY}_title`)}
        />
      ),
      [ACCOUNT]: () => (
        <AccountComponent title={i18n._(`profil_${ACCOUNT}_title`)} />
      ),
      [RECEIVE]: () => (
        <ReceiveComponent title={i18n._(`profil_${RECEIVE}_title`)} />
      ),
      [SEND]: () => (
        <SendComponent title={i18n._(`profil_${SEND}_title`)} />
      ),
      [COLLAB]: () => (
        <CollabComponent title={i18n._(`profil_${COLLAB}_title`)} />
      ),
      [SUBSCRIPTION]: () => (
        <SubscriptionComponent
          title={i18n._(`profil_${SUBSCRIPTION}_title`)}
        />
      ),
      [PERSONALIZATION]: () => (
        <PersonalizationComponent
          title={i18n._(`profil_${PERSONALIZATION}_title`)}
          isDisabled={false}
          handleChangeSpace={(
            config: ComponentConfig,
            logo?: string,
            key: string = "logo"
          ) => {
            return;
          }}
        />
      )
    };

    return components[type] ? components[type]() : null;
  }, [type, changeTab]);

  const getIcon = React.useCallback((tab: TabType) => {
    const iconProps = {
      size: 20,
      className: "text-gray-400"
    };

    switch (tab) {
      case ACTIVITY:
        return <Home {...iconProps} />;
      case SEND:
        return <Send {...iconProps} />;
      case RECEIVE:
        return <Activity {...iconProps} />;
      case COLLAB:
        return <Users {...iconProps} />;
      case SUBSCRIPTION:
        return <FileSignature {...iconProps} />;
      case ACCOUNT:
        return <UserCircle {...iconProps} />;
      case PERSONALIZATION:
        return <Settings {...iconProps} />;
      default:
        return null;
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-neutral-900">
      {/* Backdrop pour fermer le menu en mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
          aria-hidden="true"
        />
      )}
      
      {/* Barre latérale */}
      <div
        className={`${
          isSidebarOpen ? "translate-x-0 w-72 md:w-80" : "-translate-x-full w-16 sm:w-20"
        } fixed inset-y-0 start-0 z-50 hover:w-72 md:hover:w-80  bg-white dark:bg-neutral-900 border-e border-gray-200 dark:border-neutral-700 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:w-16 lg:hover:w-72 lg:end-auto lg:bottom-0 transition-all duration-300 ease-in-out group shadow-lg lg:shadow-none`}
      >
        <div className="px-4 group-hover:px-6 flex items-center">
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="lg:hidden p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-neutral-700 transition-colors mr-2"
            aria-label="Fermer le menu"
          >
            <X size={18} className="text-gray-500" />
          </button>
          <img
            src={images.FVicon}
            alt="FileVert"
            className={`w-6 h-6 sm:w-8 sm:h-8 ${isSidebarOpen ? 'hidden' : 'block lg:group-hover:hidden'}`}
          />
          <a
            className={`flex-none text-xl font-semibold text-emerald-500 ${isSidebarOpen ? 'flex' : 'hidden lg:group-hover:flex'} items-center gap-2 hover:text-emerald-600 transition-colors`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              
              // Créer un nouvel objet URLSearchParams pour ne conserver que le paramètre de langue
              const newParams = new URLSearchParams();
              
              // Récupérer le paramètre de langue depuis l'URL actuelle
              const currentParams = new URLSearchParams(window.location.search);
              const urlLang = currentParams.get("hl");
              
              // Ou depuis localStorage si non présent dans l'URL
              const storedLang = localStorage.getItem("lang");
              const langCode = urlLang || (storedLang ? storedLang.substring(0, 2) : null);
              
              // Ajouter le paramètre de langue s'il existe
              if (langCode) {
                newParams.set("hl", langCode);
              }
              
              // Rediriger vers la page d'accueil avec uniquement le paramètre de langue
              window.location.href = newParams.toString() ? `/?${newParams.toString()}` : "/";
            }}
            aria-label="Retour à l'accueil"
          >
            <ChevronLeft size={20} className="text-emerald-500" />
            <span className="text-emerald-500 text-base sm:text-sm font-medium sm:font-normal">{i18n._("start_a_transfer")}</span>
          </a>
        </div>

        <nav className="p-4 mt-6 w-full flex flex-col flex-wrap">
          <ul className="space-y-3">
            {availableTabs.map((tab) => (
              <li key={tab}>
                <button
                  onClick={() => {
                    changeTab(tab);
                    // Fermer le menu en mobile lorsqu'une tab est sélectionnée
                    if (window.innerWidth < 1024) { // lg breakpoint
                      setIsSidebarOpen(false);
                    }
                  }}
                  className={`w-full flex items-center gap-x-3 py-2 sm:py-2 px-3 sm:px-3 text-base sm:text-sm rounded-md transition-colors
                    ${
                      type === tab
                        ? "bg-primary/10 text-primary border-l-4 border-primary pl-2"
                        : "text-gray-400 hover:bg-gray-100"
                    }`}
                >
                  {getIcon(tab)}
                  <span className={`whitespace-nowrap text-base sm:text-sm font-medium sm:font-normal ${isSidebarOpen ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} transition-opacity duration-300`}>
                    {i18n._(`profil_${tab}_title`)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
          
          {/* Lien "Envoyer des fichiers" en bas du menu - visible uniquement en mobile */}
          <div className="mt-auto pt-4 block sm:hidden">
            <a
              href="#"
              className="flex items-center justify-center gap-2 px-3 py-2 mt-6 text-sm font-medium text-primary border border-primary rounded-md hover:bg-primary/10 transition-colors"
              onClick={(e) => {
                e.preventDefault();
                
                // Créer un nouvel objet URLSearchParams pour ne conserver que le paramètre de langue
                const newParams = new URLSearchParams();
                
                // Récupérer le paramètre de langue depuis l'URL actuelle
                const currentParams = new URLSearchParams(window.location.search);
                const urlLang = currentParams.get("hl");
                
                // Ou depuis localStorage si non présent dans l'URL
                const storedLang = localStorage.getItem("lang");
                const langCode = urlLang || (storedLang ? storedLang.substring(0, 2) : null);
                
                // Ajouter le paramètre de langue s'il existe
                if (langCode) {
                  newParams.set("hl", langCode);
                }
                
                // Rediriger vers la page d'accueil avec uniquement le paramètre de langue
                window.location.href = newParams.toString() ? `/?${newParams.toString()}` : "/";
              }}
              aria-label="Envoyer des fichiers"
            >
              <Send size={16} />
              <span>{i18n._("start_a_transfer")}</span>
            </a>
          </div>
        </nav>
      </div>

      {/* Contenu principal - Utilise toute la largeur */}
      <div className="w-full transition-all duration-300">
        {/* Barre du haut (mobile) */}
        <div className="sticky top-0 inset-x-0 z-20 border-y border-gray-200 dark:border-neutral-700 lg:hidden">
          <div className="flex items-center py-3 px-4">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <span className="sr-only">Toggle Navigation</span>
              <Menu size={24} />
            </button>

            <ol className="ms-4 flex items-center whitespace-nowrap">
              <li className="flex items-center text-sm text-gray-500">
                {i18n._("profile")}
                <ChevronRight className="mx-2 h-4 w-4" />
              </li>
              <li className="text-sm font-semibold text-gray-600 dark:text-gray-300 truncate">
                {i18n._(`profil_${type}_title`)}
              </li>
            </ol>
          </div>
        </div>

        {/* Contenu optimisé pour mobile */}
        <div className="py-10 px-0 sm:px-6 lg:px-8 w-full mx-auto overflow-hidden">
          <div key={type} className="touch-manipulation">
            {getComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  user: state.user?.user,
});

export default connect(mapStateToProps)(React.memo(TabComponent));
