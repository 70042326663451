import { SET_CONNECTION_QUALITY } from '../Reducers/connectionReducer';
import { AnyAction } from 'redux';
import { AppThunk } from '../index';
import { transferApi } from '../../Api';

export type ConnectionQualityType = 'excellent' | 'good' | 'medium' | 'poor' | 'unknown';

interface NetworkInformation extends EventTarget {
  effectiveType: string;
  downlink: number;
  rtt: number;
  saveData: boolean;
  addEventListener(type: string, listener: EventListener): void;
  removeEventListener(type: string, listener: EventListener): void;
}

declare global {
  interface Navigator {
    connection?: NetworkInformation;
    mozConnection?: NetworkInformation;
    webkitConnection?: NetworkInformation;
  }
}

export const setConnectionQuality = (quality: ConnectionQualityType): AnyAction => ({
  type: SET_CONNECTION_QUALITY,
  payload: quality,
});

const getNetworkConnection = (): NetworkInformation | null => {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
};

const determineQualityFromLatency = (latency: number): ConnectionQualityType => {
  if (latency < 100) return 'excellent';
  if (latency < 200) return 'good';
  if (latency < 500) return 'medium';
  return 'poor';
};

const determineQualityFromNetwork = (connection: NetworkInformation): ConnectionQualityType => {
  const { effectiveType, downlink, rtt } = connection;

  if (effectiveType === '4g' && downlink >= 10 && rtt < 50) {
    return 'excellent';
  } else if ((effectiveType === '4g' && downlink >= 5) || (effectiveType === '3g' && downlink >= 5 && rtt < 100)) {
    return 'good';
  } else if ((effectiveType === '3g' && downlink >= 2) || (effectiveType === '2g' && downlink >= 2 && rtt < 300)) {
    return 'medium';
  } else if (effectiveType === '2g' || effectiveType === 'slow-2g' || downlink < 2 || rtt >= 300) {
    return 'poor';
  }

  return 'unknown';
};

const checkApiLatency = async (): Promise<ConnectionQualityType> => {
  try {
    const connectionDetails = await transferApi.getConnectionDetails();
    if (connectionDetails.lastLatency && connectionDetails.lastLatency !== -1) {
      return determineQualityFromLatency(connectionDetails.lastLatency);
    }
  } catch (error) {
    console.error('Error checking API latency:', error);
  }
  return 'unknown';
};

export const detectConnectionQuality = (): AppThunk => {
  return async (dispatch) => {
    try {
      let quality: ConnectionQualityType = 'unknown';
      
      // Essayer d'abord l'API Network Information
      const connection = getNetworkConnection();
      if (connection) {
        quality = determineQualityFromNetwork(connection);
        if (quality !== 'unknown') {
          dispatch(setConnectionQuality(quality));
          return;
        }
      }

      // Si pas d'info réseau ou qualité inconnue, utiliser la mesure de latence API
      quality = await checkApiLatency();
      
      // Si toujours inconnu, faire un test de ping basique
      if (quality === 'unknown') {
        const startTime = performance.now();
        try {
          await fetch('/transfer/connection/ping');
          const duration = performance.now() - startTime;
          quality = determineQualityFromLatency(duration);
        } catch (error) {
          console.error('Ping test failed:', error);
          quality = 'poor';
        }
      }

      dispatch(setConnectionQuality(quality));
      
    } catch (error) {
      console.error('Error detecting connection quality:', error);
      dispatch(setConnectionQuality('unknown'));
    }
  };
};

export const startConnectionQualityMonitoring = (): AppThunk => {
  return (dispatch) => {
    // Détection initiale
    dispatch(detectConnectionQuality());

    // Mise en place de la surveillance continue
    const connection = getNetworkConnection();
    if (connection) {
      const handleChange = () => dispatch(detectConnectionQuality());
      connection.addEventListener('change', handleChange);

      // Surveillance périodique de la latence API
      const intervalId = setInterval(() => dispatch(detectConnectionQuality()), 30000);

      // Nettoyage automatique
      return () => {
        connection.removeEventListener('change', handleChange);
        clearInterval(intervalId);
      };
    } else {
      // Fallback : vérification périodique plus fréquente si l'API n'est pas disponible
      const intervalId = setInterval(() => dispatch(detectConnectionQuality()), 15000);
      return () => clearInterval(intervalId);
    }
  };
};