import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { X } from 'lucide-react';
import i18n from '../../../../utils/i18n';
import { Spinner } from '../../../Loader/Loader';
import { SpeedDisplay } from './SpeedDisplay';
import { RemainingTime } from './RemainingTime';

const CancelButton = React.memo(({ onCancel }: { onCancel: () => void }) => {
  return (
    <button
      onClick={onCancel}
      className="btn btn-ghost btn-sm flex items-center"
      aria-label="Cancel upload"
    >
      <X className="w-4 h-4 mr-2" />
      {i18n._("cancel")}
    </button>
  );
});

CancelButton.displayName = 'CancelButton';

interface Props {
  onCancel: () => void;
}

interface RootState {
  transfer: {
    activeTransferId: string | null;
    transfers: {
      [key: string]: {
        loaded: number;
        totalSize: number;
        startTime: number;
        isLocked: boolean;
        isUploadFinished: boolean;
        progress: {
          last: {
            timestamp: number;
            loaded: number;
          };
          current: {
            timestamp: number;
            loaded: number;
            instantSpeed: number;
            avgSpeed: number;
          };
        };
      };
    };
  };
}

const selectTransferStats = (state: RootState) => {
  const activeTransferId = state.transfer.activeTransferId;
  const activeTransfer = activeTransferId ? state.transfer.transfers[activeTransferId] : null;

  return {
    isLocked: activeTransfer?.isLocked || false,
    loaded: Number(activeTransfer?.loaded) || 0,
    totalSize: Number(activeTransfer?.totalSize) || 0,
    startTime: Number(activeTransfer?.startTime) || Date.now(),
    isUploadFinished: activeTransfer?.isUploadFinished || false,
    progress: activeTransfer?.progress || {
      last: { timestamp: Date.now(), loaded: 0 },
      current: { timestamp: Date.now(), loaded: 0, instantSpeed: 0, avgSpeed: 0 }
    }
  };
};

export const Progress: React.FC<Props> = React.memo(({ onCancel }) => {
  const { isLocked, loaded, totalSize, startTime, progress, isUploadFinished } = useSelector(selectTransferStats);

  const stats = useMemo(() => {
    const now = Date.now();
    const elapsedTime = Math.max(1, (now - startTime) / 1000);

    return {
      loaded: (loaded / (1024 * 1024)).toFixed(1),
      total: (totalSize / (1024 * 1024)).toFixed(1),
      elapsedTime: `${Math.floor(elapsedTime)}s`,
      percent: isUploadFinished ? 100 : (totalSize > 0 ? Math.min(Math.round((loaded * 100) / totalSize), 100) : 0)
    };
  }, [loaded, totalSize, startTime, isUploadFinished]);

  if (!isLocked) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-full p-6">
      <div className="w-full max-w-md space-y-6">
        <Spinner percent={stats.percent} className="mx-auto" />

        <div className="flex flex-col items-center space-y-4 text-sm text-base-content/70 w-full">
          <div className="text-center">
            <span className="font-medium">{stats.loaded} MB / {stats.total} MB</span>
            <span className="block text-xs">{i18n._("upload_progress_transferred")}</span>
          </div>
          <SpeedDisplay />

          <div className="text-center">
            <span className="font-medium">{stats.elapsedTime}</span>
            <span className="block text-xs">{i18n._("upload_progress_elapsed")}</span>
          </div>

          <RemainingTime />
        </div>

        <div className="flex justify-center w-full">
          <CancelButton onCancel={onCancel} />
        </div>
      </div>
    </div>
  );
});

Progress.displayName = 'Progress';
