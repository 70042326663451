import React, { useState, useEffect, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

interface ConsumptionItemProps {
  icon: React.ReactNode;
  forceIOS?: boolean;
  label: string;
  value: number;
  getUnit: (val: number) => string;
  consumption: {
    kms: number;
    gCO2: number;
    kWh: number;
  };
  type?: 'upload' | 'download';
  isPreview?: boolean;
  isOpen?: boolean;
}

const waterRatio: number = 1.8;

function isIOS(): boolean {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const ValueTransition = ({ value, getUnit }: { value: number, getUnit: (val: number) => string }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const prevValueRef = useRef(value);

  useEffect(() => {
    if (prevValueRef.current !== value) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setIsTransitioning(false);
        prevValueRef.current = value;
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [value]);

  return (
    <div className={`
      transition-all duration-300
      ${isTransitioning ? 'opacity-90 scale-95' : 'opacity-100 scale-100'}
    `}>
      {getUnit(value)}
    </div>
  );
};
const ConsumptionItem: React.FC<ConsumptionItemProps> = ({
  icon,
  label,
  value,
  getUnit,
  consumption,
  type,
  isPreview,
  isOpen,
  forceIOS,
}) => {
  // Déterminer si on est sur mobile (mais pas tablette)
  const isMobileDevice = isMobile && !isTablet;
  
  let displayValue = value;
  switch (label) {
    case "Km":
      displayValue = consumption.kms;
      break;
    case "gCO2":
      displayValue = consumption.gCO2;
      break;
    case "kWh":
      displayValue = consumption.kWh;
      break;
    case "L":
      displayValue = consumption.kWh * waterRatio;
      break;
    default:
      break;
  }

  if (isPreview) {
    return (
      <div className={`relative group cursor-pointer ${isMobileDevice ? 'p-1 py-2' : 'p-3'}`}>
        <div className="flex items-center justify-center">
          <div className={`text-emerald-500 transform group-hover:scale-110 transition-transform duration-300 ${isMobileDevice ? 'scale-75' : ''}`}>
            {icon}
          </div>
        </div>
      </div>
    );
  }
  
  const mainContent = (
    <div className={`
      relative ${isMobileDevice ? 'p-1 py-2' : 'p-3'}
      group
      transition-all duration-300
      hover:bg-white/5 rounded-lg
    `}>
      <div className={`relative z-10 flex flex-col items-center ${isMobileDevice ? 'gap-1' : 'gap-3'}`}>
        <div className={`flex items-center justify-center ${isMobileDevice ? 'w-8 h-8' : 'w-12 h-12'} text-white group-hover:scale-110 transition-transform duration-300`}>
          {icon}
        </div>
        
        {(!isIOS() || forceIOS) && type !== 'download' && (
          <div className="flex flex-col items-center">
            <span className={`text-white font-bold ${isMobileDevice ? 'text-sm' : 'text-lg'} tracking-wider`}>
              <ValueTransition value={displayValue} getUnit={getUnit} />
            </span>
            <div className={`h-px ${isMobileDevice ? 'w-8 my-1' : 'w-12 my-2'} bg-white/20 group-hover:w-16 transition-all duration-300`} />
            <span className={`text-white/80 ${isMobileDevice ? 'text-xs' : 'text-sm'} uppercase tracking-wide`}>{label}</span>
          </div>
        )}

        {type === 'download' && (
          <span className={`text-white font-bold inline-flex items-center ${isMobileDevice ? 'gap-1 text-sm' : 'gap-2'}`}>
            <ValueTransition value={displayValue} getUnit={getUnit} /> {label}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="relative group">
      {mainContent}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent rounded-lg" />
      </div>
    </div>
  );
};

export default ConsumptionItem;