import { Middleware } from 'redux';
import { RootState } from '../types';
import { transferAction } from '../Actions';

// Configuration du stockage
const STORAGE_PREFIX = 'fv_transfer_';
const HISTORY_KEY = 'filevert_transfer_history';
const TRANSFER_ACTIONS = [
  transferAction.SET_INFOS,
  transferAction.LOCK_TRANSFER,
  transferAction.UPDATE_PROGRESS,
  transferAction.PURGE,
  transferAction.SET_MINIMIZED,
  transferAction.RESTORE_TRANSFER,
  transferAction.RESET_TRANSFER_STATE
] as const;

// Actions qui nécessitent un nettoyage du localStorage
const CLEANUP_ACTIONS = [
  transferAction.PURGE,
  transferAction.RESET_TRANSFER_STATE
] as const;

// Fonction pour sauvegarder l'historique minimal
const saveToHistory = (transferData: TransferData) => {
  if (transferData.isUploadFinished) {
    const minimalData = {
      id: transferData.id,
      transfer_name: transferData.name || 'Sans nom',
      link: transferData.transfer_link,
      expiration_date: transferData.expiration_date,
      created_at: Date.now()
    };

    try {
      // Récupérer l'historique existant
      const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
      
      // Ajouter le nouveau transfert
      history.unshift(minimalData);
      
      // Garder uniquement les 3 derniers transferts
      const updatedHistory = history.slice(0, 3);
      
      // Sauvegarder l'historique mis à jour
      localStorage.setItem(HISTORY_KEY, JSON.stringify(updatedHistory));
    } catch (error) {
      console.error('[TransferPersist] Error saving to history:', error);
    }
  }
};

// Types pour la gestion du transfert
interface TransferData {
  id: string;
  isLocked: boolean;
  isUploadFinished: boolean;
  isMinimized: boolean;
  loaded: number;
  totalSize: number;
  currentFileIndex: number;
  progress: {
    current: {
      timestamp: number;
      loaded: number;
      instantSpeed: number;
      avgSpeed: number;
    };
    last?: {
      timestamp: number;
      loaded: number;
    };
  };
  startTime: number;
  totalTime: number;
  consumption: {
    size: number;
    gCO2: number;
    kWh: number;
    kms: number;
  };
  name?: string;
  transfer_link?: string;
  expiration_date?: string;
  purging?: boolean;
  lastSaved?: number;
}

// Utilitaires pour la gestion du localStorage
const storage = {
  getKey: (transferId: string) => `${STORAGE_PREFIX}${transferId}`,

  getAvailableSpace: () => {
    let totalSize = 0;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        totalSize += localStorage.getItem(key)?.length || 0;
      }
    }
    const availableSpace = 5 * 1024 * 1024 - totalSize; // Assuming 5MB limit

    return availableSpace;
  },

  syncActiveTransfers: (transfers: { [key: string]: TransferData }) => {
    Object.entries(transfers).forEach(([transferId, transfer]) => {
      if (!transfer.isUploadFinished && !transfer.purging) {
        storage.save(transferId, transfer);
      }
    });
  },

  cleanupOldTransfers: () => {

    
    const transfers = storage.loadAll();
    let cleanedUp = 0;
    
    // Nettoyer tous les transferts sauf ceux qui sont actifs (locked et non terminés)
    const transfersToClean = transfers.filter(t => 
      !t.isLocked || t.isUploadFinished || t.purging
    );
    transfersToClean.forEach(transfer => {
      storage.clear(transfer.id);
      cleanedUp++;
    });
    
    // 3. Si on a toujours besoin d'espace, trier par date et supprimer les plus anciens
    if (storage.getAvailableSpace() < 500 * 1024) {
      const remainingTransfers = storage.loadAll();
      remainingTransfers.sort((a, b) => (a.startTime || 0) - (b.startTime || 0));
      
      for (const transfer of remainingTransfers) {
        if (storage.getAvailableSpace() > 500 * 1024) break;
        storage.clear(transfer.id);
        cleanedUp++;
      }
    }
    
    if (cleanedUp > 0) {

    }
    

  },

  save: (transferId: string, data: TransferData) => {
    try {
      const progress = data.totalSize > 0 
        ? Math.round((data.loaded / data.totalSize) * 100)
        : 0;
      const instantSpeedKB = Math.round(data.progress.current.instantSpeed / 1024);
      const avgSpeedKB = Math.round(data.progress.current.avgSpeed / 1024);
      


      const stateToSave = {
        ...data,
        id: transferId,
        progress: {
          ...data.progress,
          last: undefined // On ne persiste pas les données temporelles
        }
      };
      const serializedState = JSON.stringify(stateToSave);
      
      if (serializedState.length > storage.getAvailableSpace()) {

        storage.cleanupOldTransfers();
      }
      
      if (serializedState.length <= storage.getAvailableSpace()) {
        localStorage.setItem(storage.getKey(transferId), serializedState);

      } else {
        // Suppression du log d'avertissement d'espace insuffisant
      }
    } catch (error) {
      // Suppression du log d'erreur
    }
  },

  clear: (transferId: string) => {
    try {
      localStorage.removeItem(storage.getKey(transferId));

    } catch (error) {
      // Suppression du log d'erreur
    }
  },

  load: (transferId: string): TransferData | undefined => {
    try {
      const serializedState = localStorage.getItem(storage.getKey(transferId));
      if (!serializedState) {

        return undefined;
      }

      const parsedState = JSON.parse(serializedState);
      
      if (!parsedState || typeof parsedState !== 'object') {

        storage.clear(transferId);
        return undefined;
      }

      // Calculer le temps écoulé depuis la dernière mise à jour
      const now = Date.now();
      const timeSinceLastUpdate = now - (parsedState.lastSaved || now);
      const shouldResetSpeeds = timeSinceLastUpdate > 5000; // Reset si > 5 secondes

      const loadedState: TransferData = {
        id: parsedState.id,
        isLocked: parsedState.isLocked || false,
        isUploadFinished: parsedState.isUploadFinished || false,
        isMinimized: parsedState.isMinimized || false,
        loaded: parsedState.loaded || 0,
        totalSize: parsedState.totalSize || 0,
        currentFileIndex: parsedState.currentFileIndex || 0,
        progress: {
          current: {
            timestamp: now,
            loaded: parsedState.loaded || 0,
            instantSpeed: shouldResetSpeeds ? 0 : (parsedState.progress?.current?.instantSpeed || 0),
            avgSpeed: shouldResetSpeeds ? 0 : (parsedState.progress?.current?.avgSpeed || 0)
          },
          last: {
            timestamp: now,
            loaded: parsedState.loaded || 0
          }
        },
        startTime: parsedState.startTime || now,
        totalTime: parsedState.totalTime || 0,
        consumption: parsedState.consumption || {
          size: 0,
          gCO2: 0,
          kWh: 0,
          kms: 0
        },
        name: parsedState.name,
        transfer_link: parsedState.transfer_link,
        expiration_date: parsedState.expiration_date,
        lastSaved: now
      };

      if (loadedState.loaded > 0 || loadedState.totalSize > 0) {
        const progress = loadedState.totalSize > 0 
          ? Math.round((loadedState.loaded / loadedState.totalSize) * 100)
          : 0;
        
        // Recalculer les vitesses si le transfert n'est pas terminé
        if (!loadedState.isUploadFinished && loadedState.progress.last) {
          const timeDiffSec = Math.max(0.001, (Date.now() - loadedState.progress.last.timestamp) / 1000);
          const deltaLoaded = Math.max(0, loadedState.loaded - loadedState.progress.last.loaded);
          const rawSpeed = deltaLoaded / timeDiffSec;
          
          loadedState.progress.current.instantSpeed = rawSpeed * 0.7 + loadedState.progress.current.instantSpeed * 0.3;
          loadedState.progress.current.avgSpeed = loadedState.progress.current.instantSpeed * 0.3 + loadedState.progress.current.avgSpeed * 0.7;
        }
        
        const instantSpeedKB = Math.round(loadedState.progress.current.instantSpeed / 1024);
        const avgSpeedKB = Math.round(loadedState.progress.current.avgSpeed / 1024);

      }

      return loadedState;
    } catch (error) {
      // Suppression du log d'erreur
      storage.clear(transferId);
      return undefined;
    }
  },

  loadAll: (): TransferData[] => {
    const transfers: TransferData[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(STORAGE_PREFIX)) {
        const transferId = key.replace(STORAGE_PREFIX, '');
        const transfer = storage.load(transferId);
        if (transfer && transfer.isLocked && !transfer.isUploadFinished && !transfer.purging) {
          // Ne restaurer que les transferts actifs (locked et non terminés)
          transfers.push(transfer);
        }
      }
    }
    // Trier par date de dernière sauvegarde pour avoir le plus récent en premier
    transfers.sort((a, b) => (b.lastSaved || 0) - (a.lastSaved || 0));
    // Ne retourner que le transfert le plus récent s'il y en a un
    return transfers.length > 0 ? [transfers[0]] : [];
  }
};

// Middleware
// Nettoyer périodiquement le stockage (toutes les 5 minutes)
let lastCleanupTime = 0;
const CLEANUP_INTERVAL = 5 * 60 * 1000; // 5 minutes

const transferPersistMiddleware: Middleware<{}, RootState> = store => next => action => {
  // Exécuter l'action
  const result = next(action);
  
  // Nettoyer périodiquement le stockage
  const now = Date.now();
  if (now - lastCleanupTime > CLEANUP_INTERVAL) {
    storage.cleanupOldTransfers();
    lastCleanupTime = now;
  }
  
  // Gérer la persistance si c'est une action de transfert
  if (TRANSFER_ACTIONS.includes(action.type)) {
    // Si c'est une action de nettoyage, on s'assure de nettoyer le localStorage
    if (CLEANUP_ACTIONS.includes(action.type)) {
      const transferId = action.transferId;
      if (transferId) {
        storage.clear(transferId);
      }
    }
    const state = store.getState();
    const transfers = state.transfer?.transfers;
    const transferId = action.transferId || state.transfer?.activeTransferId;
    
    // Vérifier si le transfert a été récemment purgé
    try {
      const recentlyPurged = JSON.parse(localStorage.getItem('fv_recently_purged') || '[]') as Array<{id: string; timestamp: number}>;
      const isPurged = recentlyPurged.some(purge => purge.id === transferId && Date.now() - purge.timestamp < 5000);
      if (isPurged && action.type === transferAction.UPDATE_PROGRESS) {

        return result;
      }
    } catch (error) {
      // Suppression du log d'erreur
    }

    if (!transferId) {

      return result;
    }

    // Vérifier si le transfert existe dans le store
    if (transfers && transferId) {
      const transferState = transfers[transferId];
      
      // Si c'est une action UPDATE_PROGRESS et que le transfert n'existe pas dans le store,
      // on ignore l'action car le transfert a probablement été purgé
      if (action.type === transferAction.UPDATE_PROGRESS && !transferState) {

        return result;
      }
      
      if (transferState) {
        // Si le transfert est terminé ou purgé
        if (transferState.isUploadFinished || action.type === transferAction.PURGE) {

          
          // Sauvegarder dans l'historique si c'est un transfert terminé
          if (transferState.isUploadFinished) {
            const transferDataToSave: TransferData = {
              id: transferId,
              isLocked: transferState.isLocked || false,
              isUploadFinished: true,
              isMinimized: transferState.isMinimized || false,
              loaded: transferState.loaded || 0,
              totalSize: transferState.totalSize || 0,
              currentFileIndex: transferState.currentFileIndex || 0,
              progress: {
                current: {
                  timestamp: Date.now(),
                  loaded: transferState.loaded || 0,
                  instantSpeed: transferState.progress?.current?.instantSpeed || 0,
                  avgSpeed: transferState.progress?.current?.avgSpeed || 0
                }
              },
              startTime: transferState.startTime || Date.now(),
              totalTime: transferState.totalTime || 0,
              consumption: transferState.consumption || {
                size: 0,
                gCO2: 0,
                kWh: 0,
                kms: 0
              },
              name: transferState.name,
              transfer_link: transferState.link,
              expiration_date: transferState.expiration_date
            };
            saveToHistory(transferDataToSave);
            
            // Nettoyer le storage et marquer comme purgé après un délai pour laisser
            // le temps à ButtonUploadHistory de détecter la fin du transfert
            setTimeout(() => {

              storage.clear(transferId);
              
              // Ajouter à la liste des transferts récemment purgés
              try {
                const recentlyPurged = JSON.parse(localStorage.getItem('fv_recently_purged') || '[]') as Array<{id: string; timestamp: number}>;
                recentlyPurged.push({
                  id: transferId,
                  timestamp: Date.now()
                });
                // Garder seulement les purges des 5 dernières secondes
                const recentPurges = recentlyPurged.filter(purge => Date.now() - purge.timestamp < 5000);
                localStorage.setItem('fv_recently_purged', JSON.stringify(recentPurges));
              } catch (error) {
                // Suppression du log d'erreur
              }
            }, 5000); // Attendre 5 secondes avant de nettoyer
          }
          // Si c'est une action PURGE, nettoyer immédiatement
          else if (action.type === transferAction.PURGE) {

            storage.clear(transferId);
            
            // Ajouter à la liste des transferts récemment purgés
            try {
              const recentlyPurged = JSON.parse(localStorage.getItem('fv_recently_purged') || '[]') as Array<{id: string; timestamp: number}>;
              recentlyPurged.push({
                id: transferId,
                timestamp: Date.now()
              });
              // Garder seulement les purges des 5 dernières secondes
              const recentPurges = recentlyPurged.filter(purge => Date.now() - purge.timestamp < 5000);
              localStorage.setItem('fv_recently_purged', JSON.stringify(recentPurges));
            } catch (error) {
              console.error('[TransferPersist] Error updating recently purged transfers:', error);
            }
          }
        }
        // Sinon, sauvegarder l'état
        else {
          const progress = transferState.totalSize > 0 
            ? Math.round((transferState.loaded / transferState.totalSize) * 100)
            : 0;
          const instantSpeedKB = Math.round(transferState.progress.current.instantSpeed / 1024);
          const avgSpeedKB = Math.round(transferState.progress.current.avgSpeed / 1024);
          
    
          
          const currentTimestamp = Date.now();
          // Recalculer les vitesses avant la sauvegarde
          const lastTimestamp = transferState.progress?.current?.timestamp || currentTimestamp;
          const timeDiffSec = Math.max(0.001, (currentTimestamp - lastTimestamp) / 1000);
          const deltaLoaded = Math.max(0, transferState.loaded - (transferState.progress?.current?.loaded || 0));
          const rawSpeed = deltaLoaded / timeDiffSec;
          
          const newInstantSpeed = rawSpeed * 0.7 + (transferState.progress?.current?.instantSpeed || 0) * 0.3;
          const newAvgSpeed = newInstantSpeed * 0.3 + (transferState.progress?.current?.avgSpeed || 0) * 0.7;
          
          const transferDataToSave: TransferData = {
            ...transferState,
            id: transferId,
            progress: {
              last: {
                timestamp: lastTimestamp,
                loaded: transferState.progress?.current?.loaded || 0
              },
              current: {
                timestamp: currentTimestamp,
                loaded: transferState.loaded || 0,
                instantSpeed: newInstantSpeed,
                avgSpeed: newAvgSpeed
              }
            },
            isLocked: transferState.isLocked || false,
            isUploadFinished: transferState.isUploadFinished || false,
            isMinimized: transferState.isMinimized || false,
            loaded: transferState.loaded || 0,
            totalSize: transferState.totalSize || 0,
            currentFileIndex: transferState.currentFileIndex || 0,
            startTime: transferState.startTime || Date.now(),
            totalTime: transferState.totalTime || 0,
            consumption: transferState.consumption || {
              size: 0,
              gCO2: 0,
              kWh: 0,
              kms: 0
            }
          };
          storage.save(transferId, transferDataToSave);
        }
      } else {
        // Tenter de restaurer depuis le localStorage
        const savedState = storage.load(transferId);
        if (savedState) {

          store.dispatch({
            type: transferAction.RESTORE_TRANSFER,
            transferId,
            transferState: savedState
          });
        } else {

        }
      }
    }
  }

  // Log l'état après chaque action si des changements significatifs
  const currentState = store.getState().transfer;
  const hasActiveTransfers = Object.values(currentState.transfers).some(
    t => t.loaded > 0 || t.totalSize > 0 || t.progress.current.instantSpeed > 0
  );
  
  if (hasActiveTransfers) {

  }

  return result;
};

// Export du middleware et des fonctions de chargement
export default transferPersistMiddleware;
export const loadTransferState = storage.loadAll;