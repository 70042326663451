// src/Components/BTNComponent.tsx
import React, { ReactNode } from "react";
import { Check, X } from "lucide-react";

interface BTNComponentProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  hasChanged?: boolean;
  text?: ReactNode;
  onClick?: () => void;
  className?: string;
  color?: "primary" | "danger" | "success" | "warning"; // Ajoutez d'autres couleurs si nécessaire
  isSuccess?: boolean;
  background?: "transparent" | "filled"; // 'filled' pour un fond coloré
  outline?: boolean;
  [key: string]: any; // Pour les props supplémentaires
}

const colorClasses: {
  [key in NonNullable<BTNComponentProps["color"]>]: {
    bg: string;
    text: string;
    border: string;
    hoverBg: string;
  };
} = {
  primary: {
    bg: "bg-blue-500",
    text: "text-white",
    border: "border-blue-500",
    hoverBg: "hover:bg-blue-600",
  },
  danger: {
    bg: "bg-red-500",
    text: "text-white",
    border: "border-red-500",
    hoverBg: "hover:bg-red-600",
  },
  success: {
    bg: "bg-green-500",
    text: "text-white",
    border: "border-green-500",
    hoverBg: "hover:bg-green-600",
  },
  warning: {
    bg: "bg-yellow-500",
    text: "text-white",
    border: "border-yellow-500",
    hoverBg: "hover:bg-yellow-600",
  },
  // Ajoutez d'autres couleurs ici si nécessaire
};

const BTNComponent: React.FC<BTNComponentProps> = ({
  isLoading = false,
  isDisabled = false,
  hasChanged = true,
  text = "Button",
  onClick,
  className = "",
  color = "primary",
  isSuccess = false,
  background = "transparent",
  outline = true,
  ...props
}) => {
  // Détermine la couleur actuelle en fonction des props
  let currentColor = color;
  if (!hasChanged && !isDisabled && !isLoading && !isSuccess) {
    currentColor = "danger";
  }

  const colors = colorClasses[currentColor] || colorClasses["primary"];

  // Détermine les classes de base
  const baseClasses =
    "flex items-center justify-center px-4 py-2 rounded transition-colors duration-300 focus:outline-none";

  // Détermine les classes en fonction de l'outline et du background
  let buttonClasses = "";
  if (outline) {
    buttonClasses = `border ${colors.border} ${colors.text} bg-transparent ${colors.hoverBg}`;
  } else {
    buttonClasses = `${colors.bg} ${colors.text} border-transparent ${colors.hoverBg}`;
  }

  // Classes additionnelles si le bouton est désactivé
  const disabledClasses = isDisabled
    ? "opacity-50 cursor-not-allowed"
    : "";

  // Combinaison des classes
  const combinedClasses = `${baseClasses} ${buttonClasses} ${disabledClasses} ${className}`;

  return (
    <button
      {...props}
      disabled={isDisabled || isLoading}
      className={combinedClasses}
      onClick={!isDisabled && !isLoading ? onClick : undefined}
    >
      {isLoading && (
        <span className="loading loading-spinner loading-xs mr-2 inline-block w-4 h-4 border-2 border-current border-t-transparent text-current rounded-full" style={{ animation: 'spin 1s linear infinite' }}></span>
      )}
      {isSuccess && (
        <Check className="w-5 h-5 test-emerald-500 mr-2" />
      )}
      {!hasChanged && !isDisabled && !isLoading && !isSuccess && (
        <X className="w-5 h-5 text-red-500 mr-2" />
      )}
      {!isLoading && !isSuccess && text}
    </button>
  );
};

export default BTNComponent;
