import React from 'react';
import './styles.css';

interface FolderLoaderProps {
  isLoading: boolean;
}

const FolderLoader: React.FC<FolderLoaderProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loader-container">
      <div className="svg-container">
        <svg viewBox="0 0 200 160">
          <path 
            className="folder"
            d="M40 20 Q30 20 30 30 L30 130 Q30 140 40 140 L160 140 Q170 140 170 130 L170 50 Q170 40 160 40 L100 40 L90 30 Q85 20 75 20 Z" 
          />
          <g clipPath="url(#folderClip)">
            <g className="wave-container">
              <path 
                className="wave wave1"
                d="M0 60 Q25 50 50 60 Q75 70 100 60 Q125 50 150 60 Q175 70 200 60 Q225 50 250 60 Q275 70 300 60 Q325 50 350 60 Q375 70 400 60" 
              />
              <path 
                className="wave wave2"
                d="M0 90 Q25 80 50 90 Q75 100 100 90 Q125 80 150 90 Q175 100 200 90 Q225 80 250 90 Q275 100 300 90 Q325 80 350 90 Q375 100 400 90" 
              />
              <path 
                className="wave wave3"
                d="M0 120 Q25 110 50 120 Q75 130 100 120 Q125 110 150 120 Q175 130 200 120 Q225 110 250 120 Q275 130 300 120 Q325 110 350 120 Q375 130 400 120" 
              />
            </g>
          </g>
          <defs>
            <clipPath id="folderClip">
              <path d="M40 20 Q30 20 30 30 L30 130 Q30 140 40 140 L160 140 Q170 140 170 130 L170 50 Q170 40 160 40 L100 40 L90 30 Q85 20 75 20 Z" />
            </clipPath>
          </defs>
        </svg>
      </div>
      
    </div>
  );
};

export default React.memo(FolderLoader);
