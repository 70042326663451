import { formatDate, i18n, translateHeaders } from "../../utils";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { userApi } from "../../Api";
import * as userAction from "../../Store/Actions/userAction";
import { TableListComponent, Switch } from "./TableListComponent";
import { subscriptionsCells } from "../../Constants";
import { Check, Loader } from "lucide-react";
import { UpgradeModal } from "../Modal/UpgradeModal";
import CustomModal from "../CustomModal/CustomModal";

interface LoaderProps {
  isLoading: boolean;
}

const LoaderComponent: React.FC<LoaderProps> = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="flex items-center justify-center">
      <Loader className="animate-spin text-primary" size={24} />
    </div>
  );
};

interface Subscription {
  id: string;
  name: string;
  qty: number;
  current_period_start: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
  status: string;
  isActive: boolean;
  customer?: string;
  type?: 'groupe' | 'usage';
  usage_type?: 'metered';
  usage_quantity?: number;
  metadata?: {
    is_groupe_subscription?: string;
    is_usage_subscription?: string;
    isUsageSubscription?: string;
    mainSubscriptionId?: string;
  };
  plan?: {
    id: string;
    amount: number;
    interval: string;
    active: boolean;
    product: string;
  };
  items?: {
    data: Array<{
      id: string;
      quantity: number;
    }>;
  };
}

interface TableSubscriptionsComponentProps {
  subscriptions: Subscription[];
  dispatch: (action: any) => void;
  user_id: string;
  permissions?: {
    team_size?: {
      value: number;
      id: number;
    };
    upload_size?: {
      value: number;
      id: number;
    };
    upload_ttl?: {
      value: number;
      id: number;
    };
    customization?: {
      value: boolean;
      id: number;
    };
    transfer_notification?: {
      value: boolean;
      id: number;
    };
    transfer_authentication?: {
      value: boolean;
      id: number;
    };
    consumption_export?: {
      value: boolean;
      id: number;
    };
    outlook_addin?: {
      value: boolean;
      id: number;
    };
    studio?: {
      value: boolean;
      id: number;
    };
    transfer_tracking?: {
      value: boolean;
      id: number;
    };
  };
}

export function TableSubscriptionsComponent({
  subscriptions,
  dispatch,
  user_id,
  permissions,
}: TableSubscriptionsComponentProps) {
  const reduxToken = useSelector((state: any) => state.user?.token);
  const token = localStorage.getItem('fv-auth-token') || reduxToken;
  
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<{
    isOpen: boolean;
    subscriptionId?: string;
    action: 'cancel' | 'reactivate';
    status?: string;
    user?: { id: string; customer_id: string };
  }>({
    isOpen: false,
    action: 'cancel'
  });

  const [state, setState] = useState({
    page: 1,
    rowsPerPage: 5,
    isLoading: false,
    error: null as string | null,
    processedSubscriptions: [] as Subscription[]
  });

  const handleCancelSubscription = useCallback(async (sId: string) => {
    try {
      const updatedUser = await userApi.cancelSubscription(sId, user_id);

      if (updatedUser && !updatedUser.error) {
        // Suppression du log détaillé
        dispatch && dispatch(userAction.loginUser(updatedUser));
        setConfirmationModal(prev => ({ ...prev, isOpen: false }));
        window.location.reload();
      } else {
        throw new Error(updatedUser?.error || "Unknown error");
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setConfirmationModal(prev => ({ ...prev, isOpen: false }));
      alert(i18n._("subscription_cancellation_error"));
    }
  }, [dispatch, user_id]);

  const handleReactivateSubscription = useCallback(async (sId: string, user: { id: string, customer_id: string }) => {
    try {
      if (!user?.id || !user?.customer_id || !token) {
        console.error('reactivateSubscription: Missing data');
        throw new Error(i18n._("missing_user_token_error"));
      }

      const updatedSubscription = await userApi.reactivateSubscription(sId, user.id.toString(), token);
      
      if (updatedSubscription && !updatedSubscription.error) {
        // Suppression du log détaillé
        if (dispatch) {
          dispatch(userAction.loginUser(updatedSubscription));
        }
        setConfirmationModal(prev => ({ ...prev, isOpen: false }));
        window.location.reload();
      } else {
        throw new Error(updatedSubscription.error?.message || i18n._("subscription_reactivation_server_error"));
      }
    } catch (error) {
      console.error("Erreur lors de la réactivation de l'abonnement:", error);
      setConfirmationModal(prev => ({ ...prev, isOpen: false }));
      alert(i18n._("subscription_reactivation_error"));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (!permissions) {
      setModalOpen(false);
    }
  }, [permissions]);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      processedSubscriptions: subscriptions.map((sub: Subscription) => ({
        ...sub,
        isActive: !sub.cancel_at_period_end && (sub.status === 'active' || sub.status === 'trialing')
      }))
    }));
  }, [subscriptions]);

  const handleModalClose = useCallback(() => {
    setConfirmationModal(prev => ({ ...prev, isOpen: false }));
  }, []);

  const handleModalConfirm = useCallback(() => {
    const { action, subscriptionId, user } = confirmationModal;
    if (action === 'cancel' && subscriptionId) {
      handleCancelSubscription(subscriptionId);
    } else if (action === 'reactivate' && subscriptionId && user) {
      handleReactivateSubscription(subscriptionId, user);
    }
  }, [confirmationModal, handleCancelSubscription, handleReactivateSubscription]);

  const handleToggleUpgradeModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleUpgrade = useCallback(async (newOffer: any) => {
    // Suppression du log de démarrage
    if (!permissions) {
      console.warn('TableSubscriptionsComponent: No permissions available');
      setState(prev => ({
        ...prev,
        error: i18n._("no_permissions_available_error"),
        isLoading: false
      }));
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      if (!token) {
        console.error('TableSubscriptionsComponent: No auth token found');
        alert(i18n._("session_expired_error"));
        window.location.href = '/login';
        throw new Error(i18n._("missing_token_error"));
      }

      const permissionsMetadata = {
        upload_size: String(newOffer.upload_size?.value),
        upload_ttl: String(newOffer.upload_ttl?.value),
        team_size: String(newOffer.team_size?.value),
        transfer_notification: String(newOffer.transfer_notification?.value),
        transfer_authentication: String(newOffer.transfer_authentication?.value),
        transfer_tracking: String(newOffer.transfer_tracking?.value),
        consumption_export: String(newOffer.consumption_export?.value),
        customization: String(newOffer.customization?.value),
        outlook_addin: String(newOffer.outlook_addin?.value),
        studio: String(newOffer.studio?.value)
      };

      const response = await userApi.createUpgradeSession({
        user_id,
        newOffer: {
          ...newOffer,
          metadata: {
            isUpgrade: 'true',
            user_id: String(user_id),
            ...permissionsMetadata
          }
        },
        currentPermissions: permissions,
        token
      });

      if (response?.success) {
        window.location.reload();
      }

      return response;
    } catch (error) {
      console.error('TableSubscriptionsComponent: Error during upgrade:', error);
      setState(prev => ({
        ...prev,
        error: i18n._("upgrade_error"),
        isLoading: false
      }));
      throw error;
    }
  }, [permissions, token, user_id]);

  const getSubPl = useCallback((sub: Subscription, updateCb: () => void, dispatch: (action: any) => void, user_id: string) => {
    // Suppression du log des métadonnées
    return {
      subscription_cell_name: (
        <div className="flex items-center space-x-2">
          <span className={`px-2 py-1 text-xs font-medium rounded ${
            sub.metadata?.is_usage_subscription === 'true' || sub.metadata?.isUsageSubscription === 'true'
              ? 'bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-400'
              : 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-400'
          }`}>
            {(sub.metadata?.isUsageSubscription === 'true' || sub.metadata?.is_usage_subscription === 'true') ? i18n._("filevert_group_usage") : 
             sub.metadata?.is_groupe_subscription === 'true' ? i18n._("group_offer") : i18n._("base_offer")}
          </span>
          <span>{(sub.metadata?.isUsageSubscription === 'true' || sub.metadata?.is_usage_subscription === 'true') || sub.metadata?.is_groupe_subscription === 'true' ? '' : sub?.name}</span>
        </div>
      ),
      subscription_cell_number: sub?.id,
      subscription_cell_users: (sub?.qty ?? 0) + 1,
      subscription_cell_date: i18n._("invoices_date_text", [
        sub?.current_period_start
          ? formatDate(new Date(sub?.current_period_start * 1000))
          : i18n._("not_available"),
        sub?.current_period_end
          ? formatDate(new Date(sub?.current_period_end * 1000))
          : i18n._("not_available"),
      ]),
      subscription_cell_status: (
        <div className="flex items-center space-x-2">
          <span className={`px-2 py-1 text-xs font-medium rounded ${
            sub.cancel_at_period_end 
              ? 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400'
              : sub.status === 'active' || sub.status === 'trialing'
                ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-400'
          }`}>
            {sub.cancel_at_period_end 
              ? i18n._("scheduled_cancellation") 
              : (sub.status === 'active' ? i18n._("active_status") : 
                 sub.status === 'trialing' ? i18n._("trial_status") : i18n._("inactive_status"))}
          </span>
          {(sub.metadata?.isUsageSubscription === 'true' || sub.metadata?.is_usage_subscription === 'true') && (
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {i18n._("usage_label")}
            </span>
          )}
        </div>
      ),
      subscription_cell_update: sub.metadata?.is_groupe_subscription === 'true' ? null : (
        <button
          className={`text-blue-600 hover:text-blue-800 ${
            sub?.isActive ? "" : "opacity-50 cursor-not-allowed"
          }`}
          disabled={!sub?.isActive}
          onClick={() => {
            // Suppression du log de clic
            if (sub?.isActive && updateCb) {
              updateCb();
            }
          }}
        >
          {i18n._("btn_modify_label")}
        </button>
      ),
      subscription_cell_action: (
        <div className="flex justify-center">
          <Switch
            checked={sub?.isActive}
            onChange={() => {
              if (sub?.isActive) {
                if (sub.metadata?.is_groupe_subscription === 'true' || sub.metadata?.is_usage_subscription === 'true') {
                  alert(i18n._("groupe_subscription_cancellation_warning"));
                }
                setConfirmationModal({
                  isOpen: true,
                  subscriptionId: sub.id,
                  action: 'cancel',
                  status: sub.status
                });
              } else if (sub.customer) {
                setConfirmationModal({
                  isOpen: true,
                  subscriptionId: sub.id,
                  action: 'reactivate',
                  user: { id: user_id, customer_id: sub.customer },
                  status: sub.status
                });
              } else {
                console.error("Customer ID is missing for subscription:", sub.id);
              }
            }}
          />
        </div>
      ),
    };
  }, []);

  const tableElements = useMemo(() => 
    state.processedSubscriptions.map((sub: Subscription) =>
      getSubPl(
        sub,
        handleToggleUpgradeModal,
        dispatch,
        user_id
      )
    ),
    [state.processedSubscriptions, handleToggleUpgradeModal, dispatch, user_id, getSubPl]
  );

  if (state.isLoading) {
    return (
      <div className="flex justify-center items-center p-4">
        <LoaderComponent isLoading={true} />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {state.error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 mb-4">
          <p className="text-red-700 dark:text-red-100">{state.error}</p>
        </div>
      )}
      <TableListComponent
        headers={translateHeaders(subscriptionsCells)}
        elements={tableElements}
      />
      {/* Ajouter l'intervalle de l'abonnement aux permissions */}
      <UpgradeModal
        isOpen={modalOpen && !!permissions}
        onClose={() => setModalOpen(false)}
        currentPermissions={{
          ...permissions || {},
          interval: {
            value: state.processedSubscriptions[0]?.plan?.interval || 'year',
            id: 0
          }
        }}
        onUpgrade={handleUpgrade}
        user_id={user_id}
      />
      <CustomModal
        isOpen={confirmationModal.isOpen}
        onClose={handleModalClose}
        title={confirmationModal.action === 'cancel' ? i18n._("confirm_unsubscription_title") : i18n._("confirm_reactivate_subscription_title")}
      >
        <div className="space-y-4">
          <p className="text-gray-700 dark:text-gray-300">
            {confirmationModal.action === 'cancel'
              ? i18n._("confirm_unsubscription_label")
              : i18n._("confirm_reactivate_subscription_label")}
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleModalClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
              {i18n._("cancel_button")}
            </button>
            <button
              onClick={handleModalConfirm}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
            >
              {i18n._("confirm_button")}
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default React.memo(TableSubscriptionsComponent);