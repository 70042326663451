import React, { FormEvent } from "react";
import { InputCheckboxComponent, InputEmailComponent } from "./Input";
import { i18n } from "../../utils";
import { Loader } from "lucide-react";

export function DepositFormComponent({ onSubmit, ...rest }: any) {
  const { email } = rest;

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
      <div className="bg-base-200 rounded-box shadow-xl w-full max-w-lg border border-gray-700">
        <div className="p-6 border border-white/10">
          <form
            onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
              evt.preventDefault();
              evt.stopPropagation();
              evt.currentTarget.classList.add("was-validated");
              let errors: any = Object.values(
                evt.currentTarget.querySelectorAll("input")
              ).some((e) => !e.checkValidity || !e.checkValidity());
              if (!errors && onSubmit)
                errors = await onSubmit(
                  Object.values(evt.currentTarget.querySelectorAll("input")).reduce(
                    (acc, cur) =>
                      cur && cur.name && cur.value && cur.name !== "confirm_password"
                        ? {
                            ...acc,
                            [cur.name]:
                              cur.type === "checkbox" ? cur.checked : cur.value,
                          }
                        : acc,
                    {}
                  )
                );

              if (errors && errors.field && !errors.ok) {
                switch (errors.field) {
                  case "email":
                    email.setCurrentError(errors.reason);
                    break;
                  default:
                    break;
                }
              }
            }}
            id="deposit_form"
            className="space-y-6"
          >
            <div className="space-y-4">
              <div className="rounded-lg p-4">
                <InputEmailComponent inputObject={email} />
              </div>
              
              <div className="border border-white/10 rounded-lg p-4 max-h-[150px] overflow-y-auto">
                <InputCheckboxComponent
                  id="cgu_input"
                  label={i18n._(
                    rest?.isPro
                      ? "accept_cgu_deposit_pro_label"
                      : "accept_cgu_deposit_free_label"
                  )}
                  name="cgu"
                  defaultChecked={false}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-blue-700 
                       flex items-center justify-center transition-colors duration-200
                       disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={email.submiting}
            >
              {email.submiting ? (
                <Loader className="w-5 h-5 animate-spin" />
              ) : (
                i18n._("btn_valid_label")       
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}